import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import CoarseAggregateAbsorption from './screens/CoarseAggregateAbsorption';
import CoarseAggregateAnalysis from './screens/CoarseAggregateAnalysis';
import ConcreteAntiStress from './screens/ConcreteAntiStress';
import FineAggregateAMS from './screens/FineAggregateAMS';
import FineAggregateAbsorption from './screens/FineAggregateAbsorption';
import FineAggregateAnalysis from './screens/FineAggregateAnalysis';
import FineAggregateChlorideIons from './screens/FineAggregateChlorideIons';
import FineAggregateRValue from './screens/FineAggregateRValue';
import FlyAshLValue from './screens/FlyAshLValue';
import FlyAshLossOfIgnition from './screens/FlyAshLossOfIgnition';

const routesConfig: RouteConfig[] = [
  {
    path: '/fineAggregateAnalysis/*',
    element: <FineAggregateAnalysis />,
    permission: 'WD043',
  },
  {
    path: '/fineAggregateAMS/*',
    element: <FineAggregateAMS />,
    permission: 'WD044',
  },
  {
    path: '/fineAggregateAbsorption/*',
    element: <FineAggregateAbsorption />,
    permission: 'WD045',
  },
  {
    path: '/fineAggregateChlorideIons/*',
    element: <FineAggregateChlorideIons />,
    permission: 'WD046',
  },
  {
    path: '/fineAggregateRValue/*',
    element: <FineAggregateRValue />,
    permission: 'WD047',
  },
  {
    path: '/coarseAggregateAnalysis/*',
    element: <CoarseAggregateAnalysis />,
    permission: 'WD048',
  },
  {
    path: '/CoarseAggregateAbsorption/*',
    element: <CoarseAggregateAbsorption />,
    permission: 'WD049',
  },
  {
    path: '/flyAshLValue/*',
    element: <FlyAshLValue />,
    permission: 'WD050',
  },
  {
    path: '/flyAshLossOfIgnition/*',
    element: <FlyAshLossOfIgnition />,
    permission: 'WD051',
  },
  {
    path: '/concreteAntiStress/*',
    element: <ConcreteAntiStress />,
    permission: 'WD052',
  },
];

const MonthlyReport = () => PermissionRoute(routesConfig);

export default memo(MonthlyReport);
