import React from 'react';
import TotalPageTitle from '@Src/_basic/components/YatungPageTitle';
import { useTranslation } from 'react-i18next';
import ReturnAndLossBi from '../components/ReturnAndLossBi';

export default function ReturnAndLossScreen() {
  const { t: i18T } = useTranslation();
  return (
    <>
      <TotalPageTitle title={i18T('POWERBI.RETURN_AND_LOSS')} />
      <ReturnAndLossBi />
    </>
  );
}
