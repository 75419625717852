import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import YatungDymanicTabs from '@Src/_basic/components/YatungDymanicTabs';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungRadioButton from '@Src/_basic/components/YatungRadioButton';
import { Options } from '@Src/_basic/components/YatungSelect';
import LeftArrow from '@Src/_basic/icons/LeftArrow';
import RightArrow from '@Src/_basic/icons/RightArrow';
import { AreaData, AreaOptions } from '@Src/_basic/object/AreaType';
import { FactoryItem } from '@Src/_basic/object/FactoryType';
import { CreateMixerData, MixerData, MixerDetailData, MixerNameTab } from '@Src/_basic/object/MixerTypes';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';
import { MixerApi } from '@Src/_basic/protocol/mixerInformation/MixerApi';
import { Box, Grid, Paper, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CreateMixerInput from './components/CreateMixerInput';
import UpdateMixerDetailsSettings from './components/UpdateMixerDetailsSettings';
import UpdateMixerInput from './components/UpdateMixerInput';

interface Props {
  proportionOptions: Options[];
  updateMixerData: any;
  setUpdateMixerData: (e: any) => void;
}
export default function MixerInformationUpdateForm(props: Props) {
  const { t: i18T } = useTranslation();
  const { updateMixerData, setUpdateMixerData, proportionOptions } = props;
  const history = useNavigate();
  const handleGoBack = () => {
    history(-1);
    setUpdateMixerData({
      areaName: '',
      factoryId: 0,
      factoryName: '',
      waterAbsorption: 0.0,
      mixers: [],
      lastUpdateTime: 0,
      isYardMixerActived: false,
      yardMixer: {
        factoryId: 0,
        id: 0,
        mixerName: '',
        removed: false,
        maintPropMixerCode: {
          id: 0,
          name: '',
          code: '',
        },
        storages: [],
      },
    });
    setCreateMixer([]);
  };

  const [areas, setAreas] = useState<AreaOptions[]>([]);
  const [createMixer, setCreateMixer] = useState<Array<CreateMixerData>>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [mixerName, setMixerName] = useState<Array<MixerNameTab>>([]);
  const [updatemixerName, setUpdateMixerName] = useState<Array<MixerNameTab>>([]);
  const [waterDemand, setWaterDemand] = useState<string>(updateMixerData.waterAbsorption?.toString());
  const [areaId, setAreaId] = useState<number>(0);
  const [storageType, setStorageType] = useState<string>(
    updateMixerData.isYardMixerActived === false ? 'notIs_storage' : 'is_storage',
  );

  const handleWaterDemandInput = (e: SelectChangeEvent) => {
    setWaterDemand(e.target.value);
    const updateArrayTemp = { ...updateMixerData, waterAbsorption: e.target.value };
    setUpdateMixerData(updateArrayTemp);
  };

  const handleChange = (event: any, newValue: number) => {
    setTabValue(newValue);
  };
  const handleSubmit = () => {
    for (let i = 0; i < createMixer.length; i++) {
      delete createMixer[i].id;
    }

    const data = {
      areaId: areaId,
      factoryId: updateMixerData.factoryId,
      waterAbsorption: +waterDemand,
      mixers: updateMixerData.mixers.concat(createMixer),
      isYardMixerActive: storageType === 'is_storage' ? true : false,
    };
    MixerApi.putMixerData(
      data,
      (data: MixerData[]) => {
        history(-1);
      },
      undefined,
      undefined,
    );
  };
  const getAllAreasData = () => {
    AreaApi.getAllAreas((allAreas: Array<AreaData>) => {
      const areaOptions: AreaOptions[] = allAreas.map((allArea: AreaData) => ({
        value: allArea.id,
        text: allArea.name,
        factories: allArea.factoryList.map((factory: FactoryItem) => ({
          value: factory.factoryId,
          text: factory.factoryName,
        })),
      }));
      const filterIdNotIs7 = areaOptions.filter((_data: AreaOptions) => _data.value !== 7);

      setAreas(filterIdNotIs7);
    });
  };

  const handleMixerCreateElement = () => {
    const objectData = {
      id: Math.random(),
      mixerName: '',
      factoryId: updateMixerData.factoryId,
      removed: false,
      maintPropMixerCode: {
        id: 0,
        name: '',
        code: '',
      },
      storages: [],
    };
    const newDatas = Object.assign([], createMixer);
    newDatas.push(objectData);
    setCreateMixer(newDatas);
  };
  const getMixerValue = () => {
    const mixerValue = createMixer.map((value: CreateMixerData, index: number) => {
      const data = {
        id: updateMixerData.mixers.length + index,
        name: value.mixerName === '' ? '未命名' : value.mixerName,
      };
      return data;
    });
    setMixerName(mixerValue);
  };
  const getMixerValueByUpdate = () => {
    const mixerValue = updateMixerData.mixers?.map((value: MixerDetailData, index: number) => {
      const data = {
        id: index,
        name: value.mixerName,
        removed: value.removed,
      };
      return data;
    });
    setUpdateMixerName(mixerValue);
  };
  useEffect(() => {
    getAllAreasData();
  }, []);
  useEffect(() => {
    getMixerValueByUpdate();
  }, [updateMixerData]);
  useEffect(() => {
    getMixerValue();
  }, [createMixer]);

  const [startIndex, setStartIndex] = useState(0);

  const batchSize = 5;
  const handleNext = () => {
    setStartIndex((prev) => prev + 1);
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex((prev) => prev - 1);
    }
  };
  const getFilterAreaId = () => {
    if (areas.length > 0) {
      const filteredArray = areas.filter((_data: AreaOptions) => {
        // 检查 factories 数组中是否有对象的 text 属性为 '汐止'

        return _data.factories.some((factory: Options) => factory.text === updateMixerData.factoryName);
      });
      const getAreaId = filteredArray[0].value;
      setAreaId(getAreaId);
    }
  };
  useEffect(() => {
    getFilterAreaId();
  }, [updateMixerData]);

  const handleInputChangeByCreate = (key: number, _value: string) => {
    const updatedArray = createMixer.map((data: CreateMixerData) => {
      if (data.id === key) {
        return { ...data, mixerName: _value };
      }
      return data;
    });
    setCreateMixer(updatedArray);
  };
  const handleDeleteInputByCreate = (key: number) => {
    const deleteCreateMixerById = createMixer.filter((data: CreateMixerData) => data.id !== key);
    setCreateMixer(deleteCreateMixerById);
    if (startIndex !== 0) {
      setStartIndex(startIndex - 1);
    }
    if (tabValue > 0) {
      setTabValue(tabValue - 1);
    }
  };

  const handleChangeStatus = (key: number) => {
    const updatedRemove = updateMixerData.mixers.map((data: MixerDetailData) => {
      if (data.id === key) {
        return { ...data, removed: !data.removed };
      }
      return data;
    });

    const updateRemoveTemp = { ...updateMixerData, mixers: updatedRemove };
    setUpdateMixerData(updateRemoveTemp);
  };

  const handleInputChangeByUpdate = (key: number, _value: string) => {
    const updatedArray = updateMixerData.mixers.map((data: MixerDetailData) => {
      if (data.id === key) {
        return { ...data, mixerName: _value };
      }
      return data;
    });
    const updateArrayTemp = { ...updateMixerData, mixers: updatedArray };

    setUpdateMixerData(updateArrayTemp);
  };
  const handleStorageType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStorageType(e.target.value);
    const updateArrayTemp = { ...updateMixerData, isYardMixerActived: e.target.value === 'is_storage' ? true : false };
    setUpdateMixerData(updateArrayTemp);
  };

  return (
    <>
      <Grid container item xs={12}>
        <Grid container item xs={5}>
          <Grid container item xs={12} sx={{ mt: 3 }} alignSelf={'center'}>
            <Grid container item xs={3}>
              <YatungFormLabel label={i18T('USERSMANAGEMENU.MIXER_INFORMATION.AREA')} />
            </Grid>
            <Grid container item xs={9}>
              <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>
                {updateMixerData.areaName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ my: 3 }} alignSelf={'center'}>
            <Grid container item xs={3}>
              <YatungFormLabel label={i18T('USERSMANAGEMENU.MIXER_INFORMATION.FACTORY')} />
            </Grid>
            <Grid container item xs={9}>
              <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>
                {updateMixerData.factoryName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ my: 3 }} alignSelf={'center'}>
            <Grid container item xs={3}>
              <YatungFormLabel label={i18T('USERSMANAGEMENU.MIXER_INFORMATION.WATER_DEMAND_RATE')} />
            </Grid>
            <Grid container item xs={9}>
              <YatungInput onChange={handleWaterDemandInput} sx={{ width: '260px' }} type="text" value={waterDemand} />
            </Grid>
          </Grid>
          <Grid container item xs={5} sx={{ my: 3, ml: 1 }}>
            <YatungRadioButton
              label={i18T('USERSMANAGEMENU.MIXER_INFORMATION.STORAGE_YARD')}
              onChange={handleStorageType}
              value={storageType}
              groupName={'order-group'}
              controlData={[
                {
                  label: i18T('GLOBAL.YES'),
                  value: 'is_storage',
                },
                {
                  label: i18T('GLOBAL.NOT'),
                  value: 'notIs_storage',
                },
              ]}
            />
          </Grid>
          <YatungDivider />
          <Grid container item xs={12} sx={{ my: 3 }}>
            <YatungButton
              text={i18T('USERSMANAGEMENU.MIXER_INFORMATION.CREATE_MIXER')}
              onClick={handleMixerCreateElement}
              color="green"
              sx={{ mr: 2 }}
            />
          </Grid>
          <Grid container item xs={12} sx={{ mt: 3 }} alignSelf={'center'}>
            {updateMixerData.mixers?.map((data: MixerDetailData, index: number) => {
              return (
                <UpdateMixerInput
                  key={data.id}
                  value={data}
                  index={index}
                  inuptValue={data.mixerName ? data.mixerName : ''}
                  onInput={(_value: string) => {
                    handleInputChangeByUpdate(data.id, _value);
                  }}
                  onChangeStatus={() => {
                    handleChangeStatus(data.id);
                  }}
                />
              );
            })}
          </Grid>
          <Grid container item xs={12}>
            {createMixer.map((data: CreateMixerData, index: number) => {
              return (
                <CreateMixerInput
                  key={data.id}
                  index={index}
                  updateMixerData={updateMixerData}
                  inuptValue={data.mixerName ? data.mixerName : ''}
                  onInput={(_value: string) => {
                    handleInputChangeByCreate(data.id, _value);
                  }}
                  onDeleteInput={() => {
                    handleDeleteInputByCreate(data.id);
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
        {createMixer.concat(updateMixerData).length > 0 && (
          <Grid container item xs={7} justifyContent={'flex-end'}>
            <Box
              sx={{
                backgroundSize: 'cover',
                height: '100%',
                backgroundColor: '#F2F2F2',
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  height: '100%',
                  position: 'relative',
                }}
              >
                <Grid container item xs={12} justifyContent={'flex-end'}>
                  {startIndex !== 0 && updatemixerName.concat(mixerName).length > 5 && (
                    <YatungButton
                      onClick={handlePrev}
                      color="blue"
                      text={<LeftArrow sx={{ width: '32px', height: '32px' }} />}
                    />
                  )}
                  <YatungDymanicTabs
                    tabs={updatemixerName.concat(mixerName).slice(startIndex, startIndex + batchSize)}
                    selectedIndex={tabValue}
                    onChange={handleChange}
                    fontSize={20}
                  />
                  {!(startIndex + batchSize >= updatemixerName.concat(mixerName).length) &&
                    updatemixerName.concat(mixerName).length > 5 && (
                      <YatungButton
                        onClick={handleNext}
                        color="blue"
                        text={<RightArrow sx={{ width: '32px', height: '32px' }} />}
                      />
                    )}
                </Grid>
                {updateMixerData.mixers.concat(createMixer).map((value: MixerDetailData, index: number) => {
                  return (
                    <Grid container item xs={12} justifyContent={'flex-end'} key={value.id}>
                      <>
                        {updatemixerName.length > 0 && index === tabValue && (
                          <UpdateMixerDetailsSettings
                            value={value}
                            index={index}
                            updateMixerData={updateMixerData}
                            createMixer={createMixer}
                            setUpdateMixerData={setUpdateMixerData}
                            setCreateMixer={setCreateMixer}
                            proportionOptions={proportionOptions}
                          />
                        )}
                      </>
                    </Grid>
                  );
                })}
              </Paper>
            </Box>
          </Grid>
        )}
      </Grid>
      <Stack justifyContent="flex-end" flexDirection="row" sx={{ pr: 4, mt: 5 }}>
        <YatungButton
          text={i18T('GLOBAL.CANCEL')}
          color="error"
          variant="contained"
          size="large"
          onClick={handleGoBack}
          sx={{
            color: '#ffffff',
            fontSize: '36px',
            p: 2,
            mr: 5,
          }}
        />
        <YatungButton
          disabled={Number.isNaN(+waterDemand)}
          text={i18T('GLOBAL.FINISH')}
          color="blue"
          variant="contained"
          size="large"
          onClick={handleSubmit}
          sx={{
            color: '#ffffff',
            fontSize: '36px',
            p: 2,
          }}
        />
      </Stack>
    </>
  );
}
