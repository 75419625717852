import React from 'react';
import OrganizeManagementCard from '../components/OrganizeManagementCard';

export default function OrganizeManagementScreen() {
  return (
    <>
      <OrganizeManagementCard />
    </>
  );
}
