import { AddBox } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Grid, Paper, SxProps } from '@mui/material';
import React, { CSSProperties } from 'react';
import { JsxElement } from 'typescript';

interface Props {
  body?: any;
  actions?: JSX.Element;
  style?: CSSProperties;
  sx?: SxProps;
}
export default function YatungPaper(props: Props) {
  const { body, actions } = props;
  return (
    <Card sx={{ borderRadius: 5, width: 620, height: 560, m: 1, ...props.sx }}>
      <CardHeader sx={{ bgcolor: '#BB5E00' }} />
      <CardContent>
        <Box>
          {body}
          {actions}
        </Box>
      </CardContent>
    </Card>
  );
}
