import React, { CSSProperties, useMemo } from 'react';
import YatungButton, { Props as YatungButtonProps } from '../../YatungButton';

export interface DisplayProps {
  label: string;
  backgroundColor: YatungButtonProps['color'];
  activeBackgroundColor: YatungButtonProps['color'];
}

interface Props extends DisplayProps {
  index?: number;
  activedIndex?: number;
  lastIndex?: number;
  onClick?: (value: any) => void;
}

export default function Tab(props: Props) {
  const isActived = useMemo(() => {
    return props.activedIndex === props.index;
  }, [props.index, props.activedIndex]);

  return (
    <YatungButton
      text={props.label}
      color={isActived ? props.activeBackgroundColor : props.backgroundColor}
      sx={{
        height: isActived ? '44px' : '36px',
        borderRadius: '0px',
        borderTopLeftRadius: isActived || props.index === 0 ? '10px' : '0px',
        borderTopRightRadius: isActived || props.index === props.lastIndex ? '10px' : '0px',
        boxShadow: 'none',
        textIndent: '0.3em',
        letterSpacing: '0.3em',
      }}
      onClick={() => {
        props.onClick?.(props.index);
      }}
    />
  );
}
