import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FactoryData, PostFactoryDataRequest } from '@Src/_basic/object/FactoryType';
import { FactoryApi } from '@Src/_basic/protocol/factoryManagement/FactoryApi';
import { useNavigate } from 'react-router';
import FactoryManagementCreateSubmit from '../FactoryManagementCreateForm/components/FactoryManagementCreateSubmit';
import FactoryManagementCreateField from './components/FactoryManagementCreateField';
import FactoryManagementErrorMessage from './components/FactoryManagementErrorMessage';

interface Props {
  initialValues: PostFactoryDataRequest;
}
export const factorySchema = Yup.object().shape({
  areaId: Yup.number().positive('請輸入區域'),
  factoryName: Yup.string().required('請輸入廠名'),
  factoryAddress: Yup.string().required('請輸入地址'),
  factoryPhone: Yup.string()
    .max(13, '聯絡電話最多10碼')
    .required('請輸入聯絡電話')
    .matches(/(\(\d{2,3}\))\d{3,4}-?\d{4}/, '範例:(07)2121-1212'),
  factoryCode: Yup.string()
    .max(1, '請輸入一個大寫字母')
    .required('請輸入大寫字母')
    .matches(/^(?=.*[A-Z])/, '請輸入大寫字母'),
  branchCode: Yup.string()
    .max(2, '請輸入0~99數字')
    .required('請輸入數字')
    .matches(/^(?=.*[0-9])/, '請輸入數字'),
});
export default function FactoryManagementCreateForm(props: Props) {
  const { initialValues } = props;
  const history = useNavigate();
  const [warningMoalOpen, setWarningMoalOpen] = useState<boolean>(false);

  const handleWarningModalClose = () => {
    setWarningMoalOpen(false);
  };

  const handleGoBack = () => {
    history(-1);
  };

  const handleSubmit = (request: PostFactoryDataRequest) => {
    FactoryApi.PostFactory(
      request,
      (data: FactoryData[]) => {
        handleGoBack();
      },
      () => {
        setWarningMoalOpen(true);
      },
      undefined,
    );
  };

  return (
    <>
      <FactoryManagementErrorMessage onWarningModalClose={handleWarningModalClose} warningMoalOpen={warningMoalOpen} />
      <Formik initialValues={initialValues} validationSchema={factorySchema} onSubmit={handleSubmit} enableReinitialize>
        {(formik) => {
          return (
            <Form>
              <Grid container spacing={6}>
                <FactoryManagementCreateField />
              </Grid>
              <Grid container justifyContent={'flex-end'} item xs={12} sx={{ pr: 4, mt: 25 }}>
                <FactoryManagementCreateSubmit onSubmit={formik.handleSubmit} onGoBack={handleGoBack} />
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
