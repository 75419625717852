import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DeliveryInformationConstructionSiteName(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="41" height="35" viewBox="0 0 41 35" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6471 4.83325C40.6496 3.12969 39.3968 1 37.3943 1H1L2.57175 3.32185C2.87289 3.767 3.38114 4.03465 3.92623 4.03465H32.8658C34.3283 4.03465 35.2431 5.55323 34.5112 6.83191L22.2663 28.2314C21.5344 29.5101 19.7048 29.5101 18.9729 28.2314L8.26539 9.6248H14.7786C15.4037 9.6248 15.9729 9.27324 16.2436 8.72077L17.3668 6.43043H2.56667L18.3795 33.343C19.3807 35.0466 21.8851 35.0466 22.8864 33.343L39.6471 4.83325ZM18.7273 33.1386C19.5726 34.5768 21.6933 34.5768 22.5386 33.1386L39.2993 4.62878C40.1369 3.20537 39.0978 1.40345 37.3943 1.40345H1.76031L2.90585 3.09568C3.13075 3.42814 3.51292 3.63121 3.92623 3.63121H32.8658C34.6275 3.63121 35.7525 5.47541 34.8614 7.03228C34.8614 7.0323 34.8614 7.03227 34.8614 7.03228L22.6165 28.4318C21.7297 29.981 19.51 29.9812 18.623 28.4322C18.6229 28.4321 18.6231 28.4324 18.623 28.4322L7.56773 9.22136H14.7786C15.2527 9.22136 15.6797 8.95482 15.8813 8.54328L16.7196 6.83388H3.27166L18.7273 33.1386Z"
          fill="#7C878E"
        />
        <path
          d="M34.8614 7.03228C35.7525 5.47541 34.6275 3.63121 32.8658 3.63121H3.92623C3.51292 3.63121 3.13075 3.42814 2.90585 3.09568L1.76031 1.40345H37.3943C39.0978 1.40345 40.1369 3.20537 39.2993 4.62878L22.5386 33.1386C21.6933 34.5768 19.5726 34.5768 18.7273 33.1386L3.27166 6.83388H16.7196L15.8813 8.54328C15.6797 8.95482 15.2527 9.22136 14.7786 9.22136H7.56773L18.623 28.4322M34.8614 7.03228C34.8614 7.03227 34.8614 7.0323 34.8614 7.03228ZM34.8614 7.03228L22.6165 28.4318C21.7297 29.981 19.51 29.9812 18.623 28.4322M18.623 28.4322C18.6231 28.4324 18.6229 28.4321 18.623 28.4322ZM39.6471 4.83325C40.6496 3.12969 39.3968 1 37.3943 1H1L2.57175 3.32185C2.87289 3.767 3.38114 4.03465 3.92623 4.03465H32.8658C34.3283 4.03465 35.2431 5.55323 34.5112 6.83191L22.2663 28.2314C21.5344 29.5101 19.7047 29.5101 18.9729 28.2314L8.26539 9.6248H14.7786C15.4037 9.6248 15.9729 9.27324 16.2436 8.72077L17.3668 6.43043H2.56667L18.3795 33.343C19.3807 35.0466 21.8851 35.0466 22.8864 33.343L39.6471 4.83325Z"
          stroke="#7C878E"
          strokeWidth="0.5"
        />
      </svg>
    </SvgIcon>
  );
}
