import {
  ChildrenTypography,
  TableHeaderTitle,
  TestCardContainer,
  TestVerifyResultStyles,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { AllTest, ExperimentResultType } from '@Src/_basic/object/TestType';
import {
  CoarseSieve,
  CoarseSieveLegalTypeEnum,
  ImportCoarseSieve,
} from '@Src/_basic/object/test/sandRock/CoarseSieveType';
import { PutFineSieveRequest } from '@Src/_basic/object/test/sandRock/FineSieveType';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: CoarseSieve;
  type: CoarseSieveLegalTypeEnum;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  wetWeight: basicNumberSchema,
  beforeRinseDryWeight: basicNumberSchema,
  afterRinseDryWeight: basicNumberSchema,
  poreDiameter1: basicNumberSchema,
  poreDiameter2: basicNumberSchema,
  poreDiameter3: basicNumberSchema,
  poreDiameter4: basicNumberSchema,
  poreDiameter5: basicNumberSchema,
  poreDiameter6: basicNumberSchema,
  poreDiameter7: basicNumberSchema,
  poreDiameter8: basicNumberSchema,
  underPan: basicNumberSchema,
});

function ExportCoarseSieveTable({ testDetail, type, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();
  const [legalStandardType1, setLegalStandardType1] =
    useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();
  const [legalStandardType2, setLegalStandardType2] =
    useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();
  const [edit, setEdit] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      wetWeight: testDetail?.wetWeight,
      beforeRinseDryWeight: testDetail?.beforeRinseDryWeight,
      afterRinseDryWeight: testDetail?.afterRinseDryWeight,
      poreDiameter1: testDetail?.poreDiameter1,
      poreDiameter2: testDetail?.poreDiameter2,
      poreDiameter3: testDetail?.poreDiameter3,
      poreDiameter4: testDetail?.poreDiameter4,
      poreDiameter5: testDetail?.poreDiameter5,
      poreDiameter6: testDetail?.poreDiameter6,
      poreDiameter7: testDetail?.poreDiameter7,
      poreDiameter8: testDetail?.poreDiameter8,
      underPan: testDetail?.underPan,
    }),
    [testDetail],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportCoarseSieve = {
        wetWeight: checkIsNaN(+values.wetWeight),
        beforeRinseDryWeight: checkIsNaN(+values.beforeRinseDryWeight),
        afterRinseDryWeight: checkIsNaN(+values.afterRinseDryWeight),
        poreDiameter1: checkIsNaN(+values.poreDiameter1),
        poreDiameter2: checkIsNaN(+values.poreDiameter2),
        poreDiameter3: checkIsNaN(+values.poreDiameter3),
        poreDiameter4: checkIsNaN(+values.poreDiameter4),
        poreDiameter5: checkIsNaN(+values.poreDiameter5),
        poreDiameter6: checkIsNaN(+values.poreDiameter6),
        poreDiameter7: checkIsNaN(+values.poreDiameter7),
        poreDiameter8: checkIsNaN(+values.poreDiameter8),
        underPan: checkIsNaN(+values.underPan),
      };

      TestApi.putTest<PutFineSieveRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const getLegalStandardText = useCallback(
    (key: string) => {
      switch (type) {
        case CoarseSieveLegalTypeEnum.TYPE1:
          if (legalStandardType1?.get(key)?.minValue != null && legalStandardType1?.get(key)?.maxValue != null) {
            return `${legalStandardType1?.get(key)?.minValue} ~ ${legalStandardType1?.get(key)?.maxValue}`;
          } else {
            if (legalStandardType1?.get(key)?.minValue != null) {
              return `>=${legalStandardType1?.get(key)?.minValue}`;
            } else if (legalStandardType1?.get(key)?.maxValue != null) {
              return `<=${legalStandardType1?.get(key)?.maxValue}`;
            } else {
              return '';
            }
          }
        case CoarseSieveLegalTypeEnum.TYPE2:
          if (legalStandardType2?.get(key)?.minValue != null && legalStandardType2?.get(key)?.maxValue != null) {
            return `${legalStandardType2?.get(key)?.minValue} ~ ${legalStandardType2?.get(key)?.maxValue}`;
          } else {
            if (legalStandardType2?.get(key)?.minValue != null) {
              return `>=${legalStandardType2?.get(key)?.minValue}`;
            } else if (legalStandardType2?.get(key)?.maxValue != null) {
              return `<=${legalStandardType2?.get(key)?.maxValue}`;
            } else {
              return '';
            }
          }
      }
    },
    [legalStandardType1, legalStandardType2, type],
  );

  const isLegalPassed = useMemo(() => {
    switch (type) {
      case CoarseSieveLegalTypeEnum.TYPE1:
        return isLegalStandardPassed(testDetail?.verifyPassedResults?.type1);
      case CoarseSieveLegalTypeEnum.TYPE2:
        return isLegalStandardPassed(testDetail?.verifyPassedResults?.type2);
      default:
        return true;
    }
  }, [testDetail?.verifyPassedResults, type]);

  const isCustomPassed = useMemo(() => {
    switch (type) {
      case CoarseSieveLegalTypeEnum.TYPE1:
        return isCustomStandardPassed(testDetail?.verifyCustomResults?.type1);
      case CoarseSieveLegalTypeEnum.TYPE2:
        return isCustomStandardPassed(testDetail?.verifyCustomResults?.type2);
      default:
        return true;
    }
  }, [testDetail?.verifyCustomResults, type]);

  const isCustomRejected = useMemo(() => {
    switch (type) {
      case CoarseSieveLegalTypeEnum.TYPE1:
        return isRejected(testDetail?.verifyCustomResults?.type1);
      case CoarseSieveLegalTypeEnum.TYPE2:
        return isRejected(testDetail?.verifyCustomResults?.type2);
      default:
        return true;
    }
  }, [testDetail?.verifyCustomResults, type]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [isLegalPassed, isCustomPassed, isCustomRejected, i18T]);

  const firstHeader = useMemo(
    () => ({
      title: i18T(`TEST.SIEVE_ANALYSIS.SIEVE_NUMBER`),
      values: [
        `${i18T(`TEST.SIEVE_ANALYSIS.ACCUMULATION_SIEVE_WEIGHT`)}(g)`,
        `${i18T(`TEST.SIEVE_ANALYSIS.COARSE.ACCUMULATION_SIEVE_PERCENTAGE`)}(%)`,
      ],
    }),
    [i18T],
  );

  const firstData = useMemo(
    () => [
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.37500um`),
        values: [
          formatNumber(testDetail?.poreDiameter1?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter1Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? testDetail?.verifyPassedResults?.type1['37.5mm'] &&
              testDetail?.verifyCustomResults?.type1['37.5mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal
            : testDetail?.verifyPassedResults?.type2['37.5mm'] &&
              testDetail?.verifyCustomResults?.type2['37.5mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.25000um`),
        values: [
          formatNumber(testDetail?.poreDiameter2?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter2Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? testDetail?.verifyPassedResults?.type1['25.0mm'] &&
              testDetail?.verifyCustomResults?.type1['25.0mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal
            : testDetail?.verifyPassedResults?.type2['25.0mm'] &&
              testDetail?.verifyCustomResults?.type2['25.0mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.19000um`),
        values: [
          formatNumber(testDetail?.poreDiameter3?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter3Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? testDetail?.verifyPassedResults?.type1['19.0mm'] &&
              testDetail?.verifyCustomResults?.type1['19.0mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal
            : testDetail?.verifyPassedResults?.type2['19.0mm'] &&
              testDetail?.verifyCustomResults?.type2['19.0mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.12500um`),
        values: [
          formatNumber(testDetail?.poreDiameter4?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter4Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? testDetail?.verifyPassedResults?.type1['12.5mm'] &&
              testDetail?.verifyCustomResults?.type1['12.5mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal
            : testDetail?.verifyPassedResults?.type2['12.5mm'] &&
              testDetail?.verifyCustomResults?.type2['12.5mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.9500um`),
        values: [
          formatNumber(testDetail?.poreDiameter5?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter5Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? testDetail?.verifyPassedResults?.type1['9.5mm'] && testDetail?.verifyCustomResults?.type1['9.5mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal
            : testDetail?.verifyPassedResults?.type2['9.5mm'] && testDetail?.verifyCustomResults?.type2['9.5mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.4750um`),
        values: [
          formatNumber(testDetail?.poreDiameter6?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter6Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? testDetail?.verifyPassedResults?.type1['4.75mm'] &&
              testDetail?.verifyCustomResults?.type1['4.75mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal
            : testDetail?.verifyPassedResults?.type2['4.75mm'] &&
              testDetail?.verifyCustomResults?.type2['4.75mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.2360um`),
        values: [
          formatNumber(testDetail?.poreDiameter7?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter7Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? testDetail?.verifyPassedResults?.type1['2.36mm'] &&
              testDetail?.verifyCustomResults?.type1['2.36mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal
            : testDetail?.verifyPassedResults?.type2['2.36mm'] &&
              testDetail?.verifyCustomResults?.type2['2.36mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.75um`),
        values: [
          formatNumber(testDetail?.poreDiameter8?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter8Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? testDetail?.verifyPassedResults?.type1['0.075mm'] &&
              testDetail?.verifyCustomResults?.type1['0.075mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal
            : testDetail?.verifyPassedResults?.type2['0.075mm'] &&
              testDetail?.verifyCustomResults?.type2['0.075mm'].passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.UNDER_PAN`),
        values: [
          formatNumber(testDetail?.underPan?.toFixed(4)),
          formatNumber(testDetail?.sievingUnderPanPercentage?.toFixed(4)),
        ],
      },
    ],
    [i18T, testDetail, type],
  );

  const secondHeader = useMemo(
    () => ({
      title: i18T(`TEST.SIEVE_ANALYSIS.NAME`),
      values: [i18T(`TEST.SIEVE_ANALYSIS.VALUE`), i18T(`TEST.BASE.LEGAL_STANDARD`)],
    }),
    [i18T],
  );

  const secondData = useMemo(
    () => [
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.RATE_OF_WEIGHT_LOSS`)}(%)`,
        values: [`${testDetail?.lossPercentage?.toFixed(3)}`, getLegalStandardText('lossPercentage')],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? testDetail?.verifyPassedResults?.type1.lossPercentage &&
              testDetail?.verifyCustomResults?.type1.lossPercentage.passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal
            : testDetail?.verifyPassedResults?.type2.lossPercentage &&
              testDetail?.verifyCustomResults?.type2.lossPercentage.passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal,
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.DIRT_CONTENT`)}(%)`,
        values: [testDetail?.dirtContentRatio?.toFixed(1), getLegalStandardText('dirtContentRatio')],
        valuesTextStyle:
          type === CoarseSieveLegalTypeEnum.TYPE1
            ? testDetail?.verifyPassedResults?.type1.dirtContentRatio &&
              testDetail?.verifyCustomResults?.type1.dirtContentRatio.passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal
            : testDetail?.verifyPassedResults?.type2.dirtContentRatio &&
              testDetail?.verifyCustomResults?.type2.dirtContentRatio.passed
              ? TestVerifyResultStyles.normal
              : TestVerifyResultStyles.abnormal,
      },
    ],
    [i18T, testDetail, type, getLegalStandardText],
  );

  const thirdHeader = useMemo(
    () => ({
      title: i18T(`TEST.SIEVE_ANALYSIS.NAME`),
      values: [i18T(`TEST.SIEVE_ANALYSIS.VALUE`)],
    }),
    [i18T],
  );

  const thirdData = useMemo(
    () => [
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.WET_WEIGHT`)}(g)`,
        values: [testDetail?.wetWeight?.toFixed(1)],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.BEFORE_RINSE_DRY_WEIGHT`)}(g)`,
        values: [testDetail?.beforeRinseDryWeight?.toFixed(1)],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.WATER_WEIGHT`)}(g)`,
        values: [testDetail?.waterWeight?.toFixed(1)],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.WATER_CONTENT`)}W(%)`,
        values: [testDetail?.waterContent?.toFixed(2)],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.WATER_ABSORPTION`)}(%)`,
        values: [testDetail?.waterAbsorption ? testDetail?.waterAbsorption?.toFixed(2) : 2],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.SURFACE_WATER_CONTENT`)}W(%)`,
        values: [testDetail?.surfaceWaterContent?.toFixed(2)],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.AFTER_RINSE_DRY_WEIGHT`)}(g)`,
        values: [testDetail?.afterRinseDryWeight?.toFixed(1)],
      },
    ],
    [i18T, testDetail],
  );

  const fourthHeader = useMemo(
    () => ({
      title: i18T(`TEST.BASE.DETECTION`),
    }),
    [i18T],
  );

  const fourthData = useMemo(
    () => [
      isLegalPassed && isCustomPassed && !isCustomRejected
        ? {
          titles: verifyResultText,
          titlesStyle: [TestVerifyResultStyles.normal],
        }
        : {
          titles: verifyResultText,
          titlesStyle: [
            isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
            isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
          ],
        },
    ],
    [verifyResultText, isLegalPassed, isCustomPassed, isCustomRejected],
  );

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.COARSE_AGG_SIEVE_ANALYSIS,
        effectiveUnixTime: testDetail?.completeTime || Date.now(),
        type: 1,
      },
      (_data) => {
        const legalStandardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          legalStandardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandardType1(legalStandardMap);
      },
    );
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.COARSE_AGG_SIEVE_ANALYSIS,
        effectiveUnixTime: testDetail?.completeTime || Date.now(),
        type: 2,
      },
      (_data) => {
        const legalStandardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          legalStandardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandardType2(legalStandardMap);
      },
    );
  }, [testDetail]);

  const firstTable = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack my={4} gap={2} ml={30}>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pb={2}>
              <TableHeaderTitle>{i18T(`TEST.SIEVE_ANALYSIS.SIEVE_NUMBER`)}</TableHeaderTitle>
              <TableHeaderTitle sx={{ pr: 18 }}>{`${i18T(
                `TEST.SIEVE_ANALYSIS.ACCUMULATION_SIEVE_WEIGHT`,
              )}(g)`}</TableHeaderTitle>
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.WET_WEIGHT`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.wetWeight}
                isError={!!(formik.touched.wetWeight && formik.errors.wetWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('wetWeight', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.BEFORE_RINSE_DRY_WEIGHT`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.beforeRinseDryWeight}
                isError={!!(formik.touched.beforeRinseDryWeight && formik.errors.beforeRinseDryWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('beforeRinseDryWeight', e.target.value)
                }
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.AFTER_RINSE_DRY_WEIGHT`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.afterRinseDryWeight}
                isError={!!(formik.touched.afterRinseDryWeight && formik.errors.afterRinseDryWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('afterRinseDryWeight', e.target.value)
                }
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.37500um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.poreDiameter1}
                isError={!!(formik.touched.poreDiameter1 && formik.errors.poreDiameter1)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter1', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.25000um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.poreDiameter2}
                isError={!!(formik.touched.poreDiameter2 && formik.errors.poreDiameter2)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter2', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.19000um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.poreDiameter3}
                isError={!!(formik.touched.poreDiameter3 && formik.errors.poreDiameter3)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter3', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.12500um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.poreDiameter4}
                isError={!!(formik.touched.poreDiameter4 && formik.errors.poreDiameter4)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter4', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.9500um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.poreDiameter5}
                isError={!!(formik.touched.poreDiameter5 && formik.errors.poreDiameter5)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter5', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.4750um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.poreDiameter6}
                isError={!!(formik.touched.poreDiameter6 && formik.errors.poreDiameter6)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter6', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.2360um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.poreDiameter7}
                isError={!!(formik.touched.poreDiameter7 && formik.errors.poreDiameter7)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter7', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.75um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.poreDiameter8}
                isError={!!(formik.touched.poreDiameter8 && formik.errors.poreDiameter8)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter8', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.UNDER_PAN`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.underPan}
                isError={!!(formik.touched.underPan && formik.errors.underPan)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('underPan', e.target.value)}
              />
            </Stack>
          </Stack>
        );
      case false:
        return <TestTable headerData={firstHeader} rows={firstData} isShowCollapse={true} />;
      default:
        break;
    }
  }, [edit, firstHeader, firstData, formik, i18T]);

  return (
    <Stack gap={2} mt={2}>
      <TestCardContainer>
        <Stack flexDirection="row" justifyContent="flex-end" gap={5} position="absolute" right={0} top={30}>
          <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        </Stack>
        {firstTable}
      </TestCardContainer>

      {!edit && (
        <>
          <TestCardContainer>
            <TestTable headerData={secondHeader} rows={secondData} isShowCollapse={true} />
          </TestCardContainer>
          <TestCardContainer>
            <TestTable headerData={thirdHeader} rows={thirdData} isShowCollapse={true} />
          </TestCardContainer>
        </>
      )}

      <TestCardContainer>
        <TestTable headerData={fourthHeader} rows={fourthData} />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportCoarseSieveTable);
