import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import { SelectChangeEvent, Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import CreateFactory from '@Src/_basic/icons/CreateFactory';
import SearchIcon from '@Src/_basic/icons/Search';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { FilterValueType } from '..';
interface Props {
  areaIds: number;
  searchStr: string;
  removed: number;
  // eslint-disable-next-line no-unused-vars
  handleChange: <K extends keyof FilterValueType>(field: K, value: FilterValueType[K]) => void;
  // eslint-disable-next-line no-unused-vars
  getAllFactory: (searchStr: string) => void;
}

const initialStatusOptions = [
  {
    value: 0,
    text: '全部狀態',
  },
  {
    value: 1,
    text: '運轉中',
  },
  {
    value: 2,
    text: '關閉中',
  },
];

export default function FactoryManagementSearchBar({
  areaIds,
  searchStr,
  removed,
  handleChange,
  getAllFactory,
}: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { actionLoading } = useApi();
  const { userAreaOptionsData } = useAuth();

  const handleGoToCreatePage = useCallback(() => {
    navigate('/UsersManagePage/FactoryManagement/FactoryManagementCreate');
  }, [navigate]);

  return (
    <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={2} sx={{ py: 1 }} alignItems="center">
        <YatungSelect
          helperText={i18T('GLOBAL.AREA')}
          disabled={actionLoading}
          options={[
            {
              value: 0,
              text: '全部區域',
            },
            ...userAreaOptionsData,
          ]}
          value={areaIds}
          onChange={(e: SelectChangeEvent<unknown>) => handleChange('areaIds', e.target.value as number)}
        />
        <YatungSelect
          helperText={'狀態'}
          disabled={actionLoading}
          options={initialStatusOptions}
          value={removed}
          onChange={(e: SelectChangeEvent<unknown>) => handleChange('removed', e.target.value as number)}
        />
        <YatungInput value={searchStr} onChange={(e: SelectChangeEvent) => handleChange('searchStr', e.target.value)} />
        <YatungButton
          text={i18T('GLOBAL.SEARCh_BUTTON')}
          startIcon={<SearchIcon />}
          color="blue"
          onClick={getAllFactory}
        />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <YatungButton
          disabled={actionLoading}
          text={i18T('FACTORYMANAGEMENT.ADD_BUTTON')}
          startIcon={<CreateFactory />}
          onClick={handleGoToCreatePage}
          color={'green'}
          sx={{
            fontSize: '16px',
          }}
        />
      </Stack>
    </Stack>
  );
}
