import YatungButton from '@Src/_basic/components/YatungButton';
import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import SampleLocation from '@Src/_basic/icons/SampleLocation';
import SearchIcon from '@Src/_basic/icons/Search';
import { SampleData, SearchPaginationParams } from '@Src/_basic/object/SampleLocationType';
import { SampleApi } from '@Src/_basic/protocol/sample/SampleApi';
import { TypeApi } from '@Src/_basic/protocol/type/TypeApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import SampleCreateCrad from '../component/SampleCreateCrad';
import SampleManagementTable from '../component/SampleManagementTable';
import SampleUpdateCard from '../component/SampleUpdateCard';

type FilterValueType = {
  factoryId: number;
  areaId: number;
};

type QueryType = FilterValueType & {
  name: string;
};

export default function SampleScreen() {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<QueryType>();

  const initQuery = useMemo<QueryType>(() => {
    const { factoryId = 0, areaId = 0, name = '' } = (location.pathname === request?.page && request?.request) || {};

    return {
      factoryId,
      areaId,
      name,
    };
  }, [request, location.pathname]);

  const [sampleByIdData, setSampleByIdData] = useState<SampleData>({
    id: 0,
    name: '',
    isAnalysis: true,
    removed: false,
    types: [],
  });

  const [{ areaId, factoryId, name }, setQuery] = useState<QueryType>(initQuery);

  const [sampleData, setSampleData] = useState<SampleData[]>([]);
  const [types, setTypes] = useState<Options[]>([]);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<'CREATE' | 'UPDATE' | 'DELETE' | null>(null);

  const params: SearchPaginationParams = useMemo(
    () => ({
      ...(name && { name }),
      factoryId: factoryId,
      isAnalysis: true,
      removed: false,
    }),
    [name, factoryId],
  );

  const getAllTypes = useCallback(() => {
    TypeApi.getAllTypes((data) => setTypes(data.map((type) => ({ value: type.id, text: type.name }))));
  }, []);

  const reset = useCallback(() => {
    setIsVisible(false);
    setSampleData([]);
  }, [setSampleData]);

  const handleModelOpen = useCallback((modal: 'CREATE' | 'UPDATE' | 'DELETE') => {
    setModalOpen(modal);
  }, []);

  const handleModelClose = useCallback(() => {
    setModalOpen(null);
  }, []);

  const getSampleData = useCallback(() => {
    SampleApi.geAllSample(params, (_data) => {
      setIsVisible(true);
      setSaveRequest({ page: location.pathname, request: { areaId, factoryId, name } });
      setSampleData(_data.map((item, index) => ({ ...item, number: index + 1 })));
    });
  }, [name, factoryId, areaId, location.pathname, setSaveRequest, params]);

  const handleUpdateAndDelete = useCallback(
    (id: number, modal: 'UPDATE' | 'DELETE') => {
      setModalOpen(modal);
      const selectedById = sampleData?.find((i: SampleData) => i.id === id);
      if (selectedById) setSampleByIdData(selectedById);
    },
    [sampleData],
  );

  const handleDelete = useCallback(() => {
    SampleApi.DeleteSample(sampleByIdData.id, () => {
      getSampleData();
      setModalOpen(null);
    });
  }, [sampleByIdData, getSampleData]);

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    setQuery((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    reset();
  }, [areaId, factoryId, name]);

  useEffect(() => {
    getAllTypes();
    if (request?.page === location.pathname && request?.request && request.request.factoryId) {
      getSampleData();
    }
  }, []);

  return (
    <>
      <YatungPage
        title={i18T('SAMPLEMANAGEMENT.PAGE_TITLE')}
        body={
          <>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
                <YatungCombinationSelect<FilterValueType>
                  filterValue={{ areaId, factoryId }}
                  handleChange={handleChange}
                  selectOptions={['AreaAndFactory']}
                />
                <YatungInput value={name} onChange={(e: any) => handleChange('name')(e.target.value)} />
                <YatungButton
                  disabled={!factoryId || actionLoading}
                  text={i18T('GLOBAL.SEARCh_BUTTON')}
                  startIcon={<SearchIcon />}
                  onClick={getSampleData}
                  color="blue"
                />
              </Stack>
              <YatungButton
                disabled={!factoryId || actionLoading}
                text={i18T('SAMPLEMANAGEMENT.CREATE_SAMPLE_BUTTON')}
                startIcon={<SampleLocation />}
                onClick={() => handleModelOpen('CREATE')}
                color={'green'}
              />
            </Stack>
            <YatungFadeInOut isVisible={isVisible}>
              <SampleManagementTable sampleData={sampleData} handleUpdateAndDelete={handleUpdateAndDelete} />
            </YatungFadeInOut>
          </>
        }
        contentBgColor="#FFF"
      />
      <SampleCreateCrad
        createModalOpen={modalOpen === 'CREATE'}
        onCreateGoBackButtonClick={handleModelClose}
        factoryId={factoryId}
        types={types}
        getSampleData={getSampleData}
      />
      <SampleUpdateCard
        updateModalOpen={modalOpen === 'UPDATE'}
        sampleByIdData={sampleByIdData}
        onUpdateGoBackButtonClick={handleModelClose}
        types={types}
        getSampleData={getSampleData}
      />
      <YatungConfirmAlert isShowAlert={modalOpen === 'DELETE'} onClose={handleModelClose} onOk={handleDelete} />
    </>
  );
}
