import { YatungComboBox } from '@Src/_basic/components/YatungComboBox';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { SearchPageableCarbonEmissionParams } from '@Src/_basic/object/CarbonEmissionType';
import { ProportionData, RealPropData } from '@Src/_basic/object/ProductionHistoryType';
import { useApi } from '@Src/redux/api/apiAction';
import { useCarbonEmission } from '@Src/redux/carbonEmission/carbonEmissionActions';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Box, Grid, Pagination, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import StatisticsCard from '../StatisticsCard';
import CarbonEmissionTable from './components/CarbonEmissionTable';

const Title = styled(Typography)(() => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

const initFilterValue = {
  startTime: new Date().getTime() - 1000 * 60 * 60 * 24 * 30,
  endTime: new Date().getTime(),
  proportionIds: [],
};

const initQueryValue = {
  startTime: initFilterValue.startTime,
  endTime: initFilterValue.endTime,
  realPropNames: [],
};

const initPageInfo = {
  page: 1,
  pageSize: 10,
  total: 0,
  totalCount: 0,
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'updateValue':
      return { ...state, ...action.payload };
    default:
      return { state };
  }
}

const CarbonFootprintLabel = '/media/images/carbonFootprintLabel.jpg';

export default function CarbonEmission() {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { projectInfo } = useProductionHistory();
  const {
    header,
    pageableCarbonEmission,
    getCarbonEmissionHeader,
    getCancelablePageableCarbonEmissionBySearch,
    clearHeader,
    clearPageableCarbonEmission,
  } = useCarbonEmission();
  const [filterValue, filterValueDispatch] = useReducer(reducer, initFilterValue);
  const [query, queryDispatch] = useReducer(reducer, initQueryValue);
  const [pageInfo, pageInfoDispatch] = useReducer(reducer, initPageInfo);

  const opacityStyle = useMemo(
    () => ({
      opacity: actionLoading ? 0.3 : 1,
    }),
    [actionLoading],
  );

  const proportionOptions = useMemo(() => {
    return projectInfo.proportion.map((item: ProportionData) => ({ text: item.displayName, value: item.id }));
  }, [projectInfo]);

  const searchHeaderParams: SearchPageableCarbonEmissionParams = useMemo(
    () => ({
      ...query,
      factoryId: projectInfo.factoryId,
      orderId: projectInfo.orderId,
    }),
    [query, projectInfo],
  );

  const searchTableParams: SearchPageableCarbonEmissionParams = useMemo(
    () => ({
      ...query,
      factoryId: projectInfo.factoryId,
      orderId: projectInfo.orderId,
      page: pageInfo.page,
      size: pageInfo.pageSize,
    }),
    [query, projectInfo, pageInfo.page, pageInfo.pageSize],
  );

  const handleStartDateChange = (value: number) => {
    handleFilterChange('startTime')(value);
    handleQueryChange('startTime')(value);
  };

  const handleEndDateChange = (value: number) => {
    handleFilterChange('endTime')(value);
    handleQueryChange('endTime')(value);
  };

  const handleProportionChange = (value: any) => {
    handleFilterChange('proportionIds')(value);

    const proportionsMap = new Map();

    projectInfo.proportion.forEach((item) => {
      proportionsMap.set(item.id, item.realProps);
    });

    const realPropNames: Array<string> = [];

    value.forEach((id: number) => {
      const realProps = proportionsMap.get(id);

      if (realProps) {
        realProps.forEach((realProp: RealPropData) => {
          realPropNames.push(realProp.proportionName);
        });
      }
    });

    handleQueryChange('realPropNames')(realPropNames);
  };

  const handleFilterChange = (field: string) => async (value: any) => {
    filterValueDispatch({ type: 'updateValue', payload: { [field]: value } });
  };

  const handleQueryChange = (field: string) => async (value: unknown) => {
    queryDispatch({ type: 'updateValue', payload: { [field]: value } });
    pageInfoDispatch({ type: 'updateValue', payload: { page: 1 } });
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({
      type: 'updateValue',
      payload: {
        page,
      },
    });
  };

  useEffect(() => {
    if (pageableCarbonEmission) {
      pageInfoDispatch({
        type: 'updateValue',
        payload: {
          total: pageableCarbonEmission.maxPage,
          totalCount: pageableCarbonEmission.totalElements,
        },
      });
    }
  }, [pageableCarbonEmission]);

  useEffect(() => {
    if (searchHeaderParams.realPropNames.length > 0) {
      getCarbonEmissionHeader(searchHeaderParams);
    } else {
      clearHeader();
    }
  }, [searchHeaderParams]);

  useEffect(() => {
    if (searchTableParams.realPropNames.length > 0) {
      getCancelablePageableCarbonEmissionBySearch(searchTableParams);
    } else {
      clearPageableCarbonEmission();
      pageInfoDispatch({
        type: 'updateValue',
        payload: {
          total: 0,
          totalCount: 0,
          page: 0,
        },
      });
    }
  }, [searchTableParams]);

  return (
    <Box sx={{ padding: '33px 36px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          paddingBottom: '20px',
          borderBottom: '1px solid #000000',
        }}
      >
        <Stack gap="20px">
          <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
            <Title>{i18T('REPORT.PRODUCTION_HISTORY.CARBON_EMISSION.TOOLBAR.TITLE')}</Title>
            <YatungDateSelect
              label={i18T('REPORT.PRODUCTION_HISTORY.CARBON_EMISSION.TOOLBAR.START_DATE')}
              disabled={actionLoading}
              value={filterValue.startTime}
              maxDate={filterValue.endTime}
              disableFuture
              onChange={(val) => val && handleStartDateChange(new Date(val).getTime())}
            />
            <YatungDateSelect
              label={i18T('REPORT.PRODUCTION_HISTORY.CARBON_EMISSION.TOOLBAR.END_DATE')}
              disabled={actionLoading}
              value={filterValue.endTime}
              minDate={filterValue.startTime}
              disableFuture
              onChange={(val) => val && handleEndDateChange(new Date(val).getTime())}
            />
            <YatungComboBox
              loading={actionLoading}
              multiple
              limitTags={1}
              options={proportionOptions}
              defaultValue={filterValue.proportionIds}
              onBlur={(value: any) => {
                handleProportionChange(value);
              }}
            />
          </Stack>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              aluinItems: 'center',
              px: '16px',
              gap: '21px',
            }}
          >
            <StatisticsCard title="進行澆置作業天數" style={opacityStyle} value={Number(header?.date) || 0} unit="天" />
            <StatisticsCard title="使用混凝土方數" style={opacityStyle} value={header?.qty || 0} unit="方" />
            <StatisticsCard title="期間運載車次" style={opacityStyle} value={header?.ship || 0} unit="車" />
            <StatisticsCard
              title="生產碳排放量"
              style={opacityStyle}
              value={header?.carbonEmission || 0}
              unit="頓"
              backgroundColor="#00D03A"
            />
            <StatisticsCard
              title="生命週期碳排放量"
              style={opacityStyle}
              value={header?.carbonEmissionLifeCycle || 0}
              unit="頓"
              backgroundColor="#00D03A"
            />
          </Box>
        </Stack>
        <Box>
          <Box sx={{ border: '1px #35825D solid' }}>
            <img src={CarbonFootprintLabel} height="141px" width="116px" />
          </Box>
        </Box>
      </Box>
      <Box sx={{ px: '16px', py: '6px' }}>
        <CarbonEmissionTable rows={pageableCarbonEmission?.data || []} />
        <Grid container alignItems={'center'} item xs={12} sx={{ p: 1 }}>
          <Grid container justifyContent={'center'} item xs={11}>
            <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
          </Grid>
          <Grid container justifyContent={'flex-end'} item xs={1}>
            <YatungPaperLabel label={`總數：${pageInfo.totalCount}`} totalCount />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
