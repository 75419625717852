import YatungAlert from '@Src/_basic/components/YatungAlert';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungImg from '@Src/_basic/components/YatungImg';
import { Options } from '@Src/_basic/components/YatungSelect';
import { CreateCustomUnitRequest, InitialWeightUnitFormData, UnitData } from '@Src/_basic/object/WeightUnitManageType';
import { WeightUnitManageApi } from '@Src/_basic/protocol/WeightUnitManage/WeightUnitManageApi';
import { Stack } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import CreateUnitField from './CreateUnitField';

const createImage = '/media/images/create.png';

interface Props {
  isUnitDialog: boolean;
  selectedFactory: number;
  types: Array<Options>;
  onUnitGoBackButtonClick: () => void;
  setWeightUnit: (data: Array<UnitData>) => void;
  avaliableOptions: Array<Options>;
}
export const initialValues: InitialWeightUnitFormData = {
  name: '車',
  typeId: 0,
  intlUnits: [
    {
      intlUnitId: 0,
      intlUnitValue: 1000,
    },
  ],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('請輸入單位名稱'),
  typeId: Yup.number().positive('請選擇對應單位').min(1, '請選擇對應單位'),
  intlUnits: Yup.array().of(
    Yup.object().shape({
      intlUnitId: Yup.number().positive('請選擇名稱'),
      intlUnitValue: Yup.number().min(1, '請輸入大於1的值').required('請輸入值'),
    }),
  ),
});
export default function CreateUnitForm(props: Props) {
  const { t: i18T } = useTranslation();
  const { isUnitDialog, onUnitGoBackButtonClick, types, selectedFactory, setWeightUnit, avaliableOptions } = props;
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const handleUnitGoBackButtonClick = () => {
    onUnitGoBackButtonClick();
  };
  const handleSaveSubmit = (require: InitialWeightUnitFormData) => {
    const requires: CreateCustomUnitRequest = { ...require, factoryId: selectedFactory };
    WeightUnitManageApi.createUnitCustom(
      requires,
      () => {
        onUnitGoBackButtonClick();
        WeightUnitManageApi.getUnitCustomBySearch({ factoryId: selectedFactory }, setWeightUnit);
      },
      () => {
        setAlertOpen(true);
      },
    );
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(value: InitialWeightUnitFormData) => handleSaveSubmit(value)}
        enableReinitialize
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form>
            <FieldArray name="intlUnits">
              {(arrayHelpers) => {
                return (
                  <YatungDialog
                    isMeasures
                    open={isUnitDialog}
                    handleClose={onUnitGoBackButtonClick}
                    icon={
                      <YatungImg
                        img={createImage}
                        sx={{
                          width: 28,
                          height: 28,
                          zIndex: 999,
                          alignSelf: 'center',
                          display: 'flex',
                          textAlign: 'center',
                          mr: 1,
                        }}
                      />
                    }
                    title={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.CREATE_UNIT')}
                    action={
                      <Stack direction="row" spacing={2}>
                        <YatungButton
                          text={i18T('GLOBAL.CANCEL')}
                          onClick={handleUnitGoBackButtonClick}
                          color="red"
                          sx={{
                            fontSize: '20px',
                            width: 75,
                            alignSelf: 'center',
                          }}
                        />
                        <YatungButton
                          text={i18T('GLOBAL.FINISH')}
                          color="green"
                          onClick={handleSubmit}
                          sx={{
                            fontSize: '20px',
                            width: 75,
                            alignSelf: 'center',
                          }}
                        />
                      </Stack>
                    }
                    body={
                      <Stack>
                        <CreateUnitField
                          types={types}
                          arrayHelpers={arrayHelpers}
                          values={values}
                          setFieldValue={setFieldValue}
                          isUnitDialog={isUnitDialog}
                          avaliableOptions={avaliableOptions}
                        />
                      </Stack>
                    }
                  />
                );
              }}
            </FieldArray>
          </Form>
        )}
      </Formik>
      <YatungAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        title={i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.ALERT_MESSAGE')}
        type="error"
      />
    </>
  );
}
