import React from 'react';
import TotalPageTitle from '@Src/_basic/components/YatungPageTitle';
import { useTranslation } from 'react-i18next';
import RemainingKPIBi from '../components/RemainingKPIBi';

export default function RemainingKPIScreen() {
  const { t: i18T } = useTranslation();
  return (
    <>
      <TotalPageTitle title={i18T('POWERBI.RECLAIMED_WATER_AND_STONE')} />
      <RemainingKPIBi />
    </>
  );
}
