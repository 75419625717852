import React from 'react';
import TotalPageTitle from '@Src/_basic/components/YatungPageTitle';
import { useTranslation } from 'react-i18next';
import WaterDecadeBi from '../components/WaterDecadeBi';

export default function WaterDecadeScreen() {
  const { t: i18T } = useTranslation();
  return (
    <>
      <TotalPageTitle title={i18T('POWERBI.WATER_DECADE')} />
      <WaterDecadeBi />
    </>
  );
}
