import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { testInDistrictSlice } from './testInDistrictSlice';

export function useTestDistrict() {
  const { date } = useSelector((state: RootState) => state.testInDistrictDate);
  const dispatch = useDispatch();
  const setDate = (_date: any) => dispatch(testInDistrictSlice.actions.setDate(_date));
  return {
    date,
    setDate,
  };
}
