import { Typography } from '@mui/material';
import { FactoryData } from '@Src/_basic/object/FactoryType';
import React from 'react';

interface Props {
  row: FactoryData;
}
export default function SymbolColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.factoryCode + row.branchCode}</Typography>;
}
