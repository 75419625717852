import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Name(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.1,22.2C12.1,22.2,12.1,22.2,12.1,22.2c-0.6,0-1.2-0.3-1.6-0.9L0.9,5h9.6L9.7,6.7C9.5,7.1,9.1,7.4,8.6,7.4H5.2l6.2,10.7c0.3,0.6,1.2,0.6,1.5,0l7.3-12.7c0.2-0.3,0.2-0.6,0-0.9c-0.2-0.3-0.4-0.4-0.8-0.4H2.1c-0.4,0-0.8-0.2-1-0.5l-1.2-1.8H22c0.7,0,1.2,0.3,1.6,0.9c0.3,0.6,0.3,1.2,0,1.8l-10,17C13.3,21.9,12.7,22.2,12.1,22.2z M2.2,5.7l9,15.3c0.2,0.3,0.5,0.5,0.9,0.5s0.7-0.2,0.9-0.5L23,4c0.2-0.3,0.2-0.7,0-1c-0.2-0.3-0.5-0.5-0.9-0.5H1.3l0.4,0.6C1.8,3.2,2,3.3,2.1,3.3h17.2c0.6,0,1.1,0.3,1.4,0.8c0.3,0.5,0.3,1.1,0,1.6l-7.3,12.7c-0.3,0.5-0.8,0.8-1.4,0.8c-0.6,0-1.1-0.3-1.4-0.8L3.9,6.6h4.7c0.2,0,0.4-0.1,0.4-0.3l0.3-0.7H2.2z" />
    </SvgIcon>
  );
}
