import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { ReportGroupsData } from '@Src/_basic/object/AccountRightType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { ReportGroupApi } from '@Src/_basic/protocol/accountRights/reportGroup/ReportGroupApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Grid, Pagination } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ReportGroupManagementSearchBar from './ReportGroupManagementSearchBar';
import ReportGroupManagementTable from './ReportGroupManagementTable';

export type Query = {
  keyword: string;
  factoryId: number;
};

type RequestSaving = Query & {
  page: number;
};

export default function ReportGroupManagementCard() {
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<RequestSaving>();

  const initPageInfo = useMemo(
    () => ({
      page: location.pathname === request?.page && request?.request ? request.request.page : 1,
      pageSize: 20,
      total: 0,
      totalCount: 0,
    }),
    [location.pathname, request],
  );

  const initQuery = useMemo(() => {
    const { keyword = '', factoryId = 0 } =
      location.pathname === request?.page && request?.request ? request.request : {};

    return {
      keyword,
      factoryId,
    };
  }, [location.pathname, request]);

  const effectRef = useRef<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [query, queryDispatch] = useReducer<Reducer<Query, Partial<Query>>>(updateReducer, initQuery);
  const [pageInfo, pageDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const [groupData, setGroupData] = useState<Array<ReportGroupsData>>([]);

  const getReportGroup = useCallback(
    (page: number) => {
      const { factoryId, keyword } = query;
      ReportGroupApi.getPageableReportGroupsBySearch(
        {
          page: page - 1,
          size: pageInfo.pageSize,
          removed: false,
          ...(factoryId && { factoryId }),
          ...(keyword && { keyword }),
        },
        (_data) => {
          setGroupData(
            _data.data.map((item) => ({
              ...item,
              memberList: item.users
                .map((users) => {
                  return `${users.accountName} ${users.jobTitle};`;
                })
                .join(''),
            })),
          );
          pageDispatch({
            page,
            total: _data.maxPage,
          });
          setSaveRequest({
            page: location.pathname,
            request: {
              page,
              keyword,
              factoryId,
            },
          });
          setIsVisible(true);
        },
      );
    },
    [pageInfo, query, location.pathname, setSaveRequest],
  );

  const handleChange = useCallback((file: keyof Query, value: Query[keyof Query]) => {
    queryDispatch({ [file]: value });
    pageDispatch({ page: 1 });
    setIsVisible(false);
  }, []);

  const handlePageChange = useCallback(
    (e: React.ChangeEvent<unknown>, page: number) => {
      pageDispatch({ page });
      getReportGroup(page);
    },
    [getReportGroup],
  );

  const getCurrentPageReportGroup = useCallback(() => getReportGroup(pageInfo.page), [getReportGroup, pageInfo.page]);

  useEffect(() => {
    if (!effectRef.current) {
      effectRef.current = true;
      getCurrentPageReportGroup();
    }
  }, [effectRef]);

  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.REPORT_GROUP_MANAGEMENT.ROUTE_TITLE')}
      body={
        <>
          <ReportGroupManagementSearchBar
            query={query}
            handleChange={handleChange}
            getReportGroup={getCurrentPageReportGroup}
          />
          {
            <YatungFadeInOut isVisible={isVisible}>
              <>
                <ReportGroupManagementTable groupData={groupData} getReportGroup={getCurrentPageReportGroup} />
                <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2 }}>
                  <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
                </Grid>
              </>
            </YatungFadeInOut>
          }
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
