import YatungDialog from '@Src/_basic/components/YatungDialog';
import { Options } from '@Src/_basic/components/YatungSelect';
import EditV2 from '@Src/_basic/icons/EditV2';
import { SampleData, UpdateSampleRequest } from '@Src/_basic/object/SampleLocationType';
import { SampleApi } from '@Src/_basic/protocol/sample/SampleApi';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import SampleUpdateField from './component/SampleUpdateField';

interface Props {
  updateModalOpen: boolean;
  onUpdateGoBackButtonClick: () => void;
  sampleByIdData: SampleData;
  getSampleData: () => void;
  types: Options[];
}

const updateSampleSchema = Yup.object().shape({
  name: Yup.string().required('請輸入取樣位置名稱'),
  typeIds: Yup.number().typeError('請選擇類別').min(1, '請選擇至少一個類別').required('請選擇類別'),
});

export default function SampleUpdateCard(props: Props) {
  const { t: i18T } = useTranslation();

  const { updateModalOpen, onUpdateGoBackButtonClick, sampleByIdData, getSampleData, types } = props;

  const initialValues: UpdateSampleRequest = useMemo(
    () => ({
      id: sampleByIdData.id,
      name: sampleByIdData.name,
      typeIds: sampleByIdData?.types?.map((type) => type.id),
    }),
    [sampleByIdData],
  );

  const handleSubmit = useCallback(
    (request: UpdateSampleRequest) => {
      SampleApi.UpdateSample(request, () => {
        getSampleData();
        onUpdateGoBackButtonClick();
      });
    },
    [getSampleData, onUpdateGoBackButtonClick],
  );

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={updateModalOpen}
        handleClose={onUpdateGoBackButtonClick}
        icon={<EditV2 sx={{ width: 40, height: 40, mr: 1, alignSelf: 'center' }} />}
        title={i18T('SAMPLEMANAGEMENT.PAGE_UPDATE_TITLE')}
        body={
          <Formik
            initialValues={initialValues}
            validationSchema={updateSampleSchema}
            onSubmit={(request: UpdateSampleRequest) => handleSubmit(request)}
            enableReinitialize
          >
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  <SampleUpdateField
                    values={values}
                    setValues={setFieldValue}
                    onUpdateGoBackButtonClick={onUpdateGoBackButtonClick}
                    types={types}
                  />
                </Form>
              );
            }}
          </Formik>
        }
      />
    </Grid>
  );
}
