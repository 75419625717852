import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import BackButton from '@Src/_basic/icons/BackButton';
import { RequireItemList } from '@Src/_basic/object/TestInDistrictType';
import { useApi } from '@Src/redux/api/apiAction';
import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import TestInDistrictByTestListTable from '../components/TestInDistrictByTestListTable';

export default function TestInDistrictByDetailSreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { row, lastFactoryName },
  } = useLocation();
  const { actionLoading } = useApi();
  console.log(actionLoading);
  // const [pageInfo, setPageInfo] = useState<PageInfo>({
  //   page: 1,
  //   pageSize: 10,
  //   total: 0,
  //   totalCount: 0,
  // });

  // const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
  //   setPageInfo({
  //     ...pageInfo,
  //     page,
  //   });
  // };
  const handleGoBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    row.requireItemList.forEach((item: RequireItemList) => {
      item.createTime = row.createTime;
      item.createrName = row.createrName;
      item.holderName = row.holderName;
    });
  }, [row.createTime, row.createrName, row.holderName, row.requireItemList]);
  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.TITLE') + `(${lastFactoryName})`}
      body={
        <>
          <Stack direction="row" justifyContent="flex-end">
            <Stack direction="row" spacing={2} sx={{ py: 1 }}>
              <YatungButton
                disabled={actionLoading}
                text={i18T('GLOBAL.BACK_PAGE')}
                color="green"
                onClick={handleGoBack}
                startIcon={<BackButton sx={{ width: '25px', height: '25px' }} />}
                sx={{ alignSelf: 'center' }}
              />
            </Stack>
          </Stack>

          <TestInDistrictByTestListTable loading={actionLoading} data={row.requireItemList} />
          {/* <Grid container justifyContent={'center'} item xs={12} sx={{ mt: 2 }}>
            <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
          </Grid> */}
        </>
      }
      contentBgColor="#fff"
    />
  );
}
