import { Checkbox, FormControl, FormControlLabel, FormGroup, Stack, SxProps, Typography } from '@mui/material';
import React, { CSSProperties } from 'react';
import { Options } from './YatungFormSelect';

interface Props {
  options: Array<Options>;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  initialCheckedValue?: Array<number>;
  labelTextStyle?: CSSProperties;
  sx?: SxProps;
  isFactory?: boolean;
  Date?: any;
  optionComponent?: Array<React.ReactNode>; // 額外對應的組件
  column?: boolean;
}
export default function YatungCheckBox(props: Props) {
  const { options, onChange, initialCheckedValue, sx, isFactory, Date, onBlur, optionComponent, column } = props;
  const getChecked = (id: number) => {
    if (initialCheckedValue) {
      return initialCheckedValue.includes(id);
    } else {
      return false;
    }
  };
  return (
    <FormControl>
      <FormGroup row={true} sx={{ flexDirection: column ? 'column' : 'row' }}>
        {options.map((option: any, index: number) => (
          <React.Fragment key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  value={option.value}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={option.disabled}
                  checked={getChecked?.(option.value)}
                />
              }
              label={
                optionComponent ? (
                  <Stack flexDirection={'row'} alignItems="center" key={option.value}>
                    <Typography sx={{ flex: 1 }}>{option.text}</Typography>
                    <Stack sx={{ flex: 1 }}>{optionComponent[index]}</Stack>
                  </Stack>
                ) : getChecked?.(option.value) && isFactory ? (
                  <>
                    <Stack flexDirection={'row'}>
                      <Typography sx={{ fontSize: 24, ...props.labelTextStyle }}>{option.text}</Typography>
                      <Typography
                        sx={{ fontWeight: 400, fontSize: 8, color: '#6C708C', alignSelf: 'flex-end', mb: 1, ml: 1 }}
                      >
                        {option.remainDays === undefined ? `60天` : `${option.remainDays}天`}
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <Typography sx={{ fontSize: 24, ...props.labelTextStyle }}>{option.text}</Typography>
                )
              }
              sx={sx || { mr: 5 }}
            />
          </React.Fragment>
        ))}
      </FormGroup>
    </FormControl>
  );
}
