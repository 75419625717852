import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function DistrictIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="84" height="86" viewBox="0 0 84 86" fill="none">
      <g filter="url(#filter0_d_295_39124)">
        <path
          d="M41.6928 45.3006C57.4022 45.3006 70.1355 41.3647 70.1355 36.5092C70.1355 31.6536 57.4022 27.7178 41.6928 27.7178C25.9833 27.7178 13.25 31.6536 13.25 36.5092C13.25 41.3647 25.9833 45.3006 41.6928 45.3006Z"
          fill="#C8D6DC"
        />
        <path
          d="M41.6928 72.1936C57.4022 72.1936 70.1355 68.2577 70.1355 63.4022C70.1355 58.5467 57.4022 54.6108 41.6928 54.6108C25.9833 54.6108 13.25 58.5467 13.25 63.4022C13.25 68.2577 25.9833 72.1936 41.6928 72.1936Z"
          fill="#C8D6DC"
        />
        <path
          d="M14.2773 14.4282V67.829C14.2773 72.4859 26.4726 76.2608 41.5183 76.2608C56.564 76.2608 68.7592 72.4859 68.7592 67.829V14.4282H14.2773Z"
          fill="#9DA6AA"
        />
        <g filter="url(#filter1_d_295_39124)">
          <path
            d="M70.1355 35.9946C70.1355 41.2588 57.4022 45.5273 41.6928 45.5273C25.9833 45.5273 13.25 41.2588 13.25 35.9946V43.0105C13.25 48.2747 25.9833 52.5431 41.6928 52.5431C57.4022 52.5431 70.1355 48.2747 70.1355 43.0105V35.9946Z"
            fill="#C8D6DC"
          />
        </g>
        <path
          d="M70.1355 62.8843C70.1355 68.1485 57.4022 72.4169 41.6928 72.4169C25.9833 72.4169 13.25 68.1485 13.25 62.8843V69.9002C13.25 75.1643 25.9833 79.4328 41.6928 79.4328C57.4022 79.4328 70.1355 75.1643 70.1355 69.9002V62.8843Z"
          fill="#C8D6DC"
        />
        <g filter="url(#filter2_d_295_39124)">
          <path
            d="M70.1355 13.2354C70.1355 18.4995 57.4022 22.768 41.6928 22.768C25.9833 22.768 13.25 18.4995 13.25 13.2354V20.2512C13.25 25.5154 25.9833 29.7839 41.6928 29.7839C57.4022 29.7839 70.1355 25.5154 70.1355 20.2512V13.2354Z"
            fill="#C8D6DC"
          />
        </g>
        <path
          d="M41.6928 22.8636C57.4022 22.8636 70.1355 19.0887 70.1355 14.4318C70.1355 9.77486 57.4022 6 41.6928 6C25.9833 6 13.25 9.77486 13.25 14.4318C13.25 19.0887 25.9833 22.8636 41.6928 22.8636Z"
          fill="#C8D6DC"
        />
        <path
          d="M41.606 20.923C53.6537 20.923 63.419 18.0961 63.419 14.6087C63.419 11.1213 53.6537 8.29443 41.606 8.29443C29.5582 8.29443 19.793 11.1213 19.793 14.6087C19.793 18.0961 29.5582 20.923 41.606 20.923Z"
          fill="#7F8487"
        />
        <path
          d="M41.6024 20.9267C53.0161 20.9267 62.2674 18.6138 62.2674 15.7605C62.2674 12.9071 53.0161 10.5942 41.6024 10.5942C30.1888 10.5942 20.9375 12.9071 20.9375 15.7605C20.9375 18.6138 30.1888 20.9267 41.6024 20.9267Z"
          fill="#9DA6AA"
        />
        <path
          d="M6 26.1757C6 24.1764 7.62072 22.5557 9.61999 22.5557H18.4114V29.7956H9.61999C7.62073 29.7956 6 28.1749 6 26.1757Z"
          fill="#C8D6DC"
        />
        <circle cx="11.6834" cy="26.1765" r="2.5857" fill="#9DA6AA" />
        <rect x="8.07031" y="25.6616" width="7.23997" height="1.03428" rx="0.517141" fill="#F4F4F4" />
        <path
          d="M6 64.4403C6 62.441 7.62072 60.8203 9.61999 60.8203H18.4114V68.0603H9.61999C7.62073 68.0603 6 66.4396 6 64.4403Z"
          fill="#C8D6DC"
        />
        <circle cx="11.6834" cy="64.4397" r="2.5857" fill="#9DA6AA" />
        <rect x="8.07031" y="63.9248" width="7.23997" height="1.03428" rx="0.517141" fill="#F4F4F4" />
        <path
          d="M77.3789 26.1757C77.3789 24.1764 75.7582 22.5557 73.7589 22.5557H64.9675V29.7956H73.7589C75.7582 29.7956 77.3789 28.1749 77.3789 26.1757Z"
          fill="#C8D6DC"
        />
        <circle cx="2.5857" cy="2.5857" r="2.5857" transform="matrix(-1 0 0 1 74.2734 23.5913)" fill="#9DA6AA" />
        <rect
          width="7.23997"
          height="1.03428"
          rx="0.517141"
          transform="matrix(-1 0 0 1 75.3008 25.6621)"
          fill="#F4F4F4"
        />
        <path
          d="M77.3789 64.4398C77.3789 62.4405 75.7582 60.8198 73.7589 60.8198H64.9675V68.0598H73.7589C75.7582 68.0598 77.3789 66.4391 77.3789 64.4398Z"
          fill="#C8D6DC"
        />
        <circle cx="2.5857" cy="2.5857" r="2.5857" transform="matrix(-1 0 0 1 74.2734 61.8535)" fill="#9DA6AA" />
        <rect
          width="7.23997"
          height="1.03428"
          rx="0.517141"
          transform="matrix(-1 0 0 1 75.3008 63.9248)"
          fill="#F4F4F4"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_295_39124"
          x="0"
          y="0"
          width="83.3789"
          height="85.4326"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_39124" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_39124" result="shape" />
        </filter>
        <filter
          id="filter1_d_295_39124"
          x="9.25"
          y="35.9946"
          width="64.8867"
          height="24.5483"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_39124" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_39124" result="shape" />
        </filter>
        <filter
          id="filter2_d_295_39124"
          x="9.25"
          y="13.2354"
          width="64.8867"
          height="24.5483"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_39124" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_39124" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  );
}
