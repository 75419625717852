import React, { forwardRef } from 'react';

import CementitiousIcon from '@Src/_basic/icons/Cementitious';
const CementitiousRibbon = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <div ref={ref} className="ribbon cementitious-ribbon">
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '6px',
        }}
      >
        <CementitiousIcon style={{ height: '24px', width: '24px' }} />
      </div>
    </div>
  );
});

CementitiousRibbon.displayName = 'CementitiousRibbon';

export default CementitiousRibbon;
