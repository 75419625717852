import { EquipmentData, UpdateEquipmentRequest } from '@Src/_basic/object/EquipmentManagementType';
import { EquipmentApi } from '@Src/_basic/protocol/equipment/EquipmentApi';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import ViewImageField from './component/ViewImageField';
import { MonitorOrderData } from '@Src/_basic/object/MonitorType';

interface Props {
  data: MonitorOrderData | undefined;
  imageUrl: string[];
}

export default function ViewImageForm(props: Props) {
  const { data,imageUrl } = props;

  const updateSampleSchema = Yup.object().shape({});

  const handleSubmit = (request: UpdateEquipmentRequest) => {};

  const initialEquipmentValue: any = {};

  return (
    <>
      <Formik
        initialValues={initialEquipmentValue}
        validationSchema={updateSampleSchema}
        onSubmit={(request: UpdateEquipmentRequest) => handleSubmit(request)}
        enableReinitialize
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <ViewImageField data={data} imageUrl={imageUrl}/>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
