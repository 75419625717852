import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { SiteManagementData } from '@Src/_basic/object/SiteType';
import { useApi } from '@Src/redux/api/apiAction';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SiteColumnFormatter from '../SiteManagementTable/columnFormatter/SiteColumnFormatter';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import StateColumnFormatter from './columnFormatter/StateColumnFormatter';

interface Props {
  stationData: SiteManagementData[];
}
export default function SiteManagementTable({ stationData }: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { actionLoading } = useApi();

  const handleGoToUpdatePage = useCallback(
    (row: SiteManagementData) => {
      navigate(`/UsersManagePage/SiteManagement/SiteManagementUpdate/${row.id}`, {
        state: { areaName: row.areaName, factoryName: row.factoryName, removed: row.removed },
      });
    },
    [navigate],
  );

  const getButtonText = useCallback(
    (status: string) => {
      switch (status) {
        case '編輯':
          return i18T('SITEMANAGEMENT.TABLE.UPDATE');
        case '關閉中':
          return i18T('GLOBAL.CLOSING');
        case '運轉中':
          return i18T('GLOBAL.RUNNING');
      }
    },
    [i18T],
  );

  const columns: Array<Column> = useMemo(
    () => [
      {
        text: i18T('SITEMANAGEMENT.TABLE.NO'),
        dataField: 'ids',
        width: '10%',
      },
      {
        text: i18T('SITEMANAGEMENT.TABLE.AREA'),
        dataField: 'areaName',
        width: '15%',
      },
      {
        text: i18T('SITEMANAGEMENT.TABLE.FACTORY'),
        dataField: 'factoryName',
        width: '15%',
      },
      {
        text: i18T('SITEMANAGEMENT.TABLE.SITE'),
        dataField: 'stations',
        formatter: SiteColumnFormatter,
        width: '45%',
      },
      {
        text: i18T('GLOBAL.STATE'),
        dataField: 'removed',
        formatter: StateColumnFormatter,
        formatExtraData: {
          getButtonText: getButtonText,
        },
        width: '10%',
      },
      {
        text: i18T('SITEMANAGEMENT.TABLE.ACTION'),
        dataField: '',
        formatter: ActionColumnFormatter,
        formatExtraData: {
          onGoToUpdatePage: handleGoToUpdatePage,
          getButtonText: getButtonText,
        },
        width: '15%',
      },
    ],
    [getButtonText, handleGoToUpdatePage, i18T],
  );

  return <YatungTable columns={columns} data={stationData} loading={actionLoading} />;
}
