import { Drawer, Stack } from '@mui/material';
import React from 'react';

import Content from './Content';
import Header from './Header';

import { useLayout } from '@Redux/layout/layoutActions';

export default function Left() {
  const { isLeftMenuOpen } = useLayout();

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isLeftMenuOpen}
      sx={{
        width: isLeftMenuOpen ? 270 : 0,
      }}
    >
      <Stack
        sx={{
          width: 270,
          height: '100vh',
          backgroundColor: '#BB5E00',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: 0,
          },
        }}
      >
        <Header />
        <Content />
      </Stack>
    </Drawer>
  );
}
