import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { EntityData } from '@Src/_basic/object/EntityType';
import { useApi } from '@Src/redux/api/apiAction';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionColumnFormatter from '../ColumnFormatter/ActionColumnFormatter';
import FactoryColumnFormatter from '../ColumnFormatter/FactoryColumnFormatter';

interface Props {
  entityData: EntityData[];
  handleUpdateAndDelete: (id: number, modal: 'UPDATE' | 'DELETE') => void;
}
export default function EntityManagementTable({ entityData, handleUpdateAndDelete }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const getButtonText = useCallback(
    (status: string) => {
      switch (status) {
        case '編輯':
          return i18T('GLOBAL.UPDATE');
        case '刪除':
          return i18T('ENTITYMANAGEMENT.DELETE');
        case '使用中':
          return i18T('GLOBAL.USING');
        case '啟用':
          return i18T('GLOBAL.OPEN');
        default:
          '';
      }
    },
    [i18T],
  );

  const columns: Array<Column> = useMemo(
    () => [
      {
        text: i18T('ENTITYMANAGEMENT.NO'),
        dataField: 'displayId',
      },
      {
        text: i18T('ENTITYMANAGEMENT.ENTITY'),
        dataField: 'name',
      },
      {
        text: i18T('ENTITYMANAGEMENT.FACTORY_TYPE'),
        dataField: 'factoryId',
        formatter: FactoryColumnFormatter,
      },
      {
        text: i18T('ENTITYMANAGEMENT.SITE_TYPE'),
        dataField: 'stationName',
        formatter: FactoryColumnFormatter,
      },
      {
        text: i18T('ENTITYMANAGEMENT.CREATETIME'),
        dataField: 'createTime',
        formatter: ({ row }: any) => {
          return <>{moment(row.createTime).format('YYYY/MM/DD HH:mm')}</>;
        },
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.ACTION'),
        dataField: '',
        formatter: ActionColumnFormatter,
        formatExtraData: {
          onEdit: (id: number) => handleUpdateAndDelete(id, 'UPDATE'),
          onStop: (id: number) => handleUpdateAndDelete(id, 'DELETE'),
          getButtonText: getButtonText,
        },
      },
    ],
    [handleUpdateAndDelete, i18T, getButtonText],
  );

  return <YatungTable columns={columns} data={entityData} loading={actionLoading} />;
}
