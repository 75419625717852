import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Previous(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="20" height="18" viewBox="0 0 20 18" fill="none">
      <path
        d="M4.72907 1.3335L1.375 4.2085L4.72907 7.56266"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.375 4.2085H12.3929C15.691 4.2085 18.4918 6.9016 18.6204 10.1981C18.7562 13.6814 15.878 16.6668 12.3929 16.6668H4.24923"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
