import React from 'react';
import { Typography } from '@mui/material';
import { GetClearExecution } from '@Src/_basic/object/ClearExecutionType';

interface Props {
  row: GetClearExecution;
}
export default function MemoColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.memo}</Typography>;
}
