import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function FileSearch(props: SvgIconProps) {
  return (
    <svg {...props} width="16" height="18" viewBox="0 0 18 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.61454 3.64161H1.80727V16.3872H6.32545V18.208H1.80727C0.813294 18.208 0 17.3888 0 16.3872V3.64161C0 2.64008 0.813294 1.8208 1.80727 1.8208H5.58446C5.96397 0.764596 6.95801 0 8.13272 0C9.30744 0 10.3015 0.764596 10.681 1.8208H14.4582C15.4521 1.8208 16.2654 2.64008 16.2654 3.64161V8.19362H14.4582V3.64161H12.6509V4.55201C12.6509 5.55354 11.8376 6.37282 10.8436 6.37282H5.42181C4.42784 6.37282 3.61454 5.55354 3.61454 4.55201V3.64161ZM9.03636 2.73121C9.03636 2.23066 8.62971 1.8208 8.13272 1.8208C7.63573 1.8208 7.22908 2.23066 7.22908 2.73121C7.22908 3.23175 7.63573 3.64161 8.13272 3.64161C8.62971 3.64161 9.03636 3.23175 9.03636 2.73121Z" fill="gray"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.6329 16.2961L17.4402 18.1169C17.7926 18.4721 17.7835 19.0455 17.4311 19.4003C17.0787 19.7555 16.5184 19.7555 16.166 19.4003L14.3587 17.5795C13.4461 18.1076 12.3527 18.4445 11.0605 18.044C9.50621 17.5617 8.3134 16.1779 8.15076 14.5482C8.08639 13.9405 8.15782 13.3257 8.35984 12.7496C8.56187 12.1735 8.88937 11.6499 9.3183 11.2178C9.74723 10.7857 10.2667 10.4558 10.8387 10.2522C11.4107 10.0487 12.0206 9.97664 12.6238 10.0415C14.2503 10.1962 15.6148 11.4071 16.0937 12.9732C16.4914 14.2659 16.157 15.3764 15.6329 16.2961ZM9.93999 14.1112C9.93999 15.3857 10.934 16.3872 12.1991 16.3872C13.4641 16.3872 14.4582 15.3857 14.4582 14.1112C14.4582 12.8363 13.4641 11.8352 12.1991 11.8352C10.934 11.8352 9.93999 12.8363 9.93999 14.1112Z" fill="gray"/>
    </svg>
  );
}
