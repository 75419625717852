import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungPage from '@Src/_basic/components/YatungPage';
import { MonitorAreaData } from '@Src/_basic/object/MonitorType';
import { MonitorApi } from '@Src/_basic/protocol/monitor/MonitorApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useMonitor } from '@Src/redux/monitor/monitorAction';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectAreaMenu from '../components/SelectAreaMenu';

export default function SelectAreaScreen() {
  const { t: i18T } = useTranslation();
  const { date, setDate } = useMonitor(); //redux拿區域資料
  const [monitorAreaData, setMonitorAreaData] = useState<MonitorAreaData[]>([]);
  const { userGroupsData } = useAuth();

  const handleDateChange = (value: unknown) => {
    setDate(value);
  };

  const getMonitorAreaData = () => {
    //獲取當日或選取日期的區域資訊
    MonitorApi.getMonitorArea(
      { date: moment(date).format('YYYY-MM-DD'), factoryIds: userGroupsData?.factories.map((item) => item.value) },
      (datas: MonitorAreaData[]) => {
        setMonitorAreaData(datas);
      },
      undefined,
    );
  };

  useEffect(() => {
    getMonitorAreaData();
  }, [date]);

  return (
    <>
      <YatungPage
        title={i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.PAGE_TITLE')}
        body={
          <>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Stack direction="row" spacing={2} sx={{ py: 1 }}>
                <YatungDateSelect
                  value={date}
                  disableFuture={true}
                  onChange={(val) => val && handleDateChange(new Date(val).getTime())}
                />
              </Stack>
            </Stack>
            <SelectAreaMenu monitorAreaData={monitorAreaData} />
          </>
        }
      />
    </>
  );
}
