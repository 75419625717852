import { Stack } from '@mui/material';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';

import DetailIcon from '@Src/_basic/icons/Detail';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { SearchSpecimenData } from '@Src/_basic/object/SpecimenType';
import { useApi } from '@Src/redux/api/apiAction';
import { useTranslation } from 'react-i18next';

interface Props {
  onViewDetail: (row: SearchSpecimenData) => void;
  rows?: Array<SearchSpecimenData>;
  selectedType: number;
  pageInfo: PageInfo;
  searchData?: any;
}

const TestManageTable = ({ onViewDetail, rows, selectedType, searchData }: Props) => {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const [tableColumns, setTableColumns] = useState<Array<Column>>();

  useEffect(() => {
    if (selectedType === 5) {
      setTableColumns([
        {
          text: 'NO.',
          dataField: 'index',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.SITE_NAME'),
          dataField: 'civilEngrName',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.PLATE_NUMBER'),
          dataField: 'plateNumber',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.TIME'),
          dataField: 'createTime',
          formatter: ({ row }) => {
            return <>{moment(row.createTime).format('YYYY/MM/DD HH:mm')}</>;
          },
        },
        {
          text: `${i18T('APPLICATIONSMANAGEMENT.TABLE.EXPECTED_TEST_STR')}(kgf/cm²)`,
          dataField: 'expectedCompStr',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.PROPORTION'),
          dataField: 'propName',
        },
      ]);
    } else if (selectedType === 9) {
      setTableColumns([
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.CHEM_TYPE'),
          dataField: 'chemType',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.TEST_AREA_NAME'),
          dataField: 'areaName',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.GROUP'),
          dataField: 'factoryName',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.SPECIFICATION'),
          dataField: 'specificationName',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.SOURCE'),
          dataField: 'sourceName',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.SUPPLIER_NAME'),
          dataField: 'supplierName',
        },
      ]);
    } else {
      setTableColumns([
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.SPECIFICATION'),
          dataField: 'specificationName',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.SOURCE'),
          dataField: 'sourceName',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.STORAGE_NAME'),
          dataField: 'storageName',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.SUPPLIER_NAME'),
          dataField: 'supplierName',
        },
      ]);
    }
  }, [selectedType, i18T]);

  return (
    <>
      {tableColumns && selectedType !== 9 ? (
        <YatungTable
          loading={actionLoading}
          data={rows}
          columns={[
            ...tableColumns,
            {
              text: i18T('APPLICATIONSMANAGEMENT.TABLE.SPECIMEN_NUM'),
              dataField: 'specimenNum',
            },
            {
              text: i18T('GLOBAL.ACTION'),
              dataField: 'action',
              formatter: ({ row }) => {
                return (
                  <Stack direction="row" spacing={1}>
                    <YatungButton
                      text={i18T('QUALITY_MANAGE.SIDE_MENU.EXPT_MANAGEMENT.EXPT_SCHEDULE.CREATE')}
                      startIcon={<DetailIcon />}
                      color="yellow"
                      onClick={() => {
                        onViewDetail(row);
                      }}
                    />
                  </Stack>
                );
              },
            },
          ]}
        />
      ) : (
        tableColumns &&
        rows && (
          <YatungTable
            loading={actionLoading}
            data={rows}
            columns={[
              ...tableColumns,
              {
                text: i18T('APPLICATIONSMANAGEMENT.TABLE.SPECIMEN_NUM'),
                dataField: 'specimenNum',
              },
              {
                text: i18T('GLOBAL.ACTION'),
                dataField: 'action',
                formatter: ({ row }) => {
                  if (searchData.length > 0 || row.specimenType.id === 9) {
                    return (
                      <Stack direction="row" spacing={1}>
                        <YatungButton
                          text={i18T('GLOBAL.DETAIL')}
                          startIcon={<DetailIcon />}
                          color="yellow"
                          onClick={() => {
                            onViewDetail(row);
                          }}
                        />
                      </Stack>
                    );
                  } else return <></>;
                },
              },
            ]}
          />
        )
      )}
    </>
  );
};

export default memo(TestManageTable);
