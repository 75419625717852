import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import WarningIcon from '@Src/_basic/icons/Warning';

export default function ValueCheckWrapper({ children, values = [], target = 100, customNoramlStyle = {} }: any) {
  const warningBoxStyle = {
    backgroundColor: 'rgba(255, 0, 0, 0.5)',
    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
    pb: '15px',
    mb: '15px',
  };

  const total = values.reduce((count: number, current: number) => count + Number(current), 0);
  const isWarning = Number(total?.toFixed(1)) >= 100.5 || Number(total?.toFixed(1)) < 99.5;

  return (
    <Box
      sx={{
        border: '1px solid white',
        borderRadius: '10px',
        position: 'relative',
        ...(isWarning ? warningBoxStyle : customNoramlStyle),
      }}
    >
      {children}
      {isWarning && (
        <Stack
          sx={{
            color: 'white',
            width: '180px',
            p: '2px 10px',
            backgroundColor: 'red',
            borderRadius: '15px',
            border: '1px solid white',
            position: 'absolute',
            left: '50%',
            bottom: '-15px',
            transform: 'translate(-50%, 0)',
          }}
          flexDirection="row"
          justifyContent="space-between"
        >
          <WarningIcon fontSize="small" />
          <Typography>{total > target ? `超過${target}%` : `不足${target}%`}</Typography>
          <WarningIcon fontSize="small" />
        </Stack>
      )}
    </Box>
  );
}
