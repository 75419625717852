import { Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiGroup, ApiTableType, ContentAndGroupsTableType } from '@Src/_basic/object/AccountRightType';
import { MenuApi } from '@Src/_basic/protocol/accountRights/fetAuth/menu/MenuApi';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import TitleWithButton from '../../../_components/TitleWithButton';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import DeleteCircleIcon from '@Src/_basic/icons/DeleteCircle';
import EditIcon from '@Src/_basic/icons/Edit';
import SearchIcon from '@Src/_basic/icons/Search';
import SwitchIcon from '@Src/_basic/icons/Switch';
import TickIcon from '@Src/_basic/icons/Tick';
import { ActionApi } from '@Src/_basic/protocol/accountRights/fetAuth/action/ActionApi';
import { useApi } from '@Src/redux/api/apiAction';

interface Props {
  open: boolean;
  closeModel: () => void;
  selectedGroupData: ContentAndGroupsTableType;
  apiList: Array<ApiTableType>;
  getAllApiGroup: () => void;
}

export default function ApiGroupManagementUpdateCard({
  open,
  closeModel,
  selectedGroupData,
  apiList,
  getAllApiGroup,
}: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const [code, setCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [searchGroup, setSearchGroup] = useState<string>('');
  const [updateDetail, setUpdateDetail] = useState<ApiGroup>();
  const [updateDetailApiIds, setUpdateDetailApiIds] = useState<Array<string>>();

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const [apiListData, setApiListData] = useState<Array<ApiTableType>>([]);
  const [searchApiListByInput, setSearchApiListByInput] = useState<Array<ApiTableType>>();

  const reset = () => {
    setApiListData([]);
    setCode('');
    setName('');
  };

  const cancelHandler = () => {
    closeModel();
    reset();
  };

  const finishHandler = () => {
    if (code && name && updateDetail) {
      MenuApi.postUpdateRightsMenu(
        {
          code,
          name,
          id: updateDetail.id,
          state: updateDetail.state,
          path: '/api',
          kind: '1',
          parentId: '',
          description: '',
          position: 0,
        },
        (_data) => {
          if (updateDetailApiIds) {
            const checkArr = apiListData.filter((item) => item.check).map((item) => item.id);
            const arraysEqual =
              updateDetailApiIds.length === checkArr.length &&
              updateDetailApiIds.every((value) => checkArr.find((item) => item === value));
            const deleteArr = updateDetailApiIds.filter((val) => !checkArr.includes(val));
            const addArr = checkArr.filter((val) => !updateDetailApiIds.includes(val));

            if (!arraysEqual) postUpdateAction(deleteArr, addArr, updateDetail.id);
            else {
              getAllApiGroup();
              closeModel();
              reset();
            }
          }
        },
        (error) => {
          setIsShowAlert(true);
          setAlertText(error.message);
        },
      );
    }
  };

  const postUpdateAction = (deleteArr: Array<string>, addArr: Array<string>, menuId: string) => {
    if (deleteArr.length > 0) {
      const deleteIds: Array<string> = deleteArr.map((item) => {
        if (updateDetail) {
          const updateDetailItem = updateDetail.actions.find((_item) => _item.apis[0].apiId === item);
          if (updateDetailItem) return updateDetailItem.id;
          else return '';
        } else return '';
      });
      if (deleteArr.length === deleteIds.length) {
        ActionApi.posDeleteRightsAction(deleteIds, undefined, (error) => {
          setIsShowAlert(true);
          setAlertText(error.message);
        });
      }
    }

    if (addArr.length > 0) {
      addArr.forEach((addItem) => {
        const listItem = apiList.find((_item) => _item.id === addItem);
        if (listItem) {
          ActionApi.postCreateRightsAction(
            {
              menuId: menuId,
              id: listItem.id,
              code: listItem.code,
              name: listItem.name,
              state: '1',
              apiIds: [listItem.id],
              kind: '4',
            },
            undefined,
            (error) => {
              setIsShowAlert(true);
              setAlertText(error.message);
            },
          );
        }
      });
    }

    if (!actionLoading) {
      getAllApiGroup();
      closeModel();
      reset();
    }
  };

  useEffect(() => {
    if (open) {
      MenuApi.postGetRightsMenuById(selectedGroupData.id, (_data) => {
        const idsArr = _data.actions.map((item) => item.apis.map((_item) => _item.apiId));
        setUpdateDetailApiIds(idsArr.reduce((acc, curr) => [...acc, curr[0]], []));
        setUpdateDetail(_data);
        setCode(_data.code);
        setName(_data.name);
      });
    } else reset();
  }, [selectedGroupData, open]);

  useEffect(() => {
    setApiListData(apiList);
    if (updateDetailApiIds && apiList) {
      updateDetailApiIds.forEach((item) => {
        setApiListData((prev) =>
          prev.map((apiListItem) => (apiListItem.id === item ? { ...apiListItem, check: true } : apiListItem)),
        );
      });
    }
  }, [apiList, updateDetailApiIds]);

  useEffect(() => {
    setSearchApiListByInput(apiListData);
    if (searchGroup) {
      setSearchApiListByInput(
        apiListData.filter(
          (item) =>
            item.code.toUpperCase().includes(searchGroup.toUpperCase()) ||
            item.name.toUpperCase().includes(searchGroup.toUpperCase()),
        ),
      );
    }
  }, [searchGroup, apiListData]);

  const columns: Array<Column> = [
    {
      text: i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.API_CODE'),
      dataField: 'code',
      width: '100px',
    },
    {
      text: i18T('USERSMANAGEMENU.EDIT_ACCOUNT.NAME_2'),
      dataField: 'name',
      width: '300px',
    },
    // {
    //   text: i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.ACTION'),
    //   dataField: 'status',
    //   formatter: ActionColumnFormatter,
    //   formatExtraData: {
    //     openText: i18T('GLOBAL.OPEN'),
    //     closeText: i18T('GLOBAL.CLOSE'),
    //     setStatus: setApiListData,
    //   },
    // },
    {
      text: undefined,
      dataField: 'id',
      width: '50px',
      formatter: ({ row }) => {
        return (
          <>
            <IconButton
              onClick={() =>
                setApiListData((prev) => prev.map((item) => (item.id === row.id ? { ...item, check: false } : item)))
              }
              edge="end"
              sx={{
                height: 10,
                width: 10,
              }}
            >
              <DeleteCircleIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Modal open={open}>
      <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        <TitleWithButton
          Icon={<EditIcon />}
          title={i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.EDIT_API_GROUP')}
          onCancel={cancelHandler}
          onFinish={finishHandler}
        />
        <Stack spacing={5} sx={{ bgcolor: 'white', minWidth: 700, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}>
          <Box display="flex" alignItems="center" flexDirection="row">
            <Stack sx={{ width: '60%' }}>
              <Typography sx={{ fontSize: 28 }}>
                {i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.CODE')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Stack sx={{ width: '50%' }}>
                <YatungInput value={code} onChange={(e: any) => setCode(e.target.value)} />
              </Stack>
            </Stack>
            <Stack>
              <Typography sx={{ fontSize: 28 }}>
                {i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.NAME')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Stack sx={{ width: '100%' }}>
                <YatungInput value={name} onChange={(e: any) => setName(e.target.value)} />
              </Stack>
            </Stack>
          </Box>
          {updateDetail && (
            <>
              <Box display="flex" alignItems="center" flexDirection="row" justifyContent="space-between">
                <Stack spacing={1} sx={{ width: '30%', mr: 3 }}>
                  <Typography sx={{ fontSize: 28, letterSpacing: 10 }}>
                    {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.STATUS')}:
                    <span style={{ color: updateDetail.state === '1' ? '#00D03A' : '#FC0000', marginLeft: '20px' }}>
                      {updateDetail.state === '1' ? i18T('GLOBAL.OPEN') : i18T('GLOBAL.STOP')}
                    </span>
                  </Typography>
                  <Stack spacing={2} alignItems="center" justifyContent="flex-start" direction="row">
                    <YatungButton
                      text={i18T('GLOBAL.OPEN')}
                      color="green"
                      width={120}
                      startIcon={<SwitchIcon />}
                      onClick={() => {
                        setUpdateDetail((prev) => {
                          if (prev?.state) {
                            return { ...prev, state: '1' };
                          }
                        });
                      }}
                    />
                    <YatungButton
                      text={i18T('GLOBAL.STOP')}
                      color="red"
                      width={120}
                      startIcon={<SwitchIcon />}
                      onClick={() => {
                        setUpdateDetail((prev) => {
                          if (prev?.state) {
                            return { ...prev, state: '0' };
                          }
                        });
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack>
                  <Typography sx={{ fontSize: 28, letterSpacing: 15 }}>
                    {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.EDIT_TIME')}
                  </Typography>
                  <Stack>
                    <Typography sx={{ pl: 1, letterSpacing: 10 }}>{updateDetail.updateDt.split(' ')[0]}</Typography>
                    <Typography sx={{ pl: 1, letterSpacing: 10 }}>{updateDetail.updateDt.split(' ')[1]}</Typography>
                  </Stack>
                </Stack>
              </Box>
            </>
          )}
          <Stack display="flex" flexDirection="column" spacing={2}>
            <Typography sx={{ fontSize: 28 }}>
              {i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.ADD_API_FEATURES')}
            </Typography>
            <YatungTable
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'gray',
                minHeight: 200,
                maxHeight: 200,
                maxWidth: 630,
                px: 1,
                borderRadius: 2,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#BB5E00',
                  borderRadius: 8,
                },
              }}
              isTableMinWidth={false}
              columns={columns}
              data={apiListData.filter((item) => item.check)}
              headColor={false}
            />
            <YatungInput
              value={searchGroup}
              onChange={(e: any) => setSearchGroup(e.target.value)}
              position="start"
              icon={<SearchIcon sx={{ fontSize: 36, ml: 2 }} />}
              style={{ fontSize: 28 }}
            />
            <Stack
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'gray',
                minHeight: 200,
                maxHeight: 200,
                borderRadius: 2,
                maxWidth: 630,
                px: 3,
                py: 1,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#BB5E00',
                  borderRadius: 8,
                },
              }}
            >
              {searchApiListByInput?.map((item: any) => (
                <Button
                  key={item.id}
                  sx={{ fontSize: 20, color: 'gray', borderBottom: 1, justifyContent: 'space-between' }}
                  disabled={item.check}
                  onClick={() => {
                    setApiListData((prev) =>
                      prev.map((_data) => (_data.id === item.id ? { ..._data, check: true } : _data)),
                    );
                  }}
                >
                  {`${item.code} ${item.name}`}
                  {item.check ? <TickIcon /> : <></>}
                </Button>
              ))}
              <YatungConfirmAlert
                isShowAlert={isShowAlert}
                onClose={() => {
                  setIsShowAlert(false);
                  setTimeout(() => setAlertText(''), 300);
                }}
                alertText={alertText}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}
