import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ViewQR(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.75 17.8571C15.0894 17.8571 18.6071 14.3394 18.6071 10C18.6071 5.66062 15.0894 2.14286 10.75 2.14286C6.41062 2.14286 2.89286 5.66062 2.89286 10C2.89286 14.3394 6.41062 17.8571 10.75 17.8571ZM10.75 20C16.2728 20 20.75 15.5228 20.75 10C20.75 4.47715 16.2728 0 10.75 0C5.22715 0 0.75 4.47715 0.75 10C0.75 15.5228 5.22715 20 10.75 20Z"
          fill="white"
        />
        <path
          d="M14.2671 9.36962C13.8955 9.75815 13.372 10.0001 12.7919 10.0001C11.6648 10.0001 10.7511 9.08643 10.7511 7.95932C10.7511 7.37928 10.9931 6.85575 11.3816 6.48419C11.177 6.44774 10.9663 6.42871 10.7511 6.42871C8.77867 6.42871 7.17969 8.02769 7.17969 10.0001C7.17969 11.9726 8.77867 13.5716 10.7511 13.5716C12.7236 13.5716 14.3225 11.9726 14.3225 10.0001C14.3225 9.78499 14.3035 9.57429 14.2671 9.36962Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
