import { FactoryData } from './FactoryType';
import { TestItemEnum } from './TestType';
import {
  FlyAshLValueVerifyCustomResults,
  FlyAshLValueVerifyPassedResults,
} from './test/cementtitiousPowder/FlyAshLValueType';
import {
  FlyAshLossOnIgnitionVerifyCustomResults,
  FlyAshLossOnIgnitionVerifyPassedResults,
} from './test/cementtitiousPowder/FlyAshLossOnIgnitionType';
import { AmsVerifyCustomResults, AmsVerifyPassedResults } from './test/sandRock/AmsType';
import { ChlorideVerifyCustomResults, ChlorideVerifyPassedResults } from './test/sandRock/ChlorideType';
import { CoarseSieveVerifyCustomResults, CoarseSieveVerifyPassedResults } from './test/sandRock/CoarseSieveType';
import { FineAggSgWabsVerifyPassedResults, FineAggSgWebVerifyCustomResults } from './test/sandRock/FineAggSgWabsType';
import { FineSieveVerifyCustomResults, FineSieveVerifyPassedResults } from './test/sandRock/FineSieveType';

export interface ScreeningItemIsAbnormal {
  isAbnormal?: boolean;
}
export interface ResultAndIsAbnormal<T> {
  result: T;
  isAbnormal: boolean;
  type1Abnormal?: boolean;
  type2Abnormal?: boolean;
}
export interface CreateMonthlyParams {
  factoryId: number;
  exptUnitId: number;
  startTime: number;
  endTime: number;
  specificationId?: number;
  sourceId?: number;
  supplierId?: number;
  formId?: number;
}

export interface CreateMonthlyReportViewRequire {
  name: string;
  experimentUnitId: string;
  factoryId: number;
  monthlyReportExperimentId: number;
  specificationId?: number;
  sourceId?: number;
  supplierId?: number;
  formId?: number;
  startTime: number;
  endTime: number;
}

export interface ViewMonthlyParams {
  factoryId: number;
  timeZoneStr: string;
  time: number;
  monthlyReportExperimentId: number;
  experimentUnitId: number;
  specificationId?: number;
  sourceId?: number;
  supplierId?: number;
  formId?: number;
  removed: boolean;
}

// 月報檢視列表response
export interface ReportViewResponse {
  id: number;
  name: string;
  startTime: number;
  endTime: number;
  createTime: number;
  removed: boolean;
  formId: null | number;
  monthlyReportExperimentItem: {
    id: number;
    itemName: string;
    itemCode: TestItemEnum;
  };
  experimentUnit: {
    id: number;
    unitName: string;
    removed: boolean;
    createTime: number;
    lastUpdateTime: number;
  };
  factory?: FactoryData | null;
  specification?: {
    id: number;
    name: string;
    code: number;
    removed: boolean;
    typeId: number | null;
  } | null;
  source?: {
    id: number;
    sourceName: string;
    code: number;
    removed: boolean;
    type: {
      id: number;
      name: string;
      code: number;
      removed: boolean;
    };
    specifications: [
      {
        id: number;
        name: string;
        code: number;
        removed: boolean;
        typeId: null | number;
      },
      {
        id: number;
        name: string;
        code: number;
        removed: boolean;
        typeId: null | number;
      },
    ];
    isYard: boolean;
  } | null;
  supplier?: {
    id: number;
    name: string;
    code: number;
    removed: boolean;
    type: {
      id: number;
      name: string;
      code: number;
      removed: boolean;
    };
    isYard: boolean;
  } | null;
}

export interface MonthlyReportExptItem {
  id: number;
  itemName: string;
  itemCode: MonthlyReportNameEnum;
  removed: boolean;
}

export interface MonthlyReportBasicReports {
  testDate: number;
  tester: string;
  exptId: number;
  exptItemCode: TestItemEnum;
}

// 月報細粒料篩分析
export interface MonthlyReportFineAggAnalysis extends MonthlyReportBasicReports {
  verifyPassedResults: FineSieveVerifyPassedResults;
  verifyCustomResults: FineSieveVerifyCustomResults;
  abnormal?: ScreeningItemIsAbnormal;
  screening: ResultAndIsAbnormal<number>;
  screening4: ResultAndIsAbnormal<number>;
  screening8: ResultAndIsAbnormal<number>;
  screening16: ResultAndIsAbnormal<number>;
  screening30: ResultAndIsAbnormal<number>;
  screening50: ResultAndIsAbnormal<number>;
  screening100: ResultAndIsAbnormal<number>;
  screening200: ResultAndIsAbnormal<number>;
  [key: string]: any;
  ratio: number;
  exptId1: number | null;
  exptId2: number | null;
}

export interface MonthlyReportFineAggAms extends MonthlyReportBasicReports {
  sampleDate: number;
  samplePosition: string;
  verifyPassedResults: AmsVerifyPassedResults;
  verifyCustomResults: AmsVerifyCustomResults;
  phenolphthalein: ResultAndIsAbnormal<string>;
  ph: FineAggAmsPh;
  magnetic: FineAggAmsMagnetic;
}

// 月報細粒料AMS_magnetic
export interface FineAggAmsMagnetic {
  before: number;
  after: number;
  amount: number;
  isAbnormal?: boolean;
}

// 月報細粒料AMS_ph
export interface FineAggAmsPh {
  before: number;
  after: number;
  isAbnormal?: boolean;
}

// 月報細粒料比重吸水率
export interface MonthlyReportAggAbsorption<T> extends MonthlyReportBasicReports {
  verifyPassedResults: FineAggSgWabsVerifyPassedResults;
  verifyCustomResults: FineAggSgWebVerifyCustomResults;
  proportion: T;
  absorption: T;
}

// 月報細粒料氯離子
export interface MonthlyReportFineAggChloride<T> extends MonthlyReportBasicReports {
  sampleDate: number;
  samplePosition: string;
  sampleSource: string;
  verifyPassedResults: ChlorideVerifyPassedResults;
  verifyCustomResults: ChlorideVerifyCustomResults;
  chlorideIons: T;
  isAbnormal?: boolean;
}

// 月報粗粒料篩分析
export interface MonthlyReportCoarseAggAnalysis extends MonthlyReportBasicReports {
  verifyPassedResults: CoarseSieveVerifyPassedResults;
  verifyCustomResults: CoarseSieveVerifyCustomResults;
  abnormal?: ScreeningItemIsAbnormal;
  ratio: number;
  screening1: ResultAndIsAbnormal<number>;
  screening1_5: ResultAndIsAbnormal<number>;
  screening0_5: ResultAndIsAbnormal<number>;
  screening4: ResultAndIsAbnormal<number>;
  screening8: ResultAndIsAbnormal<number>;
  exptId1: number | null;
  exptId2: number | null;
}

// 月報粗粒料比重吸水率
export interface MonthlyReportAggAbsorption<T> extends MonthlyReportBasicReports {
  verifyPassedResults: FineAggSgWabsVerifyPassedResults;
  verifyCustomResults: FineAggSgWebVerifyCustomResults;
  proportion: T;
  absorption: T;
}

// 月報細粒料飛灰L值
export interface MonthlyReportFlyAshLValue<T> extends MonthlyReportBasicReports {
  sampleDate: number;
  verifyPassedResults: FlyAshLValueVerifyPassedResults;
  verifyCustomResults: FlyAshLValueVerifyCustomResults;
  value: T;
  isAbnormal?: boolean;
}

// 月報細粒料飛灰燒失量
export interface MonthlyReportFlyAshLossOfIgnition extends MonthlyReportBasicReports {
  sampleDate: number;
  verifyPassedResults: FlyAshLossOnIgnitionVerifyPassedResults;
  verifyCustomResults: FlyAshLossOnIgnitionVerifyCustomResults;
  weight: FlyAshLossOfIgnitionWeight;
}

// 月報細粒料飛灰燒失量_weight
export interface FlyAshLossOfIgnitionWeight {
  original: number;
  loss: number;
  lossRatio: number;
  isAbnormal?: boolean;
}

// 月報混凝土抗壓
export interface MonthlyReportConcreteAntiStress {
  id: string;
  specificationName: string;
  proportionName: string;
  carNo: string;
  testSample: number;
  exptConcreteCompStr: Array<ExptConcreteCompStr>;
}

// 月報混凝土抗壓_ExptConcreteCompStr
export interface ExptConcreteCompStr extends MonthlyReportBasicReports {
  verifyPassedResults: null;
  verifyCustomResults: null;
  specimenAge: number;
  strengths: Array<ConcreteAntiStressStrength>;
}

// 月報混凝土抗壓_strength
export interface ConcreteAntiStressStrength {
  id: number;
  value: number;
  ratio: number;
}

// 月報細粒料R值
export interface MonthlyReportFineAggRValue<T> extends MonthlyReportBasicReports {
  verifyPassedResults: null;
  verifyCustomResults: null;
  sampleDate: number;
  value: T;
}

export enum MonthlyReportNameEnum {
  COARSE_AGGREGATE_ANALYSIS = 'COARSE_AGGREGATE_ANALYSIS',
  COARSE_AGGREGATE_ABSORPTION = 'COARSE_AGGREGATE_ABSORPTION',
  CONCRETE_ANTI_STRESS = 'CONCRETE_ANTI_STRESS',
  FINE_AGGREGATE_ABSORPTION = 'FINE_AGGREGATE_ABSORPTION',
  FINE_AGGREGATE_CHLORIDE_DETECTION = 'FINE_AGGREGATE_CHLORIDE_DETECTION',
  FINE_AGGREGATE_AMS_DETECTION = 'FINE_AGGREGATE_AMS_DETECTION',
  FINE_AGGREGATE_ANALYSIS = 'FINE_AGGREGATE_ANALYSIS',
  FINE_AGGREGATE_R_VALUE = 'FINE_AGGREGATE_R_VALUE',
  FLY_ASH_L_VALUE = 'FLY_ASH_L_VALUE',
  FLY_ASH_LOSS_OF_IGNITION = 'FLY_ASH_LOSS_OF_IGNITION',
}

export const SourceList = [
  MonthlyReportNameEnum.COARSE_AGGREGATE_ABSORPTION,
  MonthlyReportNameEnum.FINE_AGGREGATE_AMS_DETECTION,
  MonthlyReportNameEnum.FINE_AGGREGATE_CHLORIDE_DETECTION,
  MonthlyReportNameEnum.FINE_AGGREGATE_ABSORPTION,
  MonthlyReportNameEnum.FLY_ASH_L_VALUE,
  MonthlyReportNameEnum.FLY_ASH_LOSS_OF_IGNITION,
];

export const AnalysisList = [
  MonthlyReportNameEnum.COARSE_AGGREGATE_ANALYSIS,
  MonthlyReportNameEnum.FINE_AGGREGATE_ANALYSIS,
];

export const WithoutAnySelectList = [
  MonthlyReportNameEnum.FINE_AGGREGATE_R_VALUE,
  MonthlyReportNameEnum.CONCRETE_ANTI_STRESS,
];
