import React from 'react';

import YatungDynamicSelect from '@Src/_basic/components/YatungDynamicSelect';
import { Options } from '@Src/_basic/components/YatungSelect';

interface Props {
  proportionOptions: Options[];
  dymanicOptions: Options[];
  selectedValue: number;
  onSelected: (value: string) => void;
}
export default function UpeateMixerDetailsMixerBindSelect(props: Props) {
  const { proportionOptions, dymanicOptions, selectedValue, onSelected } = props;

  const handleSelectOption = (e: any) => {
    onSelected?.(e.target.value);
  };

  return (
    <YatungDynamicSelect
      sx={{ width: '125px' }}
      options={dymanicOptions}
      value={selectedValue}
      onChange={handleSelectOption}
      totalOptions={proportionOptions}
    />
  );
}
