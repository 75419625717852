import React from 'react';
import { Box, Grid } from '@mui/material';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';

interface Props {
  warningMoalOpen: boolean;
  onWarningModalClose: () => void;
  errorCode: string;
}
export default function SiteManagementErrorMessage(props: Props) {
  const { t: i18T } = useTranslation();
  const { warningMoalOpen, onWarningModalClose, errorCode } = props;
  const getErrorMessage = () => {
    if (errorCode === '99999') {
      return i18T('SITEMANAGEMENT.WARNING_HINT.INSUFFICIENT_PERMISSIONS')
    } else if (errorCode === '10111') {
      return i18T('SITEMANAGEMENT.WARNING_HINT.DULPICATE_DATA')
    } else if (errorCode === '10104') {
      return i18T('SITEMANAGEMENT.WARNING_HINT.ADD_INFORMATION_IS_NOT_SUCCESS')
    }
  }
  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={warningMoalOpen}
        handleClose={onWarningModalClose}
        postion={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
          },
        }}
        title={i18T('GLOBAL.WARN')}
        titleColor={{ color: '#CB333B', bgcolor: '#BB5E00' }}
        body={
          <>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
              <Box component={'div'} sx={{ fontSize: 28, fontWeight: 400, fontFamily: 'Inter', color: '#7C878E' }}>
                {getErrorMessage()}
              </Box>
            </Grid>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
              <YatungButton
                size={'large'}
                color="blue"
                type={'button'}
                text={i18T('GLOBAL.SURE')}
                sx={{
                  fontSize: '24px',
                  px: 2,
                }}
                onClick={onWarningModalClose}
              />
            </Grid>
          </>
        }
      />
    </Grid>
  );
}
