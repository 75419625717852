import YatungButton from '@Src/_basic/components/YatungButton';
import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungPage from '@Src/_basic/components/YatungPage';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { Record, TestItemEnum } from '@Src/_basic/object/TestType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Box, Pagination, Stack } from '@mui/material';
import React, { ChangeEvent, Reducer, memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import CheckSelectedRecord from './CheckSelectedRecord';
import CheckReportTable from './components/CheckReportTable';

type QueryType = {
  factoryId: number;
  exptUnitId?: number;
  exptItemCode: number | TestItemEnum;
  startTime: number;
  endTime: number;
};

type FilterValueType = QueryType & {
  areaId: number;
};

type SearchParams = QueryType & {
  pageNum: number;
  pageSize: number;
};

type AsyncStorageSavingType = SearchParams & FilterValueType;

const CreateSieveReport = () => {
  const location = useLocation();
  const { t: i18T } = useTranslation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [data, setData] = useState<Array<Record>>([]);

  const [viewPage, setViewPage] = useState<number>(1);

  const [checkedReports, setCheckedReports] = useState<Array<Record>>([]);
  const [nextStep, setNextStep] = useState<boolean>(false);

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const initPageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.pageNum ? request.request.pageNum : 1,
      pageSize: 20,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const initFilterValue = useMemo(() => {
    const defaultStart = new Date().getTime() - 1000 * 60 * 60 * 24;
    const defaultEnd = new Date().getTime();

    const {
      areaId = 13,
      factoryId = 25,
      exptUnitId = 0,
      exptItemCode = TestItemEnum.FINE_AGG_SIEVE_ANALYSIS,
      startTime = defaultStart,
      endTime = defaultEnd,
    } = request?.page === location.pathname && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
      exptUnitId,
      exptItemCode,
      startTime,
      endTime,
    };
  }, [request, location.pathname]);

  const initQueryValue = useMemo(
    () => ({
      factoryId: 25,
      exptUnitId: initFilterValue.exptUnitId,
      exptItemCode: initFilterValue.exptItemCode,
      startTime: initFilterValue.startTime,
      endTime: initFilterValue.endTime,
    }),
    [initFilterValue],
  );

  const [filterValue, filterValueDispatch] = useReducer<Reducer<FilterValueType, Partial<FilterValueType>>>(
    updateReducer,
    initFilterValue,
  );
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, initQueryValue);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const searchParams: SearchParams = useMemo(() => {
    return {
      ...query,
      pageNum: pageInfo.page,
      pageSize: pageInfo.pageSize,
    };
  }, [query, pageInfo.page, pageInfo.pageSize]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
  };

  const handleFilterChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    filterValueDispatch({ [field]: value });
  };

  const handleQueryChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    handleFilterChange(field)(value);
    handleQueryChange(field)(value);
  };

  const reset = useCallback(() => {
    setData([]);
    pageInfoDispatch({
      total: 0,
      page: 0,
      totalCount: 0,
    });
  }, []);

  const checkReportHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const checked = data?.find((item) => item.id.toString() === e.target.id);
      if (checked) {
        setCheckedReports((prev) => {
          if (prev.some((item) => item.id.toString() === e.target.id)) {
            return prev.filter((_item) => _item.id.toString() !== e.target.id);
          } else {
            return [...prev, checked];
          }
        });
      }
    },
    [data, setCheckedReports],
  );

  const getReportSearch = useCallback(({ exptUnitId, exptItemCode, startTime, endTime, ...rest }: SearchParams) => {
    TestApi.getRecord(
      {
        ...(exptUnitId && { exptUnitId }),
        ...rest,
        startCompleteTime: startTime,
        endCompleteTime: endTime,
        completed: true,
        timeZone: 'Asia/Taipei',
        exptItemCode: exptItemCode as TestItemEnum,
      },
      (_data) => {
        setData(_data.data);
        pageInfoDispatch({ total: _data.maxPage });
      },
      (error) => {
        setIsShowAlert(true);
        setAlertText(typeof error?.message === 'string' ? error?.message : 'Error !');
      },
    );
  }, []);

  useEffect(() => {
    if (!searchParams.exptItemCode || !searchParams.factoryId) {
      reset();
    } else {
      setSaveRequest({ page: location.pathname, request: { areaId: filterValue.areaId, ...searchParams } });
      getReportSearch(searchParams);
    }
  }, [searchParams]);

  const postReportHandler = useCallback(() => {
    if (!nextStep) return;
    setViewPage(2);
  }, [nextStep]);

  useEffect(() => {
    if (checkedReports.length >= 2) setNextStep(true);
    else setNextStep(false);
  }, [checkedReports]);

  return (
    <YatungPage
      title={i18T('REPORT.SIEVE_REPORT.TITLE')}
      body={
        <Stack>
          <YatungCombinationSelect<QueryType, FilterValueType>
            filterValue={filterValue}
            handleChange={handleChange}
            handleFilterChange={handleFilterChange}
            selectOptions={['Date', 'ExptTestItemType']}
            exptTypeSelection={[
              { text: '細粒料篩分析', value: TestItemEnum.FINE_AGG_SIEVE_ANALYSIS },
              { text: '粗粒料篩分析', value: TestItemEnum.COARSE_AGG_SIEVE_ANALYSIS },
            ]}
          />
          {viewPage === 1 ? (
            <>
              <CheckReportTable
                data={data}
                checkReportHandler={checkReportHandler}
                nextStep={nextStep}
                checkedReports={checkedReports}
              />
              {!!pageInfo.total && (
                <Stack flexDirection="row" justifyContent={'center'} alignItems={'center'} sx={{ mt: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                    <Pagination
                      count={pageInfo.total}
                      page={pageInfo.page}
                      onChange={handlePageChange}
                      color="standard"
                    />
                  </Box>
                  <YatungButton
                    sx={{ height: '35px', justifySelf: 'flex-end', mr: 9 }}
                    color="green"
                    disabled={!nextStep}
                    text={i18T('GLOBAL.NEXT_PAGE')}
                    onClick={postReportHandler}
                  />
                </Stack>
              )}
            </>
          ) : (
            <CheckSelectedRecord
              firstRecord={checkedReports[0]}
              secondRecord={checkedReports[1]}
              setViewPage={setViewPage}
            />
          )}
          <YatungConfirmAlert isShowAlert={isShowAlert} onClose={() => setIsShowAlert(false)} alertText={alertText} />
        </Stack>
      }
    />
  );
};

export default memo(CreateSieveReport);
