import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { ExperimentResultType } from '@Src/_basic/object/TestType';
import { CoarseSieve, CoarseSieveLegalTypeEnum } from '@Src/_basic/object/test/sandRock/CoarseSieveType';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { Button, Stack } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CoarseSieveLineChart from './CoarseSieveLineChart';
import { Axis } from './FineSieveLineChart';

interface Props {
  testFormS1: CoarseSieve;
  testFormS2?: CoarseSieve;
  ratio?: number;
  onSelectTypeChange?: (type: CoarseSieveLegalTypeEnum) => void;
}

const TabCoarseSieveLineChart = ({ testFormS1, testFormS2, ratio, onSelectTypeChange }: Props) => {
  const { t: i18T } = useTranslation();

  const [lowerAxis, setLowerAxis] = useState<Array<Axis>>([]);
  const [upperAxis, setUpperAxis] = useState<Array<Axis>>([]);

  const [selectType, setSelectType] = useState<CoarseSieveLegalTypeEnum>(CoarseSieveLegalTypeEnum.TYPE1);

  const [legalStandardType1, setLegalStandardType1] = useState<Array<LegalStandardV2SpecificationDetailsResponse>>([]);
  const [legalStandardType2, setLegalStandardType2] = useState<Array<LegalStandardV2SpecificationDetailsResponse>>([]);

  const legalTranslateAxis = useCallback(
    (_specs: Array<LegalStandardV2SpecificationDetailsResponse>, type: 'min' | 'max') => {
      return _specs
        .filter(
          (value: LegalStandardV2SpecificationDetailsResponse) => value.minValue !== null && value.maxValue !== null,
        )
        .sort(
          (a, b) =>
            Number(a.experimentDetectionItem.code.split('mm')[0]) -
            Number(b.experimentDetectionItem.code.split('mm')[0]),
        )
        .reduce((prev, current) => {
          if (current.experimentDetectionItem.name.includes('_')) {
            prev.push({
              x: current.experimentDetectionItem.name.split('_')[0],
              y: type === 'min' ? (current.minValue as number) : (current.maxValue as number),
            });
          }
          return prev;
        }, [] as Array<Axis>);
    },
    [i18T],
  );

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.COARSE_AGG_MIX_SIEVE_ANALYSIS,
        effectiveUnixTime: Date.now(),
        type: 1,
      },
      (_data) => setLegalStandardType1(_data),
      () => undefined,
    );
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.COARSE_AGG_MIX_SIEVE_ANALYSIS,
        effectiveUnixTime: Date.now(),
        type: 2,
      },
      (_data) => setLegalStandardType2(_data),
      () => undefined,
    );
  }, []);

  useEffect(() => {
    if (selectType === CoarseSieveLegalTypeEnum.TYPE1) {
      setUpperAxis(legalTranslateAxis(legalStandardType1, 'max'));
      setLowerAxis(legalTranslateAxis(legalStandardType1, 'min'));
    } else {
      setUpperAxis(legalTranslateAxis(legalStandardType2, 'max'));
      setLowerAxis(legalTranslateAxis(legalStandardType2, 'min'));
    }
  }, [selectType, legalStandardType1, legalStandardType2, legalTranslateAxis]);

  useEffect(() => {
    onSelectTypeChange?.(selectType);
  }, [selectType, onSelectTypeChange]);

  return (
    <Stack height={500}>
      <Stack direction="row" justifyContent="center" gap={8} mt={2.5}>
        <Button
          sx={{ ...styles.typeBtn, ...(selectType === CoarseSieveLegalTypeEnum.TYPE1 && styles.typeBtn_active) }}
          onClick={() => setSelectType(CoarseSieveLegalTypeEnum.TYPE1)}
        >
          Type1
        </Button>
        <Button
          sx={{ ...styles.typeBtn, ...(selectType === CoarseSieveLegalTypeEnum.TYPE2 && styles.typeBtn_active) }}
          onClick={() => setSelectType(CoarseSieveLegalTypeEnum.TYPE2)}
        >
          Type2
        </Button>
      </Stack>
      {selectType === CoarseSieveLegalTypeEnum.TYPE1 ? (
        <CoarseSieveLineChart
          lowerAxis={lowerAxis}
          upperAxis={upperAxis}
          testFormS1={testFormS1}
          testFormS2={testFormS2}
          ratio={ratio}
        />
      ) : (
        <CoarseSieveLineChart
          lowerAxis={lowerAxis}
          upperAxis={upperAxis}
          testFormS1={testFormS1}
          testFormS2={testFormS2}
          ratio={ratio}
        />
      )}
    </Stack>
  );
};

export default memo(TabCoarseSieveLineChart);

const styles = {
  typeBtn: {
    width: '35%',
    color: '#fff',
    bgcolor: 'rgba(189, 94, 0, 0.6)',
    fontWeight: 900,
    letterSpacing: 5,
    borderRadius: '20px 20px 0 0',
    transition: 'all 0.3s ease',
    '&:hover': {
      bgcolor: 'rgba(189, 94, 0, 0.4)',
      transition: 'all 0.3s ease',
      transform: 'translateY(-5px)',
    },
  },
  typeBtn_active: {
    transform: 'scale(1.3) translateY(-5px)',
    bgcolor: 'rgba(189, 94, 0, 1)',
  },
};
