import React from 'react';
import { Grid } from '@mui/material';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import { useFeedTroughMaintenance } from '@Src/redux/feedTroughMaintenance/feedTroughMaintenanceActions';
import { WarehouseAndStateData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { useTranslation } from 'react-i18next';

const editImage = '/media/images/edit.png';
interface Props {
  data: WarehouseAndStateData;
}
export default function StorageYardActions(props: Props) {
  const { t: i18T } = useTranslation();
  const { data } = props;
  const { storageYardeData, setStorageYardeData } = useFeedTroughMaintenance();
  const handleEditPaper = () => {
    const mappedData = storageYardeData.map((values: WarehouseAndStateData) => {
      if (values.id === data.id) {
        return { ...values, state: true };
      } else {
        return values;
      }
    });

    setStorageYardeData(mappedData);
  };
  return (
    <Grid container justifyContent={'flex-end'} item xs={12} sx={{ mt: 5 }}>
      <YatungButton
        text={i18T('GLOBAL.UPDATE')}
        variant={'contained'}
        color="inherit"
        type={'button'}
        size={'large'}
        startIcon={<YatungImg img={editImage} />}
        sx={{
          bgcolor: '#F0CA00',
          mr: 2,
          color: '#ffffff',
          '&.MuiButtonBase-root:hover': {
            bgcolor: '#F0CA00',
          },
        }}
        onClick={handleEditPaper}
      />
    </Grid>
  );
}
