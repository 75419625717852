import { ProportionProvider } from '@Src/_basic/contexts/ProportionContext';
import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import Proportion from './Proportion';
import ProportionCreate from './ProportionCreate';
import ProportionEdit from './ProportionEdit';
import ProportionList from './ProportionList';
import ProportionParameterTable from './ProportionParameterTable';
import SafeWaterCalculation from './SafeWaterCalculation';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <ProportionList />,
  },
  {
    path: '/create',
    element: <ProportionCreate />,
  },
  {
    path: '/proportionParameterTable',
    element: <ProportionParameterTable />,
  },
  {
    path: '/version/:propName/:factoryId',
    element: <Proportion />,
  },
  {
    path: '/:proportionId/:versionId',
    element: <ProportionEdit />,
  },
  {
    path: '/:proportionId/:versionId/safeWaterCalculation',
    element: <SafeWaterCalculation />,
  },
];

const ProportionPage = () => <ProportionProvider>{PermissionRoute(routesConfig)}</ProportionProvider>;

export default memo(ProportionPage);
