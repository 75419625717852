import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { Box, Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import CancelIcon from '@Src/_basic/icons/Cancel';
import ConfirmIcon from '@Src/_basic/icons/Confirm';

import BlankSquareIcon from '@Src/_basic/icons/BlankSquare';
import RadioButtonIcon from '@Src/_basic/icons/RadioButton';
import RadioButtonFillIcon from '@Src/_basic/icons/RadioButtonFill';
import LabelInputV2 from './LabelInputV2';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungLoading from '@Src/_basic/components/YatungLoading';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import {
  ImportProportionMaintenance,
  ProportionMaintenanceResponse,
  ProportionResponse,
} from '@Src/_basic/object/ProportionType';
import { SpecificationCostResponse } from '@Src/_basic/object/SpecificationType';
import { ProportionApi } from '@Src/_basic/protocol/proportion/ProportionApi';
import { SpecificationApi } from '@Src/_basic/protocol/specification/SpecificationApi';

const validationSchema = Yup.object().shape({
  factoryId: Yup.number().required(),

  cementSpecifWt: basicNumberSchema,
  slagSpecifWt: basicNumberSchema,
  flyAshSpecifWt: basicNumberSchema,
  microsilicaSpecifWt: basicNumberSchema,

  m1Stone1SpecifWt: basicNumberSchema,
  m1Stone2SpecifWt: basicNumberSchema,
  m1Stone3SpecifWt: basicNumberSchema,
  m1Stone4SpecifWt: basicNumberSchema,
  m1Sand1SpecifWt: basicNumberSchema,
  m1Sand2SpecifWt: basicNumberSchema,
  m1Sand3SpecifWt: basicNumberSchema,
  m1Sand4SpecifWt: basicNumberSchema,

  m2Stone1SpecifWt: basicNumberSchema,
  m2Stone2SpecifWt: basicNumberSchema,
  m2Stone3SpecifWt: basicNumberSchema,
  m2Stone4SpecifWt: basicNumberSchema,
  m2Sand1SpecifWt: basicNumberSchema,
  m2Sand2SpecifWt: basicNumberSchema,
  m2Sand3SpecifWt: basicNumberSchema,
  m2Sand4SpecifWt: basicNumberSchema,

  chemAdmxSpecifWt: basicNumberSchema,
  coraseAggCompDryWt: basicNumberSchema,

  paramAStr: basicNumberSchema,
  paramBStr: basicNumberSchema,

  costCement: basicNumberSchema,
  costSlag: basicNumberSchema,
  costFlyAsh: basicNumberSchema,
  costMicrosilica: basicNumberSchema,

  costSandStone: basicNumberSchema,
  costFineAgg: basicNumberSchema,
  costCoarseAgg: basicNumberSchema,

  costXferAndProc: basicNumberSchema,
  minDsgnStr: basicNumberSchema,

  psi1000CorrCoef: basicNumberSchema,
  psi1500CorrCoef: basicNumberSchema,
  psi2000CorrCoef: basicNumberSchema,
  psi3000CorrCoef: basicNumberSchema,
  psi3500CorrCoef: basicNumberSchema,
  psi4000CorrCoef: basicNumberSchema,
  psi4500CorrCoef: basicNumberSchema,
  psi5000CorrCoef: basicNumberSchema,
  psi6000CorrCoef: basicNumberSchema,
  psi7000CorrCoef: basicNumberSchema,
  psi8000CorrCoef: basicNumberSchema,
  psi10000CorrCoef: basicNumberSchema,
  psi12000CorrCoef: basicNumberSchema,
  psi14000CorrCoef: basicNumberSchema,
  psi16000CorrCoef: basicNumberSchema,
  psi18000CorrCoef: basicNumberSchema,

  cementCEC: basicNumberSchema,
  slagCEC: basicNumberSchema,
  flyAshCEC: basicNumberSchema,
  sandStoneCEC: basicNumberSchema,
  chemicalCEC: basicNumberSchema,
  reclaimedWaterCEC: basicNumberSchema,
  productionCEC: basicNumberSchema,
  transferCEC: basicNumberSchema,
  disposalCEC: basicNumberSchema,
});

const cardTitleStyle = {
  fontWeight: 900,
  fontSize: 20,
  color: '#002F8C',
  textAlign: 'center',
  py: 1,
};

interface Props {
  defaultProportionMaintenance: ProportionMaintenanceResponse;
  mode?: string | undefined;
  editProportion?: {
    proportionId: number;
    versionId: number;
  };
  proportion?: ProportionResponse;
}

function ProportionParameterForm({ defaultProportionMaintenance, mode, editProportion, proportion }: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();

  const [saveMode, setSaveMode] = useState<'permanent' | 'temporary'>('temporary');
  const [loading, setLoading] = useState<boolean>(false);

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);

  const [specificationCostItems, setSpecificationCostItems] = useState<Array<SpecificationCostResponse>>([]);
  const [selectedSpecificationCostItems, setSelectedSpecificationCostItems] = useState<Array<Options>>([]);
  const [selectedSpecificationCostChemAdmxIds, setSelectedSpecificationCostChemAdmxIds] = useState<Array<number>>([]);

  const initialValues: ImportProportionMaintenance = useMemo(
    () => ({
      factoryId: defaultProportionMaintenance.factoryId,

      cementSpecifWt: defaultProportionMaintenance?.cementSpecifWt ?? 0.0,
      slagSpecifWt: defaultProportionMaintenance?.slagSpecifWt ?? 0.0,
      flyAshSpecifWt: defaultProportionMaintenance?.flyAshSpecifWt ?? 0.0,
      microsilicaSpecifWt: defaultProportionMaintenance?.microsilicaSpecifWt ?? 0.0,

      m1Stone1SpecifWt: defaultProportionMaintenance?.m1Stone1SpecifWt ?? 0.0,
      m1Stone2SpecifWt: defaultProportionMaintenance?.m1Stone2SpecifWt ?? 0.0,
      m1Stone3SpecifWt: defaultProportionMaintenance?.m1Stone3SpecifWt ?? 0.0,
      m1Stone4SpecifWt: defaultProportionMaintenance?.m1Stone4SpecifWt ?? 0.0,
      m1Sand1SpecifWt: defaultProportionMaintenance?.m1Sand1SpecifWt ?? 0.0,
      m1Sand2SpecifWt: defaultProportionMaintenance?.m1Sand2SpecifWt ?? 0.0,
      m1Sand3SpecifWt: defaultProportionMaintenance?.m1Sand3SpecifWt ?? 0.0,
      m1Sand4SpecifWt: defaultProportionMaintenance?.m1Sand4SpecifWt ?? 0.0,

      m2Stone1SpecifWt: defaultProportionMaintenance?.m2Stone1SpecifWt ?? 0.0,
      m2Stone2SpecifWt: defaultProportionMaintenance?.m2Stone2SpecifWt ?? 0.0,
      m2Stone3SpecifWt: defaultProportionMaintenance?.m2Stone3SpecifWt ?? 0.0,
      m2Stone4SpecifWt: defaultProportionMaintenance?.m2Stone4SpecifWt ?? 0.0,
      m2Sand1SpecifWt: defaultProportionMaintenance?.m2Sand1SpecifWt ?? 0.0,
      m2Sand2SpecifWt: defaultProportionMaintenance?.m2Sand2SpecifWt ?? 0.0,
      m2Sand3SpecifWt: defaultProportionMaintenance?.m2Sand3SpecifWt ?? 0.0,
      m2Sand4SpecifWt: defaultProportionMaintenance?.m2Sand4SpecifWt ?? 0.0,

      chemAdmxSpecifWt: defaultProportionMaintenance?.chemAdmxSpecifWt ?? 0.0,
      coraseAggCompDryWt: defaultProportionMaintenance?.coraseAggCompDryWt ?? 0.0,

      paramAStr: defaultProportionMaintenance?.paramAStr ?? 0.0,
      paramBStr: defaultProportionMaintenance?.paramBStr ?? 0.0,

      costCement: defaultProportionMaintenance?.costCement ?? 0.0,
      costSlag: defaultProportionMaintenance?.costSlag ?? 0.0,
      costFlyAsh: defaultProportionMaintenance?.costFlyAsh ?? 0.0,
      costMicrosilica: defaultProportionMaintenance?.costMicrosilica ?? 0.0,

      costSandStone: defaultProportionMaintenance?.costSandStone ?? 0.0,
      costFineAgg: defaultProportionMaintenance?.costFineAgg ?? 0.0,
      costCoarseAgg: defaultProportionMaintenance?.costCoarseAgg ?? 0.0,

      costXferAndProc: defaultProportionMaintenance?.costXferAndProc ?? 0.0,
      minDsgnStr: defaultProportionMaintenance?.minDsgnStr ?? 0.0,

      psi1000CorrCoef: defaultProportionMaintenance?.psi1000CorrCoef ?? 0.0,
      psi1500CorrCoef: defaultProportionMaintenance?.psi1500CorrCoef ?? 0.0,
      psi2000CorrCoef: defaultProportionMaintenance?.psi2000CorrCoef ?? 0.0,
      psi3000CorrCoef: defaultProportionMaintenance?.psi3000CorrCoef ?? 0.0,
      psi3500CorrCoef: defaultProportionMaintenance?.psi3500CorrCoef ?? 0.0,
      psi4000CorrCoef: defaultProportionMaintenance?.psi4000CorrCoef ?? 0.0,
      psi4500CorrCoef: defaultProportionMaintenance?.psi4500CorrCoef ?? 0.0,
      psi5000CorrCoef: defaultProportionMaintenance?.psi5000CorrCoef ?? 0.0,
      psi6000CorrCoef: defaultProportionMaintenance?.psi6000CorrCoef ?? 0.0,
      psi7000CorrCoef: defaultProportionMaintenance?.psi7000CorrCoef ?? 0.0,
      psi8000CorrCoef: defaultProportionMaintenance?.psi8000CorrCoef ?? 0.0,
      psi10000CorrCoef: defaultProportionMaintenance?.psi10000CorrCoef ?? 0.0,
      psi12000CorrCoef: defaultProportionMaintenance?.psi12000CorrCoef ?? 0.0,
      psi14000CorrCoef: defaultProportionMaintenance?.psi14000CorrCoef ?? 0.0,
      psi16000CorrCoef: defaultProportionMaintenance?.psi16000CorrCoef ?? 0.0,
      psi18000CorrCoef: defaultProportionMaintenance?.psi18000CorrCoef ?? 0.0,

      cementCEC: defaultProportionMaintenance?.cementCEC ?? 0.0,
      slagCEC: defaultProportionMaintenance?.slagCEC ?? 0.0,
      flyAshCEC: defaultProportionMaintenance?.flyAshCEC ?? 0.0,
      sandStoneCEC: defaultProportionMaintenance?.sandStoneCEC ?? 0.0,
      chemicalCEC: defaultProportionMaintenance?.chemicalCEC ?? 0.0,
      reclaimedWaterCEC: defaultProportionMaintenance?.reclaimedWaterCEC ?? 0.0,
      tapWaterCEC: defaultProportionMaintenance?.tapWaterCEC ?? 0.0,
      productionCEC: defaultProportionMaintenance?.productionCEC ?? 0.0,
      transferCEC: defaultProportionMaintenance?.transferCEC ?? 0.0,
      disposalCEC: defaultProportionMaintenance?.disposalCEC ?? 0.0,

      chemAdmx1Id: defaultProportionMaintenance.chemAdmx1Id,
    }),
    [defaultProportionMaintenance],
  );

  const submitValues = (values: any): ImportProportionMaintenance => {
    return {
      cementSpecifWt: checkIsNaN(+values.cementSpecifWt),
      chemAdmxSpecifWt: checkIsNaN(+values.chemAdmxSpecifWt),
      coraseAggCompDryWt: checkIsNaN(+values.coraseAggCompDryWt),
      costCement: checkIsNaN(+values.costCement),
      costCoarseAgg: checkIsNaN(+values.costCoarseAgg),
      costFineAgg: checkIsNaN(+values.costFineAgg),
      costFlyAsh: checkIsNaN(+values.costFlyAsh),
      costMicrosilica: checkIsNaN(+values.costMicrosilica),
      costSandStone: checkIsNaN(+values.costSandStone),
      costSlag: checkIsNaN(+values.costSlag),
      costXferAndProc: checkIsNaN(+values.costXferAndProc),
      factoryId: checkIsNaN(+values.factoryId),
      flyAshSpecifWt: checkIsNaN(+values.flyAshSpecifWt),

      m1Sand1SpecifWt: checkIsNaN(+values.m1Sand1SpecifWt),
      m1Sand2SpecifWt: checkIsNaN(+values.m1Sand2SpecifWt),
      m1Sand3SpecifWt: checkIsNaN(+values.m1Sand3SpecifWt),
      m1Sand4SpecifWt: checkIsNaN(+values.m1Sand4SpecifWt),
      m1Stone1SpecifWt: checkIsNaN(+values.m1Stone1SpecifWt),
      m1Stone2SpecifWt: checkIsNaN(+values.m1Stone2SpecifWt),
      m1Stone3SpecifWt: checkIsNaN(+values.m1Stone3SpecifWt),
      m1Stone4SpecifWt: checkIsNaN(+values.m1Stone4SpecifWt),

      m2Sand1SpecifWt: checkIsNaN(+values.m2Sand1SpecifWt),
      m2Sand2SpecifWt: checkIsNaN(+values.m2Sand2SpecifWt),
      m2Sand3SpecifWt: checkIsNaN(+values.m2Sand3SpecifWt),
      m2Sand4SpecifWt: checkIsNaN(+values.m2Sand4SpecifWt),
      m2Stone1SpecifWt: checkIsNaN(+values.m2Stone1SpecifWt),
      m2Stone2SpecifWt: checkIsNaN(+values.m2Stone2SpecifWt),
      m2Stone3SpecifWt: checkIsNaN(+values.m2Stone3SpecifWt),
      m2Stone4SpecifWt: checkIsNaN(+values.m2Stone4SpecifWt),

      microsilicaSpecifWt: checkIsNaN(+values.microsilicaSpecifWt),
      minDsgnStr: checkIsNaN(+values.minDsgnStr),
      paramAStr: checkIsNaN(+values.paramAStr),
      paramBStr: checkIsNaN(+values.paramBStr),
      psi10000CorrCoef: checkIsNaN(+values.psi10000CorrCoef),
      psi1000CorrCoef: checkIsNaN(+values.psi1000CorrCoef),
      psi12000CorrCoef: checkIsNaN(+values.psi12000CorrCoef),
      psi14000CorrCoef: checkIsNaN(+values.psi14000CorrCoef),
      psi1500CorrCoef: checkIsNaN(+values.psi1500CorrCoef),
      psi16000CorrCoef: checkIsNaN(+values.psi16000CorrCoef),
      psi18000CorrCoef: checkIsNaN(+values.psi18000CorrCoef),
      psi2000CorrCoef: checkIsNaN(+values.psi2000CorrCoef),
      psi3000CorrCoef: checkIsNaN(+values.psi3000CorrCoef),
      psi3500CorrCoef: checkIsNaN(+values.psi3500CorrCoef),
      psi4000CorrCoef: checkIsNaN(+values.psi4000CorrCoef),
      psi4500CorrCoef: checkIsNaN(+values.psi4500CorrCoef),
      psi5000CorrCoef: checkIsNaN(+values.psi5000CorrCoef),
      psi6000CorrCoef: checkIsNaN(+values.psi6000CorrCoef),
      psi7000CorrCoef: checkIsNaN(+values.psi7000CorrCoef),
      psi8000CorrCoef: checkIsNaN(+values.psi8000CorrCoef),
      slagSpecifWt: checkIsNaN(+values.slagSpecifWt),

      cementCEC: checkIsNaN(+values.cementCEC),
      slagCEC: checkIsNaN(+values.slagCEC),
      flyAshCEC: checkIsNaN(+values.flyAshCEC),
      sandStoneCEC: checkIsNaN(+values.sandStoneCEC),
      chemicalCEC: checkIsNaN(+values.chemicalCEC),
      reclaimedWaterCEC: checkIsNaN(+values.reclaimedWaterCEC),
      tapWaterCEC: checkIsNaN(+values.tapWaterCEC),
      productionCEC: checkIsNaN(+values.productionCEC),
      transferCEC: checkIsNaN(+values.transferCEC),
      disposalCEC: checkIsNaN(+values.disposalCEC),

      chemAdmx1Id: selectedSpecificationCostChemAdmxIds[0] ?? null,
      chemAdmx2Id: selectedSpecificationCostChemAdmxIds[1] ?? null,
      chemAdmx3Id: selectedSpecificationCostChemAdmxIds[2] ?? null,
      chemAdmx4Id: selectedSpecificationCostChemAdmxIds[3] ?? null,
      chemAdmx5Id: selectedSpecificationCostChemAdmxIds[4] ?? null,
      chemAdmx6Id: selectedSpecificationCostChemAdmxIds[5] ?? null,
    };
  };

  const isSpecificationCostDisable = useCallback(
    (value: number) =>
      selectedSpecificationCostChemAdmxIds.length >= 6 && !selectedSpecificationCostChemAdmxIds.includes(value),
    [selectedSpecificationCostChemAdmxIds],
  );

  const onPressSpecificationCost = useCallback(
    (value: SpecificationCostResponse) => {
      if (selectedSpecificationCostChemAdmxIds.includes(value.specificationId)) {
        setSelectedSpecificationCostChemAdmxIds((prev) => prev.filter((_value) => _value !== value.specificationId));
      } else {
        setSelectedSpecificationCostChemAdmxIds((prev) => [...prev, value.specificationId]);
      }
    },
    [selectedSpecificationCostChemAdmxIds],
  );

  const getSelectedSpecCostItem = (id: number) => specificationCostItems.find((s) => s.specificationId === id);

  const switchSpecificationCostChemAdmxIds = (current: number, target: number) => {
    const currentIndex = selectedSpecificationCostChemAdmxIds.findIndex((id) => id === current);
    const targetIndex = selectedSpecificationCostChemAdmxIds.findIndex((id) => id === target);
    const newChemAdmxIds = [...selectedSpecificationCostChemAdmxIds];
    newChemAdmxIds[currentIndex] = target;
    newChemAdmxIds[targetIndex] = current;
    setSelectedSpecificationCostChemAdmxIds(newChemAdmxIds);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (!defaultProportionMaintenance) return;

      setLoading(true);
      ProportionApi.postProportionMaintenance(
        {
          ...submitValues(values),
          isUpdateToDefault: saveMode === 'temporary' ? false : true,
        },
        (_data) => {
          switch (mode) {
            case 'create':
              navigate('/proportionManage/proportionList/create', { state: { afterProportionMaintenance: _data } });
              return;
            case 'edit':
              navigate(
                `/proportionManage/proportionList/${editProportion?.proportionId}/${editProportion?.versionId}`,
                { state: { afterProportionMaintenance: _data, proportion } },
              );
              return;
            default:
              return;
          }
        },
        () => setLoading(false),
      );
    },
  });

  const navigateBack = () => {
    switch (mode) {
      case 'edit':
        navigate(`/proportionManage/proportionList/${editProportion?.proportionId}/${editProportion?.versionId}`, {
          state: { afterProportionMaintenance: defaultProportionMaintenance, proportion },
        });
        return;
      default:
        navigate(-1);
        return;
    }
  };

  useEffect(() => {
    SpecificationApi.getAllSpecificationCost({ factoryId: defaultProportionMaintenance?.factoryId }, (_data) =>
      setSpecificationCostItems(_data),
    );
  }, [defaultProportionMaintenance?.factoryId]);

  useEffect(() => {
    if (specificationCostItems) {
      const selectedSpecCostItems = specificationCostItems
        .filter((s) => selectedSpecificationCostChemAdmxIds.includes(s.specificationId))
        .map((s) => ({ text: s.specificationName, value: s.specificationId }));
      setSelectedSpecificationCostItems(selectedSpecCostItems);
    }
  }, [selectedSpecificationCostChemAdmxIds, specificationCostItems]);

  useEffect(() => {
    if (defaultProportionMaintenance) {
      setSelectedSpecificationCostChemAdmxIds([
        ...(defaultProportionMaintenance.chemAdmx1Id ? [defaultProportionMaintenance.chemAdmx1Id] : []),
        ...(defaultProportionMaintenance.chemAdmx2Id ? [defaultProportionMaintenance.chemAdmx2Id] : []),
        ...(defaultProportionMaintenance.chemAdmx3Id ? [defaultProportionMaintenance.chemAdmx3Id] : []),
        ...(defaultProportionMaintenance.chemAdmx4Id ? [defaultProportionMaintenance.chemAdmx4Id] : []),
        ...(defaultProportionMaintenance.chemAdmx5Id ? [defaultProportionMaintenance.chemAdmx5Id] : []),
        ...(defaultProportionMaintenance.chemAdmx6Id ? [defaultProportionMaintenance.chemAdmx6Id] : []),
      ]);
    }
  }, [defaultProportionMaintenance]);

  return (
    <>
      {loading ? (
        <YatungLoading overlay={true} />
      ) : (
        <Box sx={{ p: 2, border: '1px solid #6C708C', borderRadius: '8px', minWidth: '1536px' }}>
          <Stack flexDirection="row" justifyContent="space-between" sx={{ position: 'relative' }}>
            <Box sx={{ width: '32.5%' }}>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <LabelInputV2
                      label={i18T('PROPORTION.CEMENT_SPECIFIC_GRAVITY')}
                      value={formik.values.cementSpecifWt}
                      onChange={(text: string) => formik.setFieldValue('cementSpecifWt', text)}
                      isError={!!(formik.touched.cementSpecifWt && formik.errors.cementSpecifWt)}
                      letterSpacing={1}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.SLAG_SPECIFIC_GRAVITY')}
                      value={formik.values.slagSpecifWt}
                      onChange={(text: string) => formik.setFieldValue('slagSpecifWt', text)}
                      isError={!!(formik.touched.slagSpecifWt && formik.errors.slagSpecifWt)}
                      letterSpacing={1}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.FLY_ASH_SPECIFIC_GRAVITY')}
                      value={formik.values.flyAshSpecifWt}
                      onChange={(text: string) => formik.setFieldValue('flyAshSpecifWt', text)}
                      isError={!!(formik.touched.flyAshSpecifWt && formik.errors.flyAshSpecifWt)}
                      letterSpacing={1}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.MICROSILICA_SPECIFIC_GRAVITY')}
                      value={formik.values.microsilicaSpecifWt}
                      onChange={(text: string) => formik.setFieldValue('microsilicaSpecifWt', text)}
                      isError={!!(formik.touched.microsilicaSpecifWt && formik.errors.microsilicaSpecifWt)}
                      letterSpacing={1}
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Typography sx={cardTitleStyle}>{`${i18T('PROPORTION.MIXER_NUMBER', { number: 1 })}(${i18T(
                    'PROPORTION.SPECIFIC_GRAVITY',
                  )})`}</Typography>
                  <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                    <LabelInputV2
                      label={i18T('PROPORTION.STONE1')}
                      value={formik.values.m1Stone1SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m1Stone1SpecifWt', text)}
                      isError={!!(formik.touched.m1Stone1SpecifWt && formik.errors.m1Stone1SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.STONE2')}
                      value={formik.values.m1Stone2SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m1Stone2SpecifWt', text)}
                      isError={!!(formik.touched.m1Stone2SpecifWt && formik.errors.m1Stone2SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.STONE3')}
                      value={formik.values.m1Stone3SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m1Stone3SpecifWt', text)}
                      isError={!!(formik.touched.m1Stone3SpecifWt && formik.errors.m1Stone3SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.STONE4')}
                      value={formik.values.m1Stone4SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m1Stone4SpecifWt', text)}
                      isError={!!(formik.touched.m1Stone4SpecifWt && formik.errors.m1Stone4SpecifWt)}
                    />
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <LabelInputV2
                      label={i18T('PROPORTION.SAND1')}
                      value={formik.values.m1Sand1SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m1Sand1SpecifWt', text)}
                      isError={!!(formik.touched.m1Sand1SpecifWt && formik.errors.m1Sand1SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.SAND2')}
                      value={formik.values.m1Sand2SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m1Sand2SpecifWt', text)}
                      isError={!!(formik.touched.m1Sand2SpecifWt && formik.errors.m1Sand2SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.SAND3')}
                      value={formik.values.m1Sand3SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m1Sand3SpecifWt', text)}
                      isError={!!(formik.touched.m1Sand3SpecifWt && formik.errors.m1Sand3SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.SAND4')}
                      value={formik.values.m1Sand4SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m1Sand4SpecifWt', text)}
                      isError={!!(formik.touched.m1Sand4SpecifWt && formik.errors.m1Sand4SpecifWt)}
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Typography sx={cardTitleStyle}>{`${i18T('PROPORTION.MIXER_NUMBER', { number: 2 })}(${i18T(
                    'PROPORTION.SPECIFIC_GRAVITY',
                  )})`}</Typography>
                  <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                    <LabelInputV2
                      label={i18T('PROPORTION.STONE1')}
                      value={formik.values.m2Stone1SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m2Stone1SpecifWt', text)}
                      isError={!!(formik.touched.m2Stone1SpecifWt && formik.errors.m2Stone1SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.STONE2')}
                      value={formik.values.m2Stone2SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m2Stone2SpecifWt', text)}
                      isError={!!(formik.touched.m2Stone2SpecifWt && formik.errors.m2Stone2SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.STONE3')}
                      value={formik.values.m2Stone3SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m2Stone3SpecifWt', text)}
                      isError={!!(formik.touched.m2Stone3SpecifWt && formik.errors.m2Stone3SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.STONE4')}
                      value={formik.values.m2Stone4SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m2Stone4SpecifWt', text)}
                      isError={!!(formik.touched.m2Stone4SpecifWt && formik.errors.m2Stone4SpecifWt)}
                    />
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <LabelInputV2
                      label={i18T('PROPORTION.SAND1')}
                      value={formik.values.m2Sand1SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m2Sand1SpecifWt', text)}
                      isError={!!(formik.touched.m2Sand1SpecifWt && formik.errors.m2Sand1SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.SAND2')}
                      value={formik.values.m2Sand2SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m2Sand2SpecifWt', text)}
                      isError={!!(formik.touched.m2Sand2SpecifWt && formik.errors.m2Sand2SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.SAND3')}
                      value={formik.values.m2Sand3SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m2Sand3SpecifWt', text)}
                      isError={!!(formik.touched.m2Sand3SpecifWt && formik.errors.m2Sand3SpecifWt)}
                    />

                    <LabelInputV2
                      label={i18T('PROPORTION.SAND4')}
                      value={formik.values.m2Sand4SpecifWt}
                      onChange={(text: string) => formik.setFieldValue('m2Sand4SpecifWt', text)}
                      isError={!!(formik.touched.m2Sand4SpecifWt && formik.errors.m2Sand4SpecifWt)}
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <LabelInputV2
                      label={i18T('PROPORTION.CHEMICALS_SPECIFIC_GRAVITY')}
                      value={formik.values.chemAdmxSpecifWt}
                      onChange={(text: string) => formik.setFieldValue('chemAdmxSpecifWt', text)}
                      isError={!!(formik.touched.chemAdmxSpecifWt && formik.errors.chemAdmxSpecifWt)}
                      letterSpacing={1}
                      labelAlign="left"
                    />
                    <LabelInputV2
                      label={`${i18T('PROPORTION.COARSE_AGG_COMP_DRY_WEIGHT')}(kg)`}
                      value={formik.values.coraseAggCompDryWt}
                      onChange={(text: string) => formik.setFieldValue('coraseAggCompDryWt', text)}
                      isError={!!(formik.touched.coraseAggCompDryWt && formik.errors.coraseAggCompDryWt)}
                      letterSpacing={1}
                      labelAlign="left"
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Typography sx={cardTitleStyle}>{i18T('PROPORTION.STRENGTH_FACTOR_FIX')}</Typography>
                  <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                    <LabelInputV2
                      label="1000 Psi"
                      value={formik.values.psi1000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi1000CorrCoef', text)}
                      isError={!!(formik.touched.psi1000CorrCoef && formik.errors.psi1000CorrCoef)}
                      letterSpacing={2}
                    />
                    <LabelInputV2
                      label="1500 Psi"
                      value={formik.values.psi1500CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi1500CorrCoef', text)}
                      isError={!!(formik.touched.psi1500CorrCoef && formik.errors.psi1500CorrCoef)}
                      letterSpacing={2}
                    />
                    <LabelInputV2
                      label="2000 Psi"
                      value={formik.values.psi2000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi2000CorrCoef', text)}
                      isError={!!(formik.touched.psi2000CorrCoef && formik.errors.psi2000CorrCoef)}
                      letterSpacing={2}
                    />
                    <LabelInputV2
                      label="3000 Psi"
                      value={formik.values.psi3000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi3000CorrCoef', text)}
                      isError={!!(formik.touched.psi3000CorrCoef && formik.errors.psi3000CorrCoef)}
                      letterSpacing={2}
                    />
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                    <LabelInputV2
                      label="3500 Psi"
                      value={formik.values.psi3500CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi3500CorrCoef', text)}
                      isError={!!(formik.touched.psi3500CorrCoef && formik.errors.psi3500CorrCoef)}
                      letterSpacing={2}
                    />
                    <LabelInputV2
                      label="4000 Psi"
                      value={formik.values.psi4000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi4000CorrCoef', text)}
                      isError={!!(formik.touched.psi4000CorrCoef && formik.errors.psi4000CorrCoef)}
                      letterSpacing={2}
                    />
                    <LabelInputV2
                      label="4500 Psi"
                      value={formik.values.psi4500CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi4500CorrCoef', text)}
                      isError={!!(formik.touched.psi4500CorrCoef && formik.errors.psi4500CorrCoef)}
                      letterSpacing={2}
                    />
                    <LabelInputV2
                      label="5000 Psi"
                      value={formik.values.psi5000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi5000CorrCoef', text)}
                      isError={!!(formik.touched.psi5000CorrCoef && formik.errors.psi5000CorrCoef)}
                      letterSpacing={2}
                    />
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                    <LabelInputV2
                      label="6000 Psi"
                      value={formik.values.psi6000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi6000CorrCoef', text)}
                      isError={!!(formik.touched.psi6000CorrCoef && formik.errors.psi6000CorrCoef)}
                      letterSpacing={2}
                    />
                    <LabelInputV2
                      label="7000 Psi"
                      value={formik.values.psi7000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi7000CorrCoef', text)}
                      isError={!!(formik.touched.psi7000CorrCoef && formik.errors.psi7000CorrCoef)}
                      letterSpacing={2}
                    />
                    <LabelInputV2
                      label="8000 Psi"
                      value={formik.values.psi8000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi8000CorrCoef', text)}
                      isError={!!(formik.touched.psi8000CorrCoef && formik.errors.psi8000CorrCoef)}
                      letterSpacing={2}
                    />
                    <LabelInputV2
                      label="10000 Psi"
                      value={formik.values.psi10000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi10000CorrCoef', text)}
                      isError={!!(formik.touched.psi10000CorrCoef && formik.errors.psi10000CorrCoef)}
                      letterSpacing={0}
                    />
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                    <LabelInputV2
                      label="12000 Psi"
                      value={formik.values.psi12000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi12000CorrCoef', text)}
                      isError={!!(formik.touched.psi12000CorrCoef && formik.errors.psi12000CorrCoef)}
                      letterSpacing={0}
                    />
                    <LabelInputV2
                      label="14000 Psi"
                      value={formik.values.psi14000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi14000CorrCoef', text)}
                      isError={!!(formik.touched.psi14000CorrCoef && formik.errors.psi14000CorrCoef)}
                      letterSpacing={0}
                    />
                    <LabelInputV2
                      label="16000 Psi"
                      value={formik.values.psi16000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi16000CorrCoef', text)}
                      isError={!!(formik.touched.psi16000CorrCoef && formik.errors.psi16000CorrCoef)}
                      letterSpacing={0}
                    />
                    <LabelInputV2
                      label="18000 Psi"
                      value={formik.values.psi18000CorrCoef}
                      onChange={(text: string) => formik.setFieldValue('psi18000CorrCoef', text)}
                      isError={!!(formik.touched.psi18000CorrCoef && formik.errors.psi18000CorrCoef)}
                      letterSpacing={0}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{ width: '32.5%' }}>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <LabelInputV2
                      label={`${i18T('PROPORTION.STR_PARAMETER')}(a)`}
                      value={formik.values.paramAStr}
                      onChange={(text: string) => formik.setFieldValue('paramAStr', text)}
                      isError={!!(formik.touched.paramAStr && formik.errors.paramAStr)}
                      letterSpacing={1}
                      labelAlign="left"
                    />
                    <LabelInputV2
                      label={`${i18T('PROPORTION.STR_PARAMETER')}(b)`}
                      value={formik.values.paramBStr}
                      onChange={(text: string) => formik.setFieldValue('paramBStr', text)}
                      isError={!!(formik.touched.paramBStr && formik.errors.paramBStr)}
                      letterSpacing={1}
                      labelAlign="left"
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <LabelInputV2
                      label={i18T('PROPORTION.CEMENT_PRICE')}
                      value={formik.values.costCement}
                      onChange={(text: string) => formik.setFieldValue('costCement', text)}
                      isError={!!(formik.touched.costCement && formik.errors.costCement)}
                      letterSpacing={1}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.SLAG_PRICE')}
                      value={formik.values.costSlag}
                      onChange={(text: string) => formik.setFieldValue('costSlag', text)}
                      isError={!!(formik.touched.costSlag && formik.errors.costSlag)}
                      letterSpacing={1}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.FLY_ASH_PRICE')}
                      value={formik.values.costFlyAsh}
                      onChange={(text: string) => formik.setFieldValue('costFlyAsh', text)}
                      isError={!!(formik.touched.costFlyAsh && formik.errors.costFlyAsh)}
                      letterSpacing={1}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.MICROSILICA_PRICE')}
                      value={formik.values.costMicrosilica}
                      onChange={(text: string) => formik.setFieldValue('costMicrosilica', text)}
                      isError={!!(formik.touched.costMicrosilica && formik.errors.costMicrosilica)}
                      letterSpacing={1}
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <LabelInputV2
                      label={
                        <>
                          {i18T('PROPORTION.SAND_ROCK')}
                          <br />
                          {i18T('PROPORTION.PRICE')}
                        </>
                      }
                      value={formik.values.costSandStone}
                      onChange={(text: string) => formik.setFieldValue('costSandStone', text)}
                      isError={!!(formik.touched.costSandStone && formik.errors.costSandStone)}
                      letterSpacing={1}
                      labelAlign="left"
                    />
                    <LabelInputV2
                      label={
                        <>
                          {i18T('PROPORTION.COARSE_AGG')}
                          <br />
                          {`${i18T('PROPORTION.PRICE')}(/kg)`}
                        </>
                      }
                      value={formik.values.costFineAgg}
                      onChange={(text: string) => formik.setFieldValue('costFineAgg', text)}
                      isError={!!(formik.touched.costFineAgg && formik.errors.costFineAgg)}
                      letterSpacing={1}
                      labelAlign="left"
                    />
                    <LabelInputV2
                      label={
                        <>
                          {i18T('PROPORTION.FINE_AGG')}
                          <br />
                          {`${i18T('PROPORTION.PRICE')}(/kg)`}
                        </>
                      }
                      value={formik.values.costCoarseAgg}
                      onChange={(text: string) => formik.setFieldValue('costCoarseAgg', text)}
                      isError={!!(formik.touched.costCoarseAgg && formik.errors.costCoarseAgg)}
                      letterSpacing={1}
                      labelAlign="left"
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <LabelInputV2
                      label={`${i18T('PROPORTION.TRANSFORM_PRICE')}`}
                      value={formik.values.costXferAndProc}
                      onChange={(text: string) => formik.setFieldValue('costXferAndProc', text)}
                      isError={!!(formik.touched.costXferAndProc && formik.errors.costXferAndProc)}
                      letterSpacing={1}
                      labelAlign="left"
                    />
                    <LabelInputV2
                      label={`${i18T('PROPORTION.DESIGN_STR_limit')}(%)`}
                      value={formik.values.minDsgnStr}
                      onChange={(text: string) => formik.setFieldValue('minDsgnStr', text)}
                      isError={!!(formik.touched.minDsgnStr && formik.errors.minDsgnStr)}
                      letterSpacing={1}
                      labelAlign="left"
                    />
                  </Stack>
                </CardContent>
              </Card>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  {selectedSpecificationCostChemAdmxIds.map((id: number, i: number) => {
                    return (
                      <Stack
                        flexDirection="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        sx={{ pb: 2 }}
                        key={`${id}_${i}`}
                      >
                        <Stack flexDirection="column" sx={{ mr: 4, width: '60%' }}>
                          <Typography sx={{ fontSize: '20px', fontWeight: 900, color: '#002F8C' }}>
                            {`${i18T('PROPORTION.CHEM_ADMX_USE', {
                              number: i + 1,
                            })}`}
                          </Typography>
                          <YatungSelect
                            options={selectedSpecificationCostItems}
                            value={id}
                            width={300}
                            onChange={(e) => switchSpecificationCostChemAdmxIds(id, e.target.value as number)}
                          />
                        </Stack>
                        <Stack flexDirection="column" width={100} mt={1}>
                          <Typography sx={{ fontSize: '20px', fontWeight: 900, color: '#002F8C', textAlign: 'center' }}>
                            {`${i18T('PROPORTION.CHEM_ADMX_PRICE', {
                              number: i + 1,
                            })}`}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: 400,
                              color: '#697EA3',
                              lineHeight: '40px',
                              textAlign: 'center',
                            }}
                          >
                            {getSelectedSpecCostItem(id)?.cost}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  })}
                </CardContent>
              </Card>
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Typography sx={{ ...cardTitleStyle, color: '#00D03A' }}>
                    {i18T('PROPORTION.CARBON_EMISSION_FACTOR')}
                  </Typography>
                  <Stack flexDirection="row" justifyContent="space-between" sx={{ mb: 1 }}>
                    <LabelInputV2
                      label={i18T('PROPORTION.CEMENT')}
                      float={6}
                      value={formik.values.cementCEC}
                      onChange={(text: string) => formik.setFieldValue('cementCEC', text)}
                      isError={!!(formik.touched.cementCEC && formik.errors.cementCEC)}
                      letterSpacing={1}
                      labelAlign="left"
                      labelColor="#00D03A"
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.SLAG')}
                      value={formik.values.slagCEC}
                      onChange={(text: string) => formik.setFieldValue('slagCEC', text)}
                      isError={!!(formik.touched.slagCEC && formik.errors.slagCEC)}
                      letterSpacing={1}
                      labelAlign="left"
                      labelColor="#00D03A"
                      float={6}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.FLY_ASH')}
                      value={formik.values.flyAshCEC}
                      onChange={(text: string) => formik.setFieldValue('flyAshCEC', text)}
                      isError={!!(formik.touched.flyAshCEC && formik.errors.flyAshCEC)}
                      letterSpacing={1}
                      labelAlign="left"
                      labelColor="#00D03A"
                      float={6}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.SAND_AND_STONE')}
                      value={formik.values.sandStoneCEC}
                      onChange={(text: string) => formik.setFieldValue('sandStoneCEC', text)}
                      isError={!!(formik.touched.sandStoneCEC && formik.errors.sandStoneCEC)}
                      letterSpacing={1}
                      labelAlign="left"
                      labelColor="#00D03A"
                      float={6}
                    />
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <LabelInputV2
                      label={i18T('PROPORTION.POTION')}
                      value={formik.values.chemicalCEC}
                      onChange={(text: string) => formik.setFieldValue('chemicalCEC', text)}
                      isError={!!(formik.touched.chemicalCEC && formik.errors.chemicalCEC)}
                      letterSpacing={1}
                      labelAlign="left"
                      labelColor="#00D03A"
                      float={6}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.RECYCLED_WATER')}
                      value={formik.values.reclaimedWaterCEC}
                      onChange={(text: string) => formik.setFieldValue('reclaimedWaterCEC', text)}
                      isError={!!(formik.touched.reclaimedWaterCEC && formik.errors.reclaimedWaterCEC)}
                      letterSpacing={1}
                      labelAlign="left"
                      labelColor="#00D03A"
                      float={6}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.TAP_WATER')}
                      value={formik.values.tapWaterCEC}
                      onChange={(text: string) => formik.setFieldValue('tapWaterCEC', text)}
                      isError={!!(formik.touched.tapWaterCEC && formik.errors.tapWaterCEC)}
                      letterSpacing={1}
                      labelAlign="left"
                      labelColor="#00D03A"
                      float={6}
                    />
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between">
                    <LabelInputV2
                      label={i18T('PROPORTION.PROCESS_CARBON_EMISSION')}
                      value={formik.values.productionCEC}
                      onChange={(text: string) => formik.setFieldValue('productionCEC', text)}
                      isError={!!(formik.touched.productionCEC && formik.errors.productionCEC)}
                      letterSpacing={1}
                      labelAlign="left"
                      labelColor="#00D03A"
                      float={6}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.ABANDONED_CARBON_EMISSION')}
                      value={formik.values.transferCEC}
                      onChange={(text: string) => formik.setFieldValue('transferCEC', text)}
                      isError={!!(formik.touched.transferCEC && formik.errors.transferCEC)}
                      letterSpacing={1}
                      labelAlign="left"
                      labelColor="#00D03A"
                      float={6}
                    />
                    <LabelInputV2
                      label={i18T('PROPORTION.ABANDONED_CARBON_EMISSION')}
                      value={formik.values.disposalCEC}
                      onChange={(text: string) => formik.setFieldValue('disposalCEC', text)}
                      isError={!!(formik.touched.disposalCEC && formik.errors.disposalCEC)}
                      letterSpacing={1}
                      labelAlign="left"
                      labelColor="#00D03A"
                      float={6}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{ width: '32.5%' }} display="flex" flexDirection="column" alignItems="flex-end">
              <Card
                sx={{
                  my: 1,
                  width: '100%',
                  background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #E9E9E9',
                  boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardContent>
                  <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                    <Typography sx={{ flex: '1 0 0', fontWeight: 900, color: '#002F8C' }}>{`${i18T(
                      'PROPORTION.CHEMICAL',
                    )}${i18T('PROPORTION.USE')}`}</Typography>
                    <Typography sx={{ width: '90px', fontWeight: 900, color: '#002F8C' }}>
                      {i18T('PROPORTION.ORDER')}
                    </Typography>
                    <Typography sx={{ width: '48px', fontWeight: 900, color: '#002F8C' }}>
                      {i18T('PROPORTION.REFERENCE_PRICE')}
                    </Typography>
                  </Stack>
                  {specificationCostItems.map((d, i) => {
                    const selectedIndex = selectedSpecificationCostItems.findIndex(
                      (drug: any) => drug.value == d.specificationId,
                    );
                    return (
                      <Box key={`${d.specificationId}_${i}`}>
                        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                          <IconButton
                            sx={{ mr: 1 }}
                            onClick={() => onPressSpecificationCost(d)}
                            disabled={isSpecificationCostDisable(d.specificationId)}
                          >
                            {selectedIndex > -1 ? <ConfirmIcon /> : <BlankSquareIcon />}
                          </IconButton>
                          <Typography sx={{ flex: '1 0 0' }}>{d.specificationName}</Typography>
                          {selectedSpecificationCostChemAdmxIds.map((_value, _index) => {
                            if (selectedSpecificationCostChemAdmxIds[_index] === d.specificationId) {
                              return (
                                <Typography sx={{ width: 90 }} key={`${_value}_${_index}`}>
                                  {`#${_index + 1}`}
                                </Typography>
                              );
                            }
                          })}
                          <Typography
                            sx={{
                              fontWeight: 900,
                              color: '#002F8C',
                              width: '48px',
                            }}
                          >
                            {d.cost}
                          </Typography>
                        </Stack>
                      </Box>
                    );
                  })}
                </CardContent>
              </Card>
              <Card sx={{ my: 1, width: '100%', boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
                <CardContent>
                  <Stack flexDirection="row" alignItems="center">
                    <IconButton
                      onClick={() => {
                        setSaveMode('permanent');
                        setIsShowAlert(true);
                      }}
                    >
                      {saveMode === 'permanent' ? (
                        <RadioButtonFillIcon sx={{ color: '#002F8C' }} />
                      ) : (
                        <RadioButtonIcon />
                      )}
                    </IconButton>
                    <Typography>{i18T('PROPORTION.SAVE_AS_DEFAULT')}</Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    <IconButton onClick={() => setSaveMode('temporary')}>
                      {saveMode === 'temporary' ? (
                        <RadioButtonFillIcon sx={{ color: '#002F8C' }} />
                      ) : (
                        <RadioButtonIcon />
                      )}
                    </IconButton>
                    <Typography>{i18T('PROPORTION.SAVE_AS_NOW_PROPORTION')}</Typography>
                  </Stack>
                </CardContent>
              </Card>
              <Stack flexDirection="row" justifyContent="flex-end" sx={{ my: 2 }}>
                <YatungButton sx={{ mr: 1 }} color="red" onClick={navigateBack} startIcon={<CancelIcon />}>
                  {i18T('GLOBAL.CANCEL')}
                </YatungButton>
                <YatungButton color="blue" onClick={formik.handleSubmit} startIcon={<ConfirmIcon />}>
                  {i18T('GLOBAL.FINISH')}
                </YatungButton>
              </Stack>
            </Box>
          </Stack>
          <YatungConfirmAlert
            isShowAlert={isShowAlert}
            alertText={i18T('PROPORTION.SET_WILL_CHANGE_DEFAULT')}
            onClose={() => setIsShowAlert(false)}
          />
        </Box>
      )}
    </>
  );
}

export default memo(ProportionParameterForm);
