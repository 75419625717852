import Stack from '@mui/material/Stack';
import moment from 'moment';
import React, { useMemo } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';

import DetailIcon from '@Src/_basic/icons/Detail';
import { useTranslation } from 'react-i18next';

export default function SampleCourseTable({ onViewDetail, rows, loading }: any) {
  const { t: i18T } = useTranslation();
  const tableData = useMemo(() => {
    return rows?.map((item: any, index: number) => ({ ...item, number: index + 1 }));
  }, [rows]);
  //將顯示ID(DB的ID數字過大)替換成顯示個數的displayId，處理時再使用ID

  return (
    <YatungTable
      loading={loading}
      data={tableData}
      columns={[
        {
          text: i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.NO'),
          dataField: 'number',
        },
        {
          text: '樣品ID',
          dataField: 'sampleId',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.SAMPLE_DATE'),
          dataField: 'receiptCompleteTime',
          formatter: ({ row }) => {
            return <>{moment(row.samplingTime).format('YYYY/MM/DD HH:mm')}</>;
          },
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.SAMPLE_TYPE'),
          dataField: 'typeName',
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.TYPE'),
          dataField: 'specificationName',
        },

        {
          text: i18T('GLOBAL.ACTION'),
          dataField: 'action',
          formatter: ({ row }) => {
            return (
              <Stack direction="row" spacing={1}>
                <YatungButton
                  text="詳細"
                  startIcon={<DetailIcon />}
                  color="yellow"
                  onClick={() => {
                    onViewDetail(row.sampleId, row.specificationName, moment(row.samplingTime).format('YYYY/MM/DD '));
                  }}
                />
              </Stack>
            );
          },
        },
      ]}
    />
  );
}
