import { AllTest } from '@Src/_basic/object/TestType';
import { FineSieve } from '@Src/_basic/object/test/sandRock/FineSieveType';
import ExportFineSieveTable from '@Src/app/module/test/executeTest/fineSieve/ExportFineSieveTable';
import React, { memo } from 'react';

interface Props {
  testDetail: FineSieve;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
  closeExecuteTestHandler?: () => void;
}

const FineSieveAnalysisCommonTable = ({ testDetail, afterUpdateTest, canEdit, closeExecuteTestHandler }: Props) => {
  return (
    <>
      <ExportFineSieveTable testDetail={testDetail} afterUpdateTest={afterUpdateTest} canEdit={canEdit} closeExecuteTestHandler={closeExecuteTestHandler} />
    </>
  );
};

export default memo(FineSieveAnalysisCommonTable);
