import AdapterDateFns from '@date-io/date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import zhLocale from 'date-fns/locale/zh-TW'; // 引入中文語言包
import React from 'react';
import CalendarIcon from '../icons/Calendar';
import YatungInput from './YatungInput';

interface Props {
  onChange: (value: Date | null) => void;
  value: Date | null;
  disableFuture?: boolean;
  disablePast?: boolean;
  maxDate?: any;
  minDate?: any;
  disabled?: boolean;
  label?: string;
  icon?: any;
  fullWidth?: boolean;
  error?: string;
  mode?: 'Month';
}
export default function YatungDatePicker({
  onChange,
  value,
  icon = CalendarIcon,
  fullWidth,
  error,
  mode,
  ...props
}: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhLocale}>
      <DatePicker
        views={mode === 'Month' ? ['year', 'month'] : ['day']}
        maxDate={mode === 'Month' ? new Date() : undefined}
        inputFormat={`yyyy/MM${mode === 'Month' ? '' : '/dd'}`}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <YatungInput
            {...params}
            error={error}
            helperText={error}
            sx={{ ...props, width: fullWidth ? '100%' : 180 }}
          />
        )}
        components={{
          OpenPickerIcon: icon,
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}
