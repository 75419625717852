/* eslint-disable no-unused-vars */
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { SelectChangeEvent, Stack } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFactoryOptionsByAreaId } from '../helpers/OptionsHelpers';
import { GetAllName, SearchPaginationUser, SearchPaginationUserRequest } from '../object/EquipmentManagementType';
import { TestItemEnum } from '../object/TestType';
import { TestTypeData } from '../object/TestTypeType';
import { TypeData } from '../object/TypeType';
import { EntityApi } from '../protocol/entity/EntityApi';
import { SpecificationApi } from '../protocol/specification/SpecificationApi';
import { TypeApi } from '../protocol/type/TypeApi';
import YatungDateSelect from './YatungDateSelect';
import YatungSelect, { DropdownItem, Options } from './YatungSelect';
import { TextOptions } from './YatungTextSelect';

type SelectType =
  | 'Date'
  | 'AreaAndFactory'
  | 'TimeInterval'
  | 'ExptUnit'
  | 'ExptTestItemType'
  | 'ExptType'
  | 'Specification'
  | 'Type'
  | 'CreateType'
  | 'Operator'
  | 'Creator';

type SelectColumn = Partial<{
  areaId: number;
  areaIds: number;
  factoryId: number;
  exptUnitId: number;
  exptItemCode: number | TestItemEnum;
  startTime: number;
  endTime: number;
  testType: number;
  specificationId?: number;
  timeInterval?: number;
  page?: number;
  pageSize?: number;
  typeId?: number;
  createType?: number;
  operator?: string;
  creator?: string;
}>;

type StrictSelectColumn<T> = T extends SelectColumn ? (keyof T extends keyof SelectColumn ? T : never) : never;

const initCreateTypeUnitValueOptions = [
  {
    text: '開立試驗單',
    value: 3,
  },
];

interface YatungCombinationSelectProps<T, U = any> {
  filterValue: StrictSelectColumn<T>;
  handleChange: <K extends keyof T>(field: K) => (value: T[K]) => void;
  handleFilterChange?: <K extends keyof U>(field: K) => (value: U[K]) => void;
  selectOptions: SelectType[];
  exptTypeSelection?: DropdownItem<TestItemEnum>[];
  isNotMaxDate?: boolean;
}

function YatungCombinationSelect<T extends SelectColumn, U = any>({
  filterValue,
  handleChange,
  handleFilterChange,
  selectOptions,
  exptTypeSelection,
  isNotMaxDate,
}: YatungCombinationSelectProps<T, U>) {
  const { actionLoading } = useApi();
  const { t: i18T } = useTranslation();
  const { userAreaOptionsData } = useAuth();

  const AllItem = useMemo(() => ({ text: i18T('GLOBAL.ALL'), value: 0 }), [i18T]);
  const timeIntervalOptions = useMemo(
    () => [
      { text: '當天', value: 1 },
      { text: '一周內', value: 2 },
      { text: '一個月內', value: 3 },
      { text: '自訂', value: 4 },
    ],
    [],
  );

  const [factoryOptions, setFactoryOptions] = useState<Array<Options>>([]); // 工廠選項
  const [unitOptions, setUnitOptions] = useState<Options[]>([]); // 試驗場地選項
  const [allTypes, setAllTypes] = useState<TypeData[]>([]); // 類型類別
  const [testTypeOptions, setTestTypeOptions] = useState<Array<TestTypeData>>([]); // 試驗類別
  const [specificationOptions, setSpecificationOptions] = useState<Array<DropdownItem<number>>>([]); // 規格
  const [exptItemOptions, setExptItemOptions] = useState<Array<Options>>([]); // 規格選項
  const [canSelectDate, setCanSelectDate] = useState<boolean>(false); // 是否可以選擇日期
  const [keeperData, setKeeperData] = useState<SearchPaginationUser[]>([]); //負責人員
  const [createKeeperOptions, setCreateKeeperOptions] = useState<TextOptions[]>([]);//開單人員

  const generateSelectType = (type: SelectType): boolean =>
    useMemo(() => selectOptions.includes(type), [selectOptions]);

  const selectDate = generateSelectType('Date');
  const selectAreaAndFactory = generateSelectType('AreaAndFactory');
  const selectTimeInterval = generateSelectType('TimeInterval');
  const selectExptUnit = generateSelectType('ExptUnit');
  const selectExptType = generateSelectType('ExptType');
  const selectSpecification = generateSelectType('Specification');
  const selectExptTestItemType = generateSelectType('ExptTestItemType');
  const selectType = generateSelectType('Type');
  const selectListType = generateSelectType('CreateType');
  const selectOperator = generateSelectType('Operator');
  const selectCreator = generateSelectType('Creator');

  //取得所有使用者
  const getSearchKeeperData = (groupId: number) => {
    //獲取帳號人員作為負責人
    const params: GetAllName = {
      //當新增廠改變，以此factoryId去做全人員搜尋
      pageNumber: 0,
      pageSize: 99999,
      sourceKides: 0,
      factoryId: groupId,
    };
    EntityApi.GetKeeper({ ...params }, (datas: SearchPaginationUserRequest) => {
      setKeeperData(datas.data);
    });
  };

  const onGetKeeperOptionsSuccess = () => {
    setCreateKeeperOptions(
      keeperData?.map(({ accountName, account }: SearchPaginationUser) => ({ value: account, text: accountName })),
    ); //選擇不同廠時，顯示不同廠之負責人員
  };

  // 區域改變時，工廠選項也要跟著改變
  const handleAreaChange = (value: number) => {
    if (handleFilterChange && 'areaId' in filterValue) {
      handleFilterChange('areaId' as keyof U)(value as U[keyof U]);
    } else {
      handleChange('areaId')(value as any); // 或者改為對應的類型
    }
    const _facArr = userAreaOptionsData.find((item) => item.value === value)?.factories;

    if (!value || (_facArr && !_facArr?.find((item) => item.value === filterValue?.factoryId))) {
      handleChange('factoryId')(0);
    }
  };

  // 試驗類別改變時，規格選項也要跟著改變
  const selectTestType = useCallback(
    (testType: number) => {
      handleChange('testType')(testType);

      if (!selectSpecification) return;
      handleChange('specificationId')(0);
      handleChange('exptItemCode')(0);

      SpecificationApi.getSpecificationsBySearch(
        {
          factoryId: 25,
        },
        (_data) => {
          console.log('getSpecificationsBySearch', _data);
          setSpecificationOptions(_data.map((item) => ({ text: item.name, value: item.id })))
        },
      );

    },
    [filterValue?.factoryId, testTypeOptions, handleChange, selectSpecification],
  );

  useEffect(() => {
    selectTestType(1);
  }, []);

  // 規格改變時，試驗項目也要跟著改變
  const getExptItem = useCallback((exptTypeId: number, specificationId?: number) => {
    if (specificationId === 0) {
      setExptItemOptions([{ "text": "砂_細粒料篩分析", "value": "FINE_AGG_SIEVE_ANALYSIS" }, { "text": "石_粗粒料篩分析", "value": "COARSE_AGG_SIEVE_ANALYSIS" }])
    }
    if (specificationId === 1 || specificationId === 2) {
      setExptItemOptions([{ "text": "石_粗粒料篩分析", "value": "COARSE_AGG_SIEVE_ANALYSIS" }])
    }
    if (specificationId === 3 || specificationId === 4) {
      setExptItemOptions([{ "text": "砂_細粒料篩分析", "value": "FINE_AGG_SIEVE_ANALYSIS" }])
    }
  }, []);

  // 選取時間範圍
  const selectTimeIntervalHandler = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const timeInterval = e.target.value as number;
      if (handleFilterChange) handleFilterChange('timeInterval' as keyof U)(timeInterval as U[keyof U]);

      if (timeInterval === 4) {
        setCanSelectDate(true);
        return;
      } else {
        setCanSelectDate(false);

        handleChange('endTime')(new Date().getTime());
        handleChange('startTime')(
          moment(new Date())
            .subtract(1, timeInterval === 1 ? 'day' : timeInterval === 2 ? 'week' : 'month')
            .toDate()
            .getTime(),
        );
      }
    },
    [handleChange, handleFilterChange],
  );

  // 依照傳入要顯示的選項，來取得對應的資料
  useEffect(() => {
    if (selectExptUnit) {
      setUnitOptions([{ "value": 1, "text": "廠內試驗" }]);
    }
    if (selectExptType) {
      setTestTypeOptions([{ "id": 1, "typeName": "粒料試驗", "removed": false, "types": [{ "id": 2, "name": "石", "code": "5", "removed": false }, { "id": 1, "name": "砂", "code": "4", "removed": false }] }])
    }
    if (selectType) {
      TypeApi.getAllTypes((typesRsp: Array<TypeData>) => {
        setAllTypes(typesRsp);
      });
    }
  }, [selectExptUnit, selectExptType, selectType]);

  // 規格改變時，試驗項目也要跟著改變
  useEffect(() => {
    if (!filterValue?.testType) return;
    getExptItem(filterValue.testType, filterValue?.specificationId);
  }, [filterValue?.testType, filterValue?.specificationId, getExptItem]);

  useEffect(() => {
    if (filterValue?.areaId) setFactoryOptions(getFactoryOptionsByAreaId(userAreaOptionsData, filterValue.areaId));
  }, [filterValue?.areaId]);

  //取得人員
  useEffect(() => {
    if (filterValue?.factoryId) getSearchKeeperData(filterValue?.factoryId)
  }, [filterValue?.factoryId]);

  useEffect(() => {
    onGetKeeperOptionsSuccess();
  }, [keeperData]);

  return (
    <Stack direction="column" spacing={2} justifyContent="flex-end" my={3}>
      <Stack direction="row" spacing={2} alignItems="center">
        {selectDate && (
          <>
            <YatungDateSelect
              label={i18T('GLOBAL.START')}
              disabled={actionLoading}
              value={new Date(filterValue?.startTime as number)}
              onChange={(val) => val && handleChange('startTime')(new Date(val).getTime())}
              maxDate={isNotMaxDate ? new Date() : (filterValue?.endTime as number) - 1000 * 60 * 60 * 24}
            />
            <YatungDateSelect
              label={i18T('GLOBAL.END')}
              disabled={actionLoading}
              value={new Date(filterValue?.endTime as number)}
              minDate={filterValue?.startTime}
              onChange={(val) => val && handleChange('endTime')(new Date(val).getTime())}
              maxDate={new Date()}
              disableFuture={true}
            />
          </>
        )}
        {selectAreaAndFactory && (
          <>
            <YatungSelect
              helperText={i18T('GLOBAL.AREA')}
              disabled={!userAreaOptionsData.length || actionLoading}
              options={userAreaOptionsData}
              value={filterValue?.areaId}
              onChange={(e) => handleAreaChange(e.target.value as number)}
            />
            <YatungSelect
              helperText={i18T('GLOBAL.FACTORY')}
              disabled={!factoryOptions.length || actionLoading}
              options={factoryOptions}
              value={filterValue?.factoryId}
              onChange={(e) => handleChange('factoryId')(e.target.value as number)}
            />
          </>
        )}
        {selectTimeInterval && filterValue?.startTime && filterValue?.endTime && (
          <>
            <YatungSelect
              helperText={i18T('GLOBAL.INTERVAL')}
              width={'220px'}
              disabled={actionLoading}
              options={timeIntervalOptions}
              value={filterValue.timeInterval}
              onChange={selectTimeIntervalHandler}
            />
            <YatungDateSelect
              label={i18T('GLOBAL.START')}
              disabled={actionLoading || !canSelectDate}
              value={new Date(filterValue?.startTime)}
              onChange={(val) => val && handleChange('startTime')(new Date(val).getTime())}
              maxDate={filterValue?.endTime - 1000 * 60 * 60 * 24}
            />
            <YatungDateSelect
              label={i18T('GLOBAL.END')}
              disabled={actionLoading || !canSelectDate}
              value={new Date(filterValue?.endTime)}
              minDate={filterValue?.startTime}
              onChange={(val) => val && handleChange('endTime')(new Date(val).getTime())}
              maxDate={new Date()}
              disableFuture={true}
            />
          </>
        )}
        {selectType && (
          <YatungSelect
            helperText={i18T('GLOBAL.TYPE')}
            disabled={!allTypes.length || actionLoading}
            options={allTypes.map((item) => ({ text: item.name, value: item.id }))}
            value={filterValue?.typeId}
            onChange={(e) => handleChange('typeId')(e.target.value as number)}
          />
        )}
        {selectExptUnit && (
          <YatungSelect
            helperText={i18T('GLOBAL.EXPT_UNIT')}
            disabled={!unitOptions.length || actionLoading}
            options={[AllItem, ...unitOptions]}
            value={filterValue?.exptUnitId}
            onChange={(e) => handleChange('exptUnitId')(e.target.value as number)}
          />
        )}
        {selectExptType && (
          <YatungSelect
            helperText={i18T('GLOBAL.EXPT_TYPE')}
            disabled={!testTypeOptions.length || actionLoading}
            options={testTypeOptions.map((item) => ({ text: item.typeName, value: item.id }))}
            value={filterValue?.testType}
            onChange={(e) => selectTestType(e.target.value as number)}
          />
        )}
        {selectSpecification && (
          <YatungSelect
            helperText={i18T('GLOBAL.SPECIFICATION')}
            disabled={filterValue.testType === 4 || actionLoading}
            options={[AllItem, ...specificationOptions]}
            value={filterValue?.specificationId}
            onChange={(e) => handleChange('specificationId')(e.target.value as number)}
          />
        )}
        {selectExptTestItemType && (
          <YatungSelect
            helperText={i18T('GLOBAL.EXPT_TEST_ITEM')}
            disabled={exptTypeSelection ? false : !exptItemOptions.length || actionLoading}
            options={exptTypeSelection ? exptTypeSelection : exptItemOptions ? exptItemOptions : [AllItem]}
            value={filterValue?.exptItemCode}
            onChange={(e) => handleChange('exptItemCode')(e.target.value as TestItemEnum)}
          />
        )}
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        {selectListType && ( //開單類型
          <YatungSelect
            helperText={i18T('GLOBAL.CREATE_TYPE')}
            disabled={actionLoading}
            options={initCreateTypeUnitValueOptions}
            value={filterValue?.createType}
            onChange={(e) => handleChange('createType')(e.target.value as number)}
          />
        )}
        {selectCreator && ( //開單人員
          <YatungSelect
            helperText={i18T('GLOBAL.CREATER')}
            disabled={actionLoading}
            options={createKeeperOptions}
            value={filterValue?.creator}
            onChange={(e) => handleChange('creator')(e.target.value as string)}
          />
        )}
        {selectOperator && ( //執行試驗人員
          <YatungSelect
            helperText={i18T('GLOBAL.OPERATOR')}
            disabled={actionLoading}
            options={createKeeperOptions}
            value={filterValue?.operator}
            onChange={(e) => handleChange('operator')(e.target.value as string)}
          />
        )}

      </Stack>
    </Stack>
  );
}

export default YatungCombinationSelect;
