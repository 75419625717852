import { TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';
import { Title } from '@Src/_basic/components/YatungTest/RowFieldInfo';
import DetailIcon from '@Src/_basic/icons/Detail';
import { InformAndFineRecordList } from '@Src/_basic/object/FineRecordType';
import { useApi } from '@Src/redux/api/apiAction';
import { Typography } from '@mui/material';
import moment from 'moment';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const RecordTable = ({ data }: { data: Array<InformAndFineRecordList> }) => {
  const navigate = useNavigate();
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  return (
    <>
      <YatungTable
        loading={actionLoading}
        data={data}
        columns={[
          {
            text: '#',
            dataField: 'index',
            width: '5%',
            formatter: ({ row }: { row: InformAndFineRecordList }) => <Typography>{row.index}</Typography>,
          },
          {
            text: '試驗名稱',
            dataField: 'exptItemName',
            width: '15%',
            formatter: ({ row }: { row: InformAndFineRecordList }) => (
              <Typography>{`${row.exptItemName}${
                row.recheckExptIds?.length ? ` (${row.recheckExptIds.length})` : ''
              }`}</Typography>
            ),
          },
          {
            text: '廠',
            dataField: 'factoryName',
            width: '10%',
          },
          {
            text: '執行對象',
            dataField: 'operatorName',
            width: '10%',
          },
          {
            text: '試驗完成時間',
            dataField: 'completeTime',
            width: '15%',
            formatter: ({ row }: { row: InformAndFineRecordList }) => (
              <Typography>{moment(row?.completeTime).format('YYYY/MM/DD HH:mm')}</Typography>
            ),
          },
          {
            text: '異常項目',
            dataField: 'state',
            width: '15%',
            formatter: ({ row }: { row: InformAndFineRecordList }) => {
              const tempText: Array<string> = [];
              row?.standardTypes.forEach((standardType) => {
                if (standardType === 'CNS') {
                  tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));
                } else if (standardType === 'CUSTOM') {
                  tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
                }
              });
              return (
                <>
                  {tempText.map((title: string, index: number) => (
                    <Title key={index} style={TestVerifyResultStyles.abnormal}>
                      {title}
                    </Title>
                  ))}
                </>
              );
            },
          },
          {
            text: '通過狀態',
            dataField: 'completeTime',
            width: '15%',
            formatter: ({ row }: { row: InformAndFineRecordList }) => (
              <Typography
                style={{
                  ...(!row?.passStatus ? TestVerifyResultStyles.abnormal : TestVerifyResultStyles.normal),
                  fontWeight: 'bold',
                }}
              >
                {!row?.passStatus ? '未通過' : row?.passStatus === 1 ? '通過' : '經複驗通過'}
              </Typography>
            ),
          },
          {
            text: i18T('GLOBAL.ACTION'),
            dataField: 'action',
            width: '10%',
            formatter: ({ row }) => {
              return (
                <YatungButton
                  text={i18T('GLOBAL.DETAIL')}
                  startIcon={<DetailIcon />}
                  width={90}
                  color="yellow"
                  onClick={() =>
                    navigate(
                      `/qualityManagement/informAndFineRecord/testAndRecheckDetail/${row?.exptId}/${row?.exptItemCode}`,
                      {
                        state: row,
                      },
                    )
                  }
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default memo(RecordTable);
