import React, { useState } from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';
import PreviousIcon from '@Src/_basic/icons/Previous';
import Save from '@Src/_basic/icons/Save';
import { Stack } from '@mui/material';

interface Props {
  onSubmit: () => void;
  onGoBack: () => void;
}
export default function SubmitButton(props: Props) {
  const { t: i18T } = useTranslation();
  const { onSubmit, onGoBack } = props;
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  return (
    <Stack direction={'row'} justifyContent={'space-evenly'} sx={{ mt: 2 }}>
      <YatungButton
        text={i18T('GLOBAL.CANCEL')}
        color={'red'}
        size="large"
        onClick={onGoBack}
        width={120}
        sx={{ letterSpacing: 10, fontSize: 25, py: 1, pl: 2 }}
      />
      <YatungButton
        text={i18T('GLOBAL.JOIN')}
        color={'blue'}
        size="large"
        onClick={() => {
          onSubmit();
          setIsDisabled(true);
        }}
        width={120}
        sx={{ letterSpacing: 10, fontSize: 25, py: 1, pl: 2 }}
        disabled={isDisabled}
      />
    </Stack>
  );
}
