import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function SandSmall(props: SvgIconProps) {
    return (
        <svg {...props} width="33" height="11" viewBox="0 0 33 11" fill="none" >
            <path d="M8.3542 4.3304C8.3542 4.69831 8.0559 4.99661 7.68799 4.99661C7.32008 4.99661 7.02178 4.69831 7.02178 4.3304C7.02178 4.22988 7.0439 4.13457 7.08391 4.04934C7.08977 4.03665 7.09595 4.02429 7.10278 4.01225C7.11124 3.99664 7.12002 3.98135 7.12978 3.96671C7.14247 3.94719 7.15645 3.928 7.17142 3.90978C7.18215 3.89677 7.19321 3.88409 7.20492 3.87173C7.32626 3.74388 7.49802 3.66418 7.68799 3.66418C8.0559 3.66418 8.3542 3.96248 8.3542 4.3304Z" fill="#7C878E" />
            <path d="M5.35625 7.32835C5.72416 7.32835 6.02246 7.03005 6.02246 6.66214C6.02246 6.29422 5.72416 5.99592 5.35625 5.99592C4.98834 5.99592 4.69004 6.29422 4.69004 6.66214C4.69004 7.03005 4.98834 7.32835 5.35625 7.32835Z" fill="#7C878E" />
            <path d="M3.35762 9.66009C3.72553 9.66009 4.02383 9.36179 4.02383 8.99388C4.02383 8.62596 3.72553 8.32766 3.35762 8.32766C2.98971 8.32766 2.69141 8.62596 2.69141 8.99388C2.69141 9.36179 2.98971 9.66009 3.35762 9.66009Z" fill="#7C878E" />
            <path d="M10.3528 6.66214C10.3528 6.88464 10.2439 7.08145 10.0763 7.20246C9.96671 7.2815 9.83203 7.32835 9.68663 7.32835C9.31871 7.32835 9.02041 7.03005 9.02041 6.66214C9.02041 6.5665 9.04058 6.47541 9.07702 6.39311C9.12158 6.2926 9.1899 6.20509 9.27512 6.13808C9.38833 6.04895 9.53113 5.99592 9.68663 5.99592C9.80211 5.99592 9.91108 6.0252 10.0057 6.07692C10.0851 6.12051 10.1547 6.17972 10.2104 6.25031C10.2494 6.30008 10.2816 6.35538 10.3053 6.41491C10.3359 6.49135 10.3528 6.57463 10.3528 6.66214Z" fill="#7C878E" />
            <path d="M7.68799 9.66009C8.0559 9.66009 8.3542 9.36179 8.3542 8.99388C8.3542 8.827 8.29272 8.67443 8.19123 8.55765C8.06924 8.4168 7.88903 8.32766 7.68799 8.32766C7.48858 8.32766 7.30967 8.41517 7.18768 8.55407C7.08424 8.6715 7.02178 8.82537 7.02178 8.99388C7.02178 9.36179 7.32008 9.66009 7.68799 9.66009Z" fill="#7C878E" />
            <path d="M14.017 7.32835C14.3849 7.32835 14.6832 7.03005 14.6832 6.66214C14.6832 6.44646 14.5807 6.25486 14.422 6.1332C14.3098 6.047 14.1692 5.99592 14.017 5.99592C13.6491 5.99592 13.3508 6.29422 13.3508 6.66214C13.3508 7.03005 13.6491 7.32835 14.017 7.32835Z" fill="#7C878E" />
            <path d="M12.6846 8.99388C12.6846 9.15067 12.6303 9.29478 12.5395 9.40863C12.4175 9.56185 12.2295 9.66009 12.0184 9.66009C11.6505 9.66009 11.3522 9.36179 11.3522 8.99388C11.3522 8.62596 11.6505 8.32766 12.0184 8.32766C12.3863 8.32766 12.6846 8.62596 12.6846 8.99388Z" fill="#7C878E" />
            <path d="M12.0184 4.99661C12.3863 4.99661 12.6846 4.69831 12.6846 4.3304C12.6846 4.18043 12.6348 4.04186 12.5512 3.93028C12.5232 3.89319 12.4917 3.85904 12.4569 3.82879C12.4432 3.81707 12.4292 3.80569 12.4149 3.79495C12.398 3.78259 12.3807 3.77088 12.3629 3.76015C12.2623 3.69932 12.1446 3.66418 12.0184 3.66418C11.8674 3.66418 11.7279 3.71461 11.616 3.79918L11.5799 3.82846C11.5682 3.83854 11.5571 3.84895 11.5464 3.86001L11.5262 3.88116C11.5011 3.90848 11.4784 3.93809 11.4582 3.96931C11.3912 4.07341 11.3522 4.19735 11.3522 4.3304C11.3522 4.69831 11.6505 4.99661 12.0184 4.99661Z" fill="#7C878E" />
            <path d="M19.0136 6.66214C19.0136 7.03005 18.7153 7.32835 18.3474 7.32835C18.2062 7.32835 18.0754 7.28443 17.9678 7.20961C17.7944 7.08925 17.6812 6.88887 17.6812 6.66214C17.6812 6.50241 17.7374 6.35603 17.8311 6.2412C17.9531 6.09156 18.1392 5.99592 18.3474 5.99592C18.5003 5.99592 18.6411 6.04732 18.7537 6.13385C18.9118 6.25584 19.0136 6.44711 19.0136 6.66214Z" fill="#7C878E" />
            <path d="M16.553 9.62821C16.6034 9.61194 16.6513 9.58982 16.6958 9.5625C16.8146 9.48996 16.9089 9.38131 16.9632 9.25184C16.9847 9.20012 17.0003 9.14514 17.0084 9.08789C17.0114 9.06674 17.0133 9.04527 17.0143 9.02348L17.015 8.99388C17.015 8.78308 16.917 8.59506 16.7641 8.47307C16.6503 8.38199 16.5059 8.32766 16.3487 8.32766C16.2967 8.32766 16.2459 8.33352 16.1975 8.34491C16.1529 8.35531 16.1103 8.36995 16.0696 8.3885C16.0482 8.39858 16.0273 8.40964 16.0072 8.42168C15.9737 8.44184 15.9418 8.46494 15.9125 8.49031C15.7717 8.6123 15.6825 8.79284 15.6825 8.99388C15.6825 9.36179 15.9808 9.66009 16.3487 9.66009C16.42 9.66009 16.4886 9.6487 16.553 9.62821Z" fill="#7C878E" />
            <path d="M23.344 6.66214C23.344 7.03005 23.0457 7.32835 22.6777 7.32835C22.3098 7.32835 22.0115 7.03005 22.0115 6.66214C22.0115 6.29422 22.3098 5.99592 22.6777 5.99592C23.0457 5.99592 23.344 6.29422 23.344 6.66214Z" fill="#7C878E" />
            <path d="M20.6791 9.66009C21.047 9.66009 21.3453 9.36179 21.3453 8.99388C21.3453 8.78276 21.2471 8.59473 21.0942 8.47275C21.034 8.4246 20.9651 8.38687 20.8906 8.36182C20.8242 8.3397 20.753 8.32766 20.6791 8.32766C20.3112 8.32766 20.0129 8.62596 20.0129 8.99388C20.0129 9.08398 20.0308 9.17019 20.0633 9.24858C20.0897 9.31169 20.1255 9.36992 20.1687 9.42164C20.2087 9.46946 20.2556 9.51175 20.3076 9.54688C20.4137 9.61845 20.5415 9.66009 20.6791 9.66009Z" fill="#7C878E" />
            <path d="M21.3453 4.3304C21.3453 4.69831 21.047 4.99661 20.6791 4.99661C20.4641 4.99661 20.2728 4.89479 20.1508 4.73669C20.1342 4.7149 20.1189 4.69213 20.105 4.66838C20.0832 4.63162 20.065 4.59259 20.0506 4.5516C20.0262 4.48231 20.0129 4.40782 20.0129 4.3304C20.0129 3.96248 20.3112 3.66418 20.6791 3.66418C21.047 3.66418 21.3453 3.96248 21.3453 4.3304Z" fill="#7C878E" />
            <path d="M27.0081 7.32835C27.376 7.32835 27.6743 7.03005 27.6743 6.66214C27.6743 6.29422 27.376 5.99592 27.0081 5.99592C26.6402 5.99592 26.3419 6.29422 26.3419 6.66214C26.3419 7.03005 26.6402 7.32835 27.0081 7.32835Z" fill="#7C878E" />
            <path d="M25.6757 8.99388C25.6757 9.36179 25.3774 9.66009 25.0095 9.66009C24.7941 9.66009 24.6025 9.55794 24.4809 9.3992C24.3947 9.28697 24.3433 9.14644 24.3433 8.99388C24.3433 8.62596 24.6416 8.32766 25.0095 8.32766C25.3774 8.32766 25.6757 8.62596 25.6757 8.99388Z" fill="#7C878E" />
            <path d="M25.0095 4.99661C25.3774 4.99661 25.6757 4.69831 25.6757 4.3304C25.6757 3.96248 25.3774 3.66418 25.0095 3.66418C24.6416 3.66418 24.3433 3.96248 24.3433 4.3304C24.3433 4.69831 24.6416 4.99661 25.0095 4.99661Z" fill="#7C878E" />
            <path d="M0.482255 9.77379L8.79233 0.830357C9.01419 0.60525 9.27117 0.499854 9.52068 0.499854C9.77018 0.499854 10.0272 0.60525 10.249 0.830357L18.5591 9.77379L18.8031 9.54673L16.4957 7.06373L22.1175 0.829381C22.3391 0.604925 22.5961 0.499854 22.8449 0.499854C23.0944 0.499854 23.3514 0.60525 23.5732 0.830357L31.8833 9.77379L32.1273 9.54673L23.8156 0.601672L23.8127 0.598419C23.5349 0.315409 23.194 0.166748 22.8449 0.166748C22.4962 0.166748 22.1553 0.315409 21.8775 0.598419L21.8749 0.601022L16.268 6.81878L10.4914 0.601672L10.4881 0.598419C10.2106 0.315409 9.8694 0.166748 9.52068 0.166748C9.17196 0.166748 8.83072 0.315409 8.55324 0.598419L0.238281 9.54673L0.482255 9.77379Z" fill="#7C878E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.40516 0.339107C9.14548 0.366876 8.8907 0.490936 8.67288 0.711068C8.89034 0.4908 9.14547 0.366852 9.40516 0.339107ZM8.43132 0.481621C8.73672 0.170142 9.11958 0 9.51769 0C9.91555 0 10.2982 0.169925 10.6035 0.481027C10.6037 0.481225 10.6039 0.481423 10.6041 0.481621L10.6062 0.483706L10.6083 0.485868L16.2632 6.57192L21.7511 0.486096L21.7541 0.483056L21.7556 0.481551C21.7558 0.48136 21.756 0.481168 21.7562 0.480977C22.0617 0.169972 22.444 0 22.8419 0C23.2404 0 23.6229 0.170165 23.9285 0.481551L23.9311 0.484125L23.9335 0.486806L23.9346 0.488105L23.9364 0.490059L32.3596 9.55508L31.8718 10.0091L23.45 0.945375C23.2564 0.749702 23.0415 0.666211 22.8419 0.666211C22.6431 0.666211 22.4286 0.74923 22.2356 0.943602L16.7185 7.06184L19.0354 9.55508L18.5476 10.0091L10.1257 0.945386C9.93213 0.749705 9.71729 0.666211 9.51769 0.666211C9.31809 0.666211 9.10325 0.749705 8.90964 0.945386L0.487819 10.0091L0 9.55508L8.43132 0.481621ZM23.6883 0.712378C23.4545 0.475287 23.1778 0.349446 22.8981 0.334596C23.178 0.349493 23.4549 0.475801 23.6889 0.713251L23.6906 0.714996L31.8889 9.53811L23.6888 0.712909L23.6883 0.712378Z" fill="#7C878E" />
        </svg>
    );
}