import {
  ChildrenTypography,
  TableHeaderTitle,
  TestCardContainer
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import YatungButton from '@Src/_basic/components/YatungButton';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { AllTest, ExperimentResultType } from '@Src/_basic/object/TestType';
import {
  CoarseSieve,
  CoarseSieveLegalTypeEnum,
  ImportCoarseSieve,
} from '@Src/_basic/object/test/sandRock/CoarseSieveType';
import { PutFineSieveRequest } from '@Src/_basic/object/test/sandRock/FineSieveType';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: CoarseSieve;
  type: CoarseSieveLegalTypeEnum;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
  closeExecuteTestHandler?: () => void;
}

const validationSchema = Yup.object().shape({
  wetWeight: basicNumberSchema,
  beforeRinseDryWeight: basicNumberSchema,
  afterRinseDryWeight: basicNumberSchema,
  poreDiameter1: basicNumberSchema,
  poreDiameter2: basicNumberSchema,
  poreDiameter3: basicNumberSchema,
  poreDiameter4: basicNumberSchema,
  poreDiameter5: basicNumberSchema,
  poreDiameter6: basicNumberSchema,
  poreDiameter7: basicNumberSchema,
  poreDiameter8: basicNumberSchema,
  underPan: basicNumberSchema,
});

function ExportCoarseSieveTable({ testDetail, type, afterUpdateTest, canEdit, closeExecuteTestHandler }: Props) {
  const { t: i18T } = useTranslation();
  const [legalStandardType1, setLegalStandardType1] =
    useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();
  const [legalStandardType2, setLegalStandardType2] =
    useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();
  const [edit, setEdit] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      wetWeight: testDetail?.wetWeight,
      beforeRinseDryWeight: testDetail?.beforeRinseDryWeight,
      afterRinseDryWeight: testDetail?.afterRinseDryWeight,
      poreDiameter1: testDetail?.poreDiameter1,
      poreDiameter2: testDetail?.poreDiameter2,
      poreDiameter3: testDetail?.poreDiameter3,
      poreDiameter4: testDetail?.poreDiameter4,
      poreDiameter5: testDetail?.poreDiameter5,
      poreDiameter6: testDetail?.poreDiameter6,
      poreDiameter7: testDetail?.poreDiameter7,
      poreDiameter8: testDetail?.poreDiameter8,
      underPan: testDetail?.underPan,
    }),
    [testDetail],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportCoarseSieve = {
        wetWeight: checkIsNaN(+values.wetWeight),
        beforeRinseDryWeight: checkIsNaN(+values.beforeRinseDryWeight),
        afterRinseDryWeight: checkIsNaN(+values.afterRinseDryWeight),
        poreDiameter1: checkIsNaN(+values.poreDiameter1),
        poreDiameter2: checkIsNaN(+values.poreDiameter2),
        poreDiameter3: checkIsNaN(+values.poreDiameter3),
        poreDiameter4: checkIsNaN(+values.poreDiameter4),
        poreDiameter5: checkIsNaN(+values.poreDiameter5),
        poreDiameter6: checkIsNaN(+values.poreDiameter6),
        poreDiameter7: checkIsNaN(+values.poreDiameter7),
        poreDiameter8: checkIsNaN(+values.poreDiameter8),
        underPan: checkIsNaN(+values.underPan),
      };

      TestApi.putTest<PutFineSieveRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
            completed: true,
            deadline: Date.now(),
            exptUnitId: 1,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          closeExecuteTestHandler && closeExecuteTestHandler();
        },
        (err) => console.log(err),
      );
    },
  });

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.COARSE_AGG_SIEVE_ANALYSIS,
        effectiveUnixTime: testDetail?.completeTime || Date.now(),
        type: 1,
      },
      (_data) => {
        const legalStandardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          legalStandardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandardType1(legalStandardMap);
      },
    );
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.COARSE_AGG_SIEVE_ANALYSIS,
        effectiveUnixTime: testDetail?.completeTime || Date.now(),
        type: 2,
      },
      (_data) => {
        const legalStandardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          legalStandardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandardType2(legalStandardMap);
      },
    );
  }, [testDetail]);

  const firstTable = useMemo(() => {
    return (
      <Stack my={4} gap={2} ml={30}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pb={2}>
          <TableHeaderTitle>{i18T(`TEST.SIEVE_ANALYSIS.SIEVE_NUMBER`)}</TableHeaderTitle>
          <TableHeaderTitle sx={{ pr: 18 }}>{`${i18T(
            `TEST.SIEVE_ANALYSIS.ACCUMULATION_SIEVE_WEIGHT`,
          )}(g)`}</TableHeaderTitle>
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.WET_WEIGHT`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.wetWeight}
            isError={!!(formik.touched.wetWeight && formik.errors.wetWeight)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('wetWeight', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.BEFORE_RINSE_DRY_WEIGHT`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.beforeRinseDryWeight}
            isError={!!(formik.touched.beforeRinseDryWeight && formik.errors.beforeRinseDryWeight)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formik.setFieldValue('beforeRinseDryWeight', e.target.value)
            }
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.AFTER_RINSE_DRY_WEIGHT`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.afterRinseDryWeight}
            isError={!!(formik.touched.afterRinseDryWeight && formik.errors.afterRinseDryWeight)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formik.setFieldValue('afterRinseDryWeight', e.target.value)
            }
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.37500um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.poreDiameter1}
            isError={!!(formik.touched.poreDiameter1 && formik.errors.poreDiameter1)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter1', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.25000um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.poreDiameter2}
            isError={!!(formik.touched.poreDiameter2 && formik.errors.poreDiameter2)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter2', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.19000um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.poreDiameter3}
            isError={!!(formik.touched.poreDiameter3 && formik.errors.poreDiameter3)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter3', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.12500um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.poreDiameter4}
            isError={!!(formik.touched.poreDiameter4 && formik.errors.poreDiameter4)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter4', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.9500um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.poreDiameter5}
            isError={!!(formik.touched.poreDiameter5 && formik.errors.poreDiameter5)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter5', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.4750um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.poreDiameter6}
            isError={!!(formik.touched.poreDiameter6 && formik.errors.poreDiameter6)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter6', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.2360um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.poreDiameter7}
            isError={!!(formik.touched.poreDiameter7 && formik.errors.poreDiameter7)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter7', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.75um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.poreDiameter8}
            isError={!!(formik.touched.poreDiameter8 && formik.errors.poreDiameter8)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('poreDiameter8', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.UNDER_PAN`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.underPan}
            isError={!!(formik.touched.underPan && formik.errors.underPan)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('underPan', e.target.value)}
          />
        </Stack>
      </Stack>
    );
  }, [edit, formik, i18T]);

  return (
    <Stack gap={2} mt={2}>
      <TestCardContainer>
        {firstTable}
        <Stack flexDirection="row" justifyContent="flex-end" gap={5}>
          <YatungButton
            color="green"
            sx={{ height: '35%', mt: 1 }}
            width={80}
            text={`${i18T('GLOBAL.SUBMIT')}`}
            onClick={formik.handleSubmit}
          />
        </Stack>
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportCoarseSieveTable);
