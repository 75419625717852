import { ExcelHeader, SearchYardReceiptsParams, YardReciptData } from '@Src/_basic/object/YardReceiptsType';
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
interface Props {
  exportIdRef: any;
  query: SearchYardReceiptsParams;
  selectedAreaName: string | undefined;
  factoriesName: string | undefined;
  storageYardTotalRecipts: Array<YardReciptData>;
  exporting: boolean;
}
export default function StorageYardMaterialReceiptsExcelTable(props: Props) {
  const { exportIdRef, query, selectedAreaName, factoriesName, storageYardTotalRecipts, exporting } = props;
  const [event, updateEvent] = useReducer(
    (prev: any, next: any) => {
      return { ...prev, ...next };
    },
    {
      threePointStone1Total: 0,
      threePointStone2Total: 0,
      threePointStone3Total: 0,
      sixPointStone1Total: 0,
      sixPointStone2Total: 0,
      sixPointStone3Total: 0,
      mmStone1Total: 0,
      mmStone2Total: 0,
      mmStone3Total: 0,
      thinSand1Total: 0,
      thinSand2Total: 0,
      thinSand3Total: 0,
      roughSand1Total: 0,
      roughSand2Total: 0,
      roughSand3Total: 0,
    },
  );

  const getSandCal = () => {
    const thinSand1 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification1Id === 4);
    const thinSand2 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification2Id === 4);
    const thinSand3 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification3Id === 4);

    const roughSand1 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification1Id === 3);
    const roughSand2 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification2Id === 3);
    const roughSand3 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification3Id === 3);

    let _thinSand1Total = 0;
    let _thinSand2Total = 0;
    let _thinSand3Total = 0;
    let _roughSand1Total = 0;
    let _roughSand2Total = 0;
    let _roughSand3Total = 0;

    thinSand1?.forEach((element: YardReciptData) => {
      _thinSand1Total += element.specification1Value;
    });
    thinSand2?.forEach((element: YardReciptData) => {
      _thinSand2Total += element.specification2Value;
    });
    thinSand3?.forEach((element: YardReciptData) => {
      _thinSand3Total += element.specification3Value;
    });

    roughSand1?.forEach((element: YardReciptData) => {
      _roughSand1Total += element.specification1Value;
    });
    roughSand2?.forEach((element: YardReciptData) => {
      _roughSand2Total += element.specification2Value;
    });
    roughSand3?.forEach((element: YardReciptData) => {
      _roughSand3Total += element.specification3Value;
    });

    updateEvent({ thinSand1Total: _thinSand1Total });
    updateEvent({ thinSand2Total: _thinSand2Total });
    updateEvent({ thinSand3Total: _thinSand3Total });
    updateEvent({ roughSand1Total: _roughSand1Total });
    updateEvent({ roughSand2Total: _roughSand2Total });
    updateEvent({ roughSand3Total: _roughSand3Total });
  };
  const getStoneCal = () => {
    const threePointStone1 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification1Id === 2);
    const threePointStone2 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification2Id === 2);
    const threePointStone3 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification3Id === 2);

    const sixPointStone1 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification1Id === 1);
    const sixPointStone2 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification2Id === 1);
    const sixPointStone3 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification3Id === 1);

    const mmStone1 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification1Id === 36);
    const mmStone2 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification2Id === 36);
    const mmStone3 = storageYardTotalRecipts?.filter((value: YardReciptData) => value.specification3Id === 36);

    let _threePointStone1Total = 0;
    let _threePointStone2Total = 0;
    let _threePointStone3Total = 0;
    let _sixPointStone1Total = 0;
    let _sixPointStone2Total = 0;
    let _sixPointStone3Total = 0;
    let _mmStone1Total = 0;
    let _mmStone2Total = 0;
    let _mmStone3Total = 0;

    threePointStone1?.forEach((element: YardReciptData) => {
      _threePointStone1Total += element.specification1Value;
    });
    threePointStone2?.forEach((element: YardReciptData) => {
      _threePointStone2Total += element.specification2Value;
    });
    threePointStone3?.forEach((element: YardReciptData) => {
      _threePointStone3Total += element.specification3Value;
    });

    sixPointStone1?.forEach((element: YardReciptData) => {
      _sixPointStone1Total += element.specification1Value;
    });
    sixPointStone2?.forEach((element: YardReciptData) => {
      _sixPointStone2Total += element.specification2Value;
    });
    sixPointStone3?.forEach((element: YardReciptData) => {
      _sixPointStone3Total += element.specification3Value;
    });

    mmStone1?.forEach((element: YardReciptData) => {
      _mmStone1Total += element.specification1Value;
    });
    mmStone2?.forEach((element: YardReciptData) => {
      _mmStone2Total += element.specification2Value;
    });
    mmStone3?.forEach((element: YardReciptData) => {
      _mmStone3Total += element.specification3Value;
    });

    updateEvent({ threePointStone1Total: _threePointStone1Total });
    updateEvent({ threePointStone2Total: _threePointStone2Total });
    updateEvent({ threePointStone3Total: _threePointStone3Total });
    updateEvent({ sixPointStone1Total: _sixPointStone1Total });
    updateEvent({ sixPointStone2Total: _sixPointStone2Total });
    updateEvent({ sixPointStone3Total: _sixPointStone3Total });
    updateEvent({ mmStone1Total: _mmStone1Total });
    updateEvent({ mmStone2Total: _mmStone2Total });
    updateEvent({ mmStone3Total: _mmStone3Total });
  };

  const totalReceiptsName: Array<ExcelHeader> = [
    { name: '資料筆數' },
    { name: '建單時間' },
    { name: '車號' },
    { name: '供應商' },
    { name: '來源' },
    { name: '規格' },
    { name: '斗數' },
    { name: '入料料庫' },
  ];
  useEffect(() => {
    getStoneCal();
    getSandCal();
  }, [exporting, storageYardTotalRecipts]);
  return (
    <table id="table" ref={exportIdRef} style={{ display: 'none' }}>
      <tbody>
        <tr>
          <td colSpan={11} style={{ textAlign: 'center', fontSize: '32px' }}>
            堆場入庫統計表
          </td>
        </tr>
        <tr>
          <td>資料期間：</td>
          <td>
            {moment(query.startTime).format('YYYY/MM/DD')}~{moment(query.endTime).format('YYYY/MM/DD')}
          </td>
          <td>區域：</td>
          <td>{selectedAreaName}</td>
          <td>廠別：</td>
          <td>{factoriesName}</td>
        </tr>
        <tr></tr>
        <tr>
          <td>六分石合計：</td>
          <td>{`${event.sixPointStone1Total + event.sixPointStone2Total + event.sixPointStone3Total}斗`}</td>
          <td>粗砂合計：</td>
          <td>{`${event.roughSand1Total + event.roughSand2Total + event.roughSand3Total}斗`}</td>
        </tr>
        <tr>
          <td>三分石合計：</td>
          <td>{`${event.threePointStone1Total + event.threePointStone2Total + event.threePointStone3Total}斗`}</td>
          <td>細砂合計：</td>
          <td>{`${event.thinSand1Total + event.thinSand2Total + event.thinSand3Total}斗`}</td>
        </tr>
        <tr>
          <td>0.8mm合計：</td>
          <td>{`${event.mmStone1Total + event.mmStone2Total + event.mmStone3Total}斗`}</td>
        </tr>
        <tr>
          <td>總計入料：</td>
          <td>{`${
            event.threePointStone1Total +
            event.threePointStone2Total +
            event.threePointStone3Total +
            event.sixPointStone1Total +
            event.sixPointStone2Total +
            event.sixPointStone3Total +
            event.mmStone1Total +
            event.mmStone2Total +
            event.mmStone3Total
          }斗`}</td>
          <td>總計入料：</td>
          <td>{`${
            event.roughSand1Total +
            event.roughSand2Total +
            event.roughSand3Total +
            event.thinSand1Total +
            event.thinSand2Total +
            event.thinSand3Total
          }斗`}</td>
        </tr>
        <tr></tr>
        <tr>
          {totalReceiptsName?.map((value: ExcelHeader, index: number) => {
            return <td key={index}>{value.name}</td>;
          })}
        </tr>
        {storageYardTotalRecipts?.map((value: YardReciptData, index: number) => {
          return (
            <React.Fragment key={index}>
              <tr>
                <td>{index + 1}</td>
                {value.createTime ? <td>{moment(value.createTime).format('YYYY/MM/DD HH:mm')}</td> : <td>&nbsp;</td>}
                {value.plateNumber ? <td>{value.plateNumber}</td> : <td>&nbsp;</td>}
                {value.supplier1Name ? <td>{value.supplier1Name}</td> : <td>&nbsp;</td>}
                {value.source1Name ? <td>{value.source1Name}</td> : <td>&nbsp;</td>}
                {value.specification1Name ? <td>{value.specification1Name}</td> : <td>&nbsp;</td>}
                {value.specification1Value ? <td>{value.specification1Value}</td> : <td>&nbsp;</td>}
                {value.storageName ? <td>{value.storageName}</td> : <td>&nbsp;</td>}
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                {value.supplier2Name ? <td>{value.supplier2Name}</td> : <td>&nbsp;</td>}
                {value.source2Name ? <td>{value.source2Name}</td> : <td>&nbsp;</td>}
                {value.specification2Name ? <td>{value.specification2Name}</td> : <td>&nbsp;</td>}
                {value.specification2Value ? <td>{value.specification2Value}</td> : <td>&nbsp;</td>}
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                {value.supplier3Name ? <td>{value.supplier3Name}</td> : <td>&nbsp;</td>}
                {value.source3Name ? <td>{value.source3Name}</td> : <td>&nbsp;</td>}
                {value.specification3Name ? <td>{value.specification3Name}</td> : <td>&nbsp;</td>}
                {value.specification3Value ? <td>{value.specification3Value}</td> : <td>&nbsp;</td>}
                <td>&nbsp;</td>
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}
