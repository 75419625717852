import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import { Field } from 'formik';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import { FactoryData } from '@Src/_basic/object/FactoryType';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import { FactoryApi } from '@Src/_basic/protocol/factoryManagement/FactoryApi';
import YatungFormSelect from '@Src/_basic/components/YatungFormSelect';
import { useLocation } from 'react-router';

interface Props {
  setValue: (name: string, nubmer: any) => void;
  value: FactoryData;
}
const actionImage = '/media/images/action.png';

export default function FactoryManagementUpdateField(props: Props) {
  const { t: i18T } = useTranslation();
  const { setValue, value } = props;
  const { state: areaData } = useLocation();

  const handleFactoryState = () => {
    const filterAreaName = areaData.find((data: any) => data.value === value.areaId);
    if (value.removed === false) {

      setValue('removed', !value.removed);
      setValue('areaId', 0);
      handleChangeStatus();
    } else {
      setValue('removed', !value.removed);
      setValue('areaName', filterAreaName?.text);

      handleChangeStatus();
    }
  };
  const handleChangeStatus = () => {
    FactoryApi.DeleteFactoryById(
      value.id,
      undefined,
      undefined,
      undefined,
    );
  };
  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }} >
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('FACTORYMANAGEMENT.AREA')} />
        </Grid>
        <Grid container item xs={11}>
          {
            value.removed === false
              ?
              <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{value.areaName}</Typography>
              : <Field
                id="areaId"
                name="areaId"
                type="number"
                label=""
                component={YatungFormSelect}
                sx={{ minWidth: '70%' }}
                options={areaData}
                required
              />
          }
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('FACTORYMANAGEMENT.FACTORY')} />
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={7.5} sx={{ mt: 2 }}>
          <Grid container justifyContent={'space-between'} item xs={12}>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{value.factoryName}</Typography>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: value.removed ? '#CB333B' : '#3E9B2F' }}>
              {value.removed ? i18T('GLOBAL.CLOSING') : i18T('GLOBAL.RUNNING')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={3.5} sx={{ mt: 2 }}>
          <YatungButton
            text={value.removed ? i18T('FACTORYMANAGEMENT.ACTION_OPEN') : i18T('FACTORYMANAGEMENT.ACTION_CLOSE')}
            variant="contained"
            color="red"
            type={'button'}
            size={'large'}
            startIcon={<YatungImg img={actionImage} />}
            sx={{
              color: '#ffffff',
              fontSize: '20px',
              ml: 3,
              bgcolor: value.removed ? '#00D03A' : '#CB333B',
              '&.MuiButtonBase-root:hover': {
                bgcolor: value.removed ? '#00D03A' : '#CB333B',
              },
            }}
            onClick={handleFactoryState}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('FACTORYMANAGEMENT.ADDRESS')} />
        </Grid>
        <Grid container item xs={11}>
          <Field
            id="factoryAddress"
            name="factoryAddress"
            type="text"
            label=""
            component={YatungFormInput}
            sx={{ minWidth: '70%' }}
            required
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('FACTORYMANAGEMENT.PHONE')} />
        </Grid>
        <Grid container item xs={11}>
          <Field
            id="factoryPhone"
            name="factoryPhone"
            type="text"
            label=""
            component={YatungFormInput}
            sx={{ minWidth: '70%' }}
            required
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container item xs={3}>
            <YatungFormLabel label={i18T('FACTORYMANAGEMENT.SYMBOL')} />
          </Grid>
          <Grid container item xs={9}>
            <Field
              id="factoryCode"
              name="factoryCode"
              type="text"
              label=""
              component={YatungFormInput}
              sx={{ minWidth: '100%' }}
              required
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={6} sx={{ ml: '2%' }}>
          <Grid container item xs={2}>
            <YatungFormLabel label={i18T('FACTORYMANAGEMENT.NUMBER')} />
          </Grid>
          <Grid container item xs={10}>
            <Field
              id="branchCode"
              name="branchCode"
              type="text"
              label=""
              component={YatungFormInput}
              sx={{ minWidth: '69%' }}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
