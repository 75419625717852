import { CardMedia, SxProps } from '@mui/material';
import React from 'react';

interface Props {
  vid: string;
  sx?: SxProps;
}
export default function YatungVideo(props: Props) {
  const { vid, sx } = props;
  return (
    <>
      <CardMedia component="video" image={vid} autoPlay sx={sx} />
    </>
  );
}
