import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import { useApi } from '@Src/redux/api/apiAction';
import { Box, Stack, Typography, styled } from '@mui/material';
import React, { ChangeEvent, memo, useRef } from 'react';

const Button = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#BB5E00',
  userSelect: 'none',
  gap: '8px',
});

const ButtonLabel = styled(Typography)({
  color: '#697EA3',
  fontFamily: 'Microsoft JhengHei',
  fontWeight: 400,
  size: '20px',
});

interface Props {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function UploadPhoto(props: Props) {
  const { handleChange } = props;
  const { actionLoading } = useApi();
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadFileButtonClick = () => {
    if (actionLoading) return;
    hiddenFileInputRef?.current?.click();
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '90px',
        width: '100%',
        border: '1px dotted gray',
      }}
    >
      <Button onClick={handleUploadFileButtonClick} style={{ cursor: actionLoading ? 'wait' : 'pointer' }}>
        {actionLoading ? null : <AddCircleFill />}
        <ButtonLabel>上傳圖片</ButtonLabel>
      </Button>
      <input
        ref={hiddenFileInputRef}
        type="file"
        accept=".jpeg,.png,.jpg"
        multiple
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </Stack>
  );
}

export default memo(UploadPhoto);
