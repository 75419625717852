import YatungLoading from '@Src/_basic/components/YatungLoading';
import DeliveryInformation from '@Src/_basic/icons/DeliveryInformation';
import DeliveryInformationWarning from '@Src/_basic/icons/DeliveryInformationWarning';
import { MaterialInspectionAbnormalExpt, MaterialInspectionArea } from '@Src/_basic/object/MaterialInspectionType';
import { Box, Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface Props {
  materialInspectionAreaData: Array<MaterialInspectionArea>;
  loading: boolean;
}
export default function MaterialInspectionByAreaCard(props: Props) {
  const navigate = useNavigate();
  const { t: i18T } = useTranslation();
  const { materialInspectionAreaData, loading } = props;

  const MaterialInspectionDetailItem = ({ label, value = '' }: any) => {
    return (
      <Stack spacing={1} alignItems="center" justifyContent="space-between" direction="row" sx={{ my: 1 }}>
        <Typography
          sx={{ fontFamily: 'Abel', fontWeight: 400, fontSize: 26, color: '#FFFFFF' }}
        >{`${label} : `}</Typography>
        <Typography
          sx={{ fontFamily: 'Abel', fontWeight: 400, fontSize: 26, color: '#FFFFFF' }}
        >{`${value}`}</Typography>
      </Stack>
    );
  };
  const handleGotoNextPage = (id: number, areaName: string) => {
    navigate(`/realTimeMonitoring/materialInspection/materialInspectionByFactory/${id}`, { state: { areaName } });
  };
  return (
    <>
      {loading && <YatungLoading overlay={true} />}
      {!loading && (
        <Stack display={'flex'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
          {materialInspectionAreaData.map((data: MaterialInspectionArea, index: number) => {
            const qualifiedRatio = 1;
            const progress = Math.floor(qualifiedRatio * 100);
            const ratio = data.total == 0 ? 0 : Math.floor((data.completed / data.total) * 100);
            const getColor = () => {
              if (progress > 80) {
                return '#7C878E';
              } else if (progress > 50) {
                return '#697EA3';
              } else {
                return '#BB5E00';
              }
            };
            return (
              <React.Fragment key={data.areaId}>
                <Stack sx={{ cursor: 'pointer', mt: 1 }}>
                  <Card
                    sx={{ width: '488px', height: '360px', borderRadius: '13px', background: '#FFFFFF' }}
                    onClick={() => handleGotoNextPage(data.areaId, data.areaName)}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          width: '462px',
                          height: '335px',
                          borderRadius: '11px',
                          background: getColor(),
                          position: 'relative',
                          p: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: '210px',
                            height: '50px',
                            borderLeft: '1px solid #fff',
                            borderBottom: '1px solid #fff',
                            position: 'absolute',
                            borderBottomLeftRadius: '11px',
                            borderTopRightRadius: '11px',
                            background: getColor(),
                            top: 0,
                            right: 0,
                            p: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 25,
                              fontFamily: 'Abel',
                              fontWeight: 400,
                              color: '#FFFFFF',
                              textAlign: 'center',
                            }}
                          >
                            {data.areaName}
                          </Typography>
                        </Box>
                        {data.abnormal > 0 ? (
                          <>
                            <Stack flexDirection={'row'}>
                              <DeliveryInformationWarning sx={{ width: 37, height: 32, mr: 3 }} />
                              <Typography
                                sx={{
                                  fontSize: 25,
                                  fontFamily: 'Abel',
                                  fontWeight: 400,
                                  color: '#FFFFFF',
                                }}
                              >
                                {i18T('GLOBAL.ABNORMAL')}
                              </Typography>
                            </Stack>

                            <Stack spacing={1} alignItems="center" justifyContent="space-between" direction="row">
                              <Typography sx={{ fontSize: 60, fontFamily: 'Abel', fontWeight: 400, color: '#FFFFFF' }}>
                                {i18T('GLOBAL.PIECES', { pieces: data.abnormal })}
                              </Typography>
                              <DeliveryInformation sx={{ height: '114px', width: '100px' }} />
                            </Stack>
                            <Divider sx={{ border: '1px solid #ffffff', width: '100%', my: 1 }} />

                            {data.abnormalExpt
                              .slice(0, 3)
                              .map((item: MaterialInspectionAbnormalExpt, index: number) => (
                                <React.Fragment key={item.id}>
                                  <MaterialInspectionDetailItem
                                    label={i18T(`TEST.TEST_ITEM.${item.exptItemCode}`)}
                                    value={i18T('GLOBAL.ABNORMAL')}
                                  />
                                </React.Fragment>
                              ))}
                          </>
                        ) : (
                          <>
                            <Typography
                              sx={{
                                fontSize: 25,
                                fontFamily: 'Abel',
                                fontWeight: 400,
                                color: '#FFFFFF',
                              }}
                            >
                              {i18T('GLOBAL.PASS_RATE')}
                            </Typography>
                            <Stack spacing={1} alignItems="center" justifyContent="space-between" direction="row">
                              <Typography sx={{ fontSize: 60, fontFamily: 'Abel', fontWeight: 400, color: '#FFFFFF' }}>
                                {`${progress}％`}
                              </Typography>
                              <DeliveryInformation sx={{ height: '114px', width: '100px' }} />
                            </Stack>
                            <Divider sx={{ border: '1px solid #ffffff', width: '100%', my: 1 }} />
                            <MaterialInspectionDetailItem
                              label={i18T('APPLICATIONSMANAGEMENT.MATERIAL_INSPECTION_PASS_MONITORING.TEST_PROJECT')}
                              value={i18T('GLOBAL.ITEM', { item: data.total })}
                            />
                            <MaterialInspectionDetailItem
                              label={i18T('APPLICATIONSMANAGEMENT.MATERIAL_INSPECTION_PASS_MONITORING.TEST_COMPLETE')}
                              value={i18T('GLOBAL.ITEM', { item: data.completed })}
                            />
                            <MaterialInspectionDetailItem
                              label={i18T(
                                'APPLICATIONSMANAGEMENT.MATERIAL_INSPECTION_PASS_MONITORING.TEST_COMPLETE_RATIO',
                              )}
                              value={`${ratio}％`}
                            />
                          </>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Stack>
              </React.Fragment>
            );
          })}
        </Stack>
      )}
    </>
  );
}
