import YatungInput from '@Src/_basic/components/YatungInput';
import { Stack, Typography } from '@mui/material';
import React from 'react';

const LabelInput = ({ label, editable = true, value, valueWidth, isError, ...props }: any) => {
  return (
    <Stack flexDirection="row" alignItems="center" sx={{ mx: 1 }}>
      {label && <Typography sx={{ fontSize: '20px', fontWeight: 900, color: '#002F8C' }}>{label}</Typography>}
      {editable ? (
        <YatungInput
          {...props}
          value={value}
          sx={{ width: valueWidth ? valueWidth : '232px', border: isError ? '1px red solid' : {} }}
        />
      ) : (
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 400,
            color: '#6C708C',
            padding: '8px 12px',
            width: valueWidth ? valueWidth : '232px',
          }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};

export default LabelInput;
