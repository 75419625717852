import React from 'react';

export default function Clock2() {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none">
      <circle cx={14} cy={14} r="13.5" stroke="#6C708C" />
      <path d="M13 7H15V17H5.5V15H13V7Z" fill="#6C708C" />
    </svg>
  );
}
