import React, { useRef } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import AddCircleFill from '@Src/_basic/icons/AddCircleFill';

const Button = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#BB5E00',
  userSelect: 'none',
  gap: '8px',
});

const ButtonLabel = styled(Typography)({
  color: '#697EA3',
  fontFamily: 'Microsoft JhengHei',
  fontWeight: 400,
  size: '20px',
});

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: '#9A99A1',
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  letterSpacing: '0.255em',
}));

interface Props {
  handleChange: (event: any) => void;

}

export default function UploadFileCard(props: Props) {
  const { handleChange } = props;
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadFileButtonClick = () => {
    hiddenFileInputRef?.current?.click();
  };

  return (

    <Stack
      justifyContent="center"
      alignItems="center"
      gap="16px"
      sx={{
        top: '0px',
        left: '0px',
        height: '100%',
        width: '100%',
        border: '1px dotted gray',
      }}
    >
      <Button onClick={handleUploadFileButtonClick}>
        <AddCircleFill />
        <ButtonLabel>上傳檔案</ButtonLabel>
      </Button>
      <Label>檔案上傳只限PDF檔案</Label>
      <input
        ref={hiddenFileInputRef}
        type="file"
        accept=".pdf"
        multiple
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </Stack>

  );
}

