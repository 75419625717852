import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContentAndGroupsTableType } from '@Src/_basic/object/AccountRightType';
import { useApi } from '@Src/redux/api/apiAction';
import { Options } from '../../../../_components/FormSelect';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import StatusColumnFormatter from './columnFormatter/StatusColumnFormatter';

import DeleteIcon from '@Src/_basic/icons/Delete';
import EditIcon from '@Src/_basic/icons/Edit';

interface Props {
  factoryOptions: Array<Options>;
  accountGroupData: Array<ContentAndGroupsTableType>;
  updateAndDeleteModel: (data: ContentAndGroupsTableType, model: 'UPDATE' | 'DELETE') => void;
}

export default function AccountGroupManagementTable({ factoryOptions, accountGroupData, updateAndDeleteModel }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const columns: Array<Column> = [
    {
      text: i18T('GLOBAL.NO'),
      dataField: 'index',
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.CODE'),
      dataField: 'code',
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.NAME'),
      dataField: 'name',
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.FACTORY'),
      dataField: 'attrs',
      formatter: ({ row }) => {
        const value = row.attrs.find((item: any) => item.code === 'factory')?.attrVal;
        const text = factoryOptions.find((item: any) => item.value.toString() === value)?.text;
        return <Typography>{text}</Typography>;
      },
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.EDIT_TIME'),
      dataField: 'updateDt',
    },
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.STATUS'),
      dataField: 'state',
      formatter: StatusColumnFormatter,
      formatExtraData: {
        openText: i18T('GLOBAL.OPENING'),
        closeText: i18T('GLOBAL.STOPING'),
      },
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      width: '250px',
      formatter: ({ row }) => {
        return (
          <Stack display="flex" flexDirection="row" justifyContent="space-between">
            <YatungButton
              text={i18T('GLOBAL.UPDATE')}
              startIcon={<EditIcon />}
              color="yellow"
              onClick={() => updateAndDeleteModel(row, 'UPDATE')}
            />
            <YatungButton
              text={i18T('GLOBAL.DELETE')}
              startIcon={<DeleteIcon />}
              color="light_red"
              onClick={() => updateAndDeleteModel(row, 'DELETE')}
            />
          </Stack>
        );
      },
    },
  ];
  return <YatungTable loading={actionLoading} columns={columns} data={accountGroupData} />;
}
