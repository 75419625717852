import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import CancelIcon from '@Src/_basic/icons/Cancel';
import FinishIcon from '@Src/_basic/icons/Finish';
import { SearchExperimentItemsParams } from '@Src/_basic/object/ExperimentItemType';
import { CreateLegalStandardFormData } from '@Src/_basic/object/LegalStandardType';
import { ExperimentItemApi } from '@Src/_basic/protocol/experimentItem/ExperimentItemApi';
import { StandardV2Api } from '@Src/_basic/protocol/standardV2/legal/LegalStandardV2Api';
import { Stack } from '@mui/material';
import qs from 'qs';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateLegalStandardForm from '../components/CreateLegalStandardForm';

const background = '/media/images/applicationsMange/legalStandardBG.png';

const initTypeOptions: Options[] = [
  { value: 1, text: '砂' },
  { value: 2, text: '石' },
  { value: 3, text: '水泥' },
  { value: 4, text: '爐石' },
  { value: 5, text: '飛灰' },
  { value: 6, text: '藥劑' },
  { value: 7, text: '拌合水' },
  { value: 8, text: '混凝土' },
  { value: 11, text: '回收水' },
];

const initFormDataValue: CreateLegalStandardFormData = {
  id: 0,
  name: '',
  experimentResultTypeId: 0,
  factoryIds: [],
  specifications: [],
  startDate: new Date().getTime(),
  endDate: new Date().getTime(),
};

const initFilterValue = {
  typeId: 0,
  experimentItemId: 0,
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'updateValue':
      return { ...state, ...action.payload };
    default:
      return { state };
  }
}

export default function CreateLegalStandardScreen() {
  const { t: i18T } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [typeOptions, setTypeOptions] = useState<Options[]>(initTypeOptions);
  const [experimentItemOptions, setExperimentItemOptions] = useState<Options[]>([]);
  const [filterValue, filterValueDispatch] = useReducer(reducer, initFilterValue);
  const [legalStandard, legalStandardDispatch] = useReducer(reducer, initFormDataValue);
  const btnRef = useRef<HTMLButtonElement>(null);

  const queryObject = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);

  const getExperimentItemOptions = (typeId: number) => {
    const searchParams: SearchExperimentItemsParams = { typeId };
    ExperimentItemApi.getAllExperimentItems(searchParams, onGetExperimentItemOptionsSuccess);
  };

  const onGetExperimentItemOptionsSuccess = (data: Array<any>) => {
    setExperimentItemOptions(data.map(({ id, itemName }: any) => ({ value: id, text: itemName })));
  };

  const handleSubmit = (values: any) => {
    StandardV2Api.createLegalStandard(values, () => {
      navigate(`/qualityManagement/legalStandards/list?typeId=${queryObject.typeId}`, { replace: true });
    });
  };

  const handleExperimentItemChange = (value: any) => {
    handleFilterChange('experimentItemId')(value);
  };

  const handleTypeChange = (value: any) => {
    handleFilterChange('typeId')(value);
  };

  const handleCancelButtonClick = () => {
    navigate(`/qualityManagement/legalStandards/list?typeId=${queryObject.typeId}`, { replace: true });
  };

  const handleFinishButtonClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const handleFilterChange = (field: string) => async (value: any) => {
    filterValueDispatch({ type: 'updateValue', payload: { [field]: value } });
  };

  useEffect(() => {
    if (filterValue.typeId) {
      getExperimentItemOptions(filterValue.typeId);
    }
  }, [filterValue.typeId]);

  useEffect(() => {
    if (experimentItemOptions.length) {
      if (experimentItemOptions.filter((item) => item.value === parseInt(filterValue.experimentItemId)).length === 0) {
        handleExperimentItemChange(experimentItemOptions[0].value);
      }
    }
  }, [experimentItemOptions]);

  useEffect(() => {
    if (location.search) {
      const queryObject = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (queryObject.typeId) {
        handleTypeChange(queryObject.typeId);
      }
      if (queryObject.experimentItemId) {
        handleExperimentItemChange(queryObject.experimentItemId);
      }
      if (queryObject.experimentResultTypeId) {
        legalStandardDispatch({
          type: 'updateValue',
          payload: { experimentResultTypeId: parseInt(queryObject.experimentResultTypeId.toString()) },
        });
      }
    }
  }, [location.search]);

  return (
    <YatungPage
      title={i18T('QUALITY_MANAGE.SIDE_MENU.STANDARDIZED_PENALTY_MANAGEMENT.LEGAL_STANDARD')}
      backgroundImage={background}
      body={
        <>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2} sx={{ py: 1 }}>
              <YatungSelect
                disabled={typeOptions.length === 0 || loading}
                options={typeOptions}
                value={filterValue.typeId}
                onChange={(e) => handleTypeChange(e.target.value)}
              />
              <YatungSelect
                disabled={experimentItemOptions.length === 0 || loading}
                options={experimentItemOptions}
                value={filterValue.experimentItemId}
                onChange={(e) => handleExperimentItemChange(e.target.value)}
              />
            </Stack>
            <Stack direction="row" spacing={2} sx={{ py: 1 }}>
              <YatungButton text="取消" startIcon={<CancelIcon />} color="red" onClick={handleCancelButtonClick} />
              <YatungButton text="完成" startIcon={<FinishIcon />} color="blue" onClick={handleFinishButtonClick} />
            </Stack>
          </Stack>
          <CreateLegalStandardForm
            typeId={filterValue.typeId}
            experimentItemId={filterValue.experimentItemId}
            btnRef={btnRef}
            initialValues={legalStandard}
            handleSubmit={handleSubmit}
          />
        </>
      }
    />
  );
}
