import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CreateFactory(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="31" height="31" viewBox="0 0 31 31" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52233 21.4447C7.44552 21.4447 9.00458 19.8857 9.00458 17.9625C9.00458 16.0393 7.44552 14.4802 5.52233 14.4802C3.59915 14.4802 2.04009 16.0393 2.04009 17.9625C2.04009 19.8857 3.59915 21.4447 5.52233 21.4447ZM5.45016 14.9156C5.61043 14.9156 5.74035 15.0455 5.74035 15.2058V17.5998H8.13439C8.29466 17.5998 8.42458 17.7297 8.42458 17.89C8.42458 18.0503 8.29466 18.1802 8.13439 18.1802H5.74035V20.5742C5.74035 20.7345 5.61043 20.8644 5.45016 20.8644C5.2899 20.8644 5.15998 20.7345 5.15998 20.5742V18.1802H2.76593C2.60567 18.1802 2.47575 18.0503 2.47575 17.89C2.47575 17.7297 2.60567 17.5998 2.76593 17.5998H5.15998V15.2058C5.15998 15.0455 5.2899 14.9156 5.45016 14.9156Z"
        fill="white"
      />
      <mask id="path-2-inside-1_401_44934" fill="white">
        <path d="M28.6851 0.611839C28.8519 0.611839 28.987 0.746953 28.987 0.913626V2.22137C28.987 2.55472 28.7166 2.82494 28.3831 2.82494H26.5714V3.9315C26.5714 4.09817 26.4363 4.23328 26.2695 4.23328C26.1027 4.23328 25.9675 4.09817 25.9675 3.9315V2.82494C25.9675 2.4916 26.2379 2.22137 26.5714 2.22137H28.3831V0.913626C28.3831 0.746953 28.5183 0.611839 28.6851 0.611839Z" />
        <path d="M30.6981 2.42256C30.8649 2.42256 31 2.55768 31 2.72435V4.03209C31 4.36544 30.7296 4.63567 30.3961 4.63567H28.5844V5.74222C28.5844 5.90889 28.4493 6.04401 28.2825 6.04401C28.1157 6.04401 27.9805 5.90889 27.9805 5.74222V4.63567C27.9805 4.30232 28.2509 4.03209 28.5844 4.03209H30.3961V2.72435C30.3961 2.55768 30.5313 2.42256 30.6981 2.42256Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.01299 4.83686H2.81818V6.44639H3.01948V6.84877H2.81818V8.25711H3.01948V8.65949H2.81818V9.46426H3.42208V7.25115C3.42208 7.02892 3.60233 6.84877 3.82468 6.84877H5.43506V6.2452C5.43506 6.02297 5.61531 5.84281 5.83766 5.84281H8.45455C8.67689 5.84281 8.85714 6.02297 8.85714 6.2452V7.85473H16.5065V6.84877H16.3052V6.44639H16.5065V4.83686H17.3117V6.44639H17.513V6.84877H17.3117V8.25711H17.513V8.65949H17.3117V9.46426H18.1169V6.2452C18.1169 6.02297 18.2971 5.84281 18.5195 5.84281H21.1364C21.3587 5.84281 21.539 6.02297 21.539 6.2452V9.46426H24.3571V21.7369H14.4935V20.3286H10.2662V23.3465H19.3247V29.181H8.11603V25.7608H2.41558V29.181H0.402597V9.46426H2.01299V8.65949H1.81169V8.25711H2.01299V6.84877H1.81169V6.44639H2.01299V4.83686ZM10.2662 19.725V9.46426H14.4935V19.725H10.2662Z"
        />
        <path d="M28.7857 9.06188L29.3896 6.84877H24.961L25.5649 9.06188H28.7857Z" />
        <path d="M28.7857 9.46426L29.3896 22.9441H24.961L25.5649 9.46426H28.7857Z" />
        <path d="M24.3571 22.1393H14.4935V22.9441H24.3571V22.1393Z" />
        <path d="M0 29.9858C0 29.7635 0.180249 29.5834 0.402597 29.5834H29.7922C30.0146 29.5834 30.1948 29.7635 30.1948 29.9858C30.1948 30.208 30.0146 30.3882 29.7922 30.3882H0.402597C0.180249 30.3882 0 30.208 0 29.9858Z" />
        <path d="M19.7273 29.181H29.5909V23.3465H19.7273V29.181Z" />
      </mask>
      <path
        d="M28.6851 0.611839C28.8519 0.611839 28.987 0.746953 28.987 0.913626V2.22137C28.987 2.55472 28.7166 2.82494 28.3831 2.82494H26.5714V3.9315C26.5714 4.09817 26.4363 4.23328 26.2695 4.23328C26.1027 4.23328 25.9675 4.09817 25.9675 3.9315V2.82494C25.9675 2.4916 26.2379 2.22137 26.5714 2.22137H28.3831V0.913626C28.3831 0.746953 28.5183 0.611839 28.6851 0.611839Z"
        stroke="white"
        mask="url(#path-2-inside-1_401_44934)"
      />
      <path
        d="M30.6981 2.42256C30.8649 2.42256 31 2.55768 31 2.72435V4.03209C31 4.36544 30.7296 4.63567 30.3961 4.63567H28.5844V5.74222C28.5844 5.90889 28.4493 6.04401 28.2825 6.04401C28.1157 6.04401 27.9805 5.90889 27.9805 5.74222V4.63567C27.9805 4.30232 28.2509 4.03209 28.5844 4.03209H30.3961V2.72435C30.3961 2.55768 30.5313 2.42256 30.6981 2.42256Z"
        stroke="white"
        mask="url(#path-2-inside-1_401_44934)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.01299 4.83686H2.81818V6.44639H3.01948V6.84877H2.81818V8.25711H3.01948V8.65949H2.81818V9.46426H3.42208V7.25115C3.42208 7.02892 3.60233 6.84877 3.82468 6.84877H5.43506V6.2452C5.43506 6.02297 5.61531 5.84281 5.83766 5.84281H8.45455C8.67689 5.84281 8.85714 6.02297 8.85714 6.2452V7.85473H16.5065V6.84877H16.3052V6.44639H16.5065V4.83686H17.3117V6.44639H17.513V6.84877H17.3117V8.25711H17.513V8.65949H17.3117V9.46426H18.1169V6.2452C18.1169 6.02297 18.2971 5.84281 18.5195 5.84281H21.1364C21.3587 5.84281 21.539 6.02297 21.539 6.2452V9.46426H24.3571V21.7369H14.4935V20.3286H10.2662V23.3465H19.3247V29.181H8.11603V25.7608H2.41558V29.181H0.402597V9.46426H2.01299V8.65949H1.81169V8.25711H2.01299V6.84877H1.81169V6.44639H2.01299V4.83686ZM10.2662 19.725V9.46426H14.4935V19.725H10.2662Z"
        stroke="white"
        mask="url(#path-2-inside-1_401_44934)"
      />
      <path
        d="M28.7857 9.06188L29.3896 6.84877H24.961L25.5649 9.06188H28.7857Z"
        stroke="white"
        mask="url(#path-2-inside-1_401_44934)"
      />
      <path
        d="M28.7857 9.46426L29.3896 22.9441H24.961L25.5649 9.46426H28.7857Z"
        stroke="white"
        mask="url(#path-2-inside-1_401_44934)"
      />
      <path
        d="M24.3571 22.1393H14.4935V22.9441H24.3571V22.1393Z"
        stroke="white"
        mask="url(#path-2-inside-1_401_44934)"
      />
      <path
        d="M0 29.9858C0 29.7635 0.180249 29.5834 0.402597 29.5834H29.7922C30.0146 29.5834 30.1948 29.7635 30.1948 29.9858C30.1948 30.208 30.0146 30.3882 29.7922 30.3882H0.402597C0.180249 30.3882 0 30.208 0 29.9858Z"
        stroke="white"
        mask="url(#path-2-inside-1_401_44934)"
      />
      <path d="M19.7273 29.181H29.5909V23.3465H19.7273V29.181Z" stroke="white" mask="url(#path-2-inside-1_401_44934)" />
    </SvgIcon>
  );
}
