import { ReportViewResponse } from '@Src/_basic/object/MonthlyReportType';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Dialog, DialogContent, IconButton, Slide, Stack, Toolbar, Typography, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CoarseAggregateAbsorptionReport from '../CoarseAggregateAbsorptionReport';
import CoarseAggregateAnalysisReport from '../CoarseAggregateAnalysisReport';
import ConcreteAntiStressReport from '../ConcreteAntiStressReport';
import FineAggregateAMSReport from '../FineAggregateAMSReport';
import FineAggregateAbsorptionReport from '../FineAggregateAbsorptionReport';
import FineAggregateAnalysisReport from '../FineAggregateAnalysisReport';
import FineAggregateChlorideIonsReport from '../FineAggregateChlorideIonsReport';
import FineAggregateRValueReport from '../FineAggregateRValueReport';
import FlyAshLValueReport from '../FlyAshLValueReport';
import FlyAshLossOfIgnitionReport from '../FlyAshLossOfIgnitionReport';
import MonthlyHeader from './MonthlyHeader';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: 12,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#BB5E00',
  },
}));

interface Props {
  open: boolean;
  closeHandler: () => void;
  detailData: any;
  headerData: ReportViewResponse;
}

const MonthlyReportDialog = ({ open, closeHandler, detailData, headerData }: Props) => {
  const { t: i18T } = useTranslation();

  const showReport = useMemo(() => {
    switch (headerData.monthlyReportExperimentItem.id) {
      case 1:
        return <FineAggregateAnalysisReport report={detailData} />;
      case 2:
        return <FineAggregateAMSReport report={detailData} />;
      case 3:
        return <FineAggregateAbsorptionReport report={detailData} />;
      case 4:
        return <FineAggregateChlorideIonsReport report={detailData} headerData={headerData} />;
      case 5:
        return <CoarseAggregateAnalysisReport report={detailData} />;
      case 6:
        return <CoarseAggregateAbsorptionReport report={detailData} />;
      case 7:
        return <FlyAshLValueReport report={detailData} headerData={headerData} />;
      case 8:
        return <FlyAshLossOfIgnitionReport report={detailData} headerData={headerData} />;
      case 9:
        return <ConcreteAntiStressReport report={detailData} headerData={headerData} />;
      case 10:
        return <FineAggregateRValueReport report={detailData} headerData={headerData} />;
      default:
        return;
    }
  }, [headerData, detailData]);

  return (
    <Dialog fullScreen open={open} onClose={closeHandler} TransitionComponent={Transition} sx={{ py: 2, px: 10 }}>
      <CustomDialogContent>
        <AppBar sx={{ position: 'absolute', width: '100%', bgcolor: '#BB5E00' }}>
          <Toolbar
            sx={{
              bgcolor: '#BB5E00',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandler}
              aria-label="close"
              sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ flex: 1, fontSize: 24, transform: 'translateX(-15%)' }}>
              {i18T(`REPORT.MONTHLY_REPORT.${headerData.monthlyReportExperimentItem.itemCode}.TITLE`)}
            </Typography>
          </Toolbar>
        </AppBar>

        <Stack flexDirection="column" alignItems="center" gap={4} py={10}>
          <MonthlyHeader headerData={headerData} />
          {showReport}
        </Stack>
      </CustomDialogContent>
    </Dialog>
  );
};

export default memo(MonthlyReportDialog);
