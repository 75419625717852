import YatungLoading from '@Src/_basic/components/YatungLoading';
import YatungProgressBar from '@Src/_basic/components/YatungProgressBar';
import DistrictIcon from '@Src/_basic/icons/DistrictIcon';
import { TestInDistrictFactory } from '@Src/_basic/object/TestInDistrictType';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface Props {
  testInDistrictFactoryData: Array<TestInDistrictFactory>;
  loading: boolean;
}
export default function TestInDistrictByFactoryCard(props: Props) {
  const navigate = useNavigate();
  const { t: i18T } = useTranslation();
  const { testInDistrictFactoryData, loading } = props;
  const TestDistrictDetailItem = ({ label, value = '' }: any) => {
    return (
      <Stack spacing={1} alignItems="center" justifyContent="space-between" direction="row">
        <Typography
          sx={{ fontFamily: 'Abel', fontWeight: 400, fontSize: 26, color: '#FFFFFF' }}
        >{`${label} : `}</Typography>
        <Typography
          sx={{ fontFamily: 'Abel', fontWeight: 400, fontSize: 26, color: '#FFFFFF' }}
        >{`${value}件`}</Typography>
      </Stack>
    );
  };
  const handleGotoNextPage = (id: number, factoryName: string) => {
    navigate(`/realTimeMonitoring/testInDistrict/testsDistrictByDetail/${id}`, { state: { factoryName } });
  };
  return (
    <>
      {loading && <YatungLoading overlay={true} />}
      {!loading && (
        <Stack display={'flex'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
          {testInDistrictFactoryData.map((data: TestInDistrictFactory, index: number) => {
            const progress = data.total == 0 ? 0 : Math.floor((data.completed / data.total) * 100);

            const getColor = () => {
              if (progress >= 80) {
                return '#7C878E';
              } else if (progress >= 50) {
                return '#697EA3';
              } else {
                return '#BB5E00';
              }
            };

            return (
              <React.Fragment key={data.factoryId}>
                <Stack sx={{ cursor: 'pointer' }}>
                  <Card
                    sx={{
                      width: '367px',
                      height: '727px',
                      borderRadius: '13px',
                      background: '#FFFFFF',
                      mt: 1,
                    }}
                    onClick={() => handleGotoNextPage(data.factoryId, data.factoryName)}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          width: '341px',
                          height: '702px',
                          borderRadius: '11px',
                          background: getColor(),
                          position: 'relative',
                          p: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: '210px',
                            height: '50px',
                            borderLeft: '1px solid #fff',
                            borderBottom: '1px solid #fff',
                            position: 'absolute',
                            borderBottomLeftRadius: '11px',
                            borderTopRightRadius: '11px',
                            background: getColor(),
                            top: 0,
                            right: 0,
                            p: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 25,
                              fontFamily: 'Abel',
                              fontWeight: 400,
                              color: '#FFFFFF',
                              textAlign: 'center',
                            }}
                          >
                            {data.factoryName}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: 25,
                            fontFamily: 'Abel',
                            fontWeight: 400,
                            color: '#FFFFFF',
                          }}
                        >
                          {i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.COMPLETENESS')}
                        </Typography>
                        <Stack spacing={2} justifyContent="center" direction="row" sx={{ my: 3 }}>
                          <DistrictIcon sx={{ width: 150, height: 150 }} />
                        </Stack>
                        <Stack spacing={2} justifyContent="center" direction="row">
                          <Typography sx={{ fontSize: 116, fontFamily: 'Abel', fontWeight: 400, color: '#FFFFFF' }}>
                            {`${progress}％`}
                          </Typography>
                        </Stack>
                        <YatungProgressBar percent={progress} />
                        <TestDistrictDetailItem
                          label={i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.INSPECTION_QUANTITY')}
                          value={`${data.completed}／${data.total}`}
                        />
                        <TestDistrictDetailItem
                          label={i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.ABNORMAL')}
                          value={data.abnormal}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Stack>
              </React.Fragment>
            );
          })}
        </Stack>
      )}
    </>
  );
}
