import React from 'react';
import { SiteManagementData, StationNewData } from '@Src/_basic/object/SiteType';
import { Typography } from '@mui/material';
import NamesExpand from '@Src/_basic/components/NamesExpand';

interface Props {
  row: SiteManagementData;
}
export default function SiteColumnFormatter(props: Props) {
  const { row } = props;

  return (
    <Typography variant="inherit">
      <NamesExpand names={row.stations.map((station: StationNewData) => station.stationName)} />
    </Typography>
  );
}
