export default [
  {
    value: 1,
    text: '東區',
  },
  {
    value: 2,
    text: '西區',
  },
  {
    value: 3,
    text: '南區',
  },
  {
    value: 4,
    text: '北區',
  },
  {
    value: 5,
    text: '高屏區',
  },
];
