import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '@Basic/object/AuthType';

interface InitialState {
  //   token: string | undefined;
  //   userName: string | undefined;
  //   userData: UserData | undefined;
  //   language: string;
  //   lastActiveTime: number;
  dateTime: Date;
}

export const dateSlice = createSlice({
  name: 'dateSlice',
  initialState: {
    // token: undefined,
    // userName: undefined,
    // userData: undefined,
    // language: 'zh',
    // lastActiveTime: 0,
    dateTime: new Date(),
  } as InitialState,
  reducers: {
    // setToken: (state, action: PayloadAction<string | undefined>) => {
    //   state.token = action.payload;
    //   console.log('set token', action.payload);
    // },
    // setUserName: (state, action: PayloadAction<string | undefined>) => {
    //   state.userName = action.payload;
    //   console.log('set userName', action.payload);
    // },
    // setUserData: (state, action: PayloadAction<UserData | undefined>) => {
    //   state.userData = action.payload;
    // },
    // setLanguage: (state, action: PayloadAction<string>) => {
    //   state.language = action.payload;
    // },
    // setLastActiveTime: (state, action: PayloadAction<number>) => {
    //   state.lastActiveTime = action.payload;
    // },
    setDateTime: (state, action: PayloadAction<Date>) => {
      state.dateTime = action.payload;
    },
  },
});
