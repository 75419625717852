import YatungPaper from '@Src/_basic/components/YatungPaper';
import React, { CSSProperties } from 'react';
import FeedTroughMaintenanceManagementBody from './components/FeedTroughMaintenanceManagementBody';
import FeedTroughMaintenanceManagementActions from './components/FeedTroughMaintenanceManagementActions';
import { Grid } from '@mui/material';
import FeedTroughMaintenanceManagementUpdatePaper from '../../FeedTroughMaintenanceManagementUpdatePaper';
import FeedTroughMaintenanceManagementCreatePaper from '../../FeedTroughMaintenanceManagementCreatePaper';
import { WarehouseAndStateData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';

export default function FeedTroughMaintenanceCreateWindowPaper({
  index,
  style,
  data,
}: {
  data: Array<Array<WarehouseAndStateData>>;
  index: number;
  style: CSSProperties;
}) {
  return (
    <>
      <Grid container rowSpacing={1} key={index} style={style} justifyContent={'center'}>
        {data[index].map((value: WarehouseAndStateData, i: number) => {
          return (
            <Grid item key={i}>
              {value.id < 1 ? (
                <FeedTroughMaintenanceManagementCreatePaper
                  data={value}
                  key={value.id}
                  index={value.id}
                  selectedFactory={value.factoryId}
                />
              ) : (
                <>
                  {value.state === true ? (
                    <FeedTroughMaintenanceManagementUpdatePaper
                      data={value}
                      key={value.id}
                      selectedFactory={value.factoryId}
                    />
                  ) : (
                    <YatungPaper
                      key={value.id}
                      body={<FeedTroughMaintenanceManagementBody data={value} />}
                      actions={<FeedTroughMaintenanceManagementActions data={value} />}
                      sx={{ borderRadius: 5, width: 610, height: 550, boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)' }}
                    />
                  )}
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
