import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WarehouseAndStateData, DynmaicObject } from '@Src/_basic/object/FeedTroughMaintenanceManagement';

interface initialState {
  areaValue: number;
  factoryValue: number;
  feedTroughMaintenanceData: WarehouseAndStateData[] | any[];
  feedTroughMaintenanceCreateDatas: DynmaicObject[] | any;
  feedTroughMaintenanceMappingDatas: WarehouseAndStateData[] | any[];
  storageYardeData: WarehouseAndStateData[] | any[];
  storageYardCreateDatas: DynmaicObject[] | any;
  storageYardMappingDatas: WarehouseAndStateData[] | any[];
}
export const feedTroughMaintenanceSlice = createSlice({
  name: 'feedTroughMaintenanceSlice',
  initialState: {
    areaValue: 0,
    factoryValue: 0,
    feedTroughMaintenanceData: [],
    feedTroughMaintenanceCreateDatas: [],
    feedTroughMaintenanceMappingDatas: [],
    storageYardeData: [],
    storageYardCreateDatas: [],
    storageYardMappingDatas: [],
  } as initialState,
  reducers: {
    setAreaValue: (state, action: PayloadAction<number>) => {
      state.areaValue = action.payload;
    },
    setFactoryValue: (state, action: PayloadAction<number>) => {
      state.factoryValue = action.payload;
    },

    setFeedTroughMaintenanceData: (state, action: PayloadAction<WarehouseAndStateData[]>) => {
      state.feedTroughMaintenanceData = action.payload;
    },
    setFeedTroughMaintenanceCreateDatas: (state, action: PayloadAction<DynmaicObject[]>) => {
      state.feedTroughMaintenanceCreateDatas = action.payload;
    },
    setFeedTroughMaintenanceMappingDatas: (state, action: PayloadAction<WarehouseAndStateData[]>) => {
      state.feedTroughMaintenanceMappingDatas = action.payload;
    },

    setStorageYardeData: (state, action: PayloadAction<WarehouseAndStateData[]>) => {
      state.storageYardeData = action.payload;
    },
    setStorageYardCreateDatas: (state, action: PayloadAction<DynmaicObject[]>) => {
      state.storageYardCreateDatas = action.payload;
    },
    setStorageYardMappingDatas: (state, action: PayloadAction<WarehouseAndStateData[]>) => {
      state.storageYardMappingDatas = action.payload;
    },
  },
});
