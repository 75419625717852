import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import FactoryManagementCardScreen from './screens/FactoryManagementCardScreen';
import FactoryManagementCreateCardScreen from './screens/FactoryManagementCreateCardScreen';
import FactoryManagementUpdateCardScreen from './screens/FactoryManagementUpdateCardScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <FactoryManagementCardScreen />,
  },
  {
    path: '/FactoryManagementCreate/*',
    element: <FactoryManagementCreateCardScreen />,
  },
  {
    path: '/FactoryManagementUpdate/:id',
    element: <FactoryManagementUpdateCardScreen />,
  },
];

const FactoryManagementRouter = () => PermissionRoute(routesConfig);

export default memo(FactoryManagementRouter);
