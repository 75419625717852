/* eslint-disable no-unused-vars */
import { Response } from '@Src/_basic/object/ApiType';
import {
  AllProportionVersionResponse,
  GetAllProportionVersionRequest,
  GetDefaultProportionMaintenanceRequest,
  GetIsExistProportionResponse,
  GetProportionMaintenanceRequest,
  GetSafetyWaterRequest,
  GetSafetyWaterResponse,
  GetSearchProportionRequest,
  PostProportionMaintenanceRequest,
  PostProportionRequest,
  ProportionMaintenanceResponse,
  ProportionResponse,
  PutProportionMaintenanceRequest,
  SearchProportionNamesParams,
  SearchProportionResponse,
  putEditProportionTagsRequest,
  putProportionCurrVerRequest,
} from '@Src/_basic/object/ProportionType';
import { Api } from '../Api';
import { ProportionProtocol } from './ProportionProtocol';

export class ProportionApi {
  public static getProportionNamesBySearch(
    params: SearchProportionNamesParams,
    onSuccess?: (data: Array<string>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(ProportionProtocol.PROPORTION_NAMES, config, onSuccess, onFailed, onFinally);
  }

  // 取得所有配比
  public static getSearchProportion(
    params: GetSearchProportionRequest,
    onSuccess?: (data: SearchProportionResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ProportionProtocol.SEARCH_PROPORTION, config, onSuccess, onFailed, onFinally);
  }

  // 取得預設配比維護
  public static getDefaultProportionMaintenance(
    params: GetDefaultProportionMaintenanceRequest,
    onSuccess?: (_data: ProportionMaintenanceResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ProportionProtocol.DEFAULT_PROPORTION_MAINTENANCE, config, onSuccess, onFailed, onFinally);
  }

  // 取得配比維護
  public static getProportionMaintenance(
    params: GetProportionMaintenanceRequest,
    onSuccess?: (_data: ProportionMaintenanceResponse) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ProportionProtocol.PROPORTION_MAINTENANCE, config, onSuccess, onFailed, onFinally);
  }

  // 建立配比
  public static postProportion(
    require: PostProportionRequest,
    onSuccess?: (_data: ProportionResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ProportionProtocol.PROPORTION, require, undefined, onSuccess, onFailed, onFinally);
  }

  // 確認配比是否存在
  public static getIsExistProportion(
    require: PostProportionRequest,
    onSuccess?: (_data: GetIsExistProportionResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ProportionProtocol.IS_EXIST_PROPORTION, require, undefined, onSuccess, onFailed, onFinally);
  }

  // // 確認配比名稱是否存在
  // public static getIsExistProportionName(
  //   params: GetIsExistProportionNameRequest,
  //   onSuccess?: (_data: boolean) => void,
  //   onFailed?: (res: Response<null>) => void,
  //   onFinally?: () => void,
  // ) {
  //   const config = {
  //     params,
  //   };
  //   Api.get(ProportionProtocol.IS_EXIST_PROPORTION_NAME, config, onSuccess, onFailed, onFinally);
  // }

  // 修改配比維護
  public static putProportionMaintenance(
    require: PutProportionMaintenanceRequest,
    onSuccess?: (_data: ProportionMaintenanceResponse) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) {
    Api.put(ProportionProtocol.PROPORTION_MAINTENANCE, require, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增配比維護
  public static postProportionMaintenance(
    require: PostProportionMaintenanceRequest,
    onSuccess?: (_data: ProportionMaintenanceResponse) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) {
    Api.post(ProportionProtocol.PROPORTION_MAINTENANCE, require, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改版本
  public static putProportionCurrVer(
    require: putProportionCurrVerRequest,
    onSuccess?: (_data: ProportionMaintenanceResponse) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) {
    Api.put(ProportionProtocol.PROPORTION_CURR_VER, require, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改配比的標籤
  public static putEditProportionTags(
    require: putEditProportionTagsRequest,
    onSuccess?: (_data: ProportionMaintenanceResponse) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) {
    Api.put(ProportionProtocol.EDIT_PROPORTION_TAGS, require, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得所有配比版本
  public static getAllProportionVersion(
    params: GetAllProportionVersionRequest,
    onSuccess?: (data: AllProportionVersionResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ProportionProtocol.PROPORTION_VERSION, config, onSuccess, onFailed, onFinally);
  }

  // 取得安全水資訊
  public static getSafetyWater(
    params: GetSafetyWaterRequest,
    onSuccess?: (data: GetSafetyWaterResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ProportionProtocol.SAFETY_WATER, config, onSuccess, onFailed, onFinally);
  }
}
