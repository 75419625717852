import { Protocol } from '../Protocol';

export class WeightUnitManageProtocol {
  public static readonly UNIT_CUTOM_ALL: string = `${Protocol.YATUNG_API_ROOT}/unit/custom/all`; // 取得所有自訂重量單位
  public static readonly UNIT_CUTOM_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/unit/custom`; // 取得自訂重量單位
  public static readonly UNIT_INTL_CONVERSION_ALL: string = `${Protocol.YATUNG_API_ROOT}/unit/intl/conversion/all`; // 取得所有國際單位
  public static readonly UNIT_INTL_AVALIABLE: string = `${Protocol.YATUNG_API_ROOT}/unit/intl/avaliable`; // 取得可選擇的國際單位

  public static readonly CREATE_UNIT_CUSTOM: string = `${Protocol.YATUNG_API_ROOT}/unit/custom`; // 建立自訂重量單位
  public static readonly UPDATE_UNIT_CUSTOM: string = `${Protocol.YATUNG_API_ROOT}/unit/custom`; // 修改自訂重量單位
}
