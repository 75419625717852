import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Detele(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M9.8,5.1h4.6c0-0.6-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.7-1.6-0.7c-0.6,0-1.2,0.2-1.6,0.7C10.1,3.9,9.8,4.5,9.8,5.1zM7.5,5.1c0-1.2,0.5-2.4,1.3-3.3c0.9-0.9,2-1.3,3.3-1.3s2.4,0.5,3.3,1.3c0.9,0.9,1.3,2,1.3,3.3h5.8c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8s-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3h-1l-1,11.9c-0.1,1.1-0.6,2.2-1.5,3c-0.8,0.8-2,1.2-3.1,1.2H8.4c-1.2,0-2.3-0.4-3.1-1.2c-0.8-0.8-1.4-1.8-1.5-3l-1-11.9h-1C1.5,7.4,1.2,7.3,1,7.1C0.7,6.8,0.6,6.6,0.6,6.2S0.7,5.7,1,5.4c0.2-0.2,0.5-0.3,0.8-0.3H7.5z M15.6,12c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v4.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8V12zM9.8,10.9c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8v4.6c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8V12c0-0.3,0.1-0.6,0.3-0.8C9.2,11,9.5,10.9,9.8,10.9z M6.1,19.1c0,0.6,0.3,1.1,0.7,1.5c0.4,0.4,1,0.6,1.6,0.6h7.5c0.6,0,1.1-0.2,1.6-0.6c0.4-0.4,0.7-0.9,0.7-1.5l1-11.7H5.1L6.1,19.1L6.1,19.1z" />
    </SvgIcon>
  );
}
