import { CardMedia } from '@mui/material';
import React from 'react';

export default function CementitiousBi() {
  return (
    <CardMedia
      component="iframe"
      src="https://app.powerbi.com/reportEmbed?reportId=e453064d-0647-44a5-ad42-8c692e2df115&autoAuth=true&ctid=ab35a555-3574-4bb8-9c15-c26a9300d0ff"
      sx={{ width: '80vw', height: '80vh' }}
    ></CardMedia>
  );
}
