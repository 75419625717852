import {
  EquipmentData,
  CreateEquipmentRequest,
  DeleteEquipmentRequest,
  UpdateEquipmentRequest,
  SearchPaginationParams,
  UploadEquipmentReports,
  FileData,
  GetUploadEquipmentReports,
} from '@Src/_basic/object/EquipmentManagementType';
import { EquipmentProtocol } from './EquipmentProtocol';
import axios from 'axios';
import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../Api';

export class EquipmentApi {
  // 取得所有人員
  public static geAllEquipment(
    params: SearchPaginationParams,
    onSuccess?: (data: EquipmentData[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    if (params.factoryId === 0) {
      const paramsTemp = { ...params, factory_id: null };
      const config = {
        headers: {
          Cancelable: true,
        },
        params: paramsTemp,
      };
      Api.get(EquipmentProtocol.GET_ALL_EQUIPMENT, config, onSuccess, onFailed, onFinally);
    } else {
      const config = {
        headers: {
          Cancelable: true,
        },
        params,
      };
      Api.get(EquipmentProtocol.GET_ALL_EQUIPMENT, config, onSuccess, onFailed, onFinally);
    }
  }

  public static createEquipment(
    request: CreateEquipmentRequest,
    onSuccess?: (data: EquipmentData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(EquipmentProtocol.CREATE_EQUIPMENT, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static updateEquipment(
    request: UpdateEquipmentRequest,
    onSuccess?: (data: EquipmentData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(EquipmentProtocol.UPDATE_EQUIPMENT, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static deleteEquipment(
    id: number | undefined,
    onSuccess?: (data: DeleteEquipmentRequest) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(EquipmentProtocol.DELETE_EQUIPMENT, config, onSuccess, onFailed, onFinally);
  }

  public static getEquipmentById(id: number) {
    const params = {
      params: {
        id: id,
      },
    };
    return axios.get(EquipmentProtocol.GET_EQUIPMENT_BY_ID, params).then((res) => {
      return res.data;
    });
  }

  public static uploadEquipmentReports(
    params: UploadEquipmentReports,
    files: FileList,
    onSuccess?: (data: Array<FileData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params,
    };

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    Api.post(EquipmentProtocol.UPLOAD_EQUIPMENT, formData, config, onSuccess, onFailed, onFinally);
  }

  public static deleteUploadEquipmentReport(
    id: number | null,
    onSuccess?: (data: FileData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };

    Api.delete(EquipmentProtocol.DELETE_UPLOAD_EQUIPMENT, config, onSuccess, onFailed, onFinally);
  }

  public static downloadEquipmentReport(
    id: number | null,
    onSuccess?: (data: FileData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };

    Api.get(EquipmentProtocol.DOWNLOAD_FILE, config, onSuccess, onFailed, onFinally);
  }

  public static getUploadEquipmentReports(
    params: GetUploadEquipmentReports,
    onSuccess?: (data: Array<FileData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(EquipmentProtocol.GET_UPLOAD_EQUIPMENT, config, onSuccess, onFailed, onFinally);
  }
}
