import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import YatungButton from '@Src/_basic/components/YatungButton';

import SearchIcon from '@Src/_basic/icons/Search';
import DeleteIcon from '@Src/_basic/icons/Delete';
import StrokeIcon from '@Src/_basic/icons/Stroke';
import PersonTitleIcon from '@Src/_basic/icons/PersonTitle';
import AddCircleIcon from '@Src/_basic/icons/AddCircle';
import { UsersData } from '@Src/_basic/object/AccountRightType';

export default function SearchTable({ setSearchTableOpen, searchTableOpen, memberData, setMemberData }: any) {
  const { t: i18T } = useTranslation();
  const [searchInput, setSearchInput] = useState('');
  const [filterData, setFilterData] = useState<Array<UsersData>>([]);

  const columns: Array<Column> = [
    {
      text: '',
      dataField: 'accountName',
      width: '30%',
      titleFormatter: () => {
        return (
          <Stack direction="row" alignItems="center">
            <StrokeIcon />
            {i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.NAME')}
          </Stack>
        );
      },
    },
    {
      text: '',
      dataField: 'jobTitle',
      width: '30%',
      titleFormatter: () => {
        return (
          <Stack direction="row" alignItems="center">
            <PersonTitleIcon sx={{ mr: 1 }} />
            {i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.JOB_TITLE')}
          </Stack>
        );
      },
    },
    {
      text: i18T('GLOBAL.STATE'),
      dataField: 'state',
      width: '20%',
      formatter: ({ row }: any) => {
        return (
          <>
            {row.state ? (
              <Typography color="#00D03A">{i18T('GLOBAL.OPEN')}</Typography>
            ) : (
              <Typography color="red">{i18T('GLOBAL.STOP')}</Typography>
            )}
          </>
        );
      },
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      formatter: ({ row }: any) => {
        return (
          <YatungButton
            text={i18T('GLOBAL.DELETE')}
            startIcon={<DeleteIcon />}
            color="red"
            onClick={() => {
              setMemberData((prev: any) =>
                prev.map((item: any) => (item.id === row.id ? { ...item, added: false } : item)),
              );
            }}
          />
        );
      },
    },
  ];

  const searchColumns: Array<Column> = [
    {
      text: i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.NAME'),
      dataField: 'accountName',
      width: '20%',
    },
    {
      text: i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.ACCOUNT'),
      dataField: 'account',
      width: '30%',
    },
    {
      text: i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.JOB_TITLE'),
      dataField: 'jobTitle',
      width: '20%',
    },
    {
      text: i18T('GLOBAL.STATE'),
      dataField: 'state',
      width: '20%',
      formatter: ({ row }: any) => {
        return (
          <>
            {row.state ? (
              <Typography color="#00D03A">{i18T('GLOBAL.OPEN')}</Typography>
            ) : (
              <Typography color="red">{i18T('GLOBAL.STOP')}</Typography>
            )}
          </>
        );
      },
    },
    {
      text: '',
      dataField: 'id',
      formatter: ({ row }: any) => {
        return (
          <YatungButton
            text={i18T('GLOBAL.JOIN')}
            startIcon={<AddCircleIcon sx={{ width: 30, height: 30 }} />}
            color="blue"
            onClick={() => {
              setMemberData((prev: any) =>
                prev.map((item: any) => (item.id === row.id ? { ...item, added: true } : item)),
              );
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const memberFilter = memberData.filter((item: UsersData) => {
      if (item.added) return false;
      if (
        item.accountName.toUpperCase().includes(searchInput.toUpperCase()) ||
        item.account.toUpperCase().includes(searchInput.toUpperCase()) ||
        item.jobTitle.toUpperCase().includes(searchInput.toUpperCase())
      )
        return true;
    });
    setFilterData(memberFilter);
  }, [searchInput, memberData]);

  return (
    <Stack width="50%" sx={{ p: 5 }}>
      <Stack sx={{ bgcolor: '#BB5E00', width: '100%', height: '120px', borderRadius: '20px 20px 0 0' }}>
        <Stack sx={{ mt: 5, ml: 2, width: '85%' }} onClick={() => setSearchTableOpen(true)}>
          <YatungInput
            value={searchInput}
            onChange={(e: any) => setSearchInput(e.target.value)}
            position="start"
            icon={<SearchIcon sx={{ fontSize: 36 }} />}
            style={{ fontSize: 24 }}
          />
          {searchTableOpen && (
            <YatungTable
              sx={{
                zIndex: 100,
                bgcolor: 'white',
                borderRadius: '0 0 20px 20px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'gray',
                padding: '10px 20px',
                minHeight: 250,
                maxHeight: 250,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#BB5E00',
                  borderRadius: 8,
                },
              }}
              isTableMinWidth={false}
              columns={searchColumns}
              data={filterData}
              headColor={false}
            />
          )}
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%' }} onClick={() => setSearchTableOpen(false)}>
        <YatungTable
          sx={{
            borderRadius: '0 0 20px 20px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'gray',
            padding: '10px 20px',
            minHeight: 450,
            maxHeight: 450,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: 8,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#BB5E00',
              borderRadius: 8,
            },
          }}
          isTableMinWidth={false}
          columns={columns}
          data={memberData.filter((item: any) => item.added === true)}
          headColor={false}
        />
      </Stack>
    </Stack>
  );
}
