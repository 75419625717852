import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../../Api';
import { OrganizationProtocol } from './OrganizationProtocol';
import {
  CreateOrganizationsRequset,
  OrganiztionsData,
  PageableOrganizationsData,
  SearchPageableOrganizationsParams,
  UpdateOrganizationsRequset,
} from '@Src/_basic/object/AccountRightType';

export class OrganizationApi {
  // 取得全部組織清單
  public static getAllOrganization(
    onSuccess?: (data: Array<OrganiztionsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(OrganizationProtocol.GET_ALL_ORGANIZATION, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得分頁組織清單
  public static getPageableOrganizationsBySearch(
    params: SearchPageableOrganizationsParams,
    onSuccess?: (data: PageableOrganizationsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(OrganizationProtocol.GET_PAGEABLE_ORGANIZATION, config, onSuccess, onFailed, onFinally);
  }

  // 取得組織清單
  public static getOrganizationById(
    id: number,
    onSuccess?: (data: OrganiztionsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(OrganizationProtocol.GET_ORGANIZATIONS, config, onSuccess, onFailed, onFinally);
  }

  // 新增組織
  public static postCreateOrganization(
    request: CreateOrganizationsRequset,
    onSuccess?: (data: OrganiztionsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    console.log(request);
    Api.post(OrganizationProtocol.POST_CREATE_ORGANIZATION, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改組織
  public static putUpdateOrganization(
    request: UpdateOrganizationsRequset,
    onSuccess?: (data: OrganiztionsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(OrganizationProtocol.PUT_UPDATE_ORGANIZATION, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static deleteOrganization(
    id: number,
    onSuccess?: (data: OrganiztionsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(OrganizationProtocol.GET_ORGANIZATIONS, config, onSuccess, onFailed, onFinally);
  }
}
