import React from 'react';
import { Stack, Typography } from '@mui/material';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field } from 'formik';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import { Options } from '@Src/_basic/components/YatungSelect';
import YatungButton from '@Src/_basic/components/YatungButton';
import SampleLocationGray from '@Src/_basic/icons/SampleLocationGray';
import YatungCheckBox from '@Src/_basic/components/YatungCheckBox';
import { CreateSampleRequest } from '@Src/_basic/object/SampleLocationType';

interface Props {
  values: CreateSampleRequest | any;
  setValues: (name: string, number: any) => void;
  types: Options[];
  onCreateGoBackButtonClick: () => void;
}
export default function SampleCreateField(props: Props) {
  const { values, setValues, types, onCreateGoBackButtonClick } = props;

  const handleChangeType = (e: any) => {
    const { value } = e.target;
    const valueTurnToNumber = Number(value);
    if (!values.typeIds.includes(valueTurnToNumber)) {
      setValues('typeIds', [...values.typeIds, valueTurnToNumber]);
    } else {
      const index = values.typeIds.indexOf(valueTurnToNumber);
      if (index > -1) {
        values.typeIds.splice(index, 1);
      }
      setValues('typeIds', [...values.typeIds]);
    }
  };

  const { t: i18T } = useTranslation();

  return (
    <>
      <Stack flexDirection={'column'} sx={{ mt: 1 }}>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungFormLabel label={i18T('SAMPLEMANAGEMENT.SAMPLE_LOCATION_NAME')} />
        </Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <Field
            id="name"
            name="name"
            type="text"
            component={YatungFormInput}
            sx={{ width: '100%' }}
            position={'start'}
            icon={<SampleLocationGray />}
            required
          />
        </Stack>

        <Stack flexDirection={'row'} sx={{ mt: 2 }}>
          <YatungFormLabel label={i18T('SAMPLEMANAGEMENT.TYPES')} />
        </Stack>
        <Stack flexDirection={'row'} sx={{ ml: 1, mt: 3 }}>
          <Field
            id="typeIds"
            name="typeIds"
            type="checkbox"
            label=""
            onChange={handleChangeType}
            component={YatungCheckBox}
            initialCheckedValue={values.typeIds}
            options={types}
            sx={{minWidth:'125px', mr: 2 }}
            required
          />
        </Stack>
        <ErrorMessage
          name={'typeIds'}
          render={(msg) => (
            <Stack flexDirection={'row'} sx={{ ml: 1, mt: 1 }}>
              <Typography sx={{ fontSize: 14, color: '#d32f2f' }}>{msg}</Typography>
            </Stack>
          )}
        />

        <Stack
          spacing={3}
          sx={{ bgcolor: 'white', minWidth: 500, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}
          display="flex"
          flexDirection="column"
        >
          <Stack alignItems="center" justifyContent="center" flexDirection="row">
            <YatungButton
              text={i18T('SAMPLEMANAGEMENT.CANCEL')}
              onClick={onCreateGoBackButtonClick}
              sx={{
                color: '#ffffff',
                bgcolor: '#CB333B',
                fontSize: '24px',
                width: 120,
                mr: 1,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#CB333B',
                },
              }}
            />
            <YatungButton
              text={i18T('SAMPLEMANAGEMENT.COMPLETE')}
              type="submit"
              sx={{
                border: '1px solid #fff',
                color: '#ffffff',
                bgcolor: '#002F8C',
                fontSize: '24px',
                width: 120,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#002F8C',
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
