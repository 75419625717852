import { Card, CardContent, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

import { Options } from '@Src/_basic/components/YatungSelect';
import CheckButton from './CheckButton';

interface SortPanelProps {
  sortItems: Array<Options>;
  selectSort?: string;
  handleSortChange: (orderBy: string) => void;
  sx?: SxProps<Theme> | undefined;
}

const SortPanel = ({ sortItems, selectSort, handleSortChange, sx = {} }: SortPanelProps) => (
  <Card
    sx={{
      background: 'linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%)',
      boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2);',
    }}
  >
    <CardContent>
      <Typography
        sx={{
          fontSize: 20,
          color: '#BB5E00',
          fontWeight: 900,
        }}
      >
        排序方式：
      </Typography>
      {sortItems.map(({ text, value }, i) => (
        <CheckButton
          key={`selectOrderListValue-${i}`}
          checked={value === selectSort}
          sx={{ minWidth: '125px', px: '5px' }}
          onClick={() => handleSortChange(value)}
        >
          {text}
        </CheckButton>
      ))}
    </CardContent>
  </Card>
);

export default SortPanel;
