import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../../Api';
import { AssignGroupProtocol } from './AssignGroupProtocol';
import {
  AssignGroupBySearchParams,
  AssignGroupsData,
  CreateAssignGroupsRequest,
  PageableAssignGroupBySearchParams,
  PageableAssignGroupsData,
  UpdateAssignGroupsRequest,
} from '@Src/_basic/object/AccountRightType';

export class AssignGroupApi {
  // 取得指派群組清單
  public static getAssignGroupsBySearch(
    params: AssignGroupBySearchParams,
    onSuccess?: (data: Array<AssignGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(AssignGroupProtocol.GET_ASSIGN_GROUPS, config, onSuccess, onFailed, onFinally);
  }

  // 取得分頁指派群組清單
  public static getPageableAssignGroupsBySearch(
    params: PageableAssignGroupBySearchParams,
    onSuccess?: (data: PageableAssignGroupsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(AssignGroupProtocol.GET_PAGEABLE_ASSIGN_GROUPS, config, onSuccess, onFailed, onFinally);
  }

  // 以ID取得回報群組
  public static getAssignGroupById(
    id: number,
    onSuccess?: (data: Array<AssignGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id: id,
      },
    };
    Api.get(AssignGroupProtocol.ASSIGN_GROUP_URL, config, onSuccess, onFailed, onFinally);
  }

  // 新增回報群組
  public static postCreateAssignGroup(
    request: CreateAssignGroupsRequest,
    onSuccess?: (data: Array<AssignGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(AssignGroupProtocol.ASSIGN_GROUP_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改回報群組
  public static putUpdateAssignGroup(
    request: UpdateAssignGroupsRequest,
    onSuccess?: (data: Array<AssignGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(AssignGroupProtocol.ASSIGN_GROUP_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 刪除回報群組
  public static deleteAssignGroup(
    id: number,
    onSuccess?: (data: Response<null>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id: id,
      },
    };
    Api.delete(AssignGroupProtocol.ASSIGN_GROUP_URL, config, onSuccess, onFailed, onFinally);
  }
}
