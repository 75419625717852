import { PageableResponse } from '@Src/_basic/object/ApiType';
import { CarbonEmissionHeader, CarbonEmissionList } from '@Src/_basic/object/CarbonEmissionType';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface initialState {
  header: CarbonEmissionHeader | undefined;
  pageableCarbonEmission: PageableResponse<CarbonEmissionList> | undefined;
}

export const carbonEmissionSlice = createSlice({
  name: 'carbonEmissionSlice',
  initialState: {
    header: undefined,
    pageableCarbonEmission: undefined,
  } as initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<CarbonEmissionHeader>) => {
      state.header = action.payload;
    },
    clearHeader: (state) => {
      state.header = undefined;
    },
    setPageableCarbonEmission: (state, action: PayloadAction<PageableResponse<CarbonEmissionList>>) => {
      state.pageableCarbonEmission = action.payload;
    },
    clearPageableCarbonEmission: (state) => {
      state.pageableCarbonEmission = undefined;
    },
  },
});
