import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DeliveryInformationDesignStrength(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="39" viewBox="0 0 46 39" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5703 17.9255H5.77031V32.236H10.5703V17.9255ZM4.57031 16.7329V33.4286H11.7703V16.7329H4.57031Z"
          fill="#7C878E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1703 14.3478H15.3703V32.236H20.1703V14.3478ZM14.1703 13.1553V33.4286H21.3703V13.1553H14.1703Z"
          fill="#7C878E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.7703 10.7702H24.9703V32.236H29.7703V10.7702ZM23.7703 9.57764V33.4286H30.9703V9.57764H23.7703Z"
          fill="#7C878E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.3703 7.19255H34.5703V32.236H39.3703V7.19255ZM33.3703 6V33.4286H40.5703V6H33.3703Z"
          fill="#7C878E"
        />
      </svg>
    </SvgIcon>
  );
}
