import {
  ChildrenTypography,
  TestCardContainer,
  TestChemAdmxContainer,
  TestVerifyResultStyles,
  TitleTypography,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { calcPercent } from '@Src/_basic/helpers/CalcHelper';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { ChemType } from '@Src/_basic/object/SpecimenType';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  ChemAdmxPentaOfFluidify,
  ChemAdmxPentaOfGType,
} from '@Src/_basic/object/test/cementtitiousLiquid/ChemAdmxPentaType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  controlGType: ChemAdmxPentaOfGType | null;
  standardGType: ChemAdmxPentaOfGType | null;
  experimentalGType: Array<ChemAdmxPentaOfGType> | null;
  controlFluidify: ChemAdmxPentaOfGType | null;
  standardFluidify: ChemAdmxPentaOfFluidify | null;
  experimentalFluidify: Array<ChemAdmxPentaOfFluidify> | null;
  chemType: ChemType;
  testDetail: AllTest;
  activity?: boolean;
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

// 藥劑_減水率試驗
function ChemAdmxPentaTable({
  controlGType,
  standardGType,
  experimentalGType,
  controlFluidify,
  standardFluidify,
  experimentalFluidify,
  chemType,
  testDetail,
  activity,
  afterUpdateTest,
  canEdit,
}: Props) {
  const { t: i18T } = useTranslation();

  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    setEdit(false);
  }, [chemType]);

  const isPentaLegalPassed = useMemo(() => {
    const verifyResult: Array<boolean> = [];

    switch (chemType) {
      case ChemType.FLUIDIFIER:
        experimentalFluidify?.forEach((item) => {
          verifyResult.push(isLegalStandardPassed(item.verifyPassedResults));
        });
        break;
      default:
        experimentalGType?.forEach((item) => {
          verifyResult.push(isLegalStandardPassed(item.verifyPassedResults));
        });
        break;
    }

    return verifyResult.every((item: boolean) => item);
  }, [chemType, experimentalGType, experimentalFluidify]);

  const isPentaCustomPassed = useMemo(() => {
    const verifyResult: Array<boolean> = [];

    switch (chemType) {
      case ChemType.FLUIDIFIER:
        experimentalFluidify?.forEach((item) => {
          verifyResult.push(isCustomStandardPassed(item.verifyCustomResults));
        });
        break;
      default:
        experimentalGType?.forEach((item) => {
          verifyResult.push(isCustomStandardPassed(item.verifyCustomResults));
        });
        break;
    }

    return verifyResult.every((item: boolean) => item);
  }, [chemType, experimentalGType, experimentalFluidify]);

  const isPentaCustomRejected = useMemo(() => {
    const verifyResult: Array<boolean> = [];

    switch (chemType) {
      case ChemType.FLUIDIFIER:
        experimentalFluidify?.forEach((item) => {
          verifyResult.push(isRejected(item.verifyCustomResults));
        });
        break;
      default:
        experimentalGType?.forEach((item) => {
          verifyResult.push(isRejected(item.verifyCustomResults));
        });
        break;
    }

    return verifyResult.some((item: boolean) => item);
  }, [chemType, experimentalGType, experimentalFluidify]);

  const pentaVerifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isPentaLegalPassed && isPentaCustomPassed) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isPentaLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isPentaCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isPentaCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isPentaLegalPassed, isPentaCustomPassed, isPentaCustomRejected]);

  const formikBasicSetup = useMemo(() => {
    const gTypeData: Record<string, number> = {};
    const fuidifyData: Record<string, number> = {};

    Object.entries({
      controlGType,
      standardGType,
      experimentalGType,
    }).forEach(([key, value]) => {
      if (!value) return;

      let _transferName: string | undefined;

      if (key.includes('GType')) _transferName = key.replace('GType', '');

      if (!Array.isArray(value)) {
        gTypeData[`${_transferName}QuantityMixingWater`] = value.quantityMixingWater as number;
        gTypeData[`${_transferName}InitalSlump`] = value.initalSlump as number;
        gTypeData[`${_transferName}SlumpAfter40Mins`] = value.slumpAfter40Mins as number;
        gTypeData[`${_transferName}ChemUsage`] = value?.chemAdmxUsage as number;
        gTypeData[`${_transferName}AirContent`] = value.airContent as number;
      } else {
        value.forEach((item) => {
          gTypeData[`${key}_${item.specimenId}_${item.id}_quantityMixingWater`] = item.quantityMixingWater as number;
          gTypeData[`${key}_${item.specimenId}_${item.id}_initalSlump`] = item.initalSlump as number;
          gTypeData[`${key}_${item.specimenId}_${item.id}_slumpAfter40Mins`] = item.slumpAfter40Mins as number;
          gTypeData[`${key}_${item.specimenId}_${item.id}_chemUsage`] = item?.chemAdmxUsage as number;
          gTypeData[`${key}_${item.specimenId}_${item.id}_airContent`] = item.airContent as number;
        });
      }
    });

    Object.entries({
      controlFluidify,
      standardFluidify,
      experimentalFluidify,
    }).forEach(([key, value]) => {
      if (!value) return;

      let _transferName: string | undefined;

      if (key.includes('Fluidify')) _transferName = key.replace('Fluidify', '');

      if (!Array.isArray(value)) {
        fuidifyData[`${_transferName}InitalSlump`] = (value as ChemAdmxPentaOfGType).initalSlump as number;
        fuidifyData[`${_transferName}SlumpAfter40Mins`] = (value as ChemAdmxPentaOfGType).slumpAfter40Mins as number;
        fuidifyData[`${_transferName}QuantityMixingWater`] = (value as ChemAdmxPentaOfFluidify)
          .quantityMixingWater as number;
        fuidifyData[`${_transferName}InitalSlumpFlowD1`] = (value as ChemAdmxPentaOfFluidify)
          .initalSlumpFlowD1 as number;
        fuidifyData[`${_transferName}InitalSlumpFlowD2`] = (value as ChemAdmxPentaOfFluidify)
          .initalSlumpFlowD2 as number;
        fuidifyData[`${_transferName}SlumpFlowAfter40MinsD1`] = (value as ChemAdmxPentaOfFluidify)
          .slumpFlowAfter40MinsD1 as number;
        fuidifyData[`${_transferName}SlumpFlowAfter40MinsD2`] = (value as ChemAdmxPentaOfFluidify)
          .slumpFlowAfter40MinsD2 as number;
        fuidifyData[`${_transferName}ChemUsage`] = (value as ChemAdmxPentaOfFluidify)?.chemAdmxUsage as number;
        fuidifyData[`${_transferName}AirContent`] = value.airContent as number;
      } else {
        value.forEach((item) => {
          fuidifyData[`${key}_${item.specimenId}_${item.id}_quantityMixingWater`] = item.quantityMixingWater as number;
          fuidifyData[`${key}_${item.specimenId}_${item.id}_initalSlumpFlowD1`] = item.initalSlumpFlowD1 as number;
          fuidifyData[`${key}_${item.specimenId}_${item.id}_initalSlumpFlowD2`] = item.initalSlumpFlowD2 as number;
          fuidifyData[`${key}_${item.specimenId}_${item.id}_slumpFlowAfter40MinsD1`] =
            item.slumpFlowAfter40MinsD1 as number;
          fuidifyData[`${key}_${item.specimenId}_${item.id}_slumpFlowAfter40MinsD2`] =
            item.slumpFlowAfter40MinsD2 as number;
          fuidifyData[`${key}_${item.specimenId}_${item.id}_chemUsage`] = item?.chemAdmxUsage as number;
          fuidifyData[`${key}_${item.specimenId}_${item.id}_airContent`] = item.airContent as number;
        });
      }
    });

    return { transformedData: { gType: gTypeData, fluidify: fuidifyData } };
  }, [controlGType, standardGType, experimentalGType, controlFluidify, standardFluidify, experimentalFluidify]);

  const formik = useFormik({
    initialValues: formikBasicSetup.transformedData,
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      const basicFormData: Record<string, number> = {};
      const experimentalFormData: Array<Record<string, number | string>> = [];

      Object.entries(chemType === 'G_TYPE' ? values.gType : values.fluidify).forEach(([key, value], i) => {
        if (!key.includes('_')) {
          basicFormData[key] = value;
        } else {
          const [_, specimenId, id, item] = key.split('_');
          const index = experimentalFormData.findIndex((item) => item.specimenId === specimenId);
          if (index === -1) {
            experimentalFormData.push({
              specimenId,
              [item]: value,
              experimentalId: Number(id),
            });
          } else {
            experimentalFormData[index][item] = value;
          }
        }
      });

      experimentalFormData.forEach((item, index) => {
        setTimeout(() => {
          if (!testDetail.exptUnitId || !testDetail.id) return;
          TestApi.putChemAdmxPentaTest(
            {
              ...basicFormData,
              ...item,
              id: testDetail.id,
              exptUnitId: testDetail.exptUnitId,
              chemType,
              operator: testDetail.operator,
              completed: true,
              deadline: Date.now(),
            },
            (_data) => {
              afterUpdateTest(_data);
              setEdit(false);
            },
            (err) => console.log(err),
          );
        }, 1500 * index);
      });
    },
  });

  const showFirstTableByGType = () => {
    return (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" padding={3}>
          <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.GROUP_NAME`)}</TitleTypography>
          <TitleTypography>{`${i18T(`TEST.CHEM_ADMX_PENTA.QUANTITY_MIXING_WATER`)}\n\r(kg/m³)`}</TitleTypography>
          <TitleTypography>{`${i18T(`TEST.CHEM_ADMX_PENTA.CHEM_ADMX_USAGE`)}(%)`}</TitleTypography>
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography>{controlGType?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={2.5} gap={4}>
              <LabelInput
                valueWidth="250px"
                value={formik.values.gType['controlQuantityMixingWater']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('gType.controlQuantityMixingWater', checkIsNaN(Number(e.target.value)))
                }
              />
              <LabelInput
                valueWidth="250px"
                value={formik.values.gType['controlChemUsage']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('gType.controlChemUsage', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography>{controlGType?.quantityMixingWater ?? '0.0'}</ChildrenTypography>
              <ChildrenTypography>{controlGType?.chemAdmxUsage ?? '0.0'}</ChildrenTypography>
            </>
          )}
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography color={TestVerifyResultStyles.normal}>{standardGType?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={2.5} gap={4}>
              <LabelInput
                valueWidth="250px"
                value={formik.values.gType['standardQuantityMixingWater']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('gType.standardQuantityMixingWater', checkIsNaN(Number(e.target.value)))
                }
              />
              <LabelInput
                valueWidth="250px"
                value={formik.values.gType['standardChemUsage']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('gType.standardChemUsage', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {standardGType?.quantityMixingWater ?? '0.0'}
              </ChildrenTypography>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {standardGType?.chemAdmxUsage ?? '0.0'}
              </ChildrenTypography>
            </>
          )}
        </Stack>

        {experimentalGType?.map((testItem, index) => (
          <Stack
            key={index}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding={3}
            sx={{ borderBottom: '1px solid gray' }}
          >
            <ChildrenTypography
              color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
            >
              {testItem.groupName}
            </ChildrenTypography>
            {edit ? (
              <Stack flexDirection="row" flex={2.5} gap={4}>
                <LabelInput
                  valueWidth="250px"
                  value={
                    formik.values.gType[`experimentalGType_${testItem.specimenId}_${testItem.id}_quantityMixingWater`]
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `gType.experimentalGType_${testItem.specimenId}_${testItem.id}_quantityMixingWater`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={formik.values.gType[`experimentalGType_${testItem.specimenId}_${testItem.id}_chemUsage`]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `gType.experimentalGType_${testItem.specimenId}_${testItem.id}_chemUsage`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
              </Stack>
            ) : (
              <>
                <ChildrenTypography
                  color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                >
                  {testItem.quantityMixingWater ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography
                  color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                >
                  {testItem.chemAdmxUsage ?? '0.0'}
                </ChildrenTypography>
              </>
            )}
          </Stack>
        ))}
      </>
    );
  };

  const showSecondTableByGType = () => {
    return (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" padding={3}>
          <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.GROUP_NAME`)}</TitleTypography>
          <TitleTypography>{`${i18T(`TEST.CHEM_ADMX_PENTA.INITIAL_SLUMP`)}(cm)`}</TitleTypography>
          <TitleTypography>{`${i18T(`TEST.CHEM_ADMX_PENTA.SLUMP_AFTER_40MINS`)}(cm)`}</TitleTypography>
          {!edit && <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.SLUMP_LOSE`, { unit: 'cm' })}</TitleTypography>}
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography>{controlGType?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={2.5} gap={4}>
              <LabelInput
                valueWidth="250px"
                value={formik.values.gType['controlInitalSlump']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('gType.controlInitalSlump', checkIsNaN(Number(e.target.value)))
                }
              />
              <LabelInput
                valueWidth="250px"
                value={formik.values.gType['controlSlumpAfter40Mins']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('gType.controlSlumpAfter40Mins', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography>{controlGType?.initalSlump ?? '0.0'}</ChildrenTypography>
              <ChildrenTypography>{controlGType?.slumpAfter40Mins ?? '0.0'}</ChildrenTypography>
              <ChildrenTypography>
                {((controlGType?.initalSlump ?? 0) - (controlGType?.slumpAfter40Mins ?? 0))?.toFixed(1)}
              </ChildrenTypography>
            </>
          )}
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography color={TestVerifyResultStyles.normal}>{standardGType?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={2.5} gap={4}>
              <LabelInput
                valueWidth="250px"
                value={formik.values.gType['standardInitalSlump']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('gType.standardInitalSlump', checkIsNaN(Number(e.target.value)))
                }
              />
              <LabelInput
                valueWidth="250px"
                value={formik.values.gType['standardSlumpAfter40Mins']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('gType.standardSlumpAfter40Mins', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {standardGType?.initalSlump ?? '0.0'}
              </ChildrenTypography>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {standardGType?.slumpAfter40Mins ?? '0.0'}
              </ChildrenTypography>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {((standardGType?.initalSlump ?? 0) - (standardGType?.slumpAfter40Mins ?? 0))?.toFixed(1)}
              </ChildrenTypography>
            </>
          )}
        </Stack>

        {experimentalGType?.map((testItem, index) => (
          <Stack
            key={index}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding={3}
            sx={{ borderBottom: '1px solid gray' }}
          >
            <ChildrenTypography
              color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
            >
              {testItem.groupName}
            </ChildrenTypography>
            {edit ? (
              <Stack flexDirection="row" flex={2.5} gap={4}>
                <LabelInput
                  valueWidth="250px"
                  value={formik.values.gType[`experimentalGType_${testItem.specimenId}_${testItem.id}_initalSlump`]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `gType.experimentalGType_${testItem.specimenId}_${testItem.id}_initalSlump`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={
                    formik.values.gType[`experimentalGType_${testItem.specimenId}_${testItem.id}_slumpAfter40Mins`]
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `gType.experimentalGType_${testItem.specimenId}_${testItem.id}_slumpAfter40Mins`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
              </Stack>
            ) : (
              <>
                <ChildrenTypography
                  color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                >
                  {testItem.initalSlump ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography
                  color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                >
                  {testItem.slumpAfter40Mins ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography
                  color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                >
                  {((testItem?.initalSlump ?? 0) - (testItem?.slumpAfter40Mins ?? 0))?.toFixed(1)}
                </ChildrenTypography>
              </>
            )}
          </Stack>
        ))}
      </>
    );
  };

  const showThirdTableByGType = () => {
    return (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" padding={3}>
          <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.GROUP_NAME`)}</TitleTypography>
          <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.WATER_REDUCE_RATIO`, { unit: '%' })}</TitleTypography>
          {!edit && <TitleTypography>{`${i18T(`TEST.CHEM_ADMX_PENTA.AIR_CONTENT`)}(%)`}</TitleTypography>}
          {!edit && <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.SLUMP_LOSE_RATIO`, { unit: '%' })}</TitleTypography>}
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography>{controlGType?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={1.1}>
              <LabelInput
                valueWidth="150px"
                value={formik.values.gType['controlAirContent']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('gType.controlAirContent', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography>{'無'}</ChildrenTypography>
              <ChildrenTypography>{controlGType?.airContent ?? '0.0'}</ChildrenTypography>
              <ChildrenTypography>
                {calcPercent(
                  (controlGType?.initalSlump ?? 0) - (controlGType?.slumpAfter40Mins ?? 0),
                  controlGType?.initalSlump ?? 0,
                )?.toFixed(1)}
              </ChildrenTypography>
            </>
          )}
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography color={TestVerifyResultStyles.normal}>{standardGType?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={1.1}>
              <LabelInput
                valueWidth="150px"
                value={formik.values.gType['standardAirContent']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('gType.standardAirContent', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {calcPercent(
                  (controlGType?.quantityMixingWater ?? 0) - (standardGType?.quantityMixingWater ?? 0),
                  controlGType?.quantityMixingWater ?? 0,
                )?.toFixed(1)}
              </ChildrenTypography>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {standardGType?.airContent ?? '0.0'}
              </ChildrenTypography>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {calcPercent(
                  (standardGType?.initalSlump ?? 0) - (standardGType?.slumpAfter40Mins ?? 0),
                  standardGType?.initalSlump ?? 0,
                )?.toFixed(1)}
              </ChildrenTypography>
            </>
          )}
        </Stack>

        {experimentalGType?.map((testItem, index) => (
          <Stack
            key={index}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding={3}
            sx={{ borderBottom: '1px solid gray' }}
          >
            <ChildrenTypography
              color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
            >
              {testItem.groupName}
            </ChildrenTypography>
            {edit ? (
              <Stack flexDirection="row" flex={1.1}>
                <LabelInput
                  valueWidth="150px"
                  value={formik.values.gType[`experimentalGType_${testItem.specimenId}_${testItem.id}_airContent`]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `gType.experimentalGType_${testItem.specimenId}_${testItem.id}_airContent`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
              </Stack>
            ) : (
              <>
                <ChildrenTypography
                  color={{
                    ...(testItem.verifyPassedResults?.waterReducingRate &&
                    testItem.verifyCustomResults?.waterReducingRate?.passed
                      ? TestVerifyResultStyles.normal
                      : TestVerifyResultStyles.abnormal),
                  }}
                >
                  {testItem.waterReducingRate?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography
                  color={{
                    ...(testItem.verifyPassedResults?.airContent && testItem.verifyCustomResults?.airContent?.passed
                      ? TestVerifyResultStyles.normal
                      : TestVerifyResultStyles.abnormal),
                  }}
                >
                  {testItem?.airContent ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography
                  color={{
                    ...(testItem.verifyPassedResults?.slumpLossRate &&
                    testItem.verifyCustomResults?.slumpLossRate?.passed
                      ? TestVerifyResultStyles.normal
                      : TestVerifyResultStyles.abnormal),
                  }}
                >
                  {testItem.slumpLossRate?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
              </>
            )}
          </Stack>
        ))}
      </>
    );
  };

  const showFirstTableByFluidify = () => {
    return (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" padding={3}>
          <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.GROUP_NAME`)}</TitleTypography>
          <TitleTypography>{`${i18T(`TEST.CHEM_ADMX_PENTA.QUANTITY_MIXING_WATER`)}\n\r(kg/m³)`}</TitleTypography>
          <TitleTypography>{`${i18T(`TEST.CHEM_ADMX_PENTA.CHEM_ADMX_USAGE`)}(%)`}</TitleTypography>
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography>{controlFluidify?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={2.5} gap={4}>
              <LabelInput
                valueWidth="250px"
                value={formik.values.fluidify['controlQuantityMixingWater']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.controlQuantityMixingWater', checkIsNaN(Number(e.target.value)))
                }
              />
              <LabelInput
                valueWidth="250px"
                value={formik.values.fluidify['controlChemUsage']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.controlChemUsage', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography>{controlFluidify?.quantityMixingWater ?? '0.0'}</ChildrenTypography>
              <ChildrenTypography>{controlFluidify?.chemAdmxUsage ?? '0.0'}</ChildrenTypography>
            </>
          )}
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography color={TestVerifyResultStyles.normal}>{standardFluidify?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={2.5} gap={4}>
              <LabelInput
                valueWidth="250px"
                value={formik.values.fluidify['standardQuantityMixingWater']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.standardQuantityMixingWater', checkIsNaN(Number(e.target.value)))
                }
              />
              <LabelInput
                valueWidth="250px"
                value={formik.values.fluidify['standardChemUsage']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.standardChemUsage', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {standardFluidify?.quantityMixingWater ?? '0.0'}
              </ChildrenTypography>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {standardFluidify?.chemAdmxUsage ?? '0.0'}
              </ChildrenTypography>
            </>
          )}
        </Stack>

        {experimentalFluidify?.map((testItem, index) => (
          <Stack
            key={index}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding={3}
            sx={{ borderBottom: '1px solid gray' }}
          >
            <ChildrenTypography
              color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
            >
              {testItem.groupName}
            </ChildrenTypography>
            {edit ? (
              <Stack flexDirection="row" flex={2.5} gap={4}>
                <LabelInput
                  valueWidth="250px"
                  value={
                    formik.values.fluidify[
                      `experimentalFluidify_${testItem.specimenId}_${testItem.id}_quantityMixingWater`
                    ]
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `fluidify.experimentalFluidify_${testItem.specimenId}_${testItem.id}_quantityMixingWater`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={formik.values.fluidify[`experimentalFluidify_${testItem.specimenId}_${testItem.id}_chemUsage`]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `fluidify.experimentalFluidify_${testItem.specimenId}_${testItem.id}_chemUsage`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
              </Stack>
            ) : (
              <>
                <ChildrenTypography
                  color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                >
                  {testItem.quantityMixingWater ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography
                  color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                >
                  {testItem.chemAdmxUsage ?? '0.0'}
                </ChildrenTypography>
              </>
            )}
          </Stack>
        ))}
      </>
    );
  };

  const showSecondTableByFluidify = () => {
    return (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" padding={3}>
          <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.GROUP_NAME`)}</TitleTypography>
          <TitleTypography>{`${i18T(`TEST.CHEM_ADMX_PENTA.INITIAL_SLUMP`)}(cm)`}</TitleTypography>
          <TitleTypography>{`${i18T(`TEST.CHEM_ADMX_PENTA.SLUMP_AFTER_40MINS`)}(cm)`}</TitleTypography>
          {!edit && <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.SLUMP_LOSE`, { unit: 'cm' })}</TitleTypography>}
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography>{controlFluidify?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={2.5} gap={4}>
              <LabelInput
                valueWidth="250px"
                value={formik.values.fluidify['controlInitalSlump']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue(`fluidify.controlInitalSlump`, checkIsNaN(Number(e.target.value)))
                }
              />
              <LabelInput
                valueWidth="250px"
                value={formik.values.fluidify['controlSlumpAfter40Mins']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.controlSlumpAfter40Mins', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography>{controlFluidify?.initalSlump ?? '0.0'}</ChildrenTypography>
              <ChildrenTypography>{controlFluidify?.slumpAfter40Mins ?? '0.0'}</ChildrenTypography>
              <ChildrenTypography>
                {((controlFluidify?.initalSlump ?? 0) - (controlFluidify?.slumpAfter40Mins ?? 0))?.toFixed(1)}
              </ChildrenTypography>
            </>
          )}
        </Stack>

        {edit && (
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center" padding={3} gap={2}>
            <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.GROUP_NAME`)}</TitleTypography>
            <TitleTypography> {i18T(`TEST.CHEM_ADMX_PENTA.INITIAL_SLUMP_D`, { number: 1 })}</TitleTypography>
            <TitleTypography> {i18T(`TEST.CHEM_ADMX_PENTA.INITIAL_SLUMP_D`, { number: 2 })}</TitleTypography>
            <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.SLUMP_AFTER_40MINS_D`, { number: 1 })}</TitleTypography>
            <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.SLUMP_AFTER_40MINS_D`, { number: 2 })}</TitleTypography>
          </Stack>
        )}

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography color={TestVerifyResultStyles.normal}>{standardFluidify?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={4} gap={2}>
              <LabelInput
                valueWidth="130px"
                value={formik.values.fluidify['standardInitalSlumpFlowD1']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.standardInitalSlumpFlowD1', checkIsNaN(Number(e.target.value)))
                }
              />
              <LabelInput
                valueWidth="130px"
                value={formik.values.fluidify['standardInitalSlumpFlowD2']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.standardInitalSlumpFlowD2', checkIsNaN(Number(e.target.value)))
                }
              />
              <LabelInput
                valueWidth="130px"
                value={formik.values.fluidify['standardSlumpFlowAfter40MinsD1']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.standardSlumpFlowAfter40MinsD1', checkIsNaN(Number(e.target.value)))
                }
              />
              <LabelInput
                valueWidth="130px"
                value={formik.values.fluidify['standardSlumpFlowAfter40MinsD2']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.standardSlumpFlowAfter40MinsD2', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {(
                  ((standardFluidify?.initalSlumpFlowD1 ?? 0) + (standardFluidify?.initalSlumpFlowD2 ?? 0)) /
                  2
                )?.toFixed(1)}
              </ChildrenTypography>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {(
                  ((standardFluidify?.slumpFlowAfter40MinsD1 ?? 0) + (standardFluidify?.slumpFlowAfter40MinsD2 ?? 0)) /
                  2
                )?.toFixed(1)}
              </ChildrenTypography>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {(
                  ((standardFluidify?.initalSlumpFlowD1 ?? 0) + (standardFluidify?.initalSlumpFlowD2 ?? 0)) / 2 -
                  ((standardFluidify?.slumpFlowAfter40MinsD1 ?? 0) + (standardFluidify?.slumpFlowAfter40MinsD2 ?? 0)) /
                    2
                )?.toFixed(1)}
              </ChildrenTypography>
            </>
          )}
        </Stack>

        {experimentalFluidify?.map((testItem, index) => (
          <Stack
            key={index}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding={3}
            sx={{ borderBottom: '1px solid gray' }}
          >
            <ChildrenTypography
              color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
            >
              {testItem.groupName}
            </ChildrenTypography>
            {edit ? (
              <Stack flexDirection="row" flex={4} gap={2}>
                <LabelInput
                  valueWidth="130px"
                  value={
                    formik.values.fluidify[
                      `experimentalFluidify_${testItem.specimenId}_${testItem.id}_initalSlumpFlowD1`
                    ]
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `fluidify.experimentalFluidify_${testItem.specimenId}_${testItem.id}_initalSlumpFlowD1`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
                <LabelInput
                  valueWidth="130px"
                  value={
                    formik.values.fluidify[
                      `experimentalFluidify_${testItem.specimenId}_${testItem.id}_initalSlumpFlowD2`
                    ]
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `fluidify.experimentalFluidify_${testItem.specimenId}_${testItem.id}_initalSlumpFlowD2`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
                <LabelInput
                  valueWidth="130px"
                  value={
                    formik.values.fluidify[
                      `experimentalFluidify_${testItem.specimenId}_${testItem.id}_slumpFlowAfter40MinsD1`
                    ]
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `fluidify.experimentalFluidify_${testItem.specimenId}_${testItem.id}_slumpFlowAfter40MinsD1`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
                <LabelInput
                  valueWidth="130px"
                  value={
                    formik.values.fluidify[
                      `experimentalFluidify_${testItem.specimenId}_${testItem.id}_slumpFlowAfter40MinsD2`
                    ]
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `fluidify.experimentalFluidify_${testItem.specimenId}_${testItem.id}_slumpFlowAfter40MinsD2`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
              </Stack>
            ) : (
              <>
                <ChildrenTypography
                  color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                >
                  {(((testItem?.initalSlumpFlowD1 ?? 0) + (testItem?.initalSlumpFlowD2 ?? 0)) / 2)?.toFixed(1)}
                </ChildrenTypography>
                <ChildrenTypography
                  color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                >
                  {(((testItem?.slumpFlowAfter40MinsD1 ?? 0) + (testItem?.slumpFlowAfter40MinsD2 ?? 0)) / 2)?.toFixed(
                    1,
                  )}
                </ChildrenTypography>
                <ChildrenTypography
                  color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                >
                  {(
                    ((testItem?.initalSlumpFlowD1 ?? 0) + (testItem?.initalSlumpFlowD2 ?? 0)) / 2 -
                    ((testItem?.slumpFlowAfter40MinsD1 ?? 0) + (testItem?.slumpFlowAfter40MinsD2 ?? 0)) / 2
                  )?.toFixed(1)}
                </ChildrenTypography>
              </>
            )}
          </Stack>
        ))}
      </>
    );
  };

  const showThirdTableByFluidify = () => {
    return (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" padding={3}>
          <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.GROUP_NAME`)}</TitleTypography>
          {!edit && <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.WATER_REDUCE_RATIO`, { unit: '%' })}</TitleTypography>}
          <TitleTypography>{`${i18T(`TEST.CHEM_ADMX_PENTA.AIR_CONTENT`)}(%)`}</TitleTypography>
          {!edit && <TitleTypography>{i18T(`TEST.CHEM_ADMX_PENTA.SLUMP_LOSE_RATIO`, { unit: '%' })}</TitleTypography>}
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography>{controlFluidify?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={1.1}>
              <LabelInput
                valueWidth="150px"
                value={formik.values.fluidify['controlAirContent']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.controlAirContent', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography>{'無'}</ChildrenTypography>
              <ChildrenTypography>{controlFluidify?.airContent ?? '0.0'}</ChildrenTypography>
              <ChildrenTypography>
                {calcPercent(
                  (controlFluidify?.initalSlump ?? 0) - (controlFluidify?.slumpAfter40Mins ?? 0),
                  controlFluidify?.initalSlump ?? 0,
                )?.toFixed(1)}
              </ChildrenTypography>
            </>
          )}
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding={3}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography color={TestVerifyResultStyles.normal}>{standardFluidify?.groupName}</ChildrenTypography>
          {edit ? (
            <Stack flexDirection="row" flex={1.1}>
              <LabelInput
                valueWidth="150px"
                value={formik.values.fluidify['standardAirContent']}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('fluidify.standardAirContent', checkIsNaN(Number(e.target.value)))
                }
              />
            </Stack>
          ) : (
            <>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {calcPercent(
                  (controlFluidify?.quantityMixingWater ?? 0) - (standardFluidify?.quantityMixingWater ?? 0),
                  controlFluidify?.quantityMixingWater ?? 0,
                )?.toFixed(1)}
              </ChildrenTypography>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {standardFluidify?.airContent ?? '0.0'}
              </ChildrenTypography>
              <ChildrenTypography color={TestVerifyResultStyles.normal}>
                {calcPercent(
                  ((standardFluidify?.initalSlumpFlowD1 ?? 0) + (standardFluidify?.initalSlumpFlowD2 ?? 0)) / 2 -
                    ((standardFluidify?.slumpFlowAfter40MinsD1 ?? 0) +
                      (standardFluidify?.slumpFlowAfter40MinsD2 ?? 0)) /
                      2,
                  ((standardFluidify?.initalSlumpFlowD1 ?? 0) + (standardFluidify?.initalSlumpFlowD2 ?? 0)) / 2,
                ).toFixed(1)}
              </ChildrenTypography>
            </>
          )}
        </Stack>

        {experimentalFluidify?.map((testItem, index) => (
          <Stack
            key={index}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            padding={3}
            sx={{ borderBottom: '1px solid gray' }}
          >
            <ChildrenTypography
              style={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
            >
              {testItem.groupName}
            </ChildrenTypography>
            {edit ? (
              <Stack flexDirection="row" flex={1.1}>
                <LabelInput
                  valueWidth="150px"
                  value={
                    formik.values.fluidify[`experimentalFluidify_${testItem.specimenId}_${testItem.id}_airContent`]
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(
                      `fluidify.experimentalFluidify_${testItem.specimenId}_${testItem.id}_airContent`,
                      checkIsNaN(Number(e.target.value)),
                    )
                  }
                />
              </Stack>
            ) : (
              <>
                <ChildrenTypography
                  style={{
                    ...(testItem?.verifyPassedResults?.waterReducingRate &&
                    testItem?.verifyCustomResults?.waterReducingRate?.passed
                      ? TestVerifyResultStyles.normal
                      : TestVerifyResultStyles.abnormal),
                  }}
                >
                  {testItem?.waterReducingRate?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography
                  style={{
                    ...(testItem?.verifyPassedResults?.airContent && testItem.verifyCustomResults?.airContent?.passed
                      ? TestVerifyResultStyles.normal
                      : TestVerifyResultStyles.abnormal),
                  }}
                >
                  {testItem?.airContent ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography
                  style={{
                    ...(testItem?.verifyPassedResults?.slumpLossRate &&
                    testItem?.verifyCustomResults?.slumpLossRate?.passed
                      ? TestVerifyResultStyles.normal
                      : TestVerifyResultStyles.abnormal),
                  }}
                >
                  {testItem?.slumpLossRate?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
              </>
            )}
          </Stack>
        ))}
      </>
    );
  };

  const switchChemType = () => {
    const _fluidifiers = [showFirstTableByFluidify(), showSecondTableByFluidify(), showThirdTableByFluidify()];
    const _gTypes = [showFirstTableByGType(), showSecondTableByGType(), showThirdTableByGType()];

    switch (chemType) {
      case ChemType.G_TYPE:
        return (
          <Stack gap={2}>
            {activity ? (
              _gTypes.map((item, index) => <TestCardContainer key={index}>{item}</TestCardContainer>)
            ) : (
              <>
                {controlGType ? (
                  <Stack
                    flexDirection="row"
                    justifyContent="flex-end"
                    gap={2}
                    position="absolute"
                    right={20}
                    sx={{ zIndex: 5 }}
                  >
                    <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
                  </Stack>
                ) : null}
                {_gTypes.map((item, index) => (
                  <TestChemAdmxContainer key={index}>{item}</TestChemAdmxContainer>
                ))}
              </>
            )}
          </Stack>
        );
      case ChemType.FLUIDIFIER:
        return (
          <Stack gap={2}>
            {activity ? (
              _fluidifiers.map((item, index) => <TestCardContainer key={index}>{item}</TestCardContainer>)
            ) : (
              <>
                {controlFluidify ? (
                  <Stack
                    flexDirection="row"
                    justifyContent="flex-end"
                    gap={2}
                    position="absolute"
                    right={20}
                    sx={{ zIndex: 5 }}
                  >
                    <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
                  </Stack>
                ) : null}
                {_fluidifiers.map((item, index) => (
                  <TestChemAdmxContainer key={index}>{item}</TestChemAdmxContainer>
                ))}
              </>
            )}
          </Stack>
        );
      default:
        return null;
    }
  };

  return (
    <Stack gap={2}>
      {switchChemType()}
      {edit ? null : activity ? (
        <TestCardContainer>
          <TestTable
            headerData={{
              values: [i18T(`TEST.BASE.DETECTION`)],
            }}
            rows={[
              isPentaLegalPassed && isPentaCustomPassed
                ? {
                    titles: pentaVerifyResultText,
                    titlesStyle: [TestVerifyResultStyles.normal],
                  }
                : {
                    titles: pentaVerifyResultText,
                    titlesStyle: [
                      isPentaLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                      isPentaCustomPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                    ],
                  },
            ]}
            isShowCollapse={true}
          />
        </TestCardContainer>
      ) : (
        <TestChemAdmxContainer>
          <TestTable
            headerData={{
              values: [i18T(`TEST.BASE.DETECTION`)],
            }}
            rows={[
              isPentaLegalPassed && isPentaCustomPassed
                ? {
                    titles: pentaVerifyResultText,
                    titlesStyle: [TestVerifyResultStyles.normal],
                  }
                : {
                    titles: pentaVerifyResultText,
                    titlesStyle: [
                      isPentaLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                      isPentaCustomPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                    ],
                  },
            ]}
            isShowCollapse={true}
          />
        </TestChemAdmxContainer>
      )}
    </Stack>
  );
}

export default memo(ChemAdmxPentaTable);
