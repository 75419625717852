import * as Yup from 'yup';

export const basicStringSchema = (text?: string) => {
  return Yup.string().required(text ?? '必填');
};

export const basicNumberSchema = Yup.string()
  .min(1)
  .matches(/^-?\d+\.?\d*$/)
  .required();

export const notRequiredNumberSchema = Yup.mixed() // 使用mixed()方法可以匹配null
  .nullable() // 允许匹配null
  .test('isNumberOrNull', '必须是数字或null', (value) => {
    // 如果值是null，则直接通过验证
    if (value === null) {
      return true;
    }
    // 使用正则表达式匹配数字
    return /^-?\d+\.?\d*$/.test(value);
  });

export const concreteTestFormSchema = Yup.string().matches(/^-?\d+\.?\d*$/);
