import { Options } from '@Src/_basic/components/YatungSelect';
import YatungTextSelect from '@Src/_basic/components/YatungTextSelect';
import useI18n from '@Src/_basic/i18n/i18n';
import { GetLangData, LangData } from '@Src/_basic/object/MultilingualManagementType';
import { LanguageApi } from '@Src/_basic/protocol/languageManagement/LanguageApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useDate } from '@Src/redux/date/dateActions';
import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import React, { startTransition, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Header() {
  const { t: i18T } = useTranslation();
  const { i18n } = useI18n();
  const { userData } = useAuth();
  const { setDateTime, dateTime } = useDate();

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const weekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
  const year = dateTime.getFullYear();
  const month = dateTime.getMonth() + 1;
  const day = dateTime.getDate();
  const weekday = weekdays[dateTime.getDay()];

  const [updatePrioedUnit, setUpdtaePrioedUnit] = useState<string>('');
  const [langOptions, setLangOptions] = useState<Options[]>([]);
  const [langName, setLangName] = useState<string>('');

  const getLangOptions = () => {
    const params: GetLangData = {
      platformId: 1,
    };
    LanguageApi.getLangData(params, (datas: Array<LangData>) => {
      onGetLangOptionsSuccess(datas);
      setLangName(datas[0].name); //獲取第一個語言選項的名稱
    }); //獲取語言選項
  };

  const onGetLangOptionsSuccess = useCallback((data: Array<LangData>) => {
    setLangOptions(data.map(({ name }: LangData) => ({ value: name, text: name })));
  }, []);

  useEffect(() => {
    getLangOptions();
  }, []);

  const handleChangeClick = (e: SelectChangeEvent<unknown>) => {
    i18n.changeLanguage(e.target.value as string); //呼叫i18n的changeLanguage方法，並傳入選擇的語言
    setUpdtaePrioedUnit(e.target.value as string); //更新顯示語言
  };

  return (
    <AppBar position="static" sx={{ bgcolor: '#fff', boxShadow: 'none' }} component="nav">
      <Toolbar variant="dense" sx={{ justifyContent: 'flex-end' }}>
        <YatungTextSelect
          options={langOptions}
          value={updatePrioedUnit ? updatePrioedUnit : langName}
          onChange={(e) => {
            startTransition(() => {
              handleChangeClick(e);
            });
          }}
          style={{ maxWidth: '130px', marginRight: '10px' }}
          width={'130px'}
        />
        <Stack flexDirection={'row'}>
          <Box sx={{ border: '1px solid #BB5E00', borderRadius: 5, mr: 2, px: 3, py: '5px', alignSelf: 'center' }}>
            <Typography sx={{ color: '#BB5E00', fontSize: '14px', letterSpacing: '3px' }}>
              {i18T('AUTH.WELCOME')}
              &nbsp;
              <Link to={''} style={{ color: '#3E9B2F' }}>
                {userData?.accountName}
              </Link>
              {`｜${year}/${month}/${day}(${weekday})`}
            </Typography>
          </Box>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
