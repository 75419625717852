import { Protocol } from '../Protocol';

export class EquipmentProtocol {
  public static readonly GET_ALL_EQUIPMENT:string = `${Protocol.YATUNG_API_ROOT}/equipment/all`;
  public static readonly GET_EQUIPMENT_BY_ID:string = `${Protocol.YATUNG_API_ROOT}/equipment`;
  public static readonly CREATE_EQUIPMENT:string = `${Protocol.YATUNG_API_ROOT}/equipment`;
  public static readonly UPDATE_EQUIPMENT:string =  `${Protocol.YATUNG_API_ROOT}/equipment`;
  public static readonly DELETE_EQUIPMENT:string = `${Protocol.YATUNG_API_ROOT}/equipment`;
  public static readonly UPLOAD_EQUIPMENT:string = `${Protocol.YATUNG_API_ROOT}/file/upload/equip`;
  public static readonly DELETE_UPLOAD_EQUIPMENT:string = `${Protocol.YATUNG_API_ROOT}/file`;
  public static readonly GET_UPLOAD_EQUIPMENT:string = `${Protocol.YATUNG_API_ROOT}/file/equip`;
  public static readonly DOWNLOAD_FILE:string = `${Protocol.YATUNG_API_ROOT}/file/download`;



}
