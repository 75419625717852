import YatungChartCheckBox, { YatungChartCheckBoxVariantEnum } from '@Src/_basic/components/YatungChartCheckBox';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { useApi } from '@Src/redux/api/apiAction';
import { Box, Stack, Typography, styled } from '@mui/material';
import React, { useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useElementSize } from 'usehooks-ts';
import StatisticsCard from '../StatisticsCard';
import ChartTitle from './components/ChartTitle';
import ClimateMonitoringChart, { ClimateMonitoringOptionsValueEnum } from './components/ClimateMonitoringChart';

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

const enum DashboardTypeEnum {
  CLIMATE_MONITORING = 1,
  DAILY_RAINFALL_MONITORING = 2,
  HUMIDITY_MONITORING = 3,
  WIND_SPEED_MONITORING = 4,
}

const initDashboardTypeOptions: Array<Options> = [
  {
    text: '氣候監控',
    value: DashboardTypeEnum.CLIMATE_MONITORING,
  },
  {
    text: '日降雨量監控',
    value: DashboardTypeEnum.DAILY_RAINFALL_MONITORING,
  },
  {
    text: '濕度監控',
    value: DashboardTypeEnum.HUMIDITY_MONITORING,
  },
  {
    text: '風速監控',
    value: DashboardTypeEnum.WIND_SPEED_MONITORING,
  },
];

const initClimateMonitoringOptions = [
  { text: '平均溫度', value: ClimateMonitoringOptionsValueEnum.AVERAGE_TEMPERATURE },
  { text: '最高溫度', value: ClimateMonitoringOptionsValueEnum.HIGHEST_TEMPERATURE },
  { text: '最低溫度', value: ClimateMonitoringOptionsValueEnum.LOWEST_TEMPERATURE },
  { text: '混凝土生產方數', value: ClimateMonitoringOptionsValueEnum.CONCRETE_PRODUCTION },
];

const initFilterValue = {
  // FIXME just for demo
  startDate: new Date('2023/01/01').getTime(),
  endDate: new Date('2023/12/31').getTime(),

  // startDate: new Date().getTime() - 1000 * 60 * 60 * 24 * 30,
  // endDate: new Date().getTime(),
  dashboardTypeId: DashboardTypeEnum.CLIMATE_MONITORING,
};

const initQueryValue = {
  startDate: initFilterValue.startDate,
  endDate: initFilterValue.endDate,
  dashboardTypeId: DashboardTypeEnum.CLIMATE_MONITORING,
};
const initClimateMonitoringOptionsValue = [
  ClimateMonitoringOptionsValueEnum.AVERAGE_TEMPERATURE,
  ClimateMonitoringOptionsValueEnum.HIGHEST_TEMPERATURE,
  ClimateMonitoringOptionsValueEnum.LOWEST_TEMPERATURE,
];

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'updateValue':
      return { ...state, ...action.payload };
    default:
      return { state };
  }
}

export default function Monitor() {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const [infomationRef, informationSize] = useElementSize();

  const [filterValue, filterValueDispatch] = useReducer(reducer, initFilterValue);
  const [query, queryDispatch] = useReducer(reducer, initQueryValue);
  const [chartOptionsValue, setChartOptionsValue] = useState<Array<number>>(initClimateMonitoringOptionsValue);

  const chartOptions = useMemo(() => {
    switch (filterValue.dashboardTypeId) {
      case DashboardTypeEnum.CLIMATE_MONITORING:
        return initClimateMonitoringOptions;
      case DashboardTypeEnum.DAILY_RAINFALL_MONITORING:
        return [];
      case DashboardTypeEnum.HUMIDITY_MONITORING:
        return [];
      case DashboardTypeEnum.WIND_SPEED_MONITORING:
        return [];
      default:
        return [];
    }
  }, [filterValue.dashboardTypeId]);

  const chartTitle = useMemo(() => {
    switch (filterValue.dashboardTypeId) {
      case DashboardTypeEnum.CLIMATE_MONITORING:
        return '氣候監控圖';
      case DashboardTypeEnum.DAILY_RAINFALL_MONITORING:
        return '日降雨量監控圖';
      case DashboardTypeEnum.HUMIDITY_MONITORING:
        return '濕度監控圖';
      case DashboardTypeEnum.WIND_SPEED_MONITORING:
        return '平均風速(公里/小時)';
      default:
        return '';
    }
  }, [filterValue.dashboardTypeId]);

  const renderChart = useMemo(() => {
    switch (filterValue.dashboardTypeId) {
      case DashboardTypeEnum.CLIMATE_MONITORING:
        return <ClimateMonitoringChart chartOptionsValue={chartOptionsValue} />;
      case DashboardTypeEnum.DAILY_RAINFALL_MONITORING:
        return <></>;
      case DashboardTypeEnum.HUMIDITY_MONITORING:
        return <></>;
      case DashboardTypeEnum.WIND_SPEED_MONITORING:
        return <></>;
      default:
        return <></>;
    }
  }, [filterValue.dashboardTypeId, chartOptionsValue]);

  const handleStartDateChange = (value: any) => {
    handleFilterChange('startDate')(value);
    handleQueryChange('startDate')(value);
  };

  const handleEndDateChange = (value: any) => {
    handleFilterChange('endDate')(value);
    handleQueryChange('endDate')(value);
  };

  const handleDashboardTypeChange = (value: any) => {
    handleFilterChange('dashboardTypeId')(value);
    handleQueryChange('dashboardTypeId')(value);
  };

  const handleFilterChange = (field: string) => async (value: any) => {
    filterValueDispatch({ type: 'updateValue', payload: { [field]: value } });
  };

  const handleQueryChange = (field: string) => async (value: unknown) => {
    queryDispatch({ type: 'updateValue', payload: { [field]: value } });
  };

  return (
    <Box sx={{ padding: '33px 36px' }}>
      <Box
        ref={infomationRef}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          paddingBottom: '20px',
          borderBottom: '1px solid #000000',
        }}
      >
        <Stack gap="20px">
          <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
            <Title>{i18T('REPORT.PRODUCTION_HISTORY.MONITOR.TOOLBAR.TITLE')}</Title>
            <YatungDateSelect
              label={i18T('REPORT.PRODUCTION_HISTORY.MONITOR.TOOLBAR.START_DATE')}
              disabled={actionLoading}
              value={filterValue.startDate}
              maxDate={filterValue.endDate}
              disableFuture
              onChange={(val) => val && handleStartDateChange(new Date(val).getTime())}
            />
            <YatungDateSelect
              label={i18T('REPORT.PRODUCTION_HISTORY.MONITOR.TOOLBAR.END_DATE')}
              disabled={actionLoading}
              value={filterValue.endDate}
              minDate={filterValue.startDate}
              disableFuture
              onChange={(val) => val && handleEndDateChange(new Date(val).getTime())}
            />
            <YatungSelect
              disabled={initDashboardTypeOptions.length === 0 || actionLoading}
              options={initDashboardTypeOptions}
              value={filterValue.dashboardTypeId}
              onChange={(e) => handleDashboardTypeChange(e.target.value)}
            />
          </Stack>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              aluinItems: 'center',
              px: '16px',
              gap: '21px',
            }}
          >
            <StatisticsCard title="氣候環境監測天數" value={425} unit="天" />
            <StatisticsCard title="期間最高氣溫" value={41} unit="度" />
            <StatisticsCard title="期間最低氣溫" value={10} unit="度" />
            <StatisticsCard title="期間單日最大溫差" value={16} unit="度" />
          </Box>
        </Stack>
        <Box>
          <YatungChartCheckBox
            variant={YatungChartCheckBoxVariantEnum.RADIO_BUTTON}
            options={chartOptions}
            values={chartOptionsValue}
            onChange={setChartOptionsValue}
            labelTextStyle={{ fontFamily: 'Microsoft JhengHei', fontSize: '16px', fontWeight: 900, color: '#BB5E00' }}
            sx={{
              color: '#BB5E00',
              '&.Mui-checked': {
                color: '#BB5E00',
              },
            }}
          />
        </Box>
      </Box>
      {informationSize.height && informationSize.width && (
        <Box
          sx={{
            py: '10px',
            width: informationSize.width,
            maxHeight: `calc(100% - ${informationSize.height}px)`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <ChartTitle value={chartTitle} />
          </Box>
          {renderChart}
        </Box>
      )}
    </Box>
  );
}
