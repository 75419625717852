import YatungInput from '@Src/_basic/components/YatungInput';
import { Stack, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';

const LabelInputV2 = ({
  label,
  editable = true,
  rootWidth,
  value,
  valueWidth,
  letterSpacing = 4,
  labelAlign = 'center',
  labelColor = '#002F8C',
  isError,
  float = 4,
  negative = false,
  sx,
  ...props
}: any) => {
  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    const text: string = e.target.value;

    if (!float) {
      props.onChange(text.replace(/[^\d]/g, ''));
      return;
    } else if (text.includes('.')) {
      const decimal: Array<string> = text.split('.');
      if (decimal.length < 3) {
        if (decimal[1].length > float) {
          props.onChange(`${decimal[0]}.${decimal[1].slice(0, float)}`);
          return;
        } else props.onChange(text);
      }
    } else if (negative) {
      props.onChange(text);
      if (text.split('.').length <= 2) {
        const decimal: any = text.split('.');
        if (decimal.length < 3) {
          if (decimal[1].length > float) {
            props.onChange(`${decimal[0]}.${decimal[1].slice(0, float)}`);
            return;
          } else props.onChange(text);
        }
      }
    } else {
      props.onChange(text.replace(/[^\d.]/g, ''));
    }
  };

  return (
    <Stack
      flexDirection={'column'}
      alignItems="stretch"
      sx={{ mx: 1, mb: 1, ...(rootWidth ? { width: rootWidth } : {}) }}
    >
      <Typography
        sx={{ fontSize: '20px', fontWeight: 900, color: labelColor, letterSpacing, textAlign: labelAlign, ...sx }}
      >
        {label}
      </Typography>
      {editable ? (
        <YatungInput
          {...props}
          onChange={handleChangeText}
          sx={{ ...sx, border: isError ? '1px red solid' : {} }}
          value={value}
        />
      ) : (
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 400,
            color: '#6C708C',
            padding: '8px 12px',
            width: valueWidth ? valueWidth : '232px',
          }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};

export default LabelInputV2;
