import { Protocol } from "../../../Protocol";

export class GroupProtocol {
  
  public static readonly POST_CREATE_RIGHTS_GROUP: string = `${Protocol.YATUNG_AUTH}CA001`; // 建立權限組織
  public static readonly POST_UPDATE_RIGHTS_GROUP: string = `${Protocol.YATUNG_AUTH}CA002`; // 修改權限群組
  public static readonly POST_GET_RIGHTS_GROUPS: string = `${Protocol.YATUNG_AUTH}CA003`; // 取得權限組織清單
  public static readonly POST_DELETE_RIGHTS_GROUPS_BY_ID: string = `${Protocol.YATUNG_AUTH}CA004`; // 刪除權限群組
  public static readonly POST_GET_RIGHTS_GROUP_BY_ID: string = `${Protocol.YATUNG_AUTH}CA038`; // 取得權限組織
  
}