import {
  ChildrenTypography,
  TestCardContainer,
  TestVerifyResultStyles,
  TitleTypography,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import SpecimenDataRows from '@Src/_basic/components/YatungTest/SpecimenDataRows';
import Tab from '@Src/_basic/components/YatungTest/Tab';
import TestFormHeader from '@Src/_basic/components/YatungTest/TestFormHeader';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { ChemType, SpecimenDetailResponse } from '@Src/_basic/object/SpecimenType';
import { AllTest, ExperimentResultType } from '@Src/_basic/object/TestType';
import {
  ImportControlChemAdmxActivity,
  ImportStandardChemAdmxActivity,
  ImportTestChemAdmxActivity,
  PutChemAdmxActivityRequest,
} from '@Src/_basic/object/test/cementtitiousLiquid/ChemAdmxActivityType';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { SpecimenApi } from '@Src/_basic/protocol/specimen/SpecimenApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ChemAdmxPentaTable from '../ChemAdmxPentaTable';

interface Props {
  testDetail: AllTest;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

type FormData = Record<string, number | string>;

function ExportChemAdmxActivityTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const [specimenDetail, setSpecimenDetail] = useState<SpecimenDetailResponse>();
  const [chemType, setChemType] = useState<ChemType>(ChemType.G_TYPE);
  const [legalStandard, setLegalStandard] = useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();

  const { t: i18T } = useTranslation();

  const { pentaTestData, compStrGroup7days, compStrGroup28days } = testDetail;

  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    setEdit(false);
  }, [chemType]);

  const pentaExperimental = useMemo(
    () =>
      chemType === ChemType.G_TYPE
        ? pentaTestData?.experimentalGType?.sort((a, b) => a.id - b.id)
        : pentaTestData?.experimentalFluidify?.sort((a, b) => a.id - b.id),
    [chemType, pentaTestData?.experimentalGType, pentaTestData?.experimentalFluidify],
  );

  const compStrGroup7DaysControl = useMemo(
    () => (chemType === ChemType.G_TYPE ? compStrGroup7days?.controlGType : compStrGroup7days?.contorlFluidify),
    [chemType, compStrGroup7days?.controlGType, compStrGroup7days?.contorlFluidify],
  );
  const compStrGroup7DaysStandard = useMemo(
    () => (chemType === ChemType.G_TYPE ? compStrGroup7days?.standardGType : compStrGroup7days?.standardFludify),
    [chemType, compStrGroup7days?.standardGType, compStrGroup7days?.standardFludify],
  );
  const compStrGroup7DaysExperimental = useMemo(
    () =>
      (chemType === ChemType.G_TYPE
        ? compStrGroup7days?.experimentalGType?.sort((a, b) => Number(a.id) - Number(b.id))
        : compStrGroup7days?.experimentalFluidify?.sort((a, b) => Number(a.id) - Number(b.id))) ?? [],
    [chemType, compStrGroup7days?.experimentalGType, compStrGroup7days?.experimentalFluidify],
  );
  const compStrGroup28DaysControl = useMemo(
    () => (chemType === ChemType.G_TYPE ? compStrGroup28days?.controlGType : compStrGroup28days?.contorlFluidify),
    [chemType, compStrGroup28days?.controlGType, compStrGroup28days?.contorlFluidify],
  );
  const compStrGroup28DaysStandard = useMemo(
    () => (chemType === ChemType.G_TYPE ? compStrGroup28days?.standardGType : compStrGroup28days?.standardFludify),
    [chemType, compStrGroup28days?.standardGType, compStrGroup28days?.standardFludify],
  );
  const compStrGroup28DaysExperimental = useMemo(
    () =>
      (chemType === ChemType.G_TYPE
        ? compStrGroup28days?.experimentalGType?.sort((a, b) => Number(a.id) - Number(b.id))
        : compStrGroup28days?.experimentalFluidify?.sort((a, b) => Number(a.id) - Number(b.id))) ?? [],
    [chemType, compStrGroup28days?.experimentalGType, compStrGroup28days?.experimentalFluidify],
  );

  const tabList = useMemo(
    () => [
      { label: 'G-Type', value: ChemType.G_TYPE },
      { label: '流動化劑', value: ChemType.FLUIDIFIER },
    ],
    [],
  );

  const isCompStrLegalPassed = useMemo(() => {
    const verifyResult: Array<boolean> = [];

    compStrGroup7DaysExperimental.forEach((item) => {
      verifyResult.push(isLegalStandardPassed(item.verifyPassedResults));
    });

    compStrGroup28DaysExperimental.forEach((item) => {
      verifyResult.push(isLegalStandardPassed(item.verifyPassedResults));
    });

    return verifyResult.every((item: boolean) => item);
  }, [compStrGroup7DaysExperimental, compStrGroup28DaysExperimental]);

  const isCompStrCustomPassed = useMemo(() => {
    const verifyResult: Array<boolean> = [];

    compStrGroup7DaysExperimental.forEach((item) => {
      verifyResult.push(isCustomStandardPassed(item.verifyCustomResults));
    });

    compStrGroup28DaysExperimental.forEach((item) => {
      verifyResult.push(isCustomStandardPassed(item.verifyCustomResults));
    });

    return verifyResult.every((item: boolean) => item);
  }, [compStrGroup7DaysExperimental, compStrGroup28DaysExperimental]);

  const isCompStrCustomRejected = useMemo(() => {
    const verifyResult: Array<boolean> = [];

    compStrGroup7DaysExperimental.forEach((item) => {
      verifyResult.push(isRejected(item.verifyCustomResults));
    });

    compStrGroup28DaysExperimental.forEach((item) => {
      verifyResult.push(isRejected(item.verifyCustomResults));
    });

    return verifyResult.some((item: boolean) => item);
  }, [compStrGroup7DaysExperimental, compStrGroup28DaysExperimental]);

  const compStrVerifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isCompStrLegalPassed && isCompStrCustomPassed) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isCompStrLegalPassed
        ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`))
        : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCompStrCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCompStrCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isCompStrLegalPassed, isCompStrCustomPassed, isCompStrCustomRejected]);

  const formikBasicSetup = useMemo(() => {
    const _7DayData: FormData = {};
    const _28DayData: FormData = {};
    const validationSchemaObject: Record<string, typeof basicNumberSchema> = {};

    Object.entries({
      compStrGroup7DaysControl,
      compStrGroup7DaysStandard,
      compStrGroup7DaysExperimental,
    }).forEach(([key, value]) => {
      if (!value) return;

      if (!Array.isArray(value)) {
        if (key.includes('Control')) {
          _7DayData['controlSpecimenId'] = value.specimenId;
          _7DayData['controlSpecimen1CompStr'] = value.specimen1CompStr;
          _7DayData['controlSpecimen2CompStr'] = value.specimen2CompStr;
          _7DayData['controlSpecimen3CompStr'] = value.specimen3CompStr ?? 0;
          validationSchemaObject['controlSpecimenId'] = basicNumberSchema;
          validationSchemaObject['controlSpecimen1CompStr'] = basicNumberSchema;
          validationSchemaObject['controlSpecimen2CompStr'] = basicNumberSchema;
          value.specimen3CompStr ? (validationSchemaObject['controlSpecimen3CompStr'] = basicNumberSchema) : null;
        } else if (key.includes('Standard')) {
          _7DayData['standardSpecimenId'] = value.specimenId;
          _7DayData['standardSpecimen1CompStr'] = value.specimen1CompStr;
          _7DayData['standardSpecimen2CompStr'] = value.specimen2CompStr;
          _7DayData['standardSpecimen3CompStr'] = value.specimen3CompStr ?? 0;
          validationSchemaObject['standardSpecimenId'] = basicNumberSchema;
          validationSchemaObject['standardSpecimen1CompStr'] = basicNumberSchema;
          validationSchemaObject['standardSpecimen2CompStr'] = basicNumberSchema;
          value.specimen3CompStr ? (validationSchemaObject['standardSpecimen3CompStr'] = basicNumberSchema) : null;
        }
      } else {
        value.forEach((item) => {
          _7DayData[`${item.specimenId}_${item.id}_specimenId`] = item.specimenId;
          _7DayData[`${item.specimenId}_${item.id}_specimen1CompStr`] = item.specimen1CompStr;
          _7DayData[`${item.specimenId}_${item.id}_specimen2CompStr`] = item.specimen2CompStr;
          _7DayData[`${item.specimenId}_${item.id}_specimen3CompStr`] = item.specimen3CompStr ?? 0;
          validationSchemaObject[`${item.specimenId}_${item.id}_specimenId`] = basicNumberSchema;
          validationSchemaObject[`${item.specimenId}_${item.id}_specimen1CompStr`] = basicNumberSchema;
          validationSchemaObject[`${item.specimenId}_${item.id}_specimen2CompStr`] = basicNumberSchema;
          item.specimen3CompStr
            ? (validationSchemaObject[`${item.specimenId}_${item.id}_specimen3CompStr`] = basicNumberSchema)
            : null;
        });
      }
    });

    Object.entries({
      compStrGroup28DaysControl,
      compStrGroup28DaysStandard,
      compStrGroup28DaysExperimental,
    }).forEach(([key, value]) => {
      if (!value) return;

      if (!Array.isArray(value)) {
        if (key.includes('Control')) {
          _28DayData['controlSpecimenId'] = value.specimenId;
          _28DayData['controlSpecimen1CompStr'] = value.specimen1CompStr;
          _28DayData['controlSpecimen2CompStr'] = value.specimen2CompStr;
          _28DayData['controlSpecimen3CompStr'] = value.specimen3CompStr ?? 0;
          validationSchemaObject['controlSpecimenId'] = basicNumberSchema;
          validationSchemaObject['controlSpecimen1CompStr'] = basicNumberSchema;
          validationSchemaObject['controlSpecimen2CompStr'] = basicNumberSchema;
          value.specimen3CompStr ? (validationSchemaObject['controlSpecimen3CompStr'] = basicNumberSchema) : null;
        } else if (key.includes('Standard')) {
          _28DayData['standardSpecimenId'] = value.specimenId;
          _28DayData['standardSpecimen1CompStr'] = value.specimen1CompStr;
          _28DayData['standardSpecimen2CompStr'] = value.specimen2CompStr;
          _28DayData['standardSpecimen3CompStr'] = value.specimen3CompStr ?? 0;
          validationSchemaObject['standardSpecimenId'] = basicNumberSchema;
          validationSchemaObject['standardSpecimen1CompStr'] = basicNumberSchema;
          validationSchemaObject['standardSpecimen2CompStr'] = basicNumberSchema;
          value.specimen3CompStr ? (validationSchemaObject['standardSpecimen3CompStr'] = basicNumberSchema) : null;
        }
      } else {
        value.forEach((item) => {
          _28DayData[`${item.specimenId}_${item.id}_specimenId`] = item.specimenId;
          _28DayData[`${item.specimenId}_${item.id}_specimen1CompStr`] = item.specimen1CompStr;
          _28DayData[`${item.specimenId}_${item.id}_specimen2CompStr`] = item.specimen2CompStr;
          _28DayData[`${item.specimenId}_${item.id}_specimen3CompStr`] = item.specimen3CompStr ?? 0;
          validationSchemaObject[`${item.specimenId}_${item.id}_specimenId`] = basicNumberSchema;
          validationSchemaObject[`${item.specimenId}_${item.id}_specimen1CompStr`] = basicNumberSchema;
          validationSchemaObject[`${item.specimenId}_${item.id}_specimen2CompStr`] = basicNumberSchema;
          item.specimen3CompStr
            ? (validationSchemaObject[`${item.specimenId}_${item.id}_specimen3CompStr`] = basicNumberSchema)
            : null;
        });
      }
    });

    return { data: { _7DayData, _28DayData }, validationSchemaObject };
  }, [
    compStrGroup7DaysControl,
    compStrGroup7DaysStandard,
    compStrGroup7DaysExperimental,
    compStrGroup28DaysControl,
    compStrGroup28DaysStandard,
    compStrGroup28DaysExperimental,
  ]);

  const formik = useFormik({
    initialValues: formikBasicSetup.data,
    validationSchema: Yup.object().shape({}),
    onSubmit: (_values) => {
      const seven_basicData: FormData = {};
      const seven_formData: FormData[] = [];

      const twenty_eight_basicData: FormData = {};
      const twenty_eight_formData: FormData[] = [];

      Object.entries(_values._7DayData).forEach(([key, value]) => {
        if (key.startsWith('control') || key.startsWith('standard')) {
          seven_basicData[key] = value;
        } else {
          const [specimenId, id, item] = key.split('_');
          const _exist = seven_formData?.findIndex((item) => item.specimenId === specimenId);
          if (_exist >= 0) {
            seven_formData[_exist][item] = value;
          } else {
            seven_formData.push({
              specimenId,
              [item]: value,
              chemAdmxCompStrId: Number(id),
            });
          }
        }
      });

      Object.entries(_values._28DayData).forEach(([key, value]) => {
        if (key.startsWith('control') || key.startsWith('standard')) {
          twenty_eight_basicData[key] = value;
        } else {
          const [specimenId, id, item] = key.split('_');
          const _exist = twenty_eight_formData?.findIndex((item) => item.specimenId === specimenId);
          if (_exist >= 0) {
            twenty_eight_formData[_exist][item] = value;
          } else {
            twenty_eight_formData.push({
              specimenId,
              [item]: value,
              chemAdmxCompStrId: Number(id),
            });
          }
        }
      });

      const putTestPromises: PromiseLike<AllTest>[] = [];

      const [key]: string[] = Object.keys(testDetail.anotherOneExptIdAndAge);
      const _7_id: number = testDetail.age === 7 ? testDetail.id : Number(key);
      const _28_id: number = testDetail.age === 7 ? Number(key) : testDetail.id;

      seven_formData.forEach((item, index) => {
        const promise = new Promise<AllTest>((resolve, reject) => {
          setTimeout(() => {
            TestApi.putTest<PutChemAdmxActivityRequest>(
              {
                exptItemCode: testDetail.exptItemCode,
                fields: {
                  ...(seven_basicData as unknown as ImportControlChemAdmxActivity & ImportStandardChemAdmxActivity),
                  ...(item as unknown as ImportTestChemAdmxActivity),
                  id: _7_id,
                  exptUnitId: testDetail.exptUnitId,
                  chemType,
                  age: 7,
                  operator: testDetail.operator,
                  completed: true,
                  deadline: Date.now(),
                },
              },
              (_data) => {
                afterUpdateTest(_data);
                setEdit(false);
                resolve(_data);
              },
              (err) => {
                console.log(err);
                reject(err);
              },
            );
          }, 1500 * index);
        });
        putTestPromises.push(promise);
      });

      Promise.all(putTestPromises)
        .then(() => {
          twenty_eight_formData.forEach((item, index) => {
            setTimeout(() => {
              TestApi.putTest<PutChemAdmxActivityRequest>(
                {
                  exptItemCode: testDetail.exptItemCode,
                  fields: {
                    ...(twenty_eight_basicData as unknown as ImportControlChemAdmxActivity &
                      ImportStandardChemAdmxActivity),
                    ...(item as unknown as ImportTestChemAdmxActivity),
                    id: _28_id,
                    exptUnitId: testDetail.exptUnitId,
                    chemType,
                    age: 28,
                    operator: testDetail.operator,
                    completed: true,
                    deadline: Date.now(),
                  },
                },
                (_data) => {
                  afterUpdateTest(_data);
                  setEdit(false);
                },
                (err) => console.log(err),
              );
            }, 1000 * index);
          });
        })
        .catch((error) => {
          console.error('Error occurred:', error);
        });
    },
  });

  useEffect(() => {
    formik.setValues(formikBasicSetup.data);
  }, [chemType]);

  const showFourthTable = () => {
    return (
      <>
        <Stack flexDirection="row" justifyContent="space-between" p={2}>
          <Stack
            flexDirection="row"
            justifyContent="flex-end"
            gap={2}
            position="absolute"
            right={20}
            sx={{ zIndex: 5 }}
          >
            <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} />
          </Stack>
          <TitleTypography>{i18T(`TEST.CHEM_ADMX_ACTIVITY.GROUP_NAME`)}</TitleTypography>
          <TitleTypography>{i18T(`TEST.CHEM_ADMX_ACTIVITY.7DAY_STRENGTH`, { unit: 'kgf/m³' })}</TitleTypography>
          <TitleTypography>{i18T(`TEST.CHEM_ADMX_ACTIVITY.28DAY_STRENGTH`, { unit: 'kgf/m³' })}</TitleTypography>
        </Stack>

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography>{i18T(`TEST.CHEM_ADMX_ACTIVITY.CONTROL_GROUP`)}</ChildrenTypography>
          <Stack flex={1} p={'0 3px'} gap={1}>
            {edit ? (
              <>
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._7DayData['controlSpecimen1CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_7DayData.controlSpecimen1CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._7DayData['controlSpecimen2CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_7DayData.controlSpecimen2CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._7DayData['controlSpecimen3CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_7DayData.controlSpecimen3CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
              </>
            ) : (
              <>
                <ChildrenTypography>
                  {compStrGroup7DaysControl?.specimen1CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography>
                  {compStrGroup7DaysControl?.specimen2CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography>
                  {compStrGroup7DaysControl?.specimen3CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
              </>
            )}
          </Stack>
          <Stack flex={1} p={'0 3px'} gap={1}>
            {edit ? (
              <>
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._28DayData['controlSpecimen1CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_28DayData.controlSpecimen1CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._28DayData['controlSpecimen2CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_28DayData.controlSpecimen2CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._28DayData['controlSpecimen3CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_28DayData.controlSpecimen3CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
              </>
            ) : (
              <>
                <ChildrenTypography>
                  {compStrGroup28DaysControl?.specimen1CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography>
                  {compStrGroup28DaysControl?.specimen2CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography>
                  {compStrGroup28DaysControl?.specimen3CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
              </>
            )}
          </Stack>
        </Stack>
        {!edit && (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            sx={{ borderBottom: '1px solid gray' }}
          >
            <ChildrenTypography>{i18T(`TEST.CHEM_ADMX_ACTIVITY.AVERAGE`)}</ChildrenTypography>
            <ChildrenTypography>{compStrGroup7DaysControl?.averageCompStr?.toFixed(1) ?? '0.0'}</ChildrenTypography>
            <ChildrenTypography>{compStrGroup28DaysControl?.averageCompStr?.toFixed(1) ?? '0.0'}</ChildrenTypography>
          </Stack>
        )}

        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          sx={{ borderBottom: '1px solid gray' }}
        >
          <ChildrenTypography color={TestVerifyResultStyles.normal}>
            {i18T(`TEST.CHEM_ADMX_ACTIVITY.STANDARD_GROUP`)}
          </ChildrenTypography>
          <Stack flex={1} p={'0 3px'} gap={1}>
            {edit ? (
              <>
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._7DayData['standardSpecimen1CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_7DayData.standardSpecimen1CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._7DayData['standardSpecimen2CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_7DayData.standardSpecimen2CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._7DayData['standardSpecimen3CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_7DayData.standardSpecimen3CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
              </>
            ) : (
              <>
                <ChildrenTypography color={TestVerifyResultStyles.normal}>
                  {compStrGroup7DaysStandard?.specimen1CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography color={TestVerifyResultStyles.normal}>
                  {compStrGroup7DaysStandard?.specimen2CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography color={TestVerifyResultStyles.normal}>
                  {compStrGroup7DaysStandard?.specimen3CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
              </>
            )}
          </Stack>
          <Stack flex={1} p={'0 3px'} gap={1}>
            {edit ? (
              <>
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._28DayData['standardSpecimen1CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_28DayData.standardSpecimen1CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._28DayData['standardSpecimen2CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_28DayData.standardSpecimen2CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
                <LabelInput
                  valueWidth="250px"
                  value={formik.values._28DayData['standardSpecimen3CompStr']}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('_28DayData.standardSpecimen3CompStr', checkIsNaN(Number(e.target.value)))
                  }
                />
              </>
            ) : (
              <>
                <ChildrenTypography color={TestVerifyResultStyles.normal}>
                  {compStrGroup28DaysStandard?.specimen1CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography color={TestVerifyResultStyles.normal}>
                  {compStrGroup28DaysStandard?.specimen2CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
                <ChildrenTypography color={TestVerifyResultStyles.normal}>
                  {compStrGroup28DaysStandard?.specimen3CompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
              </>
            )}
          </Stack>
        </Stack>

        {!edit && (
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            sx={{ borderBottom: '1px solid gray' }}
          >
            <ChildrenTypography>{i18T(`TEST.CHEM_ADMX_ACTIVITY.AVERAGE`)}</ChildrenTypography>
            <ChildrenTypography>{compStrGroup7DaysStandard?.averageCompStr?.toFixed(1) ?? '0.0'}</ChildrenTypography>
            <ChildrenTypography>{compStrGroup28DaysStandard?.averageCompStr?.toFixed(1) ?? '0.0'}</ChildrenTypography>
          </Stack>
        )}

        {!edit && (
          <Stack flex={1} p={2} flexDirection="row" sx={{ borderBottom: '1px solid gray' }}>
            <ChildrenTypography color={TestVerifyResultStyles.abnormal}>
              {i18T(`TEST.CHEM_ADMX_ACTIVITY.STANDARD`)}
            </ChildrenTypography>
            <Stack flex={1}>
              <ChildrenTypography color={TestVerifyResultStyles.abnormal}>
                {(
                  (compStrGroup7DaysStandard?.averageCompStr || 0) +
                  (legalStandard?.get('7DAY_CONTROL_COMP_STR')?.minValue || 0)
                )?.toFixed(1) ?? '0.0'}
              </ChildrenTypography>
            </Stack>
            <Stack flex={1}>
              <ChildrenTypography color={TestVerifyResultStyles.abnormal}>
                {(
                  (compStrGroup7DaysStandard?.averageCompStr || 0) +
                  (legalStandard?.get('28DAY_CONTROL_COMP_STR')?.minValue || 0)
                )?.toFixed(1) ?? '0.0'}
              </ChildrenTypography>
            </Stack>
          </Stack>
        )}

        {pentaExperimental?.map((item, index) => (
          <React.Fragment key={index}>
            <Stack flex={1} p={2} flexDirection="row" alignItems="center" sx={{ borderBottom: '1px solid gray' }}>
              <ChildrenTypography
                color={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
              >
                {item?.groupName}
              </ChildrenTypography>

              <Stack flex={1} p={2} gap={1}>
                {edit ? (
                  <>
                    <LabelInput
                      valueWidth="250px"
                      value={
                        formik.values._7DayData[
                          `${item.specimenId}_${compStrGroup7DaysExperimental[index]?.id}_specimen1CompStr`
                        ]
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue(
                          `_7DayData.${item.specimenId}_${compStrGroup7DaysExperimental[index]?.id}_specimen1CompStr`,
                          checkIsNaN(Number(e.target.value)),
                        )
                      }
                    />
                    <LabelInput
                      valueWidth="250px"
                      value={
                        formik.values._7DayData[
                          `${item.specimenId}_${compStrGroup7DaysExperimental[index]?.id}_specimen2CompStr`
                        ]
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue(
                          `_7DayData.${item.specimenId}_${compStrGroup7DaysExperimental[index]?.id}_specimen2CompStr`,
                          checkIsNaN(Number(e.target.value)),
                        )
                      }
                    />
                    <LabelInput
                      valueWidth="250px"
                      value={
                        formik.values._7DayData[
                          `${item.specimenId}_${compStrGroup7DaysExperimental[index]?.id}_specimen3CompStr`
                        ]
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue(
                          `_7DayData.${item.specimenId}_${compStrGroup7DaysExperimental[index]?.id}_specimen3CompStr`,
                          checkIsNaN(Number(e.target.value)),
                        )
                      }
                    />
                  </>
                ) : (
                  <>
                    <ChildrenTypography
                      style={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                    >
                      {compStrGroup7DaysExperimental[index]?.specimen1CompStr?.toFixed(1) ?? '0.0'}
                    </ChildrenTypography>
                    <ChildrenTypography
                      style={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                    >
                      {compStrGroup7DaysExperimental[index]?.specimen2CompStr?.toFixed(1) ?? '0.0'}
                    </ChildrenTypography>
                    <ChildrenTypography
                      style={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                    >
                      {compStrGroup7DaysExperimental[index]?.specimen3CompStr?.toFixed(1) ?? '0.0'}
                    </ChildrenTypography>
                  </>
                )}
              </Stack>
              <Stack flex={1} p={'0 3px'} gap={1}>
                {edit ? (
                  <>
                    <LabelInput
                      valueWidth="250px"
                      value={
                        formik.values._28DayData[
                          `${item.specimenId}_${compStrGroup28DaysExperimental[index]?.id}_specimen1CompStr`
                        ]
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue(
                          `_28DayData.${item.specimenId}_${compStrGroup28DaysExperimental[index]?.id}_specimen1CompStr`,
                          checkIsNaN(Number(e.target.value)),
                        )
                      }
                    />
                    <LabelInput
                      valueWidth="250px"
                      value={
                        formik.values._28DayData[
                          `${item.specimenId}_${compStrGroup28DaysExperimental[index]?.id}_specimen2CompStr`
                        ]
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue(
                          `_28DayData.${item.specimenId}_${compStrGroup28DaysExperimental[index]?.id}_specimen2CompStr`,
                          checkIsNaN(Number(e.target.value)),
                        )
                      }
                    />
                    <LabelInput
                      valueWidth="250px"
                      value={
                        formik.values._28DayData[
                          `${item.specimenId}_${compStrGroup28DaysExperimental[index]?.id}_specimen3CompStr`
                        ]
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue(
                          `_28DayData.${item.specimenId}_${compStrGroup28DaysExperimental[index]?.id}_specimen3CompStr`,
                          checkIsNaN(Number(e.target.value)),
                        )
                      }
                    />
                  </>
                ) : (
                  <>
                    <ChildrenTypography
                      style={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                    >
                      {compStrGroup28DaysExperimental[index]?.specimen1CompStr?.toFixed(1) ?? '0.0'}
                    </ChildrenTypography>
                    <ChildrenTypography
                      style={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                    >
                      {compStrGroup28DaysExperimental[index]?.specimen2CompStr?.toFixed(1) ?? '0.0'}
                    </ChildrenTypography>
                    <ChildrenTypography
                      style={index % 2 === 0 ? TestVerifyResultStyles.textBlue : TestVerifyResultStyles.normal}
                    >
                      {compStrGroup28DaysExperimental[index]?.specimen3CompStr?.toFixed(1) ?? '0.0'}
                    </ChildrenTypography>
                  </>
                )}
              </Stack>
            </Stack>
            {!edit && (
              <Stack flex={1} p={2} flexDirection="row" alignItems="center" sx={{ borderBottom: '1px solid gray' }}>
                <ChildrenTypography>{i18T(`TEST.CHEM_ADMX_ACTIVITY.AVERAGE`)}</ChildrenTypography>
                <ChildrenTypography
                  color={{
                    ...(compStrGroup7DaysExperimental[index]?.verifyPassedResults &&
                    compStrGroup7DaysExperimental[index]?.verifyCustomResults
                      ? compStrGroup7DaysExperimental[index]?.verifyPassedResults['7DAY_CONTROL_COMP_STR'] &&
                        compStrGroup7DaysExperimental[index]?.verifyCustomResults['7DAY_CONTROL_COMP_STR']?.passed
                        ? TestVerifyResultStyles.normal
                        : TestVerifyResultStyles.abnormal
                      : {}),
                  }}
                >
                  {compStrGroup7DaysExperimental[index]?.averageCompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>

                <ChildrenTypography
                  color={{
                    ...(compStrGroup7DaysExperimental[index]?.verifyPassedResults &&
                    compStrGroup7DaysExperimental[index]?.verifyCustomResults
                      ? compStrGroup7DaysExperimental[index]?.verifyPassedResults['28DAY_CONTROL_COMP_STR'] &&
                        compStrGroup28DaysExperimental[index]?.verifyCustomResults['28DAY_CONTROL_COMP_STR']?.passed
                        ? TestVerifyResultStyles.normal
                        : TestVerifyResultStyles.abnormal
                      : {}),
                  }}
                >
                  {compStrGroup28DaysExperimental[index]?.averageCompStr?.toFixed(1) ?? '0.0'}
                </ChildrenTypography>
              </Stack>
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (chemType === ChemType.G_TYPE) {
      if (testDetail.compStrGroup7days?.standardGType?.specimenId)
        SpecimenApi.getSpecimenDetail(
          { specimenId: testDetail.compStrGroup7days?.standardGType?.specimenId },
          (_data) => setSpecimenDetail(_data),
        );
      else if (testDetail.compStrGroup28days?.standardGType?.specimenId)
        SpecimenApi.getSpecimenDetail(
          { specimenId: testDetail.compStrGroup28days?.standardGType?.specimenId },
          (_data) => setSpecimenDetail(_data),
        );
      else setSpecimenDetail(undefined);
    } else if (chemType === ChemType.FLUIDIFIER) {
      if (testDetail.compStrGroup7days?.standardFludify?.specimenId)
        SpecimenApi.getSpecimenDetail(
          { specimenId: testDetail.compStrGroup7days?.standardFludify?.specimenId },
          (_data) => setSpecimenDetail(_data),
        );
      else if (testDetail.compStrGroup28days?.standardFludify?.specimenId)
        SpecimenApi.getSpecimenDetail(
          { specimenId: testDetail.compStrGroup28days?.standardFludify?.specimenId },
          (_data) => setSpecimenDetail(_data),
        );
      else setSpecimenDetail(undefined);
    } else {
      setSpecimenDetail(undefined);
    }
  }, [testDetail, chemType]);

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.CHEM_ADMX_MULTI_TEST,
        effectiveUnixTime: testDetail.completeTime || Date.now(),
        type: 1,
      },
      (_data) => {
        const standardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          standardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandard(standardMap);
      },
      () => undefined,
    );
  }, []);

  return (
    <Stack gap={2}>
      <TestCardContainer sx={{ gap: 3 }}>
        <Tab
          list={tabList}
          selected={chemType}
          onSelect={(value) => (value === ChemType.G_TYPE || value === ChemType.FLUIDIFIER) && setChemType(value)}
        />
        {specimenDetail && (
          <TestFormHeader>
            <SpecimenDataRows data={specimenDetail} testDetail={testDetail} />
          </TestFormHeader>
        )}
      </TestCardContainer>
      {pentaTestData && (
        <ChemAdmxPentaTable
          controlGType={pentaTestData?.controlGType}
          standardGType={pentaTestData?.standardGType}
          experimentalGType={pentaTestData?.experimentalGType}
          controlFluidify={pentaTestData?.controlFluidify}
          standardFluidify={pentaTestData?.standardFluidify}
          experimentalFluidify={pentaTestData?.experimentalFluidify}
          chemType={chemType}
          testDetail={testDetail}
          activity={true}
          afterUpdateTest={afterUpdateTest}
          canEdit={canEdit}
        />
      )}
      <TestCardContainer>{showFourthTable()}</TestCardContainer>
      {specimenDetail ? (
        <TestCardContainer>
          <TestTable
            headerData={{
              values: [i18T(`TEST.BASE.DETECTION`)],
            }}
            rows={[
              isCompStrLegalPassed && isCompStrCustomPassed
                ? {
                    titles: compStrVerifyResultText,
                    titlesStyle: [TestVerifyResultStyles.normal],
                  }
                : {
                    titles: compStrVerifyResultText,
                    titlesStyle: [
                      isCompStrLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                      isCompStrCustomPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                    ],
                  },
            ]}
            isShowCollapse={true}
          />
        </TestCardContainer>
      ) : (
        <></>
      )}
    </Stack>
  );
}

export default memo(ExportChemAdmxActivityTable);
