import MonthlyReportIcon from '@Src/_basic/icons/MonthlyReport';
import { FileInfo } from '@Src/_basic/object/FileType';
import { AllTest, TestItemEnum } from '@Src/_basic/object/TestType';
import { GroupDataByCementCompStrResponse } from '@Src/_basic/object/test/cementtitiousPowder/CementCompStrType';
import { GroupDataByFlyAshActivityResponse } from '@Src/_basic/object/test/cementtitiousPowder/FlyAshActivityType';
import { GroupDataBySlagActivityResponse } from '@Src/_basic/object/test/cementtitiousPowder/SlagActivityType';
import { FileApi } from '@Src/_basic/protocol/file/FileApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { useAuth } from '@Src/redux/auth/authActions';
import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Typography,
  styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import YatungButton from '../../YatungButton';
import YatungDialog from '../../YatungDialog';
import SwitchTestTable from './SwitchTestTable';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialogContent = styled(DialogContent)(() => ({
  '&::-webkit-scrollbar': {
    width: 12,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#BB5E00',
  },
}));

interface Props {
  exptItemCode: TestItemEnum;
  exptId: number;
  open: boolean;
  closeHandler: () => void;
  allTable?: boolean;
  canEdit?: boolean;
}

const TestDetailDialog = ({ exptItemCode, exptId, open, closeHandler, allTable, canEdit = false }: Props) => {
  const { t: i18T } = useTranslation();
  const [files, setFiles] = useState<FileList>();
  const [testDetail, setTestDetail] = useState<AllTest | undefined | 0>(0);
  const [fileInfoList, setFileInfoList] = useState<Array<FileInfo>>([]);
  const [testDataByCementCompStr, setTestDataByCementCompStr] = useState<
    GroupDataByFlyAshActivityResponse | GroupDataBySlagActivityResponse | Array<GroupDataByCementCompStrResponse>
  >();
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | undefined>(undefined); //刪除檔案的id
  const { userData } = useAuth();

  const handleCreateGoBackButtonClick = () => {
    setCreateModalOpen(false);
  };

  const handleDeleteFileButtonClick = (id: number | undefined) => {
    //開啟刪除提示
    setCreateModalOpen(true);
    setDeleteId(id);
  };

  const handleDeleteCheckButtonClick = (id: number | undefined) => {
    //確認刪除
    FileApi.deleteFile(id, () => {
      refreshFileData();
      setCreateModalOpen(false);
    });
  };

  const refreshFileData = () => {
    //重新整理檔案
    FileApi.getFile({ exptId, exptItemCode }, (_data) => setFileInfoList(_data));
  };

  const list = [''];

  if (files) {
    for (let i = 0; i < files?.length; i++) {
      list.push(i ? `、${files[i]?.name}` : files[i]?.name); //紀錄選擇的檔案
    }
  }

  const handleChange = (event: any) => {
    const files = event.target.files;
    if (files) {
      setFiles(files);
    }
  };

  useEffect(() => {
    handleChange;
  }, [files]);

  const handleUploadFile = () => {
    //上傳檔案
    if (files) {
      if (userData) {
        FileApi.postFile({ exptId, exptItemCode, uploaderId: userData.account }, files, () => {
          refreshFileData();
          setFiles(undefined); //上傳成功後清空選擇的檔案
        });
      }
    }
  };

  useEffect(() => {
    TestApi.getTestDetail(
      { exptId, exptItemCode },
      (_data) => {
        if (_data.completed) setTestDetail(_data);
        else {
          setTestDetail(undefined);
          setTimeout(() => closeHandler(), 3000);
        }
      },
      (err) => console.log(err),
    );

    FileApi.getFile({ exptId, exptItemCode }, (_data) => setFileInfoList(_data));

    switch (exptItemCode) {
      case TestItemEnum.FLY_ASH_ACTIVITY_TEST:
        TestApi.getGroupDataByFlyAshActivity({ exptId }, (data) => setTestDataByCementCompStr(data));
        break;
      case TestItemEnum.SLAG_ACTIVITY_TEST:
        TestApi.getGroupDataBySlagActivity({ exptId }, (data) => setTestDataByCementCompStr(data));
        break;
      case TestItemEnum.CEMENT_COMP_STR_TEST:
        TestApi.getGroupDataByCementCompStr({ exptId }, (data) => setTestDataByCementCompStr(data));
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exptId, exptItemCode]);

  return (
    <Dialog fullScreen open={open} onClose={closeHandler} TransitionComponent={Transition} sx={{ py: 2, px: 10 }}>
      <CustomDialogContent>
        <AppBar sx={{ position: 'absolute', width: '100%', bgcolor: ' #BB5E00' }}>
          <Toolbar
            sx={{
              bgcolor: ' #BB5E00',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeHandler}
              aria-label="close"
              sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}
            >
              <CloseIcon />
            </IconButton>
            {testDetail && (
              <Typography
                sx={{
                  flex: 1,
                  fontSize: 24,
                  transform: testDetail?.exptItemCode ? 'translateX(-15%)' : 'translateX(-4%)',
                }}
              >
                {testDetail?.exptItemCode ? i18T(`TEST.TEST_ITEM.${testDetail.exptItemCode}`) : i18T('GLOBAL.NO_INFO')}
              </Typography>
            )}
          </Toolbar>
        </AppBar>

        <Stack gap={2} pt={10} pb={4}>
          {typeof testDetail === 'undefined' ? (
            <Typography sx={{ textAlign: 'center' }}>{i18T(`VIEW.DIALOG.UNDEFINED_FILE`)}...</Typography>
          ) : (
            <SwitchTestTable
              testDetail={testDetail as AllTest}
              testDataByCementCompStr={testDataByCementCompStr}
              allTable={allTable}
              canEdit={canEdit}
            />
          )}

        </Stack>
      </CustomDialogContent>

      <YatungDialog
        open={createModalOpen}
        handleClose={handleCreateGoBackButtonClick}
        icon={<MonthlyReportIcon sx={{ width: 40, height: 50, mr: 1, alignSelf: 'center' }} />}
        headerComponent={
          <>
            <Stack flexDirection={'row'} justifyContent={'center'} display={'flex'}>
              <Typography sx={{ fontSize: '44px', pl: 1 }}>{i18T(`VIEW.DIALOG.DELETE_FILE`)}</Typography>
            </Stack>
          </>
        }
        body={
          <>
            <Stack display={'flex'} justifyContent={'center'}>
              <Stack flexDirection={'row'} justifyContent={'center'}>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold', pl: 1, margin: '10px' }}>
                  {i18T('GLOBAL.CONFIRM_DELETE')}？
                </Typography>
              </Stack>

              <Stack
                spacing={3}
                sx={{ bgcolor: 'white', minWidth: 500, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}
                display="flex"
                flexDirection="column"
              >
                <Stack alignItems="center" justifyContent="center" flexDirection="row">
                  <YatungButton
                    text={i18T('SAMPLEMANAGEMENT.CANCEL')}
                    onClick={handleCreateGoBackButtonClick}
                    sx={{
                      color: '#ffffff',
                      bgcolor: '#CB333B',
                      fontSize: '24px',
                      width: 120,
                      mr: 1,
                      '&.MuiButtonBase-root:hover': {
                        bgcolor: '#CB333B',
                      },
                    }}
                  />
                  <YatungButton
                    text={i18T('GLOBAL.SURE')}
                    type="submit"
                    onClick={() => handleDeleteCheckButtonClick(deleteId)}
                    sx={{
                      border: '1px solid #fff',
                      color: '#ffffff',
                      bgcolor: '#002F8C',
                      fontSize: '24px',
                      width: 120,
                      '&.MuiButtonBase-root:hover': {
                        bgcolor: '#002F8C',
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </>
        }
      />
    </Dialog>
  );
};

export default memo(TestDetailDialog);
