import { Typography } from '@mui/material';
import { AppPermissionRequest } from '@Src/_basic/object/AppPermissionType';
import React from 'react';

interface Props {
  row: AppPermissionRequest;
}
export default function NameColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.name}</Typography>;
}
