import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { OutlinedInput, styled } from '@mui/material';
import TriangleDown from '../icons/TriangleDown';
import Close from '../icons/Close';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& fieldset': {
    borderColor: '#6C708C',
  },
  '& .MuiTextField-root': {
    margin: '0px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    background: 'white',
  },
}));

const TriangleDownIcon = styled(TriangleDown)(() => ({
  color: '#BB5E00',
  fill: '#BB5E00',
}));

export type Options = {
  value: number | string;
  text: string | undefined;
};

export interface Props {
  options: Options[];
  dynamicOptions?: Options[];
  multiple?: boolean;
  value?: any | undefined;
  onChange?: (value: any) => void;
  helperText?: string;
  fullWidth?: boolean;
  limitTags?: number;
  width?: React.CSSProperties['width'];
}

export function YatungDynamicComboBox(props: Props) {
  const [options, setOptions] = useState(props.options);
  const [selectedOptions, setSelectedOptions] = useState<Array<Options>>([]);
  const [selectedOption, setSelectedOption] = useState<Options>();

  const isAllSelected = () => {
    if (props.multiple) {
      if (options.length > 1) {
        return selectedOptions.length === options.length - 1;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const initOptions = () => {
    if (props.multiple) {
      if (props.dynamicOptions) {
        setOptions([{ value: 'all', text: '全選' }, ...selectedOptions, ...props.dynamicOptions]);
      } else {
        setOptions([{ value: 'all', text: '全選' }, ...props.options]);
      }
    } else {
      if (props.dynamicOptions) {
        if (selectedOption) {
          setOptions([selectedOption, ...props.dynamicOptions]);
        } else {
          setOptions([...props.dynamicOptions]);
        }
      } else {
        setOptions([...props.options]);
      }
    }
  };

  const getSelectedOptions = () => {
    if (props.value && props.options.length) {
      setSelectedOptions(props.options.filter((option: Options) => props.value.includes(option.value)));
    }
  };

  const getSelectedOption = () => {
    setSelectedOption(props.options.find((option: any) => option.value === props.value) || props.options[0]);
  };

  const handleChange = (value: any) => {
    if (props.multiple) {
      if (value[value.length - 1]?.value === 'all') {
        setSelectedOptions(isAllSelected() ? [] : options.slice(1, options.length));
        props.onChange?.(
          isAllSelected() ? [] : options.slice(1, options.length).map((option: Options) => option.value),
        );
      } else {
        setSelectedOptions(value);
        props.onChange?.(value.map((option: Options) => option.value));
      }
    } else {
      setSelectedOption(value);
      props.onChange?.(value?.value);
    }
  };

  useEffect(() => {
    initOptions();
  }, [props.options, props.dynamicOptions, selectedOptions]);

  useEffect(() => {
    if (props.multiple) {
      getSelectedOptions();
    } else {
      getSelectedOption();
    }
  }, [props.value, props.options]);

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <StyledAutocomplete
        value={props.multiple ? selectedOptions : selectedOption}
        onChange={(event, value) => {
          handleChange(value);
        }}
        size="small"
        options={options}
        getOptionLabel={(option: any) => option?.text}
        renderOption={(htmlProps, option: any, { selected }) => (
          <>
            {props.multiple ? (
              <li {...htmlProps}>
                {option.value === 'all' ? (
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={isAllSelected()}
                    indeterminate={selectedOptions.length > 0 && selectedOptions?.length < options.length - 1}
                  />
                ) : (
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                )}

                {option.text}
              </li>
            ) : (
              <li {...htmlProps}>{option.text}</li>
            )}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="normal"
            placeholder={'Search'}
            error={!!props.helperText}
            helperText={props.helperText}
            sx={{
              input: {
                color: 'black',
                borderRadius: '10px',
              },
              width: props.fullWidth ? '100%' : props.width ? props.width : '180px',
            }}
          />
        )}
        popupIcon={<TriangleDownIcon />}
        multiple={props.multiple}
        limitTags={props.limitTags}
        noOptionsText={'沒有選項'}
        disableCloseOnSelect
      />
    </div>
  );
}
