import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function Chemical(props: SvgIconProps) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18475 10.9283C10.0593 10.9283 10.7683 10.2088 10.7683 9.32118C10.7683 8.95938 10.6505 8.62549 10.4517 8.3569H11.0829L14.4132 14.7909C14.8556 15.6457 14.3026 16.714 13.4178 16.714H3.6848C2.80004 16.714 2.24704 15.6457 2.68942 14.7909L6.01979 8.3569H7.91778C7.71898 8.62549 7.60117 8.95938 7.60117 9.32118C7.60117 10.2088 8.31017 10.9283 9.18475 10.9283ZM6.0176 14.1426C6.54235 14.1426 6.96774 13.7109 6.96774 13.1783C6.96774 12.6458 6.54235 12.214 6.0176 12.214C5.49285 12.214 5.06745 12.6458 5.06745 13.1783C5.06745 13.7109 5.49285 14.1426 6.0176 14.1426ZM8.86804 16.0712C9.21787 16.0712 9.50147 15.7834 9.50147 15.4283C9.50147 15.0733 9.21787 14.7855 8.86804 14.7855C8.5182 14.7855 8.2346 15.0733 8.2346 15.4283C8.2346 15.7834 8.5182 16.0712 8.86804 16.0712Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5385 17.9998C16.8016 17.9998 17.5911 16.5935 16.9595 15.4685L12.1949 6.98172C12.0498 6.72333 12.0522 6.41248 12.0545 6.11163C12.0548 6.07414 12.0551 6.0368 12.0551 5.99976V1.12476C12.0551 0.503302 11.5653 -0.000244141 10.9611 -0.000244141H6.22068C5.61654 -0.000244141 5.12672 0.503302 5.12672 1.12476V5.99976C5.12672 6.0368 5.12701 6.07414 5.1273 6.11161C5.12961 6.41246 5.13199 6.72333 4.98693 6.98172L0.222269 15.4685C-0.409282 16.5935 0.380202 17.9998 1.64331 17.9998H15.5385ZM5.76015 1.12476C5.76015 0.850372 5.97418 0.642613 6.22068 0.642613H10.9611C11.2076 0.642613 11.4217 0.850372 11.4217 1.12476V5.99976C11.4217 6.0265 11.4214 6.05652 11.4211 6.08907C11.42 6.2228 11.4185 6.39898 11.4328 6.56371C11.4517 6.78069 11.5009 7.04409 11.6445 7.29997L16.4092 15.7867C16.8073 16.4959 16.3012 17.3569 15.5385 17.3569H1.64331C0.880597 17.3569 0.374529 16.4959 0.772643 15.7867L5.5373 7.29997C5.68095 7.04409 5.73011 6.78069 5.74898 6.56371C5.76331 6.39898 5.76182 6.2228 5.76068 6.08907C5.76041 6.05664 5.76015 6.0264 5.76015 5.99976V1.12476Z"
        fill="white"
      />
    </svg>
  );
}
