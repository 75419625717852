import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function TriangleDown(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M2,7l10,10L22,7" />
    </SvgIcon>
  );
}
