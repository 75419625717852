import DeteleIcon from '@Basic/icons/Delete';
import YatungButton from '@Src/_basic/components/YatungButton';
import { YatungDynamicComboBox } from '@Src/_basic/components/YatungDynamicComboBox';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import {
  CreateCustomStandardSpecificationDetailRequest,
  CreateCustomStandardSpecificationFormData,
} from '@Src/_basic/object/CustomStandardType';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useCreateCustomStandardUI } from '../../..';

interface Props {
  title: string;
  specificationId: string | number | undefined;
  detectionItem: any;
  detectionIndex: number;
  typeAmount: number;
  notificationPriorityOptions: Options[];
  displayspecificationExtraDataSelect: boolean;
  specificationExtraDataFieldName: string;
  specificationExtraDataOptions: Options[];
  dynamicSpecificationExtraDataOptions: Options[];
  renderDeleteButton?: boolean;
}

export default function DetectionItemCard(props: Props) {
  const { formData, formDataDispatch } = useCreateCustomStandardUI();

  const handleSpecificationExtraDataChange = (specificationId: any, value: any) => {
    if (formData && formDataDispatch) {
      const newSpecifications = formData.specifications.map((specification: any) => {
        if (specification.id === specificationId) {
          specification[props.specificationExtraDataFieldName] = value;
        }
        return specification;
      });
      formDataDispatch({ type: 'updateValue', payload: { specifications: newSpecifications } });
    }
  };
  const handleDeleteSpecificationButtonClick = (specificationId: any) => {
    if (formData && formDataDispatch) {
      const newSpecifications = formData.specifications.filter(
        (specification: CreateCustomStandardSpecificationFormData) => specification.id !== specificationId,
      );
      formDataDispatch({ type: 'updateValue', payload: { specifications: newSpecifications } });
    }
  };

  const handleNotificationPriorityChange = (type: number, value: any) => {
    if (formData && formDataDispatch) {
      const newSpecifications = formData.specifications.map(
        (specification: CreateCustomStandardSpecificationFormData) => {
          if (specification.id === props.specificationId) {
            if (isSpecificationDetailAlreadyExist(specification.details, props.detectionItem.id, type)) {
              specification.details = specification.details.map(
                (detail: CreateCustomStandardSpecificationDetailRequest) => {
                  if (detail.experimentDetectionItemId === props.detectionItem.id && detail.type === type) {
                    detail.notificationPriorityId = parseInt(value);
                  }
                  return detail;
                },
              );
            } else {
              specification.details.push({
                type,
                experimentDetectionItemId: props.detectionItem.id,
                notificationPriorityId: value,
                minValue: null,
                maxValue: null,
                rejectMinValue: null,
                rejectMaxValue: null,
              });
            }
          }
          return specification;
        },
      );
      formDataDispatch({ type: 'updateValue', payload: { specifications: newSpecifications } });
    }
  };

  const handleMinValueChange = (type: any, value: any) => {
    if (formData && formDataDispatch) {
      const newSpecifications = formData.specifications.map((specification: any) => {
        if (specification.id === props.specificationId) {
          if (isSpecificationDetailAlreadyExist(specification.details, props.detectionItem.id, type)) {
            specification.details = specification.details.map((detail: any) => {
              if (detail.experimentDetectionItemId === props.detectionItem.id && detail.type === type) {
                detail.minValue = !isNaN(parseFloat(value)) ? parseFloat(value) : null;
              }
              return detail;
            });
          } else if (!isNaN(parseFloat(value))) {
            specification.details.push({
              type,
              experimentDetectionItemId: props.detectionItem.id,
              notificationPriorityId: 3,
              minValue: parseFloat(value),
              maxValue: null,
            });
          }
        }
        return specification;
      });
      formDataDispatch({ type: 'updateValue', payload: { specifications: newSpecifications } });
    }
  };

  const handleMaxValueChange = (type: any, value: any) => {
    if (formData && formDataDispatch) {
      const newSpecifications = formData.specifications.map((specification: any) => {
        if (specification.id === props.specificationId) {
          if (isSpecificationDetailAlreadyExist(specification.details, props.detectionItem.id, type)) {
            specification.details = specification.details.map((detail: any) => {
              if (detail.experimentDetectionItemId === props.detectionItem.id && detail.type === type) {
                detail.maxValue = !isNaN(parseFloat(value)) ? parseFloat(value) : null;
              }
              return detail;
            });
          } else if (!isNaN(parseFloat(value))) {
            specification.details.push({
              type,
              experimentDetectionItemId: props.detectionItem.id,
              notificationPriorityId: 3,
              minValue: null,
              maxValue: parseFloat(value),
            });
          }
        }
        return specification;
      });
      formDataDispatch({ type: 'updateValue', payload: { specifications: newSpecifications } });
    }
  };

  const handleRejectMinValueChange = (type: any, value: any) => {
    if (formData && formDataDispatch) {
      const newSpecifications = formData.specifications.map((specification: any) => {
        if (specification.id === props.specificationId) {
          if (isSpecificationDetailAlreadyExist(specification.details, props.detectionItem.id, type)) {
            specification.details = specification.details.map((detail: any) => {
              if (detail.experimentDetectionItemId === props.detectionItem.id && detail.type === type) {
                detail.rejectMinValue = !isNaN(parseFloat(value)) ? parseFloat(value) : null;
              }
              return detail;
            });
          } else if (!isNaN(parseFloat(value))) {
            specification.details.push({
              type,
              experimentDetectionItemId: props.detectionItem.id,
              notificationPriorityId: 3,
              minValue: null,
              maxValue: null,
              rejectMinValue: parseFloat(value),
              rejectMaxValue: null,
            });
          }
        }
        return specification;
      });
      formDataDispatch({ type: 'updateValue', payload: { specifications: newSpecifications } });
    }
  };

  const handleRejectMaxValueChange = (type: any, value: any) => {
    if (formData && formDataDispatch) {
      const newSpecifications = formData.specifications.map((specification: any) => {
        if (specification.id === props.specificationId) {
          if (isSpecificationDetailAlreadyExist(specification.details, props.detectionItem.id, type)) {
            specification.details = specification.details.map((detail: any) => {
              if (detail.experimentDetectionItemId === props.detectionItem.id && detail.type === type) {
                detail.rejectMaxValue = !isNaN(parseFloat(value)) ? parseFloat(value) : null;
              }
              return detail;
            });
          } else if (!isNaN(parseFloat(value))) {
            specification.details.push({
              type,
              experimentDetectionItemId: props.detectionItem.id,
              notificationPriorityId: 3,
              minValue: null,
              maxValue: null,
              rejectMinValue: null,
              rejectMaxValue: parseFloat(value),
            });
          }
        }
        return specification;
      });
      formDataDispatch({ type: 'updateValue', payload: { specifications: newSpecifications } });
    }
  };

  const isSpecificationDetailAlreadyExist = (details: Array<any>, detectionItemId: any, type: any) => {
    return !!details.find(
      (detail: any) => detail.experimentDetectionItemId === detectionItemId && detail.type === type,
    );
  };

  const getSpecificationExtraDataValue = () => {
    if (formData) {
      switch (props.specificationExtraDataFieldName) {
        case 'sourceIds':
          return formData.specifications.find((specification: any) => specification.id === props.specificationId)
            ?.sourceIds;
        case 'specificationIds':
          return formData.specifications.find((specification: any) => specification.id === props.specificationId)
            ?.specificationIds;
        default:
          return [];
      }
    }
  };

  return (
    <Paper key={props.detectionItem.id} elevation={2} sx={{ padding: '16px 32px', borderRadius: '5px' }}>
      <Stack spacing={3}>
        {props.detectionIndex === 0 && (
          <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs>
              <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item>
                  <Typography variant="h2" color="#BB5E00">
                    {props.title}
                  </Typography>
                </Grid>
                {props.displayspecificationExtraDataSelect && (
                  <Grid item xs>
                    <YatungDynamicComboBox
                      options={props.specificationExtraDataOptions}
                      dynamicOptions={props.dynamicSpecificationExtraDataOptions}
                      multiple
                      limitTags={3}
                      value={getSpecificationExtraDataValue()}
                      onChange={(value: any) => {
                        handleSpecificationExtraDataChange(props.specificationId, value);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              {props.renderDeleteButton === false ? null : (
                <YatungButton
                  startIcon={<DeteleIcon />}
                  text="刪除"
                  color="red"
                  onClick={() => {
                    handleDeleteSpecificationButtonClick(props.specificationId);
                  }}
                />
              )}
            </Grid>
          </Grid>
        )}
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography style={{ fontSize: '20px' }}>{`檢測項目(${props.detectionIndex + 1})：`}</Typography>
          <Typography sx={{ fontSize: '20px', flex: 'none' }}>{props.detectionItem.name}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography sx={{ fontSize: '20px', flex: 'none' }}>{`回報等級：`}</Typography>
          <YatungSelect
            disabled={props.notificationPriorityOptions.length === 0}
            options={props.notificationPriorityOptions}
            value={props.detectionItem.notificationPriorityId}
            onChange={(e: any) => {
              handleNotificationPriorityChange(1, e.target.value);
            }}
          />
        </Stack>
        {props.typeAmount > 1 ? (
          [...Array(props.typeAmount)].map((_, index) => {
            return (
              <React.Fragment key={index}>
                <Stack direction="row" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: '20px',
                      flex: 'none',
                    }}
                  >
                    {`檢測(${props.detectionIndex + 1})Type${index + 1}以上>：`}
                  </Typography>
                  <YatungInput
                    type="number"
                    defaultValue={props.detectionItem.minValue}
                    onBlur={(e: any) => {
                      handleMinValueChange(index + 1, e.target.value);
                    }}
                  />
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Typography sx={{ fontSize: '20px', flex: 'none' }}>
                    {`檢測(${props.detectionIndex + 1})Type${index + 1}以下<：`}
                  </Typography>
                  <YatungInput
                    type="number"
                    defaultValue={props.detectionItem.maxValue}
                    onBlur={(e: any) => {
                      handleMaxValueChange(index + 1, e.target.value);
                    }}
                  />
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: '20px',
                      flex: 'none',
                    }}
                  >
                    {`拒收(${props.detectionIndex + 1})Type${index + 1}超過>：`}
                  </Typography>
                  <YatungInput
                    type="number"
                    defaultValue={props.detectionItem.minValue}
                    onBlur={(e: any) => {
                      handleRejectMinValueChange(index + 1, e.target.value);
                    }}
                  />
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Typography sx={{ fontSize: '20px', flex: 'none' }}>
                    {`拒收(${props.detectionIndex + 1})Type${index + 1}未達<：`}
                  </Typography>
                  <YatungInput
                    type="number"
                    defaultValue={props.detectionItem.maxValue}
                    onBlur={(e: any) => {
                      handleRejectMaxValueChange(index + 1, e.target.value);
                    }}
                  />
                </Stack>
              </React.Fragment>
            );
          })
        ) : (
          <>
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontSize: '20px',
                  flex: 'none',
                }}
              >
                {`檢測(${props.detectionIndex + 1})以上>=：`}
              </Typography>
              {props.detectionItem.code === '7DAY_CONTROL_COMP_STR' && (
                <Typography sx={{ fontSize: '20px', flex: 'none' }}>原樣7天抗壓強度</Typography>
              )}
              {props.detectionItem.code === '28DAY_CONTROL_COMP_STR' && (
                <Typography sx={{ fontSize: '20px', flex: 'none' }}>原樣28天抗壓強度</Typography>
              )}
              <YatungInput
                type="number"
                defaultValue={props.detectionItem.minValue}
                onBlur={(e: any) => {
                  handleMinValueChange(1, e.target.value);
                }}
              />
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontSize: '20px', flex: 'none' }}>
                {`檢測(${props.detectionIndex + 1})以下<=：`}
              </Typography>
              {props.detectionItem.code === '7DAY_CONTROL_COMP_STR' && (
                <Typography sx={{ fontSize: '20px', flex: 'none' }}>原樣7天抗壓強度</Typography>
              )}
              {props.detectionItem.code === '28DAY_CONTROL_COMP_STR' && (
                <Typography sx={{ fontSize: '20px', flex: 'none' }}>原樣28天抗壓強度</Typography>
              )}
              <YatungInput
                type="number"
                defaultValue={props.detectionItem.maxValue}
                onBlur={(e: any) => {
                  handleMaxValueChange(1, e.target.value);
                }}
              />
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontSize: '20px',
                  flex: 'none',
                }}
              >
                {`拒收(${props.detectionIndex + 1})超過>=：`}
              </Typography>
              {props.detectionItem.code === '7DAY_CONTROL_COMP_STR' && (
                <Typography sx={{ fontSize: '20px', flex: 'none' }}>原樣7天抗壓強度</Typography>
              )}
              {props.detectionItem.code === '28DAY_CONTROL_COMP_STR' && (
                <Typography sx={{ fontSize: '20px', flex: 'none' }}>原樣28天抗壓強度</Typography>
              )}
              <YatungInput
                type="number"
                defaultValue={props.detectionItem.rejectMinValue}
                onBlur={(e: any) => {
                  handleRejectMaxValueChange(1, e.target.value);
                }}
              />
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontSize: '20px', flex: 'none' }}>
                {`拒收(${props.detectionIndex + 1})未達<=：`}
              </Typography>
              {props.detectionItem.code === '7DAY_CONTROL_COMP_STR' && (
                <Typography sx={{ fontSize: '20px', flex: 'none' }}>原樣7天抗壓強度</Typography>
              )}
              {props.detectionItem.code === '28DAY_CONTROL_COMP_STR' && (
                <Typography sx={{ fontSize: '20px', flex: 'none' }}>原樣28天抗壓強度</Typography>
              )}
              <YatungInput
                type="number"
                defaultValue={props.detectionItem.rejectMaxValue}
                onBlur={(e: any) => {
                  handleRejectMinValueChange(1, e.target.value);
                }}
              />
            </Stack>
          </>
        )}
      </Stack>
    </Paper>
  );
}
