import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function BlankSquare(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18.9,22h-13c-1.9,0-3.5-1.6-3.5-3.5v-13C2.4,3.6,4,2,5.9,2h13c1.9,0,3.5,1.6,3.5,3.5v13C22.4,20.4,20.8,22,18.9,22z M5.9,3C4.5,3,3.4,4.1,3.4,5.5v13c0,1.4,1.1,2.5,2.5,2.5h13c1.4,0,2.5-1.1,2.5-2.5v-13c0-1.4-1.1-2.5-2.5-2.5H5.9z"/>
    </SvgIcon>
  );
}
