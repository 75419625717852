import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function More(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="29" height="29" viewBox="0 0 29 29" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4219 25.5C20.497 25.5 25.4219 20.5751 25.4219 14.5C25.4219 8.42487 20.497 3.5 14.4219 3.5C8.34674 3.5 3.42188 8.42487 3.42188 14.5C3.42188 20.5751 8.34674 25.5 14.4219 25.5ZM14.4219 28.5C22.1539 28.5 28.4219 22.232 28.4219 14.5C28.4219 6.76801 22.1539 0.5 14.4219 0.5C6.68989 0.5 0.421875 6.76801 0.421875 14.5C0.421875 22.232 6.68989 28.5 14.4219 28.5Z"
        fill="white"
      />
      <path
        d="M19.3442 13.6173C18.824 14.1612 18.0911 14.5 17.279 14.5C15.7011 14.5 14.4219 13.2208 14.4219 11.6429C14.4219 10.8308 14.7607 10.0979 15.3046 9.57768C15.0181 9.52663 14.7231 9.5 14.4219 9.5C11.6605 9.5 9.42188 11.7386 9.42188 14.5C9.42188 17.2614 11.6605 19.5 14.4219 19.5C17.1833 19.5 19.4219 17.2614 19.4219 14.5C19.4219 14.1988 19.3952 13.9038 19.3442 13.6173Z"
        fill="white"
      />
    </SvgIcon>
  );
}
