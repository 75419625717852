import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../Api';
import { TestTypeProtocol } from './TestTypeProtocol';
import { TestTypeData } from '@Src/_basic/object/TestTypeType';

export class TestTypeApi {
  public static getAllTestType(
    onSuccess?: (data: Array<TestTypeData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(TestTypeProtocol.ALL_TEST_TYPE, undefined, onSuccess, onFailed, onFinally);
  }
}
