import React from 'react';
import { Typography } from '@mui/material';
import { EquipmentData } from '@Src/_basic/object/EquipmentManagementType';

interface Props {
  row: EquipmentData;
}

export default function PeriodColumnFormatter(props: Props) {
  const { row } = props;
  const TransType = () => {
    if (row.periodUnit === 'YEAR') {
      return '年';
    }
    if (row.periodUnit === 'MONTH') {
      return '月';
    }
    if (row.periodUnit === 'WEEK') {
      return '周';
    }
  };
  return (
    <Typography variant="inherit">
      {row.periodNumber}
      {TransType()}
    </Typography>
  );
}
