import {
  CreateCustomUnitRequest,
  IntlUnitConversionData,
  IntlUnitData,
  SearchCustomUnitParams,
  UnitData,
  UpdateInitialWeightUnitRequest,
} from '@Src/_basic/object/WeightUnitManageType';
import { WeightUnitManageProtocol } from './WeightUnitManageProtocol';
import { Api } from '../Api';
import {Response} from '@Src/_basic/object/ApiType';

export class WeightUnitManageApi {
  // 取得所有預設自訂單位
  public static getAllUnitCustom(
    onSuccess?: (data: Array<UnitData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
    ) {
    Api.get(WeightUnitManageProtocol.UNIT_CUTOM_ALL, undefined, onSuccess, onFailed, onFinally);
  }

  // 依據搜尋參數取得預設自訂單位
  public static getUnitCustomBySearch(
    params: SearchCustomUnitParams,
    onSuccess?: (data: Array<UnitData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
    ) {
    const config = {
      params
    };
    Api.get(WeightUnitManageProtocol.UNIT_CUTOM_ALL, config, onSuccess, onFailed, onFinally);
  }
  //取得單一自訂重量單位
  public static getUnitCustomById(
    id: number,
    onSuccess?: (data: UnitData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
    ) {
    const config = {
      params: {
        id,
      },
    };

    Api.get(WeightUnitManageProtocol.UNIT_CUTOM_BY_ID, config, onSuccess, onFailed, onFinally);
  }
  //取得所有國際單位
  public static getUnitIntlConversion(
    onSuccess?: (data: Array<IntlUnitConversionData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,

  ) {
    Api.get(WeightUnitManageProtocol.UNIT_INTL_CONVERSION_ALL, undefined, onSuccess, onFailed, onFinally);
  }

  //取得可選擇的國際單位
  public static getUnitIntlAvaliable(
    onSuccess?: (data: Array<IntlUnitData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(WeightUnitManageProtocol.UNIT_INTL_AVALIABLE, undefined, onSuccess, onFailed, onFinally);
  }
  //建立自訂重量單位
  public static createUnitCustom(
    request: CreateCustomUnitRequest,
    onSuccess?: (data: UnitData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
    ) {

      Api.post(WeightUnitManageProtocol.CREATE_UNIT_CUSTOM, request,undefined, onSuccess, onFailed, onFinally);
  }
  //修改自訂重量單位
  public static updateUnitCostom(
    request: UpdateInitialWeightUnitRequest,
    onSuccess?: (data: UnitData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
    ) {
      Api.put(WeightUnitManageProtocol.UPDATE_UNIT_CUSTOM, request,undefined, onSuccess, onFailed, onFinally);

  }
}
