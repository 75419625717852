import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import { UpdateExptScheduleFormType } from '@Src/_basic/object/ExptScheduleType';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import PeriodForm from '../PeriodForm';
import MemoCard from './components/MemoCard';
import RequirementCard from './components/RequirementCard';
import SelectExptCard from './components/SelectExptCard';

const Label = styled(Typography)(({ theme }) => ({
  color: '#6C708C',
  fontSize: '20px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
}));

const schema = Yup.object({
  exptItems: Yup.array()
    .of(
      Yup.object({
        exptItemId: Yup.number().required('請選擇試驗'),
        operator: Yup.string().required('請指派人員'),
      }),
    )
    .required('請至少選擇一個試驗')
    .min(1, '請至少選擇一個試驗'),
});

interface Props {
  initialValues: UpdateExptScheduleFormType;
  onSubmit: (request: UpdateExptScheduleFormType) => void;
}

export default function UpdateExptScheduleForm({ initialValues, onSubmit }: Props) {
  // TODO using i18n
  const { actionLoading } = useApi();
  const { userAreaOptionsData } = useAuth();

  const handleSubmit = (values: UpdateExptScheduleFormType) => {
    console.log('UpdateExptScheduleForm', values);
    onSubmit(values);
  };

  const handleBack = () => {
    window.history.back();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const factoryOptions = useMemo(() => {
    return userAreaOptionsData.find((option) => option.value === formik.values.areaId)?.factories || [];
  }, [userAreaOptionsData, formik.values.areaId]);

  const areaSelect = useMemo(() => {
    return (
      <YatungSelect
        disabled={userAreaOptionsData.length === 0 || actionLoading}
        options={userAreaOptionsData}
        value={formik.values.areaId}
        onChange={(e) => formik.setFieldValue('areaId', e.target.value)}
      />
    );
  }, [actionLoading, userAreaOptionsData, formik.values.areaId]);

  const factorySelect = useMemo(() => {
    return (
      <YatungSelect
        disabled={factoryOptions.length === 0 || actionLoading}
        options={factoryOptions}
        value={formik.values.factoryId}
        onChange={(e) => formik.setFieldValue('factoryId', e.target.value)}
      />
    );
  }, [actionLoading, factoryOptions, formik.values.factoryId]);

  const titleInput = useMemo(() => {
    return (
      <YatungInput
        name="title"
        value={formik.values.title}
        onChange={(e: any) => formik.setFieldValue('title', e.target.value)}
      />
    );
  }, [formik.values.title]);

  const periodForm = useMemo(() => {
    return (
      <Box sx={{ opacity: formik.values.isRequirement === 'true' ? 1 : 0 }}>
        <Stack gap="20px">
          <Box sx={{ padding: '10px 0px 10px 0px', borderBottom: '1px #697EA3 solid' }}>
            <Label>排程設定</Label>
          </Box>
          <Stack direction="row" alignItems="center">
            <Label>名稱：</Label>
            {titleInput}
          </Stack>
          <Stack direction="row" alignItems="flex-start">
            <Label>排程設定：</Label>
            <PeriodForm
              periodType={formik.values.period?.periodType}
              periodWeekDays={formik.values.period?.periodWeekDays}
              periodDates={formik.values.period?.periodDates}
              setFieldValue={formik.setFieldValue}
              style={{ marginLeft: '50px' }}
              updateMode
            />
          </Stack>
        </Stack>
      </Box>
    );
  }, [
    titleInput,
    formik.values.isRequirement,
    formik.values.period?.periodType,
    formik.values.period?.periodWeekDays,
    formik.values.period?.periodDates,
    formik.setFieldValue,
  ]);
  return (
    <>
      <Box>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Stack direction="row" spacing={2} sx={{ py: 1 }}>
            {areaSelect}
            {factorySelect}
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '29px',
        }}
        gap="16px"
      >
        <RequirementCard
          factoryId={formik.values.factoryId}
          isRequirement={formik.values.isRequirement}
          exptTypeId={formik.values.exptTypeId}
          specificationId={formik.values.specificationId}
          sourceId={formik.values.sourceId}
          sampleSourceId={formik.values.sampleSourceId}
          storageId={formik.values.storageId}
          setFieldValue={formik.setFieldValue}
        />
        <SelectExptCard
          isRequirement={formik.values.isRequirement}
          exptTypeId={formik.values.exptTypeId}
          specificationId={formik.values.specificationId}
          exptItems={formik.values.exptItems}
          errors={formik.errors}
          setFieldValue={formik.setFieldValue}
        />
        <MemoCard
          memo={formik.values.memo}
          invisible={formik.values.isRequirement !== 'true'}
          setFieldValue={formik.setFieldValue}
        />
      </Box>
      <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '51px' }}>
        {/* {periodForm} */}
        <Box sx={{ opacity: formik.values.isRequirement === 'true' ? 1 : 0 }}>
          <Stack gap="20px">
            <Box sx={{ padding: '10px 0px 10px 0px', borderBottom: '1px #697EA3 solid' }}>
              <Label>排程設定</Label>
            </Box>
            <Stack direction="row" alignItems="center">
              <Label>名稱：</Label>
              {titleInput}
            </Stack>
            <Stack direction="row" alignItems="flex-start">
              <Label>排程設定：</Label>
              <PeriodForm
                periodType={formik.values.period?.periodType}
                periodWeekDays={formik.values.period?.periodWeekDays}
                periodDates={formik.values.period?.periodDates}
                setFieldValue={formik.setFieldValue}
                style={{ marginLeft: '50px' }}
                updateMode
              />
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '8px' }}>
          <YatungButton
            color="yellow"
            text="返回"
            onClick={() => {
              handleBack();
            }}
          />
          <YatungButton
            color="green"
            text="完成"
            onClick={() => {
              formik.submitForm();
            }}
          />
        </Box>
      </Stack>
    </>
  );
}
