import { Response } from '@Src/_basic/object/ApiType';
import { FactoryData, GetFactoryBySearchRequest, PostFactoryDataRequest } from '@Src/_basic/object/FactoryType';
import { FactoryProtocol } from '@Src/_basic/protocol/factoryManagement/FactoryProtocol';
import axios from 'axios';
import { Api } from '../Api';

export class FactoryApi {
  // 取得所有廠
  public static getAllFactories(
    onSuccess?: (data: Array<FactoryData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(FactoryProtocol.ALL_FACTORY, undefined, onSuccess, onFailed, onFinally);
  }

  //取得搜尋後的廠資訊
  public static getFactoryBySearch(
    params: GetFactoryBySearchRequest,
    onSuccess?: (data: Array<FactoryData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
      headers: {
        Cancelable: true,
      },
    };
    Api.get(FactoryProtocol.ALL_FACTORY, config, onSuccess, onFailed, onFinally);
  }
  //取得未建立拌合機的廠資訊
  public static getFactoryWithoutMixer(
    id: number,
    onSuccess?: (data: Array<FactoryData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        areaId: id,
      },
    };
    Api.get(FactoryProtocol.GET_FACTORY_WITHOUT_MIXER, config, onSuccess, onFailed, onFinally);
  }
  public static GetFactoryById(
    id: number,
    onSuccess?: (data: FactoryData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(FactoryProtocol.GET_FACTORY_BY_ID, config, onSuccess, onFailed, onFinally);
  }

  public static PostFactory(
    request: PostFactoryDataRequest,
    onSuccess?: (data: FactoryData[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(FactoryProtocol.GET_FACTORY_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static PutFactory(
    request: any,
    onSuccess?: (data: FactoryData[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(FactoryProtocol.GET_FACTORY_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static DeleteFactoryById(
    id: number,
    onSuccess?: (data: Array<FactoryData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(FactoryProtocol.GET_FACTORY_BY_ID, config, onSuccess, onFailed, onFinally);
  }

  public static factoryManagementData() {
    return axios.get(FactoryProtocol.FACTORY_MANAGEMENT_DATA).then((res) => {
      return res.data;
    });
  }
  public static factoryManagementAddData(require: PostFactoryDataRequest) {
    return axios.post(FactoryProtocol.FACTORY_MANAGEMENT_CREATE_DATA, require).then((res) => {
      return res.data;
    });
  }
  public static factoryManagementUpdateData(require: PostFactoryDataRequest) {
    return axios.put(FactoryProtocol.FACTORY_MANAGEMENT_UPDATE_DATA, require).then((res) => {
      return res.data;
    });
  }
  public static factoryManagementGetIdData(id: number) {
    const params = {
      params: {
        id: id,
      },
    };
    return axios.get(FactoryProtocol.FACTORY_MANAGEMENT_GET_ID, params).then((res) => {
      return res.data;
    });
  }
}
