import React from 'react';
import { Grid, Typography } from '@mui/material';

interface Props {
  row : any;
  formatExtraData: {
    openText: string,
    closeText: string
  }
}
export default function StatusColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;
  return (
    <>
      <Typography 
        variant="inherit"
        sx={{
          color: row.removed ? "#FC0000" : "#00D03A",
          fontWeight: 600
        }}
      >
          {row.removed ? formatExtraData.closeText : formatExtraData.openText}</Typography>
    </>
  );
}
