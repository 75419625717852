import MockAdapter from 'axios-mock-adapter/types';
import siteAreaTableMock from '../../site/_mocks_/siteAreaTableMock';
import siteFactoryTableMock from '../../site/_mocks_/siteFactoryTableMock';
import { FeedTroughMaintenanceProtocol } from '../FeedTroughMaintenanceProtocol';
import feedTroughMaintenanceJoinSpecificationTableMock from './feedTroughMaintenanceJoinSpecificationTableMock';
import feedTroughMaintenanceTableMock from './feedTroughMaintenanceTableMock';
import feedTroughMaintenanceTypeSettingTableMock from './feedTroughMaintenanceTypeSettingTableMock';

export default function feedTroughMaintenanceMockDetails(mock: MockAdapter) {
  mock.onGet(FeedTroughMaintenanceProtocol.FEEDTHROUGHMAINTENANCE_MANAGEMENT_DATA).reply(200, {
    feedTroughMaintenanceTableMock,
  });
  mock.onPost(FeedTroughMaintenanceProtocol.FEEDTHROUGHMAINTENANCE_MANAGEMENT_CREATE_DATA).reply(({ data }) => {
    const {
      area,
      factory,
      typeSetting,
      feedTroughMaintenanceSymbol,
      joinSpecification,
      volumeSettingCubicMeter,
      volumeSettingSpecificGravity,
    } = JSON.parse(data);
    const totalVolumeSetting = volumeSettingCubicMeter * volumeSettingSpecificGravity;
    const areaFind = siteAreaTableMock.find((val: any) => val.value === area);
    const areaFilterToObject = Object.assign({}, areaFind);
    const factoryFind = siteFactoryTableMock.find((val: any) => val.value === factory);
    const factoryFilterToObject = Object.assign({}, factoryFind);
    const typeSettingFind = feedTroughMaintenanceTypeSettingTableMock.find((val: any) => val.value === typeSetting);
    const typeSettingFilterToObject = Object.assign({}, typeSettingFind);
    const joinSpecificationFind = feedTroughMaintenanceJoinSpecificationTableMock.find(
      (val: any) => val.value === joinSpecification,
    );
    const joinSpecificationFilterToObject = Object.assign({}, joinSpecificationFind);
    if (
      area &&
      factory &&
      typeSetting &&
      feedTroughMaintenanceSymbol &&
      joinSpecification &&
      volumeSettingCubicMeter &&
      volumeSettingSpecificGravity
    ) {
      const management = {
        id: generateUserId(),
        area: areaFilterToObject,
        factory: factoryFilterToObject,
        typeSetting: typeSettingFilterToObject,
        definitionName: '砂一',
        feedTroughMaintenanceSymbol,
        joinSpecification: joinSpecificationFilterToObject,
        volumeSettingCubicMeter,
        volumeSettingSpecificGravity,
        totalVolumeSetting: totalVolumeSetting,
      };
      console.log(management);
      feedTroughMaintenanceTableMock.push(management);
      return [200, { ...management }];
    }
    return [400];
  });
  mock.onPut(FeedTroughMaintenanceProtocol.FEEDTHROUGHMAINTENANCE_MANAGEMENT_UPDATE_DATA).reply(({ data }) => {
    console.log('data', data);
    const dataToJson = JSON.parse(data);
    console.log(dataToJson);
    const totalVolumeSetting = dataToJson.volumeSettingCubicMeter * dataToJson.volumeSettingSpecificGravity;
    const areaFind = siteAreaTableMock.find((val: any) => val.value === dataToJson.area);
    const areaFilterToObject = Object.assign({}, areaFind);
    const factoryFind = siteFactoryTableMock.find((val: any) => val.value === dataToJson.factory);
    const factoryFilterToObject = Object.assign({}, factoryFind);
    const typeSettingFind = feedTroughMaintenanceTypeSettingTableMock.find(
      (val: any) => val.value === dataToJson.typeSetting,
    );
    const typeSettingFilterToObject = Object.assign({}, typeSettingFind);
    const joinSpecificationFind = feedTroughMaintenanceJoinSpecificationTableMock.find(
      (val: any) => val.value === dataToJson.joinSpecification,
    );
    const joinSpecificationFilterToObject = Object.assign({}, joinSpecificationFind);
    const index = feedTroughMaintenanceTableMock.findIndex((el) => el.id === dataToJson.id);
    console.log('index', index);
    if (index !== -1) {
      feedTroughMaintenanceTableMock[index] = {
        ...dataToJson,
        area: areaFilterToObject,
        factory: factoryFilterToObject,
        typeSetting: typeSettingFilterToObject,
        joinSpecification: joinSpecificationFilterToObject,
        totalVolumeSetting: totalVolumeSetting,
      };
      return [
        200,
        {
          ...dataToJson,
          area: areaFilterToObject,
          factory: factoryFilterToObject,
          typeSetting: typeSettingFilterToObject,
          joinSpecification: joinSpecificationFilterToObject,
          totalVolumeSetting: totalVolumeSetting,
        },
      ];
    }
    console.log('error');
    return [400];
  });
  mock.onGet(FeedTroughMaintenanceProtocol.FEEDTHROUGHMAINTENANCE_MANAGEMENT_SEARCH_DATA).reply((config) => {
    console.log(config);
    if (config) {
      if (config.params.areaValue && config.params.factoryValue) {
        const totalValue = feedTroughMaintenanceTableMock.filter(
          (el) => el.area.value === config.params.areaValue && el.factory.value === config.params.factoryValue,
        );
        return [200, totalValue];
      } else if (config.params.areaValue) {
        const areaValue = feedTroughMaintenanceTableMock.filter((el) => el.area.value === config.params.areaValue);
        return [200, areaValue];
      } else if (config.params.factoryValue) {
        const factoryValue = feedTroughMaintenanceTableMock.filter(
          (el) => el.factory.value === config.params.factoryValue,
        );
        return [200, factoryValue];
      } else {
        return [200, feedTroughMaintenanceTableMock];
      }
    }
    return [400];
  });
  function generateUserId() {
    const ids = feedTroughMaintenanceTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}
