import { Grid, } from '@mui/material';
import React from 'react';
import YatungVideo from '@Src/_basic/components/YatungVideo';
import { MonitorOrderData } from '@Src/_basic/object/MonitorType';

interface Props {
  data: MonitorOrderData | undefined;
}

export default function ViewVideoField(props: Props) {
  const { data } = props;
  const ModalIcon = () => <YatungVideo vid={`${data?.imageUrlFlow}`} />;
  return (
    <>
      <Grid container spacing={2}>
        <ModalIcon />
      </Grid>
    </>
  );
}
