import { CircularProgress } from '@mui/material';
import React from 'react';

interface Props {
  overlay?: boolean;
  style?: React.CSSProperties;
}

export default function YatungLoading({ overlay = false, style }: Props) {
  return (
    <div className={overlay ? 'overlay' : ''} style={style}>
      <CircularProgress className="loading-spinner__spinner" />
    </div>
  );
}
