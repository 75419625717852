import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Car(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 47 42">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39" fill="none">
        <g clipPath="url(#clip0_355_58013)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9669 21.6406H37.1216V24.0197H35.0578L34.3781 25.974H32.7731L32.7795 25.883C32.7853 25.8008 32.7882 25.7178 32.7882 25.6341C32.7882 23.7101 31.2286 22.1504 29.3046 22.1504C27.3806 22.1504 25.8209 23.7101 25.8209 25.6341C25.8209 25.7178 25.8239 25.8008 25.8297 25.883L25.8361 25.974H21.8468L21.337 24.0197H17.6641V22.9151H18.3438C18.3743 22.9151 18.4092 22.8958 18.4457 22.8509C18.4811 22.8074 18.5064 22.7534 18.5181 22.7183L18.5191 22.7154L18.9669 21.6406ZM19.0802 21.8106L18.6783 22.7752C18.6613 22.825 18.6275 22.8967 18.5775 22.9581C18.5277 23.0194 18.4493 23.0851 18.3438 23.0851H17.834V23.8498H21.4683L21.9781 25.804H25.6549C25.6523 25.7477 25.651 25.691 25.651 25.6341C25.651 23.6163 27.2868 21.9805 29.3046 21.9805C31.3224 21.9805 32.9582 23.6163 32.9582 25.6341C32.9582 25.691 32.9569 25.7477 32.9543 25.804H34.2572L34.937 23.8498H36.9516V21.8106H19.0802Z"
            fill="#7C878E"
          />
          <path
            d="M7.88894 26.6537C8.45205 26.6537 8.90855 26.1972 8.90855 25.6341C8.90855 25.0709 8.45205 24.6144 7.88894 24.6144C7.32583 24.6144 6.86933 25.0709 6.86933 25.6341C6.86933 26.1972 7.32583 26.6537 7.88894 26.6537Z"
            fill="#7C878E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4576 25.6341C11.4576 27.605 9.85984 29.2027 7.88894 29.2027C5.91804 29.2027 4.32031 27.605 4.32031 25.6341C4.32031 23.6632 5.91804 22.0654 7.88894 22.0654C9.85984 22.0654 11.4576 23.6632 11.4576 25.6341ZM10.268 25.6341C10.268 26.948 9.20287 28.0131 7.88894 28.0131C6.57501 28.0131 5.50985 26.948 5.50985 25.6341C5.50985 24.3201 6.57501 23.255 7.88894 23.255C9.20287 23.255 10.268 24.3201 10.268 25.6341Z"
            fill="#7C878E"
          />
          <path
            d="M29.303 26.6537C29.8661 26.6537 30.3226 26.1972 30.3226 25.6341C30.3226 25.0709 29.8661 24.6144 29.303 24.6144C28.7399 24.6144 28.2834 25.0709 28.2834 25.6341C28.2834 26.1972 28.7399 26.6537 29.303 26.6537Z"
            fill="#7C878E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.8716 25.6341C32.8716 27.605 31.2739 29.2027 29.303 29.2027C27.3321 29.2027 25.7344 27.605 25.7344 25.6341C25.7344 23.6632 27.3321 22.0654 29.303 22.0654C31.2739 22.0654 32.8716 23.6632 32.8716 25.6341ZM31.6821 25.6341C31.6821 26.948 30.6169 28.0131 29.303 28.0131C27.9891 28.0131 26.9239 26.948 26.9239 25.6341C26.9239 24.3201 27.9891 23.255 29.303 23.255C30.6169 23.255 31.6821 24.3201 31.6821 25.6341Z"
            fill="#7C878E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.19935 20.7607V24.3593H1.06021L1.0526 24.3579C0.856992 24.3219 0.72548 24.1809 0.642105 24.0359C0.558435 23.8903 0.515357 23.7278 0.500777 23.6208L0.5 23.6151V21.6144C0.5 21.4672 0.567919 21.3592 0.64921 21.2868C0.727341 21.2173 0.819682 21.1784 0.884817 21.1629L2.19935 20.7607ZM2.02941 20.9904L0.928908 21.3272L0.925952 21.3278C0.883908 21.3375 0.816979 21.365 0.762211 21.4138C0.709342 21.4608 0.669935 21.5252 0.669935 21.6144V23.6034C0.682942 23.6935 0.72026 23.8308 0.78944 23.9512C0.859002 24.0722 0.953248 24.164 1.07612 24.1893H2.02941V20.9904Z"
            fill="#7C878E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.48647 15.0107V18.2414H7.23182L7.22494 18.2403C7.01421 18.2054 6.8694 18.0678 6.77588 17.9214C6.68268 17.7754 6.6345 17.6123 6.61814 17.5042L6.61719 17.4979V15.4966C6.61719 15.3458 6.69451 15.2372 6.78318 15.1661C6.87018 15.0964 6.9727 15.0584 7.04273 15.0439L7.0503 15.0424L8.48647 15.0107ZM7.06998 15.2119C7.02073 15.2233 6.9486 15.2514 6.88945 15.2987C6.82905 15.3471 6.78712 15.411 6.78712 15.4966V17.4849C6.80151 17.574 6.84265 17.7102 6.91911 17.8299C6.99585 17.9501 7.10238 18.0454 7.24608 18.0715H8.31653V15.1845L7.06998 15.2119Z"
            fill="#7C878E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2898 24.0196H16.5587V10H7.38225L4.06852 16.7124L1.85938 19.2614V20.4672L2.45415 20.281V24.0196H3.66425C3.87772 21.8258 5.72713 20.9608 7.97702 20.9608C10.2269 20.9608 12.0763 21.8258 12.2898 24.0196ZM14.2646 10.6797H7.97702L4.32343 17.6471H6.36264V17.6618C6.38947 17.8589 6.52363 18.4376 6.84561 18.4967H8.74173V17.6471H14.2646V10.6797ZM13.16 18.7516C12.7846 18.7516 12.4803 19.056 12.4803 19.4314H14.6894C14.6894 19.056 14.3851 18.7516 14.0097 18.7516H13.16ZM4.32343 19.7712C4.32343 19.5835 4.47559 19.4314 4.6633 19.4314H5.1731C5.3608 19.4314 5.51297 19.5835 5.51297 19.7712C5.51297 19.9589 5.3608 20.1111 5.1731 20.1111H4.6633C4.47559 20.1111 4.32343 19.9589 4.32343 19.7712Z"
            fill="#7C878E"
          />
          <path
            d="M21.5718 16.7124V10H17.8332V16.7124H17.5783C17.3064 16.7124 17.1818 16.939 17.1535 17.0523V17.5621C17.1535 17.902 17.6066 17.9303 17.8332 17.902V20.451H17.5783C17.2385 20.451 17.1535 20.7908 17.1535 20.9608V22.6601C17.2215 22.932 17.465 23 17.5783 23H18.2581C18.394 23 18.4846 22.8301 18.513 22.7451L18.9378 21.7255H25.9446L24.7156 24.0196L25.0554 24.1895L26.6981 21.7255H31.7395L33.3822 24.1895L33.7221 24.0196L32.4931 21.7255H37.8005L38.1404 22.7451C38.2084 22.949 38.3953 23 38.4803 23H39.16C39.4319 23 39.4999 22.7168 39.4999 22.5752V17.5621H21.9966V17.0523C21.9966 16.7804 21.7134 16.7124 21.5718 16.7124Z"
            fill="#7C878E"
          />
        </g>
        <defs>
          <clipPath id="clip0_355_58013">
            <rect width="39" height="39" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
