export default [
  {
    value: 1,
    text: '汐止廠',
  },
  {
    value: 2,
    text: '高雄廠',
  },
  {
    value: 3,
    text: '高屏廠',
  },
  {
    value: 4,
    text: '花蓮廠',
  },
];
