import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';
import TestDetailDialog from '@Src/_basic/components/YatungTest/testDetail/TestDetailDialog';
import DetailIcon from '@Src/_basic/icons/Detail';
import { Record } from '@Src/_basic/object/TestType';
import { useApi } from '@Src/redux/api/apiAction';
import { Checkbox, Typography } from '@mui/material';
import moment from 'moment';
import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: Array<Record>;
  checkReportHandler: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  nextStep: boolean;
  checkedReports: Array<Record>;
}

const CheckReportTable = ({ data, checkReportHandler, nextStep, checkedReports }: Props) => {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const [open, setOpen] = useState(false);
  const [recordDetail, setRecordDetail] = useState<Record>();

  const openHandler = useCallback((row: Record) => {
    setRecordDetail(row);
    setOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
    setRecordDetail(undefined);
  }, []);

  return (
    <>
      <YatungTable
        loading={actionLoading}
        data={data}
        sx={{
          '&::-webkit-scrollbar': {
            width: 12,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#BB5E00',
            borderRadius: 8,
          },
        }}
        columns={[
          {
            text: '',
            dataField: 'id',
            formatter: ({ row }) => (
              <Checkbox
                id={row?.id.toString()}
                onChange={checkReportHandler}
                checked={checkedReports.some((item) => item.id === row?.id)}
                disabled={actionLoading || (nextStep && !checkedReports.some((item) => item.id === row?.id))}
              />
            ),
          },
          {
            text: i18T('REPORT.SIEVE_REPORT.TABLE.COMPLETE_DATE'),
            dataField: 'completeTime',
            width: '20%',
            formatter: ({ row }) => <Typography>{moment(row?.completeTime).format('YYYY/MM/DD HH:mm')}</Typography>,
          },
          {
            text: i18T('REPORT.SIEVE_REPORT.TABLE.SPECIFICATION'),
            dataField: 'specificationName',
          },
          {
            text: i18T('REPORT.SIEVE_REPORT.TABLE.SOURCE'),
            dataField: 'sourceName',
          },
          {
            text: i18T('REPORT.SIEVE_REPORT.TABLE.SUPPLIER'),
            dataField: 'supplierName',
          },
          {
            text: i18T('REPORT.SIEVE_REPORT.TABLE.STORAGE_NAME'),
            dataField: 'storageName',
            width: '15%',
          },
          {
            text: i18T('REPORT.SIEVE_REPORT.TABLE.TESTER'),
            dataField: 'operatorName',
          },
          {
            text: i18T('REPORT.SIEVE_REPORT.TABLE.SAMPLE_PLACE'),
            dataField: 'sampleSourceName',
          },
          {
            text: i18T('GLOBAL.ACTION'),
            dataField: 'action',
            width: '10%',
            formatter: ({ row }) => {
              return (
                <YatungButton
                  text={i18T('GLOBAL.DETAIL')}
                  startIcon={<DetailIcon />}
                  width={90}
                  onClick={() => openHandler(row)}
                  color="yellow"
                />
              );
            },
          },
        ]}
      />
      {recordDetail && (
        <TestDetailDialog
          exptItemCode={recordDetail.exptItemCode}
          exptId={recordDetail.id}
          open={open}
          closeHandler={closeHandler}
        />
      )}
    </>
  );
};

export default memo(CheckReportTable);
