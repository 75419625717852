import React from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import { ProportionStepEnum } from '@Src/_basic/object/ProductionHistoryType';

const Button = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#BB5E00',
  userSelect: 'none',
  gap: '8px',
});

const ButtonLabel = styled(Typography)({
  color: '#697EA3',
  fontFamily: 'Microsoft JhengHei',
  fontWeight: 400,
  size: '20px',
});

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

interface Props {
  onCreateItemButtonClick?: () => void;
}

export default function SelectStepCard(props: Props) {
  const handleCreateItemButtonClick = () => {
    props.onCreateItemButtonClick?.();
  };

  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      <Box sx={{ opacity: 0.2 }}>
        <Title>使用配比</Title>
      </Box>
      <Stack
        justifyContent="center"
        alignItems="center"
        gap="92px"
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          height: '100%',
          width: '100%',
        }}
      >
        <Button onClick={handleCreateItemButtonClick}>
          <AddCircleFill />
          <ButtonLabel>新增配比</ButtonLabel>
        </Button>
      </Stack>
    </Box>
  );
}
