import { TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import SpecimenDataRows from '@Src/_basic/components/YatungTest/SpecimenDataRows';
import Tab from '@Src/_basic/components/YatungTest/Tab';
import TestFormHeader from '@Src/_basic/components/YatungTest/TestFormHeader';
import { ChemType, SpecimenDetailResponse } from '@Src/_basic/object/SpecimenType';
import { AllTest } from '@Src/_basic/object/TestType';
import { SpecimenApi } from '@Src/_basic/protocol/specimen/SpecimenApi';
import { Stack } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';
import ChemAdmxPentaTable from '../ChemAdmxPentaTable';

interface Props {
  testDetail: AllTest;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

function ExportChemAdmxPentaTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const [specimenDetail, setSpecimenDetail] = useState<SpecimenDetailResponse>();
  const [chemType, setChemType] = useState<ChemType>(ChemType.G_TYPE);

  const { controlGType, standardGType, experimentalGType, controlFluidify, standardFluidify, experimentalFluidify } =
    testDetail;

  const tabList = useMemo(
    () => [
      { label: 'G-Type', value: ChemType.G_TYPE },
      { label: '流動化劑', value: ChemType.FLUIDIFIER },
    ],
    [],
  );

  useEffect(() => {
    if (chemType === ChemType.G_TYPE) {
      if (testDetail.standardGType) {
        const getSpecimenDetailController = new AbortController();
        SpecimenApi.getSpecimenDetail(
          { specimenId: testDetail.standardGType?.specimenId },
          (_data) => setSpecimenDetail(_data),
          undefined,
          undefined,
          getSpecimenDetailController.signal,
        );
      } else setSpecimenDetail(undefined);
    } else if (chemType === ChemType.FLUIDIFIER) {
      if (testDetail.standardFluidify) {
        const getSpecimenDetailController = new AbortController();
        SpecimenApi.getSpecimenDetail(
          { specimenId: testDetail.standardFluidify?.specimenId },
          (_data) => setSpecimenDetail(_data),
          undefined,
          undefined,
          getSpecimenDetailController.signal,
        );
      } else setSpecimenDetail(undefined);
    } else {
      setSpecimenDetail(undefined);
    }
  }, [testDetail, chemType]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <Tab
          list={tabList}
          selected={chemType}
          onSelect={(value) => (value === ChemType.G_TYPE || value === ChemType.FLUIDIFIER) && setChemType(value)}
        />
        {specimenDetail && (
          <TestFormHeader>
            <SpecimenDataRows data={specimenDetail} testDetail={testDetail} />
          </TestFormHeader>
        )}
      </TestCardContainer>
      <TestCardContainer>
        <ChemAdmxPentaTable
          controlGType={controlGType}
          standardGType={standardGType}
          experimentalGType={experimentalGType}
          controlFluidify={controlFluidify}
          standardFluidify={standardFluidify}
          experimentalFluidify={experimentalFluidify}
          chemType={chemType}
          testDetail={testDetail}
          afterUpdateTest={afterUpdateTest}
          canEdit={canEdit}
        />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportChemAdmxPentaTable);
