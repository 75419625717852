import { Protocol } from '../Protocol';

export class SpecimenProtocol {
  public static readonly SPECIMEN_TYPE: string = `${Protocol.YATUNG_API_ROOT}/specimen/type/all`; // 取得所有類別
  public static readonly SEARCH_SPECIMEN: string = `${Protocol.YATUNG_API_ROOT}/specimen/search`; // 搜尋試體
  public static readonly SEARCH_SPECIMEN_WITHOUT_PAGE: string = `${Protocol.YATUNG_API_ROOT}/specimen/date`; // 搜尋試體
  public static readonly SEARCH_GROUP: string = `${Protocol.YATUNG_API_ROOT}/specimen/group/detail`; // 取得試體群組資訊
  public static readonly SEARCH_CHEM_GROUP: string = `${Protocol.YATUNG_API_ROOT}/specimen/group/chem/detail`; // 取得試體群組資訊

  public static readonly NEW_CONCRETE_STR_TEST: string = `${Protocol.YATUNG_API_ROOT}/expt/conc-comp-str/V2`; // 混凝土抗壓強度試驗
  public static readonly NEW_SLAG_ACTIVITY_TEST: string = `${Protocol.YATUNG_API_ROOT}/expt/slag-activity`; // 爐石活性試驗
  public static readonly NEW_FLY_SAH_ACTIVITY_TEST: string = `${Protocol.YATUNG_API_ROOT}/expt/fly-ash-activity`; // 飛灰活性試驗
  public static readonly NEW_CHEM_STR_TEST: string = `${Protocol.YATUNG_API_ROOT}/expt/chem-admx-comp-str`; // 藥劑抗壓試驗
  public static readonly NEW_CEMENT_STR_TEST: string = `${Protocol.YATUNG_API_ROOT}/expt/cement-comp-str`; // 水泥抗壓試驗

  public static readonly CHECK_USED_IN_EXPT: string = `${Protocol.YATUNG_API_ROOT}/expt/used`; // 試體id是否已經開過試驗單
  public static readonly SPECIMEN_DETAIL: string = `${Protocol.YATUNG_API_ROOT}/specimen/detail`;
  public static readonly SPECIMEN_EXPT_SETTING: string = `${Protocol.YATUNG_API_ROOT}/specimen/expt-setting`; // 取得試體歷史開單設定
  public static readonly SPECIMEN_LATEST_EXPT: string = `${Protocol.YATUNG_API_ROOT}/specimen/latest-expt`; // 取得試體歷史開單設定

  public static readonly SPECIMEN: string = `${Protocol.YATUNG_API_ROOT}/specimen`;
}
