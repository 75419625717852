import { Response } from '@Src/_basic/object/ApiType';
import { FactoryData } from '@Src/_basic/object/FactoryType';
import { MaintPropMixerCode, MixerData, MixerOption, PostMixerData } from '@Src/_basic/object/MixerTypes';
import { SearchTypeParams } from '@Src/_basic/object/TypeType';
import { Api } from '../Api';
import { MixerProtocol } from './MixerProtocol';

export class MixerApi {
  // 取得各區廠拌合機資訊
  public static getAllMixerByAreaId(
    id: number,
    onSuccess?: (data: Array<MixerData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        areaId: id,
      },
    };
    Api.get(MixerProtocol.GET_ALL_MIXER, config, onSuccess, onFailed, onFinally);
  }
  public static getAllMixer_Mock(
    onSuccess?: (data: Array<FactoryData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(MixerProtocol.ALL_MIXER_MOCK, undefined, onSuccess, onFailed, onFinally);
  }
  //依廠取得資料
  public static getMixerDataByFactoryId(
    id: number,
    onSuccess?: (data: MixerData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        factoryId: id,
      },
    };
    Api.get(MixerProtocol.GET_MIXER_BYID, config, onSuccess, onFailed, onFinally);
  }
  //更改拌合機資料
  public static putMixerData(
    request: PostMixerData,
    onSuccess?: (data: Array<MixerData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(MixerProtocol.GET_MIXER_BYID, request, undefined, onSuccess, onFailed, onFinally);
  }
  // public static getAllMixer_Mock() {
  //   return axios.get(MixerProtocol.ALL_MIXER_MOCK).then((res) => {
  //     console.log('Response', res.data);
  //     return res.data;
  //   });
  // }

  //取得廠對應的拌合機資訊
  public static getMixerOptionsByFactoryId(
    params: SearchTypeParams,
    onSuccess?: (data: Array<any>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(MixerProtocol.GET_MIXER_OPTION, config, onSuccess, onFailed, onFinally);
  }

  //取得拌合機配比選項
  public static getMixerOptionsByProportion(
    onSuccess?: (data: Array<MaintPropMixerCode>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(MixerProtocol.GET_MIXER_PROPORTION_OPTION, undefined, onSuccess, onFailed, onFinally);
  }

  //取得廠對應的拌合機選項
  public static getMixerOptionsV2ByFactoryId(
    id: number,
    onSuccess?: (data: Array<MixerOption>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        factoryId: id,
      },
    };
    Api.get(MixerProtocol.GET_MIXER_OPTION_V2, config, onSuccess, onFailed, onFinally);
  }
}
