import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function SandIcon(props: SvgIconProps) {
  return (
    <svg width={281} height={281} viewBox="0 0 281 281" fill="none">
      <rect
        x="1.5"
        y="1.5"
        width={278}
        height={278}
        rx="8.5"
        fill="url(#paint0_linear_225_86895)"
        stroke="white"
        strokeWidth={3}
      />
      <path
        d="M171.764 62.3539C160.789 54.4272 148.012 52.1062 139.691 52.4046L139.643 52.4064H139.594C118.198 52.4064 105.5 60.9021 98.1412 71.2726C90.6768 81.7922 88.5314 94.5027 88.8747 102.945L88.9891 105.763H86.1647C65.5275 105.763 53.4042 113.942 46.4459 123.902C39.3795 134.017 37.4566 146.246 37.8849 154.373C37.9636 155.867 36.8149 157.142 35.319 157.22C33.8233 157.299 32.5467 156.152 32.468 154.658C31.9923 145.631 34.0834 132.13 41.9971 120.803C49.6851 109.798 62.7015 101.097 83.4226 100.392C83.5696 91.1965 86.1914 78.7441 93.7155 68.1406C102.127 56.2874 116.491 47.0024 139.546 46.9895C148.853 46.6665 162.845 49.2271 174.942 57.9646C186.777 66.5121 196.593 80.8284 198.543 103.533C205.957 104.359 216.104 107.223 225.233 113.446C235.465 120.421 244.415 131.628 246.699 148.741C246.898 150.223 245.854 151.586 244.369 151.783C242.885 151.981 241.521 150.939 241.323 149.457C239.268 134.068 231.312 124.149 222.175 117.92C212.956 111.635 202.532 109.121 195.851 108.738L193.445 108.6L193.299 106.198C191.936 83.6768 182.622 70.1963 171.764 62.3539Z"
        fill="white"
      />
      <path
        d="M96.9531 127.133C96.9531 130.125 94.5246 132.55 91.5288 132.55C88.533 132.55 86.1045 130.125 86.1045 127.133C86.1045 124.142 88.533 121.716 91.5288 121.716C94.5246 121.716 96.9531 124.142 96.9531 127.133Z"
        fill="white"
      />
      <path
        d="M115.396 132.55C118.391 132.55 120.82 130.125 120.82 127.133C120.82 124.142 118.391 121.716 115.396 121.716C112.4 121.716 109.971 124.142 109.971 127.133C109.971 130.125 112.4 132.55 115.396 132.55Z"
        fill="white"
      />
      <path
        d="M144.687 127.133C144.687 130.125 142.258 132.55 139.262 132.55C136.267 132.55 133.838 130.125 133.838 127.133C133.838 124.142 136.267 121.716 139.262 121.716C142.258 121.716 144.687 124.142 144.687 127.133Z"
        fill="white"
      />
      <path
        d="M163.129 132.55C166.125 132.55 168.554 130.125 168.554 127.133C168.554 124.142 166.125 121.716 163.129 121.716C160.133 121.716 157.705 124.142 157.705 127.133C157.705 130.125 160.133 132.55 163.129 132.55Z"
        fill="white"
      />
      <path
        d="M192.42 127.133C192.42 130.125 189.992 132.55 186.996 132.55C184 132.55 181.572 130.125 181.572 127.133C181.572 124.142 184 121.716 186.996 121.716C189.992 121.716 192.42 124.142 192.42 127.133Z"
        fill="white"
      />
      <path
        d="M80.6803 153.676C83.6761 153.676 86.1045 151.251 86.1045 148.259C86.1045 145.268 83.6761 142.843 80.6803 142.843C77.6845 142.843 75.256 145.268 75.256 148.259C75.256 151.251 77.6845 153.676 80.6803 153.676Z"
        fill="white"
      />
      <path
        d="M73.0863 165.052C73.0863 168.044 70.6578 170.469 67.662 170.469C64.6662 170.469 62.2377 168.044 62.2377 165.052C62.2377 162.06 64.6662 159.635 67.662 159.635C70.6578 159.635 73.0863 162.06 73.0863 165.052Z"
        fill="white"
      />
      <path
        d="M43.7952 170.469C46.791 170.469 49.2195 168.044 49.2195 165.052C49.2195 162.06 46.791 159.635 43.7952 159.635C40.7994 159.635 38.3709 162.06 38.3709 165.052C38.3709 168.044 40.7994 170.469 43.7952 170.469Z"
        fill="white"
      />
      <path
        d="M109.971 148.259C109.971 151.251 107.543 153.676 104.547 153.676C101.551 153.676 99.1228 151.251 99.1228 148.259C99.1228 145.268 101.551 142.843 104.547 142.843C107.543 142.843 109.971 145.268 109.971 148.259Z"
        fill="white"
      />
      <path
        d="M91.5288 170.469C94.5246 170.469 96.9531 168.044 96.9531 165.052C96.9531 162.06 94.5246 159.635 91.5288 159.635C88.533 159.635 86.1045 162.06 86.1045 165.052C86.1045 168.044 88.533 170.469 91.5288 170.469Z"
        fill="white"
      />
      <path
        d="M133.838 148.259C133.838 151.251 131.41 153.676 128.414 153.676C125.418 153.676 122.99 151.251 122.99 148.259C122.99 145.268 125.418 142.843 128.414 142.843C131.41 142.843 133.838 145.268 133.838 148.259Z"
        fill="white"
      />
      <path
        d="M115.396 170.469C118.391 170.469 120.82 168.044 120.82 165.052C120.82 162.06 118.391 159.635 115.396 159.635C112.4 159.635 109.971 162.06 109.971 165.052C109.971 168.044 112.4 170.469 115.396 170.469Z"
        fill="white"
      />
      <path
        d="M157.705 148.259C157.705 151.251 155.277 153.676 152.281 153.676C149.285 153.676 146.856 151.251 146.856 148.259C146.856 145.268 149.285 142.843 152.281 142.843C155.277 142.843 157.705 145.268 157.705 148.259Z"
        fill="white"
      />
      <path
        d="M139.262 170.469C142.258 170.469 144.687 168.044 144.687 165.052C144.687 162.06 142.258 159.635 139.262 159.635C136.267 159.635 133.838 162.06 133.838 165.052C133.838 168.044 136.267 170.469 139.262 170.469Z"
        fill="white"
      />
      <path
        d="M181.572 148.259C181.572 151.251 179.143 153.676 176.148 153.676C173.152 153.676 170.723 151.251 170.723 148.259C170.723 145.268 173.152 142.843 176.148 142.843C179.143 142.843 181.572 145.268 181.572 148.259Z"
        fill="white"
      />
      <path
        d="M163.129 170.469C166.125 170.469 168.554 168.044 168.554 165.052C168.554 162.06 166.125 159.635 163.129 159.635C160.133 159.635 157.705 162.06 157.705 165.052C157.705 168.044 160.133 170.469 163.129 170.469Z"
        fill="white"
      />
      <path
        d="M205.439 148.259C205.439 151.251 203.01 153.676 200.014 153.676C197.019 153.676 194.59 151.251 194.59 148.259C194.59 145.268 197.019 142.843 200.014 142.843C203.01 142.843 205.439 145.268 205.439 148.259Z"
        fill="white"
      />
      <path
        d="M186.996 170.469C189.992 170.469 192.42 168.044 192.42 165.052C192.42 162.06 189.992 159.635 186.996 159.635C184 159.635 181.572 162.06 181.572 165.052C181.572 168.044 184 170.469 186.996 170.469Z"
        fill="white"
      />
      <path
        d="M216.287 165.052C216.287 168.044 213.859 170.469 210.863 170.469C207.867 170.469 205.439 168.044 205.439 165.052C205.439 162.06 207.867 159.635 210.863 159.635C213.859 159.635 216.287 162.06 216.287 165.052Z"
        fill="white"
      />
      <path
        d="M234.73 170.469C237.726 170.469 240.154 168.044 240.154 165.052C240.154 162.06 237.726 159.635 234.73 159.635C231.734 159.635 229.305 162.06 229.305 165.052C229.305 168.044 231.734 170.469 234.73 170.469Z"
        fill="white"
      />
      <path
        d="M117.949 210.568L135.761 210.568L135.761 213.85L128.214 213.85C127.433 217.568 126.433 221.053 125.214 224.303L133.558 224.303L133.558 246.287L124.511 246.287L124.511 250.271L121.23 250.271L121.23 232.834C120.542 234.053 119.808 235.225 119.027 236.35C118.277 235.193 117.527 234.225 116.777 233.443C120.683 227.568 123.339 221.037 124.745 213.85L117.949 213.85L117.949 210.568ZM130.792 250.365C143.042 247.49 152.214 240.693 158.308 229.975L161.542 232.084C154.73 243.334 145.136 250.553 132.761 253.74C132.136 252.49 131.48 251.365 130.792 250.365ZM146.542 207.615L150.199 207.615L150.199 238.084L146.542 238.084L146.542 207.615ZM130.277 243.053L130.277 227.537L124.511 227.537L124.511 243.053L130.277 243.053ZM139.605 215.162L143.12 216.1C141.37 222.162 139.652 227.537 137.964 232.225C136.808 231.725 135.605 231.287 134.355 230.912C136.48 225.725 138.23 220.475 139.605 215.162ZM153.386 216.1L156.527 214.6C159.245 219.1 161.667 223.381 163.792 227.443L160.417 229.271C158.386 224.99 156.042 220.6 153.386 216.1Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_225_86895"
          x1="140.5"
          y1={0}
          x2="140.5"
          y2={281}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BB5E00" />
          <stop offset={1} stopColor="#1681E1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
