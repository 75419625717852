import {
  MonitorAreaData,
  MonitorCustomerData,
  MonitorFactoryData,
  MonitorOrderData,
} from '@Src/_basic/object/MonitorType';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface initialState {
  areaData: MonitorAreaData | undefined;
  factoryData: MonitorFactoryData | undefined;
  customerData: MonitorCustomerData | undefined;
  orderData: MonitorOrderData | undefined;
  date: any;
}

export const monitorSlice = createSlice({
  name: 'monitorSlice',
  initialState: {
    areaData: undefined,
    factoryData: undefined,
    customerData: undefined,
    orderData: undefined,
    date: new Date().getTime(),
  } as initialState,
  reducers: {
    setArea: (state, action: PayloadAction<MonitorAreaData>) => {
      state.areaData = action.payload;
    },
    setFactory: (state, action: PayloadAction<MonitorFactoryData>) => {
      state.factoryData = action.payload;
    },
    setCustomer: (state, action: PayloadAction<MonitorCustomerData>) => {
      state.customerData = action.payload;
    },
    setOrder: (state, action: PayloadAction<MonitorOrderData>) => {
      state.orderData = action.payload;
    },
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
  },
});
