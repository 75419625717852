import { Response } from '@Src/_basic/object/ApiType';
import {
  PostMaterialNumberRequest,
  PutMaterialNumberRequest,
  getSpecificationTypeByExptItemCodeRequire,
} from '@Src/_basic/object/MaterialNumberManageType';
import {
  GetAllSpecificationCostRequest,
  SearchChemTypeSpecificationParams,
  SearchSpecificationsParams,
  SpecificationCostResponse,
  SpecificationData,
} from '@Src/_basic/object/SpecificationType';

import { Api } from '../Api';
import { SpecificationProtocol } from './SpecificationProtocol';

export class SpecificationApi {
  // 取得所有規格
  public static getSpecifications(
    onSuccess?: (data: Array<SpecificationData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(SpecificationProtocol.ALL_SPECIFICATIONS, undefined, onSuccess, onFailed, onFinally);
  }

  public static getSpecificationsBySearch(
    params: SearchSpecificationsParams,
    onSuccess?: (data: Array<SpecificationData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(SpecificationProtocol.ALL_SPECIFICATIONS, config, onSuccess, onFailed, onFinally);
  }

  public static getAllSpecificationCost(
    params: GetAllSpecificationCostRequest,
    onSuccess?: (data: Array<SpecificationCostResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(SpecificationProtocol.ALL_SPECIFICATION_COST, config, onSuccess, onFailed, onFinally);
  }

  public static getChemTypeSpecificationBySearch(
    params: SearchChemTypeSpecificationParams,
    onSuccess?: (data: Array<SpecificationData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(SpecificationProtocol.CHEM_TYPE_SPECIFICATION, config, onSuccess, onFailed, onFinally);
  }

  // 更新規格
  public static putSpecification(
    request: PutMaterialNumberRequest,
    onSuccess?: (data: SpecificationData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(SpecificationProtocol.SPECIFICATION_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增規格
  public static postSpecification(
    request: PostMaterialNumberRequest,
    onSuccess?: (data: SpecificationData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SpecificationProtocol.SPECIFICATION_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 以試驗項目代碼取得規格
  public static getSpecificationTypeByExptItemCode(
    params: getSpecificationTypeByExptItemCodeRequire,
    onSuccess?: (data: Array<SpecificationData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(SpecificationProtocol.GET_SPECIFICATION_BY_CODE, config, onSuccess, onFailed, onFinally);
  }
}
