import YatungButton from '@Src/_basic/components/YatungButton';
import EditIcon from '@Src/_basic/icons/Edit';
import { MixerData } from '@Src/_basic/object/MixerTypes';
import { Stack } from '@mui/material';
import React from 'react';

export type ExtraData = {
  onGoToUpdatePage: (id: number) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: MixerData;
  formatExtraData: ExtraData;
}

export default function ActionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;

  return (
    <Stack direction="row">
      <YatungButton
        text={formatExtraData.getButtonText('編輯')}
        startIcon={<EditIcon />}
        color="yellow"
        onClick={() => {
          formatExtraData.onGoToUpdatePage(row.factoryId);
        }}
      />
    </Stack>
  );
}
