import {
  ApiCreateRequest,
  ApiType,
  ApiUpdateRequest,
  RightsData,
  RightsRequest,
} from '@Src/_basic/object/AccountRightType';
import { AuthSysBaseResponse } from '@Src/_basic/object/ApiType';
import { Api } from '../../../Api';
import { ApiProtocol } from './ApiProtocol';

export class ApiApi {
  // 取得API清單
  public static postGetRightsApis(
    request: RightsRequest,
    onSuccess?: (data: RightsData<ApiType>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ApiProtocol.POST_GET_RIGHTS_APIS, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得權限API
  public static postGetRightsApiById(
    id: string,
    onSuccess?: (data: RightsData<ApiType>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = { id: id };
    Api.post(ApiProtocol.POST_GET_RIGHTS_API_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增權限API
  public static postCreateRightsApis(
    requset: ApiCreateRequest,
    onSuccess?: (data: AuthSysBaseResponse<null>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ApiProtocol.POST_CREATE_RIGHTS_API, requset, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改權限API
  public static postUpdateRightsApis(
    request: ApiUpdateRequest,
    onSuccess?: (data: AuthSysBaseResponse<null>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ApiProtocol.POST_UPDATE_RIGHTS_API, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改權限API
  public static postDeleteRightsApis(
    id: string,
    onSuccess?: (data: AuthSysBaseResponse<null>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = { id: id };
    Api.post(ApiProtocol.POST_DELETE_RIGHTS_API, request, undefined, onSuccess, onFailed, onFinally);
  }
}
