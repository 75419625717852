import YatungDialog from '@Src/_basic/components/YatungDialog';
import { TextOptions } from '@Src/_basic/components/YatungTextSelect';
import YutungButtonAlert from '@Src/_basic/components/YutungButtonAlert';
import AddEquipment from '@Src/_basic/icons/AddEquipment';
import { CreateEquipmentRequest, EquipmentData } from '@Src/_basic/object/EquipmentManagementType';
import { EquipmentApi } from '@Src/_basic/protocol/equipment/EquipmentApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useEquipment } from '@Src/redux/equipment/equipmentAction';
import { Grid, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import EquipmentCreateField from './component/EquipmentCreateField';

interface Props {
  createModalOpen: boolean;
  onCreateGoBackButtonClick: () => void;
  getEquipmentData: () => void;
  userGroupsData: any;
  entityOptions: TextOptions[];
  createFactory: number | null;
  testCreateKeeper: string | null;
  createPrioedNumber: number | null;
  createPrioedUnit: string | null;
  inputNameValues: string;
  inputPeriodValues: string;
  setInputPeriodValues: (e: string) => void;
  setInputNameValues: (e: string) => void;
  setCreateFactory: (e: number) => void;
  setCreatePrioedNumber: (e: number) => void;
  setCreatePrioedUnit: (e: string) => void;
  setTestCreateKeeper: (e: string) => void;
}

const createSampleSchema = Yup.object().shape({
  name: Yup.string().required('請輸入儀器名稱'),
  propertyCode: Yup.string().required('請輸入資產編號'),
});

export default function EquipmentCreateCard(props: Props) {
  const {
    createModalOpen,
    onCreateGoBackButtonClick,
    getEquipmentData,
    userGroupsData,
    entityOptions,
    createFactory,
    testCreateKeeper,
    createPrioedNumber,
    createPrioedUnit,
    inputNameValues,
    inputPeriodValues,
    setInputPeriodValues,
    setInputNameValues,
    setCreateFactory,
    setCreatePrioedNumber,
    setCreatePrioedUnit,
    setTestCreateKeeper,
  } = props;

  const [createTimeOpen, setCreateTimeOpen] = useState<boolean>(false);

  const [equipmentTimeByIdData, setEquipmentTimeByIdData] = useState<EquipmentData | any>({
    id: 0,
    name: '',
    propertyCode: '',
    periodNumber: 0,
    periodUnit: '',
    keeper: '',
    factoryId: 0,
    needMaintenace: false,
    removed: false,
  });

  const { t: i18T } = useTranslation();

  const { uploadEquipmentReports } = useEquipment();
  const { userData } = useAuth();
  const [files, setFiles] = useState<FileList>();

  const handleSubmit = useCallback(
    (request: CreateEquipmentRequest) => {
      EquipmentApi.createEquipment(request, (data: EquipmentData) => {
        getEquipmentData();
        onCreateGoBackButtonClick();
        setEquipmentTimeByIdData(data);
        setCreateTimeOpen(true);
        if (files) {
          uploadEquipmentReports(
            {
              uploaderId: userData?.account || '',
              itemId: data.id,
            },
            files,
          );
        }
      });
    },
    [getEquipmentData, onCreateGoBackButtonClick, setEquipmentTimeByIdData, setCreateTimeOpen, uploadEquipmentReports],
  );

  const handleChange = (event: { target: { files: FileList } }) => {
    const files = event.target.files;
    if (files) {
      setFiles(files);
    }
  };

  const initialEquipmentValue: CreateEquipmentRequest = {
    name: inputNameValues,
    propertyCode: inputPeriodValues,
    lastMaintenanceTime: new Date().getTime(),
    periodNumber: createPrioedNumber,
    periodUnit: createPrioedUnit,
    keeper: testCreateKeeper,
    factoryId: createFactory,
  };

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YutungButtonAlert
        open={createTimeOpen}
        setOpen={setCreateTimeOpen}
        title={
          i18T('下次校驗時間：') +
          moment(equipmentTimeByIdData?.nextMaintenanceTime).format('YYYY/MM/DD') +
          i18T(' , ') +
          i18T('下次通知時間：') +
          moment(equipmentTimeByIdData?.notificationTime).format('YYYY/MM/DD')
        }
        type="info"
      />
      <YatungDialog
        open={createModalOpen}
        handleClose={onCreateGoBackButtonClick}
        icon={<AddEquipment sx={{ width: 40, height: 50, mr: 1, alignSelf: 'center' }} />}
        headerComponent={
          <div>
            <Stack>
              <Stack flexDirection="row" justifyContent="center">
                <AddEquipment sx={{ width: 40, height: 50, mr: 1, alignSelf: 'center' }} />
                <Stack flexDirection={'column'} justifyContent={'center'}>
                  <Typography sx={{ fontSize: '44px', pl: 1 }}>新增儀器</Typography>
                </Stack>
              </Stack>
            </Stack>
          </div>
        }
        body={
          <Formik
            initialValues={initialEquipmentValue}
            validationSchema={createSampleSchema}
            onSubmit={(request: CreateEquipmentRequest) => handleSubmit(request)}
            enableReinitialize
          >
            {() => {
              return (
                <Form>
                  <EquipmentCreateField
                    userGroupsData={userGroupsData}
                    entityOptions={entityOptions}
                    createFactory={createFactory}
                    testCreateKeeper={testCreateKeeper}
                    createPrioedNumber={createPrioedNumber}
                    createPrioedUnit={createPrioedUnit}
                    inputNameValues={inputNameValues}
                    inputPeriodValues={inputPeriodValues}
                    setInputPeriodValues={setInputPeriodValues}
                    setInputNameValues={setInputNameValues}
                    setCreateFactory={setCreateFactory}
                    setTestCreateKeeper={setTestCreateKeeper}
                    setCreatePrioedNumber={setCreatePrioedNumber}
                    setCreatePrioedUnit={setCreatePrioedUnit}
                    onCreateGoBackButtonClick={onCreateGoBackButtonClick}
                    handleChange={handleChange}
                    files={files}
                  />
                </Form>
              );
            }}
          </Formik>
        }
      />
    </Grid>
  );
}
