import axios from 'axios';
import { Pageable, Response } from '@Src/_basic/object/ApiType';
import { LegalStandardV2Protocol } from './LegalStandardV2Protocol';
import {
  CreateLegalStandardRequest,
  LegalStandardData,
  SearchLegalStandardsParams,
  SearchPageableLegalStandardsParams,
} from '@Src/_basic/object/LegalStandardType';
import { Api } from '../../Api';

export class StandardV2Api {
  public static getLegalStandardById(
    id: number,
    onSuccess?: (data: LegalStandardData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(LegalStandardV2Protocol.LEGAL_STANDARD_BY_ID, config, onSuccess, onFailed, onFinally);
  }

  public static getLegalStandardsBySearch(
    params: SearchLegalStandardsParams,
    onSuccess?: (data: Array<LegalStandardData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(LegalStandardV2Protocol.LEGAL_STANDARDS_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static getPageableLegalStandardsBySearch(
    params: SearchPageableLegalStandardsParams,
    onSuccess?: (data: Pageable<LegalStandardData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(LegalStandardV2Protocol.PAGEABLE_LEGAL_STANDARDS_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static getCancelablePageableLegalStandardsBySearch(
    params: SearchPageableLegalStandardsParams,
    onSuccess?: (data: Pageable<LegalStandardData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };

    Api.get(LegalStandardV2Protocol.PAGEABLE_LEGAL_STANDARDS_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static getActivatedLegalStandardIds(
    params: SearchLegalStandardsParams,
    onSuccess?: (data: Array<number>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(LegalStandardV2Protocol.GET_ACTIVATED_LEGAL_STANDARD_IDS, config, onSuccess, onFailed, onFinally);
  }

  public static toggleLegalStandardEnableStatusById(
    id: number,
    onSuccess?: (data: LegalStandardData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.patch(
      LegalStandardV2Protocol.TOGGLE_LEGAL_STANDARD_ENABLE_STATUS,
      null,
      config,
      onSuccess,
      onFailed,
      onFinally,
    );
  }

  public static createLegalStandard(
    request: CreateLegalStandardRequest,
    onSuccess?: (data: LegalStandardData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(LegalStandardV2Protocol.CREATE_LEGAL_STANDARD, request, undefined, onSuccess, onFailed, onFinally);
  }
}
