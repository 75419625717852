import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../Api';
import { FeedMachineStatusProtocol } from './FeedMachineStatusProtocol';

export class FeedMachineStatusApi {
  //以拌合機取得各料庫最新三筆入料單
  public static getMaterialMixer(
    params: any,
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(FeedMachineStatusProtocol.GET_MATERIAL_MIXER, config, onSuccess, onFailed, onFinally);
  }
}
