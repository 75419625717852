import React, { useState } from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import Mixers from '@Src/_basic/icons/Mixers';
import RockBig from '@Src/_basic/icons/RockBig';
import RockSmall from '@Src/_basic/icons/RockSmall';
import SandBig from '@Src/_basic/icons/SandBig';
import SandSmall from '@Src/_basic/icons/SandSmall';
import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import WaterBig from '@Src/_basic/icons/WaterBig';
import MudBigIcon from '@Src/_basic/icons/MudBigIcon';
import PotionBigIcon from '@Src/_basic/icons/PotionBigIcon';
import WaterSmall from '@Src/_basic/icons/WaterSmall';
import MudSmallIcon from '@Src/_basic/icons/MudSmallIcon';
import PotionSmallIcon from '@Src/_basic/icons/PotionSmallIcon';
import ClearExecutionModal from './ClearExecutionModal';
import { WarehouseData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import FlyAshBigIcon from '@Src/_basic/icons/FlyAshBigIcon';
import FlyAshSmallIcon from '@Src/_basic/icons/FlyAshSmallIcon';
import FurnaceStoneBigIcon from '@Src/_basic/icons/FurnaceStoneBigIcon';
import FurnaceStoneSmallIcon from '@Src/_basic/icons/FurnaceStoneSmallIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  WarehouseData: WarehouseData[];
}
export default function ClearExecutionCard(props: Props) {
  const { WarehouseData } = props;
  const { t: i18T } = useTranslation();
  const titleStyle = {
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#FFFFFF',
    textAlign: 'center',
  };
  const [clearExecutionDataById, setClearExecutionDataById] = useState<WarehouseData | undefined>({
    capacity: 0,
    code: '',
    color: 0,
    factoryId: 0,
    group: '',
    id: 0,
    isYard: false,
    maintPropStorCode: {
      code: '',
      id: 0,
      name: '',
    },
    mixerId: [],
    name: '1號機 ',
    posName: '',
    removed: false,
    sourceId: 0,
    sourceName: '',
    specificWeight: 0,
    specificationId: 0,
    specificationName: '',
    total: 0,
    typeId: 0,
    typeName: '',
    volume: 0,
  });
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const GetImageChange = ({ typeId }: any) => {
    switch (typeId) {
      case 1:
        return <SandBig sx={{ width: '64px', height: '15px' }} />;
      case 2:
        return <RockBig sx={{ width: '50px', height: '43px' }} />;
      case 3:
        return <MudBigIcon sx={{ width: '72px', height: '67px' }} />;
      //4是爐石
      case 4:
        return <FurnaceStoneBigIcon sx={{ width: '72px', height: '67px' }} />;
      //5是飛灰
      case 5:
        return <FlyAshBigIcon sx={{ width: '72px', height: '67px' }} />;
      case 6:
        return <PotionBigIcon sx={{ width: '63px', height: '64px' }} />;
      case 7:
        return <WaterBig sx={{ width: '49px', height: '61px' }} />;
      default:
        return <SandBig />;
    }
  };
  const GetSmallImageChange = ({ typeId }: any) => {
    switch (typeId) {
      case 1:
        return (
          <Box sx={{ bottom: '40px', left: '165px', position: 'absolute' }}>
            <SandSmall />
          </Box>
        );
      case 2:
        return (
          <Box sx={{ bottom: '40px', left: '170px', position: 'absolute' }}>
            <RockSmall />
          </Box>
        );
      case 3:
        return (
          <Box sx={{ bottom: '40px', left: '170px', position: 'absolute' }}>
            <MudSmallIcon />
          </Box>
        );
      case 4:
        return (
          <Box sx={{ bottom: '40px', left: '165px', position: 'absolute' }}>
            <FurnaceStoneSmallIcon />
          </Box>
        );
      case 5:
        return (
          <Box sx={{ bottom: '40px', left: '170px', position: 'absolute' }}>
            <FlyAshSmallIcon />
          </Box>
        );
      case 6:
        return (
          <Box sx={{ bottom: '40px', left: '170px', position: 'absolute' }}>
            <PotionSmallIcon />
          </Box>
        );
      case 7:
        return (
          <Box sx={{ bottom: '40px', left: '170px', position: 'absolute' }}>
            <WaterSmall />
          </Box>
        );
      default:
        return (
          <Box sx={{ bottom: '40px', left: '165px', position: 'absolute' }}>
            <SandSmall />
          </Box>
        );
    }
  };
  const handleButtonClick = (id: number) => {
    const findClearExecutionDataById = WarehouseData.find((data: WarehouseData) => data.id === id);
    setClearExecutionDataById(findClearExecutionDataById);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  return (
    <>
      <ClearExecutionModal
        clearExecutionDataById={clearExecutionDataById}
        onModalClose={handleModalClose}
        modalOpen={modalOpen}
      />
      <Stack display={'flex'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'} sx={{ mt: 1 }}>
        {WarehouseData.map((data: WarehouseData, index: number) => {
          return (
            <Stack key={data.id} sx={{ my: 1 }}>
              <Card sx={{ width: '260px', height: '275px', borderRadius: '13px' }}>
                <CardHeader
                  sx={{ color: '#ffffff', background: '#BB5E00' }}
                  title={
                    <>
                      <Grid container item xs={12} justifyContent={'center'}>
                        <Typography sx={titleStyle}>{data.name}</Typography>
                      </Grid>
                    </>
                  }
                />
                <CardContent>
                  <Box sx={{ width: '116px', height: '110px', border: '2px solid #7C878E', borderRadius: '11px' }}>
                    <Stack
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{ width: '100%', height: '100%' }}
                    >
                      <GetImageChange typeId={data.typeId} />
                    </Stack>
                  </Box>
                  <Box sx={{ textAlign: 'end', position: 'relative' }}>
                    <Mixers sx={{ width: '93px', height: '95px', mt: -7 }} />
                    <GetSmallImageChange typeId={data.typeId} />
                  </Box>
                  <Stack alignItems={'center'}>
                    <YatungButton
                      text={i18T('APPLICATIONSMANAGEMENT.CLEAR_EXECUTION.CLEAR')}
                      color="red"
                      onClick={() => handleButtonClick(data.id)}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
}
