import React from 'react';
import TotalPageTitle from '@Src/_basic/components/YatungPageTitle';
import { useTranslation } from 'react-i18next';
import FactoryDeviationBi from '../components/FactoryDeviationBi';

export default function FactoryDeviationScreen() {
  const { t: i18T } = useTranslation();
  return (
    <>
      <TotalPageTitle title={i18T('POWERBI.FACTORY_DEVIATION')} />
      <FactoryDeviationBi />
    </>
  );
}
