import YatungHistoryFilePage from '@Src/_basic/components/YatungTest/YatungHistoryFilePage';
import { useAuth } from '@Src/redux/auth/authActions';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Box, Button, Typography, styled } from '@mui/material';
import React from 'react';
import { useElementSize } from 'usehooks-ts';

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

interface Props {
  hiddenFileInputRef: React.RefObject<HTMLInputElement>;
}

export default function ViewReports({ hiddenFileInputRef }: Props) {
  const { userData } = useAuth();
  const { projectInfo, uploadReports, deleteReport } = useProductionHistory();
  const [titleRef, titleSize] = useElementSize();

  const saveTimeout = new Map(); //建立字典來查詢

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      uploadReports(
        {
          uploaderId: userData?.account || '',
          factoryId: projectInfo?.factoryId,
          orderId: projectInfo?.orderId,
        },
        files,
      );
    }
  };

  const handleDeleteButtonClick = (id: number) => {
    deleteReport(id);
  };

  const testSort = [...projectInfo.reports]; //直接使用排序會導致出錯，所以在排序之前，應該要先建立副本

  const fileSort = testSort.sort((x, y) => x.uploadFileName?.localeCompare(y.uploadFileName, 'zh-CN')); //照中文字頭排序

  return (
    <Box sx={{ height: '100%' }}>
      <Title ref={titleRef}>送審報告</Title>
      <Box sx={{ pl: '50px', mt: '16px', height: '100%' }}>
        <Box sx={{ height: `calc(100% - ${titleSize.height}px - 16px)` }}>
          <YatungHistoryFilePage
            sx={{ maxHeight: '250px', wight: '100px' }}
            body={
              <>
                <div>
                  {fileSort.map((fileUploadName, index) => {
                    const times = saveTimeout.get(fileUploadName.uploadFileName); //建立一個Map字典來記錄陣列中元素使用過幾次，進而幫fileUploadName編號，由新到舊，也能夠下載檔案

                    if (saveTimeout.get(fileUploadName.uploadFileName)) {
                      saveTimeout.set(fileUploadName.uploadFileName, times + 1);
                      return (
                        <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                          <div
                            style={{
                              display: 'flex',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              lineHeight: '40px',
                            }}
                          >
                            <Button href={fileUploadName.download}>{`${fileUploadName.uploadFileName}(${
                              times + 1
                            })`}</Button>
                          </div>
                          <Button
                            style={{
                              display: 'flex',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              lineHeight: '20px',
                            }} //讓使用者看到已存在檔案，可刪除
                            onClick={() => handleDeleteButtonClick(fileUploadName.id)}
                          >
                            X
                          </Button>
                        </div>
                      );
                    } else {
                      saveTimeout.set(fileUploadName.uploadFileName, 1);
                      return (
                        <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              lineHeight: '40px',
                            }}
                          >
                            <Button href={fileUploadName.download}>{`${fileUploadName.uploadFileName}`}</Button>
                          </div>
                          <Button
                            style={{
                              display: 'flex',
                              textAlign: 'center',
                              verticalAlign: 'middle',
                              lineHeight: '20px',
                            }}
                            onClick={() => handleDeleteButtonClick(fileUploadName.id)}
                          >
                            X
                          </Button>
                        </div>
                      );
                    }
                  })}
                </div>
              </>
            }
          />
        </Box>
      </Box>
      <input
        ref={hiddenFileInputRef}
        type="file"
        accept=".pdf"
        multiple
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </Box>
  );
}
