import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import CreateExptScheduleScreen from './screens/CreateExptScheduleScreen';
import ExptScheduleScreen from './screens/ExptScheduleScreen';
import UpdateExptScheduleScreen from './screens/UpdateExptScheduleScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/create/*',
    element: <CreateExptScheduleScreen />,
    permission: 'WD032',
  },
  {
    path: '/list/*',
    element: <ExptScheduleScreen />,
    permission: 'WD033',
  },
  {
    path: '/update/:id/*',
    element: <UpdateExptScheduleScreen />,
    permission: 'WD032',
  },
];

const ExptSchedule = () => PermissionRoute(routesConfig);

export default memo(ExptSchedule);
