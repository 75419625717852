import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Set(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26">
      <path d="M16.2165 20.5833L15.8814 18.9583C15.658 18.8681 15.4484 18.7734 15.2526 18.6745C15.0575 18.5748 14.8576 18.4528 14.6529 18.3083L13.0335 18.7958L11.9166 16.9542L13.201 15.8708C13.1638 15.6181 13.1451 15.3833 13.1451 15.1667C13.1451 14.95 13.1638 14.7153 13.201 14.4625L11.9166 13.3792L13.0335 11.5375L14.6529 12.025C14.8576 11.8806 15.0575 11.7585 15.2526 11.6588C15.4484 11.5599 15.658 11.4653 15.8814 11.375L16.2165 9.75H18.4501L18.7852 11.375C19.0085 11.4653 19.2181 11.5646 19.414 11.6729C19.609 11.7812 19.8089 11.9167 20.0137 12.0792L21.6331 11.5375L22.75 13.4333L21.4656 14.5167C21.5028 14.7333 21.5214 14.959 21.5214 15.1937C21.5214 15.4285 21.5028 15.6542 21.4656 15.8708L22.75 16.9542L21.6331 18.7958L20.0137 18.3083C19.8089 18.4528 19.609 18.5748 19.414 18.6745C19.2181 18.7734 19.0085 18.8681 18.7852 18.9583L18.4501 20.5833H16.2165ZM17.3333 17.3333C17.9476 17.3333 18.4736 17.1214 18.9114 16.6974C19.3484 16.2727 19.567 15.7625 19.567 15.1667C19.567 14.5708 19.3484 14.0606 18.9114 13.6359C18.4736 13.212 17.9476 13 17.3333 13C16.719 13 16.1934 13.212 15.7563 13.6359C15.3185 14.0606 15.0996 14.5708 15.0996 15.1667C15.0996 15.7625 15.3185 16.2727 15.7563 16.6974C16.1934 17.1214 16.719 17.3333 17.3333 17.3333Z" />
      <path d="M9.93986 11.9167L9.67182 10.6167C9.49313 10.5444 9.32545 10.4688 9.1688 10.3896C9.01274 10.3099 8.85281 10.2122 8.689 10.0967L7.39347 10.4867L6.5 9.01333L7.52749 8.14667C7.49771 7.94444 7.48282 7.75667 7.48282 7.58333C7.48282 7.41 7.49771 7.22222 7.52749 7.02L6.5 6.15333L7.39347 4.68L8.689 5.07C8.85281 4.95444 9.01274 4.8568 9.1688 4.77707C9.32545 4.69791 9.49313 4.62222 9.67182 4.55L9.93986 3.25H11.7268L11.9948 4.55C12.1735 4.62222 12.3412 4.70167 12.4979 4.78833C12.6539 4.875 12.8139 4.98333 12.9777 5.11333L14.2732 4.68L15.1667 6.19667L14.1392 7.06333C14.169 7.23667 14.1838 7.41722 14.1838 7.605C14.1838 7.79278 14.169 7.97333 14.1392 8.14667L15.1667 9.01333L14.2732 10.4867L12.9777 10.0967C12.8139 10.2122 12.6539 10.3099 12.4979 10.3896C12.3412 10.4688 12.1735 10.5444 11.9948 10.6167L11.7268 11.9167H9.93986ZM10.8333 9.31667C11.3247 9.31667 11.7456 9.14709 12.0958 8.80793C12.4455 8.4682 12.6203 8.06 12.6203 7.58333C12.6203 7.10667 12.4455 6.69847 12.0958 6.35873C11.7456 6.01958 11.3247 5.85 10.8333 5.85C10.3419 5.85 9.9214 6.01958 9.57175 6.35873C9.22151 6.69847 9.04639 7.10667 9.04639 7.58333C9.04639 8.06 9.22151 8.4682 9.57175 8.80793C9.9214 9.14709 10.3419 9.31667 10.8333 9.31667Z" />
      <path d="M7.4549 20.5833L7.25387 19.6083C7.11985 19.5541 6.99409 19.4973 6.8766 19.438C6.75955 19.3782 6.6396 19.3049 6.51675 19.2183L5.5451 19.5108L4.875 18.4058L5.64562 17.7558C5.62328 17.6041 5.61211 17.4633 5.61211 17.3333C5.61211 17.2033 5.62328 17.0624 5.64562 16.9108L4.875 16.2608L5.5451 15.1558L6.51675 15.4483C6.6396 15.3616 6.75955 15.2884 6.8766 15.2286C6.99409 15.1692 7.11985 15.1124 7.25387 15.0583L7.4549 14.0833H8.7951L8.99613 15.0583C9.13015 15.1124 9.25591 15.172 9.3734 15.237C9.49045 15.302 9.61039 15.3833 9.73325 15.4808L10.7049 15.1558L11.375 16.2933L10.6044 16.9433C10.6267 17.0733 10.6379 17.2087 10.6379 17.3495C10.6379 17.4903 10.6267 17.6258 10.6044 17.7558L11.375 18.4058L10.7049 19.5108L9.73325 19.2183C9.61039 19.3049 9.49045 19.3782 9.3734 19.438C9.25591 19.4973 9.13015 19.5541 8.99613 19.6083L8.7951 20.5833H7.4549ZM8.125 18.6333C8.49356 18.6333 8.80917 18.5061 9.07185 18.2517C9.33409 17.9969 9.46521 17.6908 9.46521 17.3333C9.46521 16.9758 9.33409 16.6696 9.07185 16.4148C8.80917 16.1604 8.49356 16.0333 8.125 16.0333C7.75644 16.0333 7.44105 16.1604 7.17881 16.4148C6.91613 16.6696 6.78479 16.9758 6.78479 17.3333C6.78479 17.6908 6.91613 17.9969 7.17881 18.2517C7.44105 18.5061 7.75644 18.6333 8.125 18.6333Z" />
    </SvgIcon>
  );
}
