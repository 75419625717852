import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Warning(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M19.8,21.5H4.2c-1,0-1.9-0.5-2.4-1.4c-0.5-0.9-0.5-1.9,0-2.7L9.6,3.9C10.1,3.1,11,2.5,12,2.5c1,0,1.9,0.5,2.4,1.4l7.8,13.4c0.5,0.9,0.5,1.9,0,2.7C21.6,20.9,20.7,21.5,19.8,21.5z M10.5,4.4L2.7,17.8c-0.3,0.5-0.3,1.2,0,1.7c0.3,0.5,0.9,0.9,1.5,0.9h15.5c0.6,0,1.2-0.3,1.5-0.9c0.3-0.5,0.3-1.2,0-1.7L13.5,4.4c-0.3-0.5-0.9-0.9-1.5-0.9C11.4,3.5,10.8,3.9,10.5,4.4L10.5,4.4z" />
      <path d="M11.1,6.5c-0.3,0-0.6,0.3-0.6,0.6v8c0,0.4,0.3,0.6,0.6,0.6h1.4c0.3,0,0.6-0.3,0.6-0.6v-8c0-0.4-0.3-0.6-0.6-0.6H11.1z" />
      <path d="M11.1,16.3c-0.3,0-0.6,0.3-0.6,0.6v1.4c0,0.4,0.3,0.6,0.6,0.6h1.4c0.3,0,0.6-0.3,0.6-0.6v-1.4c0-0.2-0.1-0.4-0.3-0.5c-0.1-0.1-0.2-0.1-0.4-0.1H11.1z" />
    </SvgIcon>
  );
}
