import { SpecimenDetailResponse } from '@Src/_basic/object/SpecimenType';
import { AllTest } from '@Src/_basic/object/TestType';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ColFieldInfo from './ColFieldInfo';

interface Props {
  data: SpecimenDetailResponse;
  testDetail: AllTest;
}

function SpecimenDataRows({ data, testDetail }: Props) {
  const { t: i18T } = useTranslation();

  return (
    <Stack flexDirection="column">
      <Stack flexDirection="row">
        {!!data?.createTime && (
          <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.SAMPLING_TIME`)}>
            {moment(data?.createTime).format('yyyy/MM/DD HH:mm')}
          </ColFieldInfo>
        )}
        {!!testDetail?.completeTime && (
          <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.TEST_COMPLETE_TIME`)}>
            {moment(testDetail?.completeTime).format('yyyy/MM/DD HH:mm')}
          </ColFieldInfo>
        )}
      </Stack>
      <Stack flexDirection="row" flexWrap="wrap" margin="3px 0">
        {!!data?.areaName && (
          <ColFieldInfo title={`${i18T('TEST.SPECIMEN_DATA.TEST_FACTORY')}`}>{`${data.areaName}`}</ColFieldInfo>
        )}
        {!!data?.factoryName && (
          <ColFieldInfo title={`${i18T('TEST.SPECIMEN_DATA.FACTORY_TO_TEST')}`}>{`${data.factoryName}`}</ColFieldInfo>
        )}
        {!!data?.chemType && (
          <ColFieldInfo title={`${i18T('TEST.SPECIMEN_DATA.CHEM_TYPE')}`}>{`${data.chemType}`}</ColFieldInfo>
        )}
        {!!data?.specificationName && (
          <ColFieldInfo
            title={`${i18T('TEST.SPECIMEN_DATA.SPECIFICATION')}`}
          >{`${data.specificationName}`}</ColFieldInfo>
        )}
        {!!data?.sourceName && (
          <ColFieldInfo title={`${i18T('TEST.SPECIMEN_DATA.SOURCE')}`}>{`${data.sourceName}`}</ColFieldInfo>
        )}
        {!!data?.supplierName && (
          <ColFieldInfo title={`${i18T('TEST.SPECIMEN_DATA.SUPPLIER')}`}>{`${data.supplierName}`}</ColFieldInfo>
        )}
      </Stack>
    </Stack>
  );
}

export default memo(SpecimenDataRows);
