import React from 'react';
import { Stack } from '@mui/material';
import YatungButton from '@Src/_basic/components/YatungButton';
import EditIcon from '@Src/_basic/icons/Edit';
import { UnitData } from '@Src/_basic/object/WeightUnitManageType';

export type ExtraData = {
  onEdit: (id: number) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: UnitData;
  formatExtraData: ExtraData;
}

export default function ActionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;
  return (
    <Stack direction="row" spacing={1}>
      <YatungButton
        text={formatExtraData.getButtonText('編輯')}
        startIcon={<EditIcon />}
        color="yellow"
        onClick={() => {
          formatExtraData.onEdit(row.id);
        }}
      />
    </Stack>
  );
}
