import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PersonAdd(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M15.3087 5.63349C15.3087 8.0943 13.3111 10.0903 10.8455 10.0903C8.37997 10.0903 6.3823 8.0943 6.3823 5.63349C6.3823 3.17269 8.37997 1.1767 10.8455 1.1767C13.3111 1.1767 15.3087 3.17269 15.3087 5.63349ZM16.9705 21.3236C16.9705 21.901 17.0977 22.4492 17.3256 22.9412H0.502564C0.500857 22.8633 0.5 22.7852 0.5 22.7069C0.5 16.9002 5.21355 12.1918 11.0293 12.1918C14.9562 12.1918 18.3816 14.3385 20.1919 17.5222C18.3644 17.8235 16.9705 19.4108 16.9705 21.3236Z"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1765 24.3233C22.7359 24.3233 24 23.0592 24 21.4998C24 19.9404 22.7359 18.6763 21.1765 18.6763C19.6172 18.6763 18.353 19.9404 18.353 21.4998C18.353 23.0592 19.6172 24.3233 21.1765 24.3233ZM21.1177 19.0293C21.2476 19.0293 21.353 19.1346 21.353 19.2646V21.2057H23.2941C23.4241 21.2057 23.5294 21.3111 23.5294 21.441C23.5294 21.571 23.4241 21.6763 23.2941 21.6763H21.353V23.6175C21.353 23.7474 21.2476 23.8528 21.1177 23.8528C20.9877 23.8528 20.8824 23.7474 20.8824 23.6175V21.6763H18.9412C18.8113 21.6763 18.7059 21.571 18.7059 21.441C18.7059 21.3111 18.8113 21.2057 18.9412 21.2057H20.8824V19.2646C20.8824 19.1346 20.9877 19.0293 21.1177 19.0293Z"
        fill="white"
      />
      
    </SvgIcon>
  );
}
