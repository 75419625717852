import MockAdapter from 'axios-mock-adapter/types';
import userTableMock from './userTableMock';

import { AppPermissionProtocol } from '@Basic/protocol/appPermission/AppPermissionProtocol';

export default function mockDetails(mock: MockAdapter) {
  // mock.onPost(AppPermissionProtocol.SEND_APPLY_EMAIL).reply(({ data }) => {
  //     const { email } = JSON.parse(data);
  //     if (email) {
  //       const user = userTableMock.find(
  //         x => x.email === email
  //       );
  //         return [200, { ...user }];
  //     }

  //     return [400];
  //   });
  mock.onPost(AppPermissionProtocol.DELETE_APPLY).reply(({ data }) => {
    const { action } = JSON.parse(data);
    if (action) {
      const check = userTableMock.find((x) => x.action === action);
      return [200, { ...check }];
    }

    return [400];
  });
}
