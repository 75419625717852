import YatungButton from '@Src/_basic/components/YatungButton';
import Detail from '@Src/_basic/icons/Detail';
import { MonitorCustomerData } from '@Src/_basic/object/MonitorType';
import { Stack } from '@mui/material';
import React from 'react';

export type ExtraData = {
  onStop: (id: number) => void;
  onGoToUpdatePage: (id: number, customerId: number, orderId: number, orderCount: number, factoryName:string) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: MonitorCustomerData;
  formatExtraData: ExtraData;
}

export default function ActionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;
  return (
    <Stack spacing={2} direction="row">
      <YatungButton
        text={formatExtraData.getButtonText('詳細')}
        startIcon={<Detail />}
        color="yellow"
        onClick={() => {
          formatExtraData.onGoToUpdatePage(row.factoryId, row.customerId, row.orderId, row.orderCount, row.factoryName);
        }}
      />
    </Stack>
  );
}
