import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { memo } from 'react';

export interface RowFieldHeaderProps {
  style?: React.CSSProperties;
  title?: string;
  titleStyle?: React.CSSProperties;
  values?: Array<string | number>;
  valuesTextStyle?: React.CSSProperties;
  lastValue?: string;
  lastValueTestStyle?: React.CSSProperties;
}

export interface RowFieldInfoProps {
  style?: React.CSSProperties;
  title?: string;
  titleStyle?: React.CSSProperties;
  titles?: Array<string>;
  titlesStyle?: Array<React.CSSProperties>;
  values?: Array<string | number>;
  valuesTextStyle?: React.CSSProperties;
  valueTextStyle?: Array<React.CSSProperties>;
  lastValues?: Array<string>;
  lastValuesTextStyle?: Array<React.CSSProperties>;
  children?: React.ReactNode;
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '16px 0',
  borderBottom: '1px solid #e0e0e0',
});

export const Title = styled(Typography)({
  flex: 1,
  fontSize: 18,
  fontWeight: '900',
});

const Content = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const RowTextField = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
});

function RowFieldInfo({
  title,
  titleStyle,
  titles,
  titlesStyle,
  style,
  values,
  valuesTextStyle,
  valueTextStyle,
  lastValues,
  lastValuesTextStyle,
  children,
}: RowFieldInfoProps) {
  return (
    <Container style={style}>
      {title && <Title style={titleStyle}>{title}</Title>}

      {titles && (
        <Content>
          {titles.map((title, index) => (
            <Title key={index} style={titlesStyle && titlesStyle[index]}>
              {title}
            </Title>
          ))}
        </Content>
      )}

      {values && (
        <div
          style={
            values?.length > 1
              ? { flex: 2, display: 'flex', flexDirection: 'column' }
              : { flex: 1, display: 'flex', flexDirection: 'column' }
          }
        >
          <RowTextField>
            {values.map((value, index) => (
              <Title
                key={index}
                style={{
                  ...valuesTextStyle,
                  ...(valueTextStyle && valueTextStyle[index]),
                }}
              >
                {value?.toString()}
              </Title>
            ))}
          </RowTextField>
        </div>
      )}

      {lastValues && (
        <div>
          {lastValues.map((value, index) => (
            <Typography key={index} style={lastValuesTextStyle && lastValuesTextStyle[index]}>
              {value}
            </Typography>
          ))}
        </div>
      )}

      {children}
    </Container>
  );
}

export default memo(RowFieldInfo);
