import React from 'react';
import { Stack } from '@mui/material';
import YatungButton from '@Src/_basic/components/YatungButton';
import EditIcon from '@Src/_basic/icons/Edit';
import Detele from '@Src/_basic/icons/Delete';
import { SampleData } from '@Src/_basic/object/SampleLocationType';

export type ExtraData = {
  onEdit: (row: any) => void;
  onStop: (row: any) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: SampleData;
  formatExtraData: ExtraData;
}

export default function ActionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;

  return (
    <Stack direction="row" spacing={1}>
      <YatungButton
        text={formatExtraData.getButtonText('編輯')}
        startIcon={<EditIcon />}
        color="yellow"
        onClick={() => {
          formatExtraData.onEdit(row.id);
        }}
      />
      <YatungButton
        text={formatExtraData.getButtonText('刪除')}
        startIcon={<Detele sx={{ width: 20, height: 20, alignSelf: 'center' }} />}
        color={'red'}
        onClick={() => {
          formatExtraData.onStop(row.id);
        }}
      />
    </Stack>
  );
}
