import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function RadioButton(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12,22.5C6.2,22.5,1.5,17.8,1.5,12C1.5,6.2,6.2,1.5,12,1.5c5.8,0,10.5,4.7,10.5,10.5C22.5,17.8,17.8,22.5,12,22.5z M12,2.5c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S17.2,2.5,12,2.5z"/>
    </SvgIcon>
  );
}
