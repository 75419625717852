import React from 'react';
import AssignGroupManagementCard from '../components/AssignGroupManagementCard';

export default function AssignGroupManagementCardScreen() {
  return (
    <>
      <AssignGroupManagementCard />
    </>
  );
}
