import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function AppsMangement(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M9.5,22.2H4.2c-1.2,0-2.1-1-2.1-2.1V5.2c0-1.2,1-2.1,2.1-2.1h4.5c0.5-1.3,1.7-2.1,3-2.1c1.3,0,2.5,0.9,3,2.1v0h4.5c1.2,0,2.1,1,2.1,2.1v5.3h-2.1V5.2l-2.1,0v1.1c0,1.2-1,2.1-2.1,2.1H8.5c-1.2,0-2.1-1-2.1-2.1V5.2l-2.1,0v14.9h5.3V22.2zM4.2,3.9c-0.7,0-1.3,0.6-1.3,1.3v14.9c0,0.7,0.6,1.3,1.3,1.3h4.5v-0.5H3.4V4.4h3.7v1.9c0,0.7,0.6,1.3,1.3,1.3h6.4c0.7,0,1.3-0.6,1.3-1.3V4.4h3.7v5.3h0.5V5.2c0-0.7-0.6-1.3-1.3-1.3h-5l-0.2-0.5c-0.3-1-1.3-1.6-2.3-1.6S9.7,2.4,9.4,3.4L9.2,3.9H4.2z M11.7,6c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9C13.5,5.2,12.7,6,11.7,6z M11.7,3.1c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1S12.2,3.1,11.7,3.1z" />
      <path d="M17,10.4c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.2l0.5,1.5c0.2,0.1,0.4,0.2,0.6,0.3l1.5-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0.1,0.2,0.2l1.2,2.1c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.2l-1,1.2c0,0.2,0,0.4,0,0.7l1,1.2c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.3l-1.2,2.1c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.3,0l-1.5-0.3c-0.2,0.1-0.4,0.2-0.6,0.3l-0.5,1.5c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.2,0.1h-2.3c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.5-1.5c-0.2-0.1-0.4-0.2-0.6-0.3l-1.5,0.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1-0.1-0.2-0.2l-1.2-2.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.2l1-1.2c0-0.2,0-0.4,0-0.7l-1-1.2c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2,0.1-0.3l1.2-2.1c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2,0,0.3,0l1.5,0.3c0.2-0.1,0.4-0.2,0.6-0.3l0.5-1.5c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.2-0.1H17L17,10.4z M16.7,11.3H15l-0.5,1.5L14.2,13c-0.2,0.1-0.3,0.2-0.5,0.3l-0.3,0.2l-1.5-0.3l-0.9,1.5l1,1.2l0,0.4c0,0.2,0,0.4,0,0.5l0,0.4l-1,1.2l0.9,1.5l1.5-0.3l0.3,0.2c0.1,0.1,0.3,0.2,0.5,0.3l0.3,0.2l0.5,1.5h1.7l0.5-1.5l0.3-0.2c0.2-0.1,0.3-0.2,0.5-0.3l0.3-0.2l1.5,0.3l0.9-1.5l-1-1.2l0-0.4c0-0.2,0-0.4,0-0.5l0-0.4l1-1.2l-0.9-1.5l-1.5,0.3L18,13.3c-0.1-0.1-0.3-0.2-0.5-0.3l-0.3-0.2L16.7,11.3L16.7,11.3z M15.9,13.9c0.7,0,1.3,0.3,1.8,0.8c0.5,0.5,0.7,1.1,0.7,1.8c0,0.7-0.3,1.4-0.7,1.8c-0.5,0.5-1.1,0.8-1.8,0.8c-0.7,0-1.3-0.3-1.8-0.8c-0.5-0.5-0.7-1.1-0.7-1.8c0-0.7,0.3-1.4,0.7-1.8C14.6,14.2,15.2,13.9,15.9,13.9z M15.9,14.8c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2C16.8,15,16.3,14.8,15.9,14.8z" />
    </SvgIcon>
  );
}
