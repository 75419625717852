import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useCallback, useEffect, useState } from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import EditIcon from '@Src/_basic/icons/Edit';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungLangTable from '@Src/_basic/components/YatungLangTable';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import Detele from '@Src/_basic/icons/Delete';
import LangDownload from '@Src/_basic/icons/LangDownload';
import LangUpload from '@Src/_basic/icons/LangUpload';
import LangWarning from '@Src/_basic/icons/LangWarning';
import { GetMultilingualFileData, LangData, MultilingualFileData } from '@Src/_basic/object/MultilingualManagementType';
import { LanguageApi } from '@Src/_basic/protocol/languageManagement/LanguageApi';
import { useApi } from '@Src/redux/api/apiAction';
import { Button, SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AddLangModal from './AddLang/AddLangModal';
import EditLangModal from './Update/EditLangModal';
import UploadLangFileModel from './UploadLang/UploadLangFileModel';

interface Props {
  langOptions: Options[];
  setLangData: (e: number) => void;
  langData: number;
  langName: string;
  refreshOptionData: () => void;
  value: number;
}

export default function AppLangPanel(props: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { langOptions, setLangData, langData, langName, refreshOptionData, value } = props;
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); //新增語系 
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); //編輯語系名稱
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); //上傳語系檔案
  const [selectedFileData, setSelectedFileData] = useState<MultilingualFileData[]>([]); //選擇的語系檔案
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false); //警示視窗
  const [alertText, setAlertText] = useState<string>('');
  const [langByIdData, setLangByIdData] = useState<LangData | any>({
    id: 0,
    name: '',
  });
  const [inputNameValues, setInputNameValues] = useState<string>('');
  const [idValue, setIdvalue] = useState<number>();
  const [alertType, setAlertType] = useState<'alert' | 'confirm'>('alert');

  //重新整理
  const refreshFileData = () => {
    const params: GetMultilingualFileData = {
      platformId: 2,
      languageId: langData,
    };
    LanguageApi.getAllMultilingualFileData(
      params,
      (datas: MultilingualFileData[]) => {
        setSelectedFileData(datas);
      },
      undefined,
      () => {},
    );
  };

  const handleUpdateButtonClick = (id: number) => {
    setIsEditModalOpen(true);
    const selectedById = langOptions?.find((i) => i.value === id);
    setLangByIdData(selectedById);
  };

  const handleUpdateGoBackButtonClick = () => {
    setIsEditModalOpen(false);
    refreshOptionData();
    refreshFileData();
    setLangByIdData({
      id: 0,
      name: '',
      removed: false,
      authId: '',
      testName: '',
      station: [],
    });
  };

  const handleAddGoBackButtonClick = () => {
    setIsAddModalOpen(false);
    setInputNameValues('');
  };

  const handleUploadGoBackButtonClick = () => {
    setIsUploadModalOpen(false);
  };

  const getSearchMultilingualfileData = () => {
    const params: GetMultilingualFileData = {
      platformId: 2,
      languageId: langData,
    };
    LanguageApi.getAllMultilingualFileData(
      params,
      (datas: MultilingualFileData[]) => {
        setSelectedFileData(datas);
      },
      undefined,
      () => {},
    );
  };

  const ConfirmLangAlert = useCallback(() => {
    if (langData) {
      setAlertType('confirm');
      setIsShowAlert(true);
      setAlertText(`您確定要將此語系永久刪除嗎？`);
      return;
    }
  }, [langData]);

  const DeleteLangAlert = () => {
    LanguageApi.deleteLangData(
      {
        id: langData,
      },
      (_data) => {
        refreshFileData();
        refreshOptionData();
      },
    );
  };

  const testGetFileId = () => {
    selectedFileData.map((item, index) => {
      return setIdvalue(item.id);
    });
  };

  useEffect(() => {
    getSearchMultilingualfileData();
  }, [langData]);

  useEffect(() => {
    testGetFileId();
  }, [selectedFileData]);

  return (
    <Box sx={{ border: '1px solid #6C708C', p: 1, pt: 2 }}>
      <Stack justifyContent={'space-between'} flexDirection="row">
        <Stack flexDirection="row">
          <YatungSelect
            options={langOptions}
            value={langData}
            onChange={(e: SelectChangeEvent<unknown>) => setLangData(e.target.value as number)}
            sx={{ marginRight: '10px' }}
          />
          <YatungButton
            text={i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.ADD_LANGUAGE')}
            startIcon={<AddCircleFill />}
            onClick={() => setIsAddModalOpen(true)}
            color="green"
          />
        </Stack>
        <Stack flexDirection="row">
          <YatungButton
            text={i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.EDIT_LANGUAGE')}
            startIcon={<EditIcon />}
            onClick={() => {
              handleUpdateButtonClick(langData);
            }}
            sx={{ marginRight: '10px' }}
            color="green"
            disabled={langData === 0}
          />
          <YatungButton
            text={i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.UPLOAD_LANG_FILE')}
            startIcon={<LangUpload />}
            onClick={() => setIsUploadModalOpen(true)}
            sx={{ marginRight: '10px' }}
            color="blue"
            disabled={langData === 0}
          />
          <YatungButton
            text={i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.DELETE_LANGUAGE')}
            startIcon={<Detele sx={{ width: 20, height: 20, alignSelf: 'center' }} />}
            onClick={() => ConfirmLangAlert()}
            sx={{ marginRight: '10px' }}
            color="red"
            disabled={langData === 0}
          />
        </Stack>
      </Stack>
      <YatungLangTable
        loading={actionLoading}
        data={selectedFileData}
        key={value}
        columns={[
          {
            text: i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.UPLOAD_TIME'),
            dataField: 'uploadTime',
            formatter: ({ row }) => {
              return <>{moment(row.uploadTime).format('YYYY/MM/DD')}</>;
            },
          },
          {
            text: i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.FILE_NAME'),
            dataField: 'name',
            width: '480px',
            formatter: ({ row }) => {
              return <>{row.name}</>;
            },
          },
          {
            text: i18T(''),
            dataField: 'action',
            formatter: ({ row }) => {
              return (
                <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                  {row.name === 'WEB_default.json' ? (
                    <Stack sx={{ textAlign: 'center', lineHeight: '40px' }}>此檔案為原始語系檔。</Stack>
                  ) : (
                    <Stack sx={{ textAlign: 'center', lineHeight: '40px' }}></Stack>
                  )}
                  <div style={{ display: 'flex', textAlign: 'center', verticalAlign: 'middle', lineHeight: '40px' }}>
                    <Button
                      href={row.download}
                      startIcon={<LangDownload />}
                      style={{
                        padding: '4px 8px',
                        borderRadius: 8,
                        lineHeight: '1.4375rem',
                        fontSize: '20px',
                        color: '#fff',
                        background: '#00D03A',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                      }}
                    >
                      {i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.DOWNLOAD')}
                    </Button>
                  </div>
                </Stack>
              );
            },
          },
        ]}
      />
      <AddLangModal
        open={isAddModalOpen}
        handleClose={() => setIsAddModalOpen(false)}
        handleAddGoBackButtonClick={handleAddGoBackButtonClick}
        inputNameValues={inputNameValues}
        setInputNameValues={setInputNameValues}
        refreshOptionData={refreshOptionData}
        platformName={'app'}
        icon={<AddCircleFill style={{ width: '50px', height: '50px' }} />}
        title={i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.ADD_LANGUAGE')}
      />
      <EditLangModal
        open={isEditModalOpen}
        langByIdData={langByIdData}
        langName={langName}
        langData={langData}
        handleClose={() => setIsEditModalOpen(false)}
        handleUpdateGoBackButtonClick={handleUpdateGoBackButtonClick}
        icon={<EditIcon style={{ width: '50px', height: '50px' }} />}
        title={i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.EDIT_LANGUAGE')}
      />
      <UploadLangFileModel
        open={isUploadModalOpen}
        langName={langName}
        idValue={idValue}
        handleClose={() => setIsUploadModalOpen(false)}
        handleUploadGoBackButtonClick={handleUploadGoBackButtonClick}
        platformName={'app'}
        icon={<LangUpload style={{ width: '60px', height: '50px' }} />}
        title={i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.UPLOAD_LANG_FILE')}
      />
      <YatungConfirmAlert
        isShowAlert={isShowAlert}
        alertText={alertText}
        confirmTitle="確認"
        onOk={alertType === 'alert' ? undefined : DeleteLangAlert}
        onClose={() => setIsShowAlert(false)}
        containerStyle={{ position: 'absolute', top: '-10px' }}
        showWarningIcon
        icon={<LangWarning />}
        fontStyle={{ color: 'red' }}
      />
    </Box>
  );
}
