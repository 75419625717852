import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungTabs from '@Src/_basic/components/YatungTabs';
import { WarehouseData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { FeedTroughMaintenanceApi } from '@Src/_basic/protocol/feedTroughMaintenanceManagement/FeedTroughMaintenanceApi';
import { useFeedTroughMaintenance } from '@Src/redux/feedTroughMaintenance/feedTroughMaintenanceActions';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import FeedTroughMaintenanceManagementCard from '../components/FeedTroughMaintenanceManagementCard';
import StorageYardCard from '../components/StorageYardCard';

type FilterValueType = {
  factoryId: number;
  areaId: number;
};

type AsyncStorageSavingType = FilterValueType;

export default function FeedTroughMaintenanceManagementScreen() {
  const { t: i18T } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [{ factoryId, areaId }, setQuery] = useState<FilterValueType>({ factoryId: 0, areaId: 0 });

  const {
    setFeedTroughMaintenanceData,
    setFeedTroughMaintenanceCreateDatas,
    setStorageYardeData,
    setStorageYardCreateDatas,
  } = useFeedTroughMaintenance();

  const reset = useCallback(() => {
    setFeedTroughMaintenanceData([]);
    setFeedTroughMaintenanceCreateDatas([]);
    setStorageYardeData([]);
    setStorageYardCreateDatas([]);
  }, [
    setFeedTroughMaintenanceData,
    setFeedTroughMaintenanceCreateDatas,
    setStorageYardeData,
    setStorageYardCreateDatas,
  ]);

  const mappingData = useCallback(
    (datas: WarehouseData[]) => {
      const mappedData = datas.map((data: WarehouseData) => ({
        ...data,
        state: false,
      }));
      setFeedTroughMaintenanceData(mappedData);
    },
    [setFeedTroughMaintenanceData],
  );

  const storageYardMappingData = useCallback(
    (datas: WarehouseData[]) => {
      const storageYardmappedData = datas.map((data: WarehouseData) => ({
        ...data,
        state: false,
      }));
      setStorageYardeData(storageYardmappedData);
    },
    [setStorageYardeData],
  );

  const getFeedTroughMaintenanceAndStorage = useCallback(() => {
    FeedTroughMaintenanceApi.getStorageBySearch({ factoryId, isYard: false }, mappingData);
    FeedTroughMaintenanceApi.getStorageBySearch({ factoryId, isYard: true }, storageYardMappingData);
  }, [factoryId, mappingData, storageYardMappingData]);

  const handleChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    setQuery((prev) => ({ ...prev, [field]: value }));
  };

  const handleChanges = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  useEffect(() => {
    if (areaId) {
      if (!factoryId) {
        reset();
      } else {
        getFeedTroughMaintenanceAndStorage();
        setSaveRequest({ page: location.pathname, request: { areaId, factoryId } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factoryId, areaId]);

  useEffect(() => {
    if (request?.page === location.pathname && request?.request) {
      Object.entries(request.request).forEach(([key, value]) => {
        setQuery((prev) => ({ ...prev, [key]: value }));
      });
    }
  }, [request, location.pathname]);

  useEffect(() => {
    reset();
  }, []);

  return (
    <YatungPage
      title={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.PAGE_TITLE')}
      body={
        <>
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <YatungCombinationSelect<FilterValueType>
              filterValue={{ areaId, factoryId }}
              handleChange={handleChange}
              selectOptions={['AreaAndFactory']}
            />
          </Stack>
          <YatungTabs
            tabs={[i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.MIXER'), i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.YARD')]}
            selectedIndex={tabValue}
            onChange={handleChanges}
            fontSize={20}
          />
          {tabValue === 0 && (
            <FeedTroughMaintenanceManagementCard selectedArea={areaId} selectedFactory={factoryId} groupsSize={2} />
          )}
          {tabValue === 1 && <StorageYardCard selectedArea={areaId} selectedFactory={factoryId} groupsSize={2} />}
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
