import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, Table, TableBody, TableContainer } from '@mui/material';
import React, { memo, useState } from 'react';
import {
  CollapseIconButton,
  TableHeader,
  TableHeaderLast,
  TableHeaderSub,
  TableHeaderSubTitle,
  TableHeaderTitle,
} from '../MuiStyleComponent/TestComponent';
import RowFieldInfo, { RowFieldHeaderProps, RowFieldInfoProps } from './RowFieldInfo';
import RowMultipleFieldInfo, { RowMultipleFieldInfoProps } from './RowMultipleFieldInfo';

interface Props {
  headerTextStyle?: React.CSSProperties;
  headerData?: RowFieldHeaderProps;
  rows?: Array<RowFieldInfoProps>;
  rowsMultiple?: Array<RowMultipleFieldInfoProps>;
  isShowCollapse?: boolean;
  children?: React.ReactNode;
}

function TestTable({
  headerTextStyle,
  rows,
  isShowCollapse = false,
  rowsMultiple,
  children,
  headerData: rowHeader,
}: Props) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <TableHeader>
        {rowHeader?.title && <TableHeaderTitle variant="body1">{rowHeader?.title}</TableHeaderTitle>}

        {rowHeader?.values && (
          <TableHeaderSub sx={{ flex: rowHeader?.values?.length > 1 ? 2 : 1 }}>
            {rowHeader?.values?.map((value: any, index: number) => (
              <TableHeaderSubTitle key={index}>
                <TableHeaderTitle variant="body1" sx={headerTextStyle}>
                  {value}
                </TableHeaderTitle>
                {isShowCollapse && rowHeader?.values && index >= rowHeader?.values?.length - 1 && (
                  <CollapseIconButton sx={{ height: 40, width: 40 }} onClick={() => setIsOpen((prev) => !prev)}>
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </CollapseIconButton>
                )}
              </TableHeaderSubTitle>
            ))}
          </TableHeaderSub>
        )}
        {rowHeader?.lastValue && <TableHeaderLast variant="body1">{rowHeader.lastValue}</TableHeaderLast>}
      </TableHeader>

      <Collapse in={isOpen}>
        {rows && (
          <TableContainer>
            <Table>
              <TableBody>
                {rows.map((value: any, index: number) => (
                  <RowFieldInfo key={index} {...value} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {rowsMultiple && (
          <TableContainer>
            <Table>
              <TableBody>
                {rowsMultiple.map((value: any, index: number) => (
                  <RowMultipleFieldInfo key={index} {...value} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {children}
      </Collapse>
    </>
  );
}

export default memo(TestTable);
