import { Typography } from '@mui/material';
import React from 'react';

export type ExtraData = {
  getButtonText: (status: string) => string;
};

interface Props {
  cellContent: boolean;
  formatExtraData: ExtraData;
}
export default function CompletedColumnFormatter(props: Props) {
  const { cellContent, formatExtraData } = props;
  return (
    <Typography
      sx={{
        color: cellContent ? '#00D03A' : '#CB333B',
        fontWeight: 600,
      }}
    >
      {cellContent ? formatExtraData.getButtonText('已完成') : formatExtraData.getButtonText('異常')}
    </Typography>
  );
}
