import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import YatungTabs from '@Src/_basic/components/YatungTabs';
import { GetLangData, LangData } from '@Src/_basic/object/MultilingualManagementType';
import { LanguageApi } from '@Src/_basic/protocol/languageManagement/LanguageApi';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppLangPanel from '../components/AppLangPanel';
import PosLangPanel from '../components/PosLangPanel';
import WebLangPanel from '../components/WebLangPanel';

export default function MultilingualManagementScreen() {
  const { t: i18T } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [langOptions, setLangOptions] = useState<Options[]>([]);
  const [langData, setLangData] = useState<number>(0);
  const [langName, setLangName] = useState<string>(''); //讓編輯拿取語系名稱

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue), setLangData(0);
  };

  const getLangOptions = () => {
    const params: GetLangData = {
      //當選擇的平台改變，使用者語言選項也要改變
      platformId: value + 1,
    };
    LanguageApi.getLangData(params, (datas: Array<LangData>) => {
      onGetLangOptionsSuccess(datas);
    }); //獲取語言選項
  };

  const getLangName = () => {
    const params: GetLangData = {
      //當選擇的平台改變，拿取名稱
      platformId: value + 1,
      id: langData,
    };
    LanguageApi.getLangData(params, (datas: Array<LangData>) => {
      onGetLangNameSuccess(datas);
    }); //獲取語言名稱
  };

  const onGetLangNameSuccess = useCallback((data: Array<LangData>) => {
    data.map((lang: LangData) => setLangName(lang.name));
  }, []);

  const onGetLangOptionsSuccess = useCallback((data: Array<LangData>) => {
    setLangOptions(data.map(({ id, name }: LangData) => ({ value: id, text: name })));
  }, []);

  //重新整理語言選項
  const refreshOptionData = () => {
    const params: GetLangData = {
      platformId: value + 1,
    };
    LanguageApi.getLangData(params, (datas: Array<LangData>) => {
      setLangOptions(datas.map(({ id, name }: LangData) => ({ value: id, text: name })));
    });
  };

  //重新整理語言名稱
  const refreshLangName = () => {
    const params: GetLangData = {
      //當選擇的平台改變，拿取名稱
      platformId: value + 1,
      id: langData,
    };
    LanguageApi.getLangData(params, (datas: Array<LangData>) => {
      datas.map((lang: LangData) => setLangName(lang.name));
    }); //獲取語言名稱
  };
  useEffect(() => {
    getLangOptions();
  }, [value, langData]); //當切換平台或切換語言

  useEffect(() => {
    getLangName();
  }, [langData]);

  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.TITLE')}
      body={
        <>
          <YatungTabs
            tabs={[
              i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.WEB'),
              i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.QUALITY_APP'),
              i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.MATERIAL_POS'),
            ]}
            selectedIndex={value}
            onChange={handleChange}
          />
          {value === 0 && (
            <WebLangPanel
              langOptions={langOptions}
              setLangData={setLangData}
              langData={langData}
              langName={langName}
              refreshOptionData={refreshOptionData}
              value={1}
              refreshLangName={refreshLangName}
            />
          )}
          {value === 1 && (
            <AppLangPanel
              langOptions={langOptions}
              setLangData={setLangData}
              langData={langData}
              langName={langName}
              refreshOptionData={refreshOptionData}
              value={2}
            />
          )}
          {value === 2 && (
            <PosLangPanel
              langOptions={langOptions}
              setLangData={setLangData}
              langData={langData}
              langName={langName}
              refreshOptionData={refreshOptionData}
              value={3}
            />
          )}
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
