import {
  GetUserGroupsData,
  GetUserGroupsParams,
  JWTData,
  LoginData,
  LoginRequest,
  LoginRequire,
} from '@Basic/object/AuthType';
import { AuthSysBaseResponse, Response } from '@Src/_basic/object/ApiType';
import axios from 'axios';
import { Api } from '../Api';
import { AuthProtocol } from './AuthProtocol';

axios.defaults.withCredentials = true;

export class AuthApi {
  public static login(
    require: LoginRequire,
    onSuccess?: (data: LoginData) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request: LoginRequest = {
      channelCode: 'Yatung',
      account: require.userName,
      pwd: require.password,
    };

    Api.post(AuthProtocol.LOGIN_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static getJWT(
    onSuccess?: (data: JWTData) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = { sysId: 'YatungAPPSystem' };

    Api.post(AuthProtocol.GET_JWT, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static getUserData(
    onSuccess?: (data: any) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(AuthProtocol.GET_USER_DATA, {}, undefined, onSuccess, onFailed, onFinally);
  }

  public static getUserGroupsData(
    params: GetUserGroupsParams,
    onSuccess?: (data: GetUserGroupsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(AuthProtocol.GET_USER_GROUPS_DATA, config, onSuccess, onFailed, onFinally);
  }

  public static getUserApiPermission(
    onSuccess?: (data: any) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(AuthProtocol.GET_USER_API_PERMISSION, {}, undefined, onSuccess, onFailed, onFinally);
  }
}
