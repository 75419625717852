import { Response } from '@Src/_basic/object/ApiType';
import { CreateSignatureRequest } from '@Src/_basic/object/ProductionHistoryType';
import {
  GetSignatureTemplateOrderIdParams,
  PageableSignature,
  SearchPageableSignatureParams,
  SearchSignatureParams,
  SignatureData,
  UpdateSignatureRequest,
  UpdateTemplateRequest,
} from '@Src/_basic/object/SignatureType';
import { Api } from '../Api';
import { SignatureProtocol } from './SignatureProtocol';

export class SignatureApi {
  public static getSignatureById(
    id: number,
    onSuccess?: (data: SignatureData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };

    Api.get(SignatureProtocol.SIGNATURE, config, onSuccess, onFailed, onFinally);
  }

  public static getSignaturesBySearch(
    params: SearchSignatureParams,
    onSuccess?: (data: Array<SignatureData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(SignatureProtocol.SIGNATURE_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static getPageableSignaturesBySearch(
    params: SearchPageableSignatureParams,
    onSuccess?: (data: PageableSignature) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(SignatureProtocol.PAGEABLE_SIGNATURE_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static createSignature(
    request: CreateSignatureRequest,
    onSuccess?: (data: SignatureData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SignatureProtocol.SIGNATURE, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static updateSignature(
    request: UpdateSignatureRequest,
    onSuccess?: (data: SignatureData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(SignatureProtocol.SIGNATURE, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static deleteSignature(
    id: number,
    onSuccess?: (data: SignatureData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };

    Api.delete(SignatureProtocol.SIGNATURE, config, onSuccess, onFailed, onFinally);
  }

  public static updateTemplate(request: UpdateTemplateRequest) {
    Api.put(SignatureProtocol.SIGNATURE_TEMPLATE, request);
  }

  public static getSignatureTemplateOrderId(
    params: GetSignatureTemplateOrderIdParams,
    onSuccess?: (data: { orderId: string }) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(SignatureProtocol.SIGNATURE_TEMPLATE_ORDER_ID, config, onSuccess, onFailed, onFinally);
  }
}
