import axios, { AxiosResponse } from 'axios';
import { AreaProtocol } from './AreaProtocol';
import { PostCreateDataRequest, PutUpdateDataRequest } from '@Src/_basic/object/AreaType';
import { Response } from '@Src/_basic/object/ApiType';
import { AreaData } from '@Src/_basic/object/AreaType';
import { Api } from '../Api';

export class AreaApi {
  // getAllAreas 取得所有區
  public static getAllAreas(
    onSuccess?: (data: Array<AreaData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(AreaProtocol.ALL_AREAS, undefined, onSuccess, onFailed, onFinally);
  }

  public static getAllAreaById(
    id: number,
    onSuccess?: (data: AreaData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(AreaProtocol.get_AREA_BY_ID, config, onSuccess, onFailed, onFinally);
  }

  public static postAllAreaById(
    request: PostCreateDataRequest,
    onSuccess?: (data: AreaData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(AreaProtocol.get_AREA_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static putAllAreaById(
    request: PutUpdateDataRequest,
    onSuccess?: (data: AreaData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(AreaProtocol.get_AREA_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static deleteAllAreaById(
    id: number,
    onSuccess?: (data: AreaData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(AreaProtocol.get_AREA_BY_ID, config, onSuccess, onFailed, onFinally);
  }

  public static areaManagementData() {
    return axios.get(AreaProtocol.AREA_MANAGEMENT_DATA).then((res) => {
      return res.data;
    });
  }
  public static areaManagementAddData(require: PostCreateDataRequest) {
    return axios.post(AreaProtocol.AREA_MANAGEMENT_ADD_DATA, require).then((res) => {
      return res.data;
    });
  }
  public static areaManagementUpdateData(require: any) {
    return axios.put(AreaProtocol.AREA_MANAGEMENT_UPDATE_DATA, require).then((res) => {
      return res.data;
    });
  }
  public static areaManagementDeleteData(id: number) {
    const config = {
      params: {
        id,
      },
    };

    return axios.delete(AreaProtocol.AREA_MANAGEMENT_DELETE_DATA, config).then((res) => {
      return res;
    });
  }
  public static areaManagementGetIdData(id: number) {
    const config = {
      params: {
        id,
      },
    };
    return axios.get(AreaProtocol.AREA_MANAGEMENT_GET_ID, config).then((res) => {
      return res.data;
    });
  }
}
