import ExportIcon from '@Src/_basic/icons/Export';
import MenuIcon from '@Src/_basic/icons/Menu';
import { Stack, SxProps, Typography } from '@mui/material';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderTitleIcon from '../icons/HeaderTitle';
import YatungButton from './YatungButton';

interface Props {
  title: string | undefined;
  sx?: SxProps;
  textColor?: string;
  iconColor?: string;
  actions?: JSX.Element;
  isOrder?: boolean;
  handleClick?: () => void;
  handleMonthlyReportClick?: () => void;
  isMonthlyReport?: boolean;
}

const YatungPageTitle = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      sx,
      textColor = '#6C708C',
      iconColor = '#BB5E00',
      actions,
      isOrder,
      isMonthlyReport,
      handleClick,
      handleMonthlyReportClick,
    }: Props,
    ref,
  ) => {
    const { t: i18T } = useTranslation();
    return (
      <Stack ref={ref} direction="row" spacing={2} alignItems="center">
        <Stack
          flexDirection="row"
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ flex: '1 0 0', fontSize: 36, color: iconColor, ...sx }}
        >
          <HeaderTitleIcon fontSize={'inherit'} />
          <Typography sx={{ color: textColor, fontSize: 36, ml: 3 }}>{title}</Typography>
          {isOrder && (
            <YatungButton
              onClick={handleClick}
              color="yellow"
              text={i18T('GLOBAL.MAKE_UP_ORDER')}
              startIcon={<MenuIcon />}
              sx={{ ml: 1 }}
            />
          )}
          {isMonthlyReport && (
            <YatungButton
              onClick={handleMonthlyReportClick}
              color="gray"
              text={i18T('VIEW.MONTHLY_REPORT.EXPORT')}
              startIcon={<ExportIcon />}
              sx={{ ml: 1 }}
            />
          )}
        </Stack>
        {actions}
      </Stack>
    );
  },
);

YatungPageTitle.displayName = 'YatungPageTitle';

export default YatungPageTitle;
