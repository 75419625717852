import { SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import React, { memo } from 'react';
import YatungLoading from './YatungLoading';

export type FormatterProps = {
  cellContent: any;
  row: any;
  formatExtraData: any;
};
export type Column = {
  text: string | undefined;
  dataField: string;
  titleFormatter?: (props: FormatterProps) => JSX.Element;
  formatter?: (props: FormatterProps) => JSX.Element;
  formatExtraData?: any;
  width?: string;
};
interface Props {
  data?: Array<any>;
  columns: Array<Column>;
  loading?: boolean;
  sx?: SxProps;
  verticalAlign?: `${'top' | 'center' | 'bottom'}`;
  isDashedBottom?: boolean;
  isTableMinWidth?: boolean;
  tableCellPadding?: number;
  headColor?: boolean;
}

export default memo(function YatungLangTable({
  loading,
  data,
  columns,
  sx,
  verticalAlign,
  isDashedBottom,
  isTableMinWidth = true,
  tableCellPadding,
  headColor = true,
}: Props) {
  const StyledTableRow = styled(TableRow)(({ theme }) => isDashedBottom && { borderBottom: '1px dashed #6C708C' });
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: 16,
    border: 0,
    padding: tableCellPadding ? tableCellPadding : undefined,
  }));

  return (
    <TableContainer
      sx={{
        backgroundColor: 'transparent',
        position: 'relative',
        minHeight: '50vh',
        maxHeight: 'calc(100vh - 258px)',
        ...sx,
      }}
    >
      <Table sx={{ minWidth: isTableMinWidth ? 650 : null, opacity: loading ? 0.5 : 1 }}>
        <TableHead>
          <StyledTableRow>
            {columns.map((column: Column, index) => (
              <StyledTableCell
                key={index}
                sx={{
                  color: headColor ? '#BB5E00' : null,
                  fontWeight: 900,
                  backgroundColor: 'transparent',
                  borderBottom: '1px solid #7C878E',
                  ...(column.width ? { width: column.width } : {}),
                }}
              >
                {column.titleFormatter ? (
                  <>
                    {column.titleFormatter({
                      cellContent: '',
                      row: '',
                      formatExtraData: '',
                    })}
                  </>
                ) : (
                  <>{column.text}</>
                )}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => {
            return (
              <>
                {index === 0 ? (
                  <StyledTableRow key={index}>
                    {columns.map((column: Column, indexes) => {
                      return (
                        <StyledTableCell
                          key={indexes}
                          sx={{
                            fontWeight: 400,
                            verticalAlign,
                          }}
                        >
                          {column.formatter ? (
                            <>
                              {column.formatter({
                                cellContent: row[column.dataField],
                                row,
                                formatExtraData: column.formatExtraData,
                              })}
                            </>
                          ) : (
                            <>{row[column.dataField]}</>
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                ) : (
                  <StyledTableRow
                    key={index}
                    style={{
                      backgroundImage: 'linear-gradient(to right, #7C878E, #7C878E 50%, transparent 50%)',
                      backgroundPosition: 'Top',
                      backgroundSize: '15px 1px',
                      backgroundRepeat: 'repeat-x',
                    }}
                  >
                    {columns.map((column: Column, indexes) => {
                      return (
                        <StyledTableCell
                          key={indexes}
                          sx={{
                            fontWeight: 400,
                            verticalAlign,
                          }}
                        >
                          {column.formatter ? (
                            <>
                              {column.formatter({
                                cellContent: row[column.dataField],
                                row,
                                formatExtraData: column.formatExtraData,
                              })}
                            </>
                          ) : (
                            <>{row[column.dataField]}</>
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                )}
              </>
            );
          })}
        </TableBody>
      </Table>
      {loading && <YatungLoading overlay={true} />}
    </TableContainer>
  );
});
