import {
  TestCardContainer,
  TestDetailContainer,
  TestVerifyResultStyles,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { AllTest, ExperimentResultType } from '@Src/_basic/object/TestType';
import { ImportXrfType, PutXrfRequest, Xrf } from '@Src/_basic/object/test/sandRock/XrfType';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: Xrf;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  Si: basicNumberSchema,
  Ca: basicNumberSchema,
  Al: basicNumberSchema,
  Fe: basicNumberSchema,
  Mg: basicNumberSchema,
  S: basicNumberSchema,
  P: basicNumberSchema,
});

function ExportXrfTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const initialValues = useMemo(
    () => ({
      Si: testDetail.elemSi,
      Ca: testDetail.elemCa,
      Al: testDetail.elemAl,
      Fe: testDetail.elemFe,
      Mg: testDetail.elemMg,
      S: testDetail.elemS,
      P: testDetail.elemP,
    }),
    [testDetail],
  );

  const [edit, setEdit] = useState<boolean>(false);
  const [legalStandard, setLegalStandard] = useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>(
    new Map(),
  );

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const getLegalStandardText = (key: string) => {
    if (legalStandard?.get(key)?.minValue != null && legalStandard?.get(key)?.maxValue != null) {
      return `${legalStandard?.get(key)?.minValue} ~ ${legalStandard?.get(key)?.maxValue}`;
    } else {
      if (legalStandard?.get(key)?.minValue != null) {
        return `>=${legalStandard?.get(key)?.minValue}`;
      } else if (legalStandard?.get(key)?.maxValue != null) {
        return `<=${legalStandard?.get(key)?.maxValue}`;
      } else {
        return '';
      }
    }
  };

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.XRF_ANALYSIS,
        effectiveUnixTime: testDetail.completeTime || Date.now(),
        type: 1,
      },
      (_data) => {
        const legalStandardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          legalStandardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandard(legalStandardMap);
      },
      () => undefined,
    );
  }, [testDetail]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportXrfType = {
        elemSi: checkIsNaN(+values.Si),
        elemCa: checkIsNaN(+values.Ca),
        elemAl: checkIsNaN(+values.Al),
        elemFe: checkIsNaN(+values.Fe),
        elemMg: checkIsNaN(+values.Mg),
        elemS: checkIsNaN(+values.S),
        elemP: checkIsNaN(+values.P),
      };

      TestApi.putTest<PutXrfRequest>(
        {
          exptItemCode: testDetail.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const siView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.Si}
            isError={!!(formik.touched.Si && formik.errors.Si)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('Si', e.target.value)}
          />
        );
      case false:
        return formatNumber(testDetail?.elemSi?.toFixed(2));
      default:
        break;
    }
  }, [edit, formik, testDetail?.elemSi]);

  const caView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.Ca}
            isError={!!(formik.touched.Ca && formik.errors.Ca)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('Ca', e.target.value)}
          />
        );
      case false:
        return formatNumber(testDetail?.elemCa?.toFixed(2));
      default:
        break;
    }
  }, [edit, formik, testDetail?.elemCa]);

  const alView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.Al}
            isError={!!(formik.touched.Al && formik.errors.Al)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('Al', e.target.value)}
          />
        );
      case false:
        return formatNumber(testDetail?.elemAl?.toFixed(2));
      default:
        break;
    }
  }, [edit, formik, testDetail?.elemAl]);

  const feView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.Fe}
            isError={!!(formik.touched.Fe && formik.errors.Fe)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('Fe', e.target.value)}
          />
        );
      case false:
        return formatNumber(testDetail?.elemFe?.toFixed(2));
      default:
        break;
    }
  }, [edit, formik, testDetail?.elemFe]);

  const mgView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.Mg}
            isError={!!(formik.touched.Mg && formik.errors.Mg)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('Mg', e.target.value)}
          />
        );
      case false:
        return formatNumber(testDetail?.elemMg?.toFixed(2));
      default:
        break;
    }
  }, [edit, formik, testDetail?.elemMg]);

  const sView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.S}
            isError={!!(formik.touched.S && formik.errors.S)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('S', e.target.value)}
          />
        );
      case false:
        return formatNumber(testDetail?.elemS?.toFixed(2));
      default:
        break;
    }
  }, [edit, formik, testDetail?.elemS]);

  const pView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <LabelInput
            valueWidth="150px"
            value={formik.values.P}
            isError={!!(formik.touched.P && formik.errors.P)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('P', e.target.value)}
          />
        );
      case false:
        return formatNumber(testDetail?.elemP?.toFixed(2));
      default:
        break;
    }
  }, [edit, formik, testDetail?.elemP]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <TestDetailContainer>
          <Stack flexDirection="row" gap={2} position={'absolute'} sx={{ right: 80 }}>
            <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
          </Stack>

          <ColFieldInfo
            titleStyle={TestVerifyResultStyles.resultTitle}
            title={`${i18T(`TEST.XRF_ANALYSIS.ELEMENT_SI`)}(%)`}
            childrenStyle={
              testDetail?.verifyPassedResults?.elemSi ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal
            }
          >
            {siView}
          </ColFieldInfo>
          {testDetail?.elemCa !== undefined && (
            <ColFieldInfo
              titleStyle={TestVerifyResultStyles.resultTitle}
              title={`${i18T(`TEST.XRF_ANALYSIS.ELEMENT_CA`)}(%)`}
              childrenStyle={
                testDetail.verifyPassedResults?.elemCa ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal
              }
            >
              {caView}
            </ColFieldInfo>
          )}
          {testDetail?.elemAl !== undefined && (
            <ColFieldInfo
              titleStyle={TestVerifyResultStyles.resultTitle}
              title={`${i18T(`TEST.XRF_ANALYSIS.ELEMENT_AL`)}(%)`}
              childrenStyle={
                testDetail.verifyPassedResults?.elemAl ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal
              }
            >
              {alView}
            </ColFieldInfo>
          )}
        </TestDetailContainer>

        <TestDetailContainer>
          {testDetail?.elemFe !== undefined && (
            <ColFieldInfo
              title={`${i18T(`TEST.XRF_ANALYSIS.ELEMENT_FE`)}(%)`}
              titleStyle={TestVerifyResultStyles.resultTitle}
              childrenStyle={
                testDetail.verifyPassedResults?.elemFe ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal
              }
            >
              {feView}
            </ColFieldInfo>
          )}
          {testDetail?.elemMg !== undefined && (
            <ColFieldInfo
              title={`${i18T(`TEST.XRF_ANALYSIS.ELEMENT_MG`)}(%)`}
              titleStyle={TestVerifyResultStyles.resultTitle}
              childrenStyle={
                testDetail.verifyPassedResults?.elemMg ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal
              }
            >
              {mgView}
            </ColFieldInfo>
          )}
          {testDetail?.elemS !== undefined && (
            <ColFieldInfo
              title={`${i18T(`TEST.XRF_ANALYSIS.ELEMENT_S`)}(%)`}
              titleStyle={TestVerifyResultStyles.resultTitle}
              childrenStyle={
                testDetail.verifyPassedResults?.elemS ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal
              }
            >
              {sView}
            </ColFieldInfo>
          )}
        </TestDetailContainer>

        <TestDetailContainer>
          {testDetail?.elemP !== undefined && (
            <ColFieldInfo
              titleStyle={TestVerifyResultStyles.resultTitle}
              title={`${i18T(`TEST.XRF_ANALYSIS.ELEMENT_P`)}(%)`}
              childrenStyle={
                testDetail.verifyPassedResults?.elemP ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal
              }
            >
              {pView}
            </ColFieldInfo>
          )}
          {testDetail?.elemSi !== undefined && testDetail?.elemCa !== undefined && !edit && (
            <ColFieldInfo
              titleStyle={TestVerifyResultStyles.resultTitle}
              title={i18T(`TEST.XRF_ANALYSIS.CA_SI_RATIO`)}
              childrenStyle={
                testDetail.verifyPassedResults?.caSiRatio
                  ? TestVerifyResultStyles.normal
                  : TestVerifyResultStyles.abnormal
              }
            >
              {formatNumber(testDetail.caSiRatio.toFixed(3))}
            </ColFieldInfo>
          )}
          {!edit && (
            <ColFieldInfo
              titleStyle={TestVerifyResultStyles.resultTitle}
              title={i18T(`TEST.XRF_ANALYSIS.PASS_XRF_LEGAL_STANDARD`)}
            >
              {`鈣矽比${getLegalStandardText('caSiRatio')}`}
            </ColFieldInfo>
          )}
        </TestDetailContainer>
      </TestCardContainer>
      <TestCardContainer>
        <TestTable
          headerData={{
            title: i18T(`TEST.BASE.DETECTION`),
          }}
          rows={[
            isLegalPassed && isCustomPassed && !isCustomRejected
              ? {
                  titles: verifyResultText,
                  titlesStyle: [TestVerifyResultStyles.normal],
                }
              : {
                  titles: verifyResultText,
                  titlesStyle: [
                    isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                    isCustomPassed && !isCustomRejected
                      ? TestVerifyResultStyles.normal
                      : TestVerifyResultStyles.abnormal,
                  ],
                },
          ]}
          isShowCollapse={true}
        />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportXrfTable);
