import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizeManagementData } from '@Src/_basic/object/OrganizeType';

interface initialState {
  targetId: number;
  organizeData: OrganizeManagementData | any;
  organizeUpdateData: OrganizeManagementData | any;
  isCreateModalOpen: boolean;
  isUpdateModalOpen: boolean;
  isDeleteModalOpen: boolean;
}

export const organizeSlice = createSlice({
  name: 'organizeSlice',
  initialState: {
    targetId: 0,
    isCreateModalOpen: false,
    isUpdateModalOpen: false,
    isDeleteModalOpen: false,
    organizeData: [],
    organizeUpdateData: [],
  } as initialState,
  reducers: {
    setTargetId: (state, action: PayloadAction<number>) => {
      state.targetId = action.payload;
    },
    setOrganizeData: (state, action: PayloadAction<OrganizeManagementData | any>) => {
      state.organizeData = action.payload;
    },
    setOrganizeUpdateData: (state, action: PayloadAction<OrganizeManagementData | any>) => {
      state.organizeUpdateData = action.payload;
    },
    switchCreateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isCreateModalOpen = action.payload;
      console.log('switch menu to', state.isCreateModalOpen);
    },
    switchUpdateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isUpdateModalOpen = action.payload;
      console.log('switch menu to', state.isUpdateModalOpen);
    },
    switchDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isDeleteModalOpen = action.payload;
      console.log('switch menu to', state.isDeleteModalOpen);
    },
  },
});
