import { Response } from '@Src/_basic/object/ApiType';
import {
  CreateSampleRequest,
  DeleteSampleRequest,
  SampleData,
  SearchPaginationParams,
  UpdateSampleRequest,
} from '@Src/_basic/object/SampleLocationType';
import { CreateReceiptMaterialSampleData, CreateReceiptMaterialSampleRequest } from '@Src/_basic/object/SampleType';
import axios from 'axios';
import { Api } from '../Api';
import { SampleProtocol } from './SampleProtocol';

export class SampleApi {
  public static geAllSample(
    params: SearchPaginationParams,
    onSuccess?: (data: SampleData[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    if (params.factoryId === 0) {
      const paramsTemp = { ...params, factory_id: null };
      const config = {
        headers: {
          Cancelable: true,
        },
        params: paramsTemp,
      };
      Api.get(SampleProtocol.GET_ALL_SAMPLE, config, onSuccess, onFailed, onFinally);
    } else {
      const config = {
        headers: {
          Cancelable: true,
        },
        params,
      };
      Api.get(SampleProtocol.GET_ALL_SAMPLE, config, onSuccess, onFailed, onFinally);
    }
  }

  public static CreateSample(
    request: CreateSampleRequest,
    onSuccess?: (data: SampleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SampleProtocol.CREATE_SAMPLE, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static UpdateSample(
    request: UpdateSampleRequest,
    onSuccess?: (data: SampleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(SampleProtocol.UPDATE_SAMPLE, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static DeleteSample(
    id: number,
    onSuccess?: (data: DeleteSampleRequest) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.delete(SampleProtocol.DELETE_SAMPLE, config, onSuccess, onFailed, onFinally);
  }

  public static GetSampleById(id: number) {
    const params = {
      params: {
        id: id,
      },
    };
    return axios.get(SampleProtocol.GET_SAMPLE_BY_ID, params).then((res) => {
      console.log(res);
      return res.data;
    });
  }

  public static ReceiptMaterialPostSample(
    request: CreateReceiptMaterialSampleRequest,
    onSuccess?: (data: CreateReceiptMaterialSampleData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SampleProtocol.RECEIPT_MATERIAL, request, undefined, onSuccess, onFailed, onFinally);
  }
}
