import { Protocol } from "../../../Protocol";

export class MenuProtocol {

  public static readonly POST_GET_RIGHTS_MENUS: string = `${Protocol.YATUNG_AUTH}CA011`; // 取得API群組清單
  public static readonly POST_GET_RIGHTS_MENU_BY_ID: string = `${Protocol.YATUNG_AUTH}CA023`; // 取得API群組

  public static readonly POST_CREATE_RIGHTS_MENU: string = `${Protocol.YATUNG_AUTH}CA009`; // 新增Api群組
  public static readonly POST_UPDATE_RIGHTS_MENU: string = `${Protocol.YATUNG_AUTH}CA010`; // 修改Api群組
  public static readonly POST_DELETE_RIGHTS_MENU: string = `${Protocol.YATUNG_AUTH}CA012`; // 刪除Api群組
  
}