import { ProductionHistoryContext } from '@Src/_basic/contexts/ProductionHistory';
import {
  CreateProportionFormType,
  ProportionStepEnum,
  ReplaceProportionsFormType,
} from '@Src/_basic/object/ProductionHistoryType';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import CreateItemCard from '../CreateProportionForm/components/CreateItemCard';
import EditItemCard from '../CreateProportionForm/components/EditItemCard';
import ShowItemsCard from '../CreateProportionForm/components/ShowItemsCard';

interface Props {
  createBtnRef: React.RefObject<HTMLButtonElement>;
  submitBtnRef: React.RefObject<HTMLButtonElement>;
  cancelBtnRef: React.RefObject<HTMLButtonElement>;
  onEditModeChange?: (value: boolean) => void;
  onHiddenActionBarChange?: (value: boolean) => void;
}

export default function UpdateProportionFrom(props: Props) {
  const context = useContext(ProductionHistoryContext);
  const { projectInfo, replaceProportions, createProportions } = useProductionHistory();
  const [step, setStep] = useState(ProportionStepEnum.SHOW_ITEMS);
  const [editIndex, setEditIndex] = useState<number>(0);

  const formData: ReplaceProportionsFormType = useMemo(() => {
    return {
      proportions: projectInfo.proportion,
    };
  }, [projectInfo]);

  const handleSubmit = (values: ReplaceProportionsFormType) => {
    replaceProportions({
      projectInfoId: projectInfo.projectInfoId,
      items: [],
    });

    setTimeout(() => {
      if (values.proportions.length > 0) {
        createProportions(
          values.proportions.map((item: CreateProportionFormType) => ({
            ...item,
            projectInfoId: projectInfo.projectInfoId,
          })),
        );
      }
    }, 2000);
  };

  const formik = useFormik({
    initialValues: formData,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleStepChange = (value: ProportionStepEnum) => {
    setStep(value);
    switch (value) {
      case ProportionStepEnum.CREATE_ITEM:
      case ProportionStepEnum.EDIT_ITEM:
        props.onHiddenActionBarChange?.(true);
        break;
      case ProportionStepEnum.SHOW_ITEMS:
        props.onHiddenActionBarChange?.(false);
        break;
      default:
        break;
    }
  };

  const addProportion = (values: CreateProportionFormType) => {
    const proportions = [...formik.values.proportions];
    proportions.push(values);
    formik.setFieldValue('proportions', proportions);
  };

  const removeProportion = (index: number) => {
    const proportions = [...formik.values.proportions];
    proportions.splice(index, 1);
    formik.setFieldValue('proportions', proportions);
  };

  const updateProportion = (index: number, values: CreateProportionFormType) => {
    const proportions = [...formik.values.proportions];
    proportions[index] = values;
    formik.setFieldValue('proportions', proportions);
  };

  useEffect(() => {
    if (context) {
      const selectedProportions = formik.values.proportions.flatMap((item) =>
        item.realProps.map((item) => item.proportionName),
      );
      context.setSelectedProportions(selectedProportions);
    }
  }, [formik.values]);

  return (
    <>
      {step === ProportionStepEnum.CREATE_ITEM && (
        <CreateItemCard
          onSubmitButtonClick={(value: CreateProportionFormType) => {
            addProportion(value);
            handleStepChange(ProportionStepEnum.SHOW_ITEMS);
          }}
          onCancelcelButtonClick={() => {
            handleStepChange(
              formik.values.proportions.length > 0 ? ProportionStepEnum.SHOW_ITEMS : ProportionStepEnum.SELECT_STEP,
            );
          }}
        />
      )}

      {step === ProportionStepEnum.EDIT_ITEM && (
        <EditItemCard
          editItem={formik.values.proportions[editIndex]}
          onSubmitButtonClick={(value: CreateProportionFormType) => {
            updateProportion(editIndex, value);
            handleStepChange(ProportionStepEnum.SHOW_ITEMS);
          }}
          onCancelcelButtonClick={() => {
            handleStepChange(ProportionStepEnum.SHOW_ITEMS);
          }}
        />
      )}

      {step === ProportionStepEnum.SHOW_ITEMS && (
        <ShowItemsCard
          data={formik.values.proportions}
          createBtnRef={props.createBtnRef}
          submitBtnRef={props.submitBtnRef}
          cancelBtnRef={props.cancelBtnRef}
          onCreateButtonClick={() => {
            handleStepChange(ProportionStepEnum.CREATE_ITEM);
          }}
          onSubmitButtonClick={() => {
            formik.submitForm();
          }}
          onCancelButtonClick={() => {
            formik.resetForm();
            props.onEditModeChange?.(false);
          }}
          onEditButtonClick={(index: number) => {
            setEditIndex(index);
            handleStepChange(ProportionStepEnum.EDIT_ITEM);
          }}
          onDeleteButtonClick={(index: number) => {
            removeProportion(index);
          }}
        />
      )}
    </>
  );
}
