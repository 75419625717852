import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import YatungTable from '@Src/_basic/components/YatungTable';

import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import EditIcon from '@Src/_basic/icons/Edit';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import { MaterialCodeAndTransactionResponse } from '@Src/_basic/object/MaterialCodeType';
import { TypeTransactionData } from '@Src/_basic/object/TypeTransactionType';
import { TypeTransactionApi } from '@Src/_basic/protocol/typeTransaction/TypeTransactionApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useTranslation } from 'react-i18next';
import TypeAddModal from './TypeAddModal';
import TypeEditModal from './TypeEditModal';

const transactionTypeIcon = '/media/images/icon_transactionType.png';

const ModalIcon = () => (
  <YatungImg
    img={transactionTypeIcon}
    sx={{
      width: 48,
      height: 48,
      mr: 1,
    }}
  />
);

export default function TransactionTypesPanel() {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const [types, setTypes] = useState<Array<MaterialCodeAndTransactionResponse>>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<MaterialCodeAndTransactionResponse>();

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const addType = (data: MaterialCodeAndTransactionResponse) => {
    if (data.transactionCode && data.transactionName) {
      TypeTransactionApi.postTypeTransaction(
        {
          transactionCode: data.transactionCode,
          transactionName: data.transactionName,
        },
        (_data) => setTypes((prev) => [...prev, _data]),
        (_error) => {
          setAlertText(_error.message);
          setIsShowAlert(true);
        },
      );
    }
    setIsAddModalOpen(false);
  };

  const handleTypeSelect = (type: TypeTransactionData) => {
    setSelectedType(type);
    setIsEditModalOpen(true);
  };

  const updateType = (data: MaterialCodeAndTransactionResponse) => {
    if (data.id && data.transactionCode && data.transactionName) {
      TypeTransactionApi.putTypeTransaction(
        {
          id: data.id,
          transactionCode: data.transactionCode,
          transactionName: data.transactionName,
        },
        (_data) => setTypes((prev) => prev.map((item) => (item.id === _data.id ? _data : item))),
        (_error) => {
          setAlertText(_error.message);
          setIsShowAlert(true);
        },
      );
    }
    setIsEditModalOpen(false);
  };

  useEffect(() => TypeTransactionApi.getTypeTransaction((_data) => setTypes(_data)), []);

  return (
    <Box sx={{ border: '1px solid #6C708C', p: 1, pt: 2 }}>
      <Stack justifyContent={'flex-end'} flexDirection="row">
        <YatungButton
          text={i18T('GLOBAL.CREATE')}
          startIcon={<AddCircleFill />}
          onClick={() => setIsAddModalOpen(true)}
          color="green"
        />
      </Stack>
      <YatungTable
        loading={actionLoading}
        data={types}
        columns={[
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.CODE'),
            dataField: 'transactionCode',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.NAME'),
            dataField: 'transactionName',
            width: '480px',
          },
          {
            text: i18T('GLOBAL.ACTION'),
            dataField: 'action',
            formatter: ({ row }) => {
              return (
                <YatungButton
                  text={i18T('GLOBAL.UPDATE')}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    handleTypeSelect(row);
                  }}
                  color="yellow"
                />
              );
            },
          },
        ]}
      />
      <TypeAddModal
        open={isAddModalOpen}
        handleSubmit={addType}
        handleClose={() => setIsAddModalOpen(false)}
        allTypes={types}
        icon={<ModalIcon />}
        title={i18T('APPLICATIONSMANAGEMENT.MATERIAL_CODE_MANAGE.TRADE_TYPE')}
      />
      {selectedType && (
        <TypeEditModal
          open={isEditModalOpen}
          handleSubmit={updateType}
          handleClose={() => setIsEditModalOpen(false)}
          allTypes={types}
          selectedType={selectedType}
          icon={<ModalIcon />}
          title={i18T('APPLICATIONSMANAGEMENT.MATERIAL_CODE_MANAGE.TRADE_TYPE')}
        />
      )}
      <YatungConfirmAlert isShowAlert={isShowAlert} alertText={alertText} onClose={() => setIsShowAlert(false)} />
    </Box>
  );
}
