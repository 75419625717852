import React from 'react';
import { Grid } from '@mui/material';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import { useFeedTroughMaintenance } from '@Src/redux/feedTroughMaintenance/feedTroughMaintenanceActions';
import { useTranslation } from 'react-i18next';

const deleteButtonImage = '/media/images/deleteButton.png';

const createImage = '/media/images/create.png';
interface Props {
  index: number;
}
export default function StorageYardCreateActions(props: Props) {
  const { t: i18T } = useTranslation();
  const { index } = props;
  const { storageYardCreateDatas, setStorageYardCreateDatas } = useFeedTroughMaintenance();

  const handleDeleteButton = () => {
    const newDatas = Object.assign([], storageYardCreateDatas);
    newDatas.splice(index, 1);
    setStorageYardCreateDatas(newDatas);
  };
  return (
    <Grid container justifyContent={'flex-end'} item xs={12} sx={{ mt: 2 }}>
      <YatungButton
        text={i18T('GLOBAL.CANCEL')}
        variant={'contained'}
        color="inherit"
        type={'button'}
        size={'large'}
        startIcon={<YatungImg img={deleteButtonImage} />}
        sx={{
          bgcolor: '#CB333B',
          mr: 2,
          color: '#ffffff',
          '&.MuiButtonBase-root:hover': {
            bgcolor: '#CB333B',
          },
        }}
        onClick={handleDeleteButton}
      />
      <YatungButton
        text={i18T('GLOBAL.CREATE')}
        variant={'contained'}
        color="inherit"
        type={'submit'}
        size={'large'}
        startIcon={<YatungImg img={createImage} />}
        sx={{
          bgcolor: '#00D03A',
          mr: 2,
          color: '#ffffff',
          '&.MuiButtonBase-root:hover': {
            bgcolor: '#00D03A',
          },
        }}
      />
    </Grid>
  );
}
