import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Video(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
        <path
          d="M15.4141 0.5H2.08073C1.16156 0.5 0.414062 1.2475 0.414062 2.16667V13.8333C0.414062 14.7525 1.16156 15.5 2.08073 15.5H15.4141C16.3332 15.5 17.0807 14.7525 17.0807 13.8333V2.16667C17.0807 1.2475 16.3332 0.5 15.4141 0.5ZM15.4149 5.5C15.4141 5.5 15.4141 5.5 15.4149 5.5H15.0274L12.8049 2.16667H15.4141L15.4149 5.5ZM11.6641 10.5L7.08073 13V8L11.6641 10.5ZM10.8607 5.5L8.63823 2.16667H10.8016L13.0241 5.5H10.8607ZM8.8574 5.5H6.69406L4.47156 2.16667H6.6349L8.8574 5.5ZM2.08073 2.16667H2.46823L4.69073 5.5H2.08073V2.16667Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
}
