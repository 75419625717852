import YatungButton from '@Src/_basic/components/YatungButton';
import EditIcon from '@Src/_basic/icons/Edit';
import SwitchIcon from '@Src/_basic/icons/Switch';
import { FactoryData } from '@Src/_basic/object/FactoryType';
import { Stack } from '@mui/material';
import React from 'react';

export type ExtraData = {
  onStop: (id: number) => void;
  onGoToUpdatePage: (id: number) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: FactoryData;
  formatExtraData: ExtraData;
}

export default function ActionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;
  return (
    <Stack spacing={2} direction="row">
      <YatungButton
        text={formatExtraData.getButtonText('編輯')}
        startIcon={<EditIcon />}
        color="yellow"
        onClick={() => {
          formatExtraData.onGoToUpdatePage(row.id);
        }}
      />
      <YatungButton
        text={row.removed ? formatExtraData.getButtonText('開啟') : formatExtraData.getButtonText('關閉')}
        startIcon={<SwitchIcon />}
        color={row.removed ? 'green' : 'red'}
        onClick={() => {
          formatExtraData.onStop(row.id);
        }}
      />
    </Stack>
  );
}
