import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { RequireItemList } from '@Src/_basic/object/TestInDistrictType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import CompletedColumnFormatter from './columnFormatter/CompletedColumnFormatter';
import CreateTimeColumnFormatter from './columnFormatter/CreateTimeColumnFormatter';
import CreaterNameColumnFormatter from './columnFormatter/CreaterNameColumnFormatter';
import OperatorNameColumnFormatter from './columnFormatter/OperatorNameColumnFormatter';
import ReqItemTitleColumnFormatter from './columnFormatter/ReqItemTitleColumnFormatter';

interface Props {
  loading?: boolean;
  data: Array<RequireItemList>;
}
export default function TestInDistrictByTestListTable(props: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { loading, data } = props;
  const getButtonText = (status: string) => {
    switch (status) {
      case '詳細':
        return i18T('GLOBAL.DETAIL');
      case '已完成':
        return i18T('GLOBAL.COMPLETE');
      case '未完成':
        return i18T('GLOBAL.INCOMPLETE');
    }
  };
  //   const handleGoToUpdatePage = (id: number) => {
  //     navigate(`/realTimeMonitoring/testInDistrict/testInDistrictByTestList/${id}`);
  //   };
  const columns: Array<Column> = [
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.TEST_NAME'),
      dataField: 'reqItemTitle',
      formatter: ReqItemTitleColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.CREATER_NAME'),
      dataField: 'createrName',
      formatter: CreaterNameColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.OPERATOR_NAME'),
      dataField: 'operatorName',
      formatter: OperatorNameColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.CREATE_TIME'),
      dataField: 'createTime',
      formatter: CreateTimeColumnFormatter,
    },
    {
      text: i18T('APPLICATIONSMANAGEMENT.TABLE.STATUS'),
      dataField: 'completed',
      formatter: CompletedColumnFormatter,
      formatExtraData: {
        getButtonText: getButtonText,
      },
    },
    // {
    //   text: i18T('GLOBAL.ACTION'),
    //   dataField: 'action',
    //   formatter: ActionColumnFormatter,
    //   formatExtraData: {
    //     // onGoToUpdatePage: handleGoToUpdatePage,
    //     getButtonText: getButtonText,
    //     onViewDetail: handleGoToUpdatePage,
    //   },
    // },
  ];
  return <YatungTable columns={columns} data={data} loading={loading} />;
}
