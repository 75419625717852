import { Dialog, DialogContent, DialogTitle, Stack, SxProps, TextField, Typography } from '@mui/material';
import React from 'react';
import YatungButton from './YatungButton';

interface Props {
  title?: string;
  open?: boolean;
  handleClose?: () => void;
  headerComponent?: JSX.Element;
  body?: JSX.Element;
  icon?: JSX.Element;
  sx?: SxProps;
  titleColor?: SxProps;
  postion?: SxProps;
  isMeasures?: boolean;
  action?: JSX.Element;
  isRestDay?: boolean;
  restDayDescription?: string;
  type?: 'xs' | 'sm' | 'md' | 'lg' | `xl`;
  isDelete?: boolean;
  onClick?: () => void;
  buttonText?: string;
  buttonColor?: 'blue' | 'light_red' | 'red' | 'green' | 'yellow' | 'gray' | 'inactive_blue' | 'inactive_green';
  isTextArea?: boolean;
  notes?: string;
  handleNotesInput?: (e: any) => void;
  placeHolder?: string;
}

export default function YatungDialog({
  title,
  body,
  icon,
  open,
  handleClose,
  headerComponent,
  sx,
  titleColor,
  postion,
  isMeasures,
  action,
  isRestDay,
  restDayDescription,
  type,
  isDelete,
  onClick,
  buttonText,
  buttonColor,
  isTextArea,
  notes,
  handleNotesInput,
  placeHolder,
}: Props) {
  const inputStyle = {
    border: '1px solid #ffffff',
    width: '600px',
  };
  return (
    <Dialog
      open={open ?? false}
      onClose={handleClose}
      PaperProps={{ style: { borderRadius: 10 } }}
      sx={postion}
      fullWidth
      maxWidth={type}
    >
      <DialogTitle
        sx={
          titleColor || {
            color: '#ffffff',
            background: '#BB5E00',
          }
        }
      >
        {headerComponent ? (
          headerComponent
        ) : !isMeasures ? (
          <Stack flexDirection="column">
            {isDelete && (
              <Stack flexDirection="row" justifyContent="flex-end" sx={{ fontSize: 40 }} alignItems="center">
                <YatungButton text={buttonText} color={buttonColor} onClick={onClick} />
              </Stack>
            )}
            {isTextArea ? (
              <Stack flexDirection="row" justifyContent="center" sx={{ fontSize: 40 }} alignItems="center">
                <TextField
                  multiline={true}
                  rows={1}
                  sx={inputStyle}
                  inputProps={{
                    maxLength: 20,
                    style: {
                      color: '#ffffff',
                      fontSize: 24,
                      fontFamily: 'Inter',
                      fontWeight: 400,
                    },
                  }}
                  defaultValue={notes}
                  onBlur={handleNotesInput}
                  placeholder={placeHolder}
                />
              </Stack>
            ) : (
              <Stack flexDirection="row" justifyContent="center" sx={{ fontSize: 40 }} alignItems="center">
                {icon}
                <Typography sx={{ fontWeight: 900, fontSize: 40 }}>{title}</Typography>
              </Stack>
            )}
            {isRestDay && (
              <Stack flexDirection="row" justifyContent="center" sx={{ fontSize: 40 }} alignItems="center">
                <Typography sx={{ fontWeight: 900, fontSize: 20, color: '#CB333B' }}>{restDayDescription}</Typography>
              </Stack>
            )}
          </Stack>
        ) : (
          <Stack flexDirection="row" justifyContent="space-between" sx={{ fontSize: 32 }} alignItems="center">
            <Stack flexDirection="row">
              {icon}
              <Typography sx={{ fontWeight: 900, fontSize: 32 }}>{title}</Typography>
            </Stack>
            <Stack flexDirection="row">{action}</Stack>
          </Stack>
        )}
      </DialogTitle>
      <DialogContent sx={sx}>{body}</DialogContent>
    </Dialog>
  );
}
