import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Switch(props: SvgIconProps) {
  return (
    <SvgIcon {...props} >
      <path
        d="M1.33325 10.8519L5.85805 20.9091H11.3883L25.6666 1H20.9407L9.77953 15.9831H7.86906L5.35529 10.8519H1.33325Z"
      />
    </SvgIcon>
  );
}
