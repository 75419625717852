import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function View(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9053 17.8571C15.2447 17.8571 18.7624 14.3394 18.7624 10C18.7624 5.66062 15.2447 2.14286 10.9053 2.14286C6.56589 2.14286 3.04813 5.66062 3.04813 10C3.04813 14.3394 6.56589 17.8571 10.9053 17.8571ZM10.9053 20C16.4281 20 20.9053 15.5228 20.9053 10C20.9053 4.47715 16.4281 0 10.9053 0C5.38243 0 0.905273 4.47715 0.905273 10C0.905273 15.5228 5.38243 20 10.9053 20Z"
        fill="white"
      />
      <path
        d="M14.4214 9.36962C14.0498 9.75815 13.5263 10.0001 12.9462 10.0001C11.8191 10.0001 10.9054 9.08643 10.9054 7.95932C10.9054 7.37928 11.1474 6.85575 11.5359 6.48419C11.3313 6.44774 11.1206 6.42871 10.9054 6.42871C8.93297 6.42871 7.33398 8.02769 7.33398 10.0001C7.33398 11.9726 8.93297 13.5716 10.9054 13.5716C12.8779 13.5716 14.4768 11.9726 14.4768 10.0001C14.4768 9.78499 14.4578 9.57429 14.4214 9.36962Z"
        fill="white"
      />
    </SvgIcon>
  );
}
