import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function SandAndRock(props: SvgIconProps) {
  return (
    <svg width={32} height={10} viewBox="0 0 32 10" fill="none" {...props}>
      <path
        d="M8.37892 4.38277C8.37892 4.74476 8.08543 5.03825 7.72345 5.03825C7.36146 5.03825 7.06797 4.74476 7.06797 4.38277C7.06797 4.28387 7.08973 4.1901 7.1291 4.10624C7.13486 4.09376 7.14094 4.0816 7.14766 4.06976C7.15598 4.05439 7.16463 4.03935 7.17423 4.02495C7.18671 4.00574 7.20047 3.98686 7.2152 3.96894C7.22576 3.95614 7.23664 3.94365 7.24816 3.93149C7.36754 3.80571 7.53653 3.72729 7.72345 3.72729C8.08543 3.72729 8.37892 4.02079 8.37892 4.38277Z"
        fill="white"
      />
      <path
        d="M5.42928 7.33241C5.79126 7.33241 6.08475 7.03892 6.08475 6.67694C6.08475 6.31495 5.79126 6.02146 5.42928 6.02146C5.06729 6.02146 4.7738 6.31495 4.7738 6.67694C4.7738 7.03892 5.06729 7.33241 5.42928 7.33241Z"
        fill="white"
      />
      <path
        d="M3.46285 9.62658C3.82483 9.62658 4.11833 9.33309 4.11833 8.97111C4.11833 8.60912 3.82483 8.31563 3.46285 8.31563C3.10087 8.31563 2.80737 8.60912 2.80737 8.97111C2.80737 9.33309 3.10087 9.62658 3.46285 9.62658Z"
        fill="white"
      />
      <path
        d="M10.3454 6.67694C10.3454 6.89586 10.2381 7.08949 10.0733 7.20855C9.96544 7.28633 9.83294 7.33241 9.68987 7.33241C9.32789 7.33241 9.0344 7.03892 9.0344 6.67694C9.0344 6.58284 9.05424 6.49323 9.09009 6.41225C9.13394 6.31335 9.20115 6.22726 9.285 6.16133C9.39638 6.07363 9.53689 6.02146 9.68987 6.02146C9.80349 6.02146 9.91071 6.05027 10.0039 6.10116C10.0819 6.14404 10.1504 6.20229 10.2052 6.27175C10.2436 6.32072 10.2753 6.37512 10.2986 6.4337C10.3287 6.50891 10.3454 6.59084 10.3454 6.67694Z"
        fill="white"
      />
      <path
        d="M7.72345 9.62658C8.08543 9.62658 8.37892 9.33309 8.37892 8.97111C8.37892 8.80692 8.31843 8.65681 8.21857 8.54191C8.09855 8.40332 7.92124 8.31563 7.72345 8.31563C7.52725 8.31563 7.35122 8.40172 7.2312 8.53839C7.12942 8.65393 7.06797 8.80532 7.06797 8.97111C7.06797 9.33309 7.36146 9.62658 7.72345 9.62658Z"
        fill="white"
      />
      <path
        d="M13.9505 7.33241C14.3125 7.33241 14.6059 7.03892 14.6059 6.67694C14.6059 6.46474 14.5051 6.27623 14.3489 6.15653C14.2385 6.07171 14.1003 6.02146 13.9505 6.02146C13.5885 6.02146 13.295 6.31495 13.295 6.67694C13.295 7.03892 13.5885 7.33241 13.9505 7.33241Z"
        fill="white"
      />
      <path
        d="M12.6395 8.97111C12.6395 9.12537 12.5861 9.26716 12.4968 9.37918C12.3768 9.52992 12.1918 9.62658 11.984 9.62658C11.6221 9.62658 11.3286 9.33309 11.3286 8.97111C11.3286 8.60912 11.6221 8.31563 11.984 8.31563C12.346 8.31563 12.6395 8.60912 12.6395 8.97111Z"
        fill="white"
      />
      <path
        d="M11.984 5.03825C12.346 5.03825 12.6395 4.74476 12.6395 4.38277C12.6395 4.23523 12.5905 4.09888 12.5083 3.9891C12.4808 3.95261 12.4497 3.91901 12.4155 3.88924C12.402 3.87772 12.3883 3.86652 12.3742 3.85596C12.3575 3.8438 12.3406 3.83227 12.323 3.82171C12.2241 3.76186 12.1082 3.72729 11.984 3.72729C11.8355 3.72729 11.6982 3.7769 11.5881 3.86012L11.5526 3.88892C11.5411 3.89885 11.5302 3.90909 11.5196 3.91997L11.4998 3.94077C11.4752 3.96766 11.4527 3.99678 11.4329 4.02751C11.367 4.12993 11.3286 4.25187 11.3286 4.38277C11.3286 4.74476 11.6221 5.03825 11.984 5.03825Z"
        fill="white"
      />
      <path
        d="M18.8665 6.67694C18.8665 7.03892 18.5731 7.33241 18.2111 7.33241C18.0722 7.33241 17.9435 7.28921 17.8376 7.21559C17.667 7.09717 17.5556 6.90002 17.5556 6.67694C17.5556 6.51979 17.611 6.37576 17.7031 6.26278C17.8232 6.11556 18.0062 6.02146 18.2111 6.02146C18.3615 6.02146 18.5001 6.07203 18.6108 6.15717C18.7664 6.27719 18.8665 6.46538 18.8665 6.67694Z"
        fill="white"
      />
      <path
        d="M16.4456 9.59522C16.4952 9.57921 16.5423 9.55745 16.5861 9.53056C16.703 9.45919 16.7958 9.35229 16.8492 9.22491C16.8703 9.17402 16.8857 9.11993 16.8937 9.0636C16.8966 9.0428 16.8985 9.02167 16.8995 9.00023L16.9001 8.97111C16.9001 8.76371 16.8038 8.57872 16.6534 8.45869C16.5413 8.36908 16.3992 8.31563 16.2446 8.31563C16.1934 8.31563 16.1435 8.32139 16.0958 8.33259C16.052 8.34283 16.01 8.35724 15.97 8.37548C15.9489 8.3854 15.9284 8.39628 15.9086 8.40813C15.8756 8.42797 15.8442 8.45069 15.8154 8.47566C15.6769 8.59568 15.5892 8.77331 15.5892 8.97111C15.5892 9.33309 15.8827 9.62658 16.2446 9.62658C16.3147 9.62658 16.3823 9.61538 16.4456 9.59522Z"
        fill="white"
      />
      <path
        d="M23.1271 6.67694C23.1271 7.03892 22.8336 7.33241 22.4717 7.33241C22.1097 7.33241 21.8162 7.03892 21.8162 6.67694C21.8162 6.31495 22.1097 6.02146 22.4717 6.02146C22.8336 6.02146 23.1271 6.31495 23.1271 6.67694Z"
        fill="white"
      />
      <path
        d="M20.5052 9.62658C20.8672 9.62658 21.1607 9.33309 21.1607 8.97111C21.1607 8.76339 21.0641 8.5784 20.9136 8.45837C20.8544 8.41101 20.7866 8.37388 20.7133 8.34924C20.648 8.32747 20.5779 8.31563 20.5052 8.31563C20.1432 8.31563 19.8498 8.60912 19.8498 8.97111C19.8498 9.05976 19.8674 9.14458 19.8994 9.22171C19.9253 9.2838 19.9605 9.34109 20.0031 9.39198C20.0424 9.43903 20.0885 9.48064 20.1397 9.5152C20.2441 9.58561 20.3699 9.62658 20.5052 9.62658Z"
        fill="white"
      />
      <path
        d="M21.1607 4.38277C21.1607 4.74476 20.8672 5.03825 20.5052 5.03825C20.2937 5.03825 20.1055 4.93807 19.9855 4.78252C19.9691 4.76108 19.9541 4.73867 19.9403 4.71531C19.9189 4.67914 19.901 4.64074 19.8869 4.60041C19.8629 4.53224 19.8498 4.45894 19.8498 4.38277C19.8498 4.02079 20.1432 3.72729 20.5052 3.72729C20.8672 3.72729 21.1607 4.02079 21.1607 4.38277Z"
        fill="white"
      />
      <path
        d="M26.7323 7.33241C27.0942 7.33241 27.3877 7.03892 27.3877 6.67694C27.3877 6.31495 27.0942 6.02146 26.7323 6.02146C26.3703 6.02146 26.0768 6.31495 26.0768 6.67694C26.0768 7.03892 26.3703 7.33241 26.7323 7.33241Z"
        fill="white"
      />
      <path
        d="M25.4213 8.97111C25.4213 9.33309 25.1278 9.62658 24.7658 9.62658C24.554 9.62658 24.3654 9.52608 24.2457 9.3699C24.1609 9.25948 24.1104 9.12121 24.1104 8.97111C24.1104 8.60912 24.4038 8.31563 24.7658 8.31563C25.1278 8.31563 25.4213 8.60912 25.4213 8.97111Z"
        fill="white"
      />
      <path
        d="M24.7658 5.03825C25.1278 5.03825 25.4213 4.74476 25.4213 4.38277C25.4213 4.02079 25.1278 3.72729 24.7658 3.72729C24.4038 3.72729 24.1104 4.02079 24.1104 4.38277C24.1104 4.74476 24.4038 5.03825 24.7658 5.03825Z"
        fill="white"
      />
      <path
        d="M0.633109 9.73813L8.80928 0.938805C9.02756 0.717325 9.2804 0.613627 9.52589 0.613627C9.77137 0.613627 10.0242 0.717325 10.2425 0.938805L18.4187 9.73813L18.6587 9.51473L16.3885 7.07173L21.9198 0.937844C22.1377 0.717005 22.3906 0.613627 22.6354 0.613627C22.8809 0.613627 23.1337 0.717325 23.352 0.938805L31.5282 9.73813L31.7682 9.51473L23.5905 0.713805L23.5876 0.710604C23.3143 0.432155 22.9788 0.285889 22.6354 0.285889C22.2923 0.285889 21.9569 0.432155 21.6836 0.710604L21.681 0.713165L16.1645 6.83073L10.4809 0.713805L10.4777 0.710604C10.2047 0.432155 9.86899 0.285889 9.52589 0.285889C9.18279 0.285889 8.84705 0.432155 8.57404 0.710604L0.393066 9.51473L0.633109 9.73813Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41547 0.455713C9.15997 0.483035 8.9093 0.605095 8.69499 0.821681C8.90895 0.604961 9.15997 0.483011 9.41547 0.455713ZM8.45733 0.595931C8.7578 0.28947 9.13449 0.12207 9.52619 0.12207C9.91764 0.12207 10.2941 0.289258 10.5945 0.595347C10.5947 0.595541 10.5949 0.595736 10.595 0.595931L10.5971 0.597982L10.5992 0.600109L16.163 6.58809L21.5624 0.600334L21.5654 0.597342L21.5669 0.595862C21.5671 0.595674 21.5673 0.595485 21.5675 0.595297C21.8681 0.289303 22.2442 0.12207 22.6357 0.12207C23.0278 0.12207 23.4041 0.289493 23.7048 0.595862L23.7073 0.598395L23.7097 0.601032L23.7108 0.60231L23.7126 0.604233L32 9.52318L31.5201 9.96986L23.234 1.05221C23.0435 0.859692 22.8321 0.777547 22.6357 0.777547C22.4401 0.777547 22.2291 0.859227 22.0392 1.05047L16.611 7.07012L18.8905 9.52318L18.4106 9.96986L10.1244 1.05222C9.93396 0.859695 9.72257 0.777547 9.52619 0.777547C9.32981 0.777547 9.11842 0.859695 8.92793 1.05222L0.641824 9.96986L0.161865 9.52318L8.45733 0.595931ZM23.4685 0.822969C23.2385 0.589699 22.9662 0.465885 22.691 0.451275C22.9664 0.465932 23.2388 0.590204 23.469 0.823829L23.4707 0.825545L31.5369 9.50649L23.4689 0.823492L23.4685 0.822969Z"
        fill="white"
      />
    </svg>
  );
}
