import useGetAllOrganize from '@Src/_basic/apiHooks/useGetAllOrganize';
import YatungButton from '@Src/_basic/components/YatungButton';
import { YatungComboBox } from '@Src/_basic/components/YatungComboBox';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import { Options } from '@Src/_basic/components/YatungFormSelect';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import { AccountDataFormRequire, GroupData, UserGroupData } from '@Src/_basic/object/AccountManagementType';
import { UsersApi } from '@Src/_basic/protocol/accountRights/users/UsersApi';
import { Grid, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import EditAccountField from '../../../_components/EditAccountField';
import EditAccountLabel from '../../../_components/EditAccountLabel';
import EditAccountCreateCard from '../EditAccountCreateCard';
import ChangeStateButton from './components/ChangeStateButton';
import EditAccountMiniTable from './components/EditAccountMiniTable';
import EditAccountSaveSubmit from './components/EditAccountSaveSubmit';
import KeyManagementTable from './components/KeyManagementTable';

interface Props {
  initialValues: any;
}

export const AccountSourceKind = Array<Options>({ value: 0, text: '一般帳號' }, { value: 3, text: 'AD帳號' });

export const createADAccountSchema = Yup.object().shape({
  account: Yup.string().required('必填'),
  userName: Yup.string().required('必填'),
  jobTitle: Yup.string().required('必填'),
  email: Yup.string().email('無效的電子郵件').required('必填'),
  phone: Yup.string()
    .max(10, '手機號碼不正確')
    .required('必填')
    .matches(/09\d{8}/, '範例：0921000111'),
});

export const createAccountSchema = Yup.object().shape({
  account: Yup.string().required('必填'),
  password: Yup.string().required('必填'),
  userName: Yup.string().required('必填'),
  jobTitle: Yup.string().required('必填'),
  email: Yup.string().email('無效的電子郵件').required('必填'),
  phone: Yup.string()
    .max(10, '手機號碼不正確')
    .required('必填')
    .matches(/09\d{8}/, '範例：0921000111'),
});

export const editAccountSchema = Yup.object().shape({
  userName: Yup.string().required('必填'),
  jobTitle: Yup.string().required('必填'),
  email: Yup.string().email('無效的電子郵件').required('必填'),
  phone: Yup.string()
    .max(10, '手機號碼不正確')
    .required('必填')
    .matches(/09\d{8}/, '範例：0921000111'),
});

export const initialUserGroupData: UserGroupData = {
  factoryIds: [],
  groupSettings: [],
  characterSetting: [],
  supervisorReportGroups: [],
  supervisorTransformOrderGroups: [],
  supervisorAssignGroups: [],
};

export default function EditAcountFrom(props: Props) {
  const { t: i18T } = useTranslation();
  const { initialValues } = props;

  const { organizeOption } = useGetAllOrganize();

  const navigate = useNavigate();
  const [isState, setIsState] = useState<string>(initialValues.state);
  const [inputType, setInputType] = useState<string>('password');
  const [passwordEdit, setPasswordEdit] = useState<boolean>(true);
  const [isEditAccountCreateOpen, setIsEditAccountCreateOpen] = useState<boolean>(false);
  const [createValue, setCreateValue] = useState<number>(0);
  const [edit, setEdit] = useState<boolean>(false);
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [isCreateAlert, setIsCreateAlert] = useState<boolean>(false);
  const [createAlertText, setCreateAlertText] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');
  const [sourceKind, setSourceKind] = useState<number>(0);

  useEffect(() => {
    setEmail(initialValues.email);
  }, [initialValues.email]);

  const [userGroupData, setUserGroupData] = useReducer((prev: UserGroupData, next: Partial<UserGroupData>) => {
    return { ...prev, ...next };
  }, initialUserGroupData);

  useEffect(() => {
    if (initialValues.account) {
      setEdit(true);
      setPasswordEdit(false);
      setIsState(initialValues.state);
      setSourceKind(initialValues.sourceKind);
      setUserGroupData({
        factoryIds: initialValues.factories.map((item: any) => ({
          id: item.id,
          name: item.factoryName,
          code: `${item.factoryCode}${item.branchCode}`,
        })),
        groupSettings: initialValues.groups.map((item: any) => ({
          ...item,
          factory: parseInt(item.attrs.find((_item: any) => _item.code === 'factory')?.attrVal),
        })),
        characterSetting: initialValues.roles.map((item: any) => ({
          ...item,
          factory: parseInt(item.attrs.find((_item: any) => _item.code === 'factory')?.attrVal),
        })),
        supervisorReportGroups: initialValues.supervisorReportGroups.map((_item: any) => ({
          id: _item.id,
          code: _item.groupCode,
          name: _item.groupName,
          factory: _item.factoryId,
        })),
        supervisorTransformOrderGroups: initialValues.supervisorTransformGroups.map((_item: any) => ({
          id: _item.id,
          code: _item.groupCode,
          name: _item.groupName,
          factory: _item.factoryId,
        })),
        supervisorAssignGroups: initialValues.supervisorAssignGroups.map((_item: any) => ({
          id: _item.id,
          code: _item.groupCode,
          name: _item.groupName,
          factory: _item.factoryId,
        })),
      });
    }
  }, [initialValues]);

  useEffect(() => {
    if (!userGroupData.factoryIds.length) {
      setUserGroupData({
        groupSettings: [],
        characterSetting: [],
        supervisorReportGroups: [],
        supervisorTransformOrderGroups: [],
        supervisorAssignGroups: [],
      });
    }
  }, [userGroupData.factoryIds]);

  const handleGoBack = () => {
    navigate('/UsersManagePage/AccountManagement');
  };

  const filterRemovedId = (initData: any, editedData: any, toNumber?: boolean) => {
    const removedId: Set<any> = new Set();
    const addId: Set<any> = new Set();

    initData.forEach((item: any) => {
      const _removedId = editedData.find((_item: any) => item.id.toString() === _item.id.toString());
      if (!_removedId) removedId.add(item);
    });

    editedData.forEach((item: any) => {
      const _addId = initData.find((_item: any) => item.id.toString() === _item.id.toString());
      if (_addId) removedId.delete(Array.from(removedId).find((_item) => _item.id.toString() === _addId.id.toString()));
      if (!_addId) addId.add(item);
    });

    if (toNumber) {
      return {
        removedId: Array.from(removedId).map((item) => parseInt(item.id)),
        addId: Array.from(addId).map((item) => parseInt(item.id)),
      };
    } else {
      return {
        removedId: Array.from(removedId).map((item) => item.id.toString()),
        addId: Array.from(addId).map((item) => item.id.toString()),
      };
    }
  };

  const handleSubmit = async (require: AccountDataFormRequire) => {
    if (edit) {
      const filterFactoryIds = filterRemovedId(initialValues.factories, userGroupData.factoryIds, true);
      const filterGroupIds = filterRemovedId(initialValues.groups, userGroupData.groupSettings);
      const filterRoleIds = filterRemovedId(initialValues.roles, userGroupData.characterSetting);
      const filterSupervisorReportGroupIds = filterRemovedId(
        initialValues.supervisorReportGroups,
        userGroupData.supervisorReportGroups,
      );
      const filterSupervisorTransformGroupIds = filterRemovedId(
        initialValues.supervisorTransformGroups,
        userGroupData.supervisorTransformOrderGroups,
      );
      const filterSupervisorAssignGroupIds = filterRemovedId(
        initialValues.supervisorAssignGroups,
        userGroupData.supervisorAssignGroups,
      );
      UsersApi.putEditUser(
        {
          ...require,
          password: require.password ?? '',
          state: isState,
          accountName: require.userName,
          sourceKind: sourceKind,
          factoryIds: filterFactoryIds.addId,
          delFactoryIds: filterFactoryIds.removedId,
          delGroupIds: filterGroupIds.removedId,
          groupDatas: filterGroupIds.addId.map((item) => ({
            groupId: item,
            position: 0,
          })),
          roleIds: filterRoleIds.addId,
          delRoleIds: filterRoleIds.removedId,
          supervisorReportGroups: filterSupervisorReportGroupIds.addId,
          delSupervisorReportGroups: filterSupervisorReportGroupIds.removedId,
          supervisorTransformGroups: filterSupervisorTransformGroupIds.addId,
          delSupervisorTransformGroups: filterSupervisorTransformGroupIds.removedId,
          supervisorAssignGroups: filterSupervisorAssignGroupIds.addId,
          delSupervisorAssignGroups: filterSupervisorAssignGroupIds.removedId,
        },
        (_data) => {
          console.log('_data', _data);
          // navigate(`/UsersManagePage/AccountManagement/AccountManagementUpdate/${_data.id}`, {
          //   state: _data.id,
          // });
          setIsShowAlert(true);
          setAlertText(i18T('GLOBAL.UPDATE_SUCCESS'));
          setEmail(require.email);
          setSourceKind(require.sourceKind);
        },
        (err) => console.log(err),
      );
    } else {
      UsersApi.postCreateUser(
        {
          ...require,
          sourceKind: sourceKind,
          factoryIds: userGroupData.factoryIds
            ? userGroupData.factoryIds.map((item) => (typeof item.id === 'string' ? parseInt(item.id) : item.id))
            : undefined,
          groupDatas: userGroupData.groupSettings
            ? userGroupData.groupSettings.map((item) =>
                typeof item.id === 'number'
                  ? {
                      groupId: item.id.toString(),
                      position: 0,
                    }
                  : {
                      groupId: item.id,
                      position: 0,
                    },
              )
            : undefined,
          roleIds: userGroupData.characterSetting
            ? userGroupData.characterSetting.map((item) => (typeof item.id === 'number' ? item.id.toString() : item.id))
            : undefined,
          supervisorReportGroups: userGroupData.supervisorReportGroups
            ? userGroupData.supervisorReportGroups.map((item) =>
                typeof item.id === 'string' ? parseInt(item.id) : item.id,
              )
            : undefined,
          supervisorTransformGroups: userGroupData.supervisorTransformOrderGroups
            ? userGroupData.supervisorTransformOrderGroups.map((item) =>
                typeof item.id === 'string' ? parseInt(item.id) : item.id,
              )
            : undefined,
          supervisorAssignGroups: userGroupData.supervisorAssignGroups
            ? userGroupData.supervisorAssignGroups.map((item) =>
                typeof item.id === 'string' ? parseInt(item.id) : item.id,
              )
            : undefined,
        },
        (_data) => {
          console.log('_data', _data);
          // navigate(`/UsersManagePage/AccountManagement`);

          setIsCreateAlert(true);
          setCreateAlertText(i18T('GLOBAL.ACCOUNT_SAVE'));
          setAccountId(_data.id);
          setSourceKind(require.sourceKind);
        },
        (err) => console.log(err),
      );
    }
  };

  const handleStateChangeStart = () => {
    if (isState === '0') setIsState('1');
  };
  const handleStateChangeStop = () => {
    if (isState === '1') setIsState('0');
  };

  const handlePasswordEdit = () => {
    if (passwordEdit) setPasswordEdit(false);
    else setPasswordEdit(true);
  };

  const handleInputType = () => {
    if (inputType == 'password') setInputType('text');
    else setInputType('password');
  };

  const handleEditAccountCreate = (value: number) => {
    setIsEditAccountCreateOpen(true);
    setCreateValue(value);
  };

  const onDeleteFactoryData = (row: GroupData) => {
    setUserGroupData({
      factoryIds: userGroupData.factoryIds.filter((item) => !(item.id.toString() === row.id.toString())),
      groupSettings: userGroupData.groupSettings.filter(
        (item) => item.factory && item.factory.toString() !== row.id.toString(),
      ),
      characterSetting: userGroupData.characterSetting.filter(
        (item) => item.factory && item.factory.toString() !== row.id.toString(),
      ),
      supervisorReportGroups: userGroupData.supervisorReportGroups.filter(
        (item) => item.factory && item.factory.toString() !== row.id.toString(),
      ),
      supervisorTransformOrderGroups: userGroupData.supervisorTransformOrderGroups.filter(
        (item) => item.factory && item.factory.toString() !== row.id.toString(),
      ),
      supervisorAssignGroups: userGroupData.supervisorAssignGroups.filter(
        (item) => item.factory && item.factory.toString() !== row.id.toString(),
      ),
    });
  };

  const onDeleteGroupSettingsData = (row: GroupData) => {
    setUserGroupData({
      groupSettings: userGroupData.groupSettings.filter((item) => !(item.id.toString() === row.id.toString())),
    });
  };

  const onDeleteCharacterSettingData = (row: GroupData) => {
    setUserGroupData({
      characterSetting: userGroupData.characterSetting.filter((item) => !(item.id.toString() === row.id.toString())),
    });
  };

  const onDeleteReportGroupData = (row: GroupData) => {
    setUserGroupData({
      supervisorReportGroups: userGroupData.supervisorReportGroups.filter(
        (item) => !(item.id.toString() === row.id.toString()),
      ),
    });
  };

  const onDeleteTransferOrderGroupData = (row: GroupData) => {
    setUserGroupData({
      supervisorTransformOrderGroups: userGroupData.supervisorTransformOrderGroups.filter(
        (item) => !(item.id.toString() === row.id.toString()),
      ),
    });
  };

  const onDeleteAssignGroupData = (row: GroupData) => {
    setUserGroupData({
      supervisorAssignGroups: userGroupData.supervisorAssignGroups.filter(
        (item) => !(item.id.toString() === row.id.toString()),
      ),
    });
  };

  const handleCloseAlert = () => {
    setIsCreateAlert(false);
    navigate(`/UsersManagePage/AccountManagement/AccountManagementUpdate/${accountId}`, {
      state: accountId,
    });
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={
          sourceKind == 3 ? createADAccountSchema : passwordEdit ? createAccountSchema : editAccountSchema
        }
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              <Stack width="100%">
                <Grid container item xs={12} justifyContent={'space-between'}>
                  <Grid container item xs={true}></Grid>
                  <Grid container item xs={true} gap={4} justifyContent={'center'} sx={{ mr: 3, my: 1 }}>
                    <EditAccountSaveSubmit onSubmit={formik.handleSubmit} onGoBack={handleGoBack} />
                  </Grid>
                </Grid>
                <Stack direction={'row'} spacing={8}>
                  <Stack>
                    <Stack direction="row">
                      <Stack direction="column" justifyContent="space-evenly" sx={{ ml: 2 }}>
                        <Typography sx={{ fontWeight: 400 }}>
                          {i18T('USERSMANAGEMENU.EDIT_ACCOUNT.JOIN_TIME') + '：' + initialValues.createDt}
                        </Typography>
                        <Typography>
                          {i18T('USERSMANAGEMENU.EDIT_ACCOUNT.UPDATE_TIME') + '：' + initialValues.updateDt}
                        </Typography>
                        <Stack direction="row">
                          <Typography>{i18T('USERSMANAGEMENU.EDIT_ACCOUNT.STATE') + '：'}</Typography>
                          <Typography color={isState === '1' ? '#00D03A' : 'red'}>
                            {isState === '1'
                              ? i18T('USERSMANAGEMENU.EDIT_ACCOUNT.ENABLE')
                              : i18T('USERSMANAGEMENU.EDIT_ACCOUNT.DISABLED')}
                          </Typography>
                        </Stack>
                        <Stack direction="row" gap={2}>
                          <ChangeStateButton onStart={handleStateChangeStart} onStop={handleStateChangeStop} />
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack direction="row" sx={{ mt: 3 }}>
                      <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.SOURCE_KIND')} isStar={true} />
                      <YatungSelect
                        sx={{ my: 1, width: 200 }}
                        options={AccountSourceKind}
                        value={sourceKind}
                        onChange={(e: any) => {
                          const selectSourceKind = e.target.value as number;
                          setSourceKind(selectSourceKind);
                        }}
                        disabled={edit}
                      />
                    </Stack>
                    <Stack direction="row" sx={{ mt: 3 }}>
                      <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.USER_ACCOUNT')} isStar={true} />
                      {edit ? (
                        <YatungInput defaultValue={initialValues.account} disabled={true} style={{ width: '100%' }} />
                      ) : (
                        <EditAccountField target="account" />
                      )}
                    </Stack>
                    {sourceKind == 3 ? (
                      <Stack direction="row" sx={{ mt: 3 }}>
                        <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.PASSWORD')} isStar={true} />
                        <EditAccountLabel label="使用AD設定" />
                      </Stack>
                    ) : edit ? (
                      <Stack direction="row" sx={{ mt: 3, justifyContent: 'space-between' }}>
                        <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.PASSWORD')} isStar={true} />
                        {passwordEdit ? (
                          <Stack direction="column" sx={{ alignItems: 'flex-end' }}>
                            <EditAccountField
                              target="password"
                              inputType={inputType}
                              handleInputType={handleInputType}
                            />
                            <YatungButton
                              width={110}
                              sx={{ mt: 1 }}
                              color="blue"
                              text="取消編輯"
                              onClick={handlePasswordEdit}
                            />
                          </Stack>
                        ) : (
                          <YatungButton color="blue" text="編輯密碼" onClick={handlePasswordEdit} />
                        )}
                      </Stack>
                    ) : (
                      <Stack direction="row" sx={{ mt: 3 }}>
                        <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.PASSWORD')} isStar={true} />
                        <EditAccountField target="password" inputType={inputType} handleInputType={handleInputType} />
                      </Stack>
                    )}
                    <Stack direction="row" sx={{ mt: 3 }}>
                      <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.NAME_1')} isStar={true} />
                      <EditAccountField target="userName" />
                    </Stack>
                    <Stack direction="row" sx={{ mt: 3 }}>
                      <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.JOB_TITLE')} isStar={true} />
                      <EditAccountField target="jobTitle" />
                    </Stack>

                    <Stack direction="row" sx={{ mt: 3 }}>
                      <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.ORGANIZE')} />
                      <YatungComboBox
                        options={organizeOption}
                        fullWidth
                        multiple
                        limitTags={10}
                        defaultValue={formik.values.organizationIds}
                        onBlur={(value: any) => {
                          formik.setFieldValue('organizationIds', value);
                        }}
                        sx={{ width: '450px' }}
                      />
                    </Stack>

                    <Stack direction="row" sx={{ mt: 3 }}>
                      <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.EMAIL')} isStar={true} />
                      <EditAccountField target="email" />
                    </Stack>
                    <Stack direction="row" sx={{ mt: 3 }}>
                      <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.PHONE')} isStar={true} />
                      <EditAccountField target="phone" />
                    </Stack>

                    <Stack direction="row" sx={{ mt: 3 }}>
                      <EditAccountLabel label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.FACTORY')} />
                      <EditAccountMiniTable
                        onCreate={() => {
                          handleEditAccountCreate(1);
                        }}
                        onDelete={onDeleteFactoryData}
                        codeTitle={`${i18T('USERSMANAGEMENU.EDIT_ACCOUNT.SITE_CODE')}`}
                        data={userGroupData.factoryIds}
                        isDisabled={false}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction={'column'}>
                    <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                      <EditAccountLabel
                        label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.GROUP_SETTINGS')}
                        isTableLabel={true}
                      />
                      <EditAccountMiniTable
                        onCreate={() => {
                          handleEditAccountCreate(2);
                        }}
                        onDelete={onDeleteGroupSettingsData}
                        codeTitle={`${i18T('USERSMANAGEMENU.EDIT_ACCOUNT.GROUP_CODE')}`}
                        data={userGroupData.groupSettings}
                        isDisabled={!userGroupData.factoryIds.length ? true : false}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                      <EditAccountLabel
                        label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.CHARACTER_SETTING')}
                        isTableLabel={true}
                      />
                      <EditAccountMiniTable
                        onCreate={() => {
                          handleEditAccountCreate(3);
                        }}
                        onDelete={onDeleteCharacterSettingData}
                        codeTitle={`${i18T('USERSMANAGEMENU.EDIT_ACCOUNT.GROUP_CODE')}`}
                        data={userGroupData.characterSetting}
                        isDisabled={!userGroupData.factoryIds.length ? true : false}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                      <EditAccountLabel
                        label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.REPORT_GROUP_SETTINGS')}
                        isTableLabel={true}
                      />
                      <EditAccountMiniTable
                        onCreate={() => {
                          handleEditAccountCreate(4);
                        }}
                        onDelete={onDeleteReportGroupData}
                        codeTitle={`${i18T('USERSMANAGEMENU.EDIT_ACCOUNT.GROUP_CODE')}`}
                        data={userGroupData.supervisorReportGroups}
                        isDisabled={!userGroupData.factoryIds.length ? true : false}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                      <EditAccountLabel
                        label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.TRANSFER_ORDER_GROUP_SETTINGS')}
                        isTableLabel={true}
                      />
                      <EditAccountMiniTable
                        onCreate={() => {
                          handleEditAccountCreate(5);
                        }}
                        onDelete={onDeleteTransferOrderGroupData}
                        codeTitle={`${i18T('USERSMANAGEMENU.EDIT_ACCOUNT.GROUP_CODE')}`}
                        data={userGroupData.supervisorTransformOrderGroups}
                        isDisabled={!userGroupData.factoryIds.length ? true : false}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                      <EditAccountLabel
                        label={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.ASSIGN_GROUP_SETTINGS')}
                        isTableLabel={true}
                      />
                      <EditAccountMiniTable
                        onCreate={() => {
                          handleEditAccountCreate(6);
                        }}
                        onDelete={onDeleteAssignGroupData}
                        codeTitle={`${i18T('USERSMANAGEMENU.EDIT_ACCOUNT.GROUP_CODE')}`}
                        data={userGroupData.supervisorAssignGroups}
                        isDisabled={!userGroupData.factoryIds.length ? true : false}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <YatungDivider />
              <KeyManagementTable ids={formik.values.id} email={email} account={initialValues.account} />
            </Form>
          );
        }}
      </Formik>
      <EditAccountCreateCard
        isOpen={isEditAccountCreateOpen}
        value={createValue}
        handleGoBackButtonClick={() => setIsEditAccountCreateOpen(false)}
        userGroupData={userGroupData}
        setUserGroupData={setUserGroupData}
      />
      <YatungConfirmAlert
        headerText={i18T('GLOBAL.SAVE')}
        isShowAlert={isShowAlert}
        onClose={() => setIsShowAlert(false)}
        alertText={alertText}
      />
      <YatungConfirmAlert
        headerText={i18T('GLOBAL.SAVE')}
        isShowAlert={isCreateAlert}
        onClose={handleCloseAlert}
        alertText={createAlertText}
      />
    </>
  );
}
