import React, { memo } from 'react';

import { Stack, Typography } from '@mui/material';
import FixedRatioBlock from './FixedRatioBlock';

interface Props {
  top: React.ReactNode;
  bottom: React.ReactNode;
  isToggled?: boolean;
  onPress?: () => void;
}

function ToggleBlockButton({ top, bottom, onPress, isToggled = false }: Props) {
  return (
    <FixedRatioBlock onPress={onPress} isToggled={isToggled}>
      <Stack flexDirection="column">
        <Stack style={styles.top}>
          <Typography sx={{ textAlign: 'center', mb: 1 }}>{top}</Typography>
        </Stack>
        <Stack style={styles.bottom}>
          <Typography sx={{ textAlign: 'center' }}>{bottom}</Typography>
        </Stack>
      </Stack>
    </FixedRatioBlock>
  );
}

const styles = {
  top: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid white',
    marginBottom: 3,
  },
  bottom: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default memo(ToggleBlockButton);
