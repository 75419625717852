import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import RecordListScreen from './screens/RecordListScreen';
import TestAndRecheckDetailScreen from './screens/TestAndRecheckDetailScreen';

const configRoutes: RouteConfig[] = [
  {
    path: '/list',
    element: <RecordListScreen />,
  },
  {
    path: '/testAndRecheckDetail/:exptId/:exptItemCode',
    element: <TestAndRecheckDetailScreen />,
  },
];

const InformAndFineRecord = () => PermissionRoute(configRoutes);

export default memo(InformAndFineRecord);
