import { Protocol } from '../Protocol';

// DEPRECATE
export class ProportionProtocol {
  public static readonly PROPORTION: string = `${Protocol.YATUNG_API_ROOT}/proportion/V2`;
  public static readonly EDIT_PROPORTION_TAGS: string = `${Protocol.YATUNG_API_ROOT}/proportion/V2/tags`;
  public static readonly PROPORTION_NAMES: string = `${Protocol.YATUNG_API_ROOT}/proportion/V2/names`;
  public static readonly SEARCH_PROPORTION: string = `${Protocol.YATUNG_API_ROOT}/proportion/V2/search`;
  public static readonly SAFETY_WATER: string = `${Protocol.YATUNG_API_ROOT}/proportion/V2/safetyWater`;
  public static readonly PROPORTION_VERSION: string = `${Protocol.YATUNG_API_ROOT}/proportion/V2/versions`;
  public static readonly PROPORTION_CURR_VER: string = `${Protocol.YATUNG_API_ROOT}/proportion/V2/currVer`;
  public static readonly PROPORTION_MAINTENANCE: string = `${Protocol.YATUNG_API_ROOT}/proportion/maintenance/V2`;
  public static readonly IS_EXIST_PROPORTION_NAME: string = `${Protocol.YATUNG_API_ROOT}/proportion/V2/exist`;
  public static readonly IS_EXIST_PROPORTION: string = `${Protocol.YATUNG_API_ROOT}/proportion/V2/checkDuplication`;
  public static readonly DEFAULT_PROPORTION_MAINTENANCE: string = `${Protocol.YATUNG_API_ROOT}/proportion/maintenance/V2/default`;
}
