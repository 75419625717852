import YatungDialog from '@Src/_basic/components/YatungDialog';
import EditV2 from '@Src/_basic/icons/EditV2';
import { EntityData, UpdateEntityRequest } from '@Src/_basic/object/EntityType';
import { EntityApi } from '@Src/_basic/protocol/entity/EntityApi';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import EntityUpdateField from './components/EntityUpdateField';

interface Props {
  entityByIdData: EntityData;
  modalOpen: boolean;
  modalClose: () => void;
  getSearchEntityData: () => void;
}

const updateEntitySchema = Yup.object().shape({
  name: Yup.string().required('請輸入人員名稱'),
});

export default function EntityUpdateCard({ entityByIdData, modalOpen, modalClose, getSearchEntityData }: Props) {
  const { t: i18T } = useTranslation();

  const initialValues: UpdateEntityRequest = useMemo(
    () => ({
      id: entityByIdData.id,
      name: entityByIdData.name,
      factory_id: entityByIdData.station.factory_id,
      stationId: entityByIdData.station.id,
    }),
    [entityByIdData],
  );

  const handleSubmit = useCallback(
    (request: UpdateEntityRequest) => {
      EntityApi.UpdateEntity(request, () => {
        modalClose();
        getSearchEntityData();
      });
    },
    [modalClose, getSearchEntityData],
  );

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={modalOpen}
        handleClose={modalClose}
        icon={<EditV2 sx={{ width: 40, height: 40, mr: 1, alignSelf: 'center' }} />}
        title={i18T('ENTITYMANAGEMENT.PAGE_UPDATE_TITLE')}
        body={
          <Formik
            initialValues={initialValues}
            validationSchema={updateEntitySchema}
            onSubmit={(request: UpdateEntityRequest) => handleSubmit(request)}
            enableReinitialize
          >
            {({ setFieldValue, values }) => {
              return (
                <Form>
                  <EntityUpdateField
                    values={values}
                    setFieldValue={setFieldValue}
                    entityByIdData={entityByIdData}
                    handleClose={modalClose}
                  />
                </Form>
              );
            }}
          </Formik>
        }
      />
    </Grid>
  );
}
