import React from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';
import { OrganizationApi } from '@Src/_basic/protocol/accountRights/organization/OrganizationApi';

interface Props {
  onGoBackButtonClick: () => void;
  targetId: number;
  setAlertIsOpen: (e: boolean) => void;
  setSetAlertTitle: (e: string) => void;
}
export default function OrganizeManagementDeleteSubmit(props: Props) {
  const { t: i18T } = useTranslation();
  const { onGoBackButtonClick, targetId, setAlertIsOpen, setSetAlertTitle } = props;

  const handleClick = () => {
    OrganizationApi.deleteOrganization(targetId, () => {
      setAlertIsOpen(true);
      setSetAlertTitle(i18T('GLOBAL.DELETE_SUCCESS'));
      onGoBackButtonClick();
    });
  };

  return (
    <>
      <YatungButton
        text={i18T('GLOBAL.SURE')}
        variant="contained"
        color={'blue'}
        size={'large'}
        type={'button'}
        sx={{
          mr: 4,
          px: 3,
          py: 'auto',
        }}
        onClick={handleClick}
      />
      <YatungButton
        text={i18T('GLOBAL.CANCEL')}
        variant={'contained'}
        size={'large'}
        type={'button'}
        onClick={onGoBackButtonClick}
        color={'red'}
        sx={{
          px: 3,
          py: 'auto',
        }}
      />
    </>
  );
}
