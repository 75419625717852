import useGetAllTypes from '@Src/_basic/apiHooks/useGetAllTypes';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import CreateMixer from '@Src/_basic/icons/CreateMixer';
import LogRecord from '@Src/_basic/icons/LogRecord';
import {
  PageableWarehouseData,
  WarehouseAndStateData,
  WarehouseData,
} from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { FeedTroughMaintenanceApi } from '@Src/_basic/protocol/feedTroughMaintenanceManagement/FeedTroughMaintenanceApi';
import { MixerApi } from '@Src/_basic/protocol/mixerInformation/MixerApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Grid, Pagination, Stack } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import ClearExecutionCard from './components/ClearExecutionCard';

interface AreaOptions extends Options {
  factories: Options[];
}

type SelectType = {
  factoryId: number;
  areaId: number;
};

type Query = SelectType & {
  mixerId: number;
  typeId: number;
};

type RequestSavingType = Query & {
  page: number;
};

export default function ClearExecution() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { typeOption } = useGetAllTypes();
  const { setSaveRequest, request } = useRequestSaving<RequestSavingType>();

  const initQuery = useMemo(() => {
    const {
      areaId = 0,
      factoryId = 0,
      mixerId = 0,
      typeId = 0,
    } = pathname === request?.page && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
      mixerId,
      typeId,
    };
  }, [pathname, request]);

  const initPageInfo = useMemo(
    () => ({
      page: pathname === request?.page && request?.request ? request.request.page : 1,
      pageSize: 12,
      total: 0,
      totalCount: 0,
    }),
    [pathname, request],
  );

  const [query, queryDispatch] = useReducer<Reducer<Query, Partial<Query>>>(updateReducer, initQuery);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [mixers, setMixers] = useState<Options[]>([]);
  const [WarehouseData, setWarehouseData] = useState<WarehouseData[]>([]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
  };

  const reset = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => setWarehouseData([]), 500);
    handleChange('mixerId')(0);
    handleChange('typeId')(0);
    pageInfoDispatch({ total: 0, totalCount: 0 });
  }, []);

  const getMixerOptions = useCallback((factoryId: number) => {
    MixerApi.getMixerOptionsByFactoryId({ factoryId }, (_data) =>
      setMixers(_data.map((mixer) => ({ value: mixer.id, text: mixer.mixerName }))),
    );
  }, []);

  const getClearExecutionSearch = useCallback(() => {
    FeedTroughMaintenanceApi.getStoragePaginationBySearch(
      {
        factoryId: query.factoryId,
        ...(query.mixerId && { mixerId: query.mixerId }),
        ...(query.typeId && { typeId: query.typeId }),
        page: pageInfo.page,
        pageSize: pageInfo.pageSize,
        isYard: false,
      },
      (datas: PageableWarehouseData) => {
        setIsVisible(true);
        const mappedData = datas.data.map((data: WarehouseAndStateData) => ({
          ...data,
          state: false,
        }));
        setWarehouseData(mappedData);
        pageInfoDispatch({
          total: datas.maxPage,
          totalCount: datas.totalElements,
        });
        setSaveRequest({
          page: pathname,
          request: {
            ...query,
            page: pageInfo.page,
          },
        });
      },
    );
  }, [query, pageInfo, setSaveRequest, pathname]);

  useEffect(() => {
    if (!query.factoryId) return;
    getClearExecutionSearch();
  }, [query.factoryId, query.mixerId, query.typeId, pageInfo.page]);

  useEffect(() => {
    if (!query.factoryId) reset();
    else getMixerOptions(query.factoryId);
  }, [query.factoryId, getMixerOptions, reset]);

  const handleChange = (field: keyof Query) => (value: Query[keyof Query]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handeleGoToLogRecord = useCallback(() => {
    navigate('ClearExecutionLogPage');
  }, [navigate]);

  const handeleGoToFeedMachine = useCallback(() => {
    navigate('/UsersManagePage/feedMachineStatus');
  }, [navigate]);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.CLEAR_EXECUTION.TITLE')}
      body={
        <>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
              <YatungCombinationSelect<SelectType>
                filterValue={query}
                handleChange={handleChange}
                selectOptions={['AreaAndFactory']}
              />
              <YatungSelect
                helperText={'拌合機'}
                disabled={!query.factoryId || actionLoading}
                options={mixers}
                value={query.mixerId}
                onChange={(e) => handleChange('mixerId')(e.target.value as number)}
              />
              <YatungSelect
                helperText={i18T('GLOBAL.TYPE')}
                disabled={!query.mixerId || actionLoading}
                options={[{ value: 0, text: '全部' }, ...typeOption]}
                value={query.typeId}
                onChange={(e) => handleChange('typeId')(e.target.value as number)}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <YatungButton
                text={i18T('APPLICATIONSMANAGEMENT.CLEAR_EXECUTION.MIXER_DESCRIPTION')}
                startIcon={<CreateMixer sx={{ width: '25px', height: '25px' }} />}
                onClick={handeleGoToFeedMachine}
                color="green"
                sx={{ alignSelf: 'center' }}
              />
              <YatungButton
                text={i18T('APPLICATIONSMANAGEMENT.CLEAR_EXECUTION.LOGRECORD')}
                startIcon={<LogRecord sx={{ width: '25px', height: '25px' }} />}
                onClick={handeleGoToLogRecord}
                color="green"
                sx={{ alignSelf: 'center' }}
              />
            </Stack>
          </Stack>
          {
            <YatungFadeInOut isVisible={isVisible}>
              <>
                <ClearExecutionCard WarehouseData={WarehouseData} />
                <Grid container justifyContent={'center'} item xs={12} sx={{ mt: 2 }}>
                  <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
                </Grid>
              </>
            </YatungFadeInOut>
          }
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
