import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LogRecord(props: SvgIconProps) {
    return (
        <SvgIcon {...props} width="35" height="27" viewBox="0 0 35 27" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.84126 3.54982C7.84126 1.71008 9.34443 0.206909 11.1842 0.206909H24.2189C25.1032 0.206909 25.9558 0.556895 26.5767 1.1778L33.4424 8.04343C34.0762 8.67723 34.4275 9.52929 34.4275 10.4155V23.4502C34.4275 25.2899 32.9243 26.7931 31.0846 26.7931H11.1842C9.34443 26.7931 7.84126 25.2899 7.84126 23.4502V20.8104H8.84126V23.4502C8.84126 24.7377 9.89671 25.7931 11.1842 25.7931H31.0846C32.372 25.7931 33.4275 24.7377 33.4275 23.4502V10.4155C33.4275 9.79487 33.1818 9.19705 32.7353 8.75054L25.8696 1.88491C25.4386 1.45393 24.8414 1.20691 24.2189 1.20691H11.1842C9.89671 1.20691 8.84126 2.26236 8.84126 3.54982V8.01725H7.84126V3.54982ZM2.57422 8.93105C1.46965 8.93105 0.574219 9.82648 0.574219 10.931V17.8966C0.574219 19.0011 1.46965 19.8966 2.57422 19.8966H16.3932C17.4978 19.8966 18.3932 19.0011 18.3932 17.8966V10.931C18.3932 9.82648 17.4978 8.93105 16.3932 8.93105H2.57422Z" fill="white" />
            <path d="M2.73885 18.4482H6.04285V17.8242H3.48285V12.5842H2.73885V18.4482ZM9.0006 18.5602C10.4726 18.5602 11.4966 17.3762 11.4966 15.4962C11.4966 13.6242 10.4726 12.4802 9.0006 12.4802C7.5206 12.4802 6.4966 13.6162 6.4966 15.4962C6.4966 17.3762 7.5206 18.5602 9.0006 18.5602ZM9.0006 17.9042C7.9446 17.9042 7.2566 16.9602 7.2566 15.4962C7.2566 14.0322 7.9446 13.1282 9.0006 13.1282C10.0486 13.1282 10.7446 14.0322 10.7446 15.4962C10.7446 16.9602 10.0486 17.9042 9.0006 17.9042ZM15.0741 18.5602C15.8661 18.5602 16.5061 18.2722 16.8901 17.8802V15.4162H14.9621V16.0322H16.2021V17.5682C15.9781 17.7762 15.5701 17.9042 15.1461 17.9042C13.8901 17.9042 13.1941 16.9842 13.1941 15.5042C13.1941 14.0402 13.9541 13.1282 15.1381 13.1282C15.7301 13.1282 16.1061 13.3762 16.4021 13.6802L16.8101 13.2002C16.4661 12.8482 15.9301 12.4802 15.1221 12.4802C13.5701 12.4802 12.4341 13.6322 12.4341 15.5202C12.4341 17.4242 13.5381 18.5602 15.0741 18.5602Z" fill="#00D03A" />
        </SvgIcon>
    );
}