import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Collapse, List, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useLayout } from '@Src/redux/layout/layoutActions';
import YatungButton from './YatungButton';

export type Option = {
  title: string | any;
  toUrl: string;
  code?: string;
};

interface Props {
  text: string | undefined;
  option: Array<Option>;
  iconComponent?: JSX.Element;
}

export default function YatungFadeMenu({ iconComponent, text, option = [] }: Props) {
  const [open, setOpen] = useState<boolean>(true);
  const { setCurrentUrl, currentUrl } = useLayout();
  const handleClick = () => {
    setOpen(!open);
  };
  const itemTextStyle = {
    color: '#fff',
    fontSize: '15px',
  };
  const handleButtonClick = useCallback((title: string) => {
    setCurrentUrl(title);
  }, []);
  useEffect(() => {
    setCurrentUrl('');
  }, []);
  return (
    <Box sx={{ color: 'white', width: '100%', padding: '0 8px 8px' }}>
      <YatungButton
        onClick={handleClick}
        color="orange"
        sx={{
          p: 0,
          borderRadius: 0,
          pr: '16px',
          py: '4px',
          width: '100%',
          boxShadow: 'none',
          '&.MuiButtonBase-root:hover': {
            boxShadow: 'none',
          },
        }}
      >
        <Stack alignItems="center" justifyContent="center" sx={{ width: '40px' }}>
          {iconComponent}
        </Stack>
        <Typography sx={{ fontSize: 20, fontWeight: 900, width: '174px', textAlign: 'left', pr: '8px' }}>
          {text}
        </Typography>
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
      </YatungButton>
      <Collapse in={open}>
        <List sx={{ p: 0 }}>
          {option.map(({ title, toUrl }: Option, index) => {
            return (
              <Link to={toUrl} key={index} style={{ textDecoration: 'none' }}>
                <ListItemButton sx={{ pl: '40px' }} onClick={() => handleButtonClick(toUrl)}>
                  <ListItemText primary={title} primaryTypographyProps={{ style: itemTextStyle }} sx={{ m: 0 }} />
                  {toUrl === currentUrl && (
                    <div style={{ background: '#00D03A', width: '10px', height: '10px', borderRadius: '50%' }}></div>
                  )}
                </ListItemButton>
              </Link>
            );
          })}
        </List>
      </Collapse>
    </Box>
  );
}
