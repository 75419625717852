import MockAdapter from 'axios-mock-adapter/types';
import siteAreaTableMock from '../../site/_mocks_/siteAreaTableMock';
import { FactoryProtocol } from '../FactoryProtocol';
import factoryTableMock from './factoryTableMock';

export default function factoryMockDetails(mock: MockAdapter) {
  mock.onGet(FactoryProtocol.FACTORY_MANAGEMENT_DATA).reply(200, {
    factoryTableMock,
  });
  mock.onPost(FactoryProtocol.FACTORY_MANAGEMENT_CREATE_DATA).reply(({ data }) => {
    const { area, factory, address, phone, uppercaseLetter, numbers } = JSON.parse(data);
    console.log({ area, factory, address, phone, uppercaseLetter, numbers });
    const areaFind = siteAreaTableMock.find((val: any) => val.value === area);
    const areaFilterToObject = Object.assign({}, areaFind);
    const sumSymbol = uppercaseLetter.concat('', numbers);
    if (area && factory && address && phone && uppercaseLetter) {
      const management = {
        id: generateUserId(),
        area: areaFilterToObject,
        factory,
        address,
        phone,
        uppercaseLetter,
        numbers,
        symbol: sumSymbol,
        state: '運轉中',
        disabled: false,
      };
      factoryTableMock.push(management);
      return [200, { ...management }];
    }
    return [400];
  });
  mock.onPut(FactoryProtocol.FACTORY_MANAGEMENT_UPDATE_DATA).reply(({ data }) => {
    console.log('data', data);
    const dataToJson = JSON.parse(data);
    console.log(dataToJson);
    const areaFilter = siteAreaTableMock.find((val: any) => val.value === dataToJson.area);
    const areaFilterToObject = Object.assign({}, areaFilter);
    console.log(areaFilterToObject);
    const index = factoryTableMock.findIndex((el) => el.id === dataToJson.id);
    console.log('index', index);
    const dataSymbol = dataToJson.uppercaseLetter.concat(dataToJson.numbers);
    console.log(dataSymbol);
    if (index !== -1) {
      factoryTableMock[index] = {
        ...dataToJson,
        area: areaFilterToObject,
        symbol: dataSymbol,
      };
      return [200, { ...dataToJson, area: areaFilterToObject, symbol: dataSymbol }];
    }
    console.log('error');
    return [400];
  });
  mock.onPut(FactoryProtocol.FACTORY_MANAGEMENT_UPDATE_STATE).reply(({ data }) => {
    console.log('data', data);
    const dataToJson = JSON.parse(data);
    const index = factoryTableMock.findIndex((el) => el.id === dataToJson.id);
    console.log('index', index);
    const mockDisable = factoryTableMock.map((el) => el.disabled);
    console.log(mockDisable);
    if (index !== -1) {
      if (mockDisable.includes(dataToJson.disabled)) {
        factoryTableMock[index] = {
          ...dataToJson,
          disabled: !dataToJson.disabled,
        };
        return [200, { ...dataToJson, disabled: !dataToJson.disabled }];
      } else {
        return [200];
      }
    }
    console.log('error');
    return [400];
  });
  mock.onGet(FactoryProtocol.FACTORY_MANAGEMENT_GET_ID).reply((config) => {
    const datas = factoryTableMock.find((el) => el.id === config.params.id);
    if (datas) {
      return [200, datas];
    }
    return [400];
  });
  function generateUserId() {
    const ids = factoryTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}
