import React from 'react';

import SelectTypeMenu from '../components/SelectTypeMenu';
import { useTranslation } from 'react-i18next';
import YatungPage from '@Src/_basic/components/YatungPage';

const background = '/media/images/applicationsMange/customStandardBG.png';
export default function SelectTypeScreen() {
  const { t: i18T } = useTranslation();

  return (
    <YatungPage
      title={i18T('QUALITY_MANAGE.SIDE_MENU.STANDARDIZED_PENALTY_MANAGEMENT.CUSTOM_STANDARD')}
      backgroundImage={background}
      body={<SelectTypeMenu />}
    />
  );
}
