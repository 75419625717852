export default [
  {
    value: 1,
    text: '砂',
  },
  {
    value: 2,
    text: '石',
  },
];
