import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungImg from '@Src/_basic/components/YatungImg';
import AreaManagementUpdateForm from './AreaManagementUpdateForm';
import { AreaData, PutUpdateDataRequest } from '@Src/_basic/object/AreaType';

const editImage = '/media/images/edit.png';

interface Props {
  areaUpdateData: AreaData;
  onUpdateGoBackButtonClick: () => void;
  updateModalOpen: boolean;
  setAreaData: (e: Array<AreaData>) => void;
  setLoading: (e: boolean) => void;
}
export default function AreaManagementUpdateCard(props: Props) {
  const { t: i18T } = useTranslation();
  const { areaUpdateData, onUpdateGoBackButtonClick, updateModalOpen, setAreaData, setLoading } = props;

  const initialArea: PutUpdateDataRequest = {
    id: areaUpdateData.id,
    name: areaUpdateData.name || '',
    sortingOrder: areaUpdateData.sortingOrder || 0,
    removed: areaUpdateData.removed || false,
  };

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={updateModalOpen}
        handleClose={onUpdateGoBackButtonClick}
        icon={
          <YatungImg
            img={editImage}
            sx={{
              width: 28,
              height: 28,
              zIndex: 999,
              alignSelf: 'center',
              display: 'flex',
              textAlign: 'center',
              mr: 1,
            }}
          />
        }
        title={i18T('AREAMANAGEMENT.UPDATE_AREA')}
        body={
          <AreaManagementUpdateForm
            initialValues={initialArea}
            onUpdateGoBackButtonClick={onUpdateGoBackButtonClick}
            setAreaData={setAreaData}
            setLoading={setLoading}
          />
        }
      />
    </Grid>
  );
}
