import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import { useCreateCustomStandardUI } from '../..';
import * as randomString from 'randomstring';
import { getMappedSpecificationDetail } from '@Src/_basic/helpers/StandardHelpers';
import DetectionItemCard from './components/DetectionItemCard';
import { Options } from '@Src/_basic/components/YatungSelect';
import { NotificationPriorityApi } from '@Src/_basic/protocol/notificationPriority/NotificationPriorityApi';
import { NotificationPriorityData } from '@Src/_basic/object/NotificationPriorityType';
import { SourceApi } from '@Src/_basic/protocol/source/SourceApi';
import { SearchSourcesParams, SourceData } from '@Src/_basic/object/SourceType';
import { SpecificationApi } from '@Src/_basic/protocol/specification/SpecificationApi';
import { SearchSpecificationsParams, SpecificationData } from '@Src/_basic/object/SpecificationType';
import { ExperimentDetectionItemApi } from '@Src/_basic/protocol/experimentDetectionItem/ExperimentDetectionItemApi';
import {
  ExperimentDetectionItemData,
  SearchExperimentDetectionItemsParams,
} from '@Src/_basic/object/ExperimentDetectionItemType';
import { CreateCustomStandardSpecificationFormData } from '@Src/_basic/object/CustomStandardType';

interface Props {
  typeId: number;
  variant?: 'source' | 'specification' | 'default';
  typeAmount?: number;
  specificationGroupsSize?: number;
  maxSpecificationsSize?: number;
  enableAddSpecification?: boolean;
  defaultSpecificationAmount?: number;
}

export default function SpecificationsFrom(props: Props) {
  const [specificationGroupsSize, setSpecificationGroupsSize] = useState<number>(2);
  const [maxSpecificationsSize, setMaxSpecificationsSize] = useState<number>(26);
  const [specificationGroups, setSpecificationGroups] = useState<
    Array<Array<CreateCustomStandardSpecificationFormData>>
  >([]);
  const [detectionItems, setDetectionItems] = useState<any[]>([]);
  const [notificationPriorityOptions, setNotificationPriorityOptions] = useState<Options[]>([]);
  const [dynamicSpecificationExtraDataOptions, setDynamicSpecificationExtraDataOptions] = useState<Options[]>([]);
  const [specificationExtraDataOptions, setSpecificationExtraDataOptions] = useState<Options[]>([]);

  const { formData, formDataDispatch } = useCreateCustomStandardUI();

  const isRenderAddSpecificationButton = () => {
    if (formData) {
      return (
        specificationExtraDataOptions.length > 0 &&
        maxSpecificationsSize &&
        formData.specifications.length < maxSpecificationsSize
      );
    }

    return false;
  };
  const addEmptySpecificationGroup = (amount: number) => {
    if (formData && formDataDispatch) {
      const emptySpecifications: Array<CreateCustomStandardSpecificationFormData> = [];
      [...Array(amount)].forEach(() => {
        const emptySpecification: CreateCustomStandardSpecificationFormData = {
          id: randomString.generate(10),
          sourceIds: [],
          specificationIds: [],
          details: [],
        };

        emptySpecifications.push(emptySpecification);
      });

      formDataDispatch({
        type: 'updateValue',
        payload: { specifications: [...formData.specifications, ...emptySpecifications] },
      });
    }
  };

  const getNotificationPriorityOptions = () => {
    NotificationPriorityApi.getNotificationPriorities(onGetNotificationPriorityOptionsSuccess);
  };

  const onGetNotificationPriorityOptionsSuccess = useCallback((data: Array<NotificationPriorityData>) => {
    setNotificationPriorityOptions(data.map(({ id, name }: NotificationPriorityData) => ({ value: id, text: name })));
  }, []);

  const getSpecificationExtraDataFieldName = () => {
    const FieldNameTable = {
      source: 'sourceIds',
      specification: 'specificationIds',
      default: 'defaultIds',
    };

    return FieldNameTable[props.variant || 'default'];
  };

  const getSpecificationExtraDataOptions = async () => {
    if (props.variant && props.typeId) {
      if (props.variant === 'source') {
        const searchParams: SearchSourcesParams = { typeId: props.typeId };
        SourceApi.getSourcesBySearch(searchParams, onGetSourcesBySearchSuccess);
      } else if (props.variant === 'specification') {
        const searchParams: SearchSpecificationsParams = { typeId: props.typeId };
        SpecificationApi.getSpecificationsBySearch(searchParams, onGetSpecificationsBySearch);
      } else {
        setSpecificationExtraDataOptions([]);
      }
    }
  };

  const getDynamicSpecificationExtraDataOptions = () => {
    if (formData && specificationExtraDataOptions.length > 0) {
      if (props.variant === 'source') {
        let sourceIds: Array<number> = [];
        formData.specifications.forEach((specification: any) => {
          sourceIds = sourceIds.concat(specification.sourceIds);
        });

        setDynamicSpecificationExtraDataOptions(
          specificationExtraDataOptions.filter((option: Options) => !sourceIds.includes(option.value)),
        );
      } else if (props.variant === 'specification') {
        let specificationIds: Array<number> = [];
        formData.specifications.forEach((specification: any) => {
          specificationIds = specificationIds.concat(specification.specificationIds);
        });

        setDynamicSpecificationExtraDataOptions(
          specificationExtraDataOptions.filter((option: Options) => !specificationIds.includes(option.value)),
        );
      } else {
        setDynamicSpecificationExtraDataOptions([]);
      }
    }
  };

  const onGetSourcesBySearchSuccess = (data: Array<SourceData>) => {
    setSpecificationExtraDataOptions(data.map(({ id, sourceName }: SourceData) => ({ value: id, text: sourceName })));
  };
  const onGetSpecificationsBySearch = (data: Array<SpecificationData>) => {
    setSpecificationExtraDataOptions(data.map(({ id, name }: SpecificationData) => ({ value: id, text: name })));
  };

  const getExperimentDetectionItems = () => {
    if (formData) {
      const searchParams: SearchExperimentDetectionItemsParams = {
        experimentResultTypeId: formData.experimentResultTypeId,
        sort: 'id,asc',
      };
      ExperimentDetectionItemApi.getExperimentDetectionItemsBySearch(searchParams, getExperimentDetectionItemsSuccess);
    }
  };

  const getExperimentDetectionItemsSuccess = (data: Array<ExperimentDetectionItemData>) => {
    setDetectionItems(data);
  };

  const getSpecificationGroups = () => {
    if (formData) {
      const specificationGroupsTemp: Array<Array<CreateCustomStandardSpecificationFormData>> = [];
      const totalSpecificationsTemp: Array<CreateCustomStandardSpecificationFormData> = [...formData.specifications];
      while (totalSpecificationsTemp.length > 0) {
        specificationGroupsTemp.push(
          totalSpecificationsTemp.splice(
            0,
            totalSpecificationsTemp.length > specificationGroupsSize
              ? specificationGroupsSize
              : totalSpecificationsTemp.length,
          ),
        );
      }

      setSpecificationGroups(specificationGroupsTemp);
    }
  };

  const handleAddSpecificationButtonClick = () => {
    addEmptySpecificationGroup(1);
  };

  useEffect(() => {
    getDynamicSpecificationExtraDataOptions();
  }, [specificationExtraDataOptions, formData]);

  useEffect(() => {
    if (props.specificationGroupsSize) {
      setSpecificationGroupsSize(props.specificationGroupsSize);
    }
  }, [props.specificationGroupsSize]);

  useEffect(() => {
    if (props.maxSpecificationsSize) {
      setMaxSpecificationsSize(props.maxSpecificationsSize);
    }
  }, [props.maxSpecificationsSize]);

  useEffect(() => {
    if (formData?.specifications.length === 0) {
      if (props.defaultSpecificationAmount) {
        addEmptySpecificationGroup(props.defaultSpecificationAmount);
      } else {
        addEmptySpecificationGroup(1);
      }
    }
  }, [formData?.specifications, props.defaultSpecificationAmount]);

  useEffect(() => {
    getSpecificationExtraDataOptions();
  }, [props.typeId, props.variant]);

  useEffect(() => {
    getExperimentDetectionItems();
  }, [formData?.experimentResultTypeId]);

  useEffect(() => {
    getSpecificationGroups();
  }, [formData?.specifications]);

  useEffect(() => {
    getNotificationPriorityOptions();
  }, []);
  return (
    <>
      <Stack divider={<YatungDivider />} spacing={3}>
        {specificationGroups.map(
          (specifications: Array<CreateCustomStandardSpecificationFormData>, specificationGroupIndex: number) => {
            return (
              <Stack key={specificationGroupIndex} direction="row" spacing={1}>
                {specifications.map(
                  (specification: CreateCustomStandardSpecificationFormData, specificationIndex: number) => {
                    return (
                      <Grid key={specification.id} item xs>
                        <Stack spacing={2}>
                          {getMappedSpecificationDetail(detectionItems, specification.details).map(
                            (detectionItem: any, detectionIndex: number) => {
                              return (
                                <DetectionItemCard
                                  key={detectionItem.id}
                                  title={`規格${String.fromCharCode(
                                    65 + specificationGroupIndex * specificationGroupsSize + specificationIndex,
                                  )}`}
                                  specificationId={specification.id}
                                  detectionItem={detectionItem}
                                  detectionIndex={detectionIndex}
                                  notificationPriorityOptions={notificationPriorityOptions}
                                  displayspecificationExtraDataSelect={
                                    props.variant !== undefined && props.variant !== 'default'
                                  }
                                  specificationExtraDataFieldName={getSpecificationExtraDataFieldName()}
                                  specificationExtraDataOptions={specificationExtraDataOptions}
                                  dynamicSpecificationExtraDataOptions={dynamicSpecificationExtraDataOptions}
                                  renderDeleteButton={formData?.specifications && formData?.specifications.length > 1}
                                  typeAmount={props.typeAmount ? props.typeAmount : 1}
                                />
                              );
                            },
                          )}
                        </Stack>
                      </Grid>
                    );
                  },
                )}
              </Stack>
            );
          },
        )}
      </Stack>
      {isRenderAddSpecificationButton() ? (
        <YatungButton
          variant="text"
          onClick={handleAddSpecificationButtonClick}
          sx={{ fontSize: 24, color: '#BB5E00', fontWeight: 'bold' }}
        >
          新增規格
        </YatungButton>
      ) : null}
    </>
  );
}
