import { AllTest } from '@Src/_basic/object/TestType';
import { CoarseSieve, CoarseSieveLegalTypeEnum } from '@Src/_basic/object/test/sandRock/CoarseSieveType';
import ExportCoarseSieveTable from '@Src/app/module/test/executeTest/coarseSieve/ExportCoarseSieveTable';
import React, { memo, useState } from 'react';

interface Props {
  testDetail: CoarseSieve;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
  closeExecuteTestHandler?: () => void;
}

const CoarseSieveAnalysisCommonTable = ({ testDetail, afterUpdateTest, canEdit, closeExecuteTestHandler }: Props) => {
  const [selectType, setSelectType] = useState<CoarseSieveLegalTypeEnum>(CoarseSieveLegalTypeEnum.TYPE1);

  return (
    <>
      <ExportCoarseSieveTable
        testDetail={testDetail}
        type={selectType}
        afterUpdateTest={afterUpdateTest}
        canEdit={canEdit}
        closeExecuteTestHandler={closeExecuteTestHandler}
      />
    </>
  );
};

export default memo(CoarseSieveAnalysisCommonTable);
