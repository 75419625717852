import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../Api';
import { DemandOrderProtocol } from './DemandOrderProtocol';
import { SampleSourceData, SearchSampleSourceParams } from '@Src/_basic/object/SampleSourceType';
import { CreateDemandOrderRequest, DemandOrderData } from '@Src/_basic/object/DemandOrderType';

export class DemandOrderApi {
  public static createDemandOrder(
    request: CreateDemandOrderRequest,
    onSuccess?: (data: DemandOrderData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(DemandOrderProtocol.DEMAND_ORDER, request, undefined, onSuccess, onFailed, onFinally);
  }
}
