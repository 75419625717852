import React, { memo } from 'react';
import { Stack, SxProps, Typography } from '@mui/material';

interface Props {
  label: string;
  labelStyle?: SxProps;
  children: React.ReactNode | string;
  textStyle?: SxProps;
}
const YatungRowField = (props: Props) => {
  const { label, labelStyle, children, textStyle } = props;
  return (
    <Stack sx={{ ...styles.container}}>
      <Typography sx={{ ...styles.textStyle, color:"#666", ...labelStyle }}>
        {label}
      </Typography>
      {typeof children === 'string' 
        ? <Typography sx={{ ...styles.textStyle, ...textStyle }}>{children}</Typography> 
        : children
      }
    </Stack>
  );
}

const styles = {
  container: { display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", flex:1 },
  textStyle: { fontSize:20, fontWeight:600, flex:1 }
}

export default memo(YatungRowField)