import { ConcreteExptData, FileData, SearchConcreteExptsParams } from '@Src/_basic/object/ProductionHistoryType';
import { ProductionHistoryApi } from '@Src/_basic/protocol/productionHistory/ProductionHistoryApi';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ExptInformationCards from './ExptInformationCards';
import Reports from './Reports';

interface Props {
  params: SearchConcreteExptsParams;
}

const divider = <Box sx={{ border: '1px dashed #7C878E' }} />;
const minDisplay = 1;
const maxDisplay = 3;
const cardSize = 232;

export default function InformationCard({ params }: Props) {
  const { projectInfo } = useProductionHistory();
  const [concreteExptsData, setConcreteExptsData] = useState<Array<ConcreteExptData>>([]);
  const [filesData, setFilesData] = useState<Array<FileData>>([]);

  const cardsSize = useMemo(() => {
    if (concreteExptsData.length < minDisplay) {
      return cardSize * minDisplay;
    }

    if (concreteExptsData.length > maxDisplay) {
      return cardSize * maxDisplay;
    }

    return cardSize * concreteExptsData.length;
  }, [filesData.length, concreteExptsData.length, cardSize, minDisplay, maxDisplay]);

  useEffect(() => {
    if (params) {
      ProductionHistoryApi.getConcreteExptsBySearch(params, setConcreteExptsData);
    }
  }, [params.startTime, params.endTime, params.realPropNames]);

  useEffect(() => {
    ProductionHistoryApi.getConcreteFileBySearch(
      {
        projectInfoId: projectInfo.projectInfoId,
        factoryId: projectInfo.factoryId,
        startTime: params.startTime,
        endTime: params.endTime,
        timezone: params.timezone,
      },
      setFilesData,
    );
  }, [projectInfo.factoryId, projectInfo.projectInfoId, params.startTime, params.endTime]);

  return (
    <Stack direction="row" divider={divider}>
      <Box
        sx={{
          width: '60%',
        }}
      >
        <ExptInformationCards
          concreteExptsData={concreteExptsData}
          height={cardsSize}
          itemSize={cardSize}
          onEmptyText={`${moment(params.startTime).format('YYYY/MM/DD')} ~ ${moment(params.endTime).format(
            'YYYY/MM/DD',
          )} 無資料`}
        />
      </Box>
      {cardsSize > 0 && (
        <Box
          sx={{
            height: `${cardsSize}px`,
            width: '40%',
          }}
        >
          <Reports reports={filesData} onEmptyDisplay={`${moment(params.startTime).format('YYYY/MM')} 無檔案`} />
        </Box>
      )}
    </Stack>
  );
}
