import { ExptScheduleData } from '@Src/_basic/object/ExptScheduleType';

const table: Array<ExptScheduleData> = [
  {
    id: 16,
    title: '測試排程1',
    areaId: 1,
    areaName: '北區',
    factoryId: 3,
    factoryName: '汐止廠',
    isRequirement: true,
    exptTypeId: 1,
    specificationId: 3,
    sourceId: 97,
    sampleSourceId: 3,
    storageId: 1,
    memo: '備註\n換行\n\n跳行',
    schedulePeriods: ['每週三'],
    period: {
      periodType: 'WEEK',
      periodWeekDays: [
        {
          weekDay: 4,
          length: 2,
        },
      ],
      periodDates: null,
    },
    scheduleItems: [
      {
        exptItemId: 28,
        exptItemName: '砂_高溫蒸煮試驗',
        operator: 'app_user01',
        operatorName: '品管APP總經理帳號',
        groupId: 1,
      },
      {
        exptItemId: 1,
        exptItemName: '砂_細粒料篩分析',
        operator: 'app_user01',
        operatorName: '品管APP總經理帳號',
        groupId: 1,
      },
      {
        exptItemId: 27,
        exptItemName: '砂_XRF成分分析',
        operator: 'app_user01',
        operatorName: '品管APP總經理帳號',
        groupId: 1,
      },
      {
        exptItemId: 26,
        exptItemName: '砂_AMS爐碴檢測',
        operator: 'app_user01',
        operatorName: '品管APP總經理帳號',
        groupId: 1,
      },
    ],
  },
];

export default table;
