import Stack from '@mui/material/Stack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';

import CreateIcon from '@Src/_basic/icons/Create';
import EditIcon from '@Src/_basic/icons/Edit';

import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import {
  MaterialPriceData,
  PostMaterialPriceRequest,
  PutMaterialPriceRequest,
} from '@Src/_basic/object/MaterialPriceType';
import { TypeData } from '@Src/_basic/object/TypeType';
import { MaterialPriceApi } from '@Src/_basic/protocol/materialPrice/MaterialPriceApi';
import { TypeApi } from '@Src/_basic/protocol/type/TypeApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { useLocation } from 'react-router-dom';
import AddModal from './components/AddModal';
import EditModal from './components/EditModal';
import MaterialPriceTable from './components/MaterialPriceTable';

type FilterValueType = {
  factoryId: number;
  areaId: number;
};

type AsyncStorageSavingType = FilterValueType;

export default function MaterialPrice() {
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [isVisiable, setIsVisiable] = useState<boolean>(false);

  const [{ factoryId, areaId }, setQuery] = useState<FilterValueType>({ factoryId: 0, areaId: 0 });
  const [types, setTypes] = useState<Array<Options>>([]);

  const [materialPriceList, setMaterialPriceList] = useState<Array<MaterialPriceData>>([]);
  const [selectedMaterialPrice, setSelectedMaterialPrice] = useState<MaterialPriceData>();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const reset = useCallback(() => {
    setIsVisiable(false);
    setMaterialPriceList([]);
  }, []);

  const selectPriceHandler = useCallback((selectPrice: MaterialPriceData) => {
    setIsEditModalOpen(true);
    setSelectedMaterialPrice(selectPrice);
  }, []);

  const onGetMaterialPriceList = useCallback((data: Array<MaterialPriceData>) => {
    setMaterialPriceList(
      data.map((item, index) => ({
        ...item,
        index: index + 1,
      })),
    );
    setIsVisiable(true);
  }, []);

  const getMaterialPriceList = useCallback(() => {
    MaterialPriceApi.getAllMaterialPrice(
      {
        factoryId: factoryId,
      },
      onGetMaterialPriceList,
    );
  }, [onGetMaterialPriceList, factoryId]);

  const addMaterialPriceHandler = useCallback(
    (data: PostMaterialPriceRequest) => {
      MaterialPriceApi.postMaterialPrice(data, () => {
        getMaterialPriceList();
        setIsAddModalOpen(false);
      });
    },
    [getMaterialPriceList],
  );

  const editMaterialPriceHandler = useCallback(
    (data: PutMaterialPriceRequest) => {
      MaterialPriceApi.putMaterialPrice(data, () => {
        getMaterialPriceList();
        setIsEditModalOpen(false);
      });
    },
    [getMaterialPriceList],
  );

  const handleChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    setQuery((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    TypeApi.getAllTypes((allTypes: Array<TypeData>) => {
      setTypes(allTypes.map(({ id, name }: TypeData) => ({ value: id, text: name })));
    });
  }, []);

  useEffect(() => {
    if (areaId) {
      if (!factoryId) {
        reset();
      } else {
        getMaterialPriceList();
        setSaveRequest({ page: location.pathname, request: { areaId, factoryId } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factoryId, areaId]);

  useEffect(() => {
    if (request?.page === location.pathname && request?.request) {
      Object.entries(request.request).forEach(([key, value]) => {
        setQuery((prev) => ({ ...prev, [key]: value }));
      });
    }
  }, [request, location.pathname]);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.MATERIAL_PRICE.TITLE')}
      body={
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <YatungCombinationSelect<FilterValueType>
              filterValue={{ areaId, factoryId }}
              handleChange={handleChange}
              selectOptions={['AreaAndFactory']}
            />
            <YatungButton
              text={i18T('APPLICATIONSMANAGEMENT.MATERIAL_PRICE.CREATE_MATERIAL')}
              startIcon={<CreateIcon />}
              disabled={!factoryId}
              onClick={() => setIsAddModalOpen(true)}
              color="green"
            />
          </Stack>

          <YatungFadeInOut isVisible={isVisiable}>
            <MaterialPriceTable data={materialPriceList} selectPriceHandler={selectPriceHandler} />
          </YatungFadeInOut>

          {isAddModalOpen && (
            <AddModal
              open={isAddModalOpen}
              handleClose={() => setIsAddModalOpen(false)}
              handleSubmit={addMaterialPriceHandler}
              icon={<CreateIcon sx={{ mr: 1 }} fontSize="large" />}
              title={i18T('APPLICATIONSMANAGEMENT.MATERIAL_PRICE.CREATE_MATERIAL_PRICE')}
              factoryId={factoryId}
              allTypes={types}
            />
          )}
          {selectedMaterialPrice && isEditModalOpen && (
            <EditModal
              open={isEditModalOpen}
              handleClose={() => setIsEditModalOpen(false)}
              handleSubmit={editMaterialPriceHandler}
              icon={<EditIcon sx={{ mr: 1 }} fontSize="large" />}
              title={i18T('APPLICATIONSMANAGEMENT.MATERIAL_PRICE.EDIT_MATERIAL_PRICE')}
              factoryId={factoryId}
              selectedMaterialPrice={selectedMaterialPrice}
            />
          )}
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
