import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function RockBig(props: SvgIconProps) {
    return (
        <svg {...props} width="56" height="53" viewBox="0 0 56 53" fill="none" >
            <path d="M15.3026 5.93249C15.9999 5.93249 16.5633 5.34908 16.5633 4.62909C16.5633 3.9091 15.9999 3.32568 15.3026 3.32568C14.6052 3.32568 14.0418 3.9091 14.0418 4.62909C14.0418 5.05622 14.24 5.43509 14.5467 5.67279C14.6204 5.73025 14.7011 5.7795 14.7862 5.81889L14.8561 5.84877C14.8846 5.85993 14.9139 5.87011 14.9437 5.87897C15.0015 5.8967 15.0606 5.91016 15.1215 5.91903C15.1514 5.92362 15.1812 5.92691 15.2117 5.92921C15.2416 5.9315 15.2721 5.93249 15.3026 5.93249Z" fill="#7C878E" />
            <path d="M10.0627 32.5055C10.024 32.5298 9.98332 32.5521 9.9414 32.5721C9.89758 32.5931 9.85248 32.6115 9.80612 32.6276L9.74515 32.6467C9.72038 32.6539 9.69497 32.6601 9.66956 32.6657C9.58573 32.6838 9.49871 32.6933 9.40916 32.6933C8.71178 32.6933 8.14842 32.1099 8.14842 31.3899C8.14842 30.6699 8.71178 30.0865 9.40916 30.0865C10.1065 30.0865 10.6699 30.6699 10.6699 31.3899C10.6699 31.494 10.6578 31.5954 10.6356 31.6926C10.6051 31.8259 10.5549 31.9513 10.4883 32.0652C10.4273 32.169 10.353 32.2632 10.2672 32.3456L10.231 32.3791C10.2101 32.3981 10.1885 32.4165 10.1656 32.4339C10.1326 32.4595 10.0983 32.4835 10.0627 32.5055Z" fill="#7C878E" />
            <path d="M24.1912 32.2885C23.962 32.538 23.6374 32.6933 23.2773 32.6933C22.7215 32.6933 22.2522 32.3239 22.0832 31.8101C22.04 31.6781 22.0165 31.537 22.0165 31.3899C22.0165 30.6699 22.5793 30.0865 23.2773 30.0865C23.9747 30.0865 24.538 30.6699 24.538 31.3899C24.538 31.5557 24.5082 31.7143 24.4535 31.86C24.4275 31.9306 24.3951 31.9982 24.3576 32.0623C24.31 32.144 24.2541 32.2199 24.1912 32.2885Z" fill="#7C878E" />
            <path d="M37.1454 32.6933C37.8428 32.6933 38.4061 32.1099 38.4061 31.3899C38.4061 31.339 38.4036 31.2888 38.3979 31.2395C38.3909 31.1748 38.3788 31.1115 38.363 31.0501C38.3401 30.9611 38.3083 30.8761 38.2683 30.7956C38.0594 30.3747 37.6357 30.0865 37.1454 30.0865C36.4474 30.0865 35.8847 30.6699 35.8847 31.3899C35.8847 32.1099 36.4474 32.6933 37.1454 32.6933Z" fill="#7C878E" />
            <path d="M52.8214 30.2881C52.7014 30.4118 52.5572 30.511 52.3971 30.577L52.3654 30.5895L52.3006 30.6118L52.253 30.6262C52.2142 30.6367 52.1748 30.6453 52.1348 30.6522C52.0681 30.6633 52.0002 30.6689 51.9303 30.6689C51.2329 30.6689 50.6696 30.0855 50.6696 29.3655C50.6696 28.6455 51.2329 28.0621 51.9303 28.0621C52.6283 28.0621 53.191 28.6455 53.191 29.3655C53.191 29.4985 53.172 29.6268 53.1364 29.7477C53.0748 29.9545 52.9656 30.1393 52.8214 30.2881Z" fill="#7C878E" />
            <path d="M29.621 5.84713C29.4813 5.90228 29.3288 5.93249 29.17 5.93249C28.4727 5.93249 27.9099 5.34908 27.9099 4.62909C27.9099 3.9091 28.4727 3.32568 29.17 3.32568C29.8681 3.32568 30.4308 3.9091 30.4308 4.62909C30.4308 4.94098 30.3253 5.22727 30.1488 5.45184C30.0103 5.62716 29.8293 5.76472 29.621 5.84713Z" fill="#7C878E" />
            <path d="M43.0382 5.93249C43.4231 5.93249 43.7667 5.7552 43.9972 5.47548C44.1852 5.24796 44.2989 4.95215 44.2989 4.62909C44.2989 3.9091 43.7362 3.32568 43.0382 3.32568C42.3408 3.32568 41.7774 3.9091 41.7774 4.62909C41.7774 4.78372 41.8035 4.93212 41.8511 5.06968C41.8638 5.10678 41.8784 5.14322 41.8943 5.17868C41.9114 5.21545 41.9299 5.25157 41.9495 5.2867L41.9883 5.35105C42.0042 5.37534 42.02 5.39898 42.0372 5.42196L42.0804 5.47745C42.3116 5.75618 42.6545 5.93249 43.0382 5.93249Z" fill="#7C878E" />
            <path d="M47.9582 24.7934C47.9582 25.5134 47.3948 26.0968 46.6974 26.0968C46.0001 26.0968 45.4367 25.5134 45.4367 24.7934C45.4367 24.0734 46.0001 23.49 46.6974 23.49C47.0417 23.49 47.3535 23.6322 47.5809 23.8626C47.6616 23.9447 47.7321 24.038 47.7892 24.1404C47.8966 24.3325 47.9582 24.5554 47.9582 24.7934Z" fill="#7C878E" />
            <path d="M9.36597 20.6511C9.39392 20.5427 9.40916 20.4288 9.40916 20.3113C9.40916 19.5913 8.8458 19.0079 8.14842 19.0079C7.45105 19.0079 6.88768 19.5913 6.88768 20.3113C6.88768 20.5703 6.96072 20.8116 7.08648 21.0142C7.22621 21.2401 7.43199 21.4184 7.67525 21.5201C7.82133 21.5812 7.98075 21.6147 8.14842 21.6147C8.61143 21.6147 9.01538 21.3576 9.2345 20.9741C9.29166 20.874 9.33612 20.7657 9.36597 20.6511Z" fill="#7C878E" />
            <path d="M23.2773 20.3113C23.2773 21.0313 22.7139 21.6147 22.0165 21.6147C21.3185 21.6147 20.7558 21.0313 20.7558 20.3113C20.7558 19.5916 21.3185 19.0079 22.0165 19.0079C22.4021 19.0079 22.7463 19.1862 22.9775 19.4672C23.1642 19.6947 23.2773 19.9895 23.2773 20.3113Z" fill="#7C878E" />
            <path d="M35.884 21.6147C36.582 21.6147 37.1448 21.0313 37.1448 20.3113C37.1448 19.5916 36.582 19.0079 35.884 19.0079C35.1867 19.0079 34.6233 19.5916 34.6233 20.3113C34.6233 21.0313 35.1867 21.6147 35.884 21.6147Z" fill="#7C878E" />
            <path d="M51.6712 19.0788C51.4413 19.3897 51.0786 19.5903 50.6696 19.5903C49.9722 19.5903 49.4088 19.0069 49.4088 18.2869C49.4088 17.8 49.6667 17.3752 50.0497 17.1516C50.2326 17.0446 50.4441 16.9835 50.6696 16.9835C51.3676 16.9835 51.9303 17.5672 51.9303 18.2869C51.9303 18.585 51.8338 18.8595 51.6712 19.0788Z" fill="#7C878E" />
            <path d="M13.8214 13.1012C14.5188 13.1012 15.0822 12.5178 15.0822 11.7978C15.0822 11.0778 14.5188 10.4944 13.8214 10.4944C13.1241 10.4944 12.5607 11.0778 12.5607 11.7978C12.5607 12.5178 13.1241 13.1012 13.8214 13.1012Z" fill="#7C878E" />
            <path d="M28.9503 11.7978C28.9503 12.5178 28.3869 13.1012 27.6895 13.1012C26.9915 13.1012 26.4288 12.5178 26.4288 11.7978C26.4288 11.0778 26.9915 10.4944 27.6895 10.4944C28.3869 10.4944 28.9503 11.0778 28.9503 11.7978Z" fill="#7C878E" />
            <path d="M42.4399 12.7292C42.6736 12.4928 42.8184 12.1629 42.8184 11.7978C42.8184 11.0778 42.255 10.4944 41.5577 10.4944C40.8597 10.4944 40.2969 11.0778 40.2969 11.7978C40.2969 12.5178 40.8597 13.1012 41.5577 13.1012C41.9013 13.1012 42.2125 12.9594 42.4399 12.7292Z" fill="#7C878E" />
            <path d="M10.6699 45.7273C10.6699 46.4473 10.1065 47.0307 9.40916 47.0307C8.71178 47.0307 8.14842 46.4473 8.14842 45.7273C8.14842 45.4427 8.23607 45.1797 8.38533 44.9653C8.61461 44.6373 8.98743 44.4239 9.40916 44.4239C10.1065 44.4239 10.6699 45.0073 10.6699 45.7273Z" fill="#7C878E" />
            <path d="M23.2773 47.0307C23.5853 47.0307 23.8667 46.9171 24.0852 46.7283C24.3621 46.4893 24.538 46.1295 24.538 45.7273C24.538 45.0073 23.9747 44.4239 23.2773 44.4239C22.5793 44.4239 22.0165 45.0073 22.0165 45.7273C22.0165 46.4473 22.5793 47.0307 23.2773 47.0307Z" fill="#7C878E" />
            <path d="M38.4061 45.7273C38.4061 46.4473 37.8428 47.0307 37.1454 47.0307C36.4474 47.0307 35.8847 46.4473 35.8847 45.7273C35.8847 45.0073 36.4474 44.4239 37.1454 44.4239C37.8428 44.4239 38.4061 45.0073 38.4061 45.7273Z" fill="#7C878E" />
            <path d="M4.36621 39.862C5.06358 39.862 5.62695 39.2786 5.62695 38.5586C5.62695 37.8386 5.06358 37.2552 4.36621 37.2552C3.66883 37.2552 3.10547 37.8386 3.10547 38.5586C3.10547 39.2786 3.66883 39.862 4.36621 39.862Z" fill="#7C878E" />
            <path d="M19.4951 38.5586C19.4951 39.2786 18.9317 39.862 18.2343 39.862C17.5363 39.862 16.9736 39.2786 16.9736 38.5586C16.9736 37.8386 17.5363 37.2552 18.2343 37.2552C18.7697 37.2552 19.2264 37.5993 19.4093 38.0852C19.4646 38.2319 19.4951 38.3915 19.4951 38.5586Z" fill="#7C878E" />
            <path d="M32.1025 39.862C32.7998 39.862 33.3632 39.2786 33.3632 38.5586C33.3632 37.8386 32.7998 37.2552 32.1025 37.2552C31.4044 37.2552 30.8417 37.8386 30.8417 38.5586C30.8417 38.8656 30.944 39.1476 31.1155 39.3702C31.346 39.6699 31.7017 39.862 32.1025 39.862Z" fill="#7C878E" />
            <path d="M48.1481 36.5342C48.1481 37.2542 47.5854 37.8376 46.8874 37.8376C46.19 37.8376 45.6266 37.2542 45.6266 36.5342C45.6266 35.8142 46.19 35.2308 46.8874 35.2308C47.5854 35.2308 48.1481 35.8142 48.1481 36.5342Z" fill="#7C878E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.371284 16.9711L0.363281 16.9828H0.371284V52.4931H43.928L55.0026 37.1004V0.594176L11.5867 0.593384L0.363281 15.617L0.371284 16.9711ZM12.3046 2.03504L2.11023 15.6139L43.1141 15.5411L52.356 2.03504H12.3046ZM53.6862 2.51799V36.687L43.2477 51.1956H1.6877V16.9828H43.7883L53.6862 2.51799Z" fill="#7C878E" />
        </svg>
    );
}