import { Options } from '@Src/_basic/components/YatungSelect';
import { PeriodDayEnum, PeriodTypeEnum, ScheduleData, ScheduleWeekDay } from '@Src/_basic/object/ExptScheduleType';
import { Box, Stack } from '@mui/material';
import React, { CSSProperties, memo, useCallback, useEffect, useState } from 'react';
import WeekSelect from './components/WeeklySelect';
import YearlySelect from './components/YearlySelect';

interface Props {
  periodType: PeriodTypeEnum | string | undefined;
  periodWeekDays: Array<ScheduleWeekDay> | null | undefined;
  periodDates: Array<ScheduleData> | null | undefined;
  setFieldValue: (field: string, value: any) => void;
  style?: CSSProperties;
  updateMode?: boolean;
}
const weekOptions: Array<Options> = [
  { text: '日', value: PeriodDayEnum.SUNDAY },
  { text: '一', value: PeriodDayEnum.MONDAY },
  { text: '二', value: PeriodDayEnum.TUESDAY },
  { text: '三', value: PeriodDayEnum.WEDNESDAY },
  { text: '四', value: PeriodDayEnum.THURSDAY },
  { text: '五', value: PeriodDayEnum.FRIDAY },
  { text: '六', value: PeriodDayEnum.SATURDAY },
];

export default memo(function PeriodForm(props: Props) {
  const [activateValue, setActivateValue] = useState<Array<PeriodTypeEnum | string>>([]);

  const dynamicWeekOptions = () => {
    if (props.periodWeekDays) {
      const weekDay: Array<number> = props.periodWeekDays.map((item: any) => item.weekDay);
      return weekOptions.filter((option: Options) => !weekDay.includes(Number(option.value)));
    }
  };

  const handlePeriodTypeChange = useCallback(
    (value: any) => {
      const period = {
        periodType: props.periodType,
        periodWeekDays: props.periodWeekDays ? [...props.periodWeekDays] : [],
        periodDates: props.periodDates ? [...props.periodDates] : [],
      };

      if (activateValue.includes(value) && !props.updateMode) {
        props.setFieldValue('period', { ...period, periodType: undefined });
      } else {
        props.setFieldValue('period', { ...period, periodType: value });
      }
    },
    [props.periodType, props.periodWeekDays, props.periodDates, activateValue, setActivateValue],
  );

  const handleWeekSelectChange = useCallback(
    (index: number, value: number) => {
      const period = {
        periodType: props.periodType,
        periodWeekDays: props.periodWeekDays ? [...props.periodWeekDays] : [],
        periodDates: props.periodDates ? [...props.periodDates] : [],
      };
      if (period.periodWeekDays) {
        period.periodWeekDays[index] = { ...period.periodWeekDays[index], weekDay: value };
        props.setFieldValue('period', { ...period });
      }
    },
    [props.periodType, props.periodWeekDays, props.periodDates],
  );

  const handleWeekInputChange = useCallback(
    (index: number, value: number) => {
      const period = {
        periodType: props.periodType,
        periodWeekDays: props.periodWeekDays ? [...props.periodWeekDays] : [],
        periodDates: props.periodDates ? [...props.periodDates] : [],
      };
      if (period.periodWeekDays) {
        period.periodWeekDays[index] = { ...period.periodWeekDays[index], length: value };
        props.setFieldValue('period', { ...period });
      }
    },
    [props.periodType, props.periodWeekDays, props.periodDates],
  );

  const handleYearlyStartMonthSelectChange = useCallback(
    (index: number, value: number) => {
      const period = {
        periodType: props.periodType,
        periodWeekDays: props.periodWeekDays ? [...props.periodWeekDays] : [],
        periodDates: props.periodDates ? [...props.periodDates] : [],
      };
      if (period.periodDates) {
        period.periodDates[index] = { ...period.periodDates[index], startMonth: value };
        props.setFieldValue('period', { ...period });
      }
    },
    [props.periodType, props.periodWeekDays, props.periodDates],
  );

  const handleYearlyStartDaySelectChange = useCallback(
    (index: number, value: number) => {
      const period = {
        periodType: props.periodType,
        periodWeekDays: props.periodWeekDays ? [...props.periodWeekDays] : [],
        periodDates: props.periodDates ? [...props.periodDates] : [],
      };
      if (period.periodDates) {
        period.periodDates[index] = { ...period.periodDates[index], startDay: value };
        props.setFieldValue('period', { ...period });
      }
    },
    [props.periodType, props.periodWeekDays, props.periodDates],
  );

  const handleYearlyEndMonthSelectChange = useCallback(
    (index: number, value: number) => {
      const period = {
        periodType: props.periodType,
        periodWeekDays: props.periodWeekDays ? [...props.periodWeekDays] : [],
        periodDates: props.periodDates ? [...props.periodDates] : [],
      };
      if (period.periodDates) {
        period.periodDates[index] = { ...period.periodDates[index], endMonth: value };
        props.setFieldValue('period', { ...period });
      }
    },
    [props.periodType, props.periodWeekDays, props.periodDates],
  );

  const handleYearlyEndDaySelectChange = useCallback(
    (index: number, value: number) => {
      const period = {
        periodType: props.periodType,
        periodWeekDays: props.periodWeekDays ? [...props.periodWeekDays] : [],
        periodDates: props.periodDates ? [...props.periodDates] : [],
      };
      if (period.periodDates) {
        period.periodDates[index] = { ...period.periodDates[index], endDay: value };
        props.setFieldValue('period', { ...period });
      }
    },
    [props.periodType, props.periodWeekDays, props.periodDates],
  );

  const handleYearlyAddButtonClick = useCallback(() => {
    const period = {
      periodType: props.periodType,
      periodWeekDays: props.periodWeekDays ? [...props.periodWeekDays] : [],
      periodDates: props.periodDates ? [...props.periodDates] : [],
    };

    if (period.periodDates) {
      const obj = { startMonth: 1, startDay: 1, endMonth: 12, endDay: 31 };
      period.periodDates.push(obj);
      props.setFieldValue('period', { ...period });
    }

    //週排成設定最多只能新增7個
    if (period.periodWeekDays && period.periodWeekDays.length < weekOptions.length) {
      const week: any = dynamicWeekOptions()?.map((option: Options) => Number(option.value));
      period.periodWeekDays?.push({ weekDay: week?.[0], length: 1 });
      props.setFieldValue('period', { ...period });
    }
  }, [props.periodType, props.periodWeekDays, props.periodDates]);

  const handleYearlyDeleteButtonClick = useCallback(
    (index: number) => {
      const period = {
        periodType: props.periodType,
        periodWeekDays: props.periodWeekDays ? [...props.periodWeekDays] : [],
        periodDates: props.periodDates ? [...props.periodDates] : [],
      };
      if (period.periodWeekDays || period.periodDates) {
        period.periodWeekDays?.splice(index, 1);
        period.periodDates?.splice(index, 1);
        props.setFieldValue('period', { ...period });
      }
    },
    [props.periodType, props.periodWeekDays, props.periodDates],
  );

  useEffect(() => {
    if (props.periodType) {
      setActivateValue([props.periodType]);
    } else {
      setActivateValue([]);
    }
  }, [props.periodType]);

  return (
    <Box style={props.style}>
      <Stack>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <WeekSelect
            value={PeriodTypeEnum.WEEK}
            activateValue={activateValue}
            onChange={handlePeriodTypeChange}
            periodWeekDays={props.periodWeekDays || []}
            onSelected={handleWeekSelectChange}
            onInputChange={handleWeekInputChange}
            dynamicWeekOptions={dynamicWeekOptions()}
            weekOptions={weekOptions}
          />
          <YearlySelect
            value={PeriodTypeEnum.DATE}
            activateValue={activateValue}
            onChange={handlePeriodTypeChange}
            periodDates={props.periodDates || []}
            onStartMonthSelected={handleYearlyStartMonthSelectChange}
            onStartDaySelected={handleYearlyStartDaySelectChange}
            onEndMonthSelected={handleYearlyEndMonthSelectChange}
            onEndDaySelected={handleYearlyEndDaySelectChange}
            onAddButtonClick={handleYearlyAddButtonClick}
            onDeleteButtonClick={handleYearlyDeleteButtonClick}
          />
        </Box>
      </Stack>
    </Box>
  );
});
