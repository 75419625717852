import YatungDialog from '@Src/_basic/components/YatungDialog';
import AddEquipment from '@Src/_basic/icons/AddEquipment';
import CancelIcon from '@Src/_basic/icons/Cancel';
import { MonitorOrderData } from '@Src/_basic/object/MonitorType';
import { Grid, Stack } from '@mui/material';
import React from 'react';
import ViewImageForm from './ViewImageForm';

interface Props {
  updateModalOpen: boolean;
  onGoBackButtonClick: () => void;
  data: MonitorOrderData | undefined;
  imageUrl: string[];
}

export default function ViewImageCard(props: Props) {
  const { updateModalOpen, onGoBackButtonClick, data,imageUrl } = props;

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={updateModalOpen}
        handleClose={onGoBackButtonClick}
        icon={<AddEquipment sx={{ width: 40, height: 50, mr: 1, alignSelf: 'center' }} />}
        headerComponent={
          <div>
            <Stack>
              <Stack flexDirection="row" justifyContent="flex-end">
                <Stack flexDirection={'column'} justifyContent={'center'}>
                  <div>
                    <CancelIcon onClick={onGoBackButtonClick} style={{ width: '40px', height: '40px' }} />
                  </div>
                </Stack>
              </Stack>
            </Stack>
          </div>
        }
        body={<ViewImageForm data={data} imageUrl={imageUrl} />}
      />
    </Grid>
  );
}
