import { SelectChangeEvent, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useApi } from '@Src/redux/api/apiAction';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';

import NewStaffIcon from '@Src/_basic/icons/NewStaff';
import SearchIcon from '@Src/_basic/icons/Search';
import { FilterValue } from '..';

interface Props {
  filterValue: FilterValue;
  handleChange: (file: keyof FilterValue, value: FilterValue[keyof FilterValue]) => void;
  factoryOptions: Array<Options>;
  postGetRoleList: () => void;
  openCreateCard: () => void;
}

export default function RoleMaintenanceSearchBar({
  filterValue,
  handleChange,
  factoryOptions,
  postGetRoleList,
  openCreateCard,
}: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
        <YatungSelect
          helperText={i18T('GLOBAL.FACTORY')}
          disabled={actionLoading}
          options={factoryOptions}
          value={filterValue.factory}
          onChange={(e: SelectChangeEvent<unknown>) => {
            handleChange('factory', e.target.value as number);
          }}
        />
        <YatungInput
          value={filterValue.inputValues}
          onChange={(e: any) => handleChange('inputValues', e.target.value)}
        />
        <YatungButton
          disabled={actionLoading || !filterValue.factory}
          text={i18T('GLOBAL.SEARCh_BUTTON')}
          startIcon={<SearchIcon />}
          color="blue"
          onClick={postGetRoleList}
        />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <YatungButton
          text={i18T('USERSMANAGEMENU.ROLE_MAINTENANCE.CREATE_ROLE')}
          variant={'contained'}
          type={'button'}
          size={'large'}
          startIcon={<NewStaffIcon />}
          onClick={openCreateCard}
          color={'green'}
          sx={{
            fontSize: '16px',
          }}
        />
      </Stack>
    </Stack>
  );
}
