export function customSort(a: string, b: string): number {
  if (!a) return 1;
  // 首先比較數字部分
  const numA = parseInt(a, 10);
  const numB = parseInt(b, 10);

  if (numA < numB) {
    return -1;
  } else if (numA > numB) {
    return 1;
  }

  // 如果數字相等，則比較字母部分
  return a.localeCompare(b);
}
