import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import ReturnAndLossScreen from './screens/ReturnAndLossScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <ReturnAndLossScreen />,
  },
];

const ReturnAndLossRouter = () => PermissionRoute(routesConfig);

export default memo(ReturnAndLossRouter);
