import DoneIcon from '@mui/icons-material/Done';
import SettingsIcon from '@mui/icons-material/Settings';
import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Stack, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StationType } from '../object/SiteType';
import { Options } from './YatungFormSelect';

interface Props {
  options: Array<Options>;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  initialCheckedValue?: Array<number>;
  labelTextStyle?: CSSProperties;
  sx?: any;
  isFactory?: boolean;
  optionComponent?: Array<React.ReactNode>;
  column?: boolean;
  updateWbStationTypeId: any;
  onCheck: any;
}

export default function YatungMenuCheckBox(props: Props) {
  const {
    options,
    onChange,
    initialCheckedValue,
    sx,
    onBlur,
    optionComponent,
    column,
    updateWbStationTypeId,
    onCheck,
  } = props;

  const { t: i18T } = useTranslation();
  const [anchorElArr, setAnchorElArr] = useState<(HTMLElement | null)[]>(new Array(options.length).fill(null));
  const [openArr, setOpenArr] = useState<boolean[]>(new Array(options.length).fill(false));
  const [selectedItemsArr, setSelectedItemsArr] = useState<
    { typeId: number; isBillOfLandingCode: boolean; isDeliveryTicketCode: boolean; isExportValue: boolean }[]
  >(
    options.map((option) => {
      const setting = updateWbStationTypeId[0].stationTypeSettings.find(
        (setting: StationType) => setting.typeId === option.value, //對應的typeId再輸入進value，否則順序會錯
      );
      return {
        typeId: option.value,
        isBillOfLandingCode: setting?.isBillOfLandingCode ?? false,
        isDeliveryTicketCode: setting?.isDeliveryTicketCode ?? false,
        isExportValue: setting?.isExportValue ?? false,
      };
    }),
  );

  const getChecked = (id: number) => {
    if (initialCheckedValue) {
      return initialCheckedValue.includes(id);
    } else {
      return false;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    const newOpenArr = [...openArr];
    newOpenArr[index] = true;
    setOpenArr(newOpenArr);

    const newAnchorElArr = [...anchorElArr];
    newAnchorElArr[index] = event.currentTarget;
    setAnchorElArr(newAnchorElArr);
  };

  const handleClose = (index: number) => {
    const newOpenArr = [...openArr];
    newOpenArr[index] = false;
    setOpenArr(newOpenArr);

    const newAnchorElArr = [...anchorElArr];
    newAnchorElArr[index] = null;
    setAnchorElArr(newAnchorElArr);
  };

  const handleMenuItemClick = (optionIndex: number, menuItemIndex: number) => {
    const newSelectedItemsArr = [...selectedItemsArr];
    const selectedItem = { ...newSelectedItemsArr[optionIndex] };
    switch (menuItemIndex) {
      case 0:
        selectedItem.isBillOfLandingCode = !selectedItem.isBillOfLandingCode;
        break;
      case 1:
        selectedItem.isDeliveryTicketCode = !selectedItem.isDeliveryTicketCode;
        break;
      case 2:
        selectedItem.isExportValue = !selectedItem.isExportValue;
        break;
      default:
        break;
    }

    newSelectedItemsArr[optionIndex] = selectedItem;
    setSelectedItemsArr(newSelectedItemsArr);
  };

  useEffect(() => {
    // 在 selectedItemsArr 更新後觸發 onCheck 函數
    const updatedSelectedItemsArr = selectedItemsArr.filter((item) => initialCheckedValue?.includes(item.typeId));
    onCheck(updatedSelectedItemsArr);
  }, [selectedItemsArr]);

  return (
    <FormControl>
      <FormGroup row={true} sx={{ flexDirection: column ? 'column' : 'row' }}>
        {options.map((option: any, index: number) => (
          <React.Fragment key={index}>
            <FormControlLabel
              control={
                <>
                  <Checkbox
                    value={option.value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={option.disabled}
                    checked={getChecked?.(option.value)}
                    onClick={() => {
                      // 在 Checkbox 的 onChange 事件觸發時將值傳遞給 onCheck 函數
                      const typeId = option.value;
                      const updatedSelectedItemsArr = [...selectedItemsArr];
                      const selectedItemIndex = updatedSelectedItemsArr.findIndex((item) => item.typeId === typeId);

                      updatedSelectedItemsArr[selectedItemIndex] = {
                        ...updatedSelectedItemsArr[selectedItemIndex],
                        typeId: typeId,
                        isBillOfLandingCode: selectedItemsArr[selectedItemIndex].isBillOfLandingCode,
                        isDeliveryTicketCode: selectedItemsArr[selectedItemIndex].isDeliveryTicketCode,
                        isExportValue: selectedItemsArr[selectedItemIndex].isExportValue,
                      };
                      setSelectedItemsArr(updatedSelectedItemsArr);
                    }}
                  />
                </>
              }
              label={
                optionComponent ? (
                  <Stack flexDirection={'row'} alignItems="center" key={option.value}>
                    <Typography sx={{ flex: 1 }}>{option.text}</Typography>
                    <Stack sx={{ flex: 1 }}>{optionComponent[index]}</Stack>
                  </Stack>
                ) : (
                  <Typography sx={{ fontSize: 24, ...props.labelTextStyle }}>
                    <Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                      {option.text}
                    </Stack>
                  </Typography>
                )
              }
              sx={sx || { mr: 5 }}
            />
            {getChecked?.(option.value) ? (
              <Stack onClick={(event) => handleClick(event, index)}>
                <SettingsIcon style={{ marginTop: '7px' }} />
              </Stack>
            ) : null}
            <Menu anchorEl={anchorElArr[index]} open={openArr[index]} onClose={() => handleClose(index)}>
              <MenuItem onClick={() => handleMenuItemClick(index, 0)}>
                {selectedItemsArr[index]?.isBillOfLandingCode && <DoneIcon style={{ marginLeft: '10px' }} />}
                {i18T('GLOBAL.ISBILLOFLANDINGCODE')}
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick(index, 1)}>
                {selectedItemsArr[index]?.isDeliveryTicketCode && <DoneIcon style={{ marginLeft: '10px' }} />}
                {i18T('GLOBAL.ISDELIVERYTICKETCODE')}
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick(index, 2)}>
                {selectedItemsArr[index]?.isExportValue && <DoneIcon style={{ marginLeft: '10px' }} />}
                {i18T('GLOBAL.ISEXPORTVALUE')}
              </MenuItem>
            </Menu>
          </React.Fragment>
        ))}
      </FormGroup>
    </FormControl>
  );
}
