import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import YatungFormSelect from '@Src/_basic/components/YatungFormSelect';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import { useTranslation } from 'react-i18next';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import { Options } from '@Src/_basic/components/YatungSelect';
import { AreaData } from '@Src/_basic/object/AreaType';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';

const actionImage = '/media/images/action.png';

export default function FactoryManagementCreateField() {
  const { t: i18T } = useTranslation();
  const [areaDataOption, setAreaDataOption] = useState<Options[]>([]);

  const getAllArea = () => {
    AreaApi.getAllAreas(areaDataToAreaOptions);
  };

  const areaDataToAreaOptions = (data: Array<AreaData>) => {
    const areaOptions: Options[] = data.map(({ id, name }: any) => ({
      value: id,
      text: name,
    }));
    setAreaDataOption(areaOptions);
  };
  useEffect(() => {
    getAllArea();
  }, []);
  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('FACTORYMANAGEMENT.AREA')} />
        </Grid>
        <Grid container item xs={11}>
          <Field
            id="areaId"
            name="areaId"
            type="number"
            label=""
            component={YatungFormSelect}
            sx={{ minWidth: '70%' }}
            options={areaDataOption}
            required
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('FACTORYMANAGEMENT.FACTORY')} />
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={11}>
          <Field
            id="factoryName"
            name="factoryName"
            type="text"
            label=""
            component={YatungFormInput}
            sx={{ minWidth: '70%' }}
            position={'end'}
            textColor={'#3E9B2F'}
            text={i18T('GLOBAL.RUNNING')}
            required
          />
          <YatungButton
            text={i18T('FACTORYMANAGEMENT.ACTION_CLOSE')}
            variant="contained"
            color="red"
            type={'button'}
            size={'large'}
            startIcon={<YatungImg img={actionImage} />}
            sx={{
              fontSize: '20px',
              ml: 2,
              '&.Mui-disabled': {
                bgcolor: '#d32f2f',
                color: '#ffffff',
                opacity: 0.6,
              },
            }}
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('FACTORYMANAGEMENT.ADDRESS')} />
        </Grid>
        <Grid container item xs={11}>
          <Field
            id="factoryAddress"
            name="factoryAddress"
            type="text"
            label=""
            component={YatungFormInput}
            sx={{ minWidth: '70%' }}
            required
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('FACTORYMANAGEMENT.PHONE')} />
        </Grid>
        <Grid container item xs={11}>
          <Field
            id="factoryPhone"
            name="factoryPhone"
            type="text"
            label=""
            component={YatungFormInput}
            sx={{ minWidth: '70%' }}
            required
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container item xs={3}>
            <YatungFormLabel label={i18T('FACTORYMANAGEMENT.SYMBOL')} />
          </Grid>
          <Grid container item xs={9}>
            <Field
              id="factoryCode"
              name="factoryCode"
              type="text"
              label=""
              component={YatungFormInput}
              sx={{ minWidth: '100%' }}
              required
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={6} sx={{ ml: '2%' }}>
          <Grid container item xs={2}>
            <YatungFormLabel label={i18T('FACTORYMANAGEMENT.NUMBER')} />
          </Grid>
          <Grid container item xs={10}>
            <Field
              id="branchCode"
              name="branchCode"
              type="text"
              label=""
              component={YatungFormInput}
              sx={{ minWidth: '69%' }}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
