import {
  ChildrenTypography,
  TableHeaderTitle,
  TestCardContainer,
  TestVerifyResultStyles,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { AllTest, ExperimentResultType } from '@Src/_basic/object/TestType';
import { FineSieve, ImportFineSieve, PutFineSieveRequest } from '@Src/_basic/object/test/sandRock/FineSieveType';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  wetWeight: basicNumberSchema,
  beforeRinseDryWeight: Yup.string()
    .min(1)
    .matches(/^-?(?!0+$)\d+\.?\d*$/)
    .required(),
  afterRinseDryWeight: basicNumberSchema,
  sieveWeight9500: basicNumberSchema,
  sieveWeight4750: basicNumberSchema,
  sieveWeight2360: basicNumberSchema,
  sieveWeight1180: basicNumberSchema,
  sieveWeight600: basicNumberSchema,
  sieveWeight300: basicNumberSchema,
  sieveWeight150: basicNumberSchema,
  sieveWeight75: basicNumberSchema,
  underPan: basicNumberSchema,
});
interface Props {
  testDetail: FineSieve;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (allTest: AllTest) => void;
  canEdit?: boolean;
}

function ExportFineSieveTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();
  // const calcTestForm = useMemo(() => new CalcFineSieve(testDetail), [testDetail]);
  const [legalStandard, setLegalStandard] = useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();
  const [edit, setEdit] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      wetWeight: testDetail?.wetWeight,
      beforeRinseDryWeight: testDetail?.beforeRinseDryWeight,
      afterRinseDryWeight: testDetail?.afterRinseDryWeight,
      sieveWeight9500: testDetail?.poreDiameter1,
      sieveWeight4750: testDetail?.poreDiameter2,
      sieveWeight2360: testDetail?.poreDiameter3,
      sieveWeight1180: testDetail?.poreDiameter4,
      sieveWeight600: testDetail?.poreDiameter5,
      sieveWeight300: testDetail?.poreDiameter6,
      sieveWeight150: testDetail?.poreDiameter7,
      sieveWeight75: testDetail?.poreDiameter8,
      underPan: testDetail?.underPan,
    }),
    [testDetail],
  );

  const getLegalStandardText = useCallback(
    (key: string) => {
      if (legalStandard?.get(key)?.minValue != null && legalStandard?.get(key)?.maxValue != null) {
        return `${legalStandard?.get(key)?.minValue} ~ ${legalStandard?.get(key)?.maxValue}`;
      } else {
        if (legalStandard?.get(key)?.minValue != null) {
          return `>=${legalStandard?.get(key)?.minValue}`;
        } else if (legalStandard?.get(key)?.maxValue != null) {
          return `<=${legalStandard?.get(key)?.maxValue}`;
        } else {
          return '';
        }
      }
    },
    [legalStandard],
  );

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const firstHeader = useMemo(
    () => ({
      title: i18T(`TEST.SIEVE_ANALYSIS.SIEVE_NUMBER`),
      values: [
        `${i18T(`TEST.SIEVE_ANALYSIS.ACCUMULATION_SIEVE_WEIGHT`)}(g)`,
        `${i18T(`TEST.SIEVE_ANALYSIS.ACCUMULATION_PERCENTAGE`)}(%)`,
        `${i18T(`TEST.SIEVE_ANALYSIS.PASS_SIEVE_PERCENTAGE`)}(%)`,
      ],
    }),
    [i18T],
  );

  const firstData = useMemo(
    () => [
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.9500um`),
        values: [
          formatNumber(testDetail?.poreDiameter1?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter1Percentage?.toFixed(4)),
          formatNumber(testDetail?.sievingPoreDiameter1Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.['9.5mm'] && testDetail?.verifyCustomResults?.['9.5mm'].passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.4750um`),
        values: [
          formatNumber(testDetail?.poreDiameter2?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter2Percentage?.toFixed(4)),
          formatNumber(testDetail?.sievingPoreDiameter2Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.['4.75mm'] && testDetail?.verifyCustomResults?.['4.75mm'].passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.2360um`),
        values: [
          formatNumber(testDetail?.poreDiameter3?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter3Percentage?.toFixed(4)),
          formatNumber(testDetail?.sievingPoreDiameter3Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.['2.36mm'] && testDetail?.verifyCustomResults?.['2.36mm'].passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.1180um`),
        values: [
          formatNumber(testDetail?.poreDiameter4?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter4Percentage?.toFixed(4)),
          formatNumber(testDetail?.sievingPoreDiameter4Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.['1.18mm'] && testDetail?.verifyCustomResults?.['1.18mm'].passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.600um`),
        values: [
          formatNumber(testDetail?.poreDiameter5?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter5Percentage?.toFixed(4)),
          formatNumber(testDetail?.sievingPoreDiameter5Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.['0.600mm'] && testDetail?.verifyCustomResults?.['0.600mm'].passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.300um`),
        values: [
          formatNumber(testDetail?.poreDiameter6?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter6Percentage?.toFixed(4)),
          formatNumber(testDetail?.sievingPoreDiameter6Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.['0.300mm'] && testDetail?.verifyCustomResults?.['0.300mm'].passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.150um`),
        values: [
          formatNumber(testDetail?.poreDiameter7?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter7Percentage?.toFixed(4)),
          formatNumber(testDetail?.sievingPoreDiameter7Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.['0.150mm'] && testDetail?.verifyCustomResults?.['0.150mm'].passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.75um`),
        values: [
          formatNumber(testDetail?.poreDiameter8?.toFixed(4)),
          formatNumber(testDetail?.poreDiameter8Percentage?.toFixed(4)),
          formatNumber(testDetail?.sievingPoreDiameter8Percentage?.toFixed(4)),
        ],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.['0.075mm'] && testDetail?.verifyCustomResults?.['0.075mm'].passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
      {
        title: i18T(`TEST.SIEVE_ANALYSIS.UNDER_PAN`),
        values: [
          formatNumber(testDetail?.underPan?.toFixed(4)),
          100 + formatNumber(testDetail?.sievingUnderPanPercentage?.toFixed(4)),
          formatNumber(testDetail?.sievingUnderPanPercentage?.toFixed(4)),
        ],
      },
    ],
    [i18T, testDetail],
  );

  const secondHeader = useMemo(
    () => ({
      title: i18T(`TEST.SIEVE_ANALYSIS.NAME`),
      values: [i18T(`TEST.SIEVE_ANALYSIS.VALUE`), i18T(`TEST.BASE.LEGAL_STANDARD`)],
    }),
    [i18T],
  );

  const secondData = useMemo(
    () => [
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.RATE_OF_WEIGHT_LOSS`)}(%)`,
        values: [`${testDetail?.lossPercentage?.toFixed(3)}`, getLegalStandardText('lossPercentage')],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.lossPercentage && testDetail?.verifyCustomResults?.lossPercentage.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.DIRT_CONTENT`)}(%)`,
        values: [formatNumber(testDetail?.dirtContentRatio?.toFixed(4)), getLegalStandardText('dirtContentRatio')],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.dirtContentRatio && testDetail?.verifyCustomResults?.dirtContentRatio.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.FM`)}`,
        values: [formatNumber(testDetail?.fm?.toFixed(4)), getLegalStandardText('fm')],
        valuesTextStyle:
          testDetail?.verifyPassedResults?.fm && testDetail?.verifyCustomResults?.fm.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
    ],
    [i18T, testDetail, getLegalStandardText],
  );

  const thirdHeader = useMemo(
    () => ({
      title: i18T(`TEST.SIEVE_ANALYSIS.NAME`),
      values: [i18T(`TEST.SIEVE_ANALYSIS.VALUE`)],
    }),
    [i18T],
  );

  const thirdData = useMemo(
    () => [
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.WET_WEIGHT`)}(g)`,
        values: [formatNumber(testDetail?.wetWeight?.toFixed(4))],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.BEFORE_RINSE_DRY_WEIGHT`)}(g)`,
        values: [formatNumber(testDetail?.beforeRinseDryWeight?.toFixed(4))],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.WATER_WEIGHT`)}(g)`,
        values: [formatNumber(testDetail?.waterWeight?.toFixed(4))],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.WATER_CONTENT`)}(%)`,
        values: [testDetail?.waterContent?.toFixed(2)],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.WATER_ABSORPTION`)}(%)`,
        values: [testDetail?.waterAbsorption ? testDetail?.waterAbsorption?.toFixed(2) : 2],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.SURFACE_WATER_CONTENT`)}(%)`,
        values: [testDetail?.surfaceWaterContent?.toFixed(2)],
      },
      {
        title: `${i18T(`TEST.SIEVE_ANALYSIS.AFTER_RINSE_DRY_WEIGHT`)}(g)`,
        values: [formatNumber(testDetail?.afterRinseDryWeight?.toFixed(4))],
      },
    ],
    [i18T, testDetail],
  );

  const fourthHeader = useMemo(
    () => ({
      title: i18T(`TEST.BASE.DETECTION`),
    }),
    [i18T],
  );

  const fourthData = useMemo(
    () => [
      isLegalPassed && isCustomPassed && !isCustomRejected
        ? {
          titles: verifyResultText,
          titlesStyle: [TestVerifyResultStyles.normal],
        }
        : {
          titles: verifyResultText,
          titlesStyle: [
            isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
            isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
          ],
        },
    ],
    [isLegalPassed, isCustomPassed, verifyResultText, isCustomRejected],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportFineSieve = {
        wetWeight: checkIsNaN(+values.wetWeight),
        beforeRinseDryWeight: checkIsNaN(+values.beforeRinseDryWeight),
        afterRinseDryWeight: checkIsNaN(+values.afterRinseDryWeight),
        poreDiameter1: checkIsNaN(+values.sieveWeight9500),
        poreDiameter2: checkIsNaN(+values.sieveWeight4750),
        poreDiameter3: checkIsNaN(+values.sieveWeight2360),
        poreDiameter4: checkIsNaN(+values.sieveWeight1180),
        poreDiameter5: checkIsNaN(+values.sieveWeight600),
        poreDiameter6: checkIsNaN(+values.sieveWeight300),
        poreDiameter7: checkIsNaN(+values.sieveWeight150),
        poreDiameter8: checkIsNaN(+values.sieveWeight75),
        underPan: checkIsNaN(+values.underPan),
      };

      TestApi.putTest<PutFineSieveRequest>(
        {
          exptItemCode: testDetail.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const firstTable = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack my={4} gap={2} ml={30}>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pb={2}>
              <TableHeaderTitle>{i18T(`TEST.SIEVE_ANALYSIS.SIEVE_NUMBER`)}</TableHeaderTitle>
              <TableHeaderTitle sx={{ pr: 18 }}>{`${i18T(
                `TEST.SIEVE_ANALYSIS.ACCUMULATION_SIEVE_WEIGHT`,
              )}(g)`}</TableHeaderTitle>
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.WET_WEIGHT`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.wetWeight}
                isError={!!(formik.touched.wetWeight && formik.errors.wetWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('wetWeight', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.BEFORE_RINSE_DRY_WEIGHT`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.beforeRinseDryWeight}
                isError={!!(formik.touched.beforeRinseDryWeight && formik.errors.beforeRinseDryWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('beforeRinseDryWeight', e.target.value)
                }
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.AFTER_RINSE_DRY_WEIGHT`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.afterRinseDryWeight}
                isError={!!(formik.touched.afterRinseDryWeight && formik.errors.afterRinseDryWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('afterRinseDryWeight', e.target.value)
                }
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.9500um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.sieveWeight9500}
                isError={!!(formik.touched.sieveWeight9500 && formik.errors.sieveWeight9500)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight9500', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.4750um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.sieveWeight4750}
                isError={!!(formik.touched.sieveWeight4750 && formik.errors.sieveWeight4750)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight4750', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.2360um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.sieveWeight2360}
                isError={!!(formik.touched.sieveWeight2360 && formik.errors.sieveWeight2360)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight2360', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.1180um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.sieveWeight1180}
                isError={!!(formik.touched.sieveWeight1180 && formik.errors.sieveWeight1180)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight1180', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.600um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.sieveWeight600}
                isError={!!(formik.touched.sieveWeight600 && formik.errors.sieveWeight600)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight600', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.300um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.sieveWeight300}
                isError={!!(formik.touched.sieveWeight300 && formik.errors.sieveWeight300)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight300', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.150um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.sieveWeight150}
                isError={!!(formik.touched.sieveWeight150 && formik.errors.sieveWeight150)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight150', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.75um`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.sieveWeight75}
                isError={!!(formik.touched.sieveWeight75 && formik.errors.sieveWeight75)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight75', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.UNDER_PAN`)}</ChildrenTypography>
              <LabelInput
                value={formik.values.underPan}
                isError={!!(formik.touched.underPan && formik.errors.underPan)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('underPan', e.target.value)}
              />
            </Stack>
          </Stack>
        );
      case false:
        return <TestTable headerData={firstHeader} rows={firstData} isShowCollapse={true} />;
      default:
        break;
    }
  }, [edit, firstHeader, firstData, formik, i18T]);

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.FINE_AGG_SIEVE_ANALYSIS,
        effectiveUnixTime: Date.now(),
        type: 1,
      },
      (_data) => {
        const legalStandardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          legalStandardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandard(legalStandardMap);
      },
    );
  }, []);

  return (
    <Stack gap={2} mt={2}>
      <TestCardContainer>
        <Stack flexDirection="row" justifyContent="flex-end" gap={5} position="absolute" right={0} top={30}>
          <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        </Stack>
        {firstTable}
      </TestCardContainer>

      {!edit && (
        <>
          <TestCardContainer>
            <TestTable headerData={secondHeader} rows={secondData} isShowCollapse={true} />
          </TestCardContainer>
          <TestCardContainer>
            <TestTable headerData={thirdHeader} rows={thirdData} isShowCollapse={true} />
          </TestCardContainer>
        </>
      )}

      <TestCardContainer>
        <TestTable headerData={fourthHeader} rows={fourthData} isShowCollapse={true} />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportFineSieveTable);
