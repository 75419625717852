import {
  ChildrenTypography,
  TestCardContainer,
  TestVerifyResultStyles,
  TitleTypography,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { CustomStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/CustomStandardType';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { AllTest, ExperimentResultType } from '@Src/_basic/object/TestType';
import {
  GroupDataByFlyAshActivityResponse,
  PutFlyAshActivityRequest,
} from '@Src/_basic/object/test/cementtitiousPowder/FlyAshActivityType';
import { CustomStandardV2Api } from '@Src/_basic/protocol/customStandardV2/CustomStandardV2Api';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testData: GroupDataByFlyAshActivityResponse | undefined;
  testDetail: AllTest;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

function FlyAshActivityCompleteTable({ testData, testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const [legalStandard, setLegalStandard] = useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();
  const [customStandard, setCustomStandard] = useState<Map<string, CustomStandardV2SpecificationDetailsResponse>>();

  const [edit, setEdit] = useState<boolean>(false);

  const formikBasicSetup = useMemo(() => {
    const transformedData: Record<string, number> = {};
    const validationSchemaObject: Record<string, typeof basicNumberSchema> = {};

    testData?.experimental.forEach((item) => {
      const age = item.age;

      transformedData[`${age}_controlSpecimen1CompStr`] = item.controlGroup?.specimen1CompStr as number;
      transformedData[`${age}_controlSpecimen2CompStr`] = item.controlGroup?.specimen2CompStr as number;
      transformedData[`${age}_specimen1CompStr`] = item?.specimen1CompStr as number;
      transformedData[`${age}_specimen2CompStr`] = item?.specimen2CompStr as number;
      validationSchemaObject[`${age}_controlSpecimen1CompStr`] = basicNumberSchema;
      validationSchemaObject[`${age}_controlSpecimen2CompStr`] = basicNumberSchema;
      validationSchemaObject[`${age}_specimen1CompStr`] = basicNumberSchema;
      validationSchemaObject[`${age}_specimen2CompStr`] = basicNumberSchema;

      if (item.controlGroup?.specimen3CompStr) {
        transformedData[`${age}_controlSpecimen3CompStr`] = item.controlGroup?.specimen3CompStr as number;
        transformedData[`${age}_specimen3CompStr`] = item?.specimen3CompStr as number;
        validationSchemaObject[`${age}_controlSpecimen3CompStr`] = basicNumberSchema;
        validationSchemaObject[`${age}_specimen3CompStr`] = basicNumberSchema;
      }
    });

    return { transformedData, validationSchemaObject };
  }, [testData]);

  const getVerifyResultText = useCallback(
    (isLegalPassed: boolean, isCustomPassed: boolean, isCustomRejected: boolean) => {
      const tempText: Array<string> = [];
      if (isLegalPassed && isCustomPassed && !isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.NORMAL`));
      } else {
        isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

        if (isCustomRejected) {
          tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
        } else {
          isCustomPassed
            ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
            : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
        }
      }
      return tempText;
    },
    [i18T],
  );

  const showTestDays = useCallback(
    (day: number) => {
      return (
        <Stack flexDirection="row" mt={2.5} mb={2} justifyContent="center">
          <Typography
            sx={{
              color: '#BB5E00',
              bgcolor: '#dcfbfb',
              p: 1,
              px: 2,
              borderRadius: '10%',
              fontWeight: 900,
              fontSize: 22,
            }}
          >
            {i18T(`TEST.SPECIMEN_STR.AGE_SPECIMEN_COMP_STR`, { day })}
          </Typography>
        </Stack>
      );
    },
    [i18T],
  );

  const formik = useFormik({
    initialValues: formikBasicSetup.transformedData,
    validationSchema: Yup.object().shape(formikBasicSetup.validationSchemaObject),
    onSubmit: (values) => {
      const _formData: { '7': Record<string, number>; '28': Record<string, number> } = {
        '7': {},
        '28': {},
      };

      Object.keys(values).forEach((item) => {
        const _name = item.split('_')[1];
        if (!item.includes('28')) _formData['7'][_name] = values[item];
        else _formData['28'][_name] = values[item];
      });

      Object.entries(_formData).forEach(([key, value], i) => {
        setTimeout(() => {
          const _id = testData?.experimental?.find((item) => item.age === Number(key))?.id;

          if (!testDetail?.specimen || !testDetail.controlGroupSpecimen || !_id) {
            return;
          }

          TestApi.putTest<PutFlyAshActivityRequest>(
            {
              exptItemCode: testDetail.exptItemCode,
              fields: {
                id: _id,
                operator: testDetail.operator,
                completed: true,
                reportComment: testDetail.reportComment,
                exptUnitId: testDetail.exptUnitId,
                specimenId: testDetail.specimen.id,
                controlSpecimenId: testDetail.controlGroupSpecimen.id,
                age: Number(key),
                controlAge: Number(key),
                ...value,
              },
            },
            (_data) => {
              afterUpdateTest(_data);
              setEdit(false);
            },
            (err) => console.log(err),
          );
        }, i * 1500);
      });
    },
  });

  const theForm = useMemo(() => {
    switch (edit) {
      case true:
        return testData?.experimental.map((value, index) => (
          <React.Fragment key={index}>
            <TestCardContainer key={index}>
              {value.age !== null && showTestDays(value.age)}
              <Stack flexDirection="column" alignItems="space-around" gap={2}>
                <Stack flexDirection="row" justifyContent="space-around">
                  <Stack>
                    <Stack alignItems="flex-start">
                      <TitleTypography>{i18T(`TEST.SPECIMEN_STR.CONTROL_GROUP`, { group: '它廠' })}</TitleTypography>
                    </Stack>
                    {value?.controlGroup?.specimen1CompStr && value?.specimen1CompStr && (
                      <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.SPECIMEN_1_STR`)}(kgf/cm²)`}>
                        <Stack sx={{ my: 1 }}>
                          <LabelInput
                            valueWidth="150px"
                            value={formik.values[`${value.age}_controlSpecimen1CompStr`]}
                            isError={
                              !!(
                                formik.touched[`${value.age}_controlSpecimen1CompStr`] &&
                                formik.errors[`${value.age}_controlSpecimen1CompStr`]
                              )
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              formik.setFieldValue(
                                `${value.age}_controlSpecimen1CompStr`,
                                checkIsNaN(Number(e.target.value)),
                              )
                            }
                          />
                        </Stack>
                        <LabelInput
                          valueWidth="150px"
                          value={formik.values[`${value.age}_specimen1CompStr`]}
                          isError={
                            !!(
                              formik.values[`${value.age}_specimen1CompStr`] &&
                              formik.errors[`${value.age}_specimen1CompStr`]
                            )
                          }
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            formik.setFieldValue(`${value.age}_specimen1CompStr`, checkIsNaN(Number(e.target.value)))
                          }
                        />
                      </ColFieldInfo>
                    )}
                    {value?.controlGroup?.specimen2CompStr && value?.specimen2CompStr && (
                      <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.SPECIMEN_2_STR`)}(kgf/cm²)`}>
                        <Stack sx={{ my: 1 }}>
                          <LabelInput
                            valueWidth="150px"
                            value={formik.values[`${value.age}_controlSpecimen2CompStr`]}
                            isError={
                              !!(
                                formik.touched[`${value.age}_controlSpecimen2CompStr`] &&
                                formik.errors[`${value.age}_controlSpecimen2CompStr`]
                              )
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              formik.setFieldValue(
                                `${value.age}_controlSpecimen2CompStr`,
                                checkIsNaN(Number(e.target.value)),
                              )
                            }
                          />
                        </Stack>
                        <LabelInput
                          valueWidth="150px"
                          value={formik.values[`${value.age}_specimen2CompStr`]}
                          isError={
                            !!(
                              formik.values[`${value.age}_specimen2CompStr`] &&
                              formik.errors[`${value.age}_specimen2CompStr`]
                            )
                          }
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            formik.setFieldValue(`${value.age}_specimen2CompStr`, checkIsNaN(Number(e.target.value)))
                          }
                        />
                      </ColFieldInfo>
                    )}
                    {value?.controlGroup?.specimen3CompStr && value?.specimen3CompStr && (
                      <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.SPECIMEN_3_STR`)}(kgf/cm²)`}>
                        <Stack sx={{ my: 1 }}>
                          <LabelInput
                            valueWidth="150px"
                            value={formik.values[`${value.age}_controlSpecimen3CompStr`]}
                            isError={
                              !!(
                                formik.touched[`${value.age}_controlSpecimen3CompStr`] &&
                                formik.errors[`${value.age}_controlSpecimen3CompStr`]
                              )
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              formik.setFieldValue(
                                `${value.age}_controlSpecimen3CompStr`,
                                checkIsNaN(Number(e.target.value)),
                              )
                            }
                          />
                        </Stack>
                        <LabelInput
                          valueWidth="150px"
                          value={formik.values[`${value.age}_specimen3CompStr`]}
                          isError={
                            !!(
                              formik.values[`${value.age}_specimen3CompStr`] &&
                              formik.errors[`${value.age}_specimen3CompStr`]
                            )
                          }
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            formik.setFieldValue(`${value.age}_specimen3CompStr`, checkIsNaN(Number(e.target.value)))
                          }
                        />
                      </ColFieldInfo>
                    )}
                  </Stack>

                  <Stack>
                    <Stack alignItems="flex-start">
                      <ChildrenTypography color={TestVerifyResultStyles.normal}>
                        {i18T(`TEST.SPECIMEN_STR.TEST_GROUP`, { group: value?.specimen?.supplierName ?? '' })}
                      </ChildrenTypography>
                    </Stack>
                    <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                      <ChildrenTypography color={TestVerifyResultStyles.textBlue}>{'---'}</ChildrenTypography>
                      {value.verifyPassedResults && value.verifyCustomResults && (
                        <ChildrenTypography
                          color={{
                            ...(value.verifyPassedResults[
                              value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                            ] &&
                              value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].passed &&
                              !value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].rejected
                              ? TestVerifyResultStyles.normal
                              : TestVerifyResultStyles.abnormal),
                          }}
                        >
                          {value.specimen1CompStrPct?.toFixed(1)}
                        </ChildrenTypography>
                      )}
                    </ColFieldInfo>
                    <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                      <ChildrenTypography color={TestVerifyResultStyles.textBlue}>{'---'}</ChildrenTypography>
                      {value.verifyPassedResults && value.verifyCustomResults && (
                        <ChildrenTypography
                          color={{
                            ...(value.verifyPassedResults[
                              value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                            ] &&
                              value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].passed &&
                              !value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].rejected
                              ? TestVerifyResultStyles.normal
                              : TestVerifyResultStyles.abnormal),
                          }}
                        >
                          {value.specimen2CompStrPct?.toFixed(1)}
                        </ChildrenTypography>
                      )}
                    </ColFieldInfo>
                    <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                      <ChildrenTypography color={TestVerifyResultStyles.textBlue}>{'---'}</ChildrenTypography>
                      {value.verifyPassedResults && value.verifyCustomResults && (
                        <ChildrenTypography
                          color={{
                            ...(value.verifyPassedResults[
                              value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                            ] &&
                              value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].passed &&
                              !value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].rejected
                              ? TestVerifyResultStyles.normal
                              : TestVerifyResultStyles.abnormal),
                          }}
                        >
                          {value.specimen3CompStrPct?.toFixed(1)}
                        </ChildrenTypography>
                      )}
                    </ColFieldInfo>
                    <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                      <ChildrenTypography color={TestVerifyResultStyles.textBlue}>{'---'}</ChildrenTypography>
                      {value.verifyPassedResults && value.verifyCustomResults && (
                        <ChildrenTypography
                          color={{
                            ...(value.verifyPassedResults[
                              value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                            ] &&
                              value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].passed &&
                              !value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].rejected
                              ? TestVerifyResultStyles.normal
                              : TestVerifyResultStyles.abnormal),
                          }}
                        >
                          {value.avgCompStrPct?.toFixed(1)}
                        </ChildrenTypography>
                      )}
                    </ColFieldInfo>
                  </Stack>
                </Stack>
              </Stack>
            </TestCardContainer>
          </React.Fragment>
        ));
      case false:
        return testData?.experimental.map((value, index) => (
          <React.Fragment key={index}>
            <TestCardContainer key={index}>
              {value.age !== null && showTestDays(value.age)}
              <Stack flexDirection="column" alignItems="space-around" gap={2}>
                <Stack flexDirection="row" justifyContent="space-around">
                  <Stack>
                    <Stack alignItems="flex-start">
                      <TitleTypography>{i18T(`TEST.SPECIMEN_STR.CONTROL_GROUP`, { group: '它廠' })}</TitleTypography>
                    </Stack>
                    {value?.controlGroup?.specimen1CompStr && value?.specimen1CompStr && (
                      <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.SPECIMEN_1_STR`)}(kgf/cm²)`}>
                        <ChildrenTypography color={TestVerifyResultStyles.textBlue}>
                          {value?.controlGroup?.specimen1CompStr}
                        </ChildrenTypography>
                        <ChildrenTypography color={TestVerifyResultStyles.normal}>
                          {value?.specimen1CompStr}
                        </ChildrenTypography>
                      </ColFieldInfo>
                    )}
                    {value?.controlGroup?.specimen2CompStr && value?.specimen2CompStr && (
                      <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.SPECIMEN_2_STR`)}(kgf/cm²)`}>
                        <ChildrenTypography color={TestVerifyResultStyles.textBlue}>
                          {value?.controlGroup?.specimen2CompStr}
                        </ChildrenTypography>
                        <ChildrenTypography color={TestVerifyResultStyles.normal}>
                          {value?.specimen2CompStr}
                        </ChildrenTypography>
                      </ColFieldInfo>
                    )}
                    {value?.controlGroup?.specimen3CompStr && value?.specimen3CompStr && (
                      <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.SPECIMEN_3_STR`)}(kgf/cm²)`}>
                        <ChildrenTypography color={TestVerifyResultStyles.textBlue}>
                          {value?.controlGroup?.specimen3CompStr}
                        </ChildrenTypography>
                        <ChildrenTypography color={TestVerifyResultStyles.normal}>
                          {value?.specimen3CompStr}
                        </ChildrenTypography>
                      </ColFieldInfo>
                    )}
                    <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.AVERAGE_STR`)}(kgf/cm²)`}>
                      <ChildrenTypography color={TestVerifyResultStyles.textBlue}>
                        {value?.controlGroup?.avgCompStr?.toFixed(1) ?? '0.0'}
                      </ChildrenTypography>
                      <ChildrenTypography color={TestVerifyResultStyles.normal}>
                        {value?.avgCompStr?.toFixed(1)}
                      </ChildrenTypography>
                    </ColFieldInfo>
                  </Stack>

                  <Stack>
                    <Stack alignItems="flex-start">
                      <ChildrenTypography color={TestVerifyResultStyles.normal}>
                        {i18T(`TEST.SPECIMEN_STR.TEST_GROUP`, { group: value?.specimen?.supplierName ?? '' })}
                      </ChildrenTypography>
                    </Stack>
                    <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                      <ChildrenTypography color={TestVerifyResultStyles.textBlue}>{'---'}</ChildrenTypography>
                      {value.verifyPassedResults && value.verifyCustomResults && (
                        <ChildrenTypography
                          color={{
                            ...(value.verifyPassedResults[
                              value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                            ] &&
                              value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].passed &&
                              !value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].rejected
                              ? TestVerifyResultStyles.normal
                              : TestVerifyResultStyles.abnormal),
                          }}
                        >
                          {value.specimen1CompStrPct?.toFixed(1)}
                        </ChildrenTypography>
                      )}
                    </ColFieldInfo>
                    <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                      <ChildrenTypography color={TestVerifyResultStyles.textBlue}>{'---'}</ChildrenTypography>
                      {value.verifyPassedResults && value.verifyCustomResults && (
                        <ChildrenTypography
                          color={{
                            ...(value.verifyPassedResults[
                              value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                            ] &&
                              value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].passed &&
                              !value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].rejected
                              ? TestVerifyResultStyles.normal
                              : TestVerifyResultStyles.abnormal),
                          }}
                        >
                          {value.specimen2CompStrPct?.toFixed(1)}
                        </ChildrenTypography>
                      )}
                    </ColFieldInfo>
                    <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                      <ChildrenTypography color={TestVerifyResultStyles.textBlue}>{'---'}</ChildrenTypography>
                      {value.verifyPassedResults && value.verifyCustomResults && (
                        <ChildrenTypography
                          color={{
                            ...(value.verifyPassedResults[
                              value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                            ] &&
                              value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].passed &&
                              !value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].rejected
                              ? TestVerifyResultStyles.normal
                              : TestVerifyResultStyles.abnormal),
                          }}
                        >
                          {value.specimen3CompStrPct?.toFixed(1)}
                        </ChildrenTypography>
                      )}
                    </ColFieldInfo>
                    <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.ACHIEVE_DESIGN_STR`)}(%)`}>
                      <ChildrenTypography color={TestVerifyResultStyles.textBlue}>{'---'}</ChildrenTypography>
                      {value.verifyPassedResults && value.verifyCustomResults && (
                        <ChildrenTypography
                          color={{
                            ...(value.verifyPassedResults[
                              value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                            ] &&
                              value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].passed &&
                              !value.verifyCustomResults[
                                value.age === 7 ? '7_specimen1CompStrPct' : '28_specimen1CompStrPct'
                              ].rejected
                              ? TestVerifyResultStyles.normal
                              : TestVerifyResultStyles.abnormal),
                          }}
                        >
                          {value.avgCompStrPct?.toFixed(1)}
                        </ChildrenTypography>
                      )}
                    </ColFieldInfo>
                  </Stack>
                </Stack>
                {legalStandard?.get(value.age === 7 ? '7DAY_CONTROL_COMP_STR' : '28DAY_CONTROL_COMP_STR')?.minValue && (
                  <ChildrenTypography style={{ alignSelf: 'center' }}>
                    {'CNS規範：100級須達基準組強度' +
                      legalStandard.get(value.age === 7 ? '7DAY_CONTROL_COMP_STR' : '28DAY_CONTROL_COMP_STR')
                        ?.minValue +
                      '%以上 '}
                  </ChildrenTypography>
                )}

                {customStandard?.get(value.age === 7 ? '7DAY_CONTROL_COMP_STR' : '28DAY_CONTROL_COMP_STR')
                  ?.minValue && (
                    <ChildrenTypography style={{ alignSelf: 'center' }}>
                      {'採購合約：100級須達基準組強度' +
                        customStandard.get(value.age === 7 ? '7DAY_CONTROL_COMP_STR' : '28DAY_CONTROL_COMP_STR')
                          ?.minValue +
                        '%以上 '}
                    </ChildrenTypography>
                  )}
              </Stack>
            </TestCardContainer>

            <TestCardContainer>
              <TestTable
                headerData={{
                  values: [i18T(`TEST.BASE.DETECTION`)],
                }}
                rows={[
                  isLegalStandardPassed(value.verifyPassedResults) && isCustomStandardPassed(value.verifyCustomResults)
                    ? {
                      titles: getVerifyResultText(
                        isLegalStandardPassed(value.verifyPassedResults),
                        isCustomStandardPassed(value.verifyCustomResults),
                        isRejected(value.verifyCustomResults),
                      ),
                      titlesStyle: [TestVerifyResultStyles.normal],
                    }
                    : {
                      titles: getVerifyResultText(
                        isLegalStandardPassed(value.verifyPassedResults),
                        isCustomStandardPassed(value.verifyCustomResults),
                        isRejected(value.verifyCustomResults),
                      ),
                      titlesStyle: [
                        isLegalStandardPassed(value.verifyPassedResults)
                          ? TestVerifyResultStyles.normal
                          : TestVerifyResultStyles.abnormal,
                        isCustomStandardPassed(value.verifyCustomResults)
                          ? TestVerifyResultStyles.normal
                          : TestVerifyResultStyles.abnormal,
                      ],
                    },
                ]}
                isShowCollapse={true}
              />
            </TestCardContainer>
          </React.Fragment>
        ));
      default:
        break;
    }
  }, [edit, formik, i18T, testData, legalStandard, customStandard, getVerifyResultText, showTestDays]);

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.FLY_ASH_ACTIVITY_TEST,
        effectiveUnixTime: Date.now(),
        type: 1,
      },
      (_data) => {
        const standardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          standardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandard(standardMap);
      },
    );
    CustomStandardV2Api.getCustomStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.FLY_ASH_ACTIVITY_TEST,
        effectiveUnixTime: Date.now(),
        type: 1,
      },
      (_data) => {
        const standardMap = new Map();
        _data.forEach((item: CustomStandardV2SpecificationDetailsResponse) => {
          standardMap.set(item.experimentDetectionItem.code, item);
        });
        setCustomStandard(standardMap);
      },
    );
  }, []);

  return (
    <Stack gap={2}>
      <Stack
        flexDirection="row"
        justifyContent="flex-end"
        gap={3}
        position="absolute"
        right={200}
        sx={{ zIndex: 5, marginTop: 5 }}
      >
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
      </Stack>
      {theForm}
    </Stack>
  );
}

export default memo(FlyAshActivityCompleteTable);
