import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import UpdateNameField from './components/UpdateNameField';
import { LanguageApi } from '@Src/_basic/protocol/languageManagement/LanguageApi';
import { UpdqateLangName } from '@Src/_basic/object/MultilingualManagementType';

interface Props {
  initialValues: UpdqateLangName;
  onUpdateGoBackButtonClick: () => void;
  langByIdData:any
}
const vahicleSchema = Yup.object().shape({});
export default function WebUpdateForm(props: Props) {
  const {
    initialValues,
    onUpdateGoBackButtonClick,
    langByIdData,

  } = props;

  const handleUpdateLangName = (request:UpdqateLangName) => {
    LanguageApi.updateLangName(
      request,
      () => {
        onUpdateGoBackButtonClick();
      },
      undefined,
      () => {},
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={vahicleSchema}
      onSubmit={(request: UpdqateLangName) => handleUpdateLangName(request)}
      enableReinitialize
    >
      {({ setFieldValue, values }) => {
        return (
          <Form>
            <UpdateNameField
              values={values}
              setValues={setFieldValue}
              langByIdData={langByIdData}
              onUpdateGoBackButtonClick={onUpdateGoBackButtonClick}

            />
          </Form>
        );
      }}
    </Formik>
  );
}
