import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import YatungTable from '@Src/_basic/components/YatungTable';
import { useTranslation } from 'react-i18next';
import { TypeApi } from '@Src/_basic/protocol/type/TypeApi';
import { TypeData } from '@Src/_basic/object/TypeType';

export default function MaterialTypesPanel() {
  const { t: i18T } = useTranslation();
  const [types, setTypes] = useState<Array<TypeData>>([]);
  const fetchAllTypes = async () => {
    TypeApi.getAllTypes(setTypes);
  };

  useEffect(() => {
    fetchAllTypes();
  }, []);

  return (
    <Box sx={{ border: '1px solid #6C708C', p: 1, pt: 2 }}>
      <YatungTable
        data={types}
        columns={[
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.CODE'),
            dataField: 'code',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.NAME'),
            dataField: 'name',
            width: '480px',
          },
        ]}
      />
    </Box>
  );
}
