import YatungDivider from '@Src/_basic/components/YatungDivider';
import { Options } from '@Src/_basic/components/YatungSelect';
import YatungWindow from '@Src/_basic/components/YatungWindow';
import { ProportionData, ProportionOption } from '@Src/_basic/object/MixerAndProportionInformationBindingType';
import { proportionMaintenanceMixerApi } from '@Src/_basic/protocol/proportionMaintenanceMixer/proportionMaintenanceMixerApi';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MixerAndProportionInformationBindSelection from './components/MixerAndProportionInformationBindSelection';

interface Props {
  proportionData: ProportionData[];
  setProportionData: (e: ProportionData[]) => void;
}
export default function MixerAndProportionInformationBindForm(props: Props) {
  const { t: i18T } = useTranslation();
  const { proportionData, setProportionData } = props;

  const [proportionOptions, setProportionOptions] = useState<Options[]>([]);

  const titleStyle = {
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#7C878E',
    my: 1,
    ml: 1,
  };
  const bodyStyle = {
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: 900,
    color: '#7C878E',
    my: 1,
    ml: 1,
  };
  const [dymanicOptions, setDymanicOptions] = useState<Array<Options>>([]);

  const handleSelectChange = (key: number, _value: number) => {
    const updatedArray = proportionData?.map((_data: ProportionData) => {
      if (_data.id === key) {
        return { ..._data, propOptId: _value };
      }
      return _data;
    });
    setProportionData(updatedArray);
  };
  const getDynamicOptions = async () => {
    const propOptValue = proportionData?.map((value: ProportionData) => {
      return value.propOptId;
    });

    const mixerBindValueFilter = proportionOptions.filter((value: Options) => {
      return !propOptValue.includes(value.value);
    });

    setDymanicOptions(mixerBindValueFilter);
  };

  useEffect(() => {
    proportionMaintenanceMixerApi.getProportionOptions((allProportions: ProportionOption[]) => {
      const proportionOption = allProportions.map((proportionOption: ProportionOption) => ({
        value: proportionOption.id,
        text: proportionOption.name,
      }));
      setProportionOptions(proportionOption);
    });
  }, []);

  useEffect(() => {
    getDynamicOptions();
  }, [proportionData]);

  return (
    <Card
      sx={{
        width: '1550px',
        height: '648px',
        mt: 1,
        boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
        background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
      }}
    >
      <CardHeader
        sx={{ color: '#ffffff' }}
        title={
          <>
            <Grid container item xs={12} justifyContent={'center'} sx={{ mt: 1 }}>
              <Grid container item xs={3} alignItems={'center'}>
                <Typography sx={titleStyle}>
                  {i18T('USERSMANAGEMENU.MIXERANDPROPROTION_INFORMATION_BIND.MIXER_INFORMATION')}
                </Typography>
              </Grid>
              <Grid container item xs={3} alignItems={'center'}>
                <Typography sx={titleStyle}>
                  {i18T('USERSMANAGEMENU.MIXERANDPROPROTION_INFORMATION_BIND.MACHING_INFORMATION')}
                </Typography>
              </Grid>
              <Grid container item xs={3} alignItems={'center'} />
              <Grid container item xs={3} alignItems={'center'} />
            </Grid>
            <YatungDivider borderStyle={true} />
          </>
        }
      />
      <CardContent sx={{ height: '100%' }}>
        <Box sx={{ width: '100%', height: `calc(100% - 32px - 72px)` }}>
          <YatungWindow
            className="scroll-bar"
            data={proportionData}
            renderItem={(data: ProportionData) => {
              return (
                <Grid container>
                  <Grid container item xs={12} justifyContent={'center'} sx={{ my: 1 }}>
                    <Grid container item xs={3}>
                      <Typography sx={bodyStyle}>{data.mixerPropSettingCode}</Typography>
                    </Grid>
                    <Grid container item xs={3}>
                      <MixerAndProportionInformationBindSelection
                        proportionOptions={proportionOptions}
                        dymanicOptions={dymanicOptions}
                        selectedValue={data.propOptId ? data.propOptId : 0}
                        onSelected={(_value: number) => {
                          handleSelectChange(data.id, _value);
                        }}
                      />
                    </Grid>
                    <Grid container item xs={3} />
                    <Grid container item xs={3} />
                  </Grid>
                  <YatungDivider />
                </Grid>
              );
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
