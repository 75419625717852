import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDatePicker from '@Src/_basic/components/YatungDatePicker';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { AreaItem } from '@Src/_basic/object/AuthType';
import {
  AnalysisList,
  MonthlyReportExptItem,
  MonthlyReportNameEnum,
  ReportViewResponse,
  SourceList,
  WithoutAnySelectList,
} from '@Src/_basic/object/MonthlyReportType';
import { SourceData } from '@Src/_basic/object/SourceType';
import { SpecificationData } from '@Src/_basic/object/SpecificationType';
import { SupplierData } from '@Src/_basic/object/SupplierType';
import { MonthlyReportApi } from '@Src/_basic/protocol/monthlyReport/MonthlyReportApi';
import { SourceApi } from '@Src/_basic/protocol/source/SourceApi';
import { SpecificationApi } from '@Src/_basic/protocol/specification/SpecificationApi';
import { SupplierApi } from '@Src/_basic/protocol/supplier/SupplierApi';
import { UnitApi } from '@Src/_basic/protocol/unit/UnitApi';
import MonthlyReportDialog from '@Src/app/module/view/page/MonthlyReport/screens/components/MonthlyReportDialog';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { Checkbox, SelectChangeEvent, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MonthlyReportTable from './MonthlyReportTable';

const optionForAll = { text: '全部', value: 0 };

interface Props {
  title: string;
  exptType: MonthlyReportNameEnum;
  selectHandler: (
    factory: number,
    testUnit: number,
    startTime: number,
    endTime: number,
    specificationId?: number,
    sourceId?: number,
    supplierId?: number,
    selectedFormId?: number,
  ) => void;
  tableData?: any;
}

const MonthlyReportSelect = ({ title, exptType, selectHandler, tableData }: Props) => {
  const { t: i18T } = useTranslation();

  const { actionLoading } = useApi();
  const { userGroupsData } = useAuth();

  const [checkThirtyDays, setCheckThirtyDays] = useState<boolean>(false); // 自動回推30天

  const [selectedFactory, setSelectedFactory] = useState<number>(); // selected廠Id
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date()); // selected月份
  const [selectedTestUnit, setSelectedTestUnit] = useState<number>(); // selected試驗場地
  const [selectedFormId, setSelectedFormId] = useState<number>(1); // selected表單
  const [selectedSpecification, setSelectedSpecification] = useState<SpecificationData>(); // selected規格
  const [selectedSource, setSelectedSource] = useState<SourceData>(); // selected來源
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierData>(); // selected供應商

  const [testUnitOptions, setTestUnitOptions] = useState<Array<Options>>([]); // 試驗場地選項
  const [factoryOptions, setFactoryOptions] = useState<Array<Options>>([]); // 工廠選項
  const [formOptions, setFormOptions] = useState<Array<Options>>([
    { text: '紀錄表', value: 1 },
    { text: '篩分析報告', value: 2 },
  ]); // 表單選項
  const [specificationOptions, setSpecificationOptions] = useState<Array<Options>>([]); // 來源選項
  const [sourceOptions, setSourceOptions] = useState<Array<Options>>([]); // 來源選項
  const [supplierOptions, setSupplierOptions] = useState<Array<Options>>([]); // 供應商選項

  const [startTime, setStartTime] = useState<number>(new Date().getTime()); // 開始時間
  const [endTime, setEndTime] = useState<number>(new Date().getTime()); // 開始時間
  const [monthlyReportName, setMonthlyReportName] = useState<string>(''); // 月報名稱

  const [headerData, setHeaderData] = useState<ReportViewResponse>();
  const [open, setOpen] = useState(false);
  const [detailData, setDetailData] = useState<any>();

  // 區選項
  const areaOptions = useMemo(() => {
    if (!userGroupsData || !userGroupsData.areas) return [];

    return userGroupsData.areas.reduce(
      (prev, curr) => [...prev, { text: curr.name, value: curr }],
      [] as Array<Options>,
    );
  }, [userGroupsData?.areas]);

  // 選擇區
  const selectAreaHandler = useCallback((e: SelectChangeEvent<unknown>) => {
    const area = e.target.value as AreaItem;
    if (!area?.factoryList) setFactoryOptions([]);
    else {
      const afterFactoryOption = area?.factoryList?.reduce(
        (prev, curr) => [...prev, { text: curr.factoryName, value: curr.factoryId }],
        [] as Array<Options>,
      );
      setFactoryOptions(afterFactoryOption);
    }
  }, []);

  // 選擇廠
  const selectFactoryHandler = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const factoryId = e.target.value as number;
      setSelectedFactory(factoryId);
      let typeId: number;
      switch (exptType) {
        case MonthlyReportNameEnum.FLY_ASH_LOSS_OF_IGNITION:
        case MonthlyReportNameEnum.FLY_ASH_L_VALUE:
          typeId = 5;
          break;
        case MonthlyReportNameEnum.COARSE_AGGREGATE_ABSORPTION:
        case MonthlyReportNameEnum.COARSE_AGGREGATE_ANALYSIS:
          typeId = 2;
          break;
        default:
          typeId = 1;
          break;
      }

      // 取得規格
      SpecificationApi.getSpecificationsBySearch({ factoryId, typeId }, (_data) => {
        typeId === 2
          ? setSpecificationOptions(
              _data
                .filter((item) => item.id === 1 || item.id === 2)
                .reduce((prev, curr) => [...prev, { text: curr.name, value: curr }], [] as Array<Options>),
            )
          : setSpecificationOptions(
              _data.reduce((prev, curr) => [...prev, { text: curr.name, value: curr }], [] as Array<Options>),
            );
      });
    },
    [exptType],
  );

  // 選擇規格
  const selectSpecificationHandler = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const specification = e.target.value as SpecificationData;
      setSelectedSpecification(specification);

      // 取得來源
      SourceApi.getSourcesBySearch({ factoryId: selectedFactory, typeId: specification.typeId }, (_data) => {
        setSourceOptions(
          _data.reduce((prev, curr) => [...prev, { text: curr.sourceName, value: curr }], [] as Array<Options>),
        );
      });
      // 取得供應商
      SupplierApi.getSuppliersBySearch({ factoryId: selectedFactory, typeId: specification.typeId }, (_data) => {
        setSupplierOptions(
          _data.reduce((prev, curr) => [...prev, { text: curr.name, value: curr }], [] as Array<Options>),
        );
      });
    },
    [selectedFactory],
  );

  // 選擇表單
  const selectFormHandler = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const formId = e.target.value as number;
      setSelectedFormId(formId);

      if (exptType === MonthlyReportNameEnum.COARSE_AGGREGATE_ANALYSIS && formId === 1) {
        selectSpecificationHandler({ target: { value: { typeId: 2 } } } as any);
      }

      setSelectedSource(undefined);
      setSelectedSupplier(undefined);
      setSelectedSpecification(undefined);
    },
    [selectedFactory, exptType],
  );

  // 依試驗type Render不同select
  const selectGroup = useMemo(() => {
    if (WithoutAnySelectList.includes(exptType)) return <></>;
    else if (SourceList.includes(exptType)) {
      return (
        <>
          <YatungSelect
            disabled={actionLoading || !selectedFactory}
            options={specificationOptions}
            onChange={selectSpecificationHandler}
          />
          <YatungSelect
            disabled={actionLoading || !selectedFactory || !selectedSpecification?.id}
            options={[optionForAll, ...sourceOptions]}
            onChange={(e) => setSelectedSource(e.target.value as SourceData)}
          />
        </>
      );
    } else {
      return (
        <>
          <YatungSelect
            disabled={actionLoading || !selectedFactory}
            options={formOptions}
            onChange={selectFormHandler}
            value={selectedFormId}
          />
          {exptType === MonthlyReportNameEnum.COARSE_AGGREGATE_ANALYSIS ? (
            selectedFormId === 1 ? (
              <>
                <YatungSelect
                  disabled={actionLoading || !selectedFactory}
                  options={[optionForAll, ...sourceOptions]}
                  onChange={(e) => setSelectedSource(e.target.value as SourceData)}
                />
                <YatungSelect
                  disabled={actionLoading || !selectedFactory}
                  options={[optionForAll, ...supplierOptions]}
                  onChange={(e) => setSelectedSupplier(e.target.value as SupplierData)}
                />
              </>
            ) : (
              <></>
            )
          ) : selectedFormId === 1 ? (
            <>
              <YatungSelect
                disabled={actionLoading || !selectedFactory}
                options={specificationOptions}
                onChange={selectSpecificationHandler}
              />
              <YatungSelect
                disabled={actionLoading || !selectedFactory || !selectedSpecification?.id}
                options={[optionForAll, ...sourceOptions]}
                onChange={(e) => setSelectedSource(e.target.value as SourceData)}
              />
              <YatungSelect
                disabled={actionLoading || !selectedFactory || !selectedSpecification?.id}
                options={[optionForAll, ...supplierOptions]}
                onChange={(e) => setSelectedSupplier(e.target.value as SupplierData)}
              />
            </>
          ) : (
            <></>
          )}
        </>
      );
    }
  }, [
    exptType,
    sourceOptions,
    selectedFactory,
    supplierOptions,
    actionLoading,
    selectedSpecification?.id,
    specificationOptions,
    selectedFormId,
    formOptions,
    selectFormHandler,
    selectSpecificationHandler,
  ]);

  // 帶入選項名稱
  const afterTableData = useMemo(() => {
    if (tableData?.length === 0) return [];
    else
      return tableData?.map((item: any) => ({
        ...item,
        specification: selectedSpecification?.name ?? '全部',
        source: selectedSource?.sourceName ?? '全部',
        supplier: selectedSupplier?.name ?? '全部',
      }));
  }, [tableData, selectedSource, selectedSpecification, selectedSupplier]);

  // 生成月報
  const postSaveViewList = useCallback(() => {
    if (!monthlyReportName || !selectedTestUnit || !selectedFactory || !monthlyReportName) return;

    let monthlyReportExperimentId: MonthlyReportExptItem | undefined;
    MonthlyReportApi.getAllMonthlyExptItem((_data) => {
      monthlyReportExperimentId = _data.find((item) => item.itemCode === exptType);

      if (!monthlyReportExperimentId) return;

      MonthlyReportApi.createMonthlyReportView(
        {
          name: monthlyReportName,
          experimentUnitId: selectedTestUnit.toString(),
          factoryId: selectedFactory,
          monthlyReportExperimentId: monthlyReportExperimentId.id,
          startTime: startTime,
          endTime: endTime,
          ...(selectedSpecification?.id && { specificationId: selectedSpecification?.id }),
          ...(selectedSource?.id && { sourceId: selectedSource?.id }),
          ...(selectedSupplier?.id && { supplierId: selectedSupplier?.id }),
          ...(selectedFormId && { formId: selectedFormId }),
        },
        (_data) => {
          const parmas = {
            factoryId: selectedFactory,
            exptUnitId: selectedTestUnit,
            startTime: startTime,
            endTime: endTime,
            ...(selectedSpecification?.id && { specificationId: selectedSpecification?.id }),
            ...(selectedSource?.id && { sourceId: selectedSource?.id }),
            ...(selectedSupplier?.id && { supplierId: selectedSupplier?.id }),
            ...(selectedFormId && { formId: selectedFormId }),
          };

          MonthlyReportApi.getAllMonthlyReportView(
            {
              time: startTime,
              factoryId: selectedFactory,
              experimentUnitId: selectedTestUnit,
              monthlyReportExperimentId: (monthlyReportExperimentId as MonthlyReportExptItem).id,
              ...(selectedSpecification?.id && { specificationId: selectedSpecification?.id }),
              ...(selectedSource?.id && { sourceId: selectedSource?.id }),
              ...(selectedSupplier?.id && { supplierId: selectedSupplier?.id }),
              ...(selectedFormId && { formId: selectedFormId }),
              timeZoneStr: 'Asia/Taipei',
              removed: false,
            },
            (_data) => {
              const _id = Math.max(..._data.map((item) => item.id));
              setHeaderData(_data.find((item) => item.id === _id));
              switch ((monthlyReportExperimentId as MonthlyReportExptItem).id) {
                case 1:
                  MonthlyReportApi.getFineAggAnalysis(
                    parmas,
                    (_data) => setDetailData(_data),
                    undefined,
                    () => setOpen(true),
                  );
                  break;
                case 2:
                  MonthlyReportApi.getFineAggAMS(
                    parmas,
                    (_data) => setDetailData(_data),
                    undefined,
                    () => setOpen(true),
                  );
                  break;
                case 3:
                  MonthlyReportApi.getFineAggAbsorption(
                    parmas,
                    (_data) => setDetailData(_data),
                    undefined,
                    () => setOpen(true),
                  );
                  break;
                case 4:
                  MonthlyReportApi.getFineAggChloride(
                    parmas,
                    (_data) => setDetailData(_data),
                    undefined,
                    () => setOpen(true),
                  );
                  break;
                case 5:
                  MonthlyReportApi.getCoarseAggAnalysis(
                    parmas,
                    (_data) => setDetailData(_data),
                    undefined,
                    () => setOpen(true),
                  );
                  break;
                case 6:
                  MonthlyReportApi.getCoarseAggAbsorption(
                    parmas,
                    (_data) => setDetailData(_data),
                    undefined,
                    () => setOpen(true),
                  );
                  break;
                case 7:
                  MonthlyReportApi.getFlyAshLValue(
                    parmas,
                    (_data) => setDetailData(_data),
                    undefined,
                    () => setOpen(true),
                  );
                  break;
                case 8:
                  MonthlyReportApi.getFlyAshLossOfIgnition(
                    parmas,
                    (_data) => setDetailData(_data),
                    undefined,
                    () => setOpen(true),
                  );
                  break;
                case 9:
                  MonthlyReportApi.getConcreteAntiStress(
                    parmas,
                    (_data) => setDetailData(_data),
                    undefined,
                    () => setOpen(true),
                  );
                  break;
                case 10:
                  MonthlyReportApi.getFineAggRValue(
                    parmas,
                    (_data) => setDetailData(_data),
                    undefined,
                    () => setOpen(true),
                  );
                  break;
                default:
                  return;
              }
            },
          );
        },
      );
    });
  }, [
    exptType,
    selectedFactory,
    selectedSource?.id,
    selectedSupplier?.id,
    selectedTestUnit,
    startTime,
    endTime,
    selectedSpecification?.id,
    selectedFormId,
    monthlyReportName,
  ]);

  // 取得所有試驗場地
  useEffect(() => {
    UnitApi.getTestUnit((_data) =>
      setTestUnitOptions(
        _data.reduce((prev, curr) => [...prev, { text: curr.unitName, value: curr.id }], [] as Array<Options>),
      ),
    );
    setMonthlyReportName(`${title}_`);
  }, []);

  useEffect(() => {
    if (!selectedFactory || !selectedTestUnit) return;

    let startTime: number;
    let endTime: number;
    if (checkThirtyDays) {
      startTime = moment(new Date()).subtract(1, 'month').toDate().getTime();
      endTime = new Date().getTime();
    } else {
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      startTime = startTime = moment(selectedMonth).startOf('month').toDate().getTime();

      if (selectedMonth.getMonth() === currentMonth && selectedMonth.getFullYear() === currentYear) {
        endTime = new Date().getTime();
      } else {
        endTime = moment(selectedMonth).endOf('month').toDate().getTime();
      }
    }

    setStartTime(startTime);
    setEndTime(endTime);

    if (selectHandler) {
      selectHandler(
        selectedFactory,
        selectedTestUnit,
        startTime,
        endTime,
        selectedSpecification?.id,
        selectedSource?.id,
        selectedSupplier?.id,
        selectedFormId,
      );
    }
  }, [
    selectedFactory,
    selectedSource?.id,
    selectedSupplier?.id,
    selectedTestUnit,
    selectedMonth,
    checkThirtyDays,
    selectedSpecification?.id,
    selectedFormId,
  ]);

  useEffect(() => {
    if (AnalysisList.includes(exptType)) setSelectedFormId(2);
  }, []);

  return (
    <YatungPage
      title={title}
      actions={
        <Stack direction="row" spacing={3} alignItems="center">
          <Stack direction="row" alignItems="center" marginRight={1}>
            <Checkbox
              checked={checkThirtyDays}
              onChange={() => setCheckThirtyDays(!checkThirtyDays)}
              disabled={actionLoading}
            ></Checkbox>
            <Typography sx={{ fontWeight: 900, fontSize: 20 }}>
              {i18T('REPORT.MONTHLY_REPORT.BASIC.AUTO_THIRTY_DAY')}
            </Typography>
          </Stack>
          <YatungSelect disabled={actionLoading} options={areaOptions} onChange={selectAreaHandler} />
          <YatungSelect
            disabled={actionLoading || factoryOptions.length <= 0}
            options={factoryOptions}
            onChange={selectFactoryHandler}
          />
        </Stack>
      }
      body={
        <Stack marginTop={2}>
          <Stack direction="row" spacing={3} justifyContent="flex-end" alignItems="center">
            <Stack direction="row" spacing={3} flexWrap="wrap" sx={{ py: 1 }}>
              <YatungDatePicker
                disabled={checkThirtyDays || actionLoading}
                label="月份"
                mode="Month"
                value={selectedMonth}
                disableFuture={true}
                onChange={(val: Date | null) => {
                  if (val) setSelectedMonth(new Date(val));
                }}
              />
              <YatungSelect
                width={'220px'}
                disabled={actionLoading || !testUnitOptions}
                options={testUnitOptions}
                onChange={(e: SelectChangeEvent<unknown>) => setSelectedTestUnit(e.target.value as number)}
              />
              {selectGroup}
            </Stack>
          </Stack>
          <Stack marginLeft="auto" padding={2} direction="row" alignItems="center" gap={2}>
            <Typography sx={{ color: 'gray', fontSize: 24, fontWeight: 600 }}>月報名稱:</Typography>
            <YatungInput
              value={monthlyReportName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setMonthlyReportName(e.target.value)}
              disabled={actionLoading || afterTableData.length <= 0}
            />
            <YatungButton
              text={'生成'}
              color="green"
              onClick={postSaveViewList}
              disabled={actionLoading || afterTableData.length <= 0}
            />
          </Stack>
          <MonthlyReportTable exptType={exptType} tableData={afterTableData} selectedFormId={selectedFormId} />
          {open && headerData && (
            <MonthlyReportDialog
              open={open}
              closeHandler={() => setOpen(false)}
              detailData={detailData}
              headerData={headerData}
            />
          )}
        </Stack>
      }
    />
  );
};

export default memo(MonthlyReportSelect);
