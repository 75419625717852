import {
  MonthlyReportAggAbsorption,
  MonthlyReportNameEnum,
  ResultAndIsAbnormal,
} from '@Src/_basic/object/MonthlyReportType';
import { MonthlyReportApi } from '@Src/_basic/protocol/monthlyReport/MonthlyReportApi';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MonthlyReportSelect from '../components/MonthlyReportSelect';

const FineAggregateAbsorption = () => {
  const { t: i18T } = useTranslation();
  const [tableData, setTableData] = useState<Array<MonthlyReportAggAbsorption<ResultAndIsAbnormal<number>>>>([]);

  const selectHandler = useCallback(
    (
      factoryId: number,
      exptUnitId: number,
      startTime: number,
      endTime: number,
      specificationId?: number,
      sourceId?: number,
    ) => {
      if (!factoryId || !exptUnitId || !startTime || !endTime || !specificationId) return;

      MonthlyReportApi.getFineAggAbsorption(
        {
          factoryId,
          exptUnitId,
          startTime,
          endTime,
          specificationId,
          ...(sourceId && { sourceId }),
        },
        (_data) => setTableData(_data),
      );
    },
    [],
  );

  return (
    <MonthlyReportSelect
      title={i18T('REPORT.MONTHLY_REPORT.FINE_AGGREGATE_ABSORPTION.TITLE')}
      exptType={MonthlyReportNameEnum.FINE_AGGREGATE_ABSORPTION}
      selectHandler={selectHandler}
      tableData={tableData}
    />
  );
};

export default memo(FineAggregateAbsorption);
