import { TestInDistrictDetialByData } from '@Src/_basic/object/TestInDistrictType';
import { Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

interface Props {
  row: TestInDistrictDetialByData;
}
export default function CreateTimeColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{moment(row.createTime).format('YYYY/MM/DD HH:mm')}</Typography>;
}
