import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import SearchIcon from '@Src/_basic/icons/Search';
import Vehicle from '@Src/_basic/icons/Vehicle';
import { useApi } from '@Src/redux/api/apiAction';
import { SelectChangeEvent, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterValue } from '../../screens/VehicleManagementScreen';

interface Props {
  factoryOption: Options[];
  filterValue: FilterValue;
  filterChange: (file: keyof FilterValue, value: FilterValue[keyof FilterValue]) => void;
  onOpenCreateModal: () => void;
  onClickSarchButton: () => void;
}
export default function VehicleManagementSearchBar({
  factoryOption,
  onOpenCreateModal,
  filterValue,
  filterChange,
  onClickSarchButton,
}: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const initialStateOptions = [
    {
      value: 1,
      text: '使用中',
    },
    {
      value: 0,
      text: '停止',
    },
  ];

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
          <YatungSelect
            helperText={i18T('GLOBAL.FACTORY')}
            disabled={actionLoading}
            options={factoryOption}
            value={filterValue.factoryId}
            onChange={(e: SelectChangeEvent<unknown>) => {
              filterChange('factoryId', e.target.value as number);
            }}
          />
          <YatungSelect
            helperText={'狀態'}
            disabled={actionLoading}
            options={initialStateOptions}
            value={filterValue.removed}
            onChange={(e: SelectChangeEvent<unknown>) => {
              filterChange('removed', e.target.value as number);
            }}
          />
          <YatungInput
            value={filterValue.plateNumber}
            onChange={(e: SelectChangeEvent) => filterChange('plateNumber', e.target.value)}
          />
          <YatungButton
            disabled={actionLoading}
            text={i18T('GLOBAL.SEARCh_BUTTON')}
            startIcon={<SearchIcon />}
            color="blue"
            onClick={onClickSarchButton}
          />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <YatungButton
            disabled={actionLoading}
            text={i18T('USERSMANAGEMENU.VEHICLE_MANAGEMENT.CREATE_VEHICLE')}
            startIcon={<Vehicle />}
            onClick={onOpenCreateModal}
            color={'green'}
            sx={{
              fontSize: '16px',
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}
