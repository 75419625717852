export default [
  {
    id: 1,
    area: '東區',
    areaSort: 1,
    createDate: '2022/07/16',
    state: '運轉中',
    disabled: true,
  },
  {
    id: 2,
    area: '西區',
    areaSort: 2,
    createDate: '2022/07/17',
    state: '運轉中',
    disabled: true,
  },
  {
    id: 3,
    area: '南區',
    areaSort: 3,
    createDate: '2022/07/18',
    state: '運轉中',
    disabled: true,
  },
  {
    id: 4,
    area: '北區',
    areaSort: 4,
    createDate: '2022/07/19',
    state: '關閉中',
    disabled: false,
  },
  {
    id: 5,
    area: '高屏區',
    areaSort: 5,
    createDate: '2022/07/20',
    state: '關閉中',
    disabled: false,
  },
];
