import React from 'react';
import { RibbonVariantEnum } from '@Src/_basic/object/ProductionHistoryType';
import { useElementSize } from 'usehooks-ts';

import CementitiousRibbon from './components/CementitiousRibbon';
import SandAndRockRibbon from './components/SandAndRockRibbon';
import ChemicalRibbon from './components/ChemicalRibbon';

export interface Props {
  variant: RibbonVariantEnum;
  children?: React.ReactNode;
  first?: boolean;
  divider?: boolean;
}

export default function YatungPaperWithRibbon(props: Props) {
  const [ribbonRef, ribbonSize] = useElementSize();

  return (
    <div
      className={`yatung-paper-with-ribbon ${props.first && 'first'} ${props.divider && 'divider'}`}
      style={{ minHeight: 'calc(530px/3)' }}
    >
      {props.variant === RibbonVariantEnum.CEMENTITIOUS && <CementitiousRibbon ref={ribbonRef} />}
      {props.variant === RibbonVariantEnum.SAND_AND_ROCK && <SandAndRockRibbon ref={ribbonRef} />}
      {props.variant === RibbonVariantEnum.CHEMICAL && <ChemicalRibbon ref={ribbonRef} />}

      <div className="content" style={{ marginLeft: `${ribbonSize.width}px` }}>
        {props.children}
      </div>
    </div>
  );
}
