export function numberComma(num: string | number | null) {
  const comma = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
  return num?.toString().replace(comma, ',') ?? 0;
}

export function getRandomNumber(max: number) {
  // 1 ~ max
  return Math.ceil(Math.random() * max);
}

export function getRandomNumberBetween(min: number, max: number) {
  // min ~ max
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
