import React, { useState, useEffect } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import { Card, CardContent, Typography, Box, SxProps, Theme } from '@mui/material';
import CheckButton from './CheckButton';
import { Options } from '@Src/_basic/components/YatungSelect';

interface TagsSelectPanelProps {
  handleTagsSelect: (tag: Options) => void;
  selectedTags: Array<Options>;
  tagItems?: Array<Options>;
  sx?: SxProps<Theme> | undefined;
}

const TagsSelectPanel = ({ selectedTags, handleTagsSelect, tagItems, sx = {} }: TagsSelectPanelProps) => {
  const [showTagItems, setShowTagItems] = useState(false);

  return (
    <Card
      sx={{
        background: 'linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%)',
        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2);',
      }}
    >
      <CardContent sx={{ maxHeight: '346px', overflow: 'auto', ...sx }}>
        <Typography
          sx={{
            fontSize: 20,
            color: '#BB5E00',
            fontWeight: 900,
          }}
        >
          標籤設定：
        </Typography>
        {selectedTags.map((tag, i) => (
          <CheckButton
            key={`selected-tag-${i}`}
            checked={true}
            sx={{ minWidth: 0, px: '12px' }}
            onClick={() => handleTagsSelect(tag)}
          >
            {tag.text}
          </CheckButton>
        ))}
        <CheckButton sx={{ minWidth: 0, px: '12px', my: 1 }} onClick={() => setShowTagItems(!showTagItems)}>
          {showTagItems ? 'Close' : 'Open'}
        </CheckButton>
        {showTagItems && (
          <Box sx={{ backgroundColor: '#EBEBEB', p: 2, height: '350px', overflow: 'auto' }}>
            {tagItems
              ?.filter((t) => selectedTags?.findIndex((st) => st.text === t.text) === -1)
              ?.map((tag, i) => (
                <YatungButton
                  key={`tag-${i}`}
                  onClick={() => handleTagsSelect(tag)}
                  sx={{
                    fontSize: 16,
                    backgroundColor: 'white',
                    color: '#6C708C',
                    border: '1px solid #6C708C',
                    m: '4px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#6C708C',
                    },
                  }}
                >
                  {tag.text}
                </YatungButton>
              ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default TagsSelectPanel;
