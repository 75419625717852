import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function WaterBig(props: SvgIconProps) {
    return (
        <svg {...props} width="51" height="63" viewBox="0 0 51 63" fill="none" >
            <path d="M25.5 62.4444C19.0022 62.4444 12.7705 59.855 8.17588 55.2458C3.58124 50.6366 1 44.3851 1 37.8667C1 27.1077 9.29938 18.5025 16.5973 10.6161L25.5 1L34.4027 10.6161C41.7006 18.5055 50 27.1108 50 37.8667C50 44.3851 47.4188 50.6366 42.8241 55.2458C38.2295 59.855 31.9978 62.4444 25.5 62.4444V62.4444Z" stroke="#7C878E" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.77729 37.8801C9.45683 37.8801 10.0077 38.431 10.0077 39.1105C10.0077 43.3224 11.6809 47.3617 14.6591 50.3399C17.6373 53.3181 21.6766 54.9912 25.8884 54.9912C26.5679 54.9912 27.1188 55.5421 27.1188 56.2217C27.1188 56.9012 26.5679 57.4521 25.8884 57.4521C21.0239 57.4521 16.3587 55.5197 12.919 52.08C9.47928 48.6403 7.54688 43.975 7.54688 39.1105C7.54688 38.431 8.09775 37.8801 8.77729 37.8801Z" fill="#7C878E" stroke="#7C878E" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.612 32.1108C10.612 33.1233 9.79117 33.9441 8.77865 33.9441C7.76612 33.9441 6.94531 33.1233 6.94531 32.1108C6.94531 31.0983 7.76612 30.2775 8.77865 30.2775C9.79117 30.2775 10.612 31.0983 10.612 32.1108Z" fill="#7C878E" stroke="#7C878E" />
        </svg>
    );
}