import MockAdapter from 'axios-mock-adapter/types';
import { AreaProtocol } from '../AreaProtocol';
import areaTableMock from './areaTableMock';

export default function areaMockDetails(mock: MockAdapter) {
  mock.onGet(AreaProtocol.AREA_MANAGEMENT_DATA).reply(200, {
    code: '00000',
    data: areaTableMock,
    message: '成功',
  });
  mock.onPost(AreaProtocol.AREA_MANAGEMENT_ADD_DATA).reply(({ data }) => {
    console.log(data);
    const { area, areaSort, disabled } = JSON.parse(data);
    const mockArea = areaTableMock.map((el) => el.area);
    console.log(mockArea);
    const mockAreaSort = areaTableMock.map((el) => el.areaSort);
    console.log(mockAreaSort);
    if (area && areaSort) {
      const areaManagement = {
        id: generateUserId(),
        area,
        areaSort,
        createDate: '2022/12/13',
        state: '運轉中',
        disabled,
      };
      console.log(areaManagement);
      if (mockArea.includes(areaManagement.area) || mockAreaSort.includes(areaManagement.areaSort)) {
        return [200];
      } else {
        areaTableMock.push(areaManagement);
        return [200, { ...areaManagement }];
      }
    }
    console.log('error');
    return [400];
  });

  mock.onDelete(AreaProtocol.AREA_MANAGEMENT_DELETE_DATA).reply((config) => {
    const { id } = config.params;
    const index = areaTableMock.findIndex((el) => el.id === id);
    console.log(index);
    areaTableMock.splice(index, 1);
    console.log(areaTableMock);
    if (index !== -1) {
      return [200];
    }
    console.log('error');
    return [400];
  });
  mock.onPut(AreaProtocol.AREA_MANAGEMENT_UPDATE_DATA).reply(({ data }) => {
    console.log('data', data);
    const dataToJson = JSON.parse(data);
    console.log(dataToJson);
    const index = areaTableMock.findIndex((el) => el.id === dataToJson.id);
    console.log('index', index);
    const createDate = '2023/02/02';
    if (index !== -1) {
      areaTableMock[index] = { ...dataToJson, createDate: createDate };
      return [200, { ...dataToJson, createDate: createDate }];
    }
    console.log('error');
    return [400];
  });
  mock.onGet(AreaProtocol.AREA_MANAGEMENT_GET_ID).reply((config) => {
    const datas = areaTableMock.find((el) => el.id === config.params.id);
    if (datas) {
      return [200, datas];
    }
    return [400];
  });
  function generateUserId() {
    const ids = areaTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}
