import React from 'react';
import AccountManagementUpdateCard from '../components/AccountManagementUpdateCard';

export default function AccountManagementUpdateCardScreen() {
  return (
    <>
      <AccountManagementUpdateCard />
    </>
  );
}
