import React from 'react';
import YatungInput from './YatungInput';

interface Props {
  field: any;
  form: { touched: string; errors: string };
}

export default function YatungFormInput({ field, form: { touched, errors }, ...props }: Props) {
  return (
    <YatungInput
      error={touched[field.name] && !!errors[field.name]}
      helperText={touched[field.name] && errors[field.name]}
      {...field}
      {...props}
    />
  );
}
