import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../../Api';
import { TransferOrderGroupProtocol } from './TransferOrderGroupApi.tsProtocol';
import { TransferOrderGroupsData, CreateTransferOrderGroupsRequest, UpdateTransferOrderGroupsRequest, TransferOrderGroupsBySearchParams, PageableTransferOrderGroupsBySearchParams, PageableTransferOrderGroupsData } from '@Src/_basic/object/AccountRightType';

export class TransferOrderGroupApi {
  // 取得轉單群組清單
  public static getTransferOrderGroupsBySearch(
    params: TransferOrderGroupsBySearchParams,
    onSuccess?: (data: Array<TransferOrderGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params
    };
    Api.get(TransferOrderGroupProtocol.GET_TRANSFER_ORDER_GROUPS, config, onSuccess, onFailed, onFinally);
  }

  // 取得分頁轉單群組清單
  public static getPageableTransferOrderGroupsBySearch(
    params: PageableTransferOrderGroupsBySearchParams,
    onSuccess?: (data: PageableTransferOrderGroupsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params
    };
    Api.get(TransferOrderGroupProtocol.GET_PAGEABLE_TRANSFER_ORDER_GROUPS, config, onSuccess, onFailed, onFinally);
  }

  // 以ID取得回報群組
  public static getTransferOrderGroupById(
    id: number,
    onSuccess?: (data: Array<TransferOrderGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id: id,
      },
    };
    Api.get(TransferOrderGroupProtocol.TRANSFER_ORDER_GROUP_URL, config, onSuccess, onFailed, onFinally);
  }

  // 新增回報群組
  public static postCreateTransferOrderGroup(
    request: CreateTransferOrderGroupsRequest,
    onSuccess?: (data: Array<TransferOrderGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(TransferOrderGroupProtocol.TRANSFER_ORDER_GROUP_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改回報群組
  public static putUpdateTransferOrderGroup(
    request: UpdateTransferOrderGroupsRequest,
    onSuccess?: (data: Array<TransferOrderGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(TransferOrderGroupProtocol.TRANSFER_ORDER_GROUP_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 刪除回報群組
  public static deleteTransferOrderGroup(
    id: number,
    onSuccess?: (data: Response<null>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id: id,
      },
    };
    Api.delete(TransferOrderGroupProtocol.TRANSFER_ORDER_GROUP_URL, config, onSuccess, onFailed, onFinally);
  }
  
}