import React, { memo } from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
  Icon?: any;
  label: string;
  value?: string;
  children?: JSX.Element;
  alignItemStyle?: string;
  justifyContent?: string;
}

const TestManageDetailItem = ({
  Icon,
  label,
  value,
  children,
  justifyContent = 'flex-start',
  alignItemStyle = 'center',
}: Props) => {
  return (
    <Stack
      spacing={1}
      alignItems={alignItemStyle}
      justifyContent={justifyContent}
      direction="row"
      sx={{ p: 1, width: '100%', fontSize: '18px' }}
    >
      {Icon && <Icon sx={{ mr: 1 }} />}
      <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none', padding: '0 8px 0 0' }}>{`${label} :`}</Typography>
      {value ?? children}
    </Stack>
  );
};

export default memo(TestManageDetailItem);
