import mockAuth from '@Basic/protocol/auth/__mocks__/mockAuth';
import areaMockDetails from '@Src/_basic/protocol/area/_mocks_/areaMockDetails';
import mockDetails from '@Src/_basic/protocol/auth/__mocks__/mockDetails';
import factoryMockDetails from '@Src/_basic/protocol/factoryManagement/_mocks_/factoryMockDetails';
import feedTroughMaintenanceMockDetails from '@Src/_basic/protocol/feedTroughMaintenanceManagement/_mocks_/feedTroughMaintenanceMockDetails';
import mixerMockDetails from '@Src/_basic/protocol/mixerInformation/_mocks_/mixerMockDetails';
import organizeMockDetails from '@Src/_basic/protocol/organizeManagement/_mocks_/organizeMockDetails';
import siteMockDetails from '@Src/_basic/protocol/site/_mocks_/siteMockDetails';
import i18nMockDetails from '@Src/_basic/protocol/testI18n/_mocks_/i18nMockDetails';
import { AxiosStatic } from 'axios';
import MockAdapter from 'axios-mock-adapter';

export default function mockAxios(axios: AxiosStatic) {
  const mock = new MockAdapter(axios);
  mockDetails(mock);
  areaMockDetails(mock);
  factoryMockDetails(mock);
  mockAuth(mock);
  siteMockDetails(mock);
  organizeMockDetails(mock);
  feedTroughMaintenanceMockDetails(mock);
  mixerMockDetails(mock);
  i18nMockDetails(mock);
  return mock.onAny().passThrough();
}
