import YatungButton from '@Src/_basic/components/YatungButton';
import YatungText from '@Src/_basic/components/YatungText';
import { AllTest, Record } from '@Src/_basic/object/TestType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { CardContent, Stack } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RecordDetailCard from './components/RecordDetailCard';

interface Props {
  firstRecord: Record;
  secondRecord: Record;
  setViewPage: (value: number) => void;
}

const CheckSelectedRecord = ({ firstRecord, secondRecord, setViewPage }: Props) => {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const [testFormS1, setTestFormS1] = useState<AllTest>();
  const [testFormS2, setTestFormS2] = useState<AllTest>();

  useEffect(() => {
    TestApi.getTestDetail({ exptItemCode: firstRecord.exptItemCode, exptId: firstRecord.id }, (data) =>
      setTestFormS1(data),
    );
    TestApi.getTestDetail({ exptItemCode: secondRecord.exptItemCode, exptId: secondRecord.id }, (data) =>
      setTestFormS2(data),
    );
  }, [firstRecord, secondRecord]);

  return (
    <>
      <CardContent
        sx={{
          background: 'linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%)',
          boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2);',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <YatungText text={i18T('REPORT.SIEVE_REPORT.BASIC.CHECK_WHETHER_GENERATE_REPORT')} />
        <Stack flexDirection="row" justifyContent="space-between" gap={2}>
          <RecordDetailCard item={firstRecord} first={true} />
          <RecordDetailCard test={testFormS1} first={true} />
        </Stack>

        <Stack flexDirection="row" justifyContent="space-between" gap={2}>
          <RecordDetailCard item={secondRecord} />
          <RecordDetailCard test={testFormS2} />
        </Stack>
      </CardContent>
      <Stack flexDirection="row" justifyContent="flex-end" gap={4} sx={{ my: 4 }}>
        <YatungButton color="red" width={100} text={i18T('GLOBAL.CANCEL')} onClick={() => setViewPage(1)} />
        <YatungButton
          color="green"
          width={100}
          text={i18T('GLOBAL.NEXT_PAGE')}
          onClick={() =>
            navigate(`/report/sieve-report/${firstRecord.id}/${secondRecord.id}/${firstRecord.exptItemCode}`)
          }
        />
      </Stack>
    </>
  );
};

export default memo(CheckSelectedRecord);
