import { MonitorCustomerData } from '@Src/_basic/object/MonitorType';
import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  row: MonitorCustomerData;
}
export default function WarningColumnFormatter(props: Props) {
  const { row } = props;
  return (
    <>
      {row.failCount === null ? (
        <Typography
          sx={{
            color: '#00D03A',
            fontWeight: 600,
          }}
        >
          坍度試驗合格
        </Typography>
      ) : (
        <Typography
          sx={{
            color: '#CB333B',
            fontWeight: 600,
          }}
        >
          {row.failCount}車次異常
        </Typography>
      )}
    </>
  );
}
