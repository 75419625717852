import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import RecordScreen from './screens';
import RecordStatusListScreen from './screens/RecordStatusListScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/*',
    element: <RecordScreen />,
  },
  {
    path: '/status-list/*',
    element: <RecordStatusListScreen />,
  },
];

const Record = () => PermissionRoute(routesConfig);

export default memo(Record);
