import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungSelect, { Options, SampleOption } from '@Src/_basic/components/YatungSelect';
import { Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungFolderTree from '@Src/_basic/components/YatungFolderTree';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungTestPage from '@Src/_basic/components/YatungTest/YatungTestPage';
import CancelIcon from '@Src/_basic/icons/Cancel';
import { GetSampleByIdData } from '@Src/_basic/object/SampleCourseType';
import { SampleCourseApi } from '@Src/_basic/protocol/sampleCourse/SampleCourseApi';
import QRCodeCanvas from 'qrcode.react';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router';

const background = '/media/images/applicationsMange/materialReceiptsBG.png';

export default function SampleCourseTreeScreens() {
  const { t: i18T } = useTranslation();

  const [selectType, setSelectType] = useState<number>(0);
  const [sampleIdData, setSampleIdData] = useState<any>(); //樣品ID
  const [sampleType, setSampleType] = useState<any>([]); //樣品類型
  const [sampleTime, setSampleTime] = useState<any>(); //樣品取樣時間

  const [sampleCourse, setSampleCourse] = useState<GetSampleByIdData[] | undefined>(undefined); //樣品歷程的試驗資料
  const [selectsampleCourse, setSelectSampleCourse] = useState<GetSampleByIdData[] | undefined>(undefined); //樣品歷程的試驗資料

  const [sampleExptId, setSampleExptId] = useState<Array<SampleOption>>([]); //樣品歷程的試驗資料
  const [exptSelectId, setExptSelectId] = useState<Array<Options>>([]); //選擇樣品歷程的試驗

  const location = useLocation();
  const navigate = useNavigate();

  const queryObject = qs.parse(location.search, { ignoreQueryPrefix: true });

  const getSampleDetailData = (id: string) => {
    SampleCourseApi.getSampleCourseById(id, onGetMonitorSuccess);
  };

  const onGetMonitorSuccess = (data: GetSampleByIdData[]) => {
    setSampleCourse(data);
  };

  useEffect(() => {
    //取得樣品ID
    if (location.search) {
      if (queryObject.id) {
        if (queryObject.type) {
          if (queryObject.time) {
            setSampleIdData(queryObject.id.toString());
            getSampleDetailData(queryObject.id.toString());
            setSampleType(queryObject.type.toString());
            setSampleTime(queryObject.time.toString());
          }
        }
      }
    }
  }, []);

  const getSampleExpt = () => {
    if (sampleCourse) {
      const typesOptions = sampleCourse.map((item: GetSampleByIdData) => ({ id: item.exptItemCode }));
      setSampleExptId(typesOptions);
    }
  };

  const handleGoBackButtonClick = () => {
    navigate(-1);
  };

  const handleSelectType = useCallback(
    //選擇樣品歷程的試驗
    (e: SelectChangeEvent<any>) => {
      const selectedFacCode = exptSelectId[e?.target?.value - 1]?.facCode; //選擇的試驗類型-1，由於新增全選:0，所以要減1
      const filteredSampleCourse = sampleCourse?.filter((value: any) => value.exptItemCode === selectedFacCode);
      setSelectSampleCourse(e?.target?.value === 0 ? sampleCourse : filteredSampleCourse); //如果選擇全選，則顯示全部的樣品歷程，否則顯示過濾後的樣品歷程
      setSelectType(e.target.value as number);
    },
    [exptSelectId, sampleCourse, setSelectType],
  );

  const test = sampleExptId.map((item: any) => item.id); //儲存樣品歷程的試驗類型

  const testfilter = new Set(test); //過濾重複的試驗類型

  const testArray = Array.from(testfilter); //轉成陣列

  const SelectSampleId = () => {
    const typesIdOptions = testArray.map((type: string, index: number) => ({
      //將樣品歷程的試驗類型轉成選項
      value: index + 1, //由於新增全選:0，所以要加1
      text: `${i18T(`TEST.TEST_ITEM.${type}`)}`,
      facCode: type,
    }));
    setExptSelectId(typesIdOptions);
  };

  useEffect(() => {
    getSampleExpt();
  }, [sampleCourse]);

  useEffect(() => {
    SelectSampleId();
  }, [sampleCourse]);

  const jsonFile = {
    sampleId: sampleIdData,
  };

  const qrcode = JSON.stringify(jsonFile); //QRcode的value使用json格式顯示，並轉成string

  return (
    <>
      <YatungPage
        title={i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.TITLE')}
        body={
          <>
            <YatungTestPage
              title={''}
              backgroundImage={background}
              body={
                <Stack>
                  <Stack direction="row" justifyContent={'flex-end'} spacing={2} sx={{ py: 1 }}>
                    <YatungButton
                      text="返回"
                      startIcon={<CancelIcon />}
                      color="blue"
                      onClick={handleGoBackButtonClick}
                    />
                  </Stack>
                  <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2, overflow: 'auto' }}>
                    <Grid>
                      <Stack
                        sx={{
                          color: '#ffffff',
                          bgcolor: '#BB5E00',
                          fontSize: '20px',
                          borderRadius: '5px',
                          padding: '5px',
                          marginTop: '10px',
                          width: 90,
                          '&.MuiButtonBase-root:hover': {
                            bgcolor: '#BB5E00',
                          },
                        }}
                      >
                        {i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.SAMPLE_QRCODE')}
                      </Stack>
                    </Grid>
                    <Grid>
                      <QRCodeCanvas
                        size={256}
                        viewBox={`0 0 256 256`}
                        value={`${qrcode}`}
                        bgColor="#FFF"
                        style={{ border: '10px solid #FFF' }}
                      />
                      <Stack>
                        {i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.SAMPLE_DATE')}：{`${sampleTime}`}
                      </Stack>
                      <Stack>
                        {i18T('APPLICATIONSMANAGEMENT.SAMPLE_COURSE.TYPE')}：{`${sampleType}`}
                      </Stack>

                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={3}>
                          <Typography variant="body1"> 類型：</Typography>
                        </Grid>
                        <YatungSelect
                          options={[{ text: i18T('GLOBAL.ALL'), value: 0 }, ...exptSelectId]}
                          value={selectType}
                          onChange={(e) => handleSelectType(e)}
                        />
                      </Grid>
                    </Grid>

                    <YatungFolderTree smapleData={selectsampleCourse ? selectsampleCourse : sampleCourse} />
                  </Grid>
                </Stack>
              }
            />
          </>
        }
      />
    </>
  );
}
