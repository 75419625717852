import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function VehicleModal(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="64" height="36" viewBox="0 0 64 36" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1698 0.626996H4.83019C2.496 0.626996 0.603774 2.59201 0.603774 5.01597V30.984C0.603774 33.408 2.496 35.373 4.83019 35.373H59.1698C61.504 35.373 63.3962 33.408 63.3962 30.984V5.01596C63.3962 2.592 61.504 0.626996 59.1698 0.626996ZM4.83019 0C2.16255 0 0 2.24573 0 5.01597V30.984C0 33.7543 2.16255 36 4.83019 36H59.1698C61.8375 36 64 33.7543 64 30.984V5.01596C64 2.24572 61.8375 0 59.1698 0H4.83019Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.8056 3.0256H7.1947C4.86343 3.0256 2.97356 5.01564 2.97356 7.47047V28.5108C2.97356 30.9656 4.86344 32.9556 7.19471 32.9556H56.8056C59.1369 32.9556 61.0268 30.9656 61.0268 28.5108V7.47046C61.0268 5.01564 59.1369 3.0256 56.8056 3.0256ZM7.1947 2.39062C4.53039 2.39062 2.37054 4.66495 2.37054 7.47047V28.5108C2.37054 31.3163 4.5304 33.5906 7.19471 33.5906H56.8056C59.47 33.5906 61.6298 31.3163 61.6298 28.5108V7.47046C61.6298 4.66495 59.47 2.39062 56.8056 2.39062H7.1947Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29439 10.7903C9.60111 10.7903 10.6604 9.71773 10.6604 8.39468C10.6604 7.07162 9.60111 5.99908 8.29439 5.99908C6.98767 5.99908 5.92836 7.07162 5.92836 8.39468C5.92836 9.71773 6.98767 10.7903 8.29439 10.7903ZM8.29439 11.9947C10.2581 11.9947 11.8499 10.3829 11.8499 8.39468C11.8499 6.40645 10.2581 4.79468 8.29439 4.79468C6.33071 4.79468 4.73883 6.40645 4.73883 8.39468C4.73883 10.3829 6.33071 11.9947 8.29439 11.9947Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8505 11.9257C12.0136 11.9647 12.1834 11.9853 12.3578 11.9853H16.6803C17.9402 11.9853 18.9616 10.9108 18.9616 9.5853C18.9616 8.25982 17.9402 7.1853 16.6803 7.1853H12.3578C12.2419 7.1853 12.128 7.1944 12.0167 7.21195C12.4575 7.84066 12.718 8.61782 12.718 9.45899C12.718 10.4011 12.3913 11.2628 11.8505 11.9257Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.7045 10.7903C54.3977 10.7903 53.3384 9.71773 53.3384 8.39468C53.3384 7.07162 54.3977 5.99908 55.7045 5.99908C57.0112 5.99908 58.0705 7.07162 58.0705 8.39468C58.0705 9.71773 57.0112 10.7903 55.7045 10.7903ZM55.7045 11.9947C53.7408 11.9947 52.1489 10.3829 52.1489 8.39468C52.1489 6.40645 53.7408 4.79468 55.7045 4.79468C57.6681 4.79468 59.26 6.40645 59.26 8.39468C59.26 10.3829 57.6681 11.9947 55.7045 11.9947Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.1484 11.9257C51.9852 11.9647 51.8154 11.9853 51.641 11.9853H47.3186C46.0586 11.9853 45.0373 10.9108 45.0373 9.5853C45.0373 8.25982 46.0586 7.1853 47.3186 7.1853H51.641C51.7569 7.1853 51.8708 7.1944 51.9821 7.21195C51.5413 7.84066 51.2808 8.61782 51.2808 9.45899C51.2808 10.4011 51.6076 11.2628 52.1484 11.9257Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29439 29.9867C9.60111 29.9867 10.6604 28.9141 10.6604 27.5911C10.6604 26.268 9.60111 25.1955 8.29439 25.1955C6.98767 25.1955 5.92836 26.268 5.92836 27.5911C5.92836 28.9141 6.98767 29.9867 8.29439 29.9867ZM8.29439 31.1911C10.2581 31.1911 11.8499 29.5793 11.8499 27.5911C11.8499 25.6029 10.2581 23.9911 8.29439 23.9911C6.33071 23.9911 4.73883 25.6029 4.73883 27.5911C4.73883 29.5793 6.33071 31.1911 8.29439 31.1911Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8505 28.7315C12.0136 28.7705 12.1834 28.7911 12.3578 28.7911H16.6803C17.9402 28.7911 18.9616 27.7166 18.9616 26.3911C18.9616 25.0656 17.9402 23.9911 16.6803 23.9911H12.3578C12.2419 23.9911 12.128 24.0002 12.0167 24.0177C12.4575 24.6465 12.718 25.4236 12.718 26.2648C12.718 27.2068 12.3913 28.0686 11.8505 28.7315Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.7045 29.9867C54.3977 29.9867 53.3384 28.9141 53.3384 27.5911C53.3384 26.268 54.3977 25.1955 55.7045 25.1955C57.0112 25.1955 58.0705 26.268 58.0705 27.5911C58.0705 28.9141 57.0112 29.9867 55.7045 29.9867ZM55.7045 31.1911C53.7408 31.1911 52.1489 29.5793 52.1489 27.5911C52.1489 25.6029 53.7408 23.9911 55.7045 23.9911C57.6681 23.9911 59.26 25.6029 59.26 27.5911C59.26 29.5793 57.6681 31.1911 55.7045 31.1911Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.1484 28.7315C51.9852 28.7705 51.8154 28.7911 51.641 28.7911H47.3186C46.0586 28.7911 45.0373 27.7166 45.0373 26.3911C45.0373 25.0656 46.0586 23.9911 47.3186 23.9911H51.641C51.7569 23.9911 51.8708 24.0002 51.9821 24.0177C51.5413 24.6465 51.2808 25.4236 51.2808 26.2648C51.2808 27.2068 51.6076 28.0686 52.1484 28.7315Z"
        fill="white"
      />
    </SvgIcon>
  );
}
