import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import WaterDecadeScreen from './screens/WaterDecadeScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <WaterDecadeScreen />,
  },
];

const WaterDecadeRouter = () => PermissionRoute(routesConfig);

export default memo(WaterDecadeRouter);
