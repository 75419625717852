import React from 'react';

export default function Location() {
  return (
    <svg width={25} height={28} viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1285 7.01704L14.1278 7.01645C13.5761 6.48767 12.9021 6.22 12.1334 6.22C11.3646 6.22 10.6909 6.48769 10.1398 7.01675C9.58746 7.54698 9.30005 8.20345 9.30005 8.96C9.30005 9.7164 9.58735 10.3725 10.1397 10.902C10.6907 11.4318 11.3645 11.7 12.1334 11.7C12.9024 11.7 13.5764 11.4318 14.1281 10.9021C14.6799 10.3725 14.9667 9.7163 14.9667 8.96C14.9667 8.20373 14.6799 7.54735 14.1285 7.01704ZM5.52813 14.9855L5.52802 14.9853C4.0163 12.8413 3.30005 10.9113 3.30005 9.184C3.30005 6.50847 4.19031 4.42109 5.94663 2.86639L5.9467 2.86632C7.73275 1.28464 9.78805 0.5 12.1334 0.5C14.4787 0.5 16.534 1.28464 18.3201 2.86632L18.3201 2.86639C20.0765 4.42109 20.9667 6.50847 20.9667 9.184C20.9667 10.9114 20.2508 12.8414 18.7399 14.9854C17.2811 17.0551 15.0848 19.3112 12.1334 21.7528C9.18204 19.3112 6.98616 17.0551 5.52813 14.9855Z"
        stroke="#6C708C"
      />
      <path
        d="M5.6 18.8868C2.23224 19.7656 0 21.3039 0 23.0546C0 25.7858 5.43228 27.9998 12.1333 27.9998C18.8344 27.9998 24.2667 25.7858 24.2667 23.0546C24.2667 21.1468 21.6158 19.4912 17.7333 18.6665V19.5154C18.6949 19.7305 19.566 19.9982 20.3218 20.3062C22.4485 21.173 23.3333 22.2043 23.3333 23.0546C23.3333 23.905 22.4485 24.9363 20.3218 25.8031C18.2743 26.6376 15.3802 27.1756 12.1333 27.1756C8.88644 27.1756 5.99233 26.6376 3.94489 25.8031C1.81817 24.9363 0.933333 23.905 0.933333 23.0546C0.933333 22.2043 1.81817 21.173 3.94489 20.3062C4.44793 20.1012 5.00207 19.914 5.6 19.7484V18.8868Z"
        fill="#6C708C"
      />
    </svg>
  );
}
