import React, { memo, useMemo } from 'react';
import YatungSelect, { Options, Props as YatungSelectProps } from './YatungSelect';

export interface Props extends YatungSelectProps {
  totalOptions: Options[];
}

function YatungDynamicSelect(props: Props) {
  const selectedOption: Options | undefined = useMemo(() => {
    return props.totalOptions.find((option) => option.value === props.value);
  }, [props.totalOptions, props.value]);

  const displayOptions: Options[] = useMemo(() => {
    return selectedOption ? [selectedOption, ...props.options] : props.options;
  }, [selectedOption, props.options]);

  return <YatungSelect {...props} options={displayOptions} />;
}

export default memo(YatungDynamicSelect);
