import React from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import { useTranslation } from 'react-i18next';
import { StationsData } from '@Src/_basic/object/SiteType';

interface Props {
  onSubmit: () => void;
  onGoBack: () => void;
  createWbStation: StationsData[];
  createGuardhouse: StationsData[];
  createFeedInlet: StationsData[];
  createQualityControl: StationsData[];
}
export default function SiteManagementCreateSubmit(props: Props) {
  const { t: i18T } = useTranslation();
  const { onSubmit, onGoBack, createWbStation, createGuardhouse, createFeedInlet, createQualityControl } = props;

  return (
    <>
      <YatungButton
        text={i18T('SITEMANAGEMENT.CANCEL')}
        color="error"
        variant="contained"
        size="large"
        onClick={onGoBack}
        sx={{
          color: '#ffffff',
          fontSize: '36px',
          p: 2,
          mr: 5,
        }}
      />
      <YatungButton
        disabled={
          createWbStation.length === 0 &&
          createGuardhouse.length === 0 &&
          createFeedInlet.length === 0 &&
          createQualityControl.length === 0
        }
        text={i18T('SITEMANAGEMENT.COMPLETE')}
        color="blue"
        variant="contained"
        size="large"
        onClick={onSubmit}
        sx={{
          color: '#ffffff',
          fontSize: '36px',
          p: 2,
        }}
      />
    </>
  );
}
