import React from 'react';
import { Typography } from '@mui/material';
import { VehicleData } from '@Src/_basic/object/VehicleType';

interface Props {
  row: VehicleData;
}

export default function PlateNumberColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.plateNumber}</Typography>;
}
