import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { Options } from '@Src/_basic/components/YatungSelect';
import ExportIcon from '@Src/_basic/icons/Export';
import SearchIcon from '@Src/_basic/icons/Search';
import { YardReceiptsApi } from '@Src/_basic/protocol/yardReceipts/YardReceiptsApi';
import { Grid, Pagination, Stack } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StorageYardMaterialReceiptsExcelTable from '../components/StorageYardMaterialReceiptsExcelTable';
import StorageYardMaterialReceiptsTable from '../components/StorageYardMaterialReceiptsTable';
import StorageYardReciptDetailModal from '../components/StorageYardReciptDetailModal';
import StorageYardReciptUpdateModal from '../components/StorageYardReciptUpdateModal';

import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { SourceData } from '@Src/_basic/object/SourceType';
import { SupplierData } from '@Src/_basic/object/SupplierType';
import {
  OptionData,
  PageableYardReciptData,
  SearchPageableYardReceiptsParams,
  SearchYardReceiptsParams,
  YardReciptData,
} from '@Src/_basic/object/YardReceiptsType';
import { SourceApi } from '@Src/_basic/protocol/source/SourceApi';
import { SupplierApi } from '@Src/_basic/protocol/supplier/SupplierApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import saveAs from 'file-saver';
import moment from 'moment';
import { useLocation } from 'react-router';
import XLSX from 'xlsx-js-style';

const background = '/media/images/applicationsMange/materialReceiptsBG.png';

interface SourceOptions extends Options {
  specifications: Options[];
}

type FilterValueType = {
  factoryId: number;
  areaId: number;
  startTime: number;
  endTime: number;
  page: number;
  pageSize: number;
};

type AsyncStorageSavingType = FilterValueType;

export default function StorageYardMaterialReceiptsScreen() {
  const { t: i18T } = useTranslation();
  const { userAreaOptionsData } = useAuth();
  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [exporting, setExporting] = useState(false);
  const [selectedArea, setSelectedArea] = useState<number>(0);
  const [factories, setFactories] = useState<Options[]>([]);
  const [storageYardRecipts, setStorageYardRecipts] = useState<Array<YardReciptData>>([]);
  const [storageYardTotalRecipts, setStorageYardTotalRecipts] = useState<Array<YardReciptData>>([]);
  const [totalSpecificationValues, setTotalSpecificationValues] = useState<number>(0);
  const [selectedStorageYardRecipt, setSelectedStorageYardRecipt] = useState<YardReciptData>({
    createTime: 0,
    factoryId: 0,
    id: 0,
    lastUpdateTime: 0,
    plateNumber: '',
    receiptCode: '',
    removed: false,
    source1Id: 0,
    source1Name: '',
    source2Id: 0,
    source2Name: '',
    source3Id: 0,
    source3Name: '',
    specification1Id: 0,
    specification1Name: '',
    specification1Value: 0,
    specification2Id: 0,
    specification2Name: '',
    specification2Value: 0,
    specification3Id: 0,
    specification3Name: '',
    specification3Value: 0,
    staffId: 0,
    staffName: '',
    stationId: 0,
    storageId: 0,
    storageName: '',
    supplier1Id: 0,
    supplier1Name: '',
    supplier2Id: 0,
    supplier2Name: '',
    supplier3Id: 0,
    supplier3Name: '',
    typeId: 0,
    typeName: '',
  });
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [sources, setSources] = useState<SourceOptions[]>([]);
  const [suppliers, setSuppliers] = useState<Options[]>([]);

  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
    pageSize: 10,
    total: 0,
    totalCount: 0,
  });
  const [query, setQuery] = useState<SearchYardReceiptsParams>({
    startTime: new Date(new Date().setDate(1)).setHours(0, 0, 0, 0),
    endTime: new Date().setHours(23, 59, 59, 999),
    factoryId: 0,
    str: '',
    sortBy: 'id',
    isAsc: true,
    removed: false,
  });
  const [selectedAreaName, setSelectedAreaName] = useState<string | undefined>('');
  const [factoriesName, setFactoriesName] = useState<string | undefined>('');

  const exportIdRef = useRef(null);

  const [rowId, setRowId] = useState<string>('');
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const reset = useCallback(() => {
    setStorageYardRecipts([]);
  }, [setStorageYardRecipts]);

  const handleSelectionToText = () => {
    const selectedAreaToText = userAreaOptionsData.find((value) => value.value === selectedArea);
    const factoriesToText = factories.find((value) => value.value === query.factoryId);
    setSelectedAreaName(selectedAreaToText?.text);
    setFactoriesName(factoriesToText?.text);
  };

  useEffect(() => {
    handleSelectionToText();
  }, [selectedArea, query]);

  const handleQueryChange = (field: string) => async (value: unknown) => {
    setQuery({
      ...query,
      [field]: value,
    });
  };
  const handleSearchYardReceipts = (page: number) => {
    const params = { ...query };
    if (params['factoryId'] > 0) {
      const { pageSize } = pageInfo;
      const paginationParams: SearchPageableYardReceiptsParams = { ...params, page, pageSize };
      YardReceiptsApi.getPageableYardReceiptsBySearch(paginationParams, (res: PageableYardReciptData) => {
        setStorageYardRecipts(res.receipts);
        setPageInfo({
          ...pageInfo,
          page,
          total: res.maxPage,
          totalCount: res.elements,
        });
      });
    }
  };
  const getYardReceiptsTotalValues = () => {
    const params = { ...query };
    if (params['factoryId'] > 0) {
      const totalInputParams: SearchYardReceiptsParams = { ...params };
      YardReceiptsApi.getYardReceiptsTtl(
        totalInputParams,
        (data: any) => {
          if (data) {
            setTotalSpecificationValues(data);
          } else {
            setTotalSpecificationValues(0);
          }
        },
        undefined,
      );
    }
  };
  const getExcellExportSearchYardReceipts = () => {
    const params = { ...query };
    if (params['factoryId'] > 0) {
      setExporting(true);
      const totalParams: SearchYardReceiptsParams = { ...params };
      YardReceiptsApi.getCancelableYardReceiptsBySearch(
        totalParams,
        (data: Array<YardReciptData>) => {
          setStorageYardTotalRecipts(data);
          setExporting(false);
        },
        undefined,
        () => {
          setExporting(false);
        },
      );
    }
  };
  // const getTotalSpecificationValues = () => {
  //   let specification1Value = 0;
  //   let specification2Value = 0;
  //   let specification3Value = 0;
  //   storageYardTotalRecipts?.forEach((element: YardReciptData) => {
  //     specification1Value += element.specification1Value;
  //     specification2Value += element.specification2Value;
  //     specification3Value += element.specification3Value;
  //   });
  //   setTotalSpecificationValues(specification1Value + specification2Value + specification3Value);
  // };
  const getAllFactoryRelatedOptions = async (factoryId: number) => {
    const searchParams = { factoryId };
    SourceApi.getSourcesBySearch(searchParams, (allSources: Array<SourceData>) => {
      const sourceOptions: SourceOptions[] = allSources.map((allSource: SourceData) => ({
        value: allSource.id,
        text: allSource.sourceName,
        specifications: allSource.specifications.map((spec: OptionData) => ({ value: spec.id, text: spec.name })),
      }));
      setSources(sourceOptions);
    });

    SupplierApi.getSuppliersBySearch(searchParams, (allSuppliers: Array<SupplierData>) => {
      setSuppliers(allSuppliers.map((allSup: OptionData) => ({ value: allSup.id, text: allSup.name })));
    });
  };
  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    handleSearchYardReceipts(page);
    setPageInfo({
      ...pageInfo,
      page,
    });
  };
  const handleDetailButtonClick = (row: YardReciptData) => {
    setSelectedStorageYardRecipt(row);
    setDetailModalOpen(true);
  };

  const handleUpdateButtonClick = (row: YardReciptData) => {
    setSelectedStorageYardRecipt(row);
    setUpdateModalOpen(true);
  };

  const refresh = () => {
    handleSearchYardReceipts(1);
  };
  const afterDeleteRefreshData = () => {
    refresh();
    getYardReceiptsTotalValues();
  };
  const handleDeleteButtonClick = (id: string) => {
    // if (confirm(`刪除此物件(${id})？`) == true) {
    //   YardReceiptsApi.deleteYardReceipt(id, () => afterDeleteRefreshData());
    // }
    setIsShowAlert(true);
    setAlertText(`刪除此物件(${id})？`);
    setRowId(id);
  };
  const handleUpdateModalClose = (needRefresh = false) => {
    setUpdateModalOpen(false);
    if (needRefresh) {
      handleSearchYardReceipts(pageInfo.page);
      getExcellExportSearchYardReceipts();
    }
  };

  const exportFile = async () => {
    const params = { ...query };
    if (params['factoryId'] > 0 && storageYardTotalRecipts?.length > 0) {
      setExporting(true);
      const fileName = '堆場入料';
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet([]);
      XLSX.utils.sheet_add_dom(ws, exportIdRef.current, { dateNF: 'YYYY/MM/DD HH:mm' });
      XLSX.utils.book_append_sheet(wb, ws, '堆場入料');
      const range = XLSX.utils.decode_range(ws['!fullref']);

      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cellref = XLSX.utils.encode_cell({ c: C, r: R });
          const rowref = Number(XLSX.utils.encode_row(R));
          if (!ws[cellref]) continue;
          const cell = ws[cellref];
          const dateTimeRange =
            moment(params.startTime).format('YYYY/MM/DD') + '~' + moment(params.endTime).format('YYYY/MM/DD');

          const tableHeaderArray = ['資料筆數', '建單時間', '車號', '供應商', '來源', '規格', '斗數', '入料料庫'];
          const dataHeaderArray = ['堆場入庫統計表'];
          const dataDetailTitleArray = ['資料期間：', '區域：', '廠別：'];
          const dataDetailArray = [selectedAreaName, factoriesName, dateTimeRange];
          const specificationTitleArray = ['細砂合計：', '粗砂合計：', '三分石合計：', '六分石合計：', '0.8mm合計：'];
          const specificationTitleCalArray = ['總計入料：'];
          if (tableHeaderArray.includes(cell.v)) {
            cell.s = {
              fill: {
                patternType: 'solid',
                fgColor: { rgb: 'D3D3D3	' },
              },
              font: {
                name: '新細明體',
                sz: 12,
                bold: true,
              },
              alignment: {
                horizontal: 'center',
              },
              border: {
                top: { style: 'thin', color: { rgb: '#ff0000' } },
                bottom: { style: 'thin', color: { rgb: '#ff0000' } },
                left: { style: 'thin', color: { rgb: '#ff0000' } },
                right: { style: 'thin', color: { rgb: '#ff0000' } },
              },
            };
          } else if (dataHeaderArray.includes(cell.v)) {
            cell.s = {
              font: {
                name: '新細明體',
                sz: 18,
                bold: true,
              },
              alignment: {
                horizontal: 'center',
              },
            };
          } else if (dataDetailTitleArray.includes(cell.v)) {
            cell.s = {
              font: {
                name: '新細明體',
                sz: 12,
                bold: true,
              },
              alignment: {
                horizontal: 'center',
              },
            };
          } else if (specificationTitleArray.includes(cell.v)) {
            cell.s = {
              font: {
                name: '新細明體',
                sz: 12,
                bold: true,
              },
              alignment: {
                horizontal: 'center',
              },
            };
          } else if (specificationTitleCalArray.includes(cell.v)) {
            cell.s = {
              font: {
                name: '新細明體',
                sz: 12,
                bold: true,
              },
              alignment: {
                horizontal: 'left',
              },
            };
          } else if (dataDetailArray.includes(cell.v)) {
            cell.s = {
              font: {
                name: '新細明體',
                sz: 12,
              },
              alignment: {
                horizontal: 'center',
              },
            };
          } else if (rowref === 4) {
            cell.s = {
              font: {
                name: '新細明體',
                sz: 12,
              },
              alignment: {
                horizontal: 'center',
              },
            };
          } else if (rowref === 5) {
            cell.s = {
              font: {
                name: '新細明體',
                sz: 12,
              },
              alignment: {
                horizontal: 'center',
              },
            };
          } else if (rowref === 6) {
            cell.s = {
              font: {
                name: '新細明體',
                sz: 12,
              },
              alignment: {
                horizontal: 'center',
              },
            };
          } else if (rowref === 7) {
            cell.s = {
              font: {
                name: '新細明體',
                sz: 12,
              },
              alignment: {
                horizontal: 'center',
              },
            };
          } else if ((rowref - 10) % 3 === 0) {
            cell.s = {
              fill: {
                patternType: 'none',
              },
              font: {
                name: '新細明體',
                sz: 12,
              },
              alignment: {
                horizontal: 'center',
              },
              border: {
                top: { style: 'thin', color: { rgb: '#ff0000' } },
                right: { style: 'thin', color: { rgb: '#ff0000' } },
              },
            };
          } else if ((rowref - 11) % 3 === 0) {
            cell.s = {
              fill: {
                patternType: 'none',
              },
              font: {
                name: '新細明體',
                sz: 12,
              },
              alignment: {
                horizontal: 'center',
              },
              border: {
                right: { style: 'thin', color: { rgb: '#ff0000' } },
              },
            };
          } else if ((rowref - 12) % 3 === 0) {
            cell.s = {
              fill: {
                patternType: 'none',
              },
              font: {
                name: '新細明體',
                sz: 12,
              },
              alignment: {
                horizontal: 'center',
              },
              border: {
                right: { style: 'thin', color: { rgb: '#ff0000' } },
                bottom: { style: 'thin', color: { rgb: '#ff0000' } },
              },
            };
          } else {
            cell.s = {
              font: {
                name: '新細明體',
                sz: 12,
              },
              alignment: {
                horizontal: 'center',
              },
            };
          }
        }
      }
      const eb = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([eb], { type: fileType });
      saveAs(blob, fileName + fileExtension);
      setExporting(false);
    }
  };
  const handleSubmit = () => {
    // MaterialReceiptsApi.deleteReceipt(rowId, () => {
    //   refresh();
    //   setIsShowAlert(false);
    // });
    YardReceiptsApi.deleteYardReceipt(rowId, () => {
      afterDeleteRefreshData();
      setIsShowAlert(false);
    });
  };

  const handleChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    switch (field) {
      case 'startTime':
        setQuery({
          ...query,
          startTime: value as number,
        });
        break;
      case 'endTime':
        setQuery({
          ...query,
          endTime: value as number,
        });
        break;
      case 'factoryId':
        setQuery({
          ...query,
          factoryId: value as number,
        });
        break;
      case 'areaId':
        setSelectedArea(value as number);
        break;
    }
  };

  useEffect(() => {
    if (selectedArea) {
      if (!query.factoryId) {
        reset();
      } else {
        const selected = userAreaOptionsData.find((i) => i.value === selectedArea);
        if (selected) {
          setFactories(selected.factories);
        }
        handleSearchYardReceipts(pageInfo.page);
        setSaveRequest({
          page: location.pathname,
          request: {
            areaId: selectedArea,
            factoryId: query.factoryId,
            startTime: query.startTime,
            endTime: query.endTime,
            page: pageInfo.page,
            pageSize: pageInfo.pageSize,
          },
        });
      }
    }
  }, [query.factoryId, selectedArea, query.startTime, query.endTime, pageInfo.page]);

  useEffect(() => {
    if (request?.page === location.pathname && request?.request) {
      const { areaId, factoryId, startTime, endTime } = request.request;
      setSelectedArea(areaId);
      setQuery({
        ...query,
        factoryId: factoryId,
        startTime: startTime,
        endTime: endTime,
      });
    }
  }, [request]);

  useEffect(() => {
    if (query.factoryId > 0) {
      getAllFactoryRelatedOptions(query.factoryId);
      // refresh();
      getExcellExportSearchYardReceipts();
    }
  }, [query.factoryId, totalSpecificationValues, query.startTime, query.endTime]);
  useEffect(() => {
    getYardReceiptsTotalValues();
  }, [storageYardRecipts]);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.TITLE')}
      backgroundImage={background}
      actions={
        <Stack direction="row" spacing={2} alignItems="center">
          <YatungInput onChange={(e: any) => handleQueryChange('str')(e.target.value)} value={query.str} />
          <YatungButton text={i18T('GLOBAL.SEARCh_BUTTON')} startIcon={<SearchIcon />} color="blue" onClick={refresh} />
        </Stack>
      }
      body={
        <>
          <YatungConfirmAlert
            isShowAlert={isShowAlert}
            alertText={alertText}
            onOk={() => handleSubmit()}
            onClose={() => setIsShowAlert(false)}
          />
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2} sx={{ py: 2 }}>
              <YatungCombinationSelect<FilterValueType>
                filterValue={{
                  areaId: selectedArea,
                  factoryId: query.factoryId,
                  startTime: query.startTime,
                  endTime: query.endTime,
                  page: pageInfo.page,
                  pageSize: pageInfo.pageSize,
                }}
                handleChange={handleChange}
                selectOptions={['Date', 'AreaAndFactory']}
              />
            </Stack>
            <Stack direction="column">
              <YatungButton
                text={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.EXPORT')}
                disabled={storageYardRecipts.length === 0 || storageYardTotalRecipts?.length === 0 || exporting}
                loading={exporting}
                startIcon={<ExportIcon />}
                onClick={exportFile}
                color="gray"
                sx={{
                  fontSize: '20px',
                  width: 90,
                  alignSelf: 'center',
                }}
              />
              <YatungPaperLabel
                label={`${i18T('APPLICATIONSMANAGEMENT.TOOLBAR.TOTAL_FEEDING') + totalSpecificationValues}`}
                totalCount
              />
            </Stack>
          </Stack>
          <StorageYardMaterialReceiptsExcelTable
            exporting={exporting}
            exportIdRef={exportIdRef}
            query={query}
            storageYardTotalRecipts={storageYardTotalRecipts}
            selectedAreaName={selectedAreaName}
            factoriesName={factoriesName}
          />
          <StorageYardMaterialReceiptsTable
            rows={storageYardRecipts}
            pageInfo={pageInfo}
            onViewDetail={handleDetailButtonClick}
            onEdit={handleUpdateButtonClick}
            onDelete={handleDeleteButtonClick}
          />
          <Grid container alignItems={'center'} item xs={12} sx={{ p: 1 }}>
            <Grid container justifyContent={'center'} item xs={11}>
              <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
            </Grid>
            <Grid container justifyContent={'flex-end'} item xs={1}>
              <YatungPaperLabel
                label={`${i18T('APPLICATIONSMANAGEMENT.TOOLBAR.TOTAL_COUNT') + pageInfo.totalCount}`}
                totalCount
              />
            </Grid>
          </Grid>
          {detailModalOpen && (
            <StorageYardReciptDetailModal
              id={selectedStorageYardRecipt.id}
              receiptCode={selectedStorageYardRecipt.receiptCode}
              onClose={() => setDetailModalOpen(false)}
              open={detailModalOpen}
            />
          )}
          {updateModalOpen && (
            <StorageYardReciptUpdateModal
              id={selectedStorageYardRecipt.id}
              receiptCode={selectedStorageYardRecipt.receiptCode}
              onClose={handleUpdateModalClose}
              open={updateModalOpen}
              sources={sources}
              suppliers={suppliers}
            />
          )}
        </>
      }
    />
  );
}
