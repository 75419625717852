import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import MonthlyReport from './pages/MonthlyReport';
import ProductionHistory from './pages/ProductionHistory';
import SieveReport from './pages/SieveReport';

const routesConfig: RouteConfig[] = [
  {
    path: '/sieve-report/*',
    element: <SieveReport />,
    permission: 'WD042',
  },
  {
    path: '/production-history/*',
    element: <ProductionHistory />,
    permission: 'WD053',
  },
  {
    path: '/monthly-report/*',
    element: <MonthlyReport />,
  },
];

const Report = () => PermissionRoute(routesConfig);

export default memo(Report);
