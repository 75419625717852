import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { AreaData, AreaOptions } from '@Src/_basic/object/AreaType';
import { FactoryData, FactoryItem } from '@Src/_basic/object/FactoryType';
import { PostStationData, StationOption, StationsData, mappingCodeBranch } from '@Src/_basic/object/SiteType';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';
import { FactoryApi } from '@Src/_basic/protocol/factoryManagement/FactoryApi';
import { StationApi } from '@Src/_basic/protocol/site/StationApi';
import { Card, CardContent, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CreateFeedInletForm from './components/CreateFeedInletForm';
import CreateGuardhouseForm from './components/CreateGuardhouseForm';
import CreateQualityControlForm from './components/CreateQualityControlForm';
import CreateWbStationForm from './components/CreateWbStationForm';
import SiteManagementCreateSubmit from './components/SiteManagementCreateSubmit';
import SiteManagementErrorMessage from './components/SiteManagementErrorMessage';

export default function SiteManagementCreateForm() {
  const { t: i18T } = useTranslation();

  const history = useNavigate();

  const handleGoBack = () => {
    history(-1);
  };
  const [loading, setLoading] = useState(false);
  const [areas, setAreas] = useState<AreaOptions[]>([]);
  const [selectedArea, setSelectedArea] = useState<number>(0);
  const [factories, setFactories] = useState<Options[]>([]);
  const [factoryRemoved, setFactoryRemoved] = useState<boolean>(false);
  const [selectedFactory, setSelectedFactory] = useState<number>(0);
  const [factoryBranchCode, setFactoryBranchCode] = useState<string>('');
  const [stationsOption, setStationsOption] = useState<Options[]>([]);

  const [createWbStation, setCreateWbStation] = useState<StationsData[]>([]);
  const [createGuardhouse, setCreateGuardhouse] = useState<StationsData[]>([]);

  const [createFeedInlet, setCreateFeedInlet] = useState<StationsData[]>([]);

  const [createQualityControl, setCreateQualityControl] = useState<StationsData[]>([]);

  const [warningMoalOpen, setWarningMoalOpen] = useState<boolean>(false);

  const [existFactory, setExistFactory] = useState<Array<number>>([]);

  const handleWarningModalClose = () => {
    setWarningMoalOpen(false);
  };

  const getAllAreasData = async () => {
    AreaApi.getAllAreas((allAreas: Array<AreaData>) => {
      const areaOptions: AreaOptions[] = allAreas.map((allArea: AreaData) => ({
        value: allArea.id,
        text: allArea.name,
        factories: allArea.factoryList.map((factory: FactoryItem) => ({
          value: factory.factoryId,
          text: factory.factoryName,
        })),
      }));
      setAreas(areaOptions);
    });
  };
  const getFactoryBranchCode = () => {
    setLoading(true);
    FactoryApi.GetFactoryById(
      selectedFactory,
      (data: mappingCodeBranch) => {
        setFactoryBranchCode(data.factoryCode + data.branchCode);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  };
  useEffect(() => {
    getFactoryBranchCode();
  }, [selectedFactory]);
  const getExistFactory = () => {
    setLoading(true);
    StationApi.getStationFactory(
      (data: Array<number>) => {
        setExistFactory(data);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  };
  const handleAreaSelectChange = (area: number) => {
    handleReset();
    const selected = areas.find((i: AreaOptions) => i.value === area);
    if (selected) {
      const filterFactory = selected?.factories.filter((obj: Options) => !existFactory.includes(obj.value));

      setFactories(filterFactory);
      setSelectedArea(area);
    }
  };

  const handleFactorySelectChange = (factory: number) => {
    setSelectedFactory(factory);

    FactoryApi.GetFactoryById(
      factory,
      (data: FactoryData) => {
        setFactoryRemoved(data.removed);
      },
      undefined,
      undefined,
    );
  };

  const getAllStaionsData = () => {
    StationApi.GetStaionTypeOption((allStaions: StationOption[]) => {
      const stationOptions = allStaions.map((allStaion: StationOption) => ({
        value: allStaion.id,
        text: allStaion.typeName,
        code: allStaion.typeCode,
      }));
      setStationsOption(stationOptions);
    });
  };
  useEffect(() => {
    getAllStaionsData();
  }, []);

  const handleReset = () => {
    setLoading(false);
    setSelectedFactory(0);
    setCreateWbStation([]);
    setCreateGuardhouse([]);
    setCreateFeedInlet([]);
    setCreateQualityControl([]);
  };
  const handleResetFactory = () => {
    setCreateWbStation([]);
    setCreateGuardhouse([]);
    setCreateFeedInlet([]);
    setCreateQualityControl([]);
  };

  const handleWbCreatePage = () => {
    const objectData = {
      id: Math.random(),
      stationTypeId: 1,
      stationCode: 0,
      stationName: '',
      account: '',
      accountName: '',
      password: '',
    };
    const newDatas = Object.assign([], createWbStation);
    newDatas.push(objectData);
    setCreateWbStation(newDatas);
  };

  const handleGuardhouseCreatePage = () => {
    const objectData = {
      id: Math.random(),
      stationTypeId: 2,
      stationCode: 0,
      stationName: '',
      account: '',
      accountName: '',
      password: '',
    };
    const newDatas = Object.assign([], createGuardhouse);

    newDatas.push(objectData);

    setCreateGuardhouse(newDatas);
  };

  const handleFeedInletCreatePage = () => {
    const objectData = {
      id: Math.random(),
      stationTypeId: 3,
      stationCode: 0,
      stationName: '',
      account: '',
      accountName: '',
      password: '',
    };
    const newDatas = Object.assign([], createFeedInlet);

    newDatas.push(objectData);

    setCreateFeedInlet(newDatas);
  };

  const handleQualityControlCreatePage = () => {
    const objectData = {
      id: Math.random(),
      stationTypeId: 4,
      stationCode: 0,
      stationName: '',
      account: '',
      accountName: '',
      password: '',
    };
    const newDatas = Object.assign([], createQualityControl);

    newDatas.push(objectData);

    setCreateQualityControl(newDatas);
  };

  const resetWbIndex = () => {
    for (let i = 0; i < createWbStation.length; i++) {
      createWbStation[i].stationCode = i + 1;
      createWbStation[i].account = `${factoryBranchCode}_W_${i + 1}`;
      createWbStation[i].accountName = `${factoryBranchCode}_W_${i + 1}`;
    }
  };

  const resetGuardhouseIndex = () => {
    for (let i = 0; i < createGuardhouse.length; i++) {
      createGuardhouse[i].stationCode = i + 1;
      createGuardhouse[i].account = `${factoryBranchCode}_G_${i + 1}`;
      createGuardhouse[i].accountName = `${factoryBranchCode}_G_${i + 1}`;
    }
  };

  const resetFeedInletIndex = () => {
    for (let i = 0; i < createFeedInlet.length; i++) {
      createFeedInlet[i].stationCode = i + 1;
      createFeedInlet[i].account = `${factoryBranchCode}_I_${i + 1}`;
      createFeedInlet[i].accountName = `${factoryBranchCode}_I_${i + 1}`;
    }
  };

  const resetQualityControlIndex = () => {
    for (let i = 0; i < createQualityControl.length; i++) {
      createQualityControl[i].stationCode = i + 1;
      createQualityControl[i].account = `${factoryBranchCode}_Q_${i + 1}`;
      createQualityControl[i].accountName = `${factoryBranchCode}_Q_${i + 1}`;
    }
  };
  const [errorCode, setErrorCode] = useState<string>('');
  const getErrorCode = (error: any) => {
    if (error.code) {
      setErrorCode(error.code);
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    const concatData = createWbStation.concat(createGuardhouse, createFeedInlet, createQualityControl);
    const stationData: PostStationData = { factoryId: selectedFactory, stations: concatData };
    StationApi.PostStation(
      stationData,
      (data: StationsData[]) => {
        history(-1);
      },
      (error) => {
        getErrorCode(error);
        setWarningMoalOpen(true);
      },
      () => {
        setLoading(false);
      },
    );
  };
  useEffect(() => {
    getAllAreasData();
    getExistFactory();
  }, []);
  useEffect(() => {
    handleResetFactory();
  }, [selectedFactory]);
  useEffect(() => {
    handleReset();
  }, [selectedArea]);

  useEffect(() => {
    resetWbIndex();
  }, [createWbStation]);

  useEffect(() => {
    resetGuardhouseIndex();
  }, [createGuardhouse]);

  useEffect(() => {
    resetFeedInletIndex();
  }, [createFeedInlet]);

  useEffect(() => {
    resetQualityControlIndex();
  }, [createQualityControl]);

  return (
    <>
      <SiteManagementErrorMessage
        onWarningModalClose={handleWarningModalClose}
        warningMoalOpen={warningMoalOpen}
        errorCode={errorCode}
      />
      <Stack direction="row" sx={{ mt: 3 }}>
        <Stack direction="row">
          <YatungFormLabel label={i18T('SITEMANAGEMENT.AREA')} />
        </Stack>
        <Stack direction="row">
          <YatungSelect
            disabled={loading}
            options={areas}
            value={selectedArea}
            onChange={(e) => handleAreaSelectChange(e.target.value as number)}
            sx={{ width: '930px' }}
          />
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ my: 3 }}>
        <Stack direction="row">
          <YatungFormLabel label={i18T('SITEMANAGEMENT.FACTORY')} />
        </Stack>
        <Stack direction="row">
          <YatungSelect
            disabled={selectedArea === 0 || loading}
            options={factories}
            value={selectedFactory}
            onChange={(e: SelectChangeEvent<unknown>) => handleFactorySelectChange(e.target.value as number)}
            sx={{ width: '930px' }}
          />
        </Stack>
      </Stack>
      <Stack direction="row" sx={{ my: 3 }} alignItems={'center'}>
        <Stack direction="row">
          <YatungFormLabel label={`${i18T('GLOBAL.STATE')}：`} />
        </Stack>
        {selectedFactory !== 0 && (
          <Stack direction="row">
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: factoryRemoved ? '#CB333B' : '#3E9B2F' }}>
              {factoryRemoved ? i18T('GLOBAL.CLOSING') : i18T('GLOBAL.RUNNING')}
            </Typography>
          </Stack>
        )}
      </Stack>
      <YatungDivider />
      <Stack flexDirection="row" sx={{ mt: 3 }}>
        <YatungButton
          disabled={selectedFactory === 0}
          text={i18T('SITEMANAGEMENT.CREATE_WBSTATION')}
          onClick={handleWbCreatePage}
          color="green"
          sx={{ mr: 2 }}
        />
        <YatungButton
          disabled={selectedFactory === 0}
          text={i18T('SITEMANAGEMENT.CREATE_GUARDHOUSE')}
          onClick={handleGuardhouseCreatePage}
          color="green"
          sx={{ mr: 2 }}
        />
        <YatungButton
          disabled={selectedFactory === 0}
          text={i18T('SITEMANAGEMENT.CREATE_FEEDINLET')}
          onClick={handleFeedInletCreatePage}
          color="green"
          sx={{ mr: 2 }}
        />
        <YatungButton
          disabled={selectedFactory === 0}
          text={i18T('SITEMANAGEMENT.CREATE_QUALITYCONTROL')}
          onClick={handleQualityControlCreatePage}
          color="green"
          sx={{ mr: 2 }}
        />
      </Stack>
      <Stack>
        {createWbStation.length > 0 && (
          <Card
            sx={{
              boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
              background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
              mt: 2,
            }}
          >
            <CardContent>
              {createWbStation.map((value: StationsData, index: number) => {
                return (
                  <CreateWbStationForm
                    key={value.id}
                    value={value}
                    factoryBranchCode={factoryBranchCode}
                    setCreateWbStation={setCreateWbStation}
                    createWbStation={createWbStation}
                    index={index}
                    stationsOption={stationsOption}
                  />
                );
              })}
            </CardContent>
          </Card>
        )}

        {createGuardhouse.length > 0 && (
          <Card
            sx={{
              boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
              background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
              mt: 2,
            }}
          >
            <CardContent>
              {createGuardhouse.map((value: StationsData, index: number) => {
                return (
                  <CreateGuardhouseForm
                    key={value.id}
                    value={value}
                    factoryBranchCode={factoryBranchCode}
                    setCreateGuardhouse={setCreateGuardhouse}
                    createGuardhouse={createGuardhouse}
                    stationsOption={stationsOption}
                    index={index}
                  />
                );
              })}
            </CardContent>
          </Card>
        )}
        {createFeedInlet.length > 0 && (
          <Card
            sx={{
              boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
              background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
              mt: 2,
            }}
          >
            <CardContent>
              {createFeedInlet.map((value: StationsData, index: number) => {
                return (
                  <CreateFeedInletForm
                    key={value.id}
                    value={value}
                    factoryBranchCode={factoryBranchCode}
                    setCreateFeedInlet={setCreateFeedInlet}
                    createFeedInlet={createFeedInlet}
                    stationsOption={stationsOption}
                    index={index}
                  />
                );
              })}
            </CardContent>
          </Card>
        )}
        {createQualityControl.length > 0 && (
          <Card
            sx={{
              boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
              background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
              mt: 2,
            }}
          >
            <CardContent>
              {createQualityControl.map((value: StationsData, index: number) => {
                return (
                  <CreateQualityControlForm
                    key={value.id}
                    value={value}
                    factoryBranchCode={factoryBranchCode}
                    setCreateQualityControl={setCreateQualityControl}
                    createQualityControl={createQualityControl}
                    stationsOption={stationsOption}
                    index={index}
                  />
                );
              })}
            </CardContent>
          </Card>
        )}
      </Stack>
      <Stack justifyContent="flex-end" flexDirection="row" sx={{ pr: 4, mt: 5 }}>
        <SiteManagementCreateSubmit
          onSubmit={handleSubmit}
          onGoBack={handleGoBack}
          createWbStation={createWbStation}
          createGuardhouse={createGuardhouse}
          createFeedInlet={createFeedInlet}
          createQualityControl={createQualityControl}
        />
      </Stack>
    </>
  );
}
