import React from 'react';
import { Grid, SxProps, Typography } from '@mui/material';

interface Props {
  label: string;
  icon?: JSX.Element;
  sx?: SxProps;
}
export default function YatungFormLabel(props: Props) {
  const { label, icon, sx } = props;
  return (
    <Grid container justifyContent={'flex-start'} alignContent={'center'} display={'flex'} item xs={12}>
      {icon}
      <Typography sx={sx || { fontSize: 20, fontWeight: 900, mt: 1, ml: 1 }}>{label}</Typography>
    </Grid>
  );
}
