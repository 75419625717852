import { EnhancedStore } from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios';
import qs from 'qs';

export default function setupAxios(axios: AxiosStatic, store: EnhancedStore) {
  const CancelToken = axios.CancelToken;
  const requestPool: any = {};

  axios.interceptors.request.use(
    (config: AxiosRequestConfig<any>) => {
      if (config.headers && config.headers.Cancelable) {
        const requestKey = `${config.method}/${config.url}`;
        if (requestPool && requestPool[requestKey]) {
          const cancel = requestPool[requestKey];
          delete requestPool[requestKey];
          cancel(JSON.stringify({ status: 'cancel', message: 'Cancel repeated request:' + requestKey }));
        }
        config.cancelToken = new CancelToken(function executor(c) {
          requestPool[requestKey] = c;
        });
      }

      const {
        auth: { token },
      } = store.getState();

      if (config.headers && token) config.headers.Authorization = `Bearer ${token}`;

      config.paramsSerializer = (params: any) => qs.stringify(params, { arrayFormat: 'repeat' });

      return config;
    },
    (err: AxiosError) => Promise.reject(err),
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      const { message, code, data, responseData } = response.data;

      if (code === '0000') {
        return responseData;
      } else if (code === '00000') {
        return data;
      } else {
        throw new AxiosError(undefined, undefined, undefined, undefined, response);
      }
    },
    (error) => {
      if (axios.isCancel(error)) {
        const errMsg = JSON.parse(error.message);
        return console.warn(errMsg.message);
      } else {
        return Promise.reject(error);
      }
    },
  );
}
