import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Close(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.71484 0C9.38344 0 9.11484 0.266462 9.11484 0.595238V10.5952C9.11484 10.924 9.38344 11.1905 9.71484 11.1905C10.0463 11.1905 10.3148 10.924 10.3148 10.5952V0.595238C10.3148 0.266462 10.0463 0 9.71484 0Z"
        fill="white"
      />
      <path
        d="M4.10848 4.08668C4.49613 3.77976 5.03484 4.07413 5.03484 4.56566C5.03484 4.76377 4.9398 4.94931 4.78512 5.07441C3.03328 6.49368 1.91484 8.65281 1.91484 11.0714C1.91484 15.3451 5.40703 18.8095 9.71484 18.8095C14.0227 18.8095 17.5148 15.3451 17.5148 11.0714C17.5148 8.75651 16.4903 6.67922 14.8666 5.26135C14.7224 5.13532 14.6348 4.95582 14.6348 4.76516C14.6348 4.26153 15.1931 3.96903 15.5781 4.29734C17.4986 5.93471 18.7148 8.36217 18.7148 11.0714C18.7148 16.0026 14.6854 20 9.71484 20C4.74434 20 0.714844 16.0026 0.714844 11.0714C0.714844 8.24312 2.04023 5.72219 4.10848 4.08668Z"
        fill="white"
      />
    </SvgIcon>
  );
}
