import { Response } from '@Src/_basic/object/ApiType';
import {
  GetSampleByIdData,
  PageableSampleCourseData,
  PageSampleCourseRequire,
  SearchParams,
} from '@Src/_basic/object/SampleCourseType';
import { Api } from '../Api';
import { SampleCourseProtocol } from './SampleCourseProtocol';

export class SampleCourseApi {
  public static getSampleCourse(
    //搜尋樣品歷程，分頁
    params: PageSampleCourseRequire,
    onSuccess?: (data: PageableSampleCourseData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(SampleCourseProtocol.GET_SAMPLE_COURSE, config, onSuccess, onFailed, onFinally);
  }

  public static getSampleCourseById(
    //id搜尋樣品歷程
    id?: string,
    onSuccess?: (data: GetSampleByIdData[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {};
    Api.get(SampleCourseProtocol.GET_SAMPLE_COURSE + `/${id}`, config, onSuccess, onFailed, onFinally);
  }
}
