import NamesExpand from '@Src/_basic/components/NamesExpand';
import { MixerData, MixerDetailData } from '@Src/_basic/object/MixerTypes';
import React from 'react';

interface Props {
  row: MixerData;
}
export default function MixersColumnFormatter(props: Props) {
  const { row } = props;
  return <NamesExpand names={row.mixers.map((mixer: MixerDetailData) => mixer.mixerName)} />;
}
