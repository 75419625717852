// import { promisify } from '@Src/_basic/helpers/PromiseHelpers';
import { BomHierarchyData } from '@Src/_basic/object/MaterialFactoryCodeType';
import {
  AMSAbnormalLegalData,
  CarbonData,
  ConcreteExptReportProportionData,
} from '@Src/_basic/object/PrintProductionType';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { printProductionSlice } from './printProductionSlice';

export function usePrintProduction() {
  const { proportionPosition, amsAbnormalLegalData, concreteExptReportData, bomData, carbonData } = useSelector(
    (state: RootState) => state.printProduction,
  );
  const dispatch = useDispatch();

  const setProportionPosition = (data: Record<string, string> | undefined) => {
    dispatch(printProductionSlice.actions.setProportionPosition(data));
  };

  const setAmsAbnormalLegalData = (data: AMSAbnormalLegalData | undefined) => {
    dispatch(printProductionSlice.actions.setAmsAbnormalLegalData(data));
  };

  const setConcreteExptReportData = (data: Record<string, Array<ConcreteExptReportProportionData>> | undefined) => {
    dispatch(printProductionSlice.actions.setConcreteExptReportData(data));
  };

  const setBomData = (data: Array<BomHierarchyData> | undefined) => {
    if (!data) {
      dispatch(printProductionSlice.actions.setBomData(undefined));
    } else {
      const transformData = data.flatMap((item) => {
        return item.specificationList.map((specification) => {
          return {
            type:
              item.typeId === 2
                ? '粗粒料'
                : item.typeId === 1
                ? '細粒料'
                : item.typeId === 6
                ? '化學摻劑'
                : item.typeName,
            specification: specification.specificationName,
            bomName: Object.values(specification.bomlist).map((bom) => bom)[0],
          };
        });
      });
      dispatch(printProductionSlice.actions.setBomData(transformData));
    }
  };

  const setCarbonData = (data: Array<CarbonData> | undefined) => {
    dispatch(printProductionSlice.actions.setCarbonData(data));
  };

  return {
    proportionPosition,
    setProportionPosition,
    amsAbnormalLegalData,
    setAmsAbnormalLegalData,
    concreteExptReportData,
    setConcreteExptReportData,
    bomData,
    setBomData,
    carbonData,
    setCarbonData,
  };
}
