import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import CreateLegalStandardScreen from './screens/CreateLegalStandardScreen';
import LegalStandardsScreen from './screens/LegalStandardsScreen';
import SelectTypeScreen from './screens/SelectTypeScreen';
import ViewLegalStandardScreen from './screens/ViewLegalStandardScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/type/*',
    element: <SelectTypeScreen />,
  },
  {
    path: '/list/*',
    element: <LegalStandardsScreen />,
  },
  {
    path: '/view/*',
    element: <ViewLegalStandardScreen />,
  },
  {
    path: '/create/*',
    element: <CreateLegalStandardScreen />,
  },
];

const LegalStandards = () => PermissionRoute(routesConfig);

export default memo(LegalStandards);
