import YatungPaperWithRibbon from '@Src/_basic/components/YatungPaperWithRibbon';
import { RibbonVariantEnum } from '@Src/_basic/object/ProductionHistoryType';
import { SignatureItemData, SignatureItemDetailData } from '@Src/_basic/object/SignatureType';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Grid, Stack } from '@mui/material';
import React from 'react';
import TextLine from '../../../../TextLine';

export default function ViewSignature() {
  const { projectInfo } = useProductionHistory();

  return (
    <Stack>
      <YatungPaperWithRibbon variant={RibbonVariantEnum.CEMENTITIOUS} first>
        <Grid container direction="row">
          {projectInfo.signature?.cementingMaterial?.map((item: SignatureItemData, index: number) => (
            <Grid key={index} item xs={4}>
              <Stack spacing={3}>
                <TextLine title={`${item.typeName}：`} />
                {item?.details?.map((itemDetail: SignatureItemDetailData, detailIndex: number) => (
                  <TextLine
                    key={detailIndex}
                    title={itemDetail.specificationName}
                    subTitle={itemDetail.BOMSupplierSourceAttributeName}
                    content={itemDetail.detailValue}
                  />
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </YatungPaperWithRibbon>

      <YatungPaperWithRibbon variant={RibbonVariantEnum.CHEMICAL} divider>
        <Grid container direction="row">
          {projectInfo.signature?.chemicalAdmixture?.map((item: SignatureItemData, index: number) => (
            <Grid key={index} item xs={4}>
              <Stack spacing={3}>
                <TextLine title={`${item.typeName}：`} />
                {item?.details?.map((itemDetail: SignatureItemDetailData, detailIndex: number) => (
                  <TextLine
                    key={detailIndex}
                    title={itemDetail.specificationName}
                    subTitle={itemDetail.BOMSupplierSourceAttributeName}
                    content={itemDetail.detailValue}
                  />
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </YatungPaperWithRibbon>

      <YatungPaperWithRibbon variant={RibbonVariantEnum.SAND_AND_ROCK} divider>
        <Grid container direction="row">
          {projectInfo.signature?.sandStone?.map((item: SignatureItemData, index: number) => (
            <Grid key={index} item xs={4}>
              <Stack spacing={3}>
                <TextLine title={`${item.typeName}：`} />
                {item?.details?.map((itemDetail: SignatureItemDetailData, detailIndex: number) => (
                  <TextLine
                    key={detailIndex}
                    title={itemDetail.specificationName}
                    subTitle={itemDetail.BOMSupplierSourceAttributeName}
                    content={itemDetail.detailValue}
                  />
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </YatungPaperWithRibbon>
    </Stack>
  );
}
