import { Box, Checkbox, Stack, Typography, styled } from '@mui/material';
import React, { memo, useCallback, useState } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import { YatungComboBox } from '@Src/_basic/components/YatungComboBox';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import Cancel from '@Src/_basic/icons/CancelV2';
import Confirm from '@Src/_basic/icons/Confirm';
import { useSignature } from '@Src/redux/signature/signatureActions';
import { useTranslation } from 'react-i18next';

const Content = styled(Typography)(() => ({
  color: '#7C878E',
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '19px',
  letterSpacing: '0.255em',
}));

const Title = styled(Typography)(() => ({
  color: '#002F8C',
  fontSize: '16px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '19px',
  letterSpacing: '0.255em',
}));

interface Props {
  cancelHandler: () => void;
  confirmHandler: (isTemplate: boolean) => void;
  children: JSX.Element;
}

const SubmitButtons = ({ cancelHandler, confirmHandler, children }: Props) => {
  const { t: i18T } = useTranslation();
  const { signatures, getSignatureById, clearSignature } = useSignature();

  const [isTemplate, setIsTemplate] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);

  const handleReUsedTemplateChange = useCallback(
    (signatureId: number) => {
      if (signatureId) getSignatureById(signatureId);
      else clearSignature();
    },
    [getSignatureById, clearSignature],
  );

  const handleCancel = useCallback(() => {
    cancelHandler();
    setIsTemplate(false);
  }, [cancelHandler]);

  const handleComfirm = useCallback(() => {
    if (isTemplate) setIsShowAlert(true);
    else confirmHandler(false);
  }, [isTemplate, confirmHandler]);

  const handleOnOk = useCallback(() => {
    confirmHandler(true);
    setIsShowAlert(false);
  }, [confirmHandler]);

  return (
    <Stack>
      <Stack sx={{ p: 3 }} gap={1} flexDirection="row" alignItems="center" justifyContent="flex-end">
        <Title>選擇其他簽名檔：</Title>
        <YatungComboBox
          width={300}
          options={[
            { value: 0, text: '原簽名檔' },
            ...signatures.map((item) => ({ value: item.id, text: item.title })),
          ]}
          onChange={handleReUsedTemplateChange}
        />
      </Stack>
      {children}
      <Stack flexDirection="row" justifyContent="flex-end" pb={2} pr={2}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Checkbox checked={isTemplate} onChange={() => setIsTemplate((prev) => !prev)} />
          <Content style={{ paddingRight: '10px' }}>是否儲存為模板</Content>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '8px',
            }}
          ></Box>
        </Box>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <YatungButton
            text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.CANCEL`)}
            color="red"
            startIcon={<Cancel />}
            onClick={handleCancel}
          />
          <YatungButton
            text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.SUBMIT`)}
            color="green"
            startIcon={<Confirm />}
            onClick={handleComfirm}
          />
        </Box>
      </Stack>
      <YatungConfirmAlert
        isShowAlert={isShowAlert}
        onClose={() => setIsShowAlert(false)}
        alertText={`此設定將會變動模板\n請再次確認是否要變更?`}
        onOk={handleOnOk}
      />
    </Stack>
  );
};

export default memo(SubmitButtons);
