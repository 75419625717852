import YatungTable from '@Src/_basic/components/YatungTable';
import { GetClearExecution } from '@Src/_basic/object/ClearExecutionType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ClearTimeColumnFormatter from './columnFormatter/ClearTimeColumnFormatter';
import IdColumnFormatter from './columnFormatter/IdColumnFormatter';
import MemoColumnFormatter from './columnFormatter/MemoColumnFormatter';

interface Props {
  logRecord: GetClearExecution[];
  loading: boolean;
}
export default function ClearExecutionLogTable(props: Props) {
  const { logRecord, loading } = props;
  const { t: i18T } = useTranslation();
  return (
    <YatungTable
      loading={loading}
      columns={[
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.NO.'),
          dataField: 'id',
          formatter: IdColumnFormatter,
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.CLEAR_TIME'),
          dataField: 'createTime',
          formatter: ClearTimeColumnFormatter,
        },
        {
          text: i18T('GLOBAL.REMARK'),
          dataField: 'memo',
          formatter: MemoColumnFormatter,
        },
      ]}
      data={logRecord}
    />
  );
}
