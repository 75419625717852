import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, SxProps, Typography } from '@mui/material';
import React, { memo } from 'react';

interface ControlDataType {
  label: string;
  value: any;
}

interface Props {
  row?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value: any;
  groupName: string;
  controlData: Array<ControlDataType>;
  labelTextStyle?: SxProps;
  sx?: SxProps;
}
const YatungRadioButton = ({
  row = true,
  label,
  onChange,
  value,
  groupName,
  controlData,
  labelTextStyle,
  sx,
}: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" spacing={2}>
      {label && <Typography sx={{ fontSize: 20 }}>{`${label}:`}</Typography>}
      <FormControl>
        <RadioGroup row={row} value={value?.toString()} name={groupName} onChange={onChange}>
          {controlData.map((item: ControlDataType, index: number) => {
            return (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio sx={sx} />}
                label={<Typography sx={labelTextStyle}>{item.label}</Typography>}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default memo(YatungRadioButton);
