import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import YatungTable from '@Src/_basic/components/YatungTable';

import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import EditIcon from '@Src/_basic/icons/Edit';

import { customSort } from '@Src/_basic/helpers/SortHelper';
import { AttributeData } from '@Src/_basic/object/AttributeType';
import { PostMaterialNumberRequest, PutMaterialNumberRequest } from '@Src/_basic/object/MaterialNumberManageType';
import { TypeData } from '@Src/_basic/object/TypeType';
import { AttributeApi } from '@Src/_basic/protocol/attribute/AttributeApi';
import { useTranslation } from 'react-i18next';
import AddModal from './AddModal';
import EditModal, { SelectedItemType } from './EditModal';

const attributeIcon = '/media/images/icon_attribute.png';

interface Props {
  filterType: number;
  types: TypeData[];
}

const ModalIcon = () => (
  <YatungImg
    img={attributeIcon}
    sx={{
      width: 48,
      height: 48,
      mr: 1,
    }}
  />
);

export default function AttributesPanel({ filterType, types }: Props) {
  const { t: i18T } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<Array<AttributeData>>([]);
  const [filteredAttributes, setFilteredAttributes] = useState<Array<SelectedItemType>>([]);

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [selectedAttribute, setSelectedAttribute] = useState<SelectedItemType>();

  const fetchAllAttributes = async () => {
    AttributeApi.getAllAttributes(onFetchAllAttributesSuccess, undefined, () => {
      setLoading(false);
    });
  };

  const onFetchAllAttributesSuccess = (data: Array<AttributeData>) => {
    setAttributes(data.sort((a, b) => customSort(a.code, b.code)));
  };

  const filterAttributes = (_attributes: Array<SelectedItemType>) => {
    _attributes &&
      setFilteredAttributes(
        _attributes.filter((attr: SelectedItemType) => filterType === -1 || attr.typeId === filterType),
      );
  };

  const handleAttributeSelect = (data: SelectedItemType) => {
    setSelectedAttribute(data);
    setIsEditModalOpen(true);
  };

  const editAttributeHandler = (data: PutMaterialNumberRequest) => {
    if (data) {
      setLoading(true);
      AttributeApi.putAttribute(data, onEditAttributeSuccess, undefined, () => {
        closeModal();
      });
    }
  };

  const onEditAttributeSuccess = (data: AttributeData) => {
    fetchAllAttributes();
  };

  const addAttributeHandler = async (data: PostMaterialNumberRequest) => {
    if (data) {
      setLoading(true);
      AttributeApi.postAttribute(data, onAddAttributeSuccess, undefined, () => {
        closeModal();
      });
    }
  };

  const onAddAttributeSuccess = (data: AttributeData) => {
    fetchAllAttributes();
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    filterAttributes(attributes);
  }, [filterType, attributes]);

  useEffect(() => {
    setLoading(true);
    fetchAllAttributes();
  }, []);

  return (
    <Box sx={{ border: '1px solid #6C708C', p: 1, pt: 2 }}>
      <Stack justifyContent={'flex-end'} flexDirection="row">
        <YatungButton
          text={i18T('GLOBAL.CREATE')}
          startIcon={<AddCircleFill />}
          onClick={() => setIsAddModalOpen(true)}
          color="green"
        />
      </Stack>
      <YatungTable
        loading={loading}
        data={filteredAttributes}
        sx={{
          maxHeight: 'calc(100vh - 340px)',
        }}
        columns={[
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.TYPE'),
            dataField: 'typeName',
            width: '120px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.CODE'),
            dataField: 'code',
            width: '120px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.NAME'),
            dataField: 'name',
          },
          {
            text: i18T('GLOBAL.ACTION'),
            dataField: 'action',
            width: '180px',
            formatter: ({ row }) => {
              return (
                <YatungButton
                  text={i18T('GLOBAL.UPDATE')}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    handleAttributeSelect(row);
                  }}
                  color="yellow"
                />
              );
            },
          },
        ]}
      />
      <AddModal
        open={isAddModalOpen}
        handleSubmit={addAttributeHandler}
        handleClose={closeModal}
        allTypes={types}
        allItems={attributes ? attributes.map(({ code, name, typeId }: any) => ({ code, name, typeId })) : []}
        icon={<ModalIcon />}
        title={i18T('APPLICATIONSMANAGEMENT.TABLE.ATTRIBUTE')}
      />
      {selectedAttribute && (
        <EditModal
          open={isEditModalOpen}
          handleSubmit={editAttributeHandler}
          handleClose={closeModal}
          allTypes={types}
          allItems={attributes.map(({ code, name, typeId }: any) => ({ code, name, typeId }))}
          icon={<ModalIcon />}
          title={i18T('APPLICATIONSMANAGEMENT.TABLE.ATTRIBUTE')}
          selectedItem={selectedAttribute}
        />
      )}
    </Box>
  );
}
