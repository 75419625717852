import YatungButton from '@Src/_basic/components/YatungButton';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import AddCircle from '@Src/_basic/icons/AddCircle';
import DeleteIcon from '@Src/_basic/icons/Delete';
import EditIcon from '@Src/_basic/icons/Edit';
import { TransferOrderGroupsData } from '@Src/_basic/object/AccountRightType';
import { TransferOrderGroupApi } from '@Src/_basic/protocol/accountRights/transferOrderGroup/TransferOrderGroupApi';
import { useApi } from '@Src/redux/api/apiAction';
import { Cancel } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface Props {
  groupData: Array<TransferOrderGroupsData>;
  getTransferGroup: () => void;
}

export default function TransferOrderGroupManagementTable({ groupData, getTransferGroup }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const navigate = useNavigate();

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [deleteId, setDeleteId] = useState<number>(0);
  const [newGroupData, setNewGroupData] = useState<Array<TransferOrderGroupsData>>([]);

  const handleGoToUpdatePage = async (row: TransferOrderGroupsData) => {
    navigate(`/UsersManagePage/TransferOrderGroupManagement/TransferOrderGroupManagementUpdate/${row.id}`, {
      state: { row },
    });
  };

  const deleteTransformGroup = (id: number) => {
    TransferOrderGroupApi.deleteTransferOrderGroup(
      id,
      () => {
        setAlertText(i18T('GLOBAL.SUCCESS', { text: i18T('GLOBAL.DELETE') }));
        setIsShowAlert(true);
      },
      (error) => {
        setAlertText(error.message);
        setIsShowAlert(true);
      },
    );
  };

  useEffect(() => {
    setNewGroupData(groupData.map((item) => ({ ...item, allList: false })));
  }, [groupData]);

  const columns: Array<Column> = [
    {
      text: i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.FACTORY_INFO'),
      dataField: 'factoryName',
    },
    {
      text: i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.GROUP_NAME'),
      dataField: 'groupName',
    },
    {
      text: i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.CREATE_TIME'),
      dataField: 'createTime',
      formatter: ({ row }) => {
        return <Typography>{moment(row.createTime).format('YYYY/MM/DD')}</Typography>;
      },
    },
    {
      text: i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.MEMBER_LIST'),
      dataField: 'memberList',
      width: '700px',
      formatter: ({ row }) => {
        const list = row.memberList.split(';');
        return (
          <>
            {list.length <= 5 ? (
              list.splice(0, list.length - 1).map((item: string, index: number) => (
                <span key={index} style={{ letterSpacing: 2 }}>
                  {item};
                </span>
              ))
            ) : row.allList ? (
              <>
                {list.splice(0, list.length - 1).map((item: string, index: number) => (
                  <span key={index} style={{ letterSpacing: 2 }}>
                    {item};
                  </span>
                ))}
                <Cancel
                  onClick={() =>
                    setNewGroupData((prev: Array<TransferOrderGroupsData>) =>
                      prev.map((item: TransferOrderGroupsData) =>
                        item.id === row.id ? { ...item, allList: false } : item,
                      ),
                    )
                  }
                  sx={{ ':hover': { cursor: 'pointer' }, paddingTop: 1 }}
                />
              </>
            ) : (
              <Stack display="flex" flexDirection="row" alignItems="center">
                {list.splice(0, 5).map((item: string, index: number) => (
                  <span key={index} style={{ letterSpacing: 2 }}>
                    {item};
                  </span>
                ))}
                <span style={{ fontSize: 26, paddingBottom: 5 }}>...</span>
                <AddCircle
                  onClick={() =>
                    setNewGroupData((prev: Array<TransferOrderGroupsData>) =>
                      prev.map((item: TransferOrderGroupsData) =>
                        item.id === row.id ? { ...item, allList: true } : item,
                      ),
                    )
                  }
                  sx={{ ':hover': { cursor: 'pointer' }, fontSize: 20 }}
                />
              </Stack>
            )}
          </>
        );
      },
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      width: '250px',
      formatter: ({ row }) => {
        return (
          <Stack display="flex" flexDirection="row" justifyContent="space-between">
            <YatungButton
              text={i18T('GLOBAL.UPDATE')}
              startIcon={<EditIcon />}
              color="yellow"
              onClick={() => {
                handleGoToUpdatePage(row);
              }}
            />
            <YatungButton
              text={i18T('GLOBAL.DELETE')}
              startIcon={<DeleteIcon />}
              color="light_red"
              onClick={() => {
                setIsShowAlert(true);
                setDeleteId(row.id);
              }}
            />
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <YatungTable loading={actionLoading} columns={columns} data={newGroupData} />
      {alertText ? (
        <YatungConfirmAlert
          isShowAlert={isShowAlert}
          alertText={alertText}
          onClose={() => {
            setIsShowAlert(false);
            setTimeout(() => setAlertText(''), 300);
            getTransferGroup();
          }}
        />
      ) : (
        <YatungConfirmAlert
          isShowAlert={isShowAlert}
          onClose={() => setIsShowAlert(false)}
          onOk={() => deleteTransformGroup(deleteId)}
        />
      )}
    </>
  );
}
