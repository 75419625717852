import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function MonitorlastExperimentOperator(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="46" height="46" viewBox="0 0 46 46" fill="none">
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39" fill="none">
        <path
          d="M27.5907 8.49193C27.5907 12.8904 23.9013 16.4839 19.3156 16.4839C14.7299 16.4839 11.0406 12.8904 11.0406 8.49193C11.0406 4.09345 14.7299 0.5 19.3156 0.5C23.9013 0.5 27.5907 4.09345 27.5907 8.49193ZM38.6406 37.7419C38.6406 37.9959 38.6353 38.2486 38.6247 38.5H0.656529C0.645955 38.2486 0.640625 37.9959 0.640625 37.7419C0.640625 27.6113 9.13167 19.371 19.6406 19.371C30.1496 19.371 38.6406 27.6113 38.6406 37.7419Z"
          stroke="#6C708C"
        />
      </svg>
    </SvgIcon>
  );
}
