import { AuthSysBaseResponse } from '@Src/_basic/object/ApiType';
import { Api } from '../../../Api';
import { ActionProtocol } from './ActionProtocol';
import { ActionCreateUpdateRequest, ActionType, RightsData } from '@Src/_basic/object/AccountRightType';

export class ActionApi {
  // 取得權限Action
  public static postGetRightsAction (
    id: string,
    onSuccess?: (data: ActionType) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = { id: id }
    Api.post(ActionProtocol.POST_GET_RIGHTS_ACTION_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增權限Action
  public static postCreateRightsAction (
    request: ActionCreateUpdateRequest,
    onSuccess?: (data: AuthSysBaseResponse<null>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ActionProtocol.POST_CREATE_RIGHTS_ACTION, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改權限Action
  public static postUpdateRightsAction (
    request: ActionCreateUpdateRequest,
    onSuccess?: (data: ActionType) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ActionProtocol.POST_UPDATE_RIGHTS_ACTION, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 刪除權限Action
  public static posDeleteRightsAction (
    ids: Array<string>,
    onSuccess?: (data: AuthSysBaseResponse<null>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const request = {
      ids: ids
    }
    Api.post(ActionProtocol.POST_DELETE_RIGHTS_ACTION, request, undefined, onSuccess, onFailed, onFinally);
  }
  
}
