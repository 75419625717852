import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import { MaintPropMixerCode, MixerData } from '@Src/_basic/object/MixerTypes';
import { MixerApi } from '@Src/_basic/protocol/mixerInformation/MixerApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import MixerInformationUpdateForm from './MixerInformationUpdateForm';

export default function MixerInformationUpdateCard() {
  const { t: i18T } = useTranslation();
  const params = useParams();
  const id = Number(params.id);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateMixerData, setUpdateMixerData] = useState<MixerData>({
    areaName: '',
    factoryId: 0,
    factoryName: '',
    waterAbsorption: 0.0,
    mixers: [],
    lastUpdateTime: 0,
    isYardMixerActived: false,
    yardMixer: {
      factoryId: 0,
      id: 0,
      mixerName: '',
      removed: false,
      maintPropMixerCode: {
        id: 0,
        name: '',
        code: '',
      },
      storages: [],
    },
  });

  const getMixerDataByFactoryId = () => {
    setLoading(true);
    MixerApi.getMixerDataByFactoryId(
      id,
      (data: MixerData) => {
        setUpdateMixerData(data);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  };
  useEffect(() => {
    getMixerDataByFactoryId();
  }, []);
  const [proportionOptions, setProportionOptions] = useState<Options[]>([]);

  useEffect(() => {
    MixerApi.getMixerOptionsByProportion(
      (data: MaintPropMixerCode[]) => {
        changeKeyName(data);
      },
      undefined,
      undefined,
    );
    const changeKeyName = (data: MaintPropMixerCode[]) => {
      const proportions = data.map((pro: MaintPropMixerCode) => ({
        value: pro.code,
        text: pro.code,
      }));
      setProportionOptions(proportions);
    };
  }, []);
  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.MIXER_INFORMATION.ROUTE_TITLE')}
      body={
        <>
          <MixerInformationUpdateForm
            key={updateMixerData.factoryId}
            updateMixerData={updateMixerData}
            setUpdateMixerData={setUpdateMixerData}
            proportionOptions={proportionOptions}
          />
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
