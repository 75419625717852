import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function HeaderTitle(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M20.4,4.4H2.7c-0.6,0-1.2,0.5-1.2,1.2l0,0c0,0.6,0.5,1.2,1.2,1.2h17.7c0.6,0,1.2-0.5,1.2-1.2l0,0C21.6,4.9,21,4.4,20.4,4.4z" />
      <path d="M11.2,10.5H2.7c-0.6,0-1.2,0.5-1.2,1.2l0,0c0,0.6,0.5,1.2,1.2,1.2h8.5c0.6,0,1.2-0.5,1.2-1.2l0,0C12.3,11,11.8,10.5,11.2,10.5z" />
      <path d="M20.4,16.7H2.7c-0.6,0-1.2,0.5-1.2,1.2l0,0c0,0.6,0.5,1.2,1.2,1.2h17.7c0.6,0,1.2-0.5,1.2-1.2l0,0C21.6,17.2,21,16.7,20.4,16.7z" />
    </SvgIcon>
  );
}
