import { Box, Button, IconButton, Modal, SelectChangeEvent, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiTableType } from '@Src/_basic/object/AccountRightType';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import TitleWithButton from '../../../_components/TitleWithButton';

import YatungCheckBox from '@Src/_basic/components/YatungCheckBox';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import AddCircleFillIcon from '@Src/_basic/icons/AddCircleFill';
import DeleteCircleIcon from '@Src/_basic/icons/DeleteCircle';
import SearchIcon from '@Src/_basic/icons/Search';
import SwitchIcon from '@Src/_basic/icons/Switch';
import TickIcon from '@Src/_basic/icons/Tick';
import { ActionApi } from '@Src/_basic/protocol/accountRights/fetAuth/action/ActionApi';
import { MenuApi } from '@Src/_basic/protocol/accountRights/fetAuth/menu/MenuApi';
import { useApi } from '@Src/redux/api/apiAction';

interface Props {
  open: boolean;
  apiList: Array<ApiTableType>;
  closeModel: () => void;
  getAllApiGroup: () => void;
}

export default function codeManagementCreateCard({ open, closeModel, apiList, getAllApiGroup }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const [code, setCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [searchGroup, setSearchGroup] = useState<string>('');
  const [state, setState] = useState<string>('0');
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const [apiListData, setApiListData] = useState<Array<ApiTableType>>([]);

  const [searchApiListByInput, setSearchApiListByInput] = useState<Array<ApiTableType>>();

  const [allChecked, setAllChecked] = useState<Array<number>>([]);

  const reset = () => {
    setApiListData([]);
    setCode('');
    setName('');
  };

  const cancelHandler = () => {
    closeModel();
    reset();
  };

  const finishHandler = () => {
    if (code && name) {
      MenuApi.postCreateRightsMenu(
        {
          code,
          name,
          state,
          path: '/api',
          kind: '1',
          parentId: '',
          description: '',
          position: 0,
        },
        (_data) => getNewMenuItemId(),
        (error) => {
          setIsShowAlert(true);
          setAlertText(error.message);
        },
      );
    } else return;
  };

  const getNewMenuItemId = () => {
    MenuApi.postGetRightsMenus(
      {
        pageNumber: 0,
        pageSize: 99999,
        keywords: [code],
      },
      (_data) => {
        const newMenuItem = _data.content.find((item) => item.code === code);
        const checkArr = apiListData.filter((item) => item.check);
        const filterAll = checkArr.filter((item) => item.code !== 'all');

        if (newMenuItem && checkArr.length > 0) createAction(newMenuItem.id, filterAll);
        else {
          getAllApiGroup();
          closeModel();
          reset();
        }
      },
    );
  };

  const createAction = (id: string, checkArr: Array<ApiTableType>) => {
    for (let i = 0; i < checkArr.length; i++) {
      const checkItem = checkArr[i];
      ActionApi.postCreateRightsAction(
        {
          menuId: id,
          id: checkItem.id,
          code: checkItem.code,
          name: checkItem.name,
          state: '1',
          apiIds: [checkItem.id],
          kind: '4',
        },
        undefined,
        (error) => {
          setIsShowAlert(true);
          setAlertText(error.message);
        },
      );
      if (!actionLoading) {
        getAllApiGroup();
        closeModel();
        reset();
      }
    }
  };

  useEffect(() => {
    if (open) {
      const allData = {
        category: '顯示畫面',
        code: 'all',
        createBy: 'SYS',
        createDt: '2023/08/25 08:00:00',
        description: '全選',
        id: 'all',
        moduleKind: 'DISP',
        name: '全選',
        securityKind: '0',
        state: '1',
        titleName: '全選',
        updateBy: 'SYS',
        updateDt: '2023/08/25 08:00:00',
      };
      setApiListData([allData, ...apiList]);
    } else reset();
  }, [open]);

  useEffect(() => {
    setSearchApiListByInput(apiListData);
    if (searchGroup) {
      setSearchApiListByInput(
        apiListData.filter((item) => item.code.includes(searchGroup) || item.name.includes(searchGroup)),
      );
    }
  }, [searchGroup, apiList, apiListData]);

  const columns: Array<Column> = [
    {
      text: i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.API_CODE'),
      dataField: 'code',
      width: '200px',
    },
    {
      text: i18T('USERSMANAGEMENU.EDIT_ACCOUNT.NAME_2'),
      dataField: 'name',
      width: '200px',
    },
    // {
    //   text: i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.ACTION'),
    //   dataField: 'state',
    //   formatter: ActionColumnFormatter,
    //   formatExtraData: {
    //     openText: i18T('GLOBAL.OPEN'),
    //     closeText: i18T('GLOBAL.CLOSE'),
    //     setStatus: setApiListData,
    //   },
    // },
    {
      text: undefined,
      dataField: 'id',
      width: '50px',
      formatter: ({ row }) => {
        return (
          <>
            <IconButton
              onClick={() => {
                setApiListData((prev) => prev.map((item) => (item.id === row.id ? { ...item, check: false } : item)));
              }}
              edge="end"
              sx={{
                height: 10,
                width: 10,
              }}
            >
              <DeleteCircleIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const handleChangeAll = (e: SelectChangeEvent) => {
    const valueTurnToNumber = Number(e.target.value);

    if (!allChecked.includes(valueTurnToNumber)) {
      setAllChecked([...allChecked, valueTurnToNumber]);
      setApiListData((prev) => prev.map((_data) => ({ ..._data, check: true })));
    } else {
      const index = allChecked.indexOf(valueTurnToNumber);
      if (index > -1) {
        allChecked.splice(index, 1);
      }
      setAllChecked([...allChecked]);
      setApiListData((prev) => prev.map((_data) => ({ ..._data, check: false })));
    }
  };

  const getFilterAllData = (apiListData: Array<ApiTableType>) => {
    const filterAll = apiListData.filter((item: ApiTableType) => item.code !== 'all');

    return filterAll.filter((item: ApiTableType) => item.check);
  };

  return (
    <Modal open={open}>
      <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        <TitleWithButton
          Icon={<AddCircleFillIcon sx={{ width: 40, height: 35 }} />}
          title={i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.CREATE_API_GROUP')}
          onCancel={cancelHandler}
          onFinish={finishHandler}
        />
        <Stack spacing={5} sx={{ bgcolor: 'white', minWidth: 700, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}>
          <Box display="flex" alignItems="center" flexDirection="row">
            <Stack sx={{ width: '60%' }}>
              <Typography sx={{ fontSize: 28 }}>
                {i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.CODE')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Stack sx={{ width: '50%' }}>
                <YatungInput value={code} onChange={(e: any) => setCode(e.target.value)} />
              </Stack>
            </Stack>
            <Stack>
              <Typography sx={{ fontSize: 28 }}>
                {i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.NAME')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Stack sx={{ width: '100%' }}>
                <YatungInput value={name} onChange={(e: any) => setName(e.target.value)} />
              </Stack>
            </Stack>
          </Box>
          <Box display="flex" alignItems="center" flexDirection="row" justifyContent="space-between">
            <Stack spacing={1} sx={{ width: '35%', mr: 3 }}>
              <Typography sx={{ fontSize: 28, letterSpacing: 10 }}>
                {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.STATUS')}:
                <span style={{ color: state === '1' ? '#00D03A' : '#FC0000', marginLeft: '20px' }}>
                  {state === '1' ? i18T('GLOBAL.OPEN') : i18T('GLOBAL.STOP')}
                </span>
              </Typography>
              <Stack spacing={2} alignItems="center" justifyContent="flex-start" direction="row">
                <YatungButton
                  text={i18T('GLOBAL.OPEN')}
                  color="green"
                  width={120}
                  startIcon={<SwitchIcon />}
                  onClick={() => setState('1')}
                />
                <YatungButton
                  text={i18T('GLOBAL.STOP')}
                  color="red"
                  width={120}
                  startIcon={<SwitchIcon />}
                  onClick={() => setState('0')}
                />
              </Stack>
            </Stack>
            <Stack>
              <Typography sx={{ fontSize: 28, letterSpacing: 15 }}>
                {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.EDIT_TIME')}
              </Typography>
              <Stack>
                <Typography sx={{ pl: 1, letterSpacing: 10 }}>{moment(new Date()).format('YYYY/MM/DD')}</Typography>
                <Typography sx={{ pl: 1, letterSpacing: 10 }}>{moment(new Date()).format('HH:mm')}</Typography>
              </Stack>
            </Stack>
          </Box>
          <Stack display="flex" flexDirection="column" spacing={2}>
            <Typography sx={{ fontSize: 28 }}>
              {i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.ADD_API_FEATURES')}
            </Typography>
            <YatungTable
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'gray',
                minHeight: 200,
                maxHeight: 200,
                maxWidth: 630,
                px: 1,
                borderRadius: 2,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#BB5E00',
                  borderRadius: 8,
                },
              }}
              isTableMinWidth={false}
              columns={columns}
              data={getFilterAllData(apiListData)}
              headColor={false}
            />
            <YatungInput
              value={searchGroup}
              onChange={(e: any) => setSearchGroup(e.target.value)}
              position="start"
              icon={<SearchIcon sx={{ fontSize: 36, ml: 2 }} />}
              style={{ fontSize: 28 }}
            />
            <Stack
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'gray',
                minHeight: 200,
                maxHeight: 200,
                borderRadius: 2,
                maxWidth: 630,
                px: 3,
                py: 1,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#BB5E00',
                  borderRadius: 8,
                },
              }}
            >
              {searchApiListByInput?.map((item: any) => (
                <>
                  {item.code === 'all' ? (
                    <YatungCheckBox
                      options={[{ value: 1, text: '全選' }]}
                      labelTextStyle={{ fontSize: 22, fontWeight: 600 }}
                      onChange={handleChangeAll}
                      initialCheckedValue={allChecked}
                    />
                  ) : (
                    <Button
                      key={item.id}
                      sx={{ fontSize: 20, color: 'gray', borderBottom: 1, justifyContent: 'space-between' }}
                      disabled={item.check}
                      onClick={() => {
                        setApiListData((prev) =>
                          prev.map((_data) => (_data.id === item.id ? { ..._data, check: true } : _data)),
                        );
                      }}
                    >
                      {`${item.code} ${item.name}`}
                      {item.check ? <TickIcon /> : <></>}
                    </Button>
                  )}
                </>
              ))}
              <YatungConfirmAlert
                isShowAlert={isShowAlert}
                onClose={() => {
                  setIsShowAlert(false);
                  setTimeout(() => setAlertText(''), 300);
                }}
                alertText={alertText}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}
