import Stack from '@mui/material/Stack';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';

import CreateIcon from '@Src/_basic/icons/Create';
import EditIcon from '@Src/_basic/icons/Edit';

import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import { Options } from '@Src/_basic/components/YatungSelect';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { AttributeData } from '@Src/_basic/object/AttributeType';
import {
  MaterialFactoryCodeData,
  PostMaterialFactoryCodeRequest,
  PutMaterialFactoryCodeRequest,
} from '@Src/_basic/object/MaterialFactoryCodeType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { SourceData } from '@Src/_basic/object/SourceType';
import { SpecificationData } from '@Src/_basic/object/SpecificationType';
import { SupplierData } from '@Src/_basic/object/SupplierType';
import { AttributeApi } from '@Src/_basic/protocol/attribute/AttributeApi';
import { MaterialFactoryCodeApi } from '@Src/_basic/protocol/materialFactoryCode/MaterialFactoryCodeApi';
import { SourceApi } from '@Src/_basic/protocol/source/SourceApi';
import { SpecificationApi } from '@Src/_basic/protocol/specification/SpecificationApi';
import { SupplierApi } from '@Src/_basic/protocol/supplier/SupplierApi';
import { TypeApi } from '@Src/_basic/protocol/type/TypeApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Grid, Pagination } from '@mui/material';
import { useLocation } from 'react-router';
import AddModal from './components/AddModal';
import EditModal from './components/EditModal';
import FactoryMaterialCodeTable from './components/FactoryMaterialCodeTable';

export interface NumberData {
  suppliers: Array<SupplierData>;
  sources: Array<SourceData>;
  specs: Array<SpecificationData>;
  attributes: Array<AttributeData>;
  type: Partial<Options>;
}

type QueryType = {
  factoryId: number;
  typeId: number;
};

type FilterValueType = QueryType & {
  areaId: number;
  facCode: string;
};

type SearchParams = QueryType & {
  page: number;
  pageSize: number;
};

type AsyncStorageSavingType = SearchParams & FilterValueType;

export default function FactoryMaterialCodeManage() {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { userAreaOptionsData } = useAuth();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [allNumbersData, setAllNumberData] = useState<NumberData>({
    suppliers: [],
    sources: [],
    specs: [],
    attributes: [],
    type: {},
  });

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [allTypes, setAllTypes] = useState<Array<Options>>([]);
  const [materialCodeList, setMaterialCodeList] = useState<Array<MaterialFactoryCodeData>>([]);
  const [selectedMaterialCode, setSelectedMaterialCode] = useState<MaterialFactoryCodeData>();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const initPageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
      pageSize: 10,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const initFilterValue = useMemo(() => {
    const {
      areaId = 0,
      factoryId = 0,
      typeId = 0,
      facCode = '',
    } = request?.page === location.pathname && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
      typeId,
      facCode,
    };
  }, [request, location.pathname]);

  const initQueryValue = useMemo(
    () => ({
      factoryId: initFilterValue.factoryId,
      typeId: initFilterValue.typeId,
    }),
    [initFilterValue],
  );

  const [filterValue, filterValueDispatch] = useReducer<Reducer<FilterValueType, Partial<FilterValueType>>>(
    updateReducer,
    initFilterValue,
  );
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, initQueryValue);
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const reset = useCallback(() => {
    setIsVisible(false);
    setMaterialCodeList([]);
    pageInfoDispatch({
      total: 0,
      page: 0,
      totalCount: 0,
    });
  }, [setMaterialCodeList]);

  const searchParams: SearchParams = useMemo(() => {
    return {
      ...query,
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
    };
  }, [query, pageInfo.page, pageInfo.pageSize]);

  const handlePageChange = useCallback((e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
  }, []);

  const handleFilterChange = (field: keyof FilterValueType) => (value: FilterValueType[keyof FilterValueType]) => {
    filterValueDispatch({ [field]: value });
  };

  const handleQueryChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    queryDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
  };

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    handleFilterChange(field)(value);
    handleQueryChange(field)(value);
  };

  const handleCodeSelect = useCallback((code: MaterialFactoryCodeData) => {
    setIsEditModalOpen(true);
    setSelectedMaterialCode(code);
  }, []);

  const getAllNumbersData = useCallback(() => {
    TypeApi.getAllTypes((allTypes) => {
      SupplierApi.getAllSuppliers((allSuppliers: Array<SupplierData>) => {
        SourceApi.getAllSources((allSources: Array<SourceData>) => {
          SpecificationApi.getSpecifications((allSpecs: Array<SpecificationData>) => {
            AttributeApi.getAllAttributes((allAttributes: Array<AttributeData>) => {
              setAllTypes(
                allTypes.map((item) => ({
                  value: item.id,
                  text: item.name,
                  code: item.code,
                })),
              );
              const _findType = allTypes.find((t) => t.id === filterValue.typeId);
              if (!_findType) return;
              else {
                const type = {
                  value: _findType.id,
                  text: _findType.name,
                  code: _findType.code,
                };
                setAllNumberData({
                  suppliers: allSuppliers,
                  sources: allSources,
                  specs: allSpecs,
                  attributes: allAttributes,
                  type,
                });
              }
            });
          });
        });
      });
    });
  }, [filterValue.typeId]);

  const getMaterialCodeList = useCallback(() => {
    MaterialFactoryCodeApi.getMaterialFactory(searchParams, (_data) => {
      setMaterialCodeList(
        _data.data.map((item, index) => ({ ...item, index: (searchParams.page - 1) * 10 + index + 1 })),
      );
      pageInfoDispatch({
        total: _data.maxPage,
        totalCount: _data.totalElement,
      });
      setIsVisible(true);
    });
  }, [searchParams]);

  const successHandler = useCallback(
    (mode: 'edit' | 'add') => {
      getMaterialCodeList();
      switch (mode) {
        case 'edit':
          setIsEditModalOpen(false);
          break;
        case 'add':
          setIsAddModalOpen(false);
          break;
      }
    },
    [getMaterialCodeList],
  );

  const addAndEditHandler = useCallback(
    (data: Omit<PostMaterialFactoryCodeRequest | PutMaterialFactoryCodeRequest, 'factoryId'>, mode: 'edit' | 'add') => {
      const request = { ...data, factoryId: filterValue.factoryId };
      switch (mode) {
        case 'add':
          MaterialFactoryCodeApi.postMaterialFactory(request, () => successHandler(mode));
          break;
        case 'edit':
          MaterialFactoryCodeApi.putMaterialFactory(request as PutMaterialFactoryCodeRequest, () =>
            successHandler(mode),
          );
          break;
      }
    },
    [successHandler, filterValue.factoryId],
  );

  const changeRemovedHandler = useCallback(
    (data: MaterialFactoryCodeData) => {
      MaterialFactoryCodeApi.putMaterialFactory({ ...data, removed: !data.removed }, getMaterialCodeList);
    },
    [getMaterialCodeList],
  );

  useEffect(() => {
    if (!searchParams.factoryId || !searchParams.typeId) {
      reset();
    } else {
      const { factoryId, areaId } = filterValue;
      const _facCode = userAreaOptionsData
        .find((item) => item.value === areaId)
        ?.factories?.find((item) => item.value === factoryId);

      const _findType = allTypes.find((t) => t.value === filterValue.typeId);
      if (_findType) setAllNumberData((prev) => ({ ...prev, type: _findType }));

      handleFilterChange('facCode')(_facCode?.facCode || '');
      setSaveRequest({ page: location.pathname, request: { ...filterValue, ...searchParams } });
      getMaterialCodeList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    getAllNumbersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.FACTORY_MATERIAL_CODE_MANAGE.TITLE')}
      body={
        <>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
              <YatungCombinationSelect<QueryType, FilterValueType>
                filterValue={filterValue}
                handleChange={handleChange}
                handleFilterChange={handleFilterChange}
                selectOptions={['AreaAndFactory', 'Type']}
              />
            </Stack>
            <YatungButton
              text={i18T('GLOBAL.CREATE')}
              startIcon={<CreateIcon />}
              disabled={!filterValue.factoryId || !filterValue.typeId || actionLoading}
              onClick={() => setIsAddModalOpen(true)}
              color="green"
              sx={{ alignSelf: 'center' }}
            />
          </Stack>
          <YatungFadeInOut isVisible={isVisible}>
            <FactoryMaterialCodeTable
              materialCodeList={materialCodeList}
              handleCodeSelect={handleCodeSelect}
              changeRemovedHandler={changeRemovedHandler}
            />
          </YatungFadeInOut>

          <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2 }}>
            <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
          </Grid>
          {allNumbersData && (
            <AddModal
              open={isAddModalOpen}
              handleClose={() => setIsAddModalOpen(false)}
              handleSubmit={addAndEditHandler}
              icon={<CreateIcon sx={{ mr: 1 }} fontSize="large" />}
              title={i18T('GLOBAL.CREATE')}
              allNumbersData={allNumbersData}
              facCode={filterValue.facCode}
            />
          )}
          {selectedMaterialCode && (
            <EditModal
              open={isEditModalOpen}
              handleClose={() => setIsEditModalOpen(false)}
              handleSubmit={addAndEditHandler}
              icon={<EditIcon sx={{ mr: 1 }} fontSize="large" />}
              title={i18T('GLOBAL.UPDATE')}
              allNumbersData={allNumbersData}
              selectedData={selectedMaterialCode}
              facCode={filterValue.facCode}
            />
          )}
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
