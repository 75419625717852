import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import { Box, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onModalClose: () => void;
  onSubmit: () => void;
  setNotes: (e: string) => void;
  notes: string;
}
export default function KeyNoteModal(props: Props) {
  const { t: i18T } = useTranslation();
  const { open, onModalClose, onSubmit, setNotes, notes } = props;

  const bodyStyle = {
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#697EA3',
    px: 5,
    py: 1,
  };
  const inputStyle = {
    border: '1px solid #FFFFFF',
    borderRadius: '8px',
  };

  const handleModalColse = () => {
    onModalClose();
  };

  return (
    <Grid container justifyContent={'center'} item xs={12}>
      <YatungDialog
        open={open}
        handleClose={handleModalColse}
        // icon={<Clear sx={{ mr: 1 }} fontSize="large" />}
        postion={{
          '& .MuiDialog-container': {
            alignItems: 'center',
          },
        }}
        title={i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.NOTE')}
        titleColor={{ color: '#FFFFFF', bgcolor: '#BB5E00' }}
        body={
          <>
            <Grid container justifyContent={'flex-start'} item xs={12} sx={{ mt: 1 }}>
              <Typography sx={bodyStyle}>{`${i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.NOTE')}：`}</Typography>
            </Grid>
            <Box sx={{ px: 5, py: 1 }}>
              <TextField
                multiline={true}
                rows={6}
                fullWidth
                sx={inputStyle}
                inputProps={{
                  maxLength: 200,
                  style: {
                    color: '#6C708C',
                    fontSize: 24,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                  },
                }}
                defaultValue={notes}
                onBlur={(e) => {
                  setNotes(e.target.value);
                }}
              />
            </Box>
            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
              <YatungButton
                size={'large'}
                color="error"
                type={'button'}
                text={i18T('GLOBAL.CANCEL')}
                sx={{
                  fontSize: '24px',
                  px: 5,
                }}
                onClick={handleModalColse}
              />
              <YatungButton
                size={'large'}
                color="blue"
                type={'button'}
                text={i18T('GLOBAL.FINISH')}
                sx={{
                  fontSize: '24px',
                  px: 5,
                  ml: 2,
                }}
                onClick={onSubmit}
              />
            </Grid>
          </>
        }
      />
    </Grid>
  );
}
