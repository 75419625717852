import React from 'react';
import { useTranslation } from 'react-i18next';
import SiteManagementUpdateForm from './SiteManagementUpdateForm';
import YatungPage from '@Src/_basic/components/YatungPage';

const background = '/media/images/background.png';

export default function SiteManagementUpdateCard() {
  const { t: i18T } = useTranslation();
  return (
    <YatungPage
      title={i18T('SITEMANAGEMENT.PAGE_UPDATE_TITLE')}
      backgroundImage={background}
      body={<SiteManagementUpdateForm />}
    />
  );
}
