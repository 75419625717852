import { Protocol } from '../Protocol';

export class CalendarProtocol {
  public static readonly GET_CALENDAR_ALL: string = `${Protocol.YATUNG_API_ROOT}/calendar/all`;
  public static readonly GET_CALENDAR_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/calendar`;
  public static readonly GET_CALENDAR_COLOR_CODE: string = `${Protocol.YATUNG_API_ROOT}/calendar/colorCode/all
  `;
  public static readonly GET_CALENDAR_FESTIVAL: string = `${Protocol.YATUNG_API_ROOT}/calendar/csv/all
  `;
}
