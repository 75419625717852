import { AreaData, PutUpdateDataRequest } from '@Src/_basic/object/AreaType';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import AreaManagementUpdateField from './components/AreaManagementUpdateField';
import AreaManagementUpdateSubmit from './components/AreaManagementUpdateSubmit';

interface Props {
  initialValues: PutUpdateDataRequest;
  onUpdateGoBackButtonClick: () => void;
  setAreaData: (e: Array<AreaData>) => void;
  setLoading: (e: boolean) => void;
}
const areaSchema = Yup.object().shape({
  name: Yup.string().required('請輸入區域名稱'),
  sortingOrder: Yup.number().typeError('請輸入數字').min(1, '請輸入大於1的數字').required('請輸入區域排序'),
});
export default function AreaManagementUpdateForm(props: Props) {
  const { initialValues, onUpdateGoBackButtonClick, setAreaData, setLoading } = props;
  const [warningMoalOpen, setWarningMoalOpen] = useState<boolean>(false);
  const [dulpicateWarningMoalOpen, setDulpicateWarningMoalOpen] = useState<boolean>(false);
  const handleSubmit = async (require: PutUpdateDataRequest) => {
    setLoading(true);
    AreaApi.putAllAreaById(
      require,
      (datas: AreaData) => {
        AreaApi.getAllAreas((data: Array<AreaData>) => {
          setAreaData(data);
        });
        onUpdateGoBackButtonClick();
      },
      () => {
        setDulpicateWarningMoalOpen(true);
      },
      () => {
        setLoading(false);
      },
    );
  };
  const handleDulpicateWarningModalClose = () => {
    setDulpicateWarningMoalOpen(false);
  };
  const handleClose = () => {
    setWarningMoalOpen(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={areaSchema}
      onSubmit={(values) => handleSubmit(values)}
      enableReinitialize
    >
      {(formik) => (
        <Form>
          <Grid container justifyContent={'center'} sx={{ my: 2 }} item xs={12}>
            <AreaManagementUpdateField
              setValue={formik.setFieldValue}
              value={formik.values}
              warningMoalOpen={warningMoalOpen}
              dulpicateWarningMoalOpen={dulpicateWarningMoalOpen}
              onClose={handleClose}
              onDulpicateWarningModalClose={handleDulpicateWarningModalClose}
              setWarningMoalOpen={setWarningMoalOpen}
              onUpdateGoBackButtonClick={onUpdateGoBackButtonClick}
              setAreaData={setAreaData}
            />
          </Grid>
          <Grid container justifyContent={'center'} sx={{ my: 2 }} item xs={12}>
            <AreaManagementUpdateSubmit onUpdateGoBackButtonClick={onUpdateGoBackButtonClick} />
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
