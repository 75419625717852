import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import { useAuth } from '@Src/redux/auth/authActions';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Box, Stack, Typography, styled } from '@mui/material';
import React, { useRef } from 'react';

const Button = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#BB5E00',
  userSelect: 'none',
  gap: '8px',
});

const ButtonLabel = styled(Typography)({
  color: '#697EA3',
  fontFamily: 'Microsoft JhengHei',
  fontWeight: 400,
  size: '20px',
});

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: '#9A99A1',
  fontSize: '16px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  letterSpacing: '0.255em',
}));

export default function UploadFileCard() {
  const { userData } = useAuth();
  const { projectInfo, uploadReports } = useProductionHistory();
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadFileButtonClick = () => {
    hiddenFileInputRef?.current?.click();
  };

  const handleChange = (event: any) => {
    const files = event.target.files;
    if (files) {
      uploadReports(
        {
          uploaderId: userData?.account || '',
          factoryId: projectInfo?.factoryId,
          orderId: projectInfo?.orderId,
        },
        files,
      );
    }
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ opacity: 0.2 }}>
        <Title>送審報告</Title>
      </Box>
      <Stack
        justifyContent="center"
        alignItems="center"
        gap="16px"
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          height: '100%',
          width: '100%',
        }}
      >
        <Button onClick={handleUploadFileButtonClick}>
          <AddCircleFill />
          <ButtonLabel>上傳檔案</ButtonLabel>
        </Button>
        <Label>檔案上傳只限PDF檔案</Label>
        <input
          ref={hiddenFileInputRef}
          type="file"
          accept=".pdf"
          multiple
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </Stack>
    </Box>
  );
}
