import { ChildrenTypography, TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import { MonthlyReportFineAggAnalysis } from '@Src/_basic/object/MonthlyReportType';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { SetStateAction, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportBlock from './components/ReportBlock';
import RowContent from './components/RowContent';
import ToggleBlockButton from './components/ToggleBlockButton';

type FilterType =
  | 'abnormal'
  | 'screening'
  | 'screening4'
  | 'screening8'
  | 'screening16'
  | 'screening30'
  | 'screening50'
  | 'screening100'
  | 'screening200'
  | null;

const FineAggregateAnalysisReport = ({ report }: { report: Array<MonthlyReportFineAggAnalysis> }) => {
  const { t: i18T } = useTranslation();
  const [abnormalSummary, setAbnormalSummery] = useState({
    abnormal: 0,
    screening: 0,
    screening4: 0,
    screening8: 0,
    screening16: 0,
    screening30: 0,
    screening50: 0,
    screening100: 0,
    screening200: 0,
  });
  const [selectedFilter, setSelectedFilter] = useState<SetStateAction<FilterType>>(null);

  const [reports, setReports] = useState<Array<MonthlyReportFineAggAnalysis>>([]);
  const [filteredReport, setFilteredReport] = useState<Array<MonthlyReportFineAggAnalysis>>([]);

  const handleFilterChange = (filter: FilterType) => () => {
    const newFilter = selectedFilter === filter ? null : filter;
    if (filter !== 'abnormal') {
      const filteredReport = reports.filter((i: MonthlyReportFineAggAnalysis) =>
        newFilter ? i[newFilter]?.isAbnormal : true,
      );
      setFilteredReport(filteredReport);
    } else {
      const allFilteredReport = report.filter(
        (item: MonthlyReportFineAggAnalysis) =>
          Object.keys(item).filter((i) => item[i]?.isAbnormal === true).length > 0,
      );
      setFilteredReport(allFilteredReport);
    }
    setSelectedFilter(newFilter);
  };

  useEffect(() => {
    const _report = report.map((d: MonthlyReportFineAggAnalysis) => ({
      ...d,
      abnormal: {
        isAbnormal:
          d['screening']?.isAbnormal ||
          d['screening4']?.isAbnormal ||
          d['screening8']?.isAbnormal ||
          d['screening16']?.isAbnormal ||
          d['screening30']?.isAbnormal ||
          d['screening50']?.isAbnormal ||
          d['screening100']?.isAbnormal ||
          d['screening200']?.isAbnormal,
      },
    }));

    const abnormalDay = new Set();
    const screeningAbnormalDay = new Set();
    const screening4AbnormalDay = new Set();
    const screening8AbnormalDay = new Set();
    const screening16AbnormalDay = new Set();
    const screening30AbnormalDay = new Set();
    const screening50AbnormalDay = new Set();
    const screening100AbnormalDay = new Set();
    const screening200AbnormalDay = new Set();

    _report.forEach((item) => {
      item.abnormal?.isAbnormal ? abnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening?.isAbnormal ? screeningAbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening4?.isAbnormal ? screening4AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening8?.isAbnormal ? screening8AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening16?.isAbnormal ? screening16AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening30?.isAbnormal ? screening30AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening50?.isAbnormal ? screening50AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening100?.isAbnormal ? screening100AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
      item.screening200?.isAbnormal ? screening200AbnormalDay.add(moment(item.testDate).format('MM-dd')) : null;
    });

    const abnormalSummary = {
      abnormal: abnormalDay.size,
      screening: screeningAbnormalDay.size,
      screening4: screening4AbnormalDay.size,
      screening8: screening8AbnormalDay.size,
      screening16: screening16AbnormalDay.size,
      screening30: screening30AbnormalDay.size,
      screening50: screening50AbnormalDay.size,
      screening100: screening100AbnormalDay.size,
      screening200: screening200AbnormalDay.size,
    };

    setReports(_report);
    setFilteredReport(_report);
    setAbnormalSummery(abnormalSummary);
  }, [report]);

  return (
    <>
      <TestCardContainer sx={{ flexDirection: 'row', gap: 3, justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <ToggleBlockButton
          isToggled={selectedFilter === 'abnormal'}
          onPress={handleFilterChange('abnormal')}
          top={
            <>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE_ANALYSIS`)}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.abnormal ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.abnormal}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening'}
          onPress={handleFilterChange('screening')}
          top={
            <>
              <ChildrenTypography>{`3/8"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack mt={3}>
              <ChildrenTypography style={abnormalSummary.screening ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.screening}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening4'}
          onPress={handleFilterChange('screening4')}
          top={
            <>
              <ChildrenTypography>{`#4"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening4 ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.screening4}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening8'}
          onPress={handleFilterChange('screening8')}
          top={
            <>
              <ChildrenTypography>{`#8"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening8 ? { color: `#FFA0F0` } : {}}>
                {' '}
                {abnormalSummary.screening8}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening16'}
          onPress={handleFilterChange('screening16')}
          top={
            <>
              <ChildrenTypography>{`#16"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening16 ? { color: `#FFA0F0` } : {}}>
                {' '}
                {abnormalSummary.screening16}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening30'}
          onPress={handleFilterChange('screening30')}
          top={
            <>
              <ChildrenTypography>{`#30"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening30 ? { color: `#FFA0F0` } : {}}>
                {' '}
                {abnormalSummary.screening30}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening50'}
          onPress={handleFilterChange('screening50')}
          top={
            <>
              <ChildrenTypography>{`#50"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening50 ? { color: `#FFA0F0` } : {}}>
                {' '}
                {abnormalSummary.screening50}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening100'}
          onPress={handleFilterChange('screening100')}
          top={
            <>
              <ChildrenTypography>{`#100"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening100 ? { color: `#FFA0F0` } : {}}>
                {' '}
                {abnormalSummary.screening100}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'screening200'}
          onPress={handleFilterChange('screening200')}
          top={
            <>
              <ChildrenTypography>{`#200"${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.screening200 ? { color: `#FFA0F0` } : {}}>
                {' '}
                {abnormalSummary.screening200}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAYS`)}</ChildrenTypography>
            </Stack>
          }
        />
      </TestCardContainer>
      {filteredReport &&
        filteredReport?.map((item: MonthlyReportFineAggAnalysis) => (
          <ReportBlock
            key={item.exptId}
            tester={item.tester}
            exptId={item.exptId}
            exptItemCode={item.exptItemCode}
            verifyPassedResults={item.verifyPassedResults}
            verifyCustomResults={item.verifyCustomResults}
            exptId1={item.exptId1}
            exptId2={item.exptId2}
            ratio={item.ratio}
          >
            <RowContent
              labels={[
                i18T(`MONTHLY_REPORT.BASE.TEST_DATE`),
                `3/8"\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
                `#4"\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
              ]}
              values={[
                moment(item.testDate).format('YYYY/MM/DD'),
                [
                  item.exptId1 ? item.screening?.result.toFixed(2) ?? '0' : item.screening?.result.toFixed(2) ?? '0',
                  item.screening?.isAbnormal,
                ],
                [
                  item.exptId1 ? item.screening4?.result.toFixed(2) ?? '0' : item.screening4?.result.toFixed(2) ?? '0',
                  item.screening4?.isAbnormal,
                ],
              ]}
            />
            <RowContent
              labels={[
                `#8\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
                `#16\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
                `#30\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
              ]}
              values={[
                [
                  item.exptId1 ? item.screening8?.result.toFixed(2) ?? '0' : item.screening8?.result.toFixed(2) ?? '0',
                  item.screening8?.isAbnormal,
                ],
                [
                  item.exptId1
                    ? item.screening16?.result.toFixed(2) ?? '0'
                    : item.screening16?.result.toFixed(2) ?? '0',
                  item.screening16?.isAbnormal,
                ],
                [
                  item.exptId1
                    ? item.screening30?.result.toFixed(2) ?? '0'
                    : item.screening30?.result.toFixed(2) ?? '0',
                  item.screening30?.isAbnormal,
                ],
              ]}
            />
            <RowContent
              labels={[
                `#50"\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
                `#100"\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
                `#200"\n${i18T(`MONTHLY_REPORT.ANALYSIS.SIEVE`)}(%)`,
              ]}
              values={[
                [
                  item.exptId1
                    ? item.screening50?.result.toFixed(2) ?? '0'
                    : item.screening50?.result.toFixed(2) ?? '0',
                  item.screening50?.isAbnormal,
                ],
                [
                  item.exptId1
                    ? item.screening100?.result.toFixed(2) ?? '0'
                    : item.screening100?.result.toFixed(2) ?? '0',
                  item.screening100?.isAbnormal,
                ],
                [
                  item.exptId1
                    ? item.screening200?.result.toFixed(2) ?? '0'
                    : item.screening200?.result.toFixed(2) ?? ' 0',
                  item.screening200?.isAbnormal,
                ],
              ]}
            />
          </ReportBlock>
        ))}
    </>
  );
};

export default memo(FineAggregateAnalysisReport);
