import {
  getSpecificationExtraDataFieldNameByExperimentResultTypeId,
  getTypeAmountByExperimentItemId,
} from '@Src/_basic/helpers/CustomStandardHelpers';
import { CreateCustomStandardFormData } from '@Src/_basic/object/CustomStandardType';
import { Stack } from '@mui/material';
import React, { createContext, useContext, useReducer, useState } from 'react';
import BasicFrom from './components/BasicFrom';
import SpecificationFrom from './components/SpecificationsFrom';

export type CreateCustomStandardUIEvents = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  formData: CreateCustomStandardFormData;
  formDataDispatch: React.Dispatch<any>;
  clearFormData: () => void;
};

const CreateCustomStandardUIContent = createContext<Partial<CreateCustomStandardUIEvents>>({});

export function useCreateCustomStandardUI() {
  return useContext(CreateCustomStandardUIContent);
}

export const CreateCustomStandardUIConsumer = CreateCustomStandardUIContent.Consumer;

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'updateValue':
      return { ...state, ...action.payload };
    default:
      return { state };
  }
}

interface Props {
  typeId: number;
  experimentItemId: number;
  initialValues?: CreateCustomStandardFormData;
  btnRef?: any;
  handleSubmit: (values: any) => void;
}

export default function CreateCustomStandardForm(props: Props) {
  const [loading, setLoading] = useState(false);
  const [formData, formDataDispatch] = useReducer(reducer, props.initialValues);

  const clearFormData = () => {
    formDataDispatch({ type: 'updateValue', payload: { ...props.initialValues } });
  };

  const value = {
    loading,
    setLoading,
    formData,
    formDataDispatch,
    clearFormData,
  };

  return (
    <CreateCustomStandardUIContent.Provider value={value}>
      <>
        <button
          ref={props.btnRef}
          onClick={() => {
            props.handleSubmit(formData);
          }}
          style={{ display: 'none' }}
        />
        <Stack spacing={2}>
          <BasicFrom typeId={props.typeId} experimentItemId={props.experimentItemId} />
          <SpecificationFrom
            variant={getSpecificationExtraDataFieldNameByExperimentResultTypeId(formData.experimentResultTypeId)}
            typeId={props.typeId}
            specificationGroupsSize={2}
            maxSpecificationsSize={26}
            typeAmount={getTypeAmountByExperimentItemId(props.experimentItemId)}
          />
        </Stack>
      </>
    </CreateCustomStandardUIContent.Provider>
  );
}
