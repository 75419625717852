import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import TransferOrderGroupManagementCardScreen from './screens/TransferOrderGroupManagementCardScreen';
import TransferOrderGroupManagementCreateCardScreen from './screens/TransferOrderGroupManagementCreateCardScreen';
import TransferOrderGroupManagementUpdateCardScreen from './screens/TransferOrderGroupManagementUpdateCardScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <TransferOrderGroupManagementCardScreen />,
  },
  {
    path: '/TransferOrderGroupManagementCreate/*',
    element: <TransferOrderGroupManagementCreateCardScreen />,
  },
  {
    path: '/TransferOrderGroupManagementUpdate/:id',
    element: <TransferOrderGroupManagementUpdateCardScreen />,
  },
];

const TransferOrderGroupManagementRouter = () => PermissionRoute(routesConfig);

export default memo(TransferOrderGroupManagementRouter);
