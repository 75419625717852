import MockAdapter from 'axios-mock-adapter/types';
import { organizeProtocol } from '../organizeProtocol';
import organizeTableMock from './organizeTableMock';

export default function factoryMockDetails(mock: MockAdapter) {
  mock.onGet(organizeProtocol.ORGANIZE_MANAGEMENT_DATA).reply(200, {
    organizeTableMock,
  });
  mock.onPost(organizeProtocol.ORGANIZE_MANAGEMENT_CREATE_DATA).reply(({ data }) => {
    const { organizeName } = JSON.parse(data);

    if (organizeName) {
      const management = {
        id: generateUserId(),
        organizeName,
      };
      console.log(management);
      organizeTableMock.push(management);
      return [200, { ...management }];
    }
    return [400];
  });
  mock.onGet(organizeProtocol.ORGANIZE_MANAGEMENT_GET_ID).reply((config) => {
    const datas = organizeTableMock.find((el) => el.id === config.params.id);
    if (datas) {
      return [200, datas];
    }
    return [400];
  });
  mock.onPut(organizeProtocol.ORGANIZE_MANAGEMENT_UPDATE_DATA).reply(({ data }) => {
    console.log('data', data);
    const dataToJson = JSON.parse(data);
    console.log(dataToJson);
    const index = organizeTableMock.findIndex((el) => el.id === dataToJson.id);
    console.log('index', index);
    if (index !== -1) {
      organizeTableMock[index] = { ...dataToJson };
      return [200, { ...dataToJson }];
    }
    console.log('error');
    return [400];
  });
  mock.onDelete(organizeProtocol.ORGANIZE_MANAGEMENT_DELETE_DATA).reply((config) => {
    const index = organizeTableMock.findIndex((el) => el.id === config.params.id);
    console.log(index);
    if (index !== -1) {
      organizeTableMock.splice(index, 1);
      console.log(organizeTableMock);
      return [200, organizeTableMock];
    }
    console.log('error');
    return [400];
  });
  function generateUserId() {
    const ids = organizeTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}
