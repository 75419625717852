import { Stack } from '@mui/system';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import AggregateIcon from '@Src/_basic/icons/GradientBackground/Aggregate';
import CementIcon from '@Src/_basic/icons/GradientBackground/Cement';
import ConcreteIcon from '@Src/_basic/icons/GradientBackground/Concrete';
import FlyashIcon from '@Src/_basic/icons/GradientBackground/Flyash';
import MedicineIcon from '@Src/_basic/icons/GradientBackground/Medicine';
import MixingWaterIcon from '@Src/_basic/icons/GradientBackground/MixingWater';
import ReclaimedWaterIcon from '@Src/_basic/icons/GradientBackground/ReclaimedWater';
import SandIcon from '@Src/_basic/icons/GradientBackground/Sand';
import SlagIcon from '@Src/_basic/icons/GradientBackground/Slag';
import { useNavigate } from 'react-router-dom';

const selectTypes = [
  SandIcon,
  AggregateIcon,
  CementIcon,
  SlagIcon,
  FlyashIcon,
  MedicineIcon,
  MixingWaterIcon,
  ConcreteIcon,
  ReclaimedWaterIcon,
];

export default function SelectTypeMenu() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();

  const handleClick = useCallback(
    (index: number) => {
      navigate(`/qualityManagement/legalStandards/list?typeId=${index === 8 ? 11 : index + 1}`);
    },
    [navigate],
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Stack
        direction="row"
        style={{
          backgroundColor: '#b6b7b8',
          borderRadius: '10px',
          border: '2px solid White',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#033991',
            height: '100%',
            width: '60px',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
          }}
        >
          <span
            style={{
              letterSpacing: '27px',
              textIndent: '27px',
              fontSize: '30px',
              color: 'white',
              writingMode: 'vertical-rl',
              WebkitWritingMode: 'vertical-rl',
              fontFamily: 'microsoft yahei',
            }}
          >
            {i18T('QUALITY_MANAGE.STANDARD.SELECT_TYPE_TITLE')}
          </span>
        </div>
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
          maxWidth={880}
          sx={{ py: 2 }}
        >
          {selectTypes.map((Icon, index) => {
            return (
              <div
                key={index}
                role="button"
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={() => handleClick(index)}
              >
                <Icon />
              </div>
            );
          })}
        </Stack>
      </Stack>
    </div>
  );
}
