import { Box } from '@mui/material';
import React from 'react';
import QualityManageMenu from './SideMenu/QualityManageMenu';
import ReportMenu from './SideMenu/ReportMenu';
import UsersManageMenu from './SideMenu/UsersManageMenu';
import InepectionReportMenu from './SideMenu/ViewMenu';

export default function Content() {
  return (
    <Box>
      <UsersManageMenu />
      <QualityManageMenu />
      <ReportMenu />
      <InepectionReportMenu />
    </Box>
  );
}
