import { Response } from '@Src/_basic/object/ApiType';
import { SearchTypeParams, SearchTypeParamsByRemoved, TypeData } from '@Src/_basic/object/TypeType';
import { Api } from '../Api';
import { TypeProtocol } from './TypeProtocol';

export class TypeApi {
  public static getAllTypes(
    onSuccess?: (data: Array<TypeData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: { removed: false },
    };
    Api.get(TypeProtocol.ALL_TYPES, config, onSuccess, onFailed, onFinally);
  }

  public static getTypesBySearch(
    params: SearchTypeParams,
    onSuccess?: (data: Array<TypeData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TypeProtocol.ALL_TYPES, config, onSuccess, onFailed, onFinally);
  }
  public static getTypesBySearchRelatedRemoved(
    params: SearchTypeParamsByRemoved,
    onSuccess?: (data: Array<TypeData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(TypeProtocol.ALL_TYPES, config, onSuccess, onFailed, onFinally);
  }
}
