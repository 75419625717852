import { ChildrenTypography, TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import {
  MonthlyReportFineAggRValue,
  ReportViewResponse,
  ResultAndIsAbnormal,
} from '@Src/_basic/object/MonthlyReportType';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { SetStateAction, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportBlock from './components/ReportBlock';
import RowContent from './components/RowContent';
import ToggleBlockButton from './components/ToggleBlockButton';

type FilterType = 'pieces' | 'maxmin' | null;

const FineAggregateRValueReport = ({
  report,
  headerData,
}: {
  report: Array<MonthlyReportFineAggRValue<ResultAndIsAbnormal<number>>>;
  headerData: ReportViewResponse;
}) => {
  const { t: i18T } = useTranslation();
  const [summary, setSummery] = useState({ max: 0, min: 0, average: 0 });
  const [abnormalSummary, setAbnormalSummery] = useState({
    pieces: 0,
    days: 0,
    dateRecord: {},
  });
  const [selectedFilter, setSelectedFilter] = useState<SetStateAction<FilterType>>(null);

  const [reports, setReports] = useState<Array<MonthlyReportFineAggRValue<ResultAndIsAbnormal<number>>>>([]);
  const [filteredReport, setFilteredReport] = useState<Array<MonthlyReportFineAggRValue<ResultAndIsAbnormal<number>>>>(
    [],
  );

  const handleFilterChange = (filter: FilterType) => () => {
    const newFilter = selectedFilter === filter ? null : filter;
    const filteredReport = reports.filter((d: MonthlyReportFineAggRValue<ResultAndIsAbnormal<number>>) => {
      switch (newFilter) {
        case 'pieces':
          return d.value.isAbnormal;
        case 'maxmin':
          return d.value.result === summary.min || d.value.result === summary.max;
        default:
          return true;
      }
    });
    setSelectedFilter(newFilter);
    setFilteredReport(filteredReport);
  };

  useEffect(() => {
    const dateRecord: any = {};
    const summary = { max: Number.MIN_VALUE, min: report?.length > 0 ? Number.MAX_VALUE : 0, average: 0, sum: 0 };

    let pieces = 0;
    report?.forEach((d: MonthlyReportFineAggRValue<ResultAndIsAbnormal<number>>) => {
      summary.max = Math.max(summary.max, d.value.result);
      summary.min = Math.min(summary.min, d.value.result);
      summary.sum += d.value.result;

      if (d.value.isAbnormal) {
        const date = moment(d.testDate).format('YYYY/MM/DD');
        if (!dateRecord[date]) dateRecord[date] = 1;
        pieces += 1;
      }
    });

    setReports(report);
    setFilteredReport(report);
    setSummery({
      max: checkIsNaN(summary.max),
      min: checkIsNaN(summary.min),
      average: checkIsNaN(summary.sum / report?.length),
    });
    setAbnormalSummery({
      pieces,
      days: Object.keys(dateRecord)?.length,
      dateRecord,
    });
  }, [report]);

  return (
    <>
      <TestCardContainer sx={{ flexDirection: 'row', gap: 3, justifyContent: 'space-between' }}>
        <ToggleBlockButton
          top={
            <>
              <ChildrenTypography>{`${
                new Date(headerData?.endTime).getDate() - new Date(headerData?.startTime).getDate() + 1
              }
              ${i18T(`MONTHLY_REPORT.BASE.DAY`)}${i18T(`MONTHLY_REPORT.R_VALUE.R_VALUE`)}
              ${i18T(`MONTHLY_REPORT.BASE.AVERAGE`)}`}</ChildrenTypography>
              <ChildrenTypography>{summary.average?.toFixed(1)}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.R_VALUE.CONTRACT_SPECIFICATION`)}</ChildrenTypography>
              <ChildrenTypography>≥40</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'pieces'}
          onPress={handleFilterChange('pieces')}
          top={
            <>
              <ChildrenTypography>{`${
                new Date(headerData?.endTime).getDate() - new Date(headerData?.startTime).getDate() + 1
              }
              ${i18T(`MONTHLY_REPORT.BASE.DAY`)}${i18T(`MONTHLY_REPORT.R_VALUE.R_VALUE`)}
              ${i18T(`MONTHLY_REPORT.BASE.AVERAGE`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.ITEMS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack flexDirection="row" mt={3}>
              <ChildrenTypography style={abnormalSummary.days ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.pieces}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.ITEM`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'maxmin'}
          onPress={handleFilterChange('maxmin')}
          top={
            <>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.R_VALUE.MAX_R_VALUE`)}</ChildrenTypography>
              <ChildrenTypography>{summary.max?.toFixed(2)}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.R_VALUE.MIN_R_VALUE`)}</ChildrenTypography>
              <ChildrenTypography>{summary.min?.toFixed(2)}</ChildrenTypography>
            </Stack>
          }
        />
      </TestCardContainer>
      {filteredReport &&
        filteredReport?.map((item: MonthlyReportFineAggRValue<ResultAndIsAbnormal<number>>) => (
          <ReportBlock
            key={item.exptId}
            tester={item.tester}
            exptId={item.exptId}
            exptItemCode={item.exptItemCode}
            verifyPassedResults={true}
            verifyCustomResults={true}
          >
            <RowContent
              widths={[30, 30, 40]}
              labels={[
                i18T(`MONTHLY_REPORT.BASE.TEST_DATE`),
                i18T(`MONTHLY_REPORT.BASE.SAMPLING_DATE`),
                i18T(`MONTHLY_REPORT.R_VALUE.R_VALUE`),
              ]}
              values={[
                moment(item.testDate).format('YYYY/MM/DD'),
                moment(item.sampleDate).format('YYYY/MM/DD'),
                [item?.value?.result?.toFixed(2) ?? '0', item?.value?.isAbnormal],
              ]}
            />
          </ReportBlock>
        ))}
    </>
  );
};

export default memo(FineAggregateRValueReport);
