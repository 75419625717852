import React, { useEffect, useState } from 'react';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import moment from 'moment';
import NamesExpand from '@Src/_basic/components/NamesExpand';
import { LegalStandardData } from '@Src/_basic/object/LegalStandardType';
import { FactoryData } from '@Src/_basic/object/FactoryType';

const rawMaterialMappingTable: any = {
  '1': '細粒料',
  '2': '粗粒料',
  '3': '水泥',
  '4': '爐石',
  '5': '飛灰',
  '6': '藥劑',
  '7': '拌合水',
  '8': '混凝土',
  '11': '回收水',
};

interface Props {
  legalStandard: LegalStandardData | undefined;
  typeId: number;
}

export default function BasicCard(props: Props) {
  const [factoryNames, setFactoryNames] = useState<Array<string>>([]);
  const getFactoryNames = () => {
    setFactoryNames(props.legalStandard?.factories?.map((factory: FactoryData) => factory.factoryName) || []);
  };

  useEffect(() => {
    if (props.legalStandard) {
      getFactoryNames();
    }
  }, [props.legalStandard]);

  return (
    <Paper elevation={2} sx={{ padding: '16px 32px', borderRadius: '5px' }}>
      <Stack spacing={2}>
        <Typography variant="h2" color="#BB5E00">
          基本設定
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">規範名稱：</Typography>
            </Grid>
            <Grid item xs>
              <Typography>{props.legalStandard?.name}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center"></Grid>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1"> 對應紀錄表/報告：</Typography>
            </Grid>
            <Grid item xs>
              <Typography>{props.legalStandard?.experimentResultType?.name}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center"></Grid>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1"> 對應廠區：</Typography>
            </Grid>
            <Grid item xs>
              <Typography>
                <NamesExpand names={factoryNames} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1"> 對應原料：</Typography>
            </Grid>
            <Grid item>
              <Typography>{rawMaterialMappingTable[props.typeId.toString()]}</Typography>
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1"> 有效日期_開始：</Typography>
            </Grid>
            <Grid item xs>
              <Typography>{moment(props.legalStandard?.startDate).format('YYYY/MM/DD HH:mm:ss')}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1"> 有效日期_結束：</Typography>
            </Grid>
            <Grid item xs>
              <Typography>{moment(props.legalStandard?.endDate).format('YYYY/MM/DD HH:mm:ss')}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Paper>
  );
}
