import Stack from '@mui/material/Stack';
import moment from 'moment';
import React from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';

import DeteleIcon from '@Src/_basic/icons/Delete';
import DetailIcon from '@Src/_basic/icons/Detail';
import EditIcon from '@Src/_basic/icons/Edit';
import { MaterialReceiptsData } from '@Src/_basic/object/MaterialReceiptsType';
import { useApi } from '@Src/redux/api/apiAction';
import { ModalType } from '../../screens/MaterialReceiptsScreen';
import DiffColumnFormatter from './columnFormatter/DiffColumnFormatter';

interface Props {
  receiptData: MaterialReceiptsData[];
  handleModalOpen: (data: MaterialReceiptsData, modal: ModalType) => void;
}

export default function MaterialsReceiptsTable({ receiptData, handleModalOpen }: any) {
  const { actionLoading } = useApi();

  return (
    <YatungTable
      loading={actionLoading}
      data={receiptData}
      columns={[
        {
          text: '車號',
          dataField: 'plateNumber',
        },
        {
          text: '建單時間',
          dataField: 'createTime',
          formatter: ({ row }) => {
            return <>{moment(row.createTime).format('YYYY/MM/DD HH:mm')}</>;
          },
        },
        {
          text: '結單時間',
          dataField: 'receiptCompleteTime',
          formatter: ({ row }) => {
            return <>{moment(row.receiptCompleteTime).format('YYYY/MM/DD HH:mm')}</>;
          },
        },
        {
          text: '供應商',
          dataField: 'supplier',
        },
        {
          text: '規格',
          dataField: 'specification',
        },
        {
          text: '來源',
          dataField: 'source',
        },
        {
          text: '料庫',
          dataField: 'storage',
        },
        {
          text: '淨重',
          dataField: 'emptyWeight',
          formatter: ({ row }) => {
            return <>{row.grossWeight - row.emptyWeight}</>;
          },
        },
        {
          text: '差異量',
          dataField: 'diff',
          formatter: DiffColumnFormatter,
        },
        {
          text: '動作',
          dataField: 'action',
          formatter: ({ row }) => {
            return (
              <Stack direction="row" spacing={1}>
                <YatungButton
                  text="詳細"
                  startIcon={<DetailIcon />}
                  color="yellow"
                  onClick={() => handleModalOpen(row, 'DETAIL')}
                />
                <YatungButton
                  text="編輯"
                  startIcon={<EditIcon />}
                  color="blue"
                  onClick={() => handleModalOpen(row, 'UPDATE')}
                />
                <YatungButton
                  text="刪除"
                  startIcon={<DeteleIcon />}
                  color="red"
                  onClick={() => handleModalOpen(row, 'DELETE')}
                />
              </Stack>
            );
          },
        },
      ]}
    />
  );
}
