import { Box, Typography, styled } from '@mui/material';
import React from 'react';

import YatungWindow from '@Src/_basic/components/YatungWindow';
import MonthlyReportIcon from '@Src/_basic/icons/MonthlyReport';
import { FileData } from '@Src/_basic/object/ProductionHistoryType';
import TextLine from '../../TextLine';

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

interface Props {
  reports: Array<FileData>;
  onEmptyDisplay: string;
}

export default function Reports(props: Props) {
  // TODO using redux & i18n & virtualized

  return (
    <>
      {props.reports.length > 0 ? (
        <Box sx={{ padding: '12px 16px', height: '100%' }}>
          <Title>原料檢測報告</Title>
          <Box sx={{ pl: '50px', mt: '16px', height: `calc(100% - 16px - 24px)`, width: '100%' }}>
            <YatungWindow
              className="scroll-bar"
              data={props.reports}
              renderItem={(item: any) => {
                return (
                  <Box sx={{ py: '5px' }}>
                    <a href={item.download} style={{ textDecoration: 'none', userSelect: 'none' }}>
                      <TextLine icon={<MonthlyReportIcon stroke="#002F8C" />} content={item.uploadFileName} />
                    </a>
                  </Box>
                );
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', opacity: 0.3 }}>
          <Typography variant="h1">{props.onEmptyDisplay}</Typography>
        </Box>
      )}
    </>
  );
}
