import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

export default function FurnaceStoneBigIcon(props: SvgIconProps) {
  return (
    <svg {...props} width="69" height="39" viewBox="0 0 69 39" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2552 9.53455C33.9427 9.53455 34.5001 8.82309 34.5001 7.94545C34.5001 7.06782 33.9427 6.35636 33.2552 6.35636C32.5676 6.35636 32.0103 7.06782 32.0103 7.94545C32.0103 8.82309 32.5676 9.53455 33.2552 9.53455ZM33.2552 8.74C33.599 8.74 33.8776 8.38427 33.8776 7.94545C33.8776 7.50664 33.599 7.15091 33.2552 7.15091C32.9114 7.15091 32.6327 7.50664 32.6327 7.94545C32.6327 8.38427 32.9114 8.74 33.2552 8.74Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1022 25.4255C42.5086 25.4255 44.4594 22.9353 44.4594 19.8636C44.4594 16.7919 42.5086 14.3018 40.1022 14.3018C37.6958 14.3018 35.745 16.7919 35.745 19.8636C35.745 22.9353 37.6958 25.4255 40.1022 25.4255ZM40.1022 24.6309C42.1648 24.6309 43.8369 22.4965 43.8369 19.8636C43.8369 17.2307 42.1648 15.0964 40.1022 15.0964C38.0396 15.0964 36.3675 17.2307 36.3675 19.8636C36.3675 22.4965 38.0396 24.6309 40.1022 24.6309Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.6327 34.96C34.3516 34.96 35.745 33.1814 35.745 30.9873C35.745 28.7932 34.3516 27.0145 32.6327 27.0145C30.9139 27.0145 29.5204 28.7932 29.5204 30.9873C29.5204 33.1814 30.9139 34.96 32.6327 34.96ZM32.6327 34.1655C34.0078 34.1655 35.1226 32.7425 35.1226 30.9873C35.1226 29.232 34.0078 27.8091 32.6327 27.8091C31.2576 27.8091 30.1429 29.232 30.1429 30.9873C30.1429 32.7425 31.2576 34.1655 32.6327 34.1655Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0306 18.2745C27.0306 19.591 25.9159 20.6582 24.5408 20.6582C23.1657 20.6582 22.051 19.591 22.051 18.2745C22.051 16.9581 23.1657 15.8909 24.5408 15.8909C25.9159 15.8909 27.0306 16.9581 27.0306 18.2745ZM26.4082 18.2745C26.4082 18.6266 26.2606 19.0059 25.9279 19.3244C25.595 19.6431 25.1067 19.8636 24.5408 19.8636C23.9749 19.8636 23.4866 19.6431 23.1537 19.3244C22.821 19.0059 22.6734 18.6266 22.6734 18.2745C22.6734 17.9225 22.821 17.5432 23.1537 17.2247C23.4866 16.906 23.9749 16.6855 24.5408 16.6855C25.1067 16.6855 25.595 16.906 25.9279 17.2247C26.2606 17.5432 26.4082 17.9225 26.4082 18.2745Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7141 30.1927C13.7455 30.1927 14.5815 29.1255 14.5815 27.8091C14.5815 26.4926 13.7455 25.4255 12.7141 25.4255C11.6828 25.4255 10.8468 26.4926 10.8468 27.8091C10.8468 29.1255 11.6828 30.1927 12.7141 30.1927ZM12.7141 29.3982C13.4017 29.3982 13.9591 28.6867 13.9591 27.8091C13.9591 26.9315 13.4017 26.22 12.7141 26.22C12.0266 26.22 11.4692 26.9315 11.4692 27.8091C11.4692 28.6867 12.0266 29.3982 12.7141 29.3982Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4187 28.6036C54.4187 29.4813 53.8613 30.1927 53.1738 30.1927C52.4862 30.1927 51.9289 29.4813 51.9289 28.6036C51.9289 27.726 52.4862 27.0145 53.1738 27.0145C53.8613 27.0145 54.4187 27.726 54.4187 28.6036ZM53.7962 28.6036C53.7962 29.0425 53.5175 29.3982 53.1738 29.3982C52.83 29.3982 52.5513 29.0425 52.5513 28.6036C52.5513 28.1648 52.83 27.8091 53.1738 27.8091C53.5175 27.8091 53.7962 28.1648 53.7962 28.6036Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.9189 33.3709H69L55.2132 20.0353C55.5015 19.5047 55.6635 18.9069 55.6635 18.2745C55.6635 16.0805 53.7127 14.3018 51.3063 14.3018C50.7042 14.3018 50.1306 14.4132 49.609 14.6145L34.5 0L21.9313 12.1573C20.9122 11.5066 19.6642 11.1236 18.3161 11.1236C14.8784 11.1236 12.0916 13.6137 12.0916 16.6855C12.0916 17.801 12.4591 18.8398 13.0919 19.7105C13.2322 19.9035 13.3855 20.0882 13.5507 20.2637L0 33.3709H8.10751C9.016 34.3358 10.4624 34.96 12.0916 34.96C13.7208 34.96 15.1672 34.3358 16.0757 33.3709H25.8487C26.2801 36.0662 28.8744 38.1382 32.0102 38.1382C35.146 38.1382 37.7402 36.0662 38.1717 33.3709H47.4488C48.0945 34.3209 49.3015 34.96 50.6838 34.96C52.0662 34.96 53.2732 34.3209 53.9189 33.3709ZM54.7017 19.5826L54.3617 20.2086L67.1479 32.5764H53.6349L53.4488 32.8502C52.9514 33.5819 51.9376 34.1655 50.6838 34.1655C49.4301 34.1655 48.4163 33.5819 47.9189 32.8502L47.7328 32.5764H37.6636L37.5618 33.2118C37.3899 34.286 36.772 35.3128 35.7802 36.0876C34.7926 36.8589 33.4746 37.3436 32.0102 37.3436C30.5457 37.3436 29.2277 36.8589 28.2402 36.0876C27.2483 35.3128 26.6305 34.286 26.4585 33.2118L26.3568 32.5764H15.8506L15.6775 32.7601C14.9052 33.5804 13.6105 34.1655 12.0916 34.1655C10.5727 34.1655 9.27794 33.5804 8.50564 32.7601L8.33261 32.5764H1.85206L14.5512 20.2928L13.9488 19.653C13.1435 18.7976 12.714 17.7572 12.714 16.6855C12.714 15.48 13.26 14.3075 14.2717 13.4036C15.2813 12.5014 16.7093 11.9182 18.3161 11.9182C19.5741 11.9182 20.7259 12.276 21.6529 12.8679L21.9989 13.0889L34.5 0.9969L49.4857 15.4922L49.7892 15.375C50.2511 15.1967 50.7639 15.0964 51.3063 15.0964C52.3947 15.0964 53.3541 15.4998 54.0255 16.112C54.6981 16.7252 55.041 17.5007 55.041 18.2745C55.041 18.7268 54.9263 19.1691 54.7017 19.5826Z"
        fill="#7C878E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7019 26.3884C35.0457 26.3884 35.3244 26.0327 35.3244 25.5939C35.3244 25.155 35.0457 24.7993 34.7019 24.7993C34.3582 24.7993 34.0795 25.155 34.0795 25.5939C34.0795 26.0327 34.3582 26.3884 34.7019 26.3884ZM34.7019 27.1829C35.3895 27.1829 35.9469 26.4715 35.9469 25.5939C35.9469 24.7162 35.3895 24.0048 34.7019 24.0048C34.0144 24.0048 33.457 24.7162 33.457 25.5939C33.457 26.4715 34.0144 27.1829 34.7019 27.1829Z"
        fill="#7C878E"
      />
    </svg>
  );
}
