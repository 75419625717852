import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CreateV2(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18.8,9.6c0-0.8-0.2-1.6-0.5-2.3c-0.4-0.9-0.9-1.7-1.6-2.3c-0.7-0.7-1.4-1.2-2.3-1.6c-0.7-0.3-1.5-0.5-2.3-0.5l-0.4,0V10h7.2L18.8,9.6z M12.4,9.2V3.7C13,3.8,13.5,3.9,14,4.1c0.8,0.3,1.5,0.8,2.1,1.4c0.6,0.6,1.1,1.3,1.4,2.1c0.2,0.5,0.4,1.1,0.4,1.6H12.4z"/>
      <path d="M14,17.9c-0.2,0.1-0.5,0.3-0.7,0.4c-1.4,0.6-2.9,0.7-4.4,0.4c-1.5-0.3-2.8-1-3.9-2.1c-1.1-1.1-1.8-2.4-2.1-3.9c-0.3-1.5-0.1-3,0.4-4.4C4,6.9,4.9,5.8,6.2,4.9c1-0.7,2.2-1.1,3.4-1.2V12h8.3c-0.1,0.6-0.2,1.1-0.4,1.7c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0.2-0.7,0.3-1.3,0.4-2l0-0.4h-8.4V2.8l-0.4,0C8.5,2.9,7,3.4,5.7,4.3C4.3,5.2,3.3,6.5,2.6,8C2,9.6,1.8,11.2,2.2,12.9c0.3,1.6,1.1,3.1,2.3,4.3c1.2,1.2,2.7,2,4.3,2.3c0.5,0.1,1.1,0.2,1.6,0.2c1.1,0,2.2-0.2,3.2-0.6c0.2-0.1,0.4-0.2,0.6-0.3C14.1,18.4,14,18.2,14,17.9z"/>
      <path d="M18,14c-1.9,0-3.5,1.6-3.5,3.5S16.1,21,18,21s3.5-1.6,3.5-3.5S19.9,14,18,14z M20.1,17.7h-1.7v1.9h-0.7v-1.9h-1.8V17h1.8v-1.6h0.7V17h1.7V17.7z"/>
    </SvgIcon>
  );
}
