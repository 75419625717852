import {
  ChildrenTypography,
  TableHeaderTitle,
  TestCardContainer,
  TestVerifyResultStyles,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CalcHelper';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema, notRequiredNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  FineAggSgWabs,
  ImportFineAggSgWabs,
  PutFineAggSgWabsRequest,
} from '@Src/_basic/object/test/sandRock/FineAggSgWabsType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { CSSProperties, ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface TableByTestProps {
  colorIndex?: number;
  colorIndexes?: Array<number>;
  style?: CSSProperties;
  list?: Array<string | undefined | React.ReactNode>;
}

const TableByTest = ({ colorIndex, colorIndexes, style, list }: TableByTestProps) => {
  const styleColors = ['#5A60AE', '#81B29A', '#E07A5F', '#6DC3F6'];
  const verifyResultColor = ['#FFFFFF', '#70C030', '#FF0002'];

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center" py={1} sx={style}>
      {list?.map((value, index) => (
        <ChildrenTypography
          key={index}
          sx={{
            ...TestVerifyResultStyles.normal,
            ...(colorIndex !== undefined && { color: styleColors[colorIndex] }),
            ...(colorIndexes !== undefined &&
              colorIndexes[index] !== 0 && { color: verifyResultColor[colorIndexes[index]] }),
          }}
        >
          {typeof value === 'string' ? formatNumber(value) : typeof value === undefined ? '' : value}
        </ChildrenTypography>
      ))}
    </Stack>
  );
};

interface Props {
  testDetail: FineAggSgWabs;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  firstSpecificGravitySurfaceDryWeight: basicNumberSchema,
  firstWaterAbsorptionSurfaceDryWeight: basicNumberSchema,
  firstOvenDryWeight: basicNumberSchema,
  firstPycnometerAndWaterWeight: basicNumberSchema,
  firstSDWPycnometerWaterWeight: basicNumberSchema,

  secondSpecificGravitySurfaceDryWeight: notRequiredNumberSchema,
  secondWaterAbsorptionSurfaceDryWeight: notRequiredNumberSchema,
  secondOvenDryWeight: notRequiredNumberSchema,
  secondPycnometerAndWaterWeight: notRequiredNumberSchema,
  secondSDWPycnometerWaterWeight: notRequiredNumberSchema,

  thirdSpecificGravitySurfaceDryWeight: notRequiredNumberSchema,
  thirdWaterAbsorptionSurfaceDryWeight: notRequiredNumberSchema,
  thirdOvenDryWeight: notRequiredNumberSchema,
  thirdPycnometerAndWaterWeight: notRequiredNumberSchema,
  thirdSDWPycnometerWaterWeight: notRequiredNumberSchema,

  fourthSpecificGravitySurfaceDryWeight: notRequiredNumberSchema,
  fourthWaterAbsorptionSurfaceDryWeight: notRequiredNumberSchema,
  fourthOvenDryWeight: notRequiredNumberSchema,
  fourthPycnometerAndWaterWeight: notRequiredNumberSchema,
  fourthSDWPycnometerWaterWeight: notRequiredNumberSchema,
});

function ExportFineAggSgWabsTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const initialValues = {
    firstSpecificGravitySurfaceDryWeight: testDetail?.firstSpecificGravitySurfaceDryWeight,
    firstWaterAbsorptionSurfaceDryWeight: testDetail?.firstWaterAbsorptionSurfaceDryWeight,
    firstOvenDryWeight: testDetail?.firstOvenDryWeight,
    firstPycnometerAndWaterWeight: testDetail?.firstPycnometerAndWaterWeight,
    firstSDWPycnometerWaterWeight: testDetail?.firstSDWPycnometerWaterWeight,

    secondSpecificGravitySurfaceDryWeight: testDetail?.secondSpecificGravitySurfaceDryWeight,
    secondWaterAbsorptionSurfaceDryWeight: testDetail?.secondWaterAbsorptionSurfaceDryWeight,
    secondOvenDryWeight: testDetail?.secondOvenDryWeight,
    secondPycnometerAndWaterWeight: testDetail?.secondPycnometerAndWaterWeight,
    secondSDWPycnometerWaterWeight: testDetail?.secondSDWPycnometerWaterWeight,

    thirdSpecificGravitySurfaceDryWeight: testDetail?.thirdSpecificGravitySurfaceDryWeight,
    thirdWaterAbsorptionSurfaceDryWeight: testDetail?.thirdWaterAbsorptionSurfaceDryWeight,
    thirdOvenDryWeight: testDetail?.thirdOvenDryWeight,
    thirdPycnometerAndWaterWeight: testDetail?.thirdPycnometerAndWaterWeight,
    thirdSDWPycnometerWaterWeight: testDetail?.thirdSDWPycnometerWaterWeight,

    fourthSpecificGravitySurfaceDryWeight: testDetail?.fourthSpecificGravitySurfaceDryWeight,
    fourthWaterAbsorptionSurfaceDryWeight: testDetail?.fourthWaterAbsorptionSurfaceDryWeight,
    fourthOvenDryWeight: testDetail?.fourthOvenDryWeight,
    fourthPycnometerAndWaterWeight: testDetail?.fourthPycnometerAndWaterWeight,
    fourthSDWPycnometerWaterWeight: testDetail?.fourthSDWPycnometerWaterWeight,
  };
  const [edit, setEdit] = useState<boolean>(false);

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText: Array<string> = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportFineAggSgWabs = {
        firstSpecificGravitySurfaceDryWeight: checkIsNaN(+values.firstSpecificGravitySurfaceDryWeight),
        firstWaterAbsorptionSurfaceDryWeight: checkIsNaN(+values.firstWaterAbsorptionSurfaceDryWeight),
        firstOvenDryWeight: checkIsNaN(+values.firstOvenDryWeight),
        firstPycnometerAndWaterWeight: checkIsNaN(+values.firstPycnometerAndWaterWeight),
        firstSDWPycnometerWaterWeight: checkIsNaN(+values.firstSDWPycnometerWaterWeight),

        ...(values.secondSpecificGravitySurfaceDryWeight &&
          values.secondWaterAbsorptionSurfaceDryWeight &&
          values.secondOvenDryWeight &&
          values.secondPycnometerAndWaterWeight &&
          values.secondSDWPycnometerWaterWeight && {
            secondSpecificGravitySurfaceDryWeight: checkIsNaN(+values.secondSpecificGravitySurfaceDryWeight),
            secondWaterAbsorptionSurfaceDryWeight: checkIsNaN(+values.secondWaterAbsorptionSurfaceDryWeight),
            secondOvenDryWeight: checkIsNaN(+values.secondOvenDryWeight),
            secondPycnometerAndWaterWeight: checkIsNaN(+values.secondPycnometerAndWaterWeight),
            secondSDWPycnometerWaterWeight: checkIsNaN(+values.secondSDWPycnometerWaterWeight),
          }),

        ...(values.thirdSpecificGravitySurfaceDryWeight &&
          values.thirdWaterAbsorptionSurfaceDryWeight &&
          values.thirdOvenDryWeight &&
          values.thirdPycnometerAndWaterWeight &&
          values.thirdSDWPycnometerWaterWeight && {
            thirdSpecificGravitySurfaceDryWeight: checkIsNaN(+values.thirdSpecificGravitySurfaceDryWeight),
            thirdWaterAbsorptionSurfaceDryWeight: checkIsNaN(+values.thirdWaterAbsorptionSurfaceDryWeight),
            thirdOvenDryWeight: checkIsNaN(+values.thirdOvenDryWeight),
            thirdPycnometerAndWaterWeight: checkIsNaN(+values.thirdPycnometerAndWaterWeight),
            thirdSDWPycnometerWaterWeight: checkIsNaN(+values.thirdSDWPycnometerWaterWeight),
          }),

        ...(values.fourthSpecificGravitySurfaceDryWeight &&
          values.fourthWaterAbsorptionSurfaceDryWeight &&
          values.fourthOvenDryWeight &&
          values.fourthPycnometerAndWaterWeight &&
          values.fourthSDWPycnometerWaterWeight && {
            fourthSpecificGravitySurfaceDryWeight: checkIsNaN(+values.fourthSpecificGravitySurfaceDryWeight),
            fourthWaterAbsorptionSurfaceDryWeight: checkIsNaN(+values.fourthWaterAbsorptionSurfaceDryWeight),
            fourthOvenDryWeight: checkIsNaN(+values.fourthOvenDryWeight),
            fourthPycnometerAndWaterWeight: checkIsNaN(+values.fourthPycnometerAndWaterWeight),
            fourthSDWPycnometerWaterWeight: checkIsNaN(+values.fourthSDWPycnometerWaterWeight),
          }),
      };

      TestApi.putTest<PutFineAggSgWabsRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const showFirstTable = useMemo(
    () => (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" py={2}>
          <TableHeaderTitle>{`${i18T(
            `TEST.FINE_AGG_SG_WABS.SPECIFIC_GRAVITY_SURFACE_DRY_WEIGHT`,
          )}(g)`}</TableHeaderTitle>
          <TableHeaderTitle>{`${i18T(
            `TEST.FINE_AGG_SG_WABS.WATER_ABSORPTION_SURFACE_DRY_WEIGHT`,
          )}(g)`}</TableHeaderTitle>
        </Stack>

        {edit ? (
          <Stack>
            <TableByTest
              colorIndex={0}
              list={[
                <Stack key={'firstSpecificGravitySurfaceDryWeight'} flexDirection="row" pr={10}>
                  <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_SPECIFIC_GRAVITY_SURFACE_DRY_WEIGHT`, {
                    number: 1,
                  })}(g)`}</ChildrenTypography>
                  <LabelInput
                    valueWidth="150px"
                    value={formik.values.firstSpecificGravitySurfaceDryWeight}
                    isError={
                      !!(
                        formik.touched.firstSpecificGravitySurfaceDryWeight &&
                        formik.errors.firstSpecificGravitySurfaceDryWeight
                      )
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('firstSpecificGravitySurfaceDryWeight', e.target.value)
                    }
                  />
                </Stack>,
                <Stack key={'firstWaterAbsorptionSurfaceDryWeight'} flexDirection="row" pr={10}>
                  <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_WATER_ABSORPTION_SURFACE_DRY_WEIGHT`, {
                    number: 1,
                  })}(g)`}</ChildrenTypography>
                  <LabelInput
                    valueWidth="150px"
                    value={formik.values.firstWaterAbsorptionSurfaceDryWeight}
                    isError={
                      !!(
                        formik.touched.firstWaterAbsorptionSurfaceDryWeight &&
                        formik.errors.firstWaterAbsorptionSurfaceDryWeight
                      )
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('firstWaterAbsorptionSurfaceDryWeight', e.target.value)
                    }
                  />
                </Stack>,
              ]}
            />

            {(testDetail?.secondSpecificGravitySurfaceDryWeight ||
              testDetail?.secondSpecificGravitySurfaceDryWeight === 0) &&
              (testDetail?.secondWaterAbsorptionSurfaceDryWeight ||
                testDetail?.secondWaterAbsorptionSurfaceDryWeight === 0) && (
                <TableByTest
                  colorIndex={1}
                  list={[
                    <Stack key={'firstSpecificGravitySurfaceDryWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_SPECIFIC_GRAVITY_SURFACE_DRY_WEIGHT`, {
                        number: 2,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.secondSpecificGravitySurfaceDryWeight}
                        isError={
                          !!(
                            formik.touched.secondSpecificGravitySurfaceDryWeight &&
                            formik.errors.secondSpecificGravitySurfaceDryWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('secondSpecificGravitySurfaceDryWeight', e.target.value)
                        }
                      />
                    </Stack>,
                    <Stack key={'secondWaterAbsorptionSurfaceDryWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_WATER_ABSORPTION_SURFACE_DRY_WEIGHT`, {
                        number: 2,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.secondWaterAbsorptionSurfaceDryWeight}
                        isError={
                          !!(
                            formik.touched.secondWaterAbsorptionSurfaceDryWeight &&
                            formik.errors.secondWaterAbsorptionSurfaceDryWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('secondWaterAbsorptionSurfaceDryWeight', e.target.value)
                        }
                      />
                    </Stack>,
                  ]}
                />
              )}

            {(testDetail?.thirdSpecificGravitySurfaceDryWeight ||
              testDetail?.thirdSpecificGravitySurfaceDryWeight === 0) &&
              (testDetail?.thirdWaterAbsorptionSurfaceDryWeight ||
                testDetail?.thirdWaterAbsorptionSurfaceDryWeight === 0) && (
                <TableByTest
                  colorIndex={2}
                  list={[
                    <Stack key={'thirdSpecificGravitySurfaceDryWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_SPECIFIC_GRAVITY_SURFACE_DRY_WEIGHT`, {
                        number: 3,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.thirdSpecificGravitySurfaceDryWeight}
                        isError={
                          !!(
                            formik.touched.thirdSpecificGravitySurfaceDryWeight &&
                            formik.errors.thirdSpecificGravitySurfaceDryWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('thirdSpecificGravitySurfaceDryWeight', e.target.value)
                        }
                      />
                    </Stack>,
                    <Stack key={'thirdWaterAbsorptionSurfaceDryWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_WATER_ABSORPTION_SURFACE_DRY_WEIGHT`, {
                        number: 3,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.thirdWaterAbsorptionSurfaceDryWeight}
                        isError={
                          !!(
                            formik.touched.thirdWaterAbsorptionSurfaceDryWeight &&
                            formik.errors.thirdWaterAbsorptionSurfaceDryWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('thirdWaterAbsorptionSurfaceDryWeight', e.target.value)
                        }
                      />
                    </Stack>,
                  ]}
                />
              )}

            {(testDetail?.fourthSpecificGravitySurfaceDryWeight ||
              testDetail?.fourthSpecificGravitySurfaceDryWeight === 0) &&
              (testDetail?.fourthWaterAbsorptionSurfaceDryWeight ||
                testDetail?.fourthWaterAbsorptionSurfaceDryWeight === 0) && (
                <TableByTest
                  colorIndex={3}
                  list={[
                    <Stack key={'fourthSpecificGravitySurfaceDryWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_SPECIFIC_GRAVITY_SURFACE_DRY_WEIGHT`, {
                        number: 4,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.fourthSpecificGravitySurfaceDryWeight}
                        isError={
                          !!(
                            formik.touched.fourthSpecificGravitySurfaceDryWeight &&
                            formik.errors.fourthSpecificGravitySurfaceDryWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('fourthSpecificGravitySurfaceDryWeight', e.target.value)
                        }
                      />
                    </Stack>,
                    <Stack key={'fourthWaterAbsorptionSurfaceDryWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_WATER_ABSORPTION_SURFACE_DRY_WEIGHT`, {
                        number: 4,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.fourthWaterAbsorptionSurfaceDryWeight}
                        isError={
                          !!(
                            formik.touched.fourthWaterAbsorptionSurfaceDryWeight &&
                            formik.errors.fourthWaterAbsorptionSurfaceDryWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('fourthWaterAbsorptionSurfaceDryWeight', e.target.value)
                        }
                      />
                    </Stack>,
                  ]}
                />
              )}
          </Stack>
        ) : (
          <Stack>
            <TableByTest
              colorIndex={0}
              list={[
                formatNumber(checkIsNaN(testDetail?.firstSpecificGravitySurfaceDryWeight)?.toFixed(2) ?? 0),
                formatNumber(checkIsNaN(testDetail?.firstWaterAbsorptionSurfaceDryWeight)?.toFixed(2) ?? 0),
              ]}
            />

            {(testDetail?.secondSpecificGravitySurfaceDryWeight ||
              testDetail?.secondSpecificGravitySurfaceDryWeight === 0) &&
              (testDetail?.secondWaterAbsorptionSurfaceDryWeight ||
                testDetail?.secondWaterAbsorptionSurfaceDryWeight === 0) && (
                <TableByTest
                  colorIndex={1}
                  list={[
                    formatNumber(checkIsNaN(testDetail?.secondSpecificGravitySurfaceDryWeight)?.toFixed(1)),
                    formatNumber(checkIsNaN(testDetail?.secondWaterAbsorptionSurfaceDryWeight)?.toFixed(1)),
                  ]}
                />
              )}

            {(testDetail?.thirdSpecificGravitySurfaceDryWeight ||
              testDetail?.thirdSpecificGravitySurfaceDryWeight === 0) &&
              (testDetail?.thirdWaterAbsorptionSurfaceDryWeight ||
                testDetail?.thirdWaterAbsorptionSurfaceDryWeight === 0) && (
                <TableByTest
                  colorIndex={2}
                  list={[
                    formatNumber(checkIsNaN(testDetail?.thirdSpecificGravitySurfaceDryWeight)?.toFixed(1)),
                    formatNumber(checkIsNaN(testDetail?.thirdWaterAbsorptionSurfaceDryWeight)?.toFixed(1)),
                  ]}
                />
              )}

            {(testDetail?.fourthSpecificGravitySurfaceDryWeight ||
              testDetail?.fourthSpecificGravitySurfaceDryWeight === 0) &&
              (testDetail?.fourthWaterAbsorptionSurfaceDryWeight ||
                testDetail?.fourthWaterAbsorptionSurfaceDryWeight === 0) && (
                <TableByTest
                  colorIndex={3}
                  list={[
                    formatNumber(checkIsNaN(testDetail?.fourthSpecificGravitySurfaceDryWeight)?.toFixed(1)),
                    formatNumber(checkIsNaN(testDetail?.fourthWaterAbsorptionSurfaceDryWeight)?.toFixed(1)),
                  ]}
                />
              )}

            <TableByTest
              style={{ borderTop: '1px solid gray' }}
              list={[
                formatNumber(checkIsNaN(testDetail?.averageSpecificGravitySurfaceDryWeight)?.toFixed(2)),
                formatNumber(checkIsNaN(testDetail?.averageWaterAbsorptionSurfaceDryWeight)?.toFixed(2)),
              ]}
            />
          </Stack>
        )}
      </>
    ),
    [
      i18T,
      testDetail?.firstSpecificGravitySurfaceDryWeight,
      testDetail?.secondSpecificGravitySurfaceDryWeight,
      testDetail?.thirdSpecificGravitySurfaceDryWeight,
      testDetail?.fourthSpecificGravitySurfaceDryWeight,
      testDetail?.firstWaterAbsorptionSurfaceDryWeight,
      testDetail?.secondWaterAbsorptionSurfaceDryWeight,
      testDetail?.thirdWaterAbsorptionSurfaceDryWeight,
      testDetail?.fourthWaterAbsorptionSurfaceDryWeight,
      testDetail?.averageSpecificGravitySurfaceDryWeight,
      testDetail?.averageWaterAbsorptionSurfaceDryWeight,
      edit,
      formik,
    ],
  );

  const showSecondTable = useMemo(
    () => (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" py={2}>
          <TableHeaderTitle>{`${i18T(`TEST.FINE_AGG_SG_WABS.OVEN_DRY_WEIGHT`)}(g)`}</TableHeaderTitle>
          <TableHeaderTitle>{`${i18T(`TEST.FINE_AGG_SG_WABS.PYCNOMETER_WATER_WEIGHT`)}(g)`}</TableHeaderTitle>
          <TableHeaderTitle>{`${i18T(`TEST.FINE_AGG_SG_WABS.SDW_PYCNOMETER_WATER_WEIGHT`)}(g)`}</TableHeaderTitle>
        </Stack>

        {edit ? (
          <Stack>
            <TableByTest
              colorIndex={0}
              list={[
                <Stack key={'firstOvenDryWeight'} flexDirection="row" pr={10}>
                  <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_OVEN_DRY_WEIGHT`, {
                    number: 1,
                  })}(g)`}</ChildrenTypography>
                  <LabelInput
                    valueWidth="150px"
                    value={formik.values.firstOvenDryWeight}
                    isError={!!(formik.touched.firstOvenDryWeight && formik.errors.firstOvenDryWeight)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('firstOvenDryWeight', e.target.value)
                    }
                  />
                </Stack>,
                <Stack key={'firstPycnometerAndWaterWeight'} flexDirection="row" pr={10}>
                  <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_PYCNOMETER_WATER_WEIGHT`, {
                    number: 1,
                  })}(g)`}</ChildrenTypography>
                  <LabelInput
                    valueWidth="150px"
                    value={formik.values.firstPycnometerAndWaterWeight}
                    isError={
                      !!(formik.touched.firstPycnometerAndWaterWeight && formik.errors.firstPycnometerAndWaterWeight)
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('firstPycnometerAndWaterWeight', e.target.value)
                    }
                  />
                </Stack>,
                <Stack key={'firstSDWPycnometerWaterWeight'} flexDirection="row" pr={10}>
                  <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_SDW_PYCNOMETER_WATER_WEIGHT`, {
                    number: 1,
                  })}(g)`}</ChildrenTypography>
                  <LabelInput
                    valueWidth="150px"
                    value={formik.values.firstSDWPycnometerWaterWeight}
                    isError={
                      !!(formik.touched.firstSDWPycnometerWaterWeight && formik.errors.firstSDWPycnometerWaterWeight)
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('firstSDWPycnometerWaterWeight', e.target.value)
                    }
                  />
                </Stack>,
              ]}
            />

            {(testDetail?.secondOvenDryWeight || testDetail?.secondOvenDryWeight === 0) &&
              (testDetail?.secondPycnometerAndWaterWeight || testDetail?.secondPycnometerAndWaterWeight === 0) &&
              (testDetail?.secondSDWPycnometerWaterWeight || testDetail?.secondSDWPycnometerWaterWeight === 0) && (
                <TableByTest
                  colorIndex={1}
                  list={[
                    <Stack key={'secondOvenDryWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_OVEN_DRY_WEIGHT`, {
                        number: 2,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.secondOvenDryWeight}
                        isError={!!(formik.touched.secondOvenDryWeight && formik.errors.secondOvenDryWeight)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('secondOvenDryWeight', e.target.value)
                        }
                      />
                    </Stack>,
                    <Stack key={'secondPycnometerAndWaterWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_PYCNOMETER_WATER_WEIGHT`, {
                        number: 2,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.secondPycnometerAndWaterWeight}
                        isError={
                          !!(
                            formik.touched.secondPycnometerAndWaterWeight &&
                            formik.errors.secondPycnometerAndWaterWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('secondPycnometerAndWaterWeight', e.target.value)
                        }
                      />
                    </Stack>,
                    <Stack key={'secondSDWPycnometerWaterWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_SDW_PYCNOMETER_WATER_WEIGHT`, {
                        number: 2,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.secondSDWPycnometerWaterWeight}
                        isError={
                          !!(
                            formik.touched.secondSDWPycnometerWaterWeight &&
                            formik.errors.secondSDWPycnometerWaterWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('secondSDWPycnometerWaterWeight', e.target.value)
                        }
                      />
                    </Stack>,
                  ]}
                />
              )}

            {(testDetail?.thirdOvenDryWeight || testDetail?.thirdOvenDryWeight === 0) &&
              (testDetail?.thirdPycnometerAndWaterWeight || testDetail?.thirdPycnometerAndWaterWeight === 0) &&
              (testDetail?.thirdSDWPycnometerWaterWeight || testDetail?.thirdSDWPycnometerWaterWeight === 0) && (
                <TableByTest
                  colorIndex={2}
                  list={[
                    <Stack key={'thirdOvenDryWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_OVEN_DRY_WEIGHT`, {
                        number: 3,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.thirdOvenDryWeight}
                        isError={!!(formik.touched.thirdOvenDryWeight && formik.errors.thirdOvenDryWeight)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('thirdOvenDryWeight', e.target.value)
                        }
                      />
                    </Stack>,
                    <Stack key={'thirdPycnometerAndWaterWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_PYCNOMETER_WATER_WEIGHT`, {
                        number: 3,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.thirdPycnometerAndWaterWeight}
                        isError={
                          !!(
                            formik.touched.thirdPycnometerAndWaterWeight && formik.errors.thirdPycnometerAndWaterWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('thirdPycnometerAndWaterWeight', e.target.value)
                        }
                      />
                    </Stack>,
                    <Stack key={'thirdSDWPycnometerWaterWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_SDW_PYCNOMETER_WATER_WEIGHT`, {
                        number: 3,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.thirdSDWPycnometerWaterWeight}
                        isError={
                          !!(
                            formik.touched.thirdSDWPycnometerWaterWeight && formik.errors.thirdSDWPycnometerWaterWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('thirdSDWPycnometerWaterWeight', e.target.value)
                        }
                      />
                    </Stack>,
                  ]}
                />
              )}

            {(testDetail?.fourthOvenDryWeight || testDetail?.fourthOvenDryWeight === 0) &&
              (testDetail?.fourthPycnometerAndWaterWeight || testDetail?.fourthPycnometerAndWaterWeight === 0) &&
              (testDetail?.fourthSDWPycnometerWaterWeight || testDetail?.fourthSDWPycnometerWaterWeight === 0) && (
                <TableByTest
                  colorIndex={3}
                  list={[
                    <Stack key={'fourthOvenDryWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_OVEN_DRY_WEIGHT`, {
                        number: 4,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.fourthOvenDryWeight}
                        isError={!!(formik.touched.fourthOvenDryWeight && formik.errors.fourthOvenDryWeight)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('fourthOvenDryWeight', e.target.value)
                        }
                      />
                    </Stack>,
                    <Stack key={'fourthPycnometerAndWaterWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_PYCNOMETER_WATER_WEIGHT`, {
                        number: 4,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.fourthPycnometerAndWaterWeight}
                        isError={
                          !!(
                            formik.touched.fourthPycnometerAndWaterWeight &&
                            formik.errors.fourthPycnometerAndWaterWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('fourthPycnometerAndWaterWeight', e.target.value)
                        }
                      />
                    </Stack>,
                    <Stack key={'fourthSDWPycnometerWaterWeight'} flexDirection="row" pr={10}>
                      <ChildrenTypography>{`${i18T(`TEST.FINE_AGG_SG_WABS.NUMBER_SDW_PYCNOMETER_WATER_WEIGHT`, {
                        number: 4,
                      })}(g)`}</ChildrenTypography>
                      <LabelInput
                        valueWidth="150px"
                        value={formik.values.fourthSDWPycnometerWaterWeight}
                        isError={
                          !!(
                            formik.touched.fourthSDWPycnometerWaterWeight &&
                            formik.errors.fourthSDWPycnometerWaterWeight
                          )
                        }
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          formik.setFieldValue('fourthSDWPycnometerWaterWeight', e.target.value)
                        }
                      />
                    </Stack>,
                  ]}
                />
              )}
          </Stack>
        ) : (
          <Stack>
            <TableByTest
              colorIndex={0}
              list={[
                formatNumber(checkIsNaN(testDetail?.firstOvenDryWeight)?.toFixed(1)),
                formatNumber(checkIsNaN(testDetail?.firstPycnometerAndWaterWeight)?.toFixed(1)),
                formatNumber(checkIsNaN(testDetail?.firstSDWPycnometerWaterWeight)?.toFixed(1)),
              ]}
            />

            {(testDetail?.secondOvenDryWeight || testDetail?.secondOvenDryWeight === 0) &&
              (testDetail?.secondPycnometerAndWaterWeight || testDetail?.secondPycnometerAndWaterWeight === 0) &&
              (testDetail?.secondSDWPycnometerWaterWeight || testDetail?.secondSDWPycnometerWaterWeight === 0) && (
                <TableByTest
                  colorIndex={1}
                  list={[
                    formatNumber(checkIsNaN(testDetail?.secondOvenDryWeight)?.toFixed(1)),
                    formatNumber(checkIsNaN(testDetail?.secondPycnometerAndWaterWeight)?.toFixed(1)),
                    formatNumber(checkIsNaN(testDetail?.secondSDWPycnometerWaterWeight)?.toFixed(1)),
                  ]}
                />
              )}

            {(testDetail?.thirdOvenDryWeight || testDetail?.thirdOvenDryWeight === 0) &&
              (testDetail?.thirdPycnometerAndWaterWeight || testDetail?.thirdPycnometerAndWaterWeight === 0) &&
              (testDetail?.thirdSDWPycnometerWaterWeight || testDetail?.thirdSDWPycnometerWaterWeight === 0) && (
                <TableByTest
                  colorIndex={2}
                  list={[
                    formatNumber(checkIsNaN(testDetail?.thirdOvenDryWeight)?.toFixed(1)),
                    formatNumber(checkIsNaN(testDetail?.thirdPycnometerAndWaterWeight)?.toFixed(1)),
                    formatNumber(checkIsNaN(testDetail?.thirdSDWPycnometerWaterWeight)?.toFixed(1)),
                  ]}
                />
              )}

            {(testDetail?.fourthOvenDryWeight || testDetail?.fourthOvenDryWeight === 0) &&
              (testDetail?.fourthPycnometerAndWaterWeight || testDetail?.fourthPycnometerAndWaterWeight === 0) &&
              (testDetail?.fourthSDWPycnometerWaterWeight || testDetail?.fourthSDWPycnometerWaterWeight === 0) && (
                <TableByTest
                  colorIndex={3}
                  list={[
                    formatNumber(checkIsNaN(testDetail?.fourthOvenDryWeight)?.toFixed(1)),
                    formatNumber(checkIsNaN(testDetail?.fourthPycnometerAndWaterWeight)?.toFixed(1)),
                    formatNumber(checkIsNaN(testDetail?.fourthSDWPycnometerWaterWeight)?.toFixed(1)),
                  ]}
                />
              )}

            <TableByTest
              style={{ borderTop: '1px solid gray' }}
              list={[
                formatNumber(checkIsNaN(testDetail?.averageOvenDryWeight)?.toFixed(2)),
                formatNumber(checkIsNaN(testDetail?.averagePycnometerAndWaterWeight)?.toFixed(2)),
                formatNumber(checkIsNaN(testDetail?.averageSDWPycnometerWaterWeight)?.toFixed(2)),
              ]}
            />
          </Stack>
        )}
      </>
    ),
    [
      i18T,
      testDetail?.firstOvenDryWeight,
      testDetail?.firstPycnometerAndWaterWeight,
      testDetail?.firstSDWPycnometerWaterWeight,
      testDetail?.secondOvenDryWeight,
      testDetail?.secondPycnometerAndWaterWeight,
      testDetail?.secondSDWPycnometerWaterWeight,
      testDetail?.thirdOvenDryWeight,
      testDetail?.thirdPycnometerAndWaterWeight,
      testDetail?.thirdSDWPycnometerWaterWeight,
      testDetail?.fourthOvenDryWeight,
      testDetail?.fourthPycnometerAndWaterWeight,
      testDetail?.fourthSDWPycnometerWaterWeight,
      testDetail?.averageOvenDryWeight,
      testDetail?.averagePycnometerAndWaterWeight,
      testDetail?.averageSDWPycnometerWaterWeight,
      edit,
      formik,
    ],
  );

  const showThirdTable = useMemo(
    () => (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" py={2}>
          <TableHeaderTitle>{i18T(`TEST.FINE_AGG_SG_WABS.SPECIFIC_GRAVITY`)}</TableHeaderTitle>
          <TableHeaderTitle>{i18T(`TEST.FINE_AGG_SG_WABS.WATER_ABSORPTION`)}</TableHeaderTitle>
        </Stack>

        <Stack>
          <TableByTest
            colorIndex={0}
            list={[
              formatNumber(checkIsNaN(testDetail?.firstSpecificWeight)?.toFixed(2)),
              formatNumber(checkIsNaN(testDetail?.firstWaterAbsorption)?.toFixed(2)),
            ]}
          />

          {(testDetail?.secondSpecificWeight || testDetail?.secondSpecificWeight === 0) &&
            (testDetail?.secondWaterAbsorption || testDetail?.secondWaterAbsorption === 0) &&
            (testDetail?.secondOvenDryWeight || testDetail?.secondOvenDryWeight === 0) && (
              <TableByTest
                colorIndex={1}
                list={[
                  formatNumber(checkIsNaN(testDetail?.secondSpecificWeight)?.toFixed(2)),
                  formatNumber(checkIsNaN(testDetail?.secondWaterAbsorption)?.toFixed(2)),
                ]}
              />
            )}

          {(testDetail?.thirdSpecificWeight || testDetail?.thirdSpecificWeight === 0) &&
            (testDetail?.thirdWaterAbsorption || testDetail?.thirdWaterAbsorption === 0) &&
            (testDetail?.thirdOvenDryWeight || testDetail?.thirdOvenDryWeight === 0) && (
              <TableByTest
                colorIndex={2}
                list={[
                  formatNumber(checkIsNaN(testDetail?.thirdSpecificWeight)?.toFixed(2)),
                  formatNumber(checkIsNaN(testDetail?.thirdWaterAbsorption)?.toFixed(2)),
                ]}
              />
            )}

          {(testDetail?.fourthSpecificWeight || testDetail?.fourthSpecificWeight === 0) &&
            (testDetail?.fourthWaterAbsorption || testDetail?.fourthWaterAbsorption === 0) &&
            (testDetail?.fourthOvenDryWeight || testDetail?.fourthOvenDryWeight === 0) && (
              <TableByTest
                colorIndex={3}
                list={[
                  formatNumber(checkIsNaN(testDetail?.fourthSpecificWeight)?.toFixed(2)),
                  formatNumber(checkIsNaN(testDetail?.fourthWaterAbsorption)?.toFixed(2)),
                ]}
              />
            )}

          <TableByTest
            style={{ borderTop: '1px solid gray' }}
            colorIndexes={
              testDetail?.verifyPassedResults
                ? [
                    testDetail?.verifyPassedResults.averageSpecificWeight &&
                    testDetail?.verifyCustomResults.averageWaterAbsorption.passed
                      ? 1
                      : 2,
                    testDetail?.verifyPassedResults.averageSpecificWeight &&
                    testDetail?.verifyCustomResults.averageWaterAbsorption.passed
                      ? 1
                      : 2,
                  ]
                : undefined
            }
            list={[
              formatNumber(checkIsNaN(testDetail?.averageSpecificWeight)?.toFixed(2)),
              formatNumber(checkIsNaN(testDetail?.averageWaterAbsorption)?.toFixed(2)),
            ]}
          />
        </Stack>
      </>
    ),
    [
      i18T,
      testDetail?.firstSpecificWeight,
      testDetail?.firstWaterAbsorption,
      testDetail?.secondSpecificWeight,
      testDetail?.secondWaterAbsorption,
      testDetail?.thirdSpecificWeight,
      testDetail?.thirdWaterAbsorption,
      testDetail?.fourthSpecificWeight,
      testDetail?.fourthWaterAbsorption,
      testDetail?.averageSpecificWeight,
      testDetail?.averageWaterAbsorption,
      testDetail?.verifyPassedResults,
      testDetail?.verifyCustomResults,
      testDetail?.secondOvenDryWeight,
      testDetail?.thirdOvenDryWeight,
      testDetail?.fourthOvenDryWeight,
    ],
  );

  const showFourthTable = useMemo(
    () => (
      <TestTable
        headerData={{
          title: i18T(`TEST.BASE.DETECTION`),
        }}
        rows={[
          isLegalPassed && isCustomPassed && !isCustomRejected
            ? {
                titles: verifyResultText,
                titlesStyle: [TestVerifyResultStyles.normal],
              }
            : {
                titles: verifyResultText,
                titlesStyle: [
                  isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                  isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                ],
              },
        ]}
        isShowCollapse={true}
      />
    ),
    [i18T, isLegalPassed, isCustomPassed, verifyResultText, isCustomRejected],
  );

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {showFirstTable}
        {showSecondTable}
        {!edit && showThirdTable}
      </TestCardContainer>
      <TestCardContainer>{showFourthTable}</TestCardContainer>
    </Stack>
  );
}

export default memo(ExportFineAggSgWabsTable);
