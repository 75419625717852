import { SignatureData } from '@Src/_basic/object/SignatureType';

const table: Array<SignatureData> = [
  {
    id: 1,
    createTime: new Date('2023/07/04').getTime(),
    title: '',
    cementingMaterial: [],
    chemicalAdmixture: [],
    sandStone: [],
  },
];

export default table;
