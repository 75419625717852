import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DeliveryInformationCar(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="46" height="46" viewBox="0 0 46 46" fill="none">
      <svg xmlns="http://www.w3.org/2000/svg" width="161" height="91" viewBox="0 0 161 91" fill="none">
        <g filter="url(#filter0_d_305_48623)">
          <path
            d="M108.781 59.2013L123.588 69.5699H146.357V42.6117L138.26 22.9875L108.781 21.4385V59.2013Z"
            fill="#EDEFF1"
          />
          <path d="M108.781 19.8076H136.91L138.268 22.9953H108.781V19.8076Z" fill="white" />
          <path d="M133.531 66.0342H147.045V72.2383H133.531V66.0342Z" fill="white" />
          <path d="M119.945 44.5557H125.724V46.4812H119.945V44.5557Z" fill="#B3B3B3" />
          <path d="M9.31996 55.7461L55.3836 55.7492L60.6309 63.0762H4.92188L9.31996 55.7461Z" fill="#959292" />
          <path
            d="M146.153 38.8412H147.048C147.857 38.8412 148.514 38.182 148.514 37.3726V31.5126C148.514 30.7007 147.857 30.0439 147.048 30.0439H146.039V33.6404L140.664 38.3323L140.984 38.7297L146.039 34.2923V38.2766L142.528 41.4319C142.47 41.478 142.436 41.5458 142.431 41.6137C142.426 41.6743 142.445 41.7373 142.487 41.7906C142.574 41.8997 142.734 41.9191 142.845 41.8318L146.153 38.8412Z"
            fill="#B3B3B3"
          />
          <path d="M134.766 66.0342H138.488L140.349 72.2383H136.627L134.766 66.0342Z" fill="#B3B3B3" />
          <path d="M140.969 66.0342H144.691L146.552 72.2383H142.83L140.969 66.0342Z" fill="#B3B3B3" />
          <path d="M119.953 39.1363L119.986 26.1914H135.962L144.658 42.13L119.953 39.1363Z" fill="#B3B3B3" />
          <path d="M126.303 27.7842L120.656 33.3627V38.1442L127.009 38.9412L134.773 27.7842H126.303Z" fill="white" />
          <path d="M136.891 30.175L131.244 39.7381L139.008 40.535L140.42 36.5504L136.891 30.175Z" fill="white" />
          <path
            d="M102.258 46.1918C100.702 45.2371 98.2946 43.7148 98.2946 43.7148L97.6328 47.378L101.408 49.5898L98.2946 62.0442H107.212V49.1641C107.212 49.1641 104.181 47.3719 102.258 46.1918Z"
            fill="#B3B3B3"
          />
          <circle cx="104.241" cy="51.1451" r="0.990775" fill="#D9D9D9" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.488 28.3792L89.5638 14.6917L65.725 8.20827L65.7246 8.20964L57.2714 5.91062L35.7459 9.14099L28.2168 36.8248L45.1411 50.5123L68.9799 56.9958L68.9803 56.9944L77.4335 59.2934L98.959 56.0631L106.488 28.3792Z"
            fill="#EDEFF1"
          />
          <path
            d="M30.5234 28.3672L28.2229 36.8261L45.1472 50.5137L77.445 59.2977L98.9704 56.0673L101.271 47.6084L30.5234 28.3672Z"
            fill="#B3B3B3"
          />
          <path d="M26.4152 9.19759L35.5666 5.89886L27.2251 36.6652L19.5335 34.5799L26.4152 9.19759Z" fill="#D9D9D9" />
          <rect
            x="35.9844"
            y="4.36035"
            width="3.18771"
            height="33.471"
            transform="rotate(15.1696 35.9844 4.36035)"
            fill="#B3B3B3"
          />
          <path d="M37.0625 38.139L16.3424 32.5605L16.3424 53.2807H37.0625V38.139Z" fill="#D9D9D9" />
          <rect x="22.5625" y="40.5234" width="12.4321" height="3.18771" fill="#EDEFF1" />
          <path d="M34.9946 40.5234H22.5625V42.1173H32.9226V43.7111H34.9946V40.5234Z" fill="#B3B3B3" />
          <rect x="22.5625" y="45.3047" width="12.4321" height="6.37542" fill="#EDEFF1" />
          <path d="M34.9946 45.3047H22.5625V46.8985H32.9226V51.6801H34.9946V45.3047Z" fill="#B3B3B3" />
          <path
            d="M12.4172 30.8371H24.3056V27.6494H12.4172C11.0614 27.6494 9.96094 28.3629 9.96094 29.2429C9.96094 30.1229 11.0614 30.8371 12.4172 30.8371Z"
            fill="#EDEFF1"
          />
          <path
            d="M12.4172 46.7805H24.3056V43.5928H12.4172C11.0614 43.5928 9.96094 44.3063 9.96094 45.187C9.96094 46.067 11.0614 46.7805 12.4172 46.7805Z"
            fill="#EDEFF1"
          />
          <path d="M21.5255 19.8125H20.5703V53.6324H21.5255V19.8125Z" fill="white" />
          <path d="M14.2287 19.8125H13.2734V53.6324H14.2287V19.8125Z" fill="white" />
          <path d="M21.0575 20.7588H13.7656V21.4454H21.0575V20.7588Z" fill="white" />
          <path d="M21.0575 25.9746H13.7656V26.6612H21.0575V25.9746Z" fill="white" />
          <path d="M21.0575 31.1748H13.7656V31.8614H21.0575V31.1748Z" fill="white" />
          <path d="M21.0575 36.3779H13.7656V37.0646H21.0575V36.3779Z" fill="white" />
          <path d="M21.0575 41.5869H13.7656V42.2736H21.0575V41.5869Z" fill="white" />
          <path d="M21.0575 46.79H13.7656V47.4767H21.0575V46.79Z" fill="white" />
          <path d="M21.0575 51.9971H13.7656V52.6837H21.0575V51.9971Z" fill="white" />
          <path
            d="M10.0654 53.2773H56.5965L61.9742 60.7223H116.156V64.4448H60.9394L55.5616 56.9998H11.1002L5.72249 64.4448H2L10.0654 53.2773Z"
            fill="white"
          />
          <path d="M16.8852 53.2773H11.9219V56.9998H16.8852V53.2773Z" fill="#B3B3B3" />
          <path d="M19.3668 53.2773H24.3302V56.9998H19.3668V53.2773Z" fill="#B3B3B3" />
          <path d="M31.7751 53.2773H26.8118V56.9998H31.7751V53.2773Z" fill="#B3B3B3" />
          <path d="M34.2568 53.2773H39.2201V56.9998H34.2568V53.2773Z" fill="#B3B3B3" />
          <path d="M46.6651 53.2773H41.7018V56.9998H46.6651V53.2773Z" fill="#B3B3B3" />
          <path d="M49.1467 53.2773H54.11V56.9998H49.1467V53.2773Z" fill="#B3B3B3" />
          <path d="M71.4816 60.7223H66.5183V64.4448H71.4816V60.7223Z" fill="#B3B3B3" />
          <path d="M73.9633 60.7223H78.9266V64.4448H73.9633V60.7223Z" fill="#B3B3B3" />
          <path d="M86.3716 60.7223H81.4083V64.4448H86.3716V60.7223Z" fill="#B3B3B3" />
          <path d="M88.8532 60.7223H93.8166V64.4448H88.8532V60.7223Z" fill="#B3B3B3" />
          <path d="M101.262 60.7223H96.2982V64.4448H101.262V60.7223Z" fill="#B3B3B3" />
          <path d="M103.743 60.7223H110.3V64.4448H103.743V60.7223Z" fill="#B3B3B3" />
          <path d="M111.188 60.7223H116.151V64.4448H111.188V60.7223Z" fill="#B3B3B3" />
          <path
            d="M108.781 56.4688H129.501V56.5248C129.763 56.4874 130.027 56.4688 130.298 56.4688C133.109 56.4688 135.435 58.5451 135.821 61.2503H147.034V66.0319H132.689V64.438H108.781V56.4688Z"
            fill="#B0AEAE"
          />
          <path
            d="M123.657 79.3188C117.783 79.3188 113.031 74.562 113.031 68.6935C113.031 62.825 117.783 58.0674 123.657 58.0674C129.522 58.0674 134.283 62.825 134.283 68.6935C134.283 74.562 129.522 79.3188 123.657 79.3188Z"
            fill="#EDEFF1"
          />
          <path
            d="M123.656 74.4196C120.492 74.4196 117.93 71.8579 117.93 68.6986C117.93 65.5382 120.492 62.9766 123.656 62.9766C126.81 62.9766 129.373 65.5382 129.373 68.6986C129.373 71.8579 126.81 74.4196 123.656 74.4196Z"
            fill="#B3B3B3"
          />
          <path
            d="M123.649 71.1512C122.294 71.1512 121.195 70.0534 121.195 68.6994C121.195 67.3449 122.294 66.2471 123.649 66.2471C125.001 66.2471 126.099 67.3449 126.099 68.6994C126.099 70.0534 125.001 71.1512 123.649 71.1512Z"
            fill="#D9D9D9"
          />
          <path
            d="M46.0866 79.3188C40.213 79.3188 35.4609 74.562 35.4609 68.6935C35.4609 62.825 40.213 58.0674 46.0866 58.0674C51.9521 58.0674 56.7124 62.825 56.7124 68.6935C56.7124 74.562 51.9521 79.3188 46.0866 79.3188Z"
            fill="#EDEFF1"
          />
          <path
            d="M46.0856 74.4196C42.9221 74.4196 40.3594 71.8579 40.3594 68.6986C40.3594 65.5382 42.9221 62.9766 46.0856 62.9766C49.2397 62.9766 51.8024 65.5382 51.8024 68.6986C51.8024 71.8579 49.2397 74.4196 46.0856 74.4196Z"
            fill="#B3B3B3"
          />
          <path
            d="M46.0791 71.1512C44.7233 71.1512 43.625 70.0534 43.625 68.6994C43.625 67.3449 44.7233 66.2471 46.0791 66.2471C47.4308 66.2471 48.5292 67.3449 48.5292 68.6994C48.5292 70.0534 47.4308 71.1512 46.0791 71.1512Z"
            fill="#D9D9D9"
          />
          <path
            d="M22.196 79.3188C16.3224 79.3188 11.5703 74.562 11.5703 68.6935C11.5703 62.825 16.3224 58.0674 22.196 58.0674C28.0615 58.0674 32.8217 62.825 32.8217 68.6935C32.8217 74.562 28.0615 79.3188 22.196 79.3188Z"
            fill="#EDEFF1"
          />
          <path
            d="M22.2106 74.4196C19.0471 74.4196 16.4844 71.8579 16.4844 68.6986C16.4844 65.5382 19.0471 62.9766 22.2106 62.9766C25.3647 62.9766 27.9274 65.5382 27.9274 68.6986C27.9274 71.8579 25.3647 74.4196 22.2106 74.4196Z"
            fill="#B3B3B3"
          />
          <path
            d="M22.2041 71.1512C20.8483 71.1512 19.75 70.0534 19.75 68.6994C19.75 67.3449 20.8483 66.2471 22.2041 66.2471C23.5558 66.2471 24.6542 67.3449 24.6542 68.6994C24.6542 70.0534 23.5558 71.1512 22.2041 71.1512Z"
            fill="#D9D9D9"
          />
          <rect x="116.75" y="22.999" width="1.59386" height="33.471" fill="#B0AEAE" />
          <rect
            x="110.633"
            y="26.4346"
            width="4.28157"
            height="12.2508"
            fill="#D9D9D9"
            stroke="#B3B3B3"
            strokeWidth="0.5"
          />
          <rect x="143.859" y="54.877" width="3.18771" height="4.78157" fill="#B0AEAE" />
        </g>
        <defs>
          <filter
            id="filter0_d_305_48623"
            x="0"
            y="1.36035"
            width="160.516"
            height="88.958"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="5" dy="4" />
            <feGaussianBlur stdDeviation="3.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_305_48623" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_305_48623" result="shape" />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
}
