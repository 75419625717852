import { ChildrenTypography, TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import { calcAverage } from '@Src/_basic/helpers/CalcHelper';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  ImportBaseCementCompStr,
  ImportCementCompStrFormData,
  PutCementCompStrRequest,
} from '@Src/_basic/object/test/cementtitiousPowder/CementCompStrType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  formData: ImportCementCompStrFormData;
  testDetail: AllTest;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

function ExportCementCompStrTable({ formData, testDetail, afterUpdateTest }: Props) {
  const { t: i18T } = useTranslation();

  const [edit, setEdit] = useState<boolean>(false);

  const initialValues = useMemo(() => {
    const _initialValues: Record<string, number> = {};
    for (let i = 0; i <= formData.specimenData.length - 1; i++) {
      _initialValues[`specimen${i + 1}CompStr`] = formData.specimenData[i];
    }
    return _initialValues;
  }, [formData]);

  const validationSchema = useMemo(() => {
    const validationSchemaObject: Record<string, typeof basicNumberSchema> = {};
    for (let i = 0; i <= formData.specimenData.length - 1; i++) {
      validationSchemaObject[`specimen${i + 1}CompStr`] = basicNumberSchema;
    }
    return Yup.object().shape(validationSchemaObject);
  }, [formData]);

  const baseAverage = useMemo(() => {
    if (formData.specimenData.length > 0) return calcAverage([...formData.specimenData]);
    else return 0.0;
  }, [formData]);

  const showTestDays = (day: number) => {
    return (
      <Stack flexDirection="row" mt={2.5} mb={2}>
        <Typography sx={{ color: '#BB5E00', bgcolor: '#dcfbfb', p: 0.7, px: 2, borderRadius: '10%', fontWeight: 900 }}>
          {i18T(`TEST.SPECIMEN_STR.AGE_SPECIMEN_COMP_STR`, { day })}
        </Typography>
      </Stack>
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const transformedData: ImportBaseCementCompStr = {};
      for (const key in values) transformedData[key] = +values[key];

      TestApi.putTest<PutCementCompStrRequest>(
        {
          exptItemCode: testDetail.exptItemCode,
          fields: {
            id: formData.id,
            operator: testDetail.operator,
            completed: true,
            exptUnitId: testDetail.exptUnitId,
            abnormal: false,

            age: formData.age,
            ...transformedData,

            specimenId: formData.specimenId,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const theForm = () => {
    return (
      <TestCardContainer sx={{ my: 1 }}>
        {showTestDays(formData.age)}
        {formData.specimenData.map((item, index) => (
          <Stack key={index}>
            <ColFieldInfo
              title={`${i18T(`TEST.SPECIMEN_STR.SPECIMEN_STR`, { index: index + 1 })}(kgf/cm²)`}
              style={{ borderBottom: '1px solid gray', margin: '3px 0' }}
            >
              {edit ? (
                <LabelInput
                  key={index}
                  error={
                    !!(formik.touched[`specimen${index + 1}CompStr`] && formik.errors[`specimen${index + 1}CompStr`])
                  }
                  value={formik.values[`specimen${index + 1}CompStr`]}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue(`specimen${index + 1}CompStr`, e.target.value)
                  }
                >
                  {i18T(`TEST.SPECIMEN_STR.SPECIMEN_STR`, { index: index + 1 })}
                </LabelInput>
              ) : (
                <ChildrenTypography>{item}</ChildrenTypography>
              )}
            </ColFieldInfo>
          </Stack>
        ))}
        <ColFieldInfo title={`${i18T(`TEST.SPECIMEN_STR.AVERAGE_STR`)}(kgf/cm²)`}>
          <ChildrenTypography>{baseAverage?.toFixed(1)}</ChildrenTypography>
        </ColFieldInfo>
      </TestCardContainer>
    );
  };

  return (
    <Stack>
      <Stack
        flexDirection="row"
        justifyContent="flex-end"
        gap={3}
        position="absolute"
        right={200}
        sx={{ zIndex: 5, marginTop: 5 }}
      >
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} />
      </Stack>
      {theForm()}
    </Stack>
  );
}

export default memo(ExportCementCompStrTable);
