import DeliveryInformationCar from '@Src/_basic/icons/DeliveryInformationCar';
import { MonitorAreaData } from '@Src/_basic/object/MonitorType';
import { Stack } from '@mui/system';
import qs from 'qs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface Props {
  monitorAreaData: MonitorAreaData[];
}

export default function SelectAreaMenu(props: Props) {
  const navigate = useNavigate();
  const { t: i18T } = useTranslation();

  const { monitorAreaData } = props;

  function thousands(value: any) {
    //計算數字百位數加入逗號
    if (value) {
      value += '';
      const arr = value.split('.');
      const re = /(\d{1,3})(?=(\d{3})+$)/g;
      return arr[0].replace(re, '$1,') + (arr.length == 2 ? '.' + arr[1] : '');
    } else {
      return '0';
    }
  }

  return (
    <div style={{ display: 'flex', marginBottom: '10px', flexWrap: 'wrap' }}>
      {monitorAreaData.map((data: MonitorAreaData, index: number) => {
        const progress = Math.floor((data.orderCount - data.rejectCount) / data.orderCount) * 100;
        const handleGotoNextPage = (id: number) => {
          navigate(
            `/realTimeMonitoring/deliveryInformation/deliveryInformationByFactory?areaId=${id}&area=${data.areaName}`, //將所需參數帶入，使用uselocation取得
          );
        };
        return (
          <>
            <Stack key={data.areaId}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: '20px',
                  marginBottom: '10px',
                }}
              >
                <Stack
                  direction="row"
                  style={{
                    backgroundColor: `#BB5E00`,
                    borderRadius: '40px',
                    border: '15px solid #FFFFFF',
                    boxShadow: '4px 4px 20px 0px #00000033',
                  }}
                >
                  <Stack spacing={1} alignItems="center">
                    <div
                      role="button"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '462px',
                        height: '347px',
                        color: '#FFF',
                        borderRadius: '10px',
                      }}
                      onClick={() => handleGotoNextPage(data.areaId)}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div
                          style={{ fontSize: '25px', letterSpacing: '10px', marginLeft: '20px', lineHeight: '70px' }}
                        >
                          {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.PASS_RATE')}
                        </div>
                        <div
                          style={{
                            fontSize: '30px',
                            borderLeft: '1px solid white',
                            borderBottom: '1px solid white',
                            borderBottomLeftRadius: '10px',
                            width: '214px',
                            height: '54px',
                            textAlign: 'center',
                            lineHeight: '50px',
                            letterSpacing: '20px',
                            paddingLeft: '20px',
                          }}
                        >
                          {data.areaName}
                        </div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: '76px', letterSpacing: '10px', marginLeft: '20px' }}>
                          {Number.isNaN(((data.orderCount - data.rejectCount) / data.orderCount) * 100)
                            ? 100 + '%'
                            : progress + '%'}
                        </div>
                        <div>
                          <DeliveryInformationCar style={{ height: '114px', width: '140px', marginRight: '20px' }} />
                        </div>
                      </div>
                      <div style={{ border: '1px solid white', margin: '10px' }}></div>
                      <div style={{ marginLeft: '10px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ fontSize: '26px', letterSpacing: '10px' }}>
                            {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.TOTAL_CAR')}：
                          </div>
                          <div style={{ fontSize: '26px', letterSpacing: '10px' }}> {thousands(data.orderCount)}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ fontSize: '26px', letterSpacing: '10px' }}>
                            {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.OUT_CAR')}：
                          </div>
                          <div style={{ fontSize: '26px', letterSpacing: '10px' }}>
                            {' '}
                            {thousands(data.shipmentCount)}
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ fontSize: '26px', letterSpacing: '10px' }}>
                            {i18T('APPLICATIONSMANAGEMENT.DELIVERYINFORMATION.NOT_OUT_CAR')}：
                          </div>
                          <div style={{ fontSize: '26px', letterSpacing: '10px' }}>{data.rejectCount}</div>
                        </div>
                      </div>
                    </div>
                  </Stack>
                </Stack>
              </div>
            </Stack>
          </>
        );
      })}
    </div>
  );
}
