import { TestItemEnum } from './TestType';

export interface SpecimenTypeData {
  id: number;
  typeCode: string;
  typeName: string;
  removed: boolean;
}

export interface SearchSpecimenRequest {
  page: number;
  factoryId: number;
  createTime: number;
  timeZone: string;
  specimenTypeId: number;
  isShipmentRequired?: boolean;
}

export interface Specimen {
  id: string;
  specimenNum: number;
  createTime: number;
  specimenCode: string;
  removed: boolean;
  isShow: boolean;
  specimenType: SpecimenTypeData;
}

export interface BasicSpecimensResponse {
  id: string;
  specimenNum: number;
  createTime: number;
  specimenCode: string;
  age: number;
  specimenType: SpecimenTypeData;
  specificationId: number;
  specificationName: string;
  sourceId: number;
  sourceName: string;
  supplierId: number;
  supplierName: string;
  storageId: number;
  storageName: string;
  isControlGroup: boolean;
  controlGroupId: string;
  areaName: string;
  factoryName?: string;
  chemType?: string;
  exptUnitId: number;
  exptUnitName: string;
  plateNumber?: string | Array<string>;
}

export interface SearchSpecimenData extends BasicSpecimensResponse {
  creater?: string;
  shippingFactoryId?: number;
  shippingFactoryName?: string;
  civilEngrName?: string;
  placement?: string;
  shippingSpecification?: string;
  areaId?: number;
  expectedCompStr?: number;
  propName?: string;
  civilEngrAddr?: string;
  weatherStatus?: string;
  shipmentTimestamp?: number;
  inspectionNotificationTime?: null | number;
  lastInspectionNotificationTime?: null | number;
  retrieveNotificationTime?: null | number;
  lastRetrieveNotificationTime?: null | number;
  standardTime?: null | number;
  notifiedAccount?: string;
}

export interface SearchSpecimenGroupData extends BasicSpecimensResponse {
  factoryId: number;
  chemType: string;
  factoryName: string;
  areaName: string;
}

export interface SearchSpecimenGroupRequest {
  specimenId: string;
}

export interface SearchChemGroupRequest {
  specimenId: string;
}

export interface SearchSpecimenWithoutPageRequest {
  createTime: number;
}

export interface SearchPageableSpecimenData {
  maxPage: number;
  specimens: Array<SearchSpecimenData>;
}

export interface SearchChemGroupData {
  chemType: string;
  controlGroup: BasicSpecimensResponse;
  standardTarget: BasicSpecimensResponse;
  experimentalGroup: Array<BasicSpecimensResponse>;
}

export interface PostConCreteStrTestRequest {
  specimenId: string;
  operatorId: string;
  factoryId: number;
  incomingNum: number;
  createrId?: string;
  startTime?: number;
  endTime?: number;
  age: number;
  exptNotificationTime?: number;
}

export interface PostStrAndActivityRequest {
  createrId: string;
  operatorId: string;
  startTime?: number;
  endTime?: number;
  factoryId: number;
  specimenId: string;
  notificationTimes?: Array<{
    age: number;
    exptNotificationTime: number;
  }>;
}

export interface GetCheckUsedInExptRequest {
  specimenId: string;
}

export interface GetSpecimenExptSettingRequest {
  specimenId: string;
  factoryId: number;
  isRequired: boolean;
}

export interface GetSpecimenExptIdAndCodeRequest {
  specimenId: string;
  factoryId: number;
}

export interface PutSpecimenRequest {
  id: string;
  specimenNum: number;
  ingredients: null;
  inspectionNotificationTime: number | null;
  retrieveNotificationTime: number | null;
  standardTime: number;
  notifiedAccount: string;
}

export interface PostCreateTestData {
  id: number;
  exptItemCode: string;
  operater: string;
}

export enum ChemType {
  G_TYPE = 'G_TYPE',
  FLUIDIFIER = 'FLUIDIFIER',
}

export interface SpecimenDetailResponse extends BasicSpecimensResponse {
  factoryId: number | null;
  chemType: ChemType;
  factoryName: string;
  areaName: string;
}

export interface SpecimenExptSettingResponse {
  id: number;
  specimenId: string;
  exptItemCode: TestItemEnum;
  reqItemId: null | number;
  age: number;
  startTime: null | number;
  endTime: null | number;
  incomingSpecimenNum: number;
  exptNotificationTime: null | number;
  operator: string;
}

export interface SpecimenExptIdAndCodeResponse {
  exptId: number;
  exptItemCode: TestItemEnum;
}
