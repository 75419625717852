import { MonitorOrderData } from '@Src/_basic/object/MonitorType';
import { Title } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ShipmentBlock from '../ShipmentBlock';

interface Props {
  monitorOrderData: MonitorOrderData[];
  count: any;
  handleImageButtonClick: (shipmentId: number) => void;
  handleVideoButtonClick: (shipmentId: number) => void;
  setImageUrl: (imageUrl: string[]) => void;
}

export default function MonitorDetailCard(props: Props) {
  const { monitorOrderData, count, handleImageButtonClick, handleVideoButtonClick, setImageUrl } = props;
  const { t: i18T } = useTranslation();

  return (
    <>
      <Box sx={{ height: '100%' }}>
        <Title>出貨資訊</Title>
        <Box sx={{ pl: '50px', mt: '16px', height: '100%'}}>
          <Box sx={{ height: `calc(100% - ${123}px - 16px)` }}>
            {monitorOrderData.map((data: MonitorOrderData, index) => (
              <div key={data.shipmentId} style={{ scrollMarginTop: '50px' }}>
                <ShipmentBlock
                  data={data}
                  index={index}
                  count={count}
                  handleImageButtonClick={handleImageButtonClick}
                  handleVideoButtonClick={handleVideoButtonClick}
                  setImageUrl={setImageUrl}
                />
              </div>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}
