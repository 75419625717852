import YatungFadeMenu from '@Src/_basic/components/YatungFadeMenu';
import { useAuth } from '@Src/redux/auth/authActions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function UsersManageMenu() {
  const { t: i18T } = useTranslation();
  const { userApiPermission } = useAuth();
  const [newRouteSideMenu, setNewRouteSideMenu] = React.useState<Array<any>>([]);
  const [newRouteRawMaterialManagement, setNewRouteRawMaterialManagement] = React.useState<Array<any>>([]);
  const [newRouteAccountRightsManagementSideMenu, setNewRouteAccountRightsManagementSideMenu] = React.useState<
    Array<any>
  >([]);
  const [newOtherMantenanceSideMenu, setNewOtherMantenanceSideMenu] = React.useState<Array<any>>([]);

  const routeSideMenu = [
    {
      title: i18T('AREAMANAGEMENT.PAGE_TITLE'),
      toUrl: '/UsersManagePage/AreaManagement',
      code: 'WD001',
    },
    {
      title: i18T('FACTORYMANAGEMENT.PAGE_TITLE'),
      toUrl: '/UsersManagePage/FactoryManagement',
      code: 'WD002',
    },
    {
      title: i18T('SITEMANAGEMENT.PAGE_TITLE'),
      toUrl: '/UsersManagePage/SiteManagement',
      code: 'WD003',
    },
    {
      title: i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.PAGE_TITLE'),
      toUrl: '/UsersManagePage/FeedTroughMaintenanceManagement',
      code: 'WD004',
    },
    {
      title: i18T('USERSMANAGEMENU.MIXER_INFORMATION.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/MixerInformation',
      code: 'WD005',
    },
    {
      title: i18T('USERSMANAGEMENU.MIXERANDPROPROTION_INFORMATION_BIND.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/MixerAndProportionBind',
      code: 'WD006',
    },
    {
      title: i18T('ENTITYMANAGEMENT.PAGE_TITLE'),
      toUrl: '/UsersManagePage/EntityManagement',
      code: 'WD007',
    },
    {
      title: i18T('USERSMANAGEMENU.VEHICLE_MANAGEMENT.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/VehicleManagement',
      code: 'WD008',
    },
    {
      title: i18T('SAMPLEMANAGEMENT.PAGE_TITLE'),
      toUrl: '/UsersManagePage/SampleManagement',
      code: 'WD009',
    },
    {
      title: i18T('SAMPLESOURCEMANAGEMENT.PAGE_TITLE'),
      toUrl: '/UsersManagePage/SampleSourceManagement',
      code: 'WD010',
    },
  ];

  const routeRawMaterialManagement = [
    {
      title: i18T('APPLICATIONSMANAGEMENT.MATERIAL_CODE_MANAGE.TITLE'),
      toUrl: '/UsersManagePage/materialCodeMangae',
      code: 'WD011',
    },
    {
      title: i18T('APPLICATIONSMANAGEMENT.MATERIAL_NUMBER_MANAGE.TITLE'),
      toUrl: '/UsersManagePage/materialNumberMangae',
      code: 'WD012',
    },
    {
      title: i18T('APPLICATIONSMANAGEMENT.FACTORY_MATERIAL_CODE_MANAGE.TITLE'),
      toUrl: '/UsersManagePage/factoryMaterialCodeMangae',
      code: 'WD013',
    },
    {
      title: i18T('APPLICATIONSMANAGEMENT.MATERIAL_PRICE.TITLE'),
      toUrl: '/UsersManagePage/materialPrice',
      code: 'WD014',
    },
  ];
  const routeAccountRightsManagementSideMenu = [
    {
      title: i18T('ORGANIZEMANAGEMENT.PAGE_TITLE'),
      toUrl: '/UsersManagePage/OrganizeManagement',
      code: 'WD015',
    },
    {
      title: i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/AccountManagement',
      code: 'WD016',
    },
    {
      title: i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/AccountGroupManagement',
      code: 'WD017',
    },
    {
      title: i18T('USERSMANAGEMENU.ROLE_MAINTENANCE.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/RoleMaintenance',
      code: 'WD018',
    },
    {
      title: i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/ApiGroupManagement',
      code: 'WD019',
    },
    // {
    //   title: i18T('USERSMANAGEMENU.API_FUNCTION_MANAGEMENT.ROUTE_TITLE'),
    //   toUrl: '/UsersManagePage/ApiFunctionManagement',
    // },
    {
      title: i18T('USERSMANAGEMENU.REPORT_GROUP_MANAGEMENT.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/ReportGroupManagement',
      code: 'WD020',
    },
    {
      title: i18T('USERSMANAGEMENU.TRANSFER_ORDER_GROUP_MANAGEMENT.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/TransferOrderGroupManagement',
      code: 'WD021',
    },
    {
      title: i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/AssignGroupManagement',
      code: 'WD022',
    },
    // {
    //   title: i18T('USERSMANAGEMENU.APP_INSTALLATION_AUTHENTICATION_CODE_RELEASED.ROUTE_TITLE'),
    //   toUrl: '/UsersManagePage/VerificationCode',
    //   code: 'WD023',
    // },
  ];
  const otherMantenanceSideMenu = [
    {
      title: i18T('APPLICATIONSMANAGEMENT.WEIGHTUNITMANAGE.TITLE'),
      toUrl: '/UsersManagePage/WeightUnitManage',
      code: 'WD024',
    },
    {
      title: i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.PAGE_TITLE'),
      toUrl: '/UsersManagePage/equipmentManage',
      code: 'WD025',
    },
    {
      title: i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.TITLE'),
      toUrl: '/UsersManagePage/materialReceipts',
      code: 'WD026',
    },
    {
      title: i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.TITLE'),
      toUrl: '/UsersManagePage/storageYardMaterialReceipts',
      code: 'WD027',
    },
    {
      title: i18T('USERSMANAGEMENU.FEED_MACHINE_STATUS.ROUTE_TITLE'),
      toUrl: '/UsersManagePage/feedMachineStatus',
      code: 'WD028',
    },
    {
      title: i18T('APPLICATIONSMANAGEMENT.CLEAR_EXECUTION.TITLE'),
      toUrl: '/UsersManagePage/clearExecution',
      code: 'WD029',
    },
    {
      title: i18T('USERSMANAGEMENU.CALENDARMANAGE.TITLE'),
      toUrl: '/UsersManagePage/CalendarManagement',
      code: 'WD030',
    },
    {
      title: i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.TITLE'),
      toUrl: '/UsersManagePage/MultilingualManagement',
      code: 'WD031',
    },
  ];

  useEffect(() => {
    const routeSideMenuTemp: Array<any> = [];
    routeSideMenu.forEach((item) => {
      if (userApiPermission.includes(item.code)) {
        routeSideMenuTemp.push(item);
      }
    });
    setNewRouteSideMenu(routeSideMenuTemp);

    const routeRawMaterialManagementTemp: Array<any> = [];
    routeRawMaterialManagement.forEach((item) => {
      if (userApiPermission.includes(item.code)) {
        routeRawMaterialManagementTemp.push(item);
      }
    });
    setNewRouteRawMaterialManagement(routeRawMaterialManagementTemp);

    const routeAccountRightsManagementSideMenuTemp: Array<any> = [];
    routeAccountRightsManagementSideMenu.forEach((item) => {
      if (userApiPermission.includes(item.code)) {
        routeAccountRightsManagementSideMenuTemp.push(item);
      }
    });
    setNewRouteAccountRightsManagementSideMenu(routeAccountRightsManagementSideMenuTemp);

    const otherMantenanceSideMenuTemp: Array<any> = [];
    otherMantenanceSideMenu.forEach((item) => {
      if (userApiPermission.includes(item.code)) {
        otherMantenanceSideMenuTemp.push(item);
      }
    });
    setNewOtherMantenanceSideMenu(otherMantenanceSideMenuTemp);
  }, [userApiPermission]);
  return (
    <>
      {newRouteSideMenu.length > 0 && (
        <YatungFadeMenu text={i18T('USERSMANAGEMENU.FACTORY_MANAGEMENT.ROUTE_TITLE')} option={newRouteSideMenu} />
      )}
      {newRouteRawMaterialManagement.length > 0 && (
        <YatungFadeMenu
          text={i18T('USERSMANAGEMENU.RAW_MATERIAL_MANAGEMENT.ROUTE_TITLE')}
          option={newRouteRawMaterialManagement}
        />
      )}
      {newRouteAccountRightsManagementSideMenu.length > 0 && (
        <YatungFadeMenu
          text={i18T('USERSMANAGEMENU.ACCOUNT_RIGHTS_MANAGEMENT.ROUTE_TITLE')}
          option={newRouteAccountRightsManagementSideMenu}
        />
      )}
      {newOtherMantenanceSideMenu.length > 0 && (
        <YatungFadeMenu
          text={i18T('USERSMANAGEMENU.OTHER_MANTENANCE_MANAGEMENT.ROUTE_TITLE')}
          option={newOtherMantenanceSideMenu}
        />
      )}
    </>
  );
}
