import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import AssignGroupManagementCardScreen from './screens/AssignGroupManagementCardScreen';
import AssignGroupManagementCreateCardScreen from './screens/AssignGroupManagementCreateCardScreen';
import AssignGroupManagementUpdateCardScreen from './screens/AssignGroupManagementUpdateCardScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <AssignGroupManagementCardScreen />,
  },
  {
    path: '/AssignGroupManagementCreate/*',
    element: <AssignGroupManagementCreateCardScreen />,
  },
  {
    path: '/AssignGroupManagementUpdate/:id',
    element: <AssignGroupManagementUpdateCardScreen />,
  },
];

const AssignGroupManagementRouterPermissionRoute = () => PermissionRoute(routesConfig);

export default memo(AssignGroupManagementRouterPermissionRoute);
