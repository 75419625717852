import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Edit(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M18.7,22H1.8V5.1H12l1.5-1.5H1.8C1.4,3.6,1,3.7,0.8,4C0.5,4.3,0.3,4.7,0.3,5.1V22c0,0.4,0.2,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4h16.9c0.4,0,0.8-0.2,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1V10.5L18.7,12V22z" />
      <path d="M22.9,3.4l-2.6-2.6c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.4,0.3L8.1,11.4l-0.9,3.7c0,0.2,0,0.4,0,0.5c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.3,0.3,0.4,0.3c0.2,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0l3.7-0.8L22.9,5.2c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0-0.3-0.1-0.5C23.2,3.7,23.1,3.6,22.9,3.4z M11.7,14.4L8.9,15l0.7-2.8l7.9-8l2.2,2.2L11.7,14.4z M20.4,5.5l-2.2-2.2l1.2-1.2l2.2,2.2L20.4,5.5z" />
    </SvgIcon>
  );
}
