import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FactoryData, PutFactoryDataRequest } from '@Src/_basic/object/FactoryType';
import { FactoryApi } from '@Src/_basic/protocol/factoryManagement/FactoryApi';
import { useNavigate } from 'react-router';
import FactoryManagementUpdateField from './components/FactoryManagementUpdateField';
import FactoryManagementUpdateSubmit from './components/FactoryManagementUpdateSubmit';
import FactoryManagementErrorMessage from '../../FactoryManagementCreateCard/FactoryManagementCreateForm/components/FactoryManagementErrorMessage';

interface Props {
  open: boolean;
  initialValues: PutFactoryDataRequest;
  onOpenModal: () => void;
  onCancelButtonOpenModal: () => void;
}

const factorySchema = Yup.object().shape({
  factoryAddress: Yup.string().required('請輸入地址'),
  factoryPhone: Yup.string()
    .max(13, '聯絡電話最多10碼')
    .required('請輸入聯絡電話')
    .matches(/(\(\d{2,3}\))\d{3,4}-?\d{4}/, '範例:(07)2121-1212'),
  factoryCode: Yup.string()
    .max(1, '請輸入一個大寫字母')
    .required('請輸入大寫字母')
    .matches(/^(?=.*[A-Z])/, '請輸入大寫字母'),
  branchCode: Yup.string()
    .max(2, '請輸入0~99數字')
    .required('請輸入數字')
    .matches(/^(?=.*[0-9])/, '請輸入數字'),
});
export default function FactoryManagementUpdateForm(props: Props) {
  const history = useNavigate();
  const { initialValues } = props;
  const [warningMoalOpen, setWarningMoalOpen] = useState<boolean>(false);

  const handleSubmit = async (rquest: PutFactoryDataRequest) => {
    FactoryApi.PutFactory(
      rquest,
      (data: FactoryData[]) => {
        handleGoBack();
      },
      () => {
        setWarningMoalOpen(true);
      },
      undefined,
    );
  };

  const handleWarningModalClose = () => {
    setWarningMoalOpen(false);
  };
  const handleGoBack = () => {
    history(-1);
  };

  return (
    <>
      <FactoryManagementErrorMessage onWarningModalClose={handleWarningModalClose} warningMoalOpen={warningMoalOpen} />

      <Formik
        initialValues={initialValues}
        validationSchema={factorySchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Grid>
              <Form>
                <Grid container spacing={6}>
                  <FactoryManagementUpdateField setValue={formik.setFieldValue} value={formik.values} />
                </Grid>
                <Grid container justifyContent={'flex-end'} sx={{ pr: 4, mt: 25 }}>
                  <FactoryManagementUpdateSubmit onGoBack={handleGoBack} value={formik.values} />
                </Grid>
              </Form>
            </Grid>
          );
        }}
      </Formik>
    </>
  );
}
