import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { MixerData } from '@Src/_basic/object/MixerTypes';
import { useApi } from '@Src/redux/api/apiAction';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import FeedTroughsColumnFormatter from './columnFormatter/FeedTroughsColumnFormatter';
import LastUpdateTimeColumnFormatter from './columnFormatter/LastUpdateTimeColumnFormatter';
import MixersColumnFormatter from './columnFormatter/MixersColumnFormatter';

interface Props {
  mixerData: MixerData[];
}
export default function MixerInformationTable({ mixerData }: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const navigate = useNavigate();

  const handleGoToUpdatePage = useCallback(
    (id: number) => {
      navigate(`/UsersManagePage/MixerInformation/MixerInformationUpdate/${id}`);
    },
    [navigate],
  );

  const getButtonText = useCallback(
    (status: string) => {
      switch (status) {
        case '編輯':
          return i18T('FACTORYMANAGEMENT.UPDATE');
        default:
          return '';
      }
    },
    [i18T],
  );

  const columns: Array<Column> = useMemo(
    () => [
      {
        text: i18T('USERSMANAGEMENU.TABLE.NO'),
        dataField: 'ids',
        width: '5%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.AREA'),
        dataField: 'areaName',
        width: '10%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.FACTORY'),
        dataField: 'factoryName',
        width: '10%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.MIXER'),
        dataField: 'mixers',
        formatter: MixersColumnFormatter,
        width: '10%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.FEEDTROUGH'),
        dataField: 'storages',
        formatter: FeedTroughsColumnFormatter,
        width: '45%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.LASTTIME'),
        dataField: 'lastUpdateTime',
        formatter: LastUpdateTimeColumnFormatter,
        width: '10%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.ACTION'),
        dataField: 'action',
        formatter: ActionColumnFormatter,
        formatExtraData: {
          onGoToUpdatePage: handleGoToUpdatePage,
          getButtonText: getButtonText,
        },
        width: '10%',
      },
    ],
    [i18T, handleGoToUpdatePage, getButtonText],
  );

  return <YatungTable columns={columns} data={mixerData} />;
}
