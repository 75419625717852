import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import SampleScreen from './screens/SampleScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <SampleScreen />,
  },
];

const SampleManagementRouter = () => PermissionRoute(routesConfig);

export default memo(SampleManagementRouter);
