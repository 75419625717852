import React from 'react';
import { Stack } from '@mui/material';
import { AreaManagementData } from '@Src/_basic/object/AreaType';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';

export type ExtraData = {
  onOpenUpdateModal: (id: number) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: AreaManagementData;
  formatExtraData: ExtraData;
}
const editImage = '/media/images/edit.png';

export default function AreaColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;
  return (
    <Stack spacing={2} direction="row">
      <YatungButton
        text={formatExtraData.getButtonText('編輯')}
        variant={'contained'}
        color="gray"
        type={'button'}
        size={'large'}
        startIcon={<YatungImg img={editImage} />}
        onClick={() => {
          formatExtraData.onOpenUpdateModal(row.id);
        }}
      />
    </Stack>
  );
}
