import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { TextLineVariantEnum } from '@Src/_basic/object/ProductionHistoryType';
import { Box, Stack, Typography, styled } from '@mui/material';
import moment from 'moment';
import React, { memo, useMemo } from 'react';

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '16px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '19px',
  letterSpacing: '0.255em',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: '#1976D2',
  fontSize: '15px',
  fontWeight: '700',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '19px',
  letterSpacing: '0.2em',
}));

const Content = styled(Typography)(({ theme }) => ({
  color: '#7C878E',
  fontSize: '15px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '19px',
  letterSpacing: '0.255em',
}));

export interface Props {
  icon: React.ReactNode;
  title: string;
  subTitle: string;
  content: string;
  editable: boolean;
  field: any;
  form: any;
  variant: TextLineVariantEnum;
  minValue: any;
  maxValue: any;
  options: Array<Options>;
}

function TextLine(props: Partial<Props>) {
  const titleView = useMemo(() => {
    const breakLine = props.subTitle && props.subTitle.length > 10;

    if (!props.subTitle) return <Title>{props.title}</Title>;
    else {
      let line1;
      let line2;

      if (breakLine) {
        line1 = props.subTitle.substring(0, props.subTitle.length / 2);
        line2 = props.subTitle.substring(props.subTitle.length / 2, props.subTitle.length);
      }

      return (
        <Stack gap={1} mr={1} flexDirection="row" alignItems="center">
          <Stack>
            {props.title && <SubTitle>{props.title}</SubTitle>}
            {props.subTitle && breakLine ? (
              <Stack>
                <SubTitle>{`(${line1}`}</SubTitle>
                <SubTitle>{`${line2})`}</SubTitle>
              </Stack>
            ) : (
              <SubTitle>{`(${props.subTitle})`}</SubTitle>
            )}
          </Stack>
          <SubTitle> : </SubTitle>
        </Stack>
      );
    }
  }, [props.title, props.subTitle]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {props.icon && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mr: '12px' }}>{props.icon}</Box>
      )}

      {titleView}

      {props.editable && props.field && props.form ? (
        <>
          {props.variant === undefined || props.variant === TextLineVariantEnum.TEXT ? (
            <YatungInput
              error={props.form.touched[props.field.name] && !!props.form.errors[props.field.name]}
              helperText={props.form.touched[props.field.name] && props.form.errors[props.field.name]}
              defaultValue={props.field.value}
              onBlur={(e: any) => {
                props.form?.setTouched({ [props.field.name]: true });
                props.form?.setFieldValue(props.field.name, e.target.value);
              }}
            />
          ) : null}
          {props.variant === TextLineVariantEnum.NUMBER ? (
            <YatungInput
              type="number"
              error={props.form.touched[props.field.name] && !!props.form.errors[props.field.name]}
              helperText={props.form.touched[props.field.name] && props.form.errors[props.field.name]}
              defaultValue={props.field?.value}
              onBlur={(e: any) => {
                props.form?.setTouched({ [props.field.name]: true });
                props.form?.setFieldValue(props.field.name, e.target.value);
              }}
            />
          ) : null}
          {props.variant === TextLineVariantEnum.DATE ? (
            <YatungDateSelect
              error={props.form?.errors[props.field.name]}
              value={props.field.value}
              minDate={props.minValue ? moment(props.minValue).toDate() : undefined}
              maxDate={props.maxValue ? moment(props.maxValue).toDate() : undefined}
              onChange={(val) => val && props.form?.setFieldValue(props.field.name, new Date(val).getTime())}
            />
          ) : null}
          {props.variant === TextLineVariantEnum.OPTION && props.options ? (
            <YatungSelect
              disabled={props.options.length === 0}
              options={props.options}
              value={props.field.value}
              onChange={(e: any) => {
                props.form?.setFieldValue(props.field.name, e.target.value);
              }}
            />
          ) : null}
        </>
      ) : (
        <Content>{props.content}</Content>
      )}
    </Box>
  );
}

export default memo(TextLine);
