import { Response } from '@Src/_basic/object/ApiType';
import {
  GetCheckUsedInExptRequest,
  GetSpecimenExptIdAndCodeRequest,
  GetSpecimenExptSettingRequest,
  PostConCreteStrTestRequest,
  PostCreateTestData,
  PostStrAndActivityRequest,
  PutSpecimenRequest,
  SearchChemGroupData,
  SearchChemGroupRequest,
  SearchPageableSpecimenData,
  SearchSpecimenData,
  SearchSpecimenGroupData,
  SearchSpecimenGroupRequest,
  SearchSpecimenRequest,
  SearchSpecimenWithoutPageRequest,
  SpecimenDetailResponse,
  SpecimenExptIdAndCodeResponse,
  SpecimenExptSettingResponse,
  SpecimenTypeData,
} from '@Src/_basic/object/SpecimenType';
import { Api } from '../Api';
import { SpecimenProtocol } from './SpecimenProtocol';

export class SpecimenApi {
  // 取得所有試體種類
  public static getSpecimenType(
    onSuccess?: (data: Array<SpecimenTypeData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(SpecimenProtocol.SPECIMEN_TYPE, undefined, onSuccess, onFailed, onFinally);
  }

  // 搜尋試體
  public static getSpecimenBySearch(
    params: SearchSpecimenRequest,
    onSuccess?: (data: SearchPageableSpecimenData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(SpecimenProtocol.SEARCH_SPECIMEN, config, onSuccess, onFailed, onFinally);
  }

  // 取得試體群組資訊
  public static getSpecimenGroupBySearch(
    params: SearchSpecimenGroupRequest,
    onSuccess?: (data: Array<SearchSpecimenGroupData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(SpecimenProtocol.SEARCH_GROUP, config, onSuccess, onFailed, onFinally);
  }

  // 取得藥劑群組資訊
  public static getChemGroupBySearch(
    params: SearchChemGroupRequest,
    onSuccess?: (data: Array<SearchChemGroupData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(SpecimenProtocol.SEARCH_CHEM_GROUP, config, onSuccess, onFailed, onFinally);
  }

  // 取得當日不分頁試體
  public static getSpecimenWithoutPageableBySearch(
    params: SearchSpecimenWithoutPageRequest,
    onSuccess?: (data: Array<SearchSpecimenData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(SpecimenProtocol.SEARCH_SPECIMEN_WITHOUT_PAGE, config, onSuccess, onFailed, onFinally);
  }

  // 建立混凝土抗壓需求單
  public static postConCreteStrTest(
    request: PostConCreteStrTestRequest,
    onSuccess?: (data: PostCreateTestData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SpecimenProtocol.NEW_CONCRETE_STR_TEST, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 建立爐石抗壓需求單
  public static postSlagActivityTest(
    request: PostStrAndActivityRequest,
    onSuccess?: (data: PostCreateTestData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SpecimenProtocol.NEW_SLAG_ACTIVITY_TEST, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 建立爐石抗壓需求單
  public static postFlyAshActivityTest(
    request: PostStrAndActivityRequest,
    onSuccess?: (data: PostCreateTestData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SpecimenProtocol.NEW_FLY_SAH_ACTIVITY_TEST, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 建立藥劑抗壓需求單
  public static postChemStrTest(
    request: PostStrAndActivityRequest,
    onSuccess?: (data: PostCreateTestData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SpecimenProtocol.NEW_CHEM_STR_TEST, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 建立水泥抗壓需求單
  public static postCementStrTest(
    request: PostStrAndActivityRequest,
    onSuccess?: (data: PostCreateTestData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(SpecimenProtocol.NEW_CEMENT_STR_TEST, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 以specimenId確認是否有開過試驗單
  public static getCheckUsedInExpt(
    params: GetCheckUsedInExptRequest,
    onSuccess?: (data: boolean) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(SpecimenProtocol.CHECK_USED_IN_EXPT, config, onSuccess, onFailed, onFinally);
  }

  // 取得試體詳細資訊
  public static getSpecimenDetail(
    params: GetCheckUsedInExptRequest,
    onSuccess?: (data: SpecimenDetailResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
    signal?: AbortSignal,
  ) {
    const config = {
      params,
      signal,
    };
    Api.get(SpecimenProtocol.SPECIMEN_DETAIL, config, onSuccess, onFailed, onFinally);
  }

  // 取得試體歷史開單設定
  public static getSpecimenExptSetting(
    params: GetSpecimenExptSettingRequest,
    onSuccess?: (data: Array<SpecimenExptSettingResponse>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(SpecimenProtocol.SPECIMEN_EXPT_SETTING, config, onSuccess, onFailed, onFinally);
  }

  // 取得試體Id與Code
  public static getSpecimenExptIdAndCode(
    params: GetSpecimenExptIdAndCodeRequest,
    onSuccess?: (data: SpecimenExptIdAndCodeResponse) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(SpecimenProtocol.SPECIMEN_LATEST_EXPT, config, onSuccess, onFailed, onFinally);
  }

  // 修改試體
  public static putSpecimen(
    request: PutSpecimenRequest,
    onSuccess?: (data: SearchSpecimenData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(SpecimenProtocol.SPECIMEN, request, undefined, onSuccess, onFailed, onFinally);
  }
}
