import { Response } from '@Src/_basic/object/ApiType';
import { BomHierarchyData } from '@Src/_basic/object/MaterialFactoryCodeType';
import {
  AMSAbnormalLegalData,
  CarbonData,
  ConcreteExptReportParams,
  ConcreteExptReportProportionData,
  GetAMSAbnormalLegalParams,
  GetProjectPropsParams,
} from '@Src/_basic/object/PrintProductionType';
import axios from 'axios';
import { Api } from '../Api';
import { PrintProductionProtocol } from './printProductionProtocol';

export class PrintProductionApi {
  public static getProjectProps(
    params: GetProjectPropsParams,
    onSuccess?: (data: Array<string>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(PrintProductionProtocol.EXPORT_PROPS, config, onSuccess, onFailed, onFinally);
  }

  public static async getAMSAbnormalLegal(
    params: GetAMSAbnormalLegalParams,
  ): Promise<AMSAbnormalLegalData | undefined> {
    try {
      const result = await axios.get(PrintProductionProtocol.AMS_EXPT_REPORT, { params });
      return result as any;
    } catch (err) {
      console.log(err);
    }
  }

  public static async getConcreteExptReport(
    params: ConcreteExptReportParams,
  ): Promise<Record<string, Array<ConcreteExptReportProportionData>> | undefined> {
    try {
      const result = await axios.get(PrintProductionProtocol.CONC_EXPT_REPORT, { params });
      return result as any;
    } catch (err) {
      console.log(err);
    }
  }

  public static async getBom(
    params: Omit<GetProjectPropsParams, 'shipmentTimestamp'>,
  ): Promise<Array<BomHierarchyData> | undefined> {
    try {
      const result = await axios.get(PrintProductionProtocol.BOM, { params });
      return result as any;
    } catch (err) {
      console.log(err);
    }
  }

  public static async getCarbon(
    params: Omit<ConcreteExptReportParams, 'realPropNames' | 'factoryId'>,
  ): Promise<Array<CarbonData> | undefined> {
    try {
      const result = await axios.get(PrintProductionProtocol.CARBON_EMISSION_INFO, { params });
      return result as any;
    } catch (err) {
      console.log(err);
    }
  }
}
