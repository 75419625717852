import React from 'react';
import { VehicleArea, VehicleData, VehicleOptions } from '@Src/_basic/object/VehicleType';
import NamesExpand from '@Src/_basic/components/NamesExpand';

interface Props {
  row: VehicleData;
}

export default function FactoryColumnFormatter(props: Props) {
  const { row } = props;

  const getArea = () => {
    return row.area?.filter((value: VehicleArea) => value.areaId !== 7);
  };
  const getFactory = () => {
    const factoryArray = getArea()?.map((value: any) => {
      return value.factories;
    });
    const mergedFactoryArray = [].concat(...factoryArray);
    return mergedFactoryArray;
  };

  return <NamesExpand names={getFactory().map((factory: VehicleOptions) => factory.name)} />;
}
