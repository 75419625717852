import { TestCardContainer, TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { CalcFlyAshLossOnIgnition } from '@Src/_basic/helpers/CalcTestForm';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  FlyAshLossOnIgnition,
  ImportFlyAshLossOnIgnition,
  PutFlyAshLossOnIgnitionRequest,
} from '@Src/_basic/object/test/cementtitiousPowder/FlyAshLossOnIgnitionType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: FlyAshLossOnIgnition;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  crucibleWeight: basicNumberSchema,
  initialWeight: basicNumberSchema,
  finalWeight: basicNumberSchema,
});

function ExportFlyAshLossOnIgnitionTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const initialValues = useMemo(
    () => ({
      crucibleWeight: testDetail.crucibleWeight,
      initialWeight: testDetail.initialWeight,
      finalWeight: testDetail.finalWeight,
    }),
    [testDetail],
  );

  const [edit, setEdit] = useState<boolean>(false);

  const calcTestForm = useMemo(() => new CalcFlyAshLossOnIgnition(testDetail), [testDetail]);

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const firstInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.crucibleWeight?.toFixed(4)),
        values: [
          formatNumber(testDetail?.initialWeight?.toFixed(4)),
          formatNumber(testDetail?.finalWeight?.toFixed(4)),
        ],
      },
    ],
    [testDetail?.crucibleWeight, testDetail?.initialWeight, testDetail?.finalWeight],
  );

  const secondInfo = useMemo(
    () => [
      {
        title: formatNumber(calcTestForm?.netWeightInitialWeight?.toFixed(4)),
        values: [
          formatNumber(calcTestForm?.netWeightFinalWeight?.toFixed(4)),
          formatNumber(calcTestForm?.lossWeight?.toFixed(4)),
        ],
      },
    ],
    [calcTestForm?.netWeightInitialWeight, calcTestForm?.netWeightFinalWeight, calcTestForm?.lossWeight],
  );

  const thirdInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.lossOnIgnition?.toFixed(4)),
        titleStyle: {
          ...(testDetail?.verifyPassedResults?.lossOnIgnition && testDetail?.verifyCustomResults?.lossOnIgnition?.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal),
        },
      },
    ],
    [
      testDetail?.lossOnIgnition,
      testDetail?.verifyPassedResults?.lossOnIgnition,
      testDetail?.verifyCustomResults?.lossOnIgnition,
    ],
  );

  const fourthInfo = useMemo(
    () => [
      isLegalPassed && isCustomPassed && !isCustomRejected
        ? {
            titles: verifyResultText,
            titlesStyle: [TestVerifyResultStyles.normal],
          }
        : {
            titles: verifyResultText,
            titlesStyle: [
              isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
              isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
            ],
          },
    ],
    [isLegalPassed, isCustomPassed, verifyResultText, isCustomRejected],
  );

  const firstHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.FLY_ASH_LOSS_ON_IGNITION.CRUCIBLE_WEIGHT`)}(g)`,
      values: [
        `${i18T(`TEST.FLY_ASH_LOSS_ON_IGNITION.INITIAL_WEIGHT`)}(g)`,
        `${i18T(`TEST.FLY_ASH_LOSS_ON_IGNITION.FINAL_WEIGHT`)}(g)`,
      ],
    }),
    [i18T],
  );

  const secondHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.FLY_ASH_LOSS_ON_IGNITION.NET_WEIGHT_INITIAL_WEIGHT`)}(g)`,
      values: [
        `${i18T(`TEST.FLY_ASH_LOSS_ON_IGNITION.NET_WEIGHT_FINAL_WEIGHT`)}(g)`,
        `${i18T(`TEST.FLY_ASH_LOSS_ON_IGNITION.LOSS_WEIGHT`)}(g)`,
      ],
    }),
    [i18T],
  );

  const thirdHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.FLY_ASH_LOSS_ON_IGNITION.LOSS_ON_IGNITION`)}(%)`,
    }),
    [i18T],
  );

  const fourthHeader = useMemo(
    () => ({
      title: i18T(`TEST.BASE.DETECTION`),
    }),
    [i18T],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportFlyAshLossOnIgnition = {
        crucibleWeight: checkIsNaN(+values.crucibleWeight),
        initialWeight: checkIsNaN(+values.initialWeight),
        finalWeight: checkIsNaN(+values.finalWeight),
      };

      TestApi.putTest<PutFlyAshLossOnIgnitionRequest>(
        {
          exptItemCode: testDetail.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const firstView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack flexDirection="row">
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.FLY_ASH_LOSS_ON_IGNITION.CRUCIBLE_WEIGHT`)}(g)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.crucibleWeight}
                isError={!!(formik.touched.crucibleWeight && formik.errors.crucibleWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('crucibleWeight', e.target.value)}
              />
            </ColFieldInfo>
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.FLY_ASH_LOSS_ON_IGNITION.INITIAL_WEIGHT`)}(g)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.initialWeight}
                isError={!!(formik.touched.initialWeight && formik.errors.initialWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('initialWeight', e.target.value)}
              />
            </ColFieldInfo>
            <ColFieldInfo
              titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
              title={`${i18T(`TEST.FLY_ASH_LOSS_ON_IGNITION.FINAL_WEIGHT`)}(g)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.finalWeight}
                isError={!!(formik.touched.finalWeight && formik.errors.finalWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('finalWeight', e.target.value)}
              />
            </ColFieldInfo>
          </Stack>
        );
      case false:
        return (
          <>
            <TestTable headerData={firstHeader} rows={firstInfo} />
            <TestTable headerData={secondHeader} rows={secondInfo} />
            <TestTable headerData={thirdHeader} rows={thirdInfo} />
          </>
        );
      default:
        break;
    }
  }, [edit, formik, firstHeader, firstInfo, secondHeader, secondInfo, thirdHeader, thirdInfo, i18T]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {firstView}
      </TestCardContainer>
      <TestCardContainer>
        <TestTable headerData={fourthHeader} rows={fourthInfo} />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportFlyAshLossOnIgnitionTable);
