import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { SiteManagementData } from '@Src/_basic/object/SiteType';
import { StationApi } from '@Src/_basic/protocol/site/StationApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Pagination, Stack } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import SiteSearchBar from './SiteManagementSearchBar';
import SiteManagementTable from './SiteManagementTable';

const background = '/media/images/background.png';

export type QueryType = {
  areaId: number;
  keywords: string;
};

type SearchParams = QueryType & {
  page: number;
};

export default function SiteManagementCard() {
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<SearchParams>();

  const effectRef = useRef(false);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [stationData, setStationData] = useState<SiteManagementData[]>([]);

  const initPageInfo: PageInfo = useMemo(
    () => ({
      page: request?.page === location.pathname && request?.request?.page ? request.request.page : 1,
      pageSize: 10,
      total: 0,
      totalCount: 0,
    }),
    [location.pathname, request],
  );

  const initFilterValue = useMemo(() => {
    const { areaId = 0, keywords = '' } =
      request?.page === location.pathname && request?.request ? request.request : {};

    return {
      areaId,
      keywords,
    };
  }, [request, location.pathname]);

  const [filterValue, filterValueDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(
    updateReducer,
    initFilterValue,
  );
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const changeHandler = useCallback((field: keyof QueryType, value: QueryType[keyof QueryType]) => {
    filterValueDispatch({ [field]: value });
    pageInfoDispatch({ page: 1 });
    setIsVisible(false);
  }, []);

  const getSiteManagementData = useCallback(
    (page: number) => {
      const { keywords, areaId } = filterValue;

      StationApi.getPaginationStation(
        {
          ...(keywords && { keywords }),
          ...(areaId && { areaIds: areaId }),
          page,
          size: pageInfo.pageSize,
        },
        (_data) => {
          setIsVisible(true);
          setStationData(
            _data.data.map((item, index) => ({
              ...item,
              ids: index + 1 + (page - 1) * pageInfo.pageSize,
            })),
          );
          pageInfoDispatch({
            total: _data.maxPage,
          });
          setSaveRequest({
            page: location.pathname,
            request: {
              keywords: keywords,
              areaId: areaId,
              page,
            },
          });
        },
      );
    },
    [pageInfo, filterValue, location.pathname, setSaveRequest],
  );

  const handlePageChange = useCallback(
    (e: React.ChangeEvent<unknown>, page: number) => {
      pageInfoDispatch({ page });
      getSiteManagementData(page);
    },
    [getSiteManagementData],
  );

  useEffect(() => {
    if (!effectRef.current) {
      effectRef.current = true;
      getSiteManagementData(pageInfo.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effectRef]);

  return (
    <>
      <YatungPage
        title={i18T('SITEMANAGEMENT.PAGE_TITLE')}
        backgroundImage={background}
        body={
          <>
            <SiteSearchBar
              filterValue={filterValue}
              changeHandler={changeHandler}
              onClickSarchButton={() => getSiteManagementData(pageInfo.page)}
            />
            <YatungFadeInOut isVisible={isVisible}>
              <>
                <SiteManagementTable stationData={stationData} />
                {!!pageInfo.total && (
                  <Stack flexDirection={'row'} justifyContent={'center'} sx={{ p: 1 }}>
                    <Pagination
                      count={pageInfo.total}
                      page={pageInfo.page}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Stack>
                )}
              </>
            </YatungFadeInOut>
          </>
        }
      />
    </>
  );
}
