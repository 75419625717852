import React from 'react';

export default function Member() {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_921_10234)">
        <path
          d="M19.5667 6.09677C19.5667 9.17245 16.9855 11.6935 13.7667 11.6935C10.5479 11.6935 7.96665 9.17245 7.96665 6.09677C7.96665 3.02109 10.5479 0.5 13.7667 0.5C16.9855 0.5 19.5667 3.02109 19.5667 6.09677ZM27.5 27.0968C27.5 27.2317 27.4979 27.3661 27.4937 27.5H0.506328C0.502118 27.3661 0.5 27.2317 0.5 27.0968C0.5 19.9057 6.5286 14.0484 14 14.0484C21.4714 14.0484 27.5 19.9057 27.5 27.0968Z"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_921_10234">
          <rect width={28} height={28} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
