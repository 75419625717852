import { Typography } from '@mui/material';
import React from 'react';

export type ExtraData = {
  getButtonText: (status: string) => string;
};
interface Props {
  cellContent: boolean;
  formatExtraData: ExtraData;
}
export default function StateColumnFormatter(props: Props) {
  const { cellContent, formatExtraData } = props;
  return (
    <Typography
      variant="inherit"
      sx={{
        color: cellContent ? '#CB333B' : '#3E9B2F',
        fontWeight: 400,
        fontSize: 16,
      }}
    >
      {cellContent ? formatExtraData.getButtonText('關閉中') : formatExtraData.getButtonText('運轉中')}
    </Typography>
  );
}
