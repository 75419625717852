import { QrcodeData } from '@Src/_basic/object/QrcodeType';
import { AllTest, Record } from '@Src/_basic/object/TestType';
import { Box, styled } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ColFieldInfo from './ColFieldInfo';

interface Props {
  qrcodeData?: Record | QrcodeData;
  testDetail?: AllTest;
}

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: theme.spacing(1, 0),
}));

function QrcodeDataRows({ qrcodeData, testDetail }: Props) {
  const { t: i18T } = useTranslation();

  return (
    <Container>
      <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.FACTORY`)}>
        {(qrcodeData as QrcodeData)?.factoryName ?? i18T('GLOBAL.NO_INFO')}
      </ColFieldInfo>

      <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.TESTERS`)}>
        {testDetail?.operatorName ?? i18T('GLOBAL.NO_INFO')}
      </ColFieldInfo>

      <ColFieldInfo title={i18T(`TEST.QRCODE_DATA.CAR_NO`)} style={{ flex: 1 }}>
        {qrcodeData?.plateNumber ? qrcodeData.plateNumber : i18T('GLOBAL.NO_INFO')}
      </ColFieldInfo>

      <ColFieldInfo title={`${i18T('TEST.QRCODE_DATA.SPECIFICATION')}`}>
        {qrcodeData?.specificationName ?? i18T('GLOBAL.NO_INFO')}
      </ColFieldInfo>

      <ColFieldInfo title={`${i18T('TEST.QRCODE_DATA.SOURCE')}`}>
        {qrcodeData?.sourceName ?? i18T('GLOBAL.NO_INFO')}
      </ColFieldInfo>

      <ColFieldInfo title={`${i18T('TEST.QRCODE_DATA.SUPPLIER')}`}>
        {qrcodeData?.supplierName ?? i18T('GLOBAL.NO_INFO')}
      </ColFieldInfo>

      <ColFieldInfo title={`${i18T('TEST.QRCODE_DATA.STORAGE')}`}>
        {qrcodeData?.storageName ?? i18T('GLOBAL.NO_INFO')}
      </ColFieldInfo>

      <ColFieldInfo title={`${i18T('TEST.QRCODE_DATA.SAMPLER')}`}>
        {qrcodeData?.samplerName ?? qrcodeData?.staffName ?? i18T('GLOBAL.NO_INFO')}
      </ColFieldInfo>

      <ColFieldInfo title={`${i18T('TEST.QRCODE_DATA.SAMPLE_SOURCE')}`}>
        {qrcodeData?.sampleSourceName ?? i18T('GLOBAL.NO_INFO')}
      </ColFieldInfo>
    </Container>
  );
}

export default memo(QrcodeDataRows);
