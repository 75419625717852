import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import TestInDistrictByAreaScreen from './screens/TestInDistrictByAreaScreen';
import TestInDistrictByDetailSreen from './screens/TestInDistrictByDetailSreen';
import TestInDistrictByFactoryScreen from './screens/TestInDistrictByFactoryScreen';
import TestInDistrictByTestListSreen from './screens/TestInDistrictByTestListSreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/testsDistrictByArea',
    element: <TestInDistrictByAreaScreen />,
  },
  {
    path: '/testsDistrictByFactory/:id',
    element: <TestInDistrictByFactoryScreen />,
  },
  {
    path: '/testsDistrictByDetail/:id',
    element: <TestInDistrictByDetailSreen />,
  },
  {
    path: '/testInDistrictByTestList/:id',
    element: <TestInDistrictByTestListSreen />,
  },
];

const TestInDistrict = () => PermissionRoute(routesConfig);

export default memo(TestInDistrict);
