import {
  AMSAbnormalLegalData,
  CarbonData,
  ConcreteExptReportProportionData,
} from '@Src/_basic/object/PrintProductionType';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type BomData = { type: string; specification: string; bomName: string };

interface initialState {
  proportionPosition: Record<string, string> | undefined;
  amsAbnormalLegalData: AMSAbnormalLegalData | undefined;
  concreteExptReportData: Record<string, Array<ConcreteExptReportProportionData>> | undefined;
  bomData: Array<BomData> | undefined;
  carbonData: Array<CarbonData> | undefined;
}

export const printProductionSlice = createSlice({
  name: 'printProductionSlice',
  initialState: {
    proportionPosition: {},
    amsAbnormalLegalData: {},
    concreteExptReportData: {},
    bomData: undefined,
    carbonData: undefined,
  } as initialState,
  reducers: {
    setProportionPosition: (state, action: PayloadAction<Record<string, string> | undefined>) => {
      state.proportionPosition = action.payload;
    },
    setAmsAbnormalLegalData: (state, action: PayloadAction<AMSAbnormalLegalData | undefined>) => {
      state.amsAbnormalLegalData = action.payload;
    },
    setConcreteExptReportData: (
      state,
      action: PayloadAction<Record<string, Array<ConcreteExptReportProportionData>> | undefined>,
    ) => {
      state.concreteExptReportData = action.payload;
    },
    setBomData: (state, action: PayloadAction<Array<BomData> | undefined>) => {
      state.bomData = action.payload;
    },
    setCarbonData: (state, action: PayloadAction<Array<CarbonData> | undefined>) => {
      state.carbonData = action.payload;
    },
  },
});
