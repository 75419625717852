import YatungCheckBox from '@Src/_basic/components/YatungCheckBox';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import { Options } from '@Src/_basic/components/YatungSelect';
import PlateNumber from '@Src/_basic/icons/PlateNumber';
import { PutVehicleData } from '@Src/_basic/object/VehicleType';
import { SelectChangeEvent, Stack, Typography } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  values: PutVehicleData | any;
  setValues: (name: string, number: any) => void;
  typeOption: Options[];
  factoryOption: Options[];
}
export default function VehicleManagementUpdateField(props: Props) {
  const { t: i18T } = useTranslation();
  const { values, setValues, typeOption, factoryOption } = props;

  const handleChangeType = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const valueTurnToNumber = Number(value);
    if (!values.type.includes(valueTurnToNumber)) {
      setValues('type', [...values.type, valueTurnToNumber]);
    } else {
      const index = values.type.indexOf(valueTurnToNumber);
      if (index > -1) {
        values.type.splice(index, 1);
      }
      setValues('type', [...values.type]);
    }
  };
  const handleChangeFactory = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const valueTurnToNumber = Number(value);
    if (!values.factory?.includes(valueTurnToNumber)) {
      setValues('factory', [...values.factory, valueTurnToNumber]);
    } else {
      const index = values.factory.indexOf(valueTurnToNumber);
      if (index > -1) {
        values.factory.splice(index, 1);
      }
      setValues('factory', [...values.factory]);
    }
  };
  return (
    <>
      <Stack flexDirection={'column'} sx={{ mt: 2 }}>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <YatungFormLabel label={i18T('USERSMANAGEMENU.VEHICLE_MANAGEMENT.PLATE_NUMBER')} />
        </Stack>
        <Stack flexDirection={'row'} sx={{ my: 1 }}>
          <Field
            id="plateNumber"
            name="plateNumber"
            type="text"
            label=""
            component={YatungFormInput}
            sx={{ width: '100%', ml: 1 }}
            position={'start'}
            icon={<PlateNumber sx={{ width: 40, height: 40 }} />}
            required
          />
        </Stack>
        <Stack flexDirection={'row'} sx={{ mt: 2 }}>
          <YatungFormLabel label={i18T('USERSMANAGEMENU.VEHICLE_MANAGEMENT.TYPE')} />
        </Stack>
        <Stack flexDirection={'row'} sx={{ ml: 1, mt: 3 }}>
          <Field
            id="type"
            name="type"
            type="checkbox"
            label=""
            onChange={handleChangeType}
            component={YatungCheckBox}
            initialCheckedValue={values.type}
            options={typeOption}
            sx={{ mr: 2 }}
            required
          />
        </Stack>
        <ErrorMessage
          name={'type'}
          render={(msg) => (
            <Stack flexDirection={'row'} sx={{ ml: 1, mt: 1 }}>
              <Typography sx={{ fontSize: 14, color: '#d32f2f' }}>{msg}</Typography>
            </Stack>
          )}
        />
        <Stack flexDirection={'row'} sx={{ mt: 2 }}>
          <Stack flexDirection={'row'} sx={{ width: '15%' }}>
            <YatungFormLabel label={i18T('USERSMANAGEMENU.VEHICLE_MANAGEMENT.SITE')} />
          </Stack>
        </Stack>
        <Stack flexDirection={'row'} sx={{ ml: 1, mt: 3 }}>
          <Field
            isFactory
            id="factory"
            name="factory"
            type="checkbox"
            label=""
            onChange={handleChangeFactory}
            component={YatungCheckBox}
            initialCheckedValue={values.factory}
            options={factoryOption}
            sx={{ mr: 1 }}
            required
          />
        </Stack>
        <ErrorMessage
          name={'factory'}
          render={(msg) => (
            <Stack flexDirection={'row'} sx={{ ml: 1, mt: 1 }}>
              <Typography sx={{ fontSize: 14, color: '#d32f2f' }}>{msg}</Typography>
            </Stack>
          )}
        />
      </Stack>
    </>
  );
}
