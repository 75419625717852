import useGetAllFactories from '@Src/_basic/apiHooks/useGetAllFactories';
import useGetAllOrganize from '@Src/_basic/apiHooks/useGetAllOrganize';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import NewStaffIcon from '@Src/_basic/icons/NewStaff';
import SearchIcon from '@Src/_basic/icons/Search';
import { useApi } from '@Src/redux/api/apiAction';
import { SelectChangeEvent, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FilterValue } from '..';

interface Props {
  filterValue: FilterValue;
  handleChange: (file: keyof FilterValue, value: FilterValue[keyof FilterValue]) => void;
  getAllAccount: () => void;
}

export default function AccountManagementSearchBar({ filterValue, handleChange, getAllAccount }: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { actionLoading } = useApi();

  const { factoryOption } = useGetAllFactories();
  const { organizeOption } = useGetAllOrganize();

  const handleGoToCreatePage = () => {
    navigate('/UsersManagePage/AccountManagement/AccountManagementCreate');
  };

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
        <YatungSelect
          helperText={i18T('GLOBAL.FACTORY')}
          disabled={actionLoading}
          options={factoryOption}
          value={filterValue.factory}
          onChange={(e: SelectChangeEvent<unknown>) => {
            handleChange('factory', e.target.value as number);
          }}
        />
        <YatungSelect
          helperText={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.ORGANIZE')}
          disabled={actionLoading}
          options={organizeOption}
          value={filterValue.organize}
          onChange={(e: SelectChangeEvent<unknown>) => {
            handleChange('organize', e.target.value as number);
          }}
        />
        <YatungInput
          value={filterValue.inputValues}
          onChange={(e: any) => handleChange('inputValues', e.target.value)}
        />
        <YatungButton
          text={i18T('GLOBAL.SEARCh_BUTTON')}
          startIcon={<SearchIcon />}
          color="blue"
          onClick={getAllAccount}
        />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <YatungButton
          text={i18T('GLOBAL.NEW_STAFF')}
          variant={'contained'}
          type={'button'}
          size={'large'}
          startIcon={<NewStaffIcon />}
          onClick={handleGoToCreatePage}
          color={'green'}
          sx={{
            fontSize: '16px',
          }}
        />
      </Stack>
    </Stack>
  );
}
