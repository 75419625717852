export function formatNumber(number: string | null | number): string {
  if (number === null) return '';

  const _number = typeof number === 'string' ? number : number?.toString();

  if (_number?.includes('.')) {
    const splitNumber = _number?.split('.');

    const afterPointNumber = splitNumber[1]?.split('');

    const result = [];
    let foundNonZero = false;

    for (let i = afterPointNumber.length - 1; i >= 0; i--) {
      if (afterPointNumber[i] !== '0' || foundNonZero) {
        result.unshift(afterPointNumber[i]);
        foundNonZero = true;
      }
    }

    if (result.length === 0) return splitNumber[0];
    return `${splitNumber[0]}.${result.join('')}`;
  } else return _number;
}

export function getInteger(number: string): string {
  if (number.includes('.')) {
    return number.split('.')[0];
  } else return number;
}
