import { CustomStandardV2SpecificationDetailsResponse } from '../object/CustomStandardType';
import { LegalStandardV2SpecificationDetailsResponse } from '../object/LegalStandardV2Type';
import { AllVerifyCustomResults, AllVerifyPassedResults, CustomResultItem } from '../object/TestType';
import {
  CoarseSieveVerifyCustomResultItems,
  CoarseSieveVerifyPassedResultItems,
} from '../object/test/sandRock/CoarseSieveType';

export const isLegalStandardPassed = (
  result: AllVerifyPassedResults | CoarseSieveVerifyPassedResultItems | Map<string, boolean>,
) => {
  if (result) {
    if (result instanceof Map) {
      return Array.from(result.values()).every((value) => value);
    }

    return Object.values(result).every((value: boolean) => value);
  } else {
    return false;
  }
};

export const isCustomStandardPassed = (
  result: AllVerifyCustomResults | CoarseSieveVerifyCustomResultItems | Map<string, boolean>,
) => {
  if (result) {
    if (result instanceof Map) {
      return Array.from(result.values()).every((value) => value);
    }

    return Object.values(result).every((value: CustomResultItem) => value.passed);
  } else {
    return false;
  }
};

export const isRejected = (
  result: AllVerifyCustomResults | CoarseSieveVerifyCustomResultItems | Map<string, boolean>,
) => {
  if (result) {
    if (result instanceof Map) {
      return Array.from(result.values()).some((value) => value);
    }

    return Object.values(result).some((value: CustomResultItem) => value.rejected);
  } else {
    return true;
  }
};

export const getPassedResultByKey = (
  standard:
    | Map<string, LegalStandardV2SpecificationDetailsResponse | CustomStandardV2SpecificationDetailsResponse>
    | undefined,
  key: string,
  value: number,
) => {
  if (standard?.get(key)) {
    if (standard?.get(key)?.minValue !== null && standard?.get(key)?.maxValue !== null) {
      return (standard?.get(key)?.minValue || 0) <= value && value <= (standard?.get(key)?.maxValue || 0);
    } else if (standard?.get(key)?.maxValue !== null) {
      return value <= (standard?.get(key)?.maxValue || 0);
    } else if (standard?.get(key)?.minValue !== null) {
      return (standard?.get(key)?.minValue || 0) <= value;
    }
  }

  return true;
};

export const getRejectedResultByKey = (
  standard: Map<string, CustomStandardV2SpecificationDetailsResponse> | undefined,
  key: string,
  value: number,
) => {
  if (standard?.get(key)) {
    if (standard?.get(key)?.rejectMaxValue !== null) {
      return value <= (standard?.get(key)?.rejectMaxValue || 0);
    } else if (standard?.get(key)?.rejectMinValue !== null) {
      return value >= (standard?.get(key)?.rejectMinValue || 0);
    }
  }

  return false;
};
