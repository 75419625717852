import { ChildrenTypography, TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import {
  ExptConcreteCompStr,
  MonthlyReportConcreteAntiStress,
  ReportViewResponse,
} from '@Src/_basic/object/MonthlyReportType';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { SetStateAction, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportBlock from './components/ReportBlock';
import RowContent from './components/RowContent';
import ToggleBlockButton from './components/ToggleBlockButton';

type FilterType = 'below100' | 'below110' | 'below130' | 'maxAndSmall' | null;

type ValueRatioReduce = {
  value: Array<number>;
  ratio: Array<number>;
};

const ConcreteAntiStressReport = ({
  report,
  headerData,
}: {
  report: Array<MonthlyReportConcreteAntiStress>;
  headerData: ReportViewResponse;
}) => {
  const { t: i18T } = useTranslation();
  const [summary, setSummary] = useState({
    max: 0,
    min: 0,
    average: 0,
  });
  const [abnormalSummary, setAbnormalSummery] = useState({
    below100: 0,
    below110: 0,
    below130: 0,
  });
  const [selectedFilter, setSelectedFilter] = useState<SetStateAction<FilterType>>(null);

  const [reports, setReports] = useState<Array<MonthlyReportConcreteAntiStress>>([]);
  const [filteredReport, setFilteredReport] = useState<Array<MonthlyReportConcreteAntiStress>>([]);

  const handleFilterChange = (filter: FilterType) => () => {
    const newFilter = selectedFilter === filter ? null : filter;
    const filteredReport = reports.filter((d: MonthlyReportConcreteAntiStress) => {
      switch (newFilter) {
        case 'below100':
          return d.exptConcreteCompStr.filter((_item) => _item.strengths.find((item) => item.ratio < 100));
        case 'below110':
          return d.exptConcreteCompStr.filter((_item) => _item.strengths.find((item) => item.ratio < 100));
        case 'maxAndSmall':
          // eslint-disable-next-line no-case-declarations
          const { max, min } = summary;
          return (
            d.exptConcreteCompStr.filter((_item) => _item.strengths.find((item) => item.value === max)) ||
            d.exptConcreteCompStr.filter((_item) => _item.strengths.find((item) => item.value === min))
          );
        default:
          return true;
      }
    });
    setFilteredReport(filteredReport);
    setSelectedFilter(newFilter);
  };

  useEffect(() => {
    let sum = 0;
    let max = Number.MIN_VALUE;
    let min = report.length > 0 ? Number.MAX_VALUE : 0;

    if (report.length === 0) {
      setReports(report);
      setFilteredReport(report);
      setSummary({
        max,
        min,
        average: 0,
      });
      return;
    }

    const allStrengthRatio: Array<ValueRatioReduce> = report?.map((_item) => {
      return _item.exptConcreteCompStr?.reduce(
        (acc: ValueRatioReduce, cur: ExptConcreteCompStr) => {
          return {
            value: [...acc.value, ...cur.strengths.map((item) => item.value)],
            ratio: [...acc.ratio, ...cur.strengths.map((item) => item.ratio)],
          };
        },
        {
          value: [],
          ratio: [],
        } as ValueRatioReduce,
      );
    });

    max = Math.max(max, ...allStrengthRatio[0].value);
    min = Math.min(max, ...allStrengthRatio[0].value);
    sum = allStrengthRatio[0].value.reduce((acc, cur) => acc + cur, 0);

    const abnormalSummary = {
      below100: allStrengthRatio[0].ratio.filter((item) => item < 100).length,
      below110: allStrengthRatio[0].ratio.filter((item) => item < 110).length,
      below130: allStrengthRatio[0].ratio.filter((item) => item < 130).length,
    };

    setReports(report);
    setFilteredReport(report);
    setAbnormalSummery(abnormalSummary);
    setSummary({
      max,
      min,
      average: 0,
    });
  }, [report]);

  return (
    <>
      <TestCardContainer sx={{ flexDirection: 'row', gap: 3, justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <ToggleBlockButton
          isToggled={selectedFilter === 'below100'}
          onPress={handleFilterChange('below100')}
          top={
            <>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ANTI_STRESS.UN_ACHIEVED`)}100%`}</ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.ANTI_STRESS.DESIGN_STR`)}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.below100 ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.below100}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.ITEM`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'below110'}
          onPress={handleFilterChange('below110')}
          top={
            <>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ANTI_STRESS.UN_ACHIEVED`)}110%`}</ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.ANTI_STRESS.DESIGN_STR`)}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.below110 ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.below110}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.ITEM`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'below130'}
          onPress={handleFilterChange('below130')}
          top={
            <>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ANTI_STRESS.UN_ACHIEVED`)}130%`}</ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.ANTI_STRESS.DESIGN_STR`)}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography style={abnormalSummary.below130 ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.below130}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.ITEM`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          top={
            <>
              <ChildrenTypography>{`${
                new Date(headerData.endTime).getDate() - new Date(headerData.startTime).getDate() + 1
              }${i18T(`MONTHLY_REPORT.BASE.DAY`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ANTI_STRESS.ANTI_STRESS_STR`)}${i18T(
                `MONTHLY_REPORT.BASE.AVERAGE`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography>{summary.average?.toFixed(1)}</ChildrenTypography>
              <ChildrenTypography>Kgf/cm²</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          top={
            <>
              <ChildrenTypography>{`30${i18T(`MONTHLY_REPORT.BASE.DAY`)}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ANTI_STRESS.ANTI_STRESS_STR`)}${i18T(
                `MONTHLY_REPORT.BASE.SPACING`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1}>
              <ChildrenTypography>{(summary.max - summary.min)?.toFixed(1)}</ChildrenTypography>
              <ChildrenTypography>Kgf/cm²</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'maxAndSmall'}
          onPress={handleFilterChange('maxAndSmall')}
          top={
            <>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ANTI_STRESS.ANTI_STRESS_STR`)}${i18T(
                `MONTHLY_REPORT.BASE.MAX`,
              )}`}</ChildrenTypography>
              <Stack>
                <ChildrenTypography> {summary.max?.toFixed(1)}</ChildrenTypography>
                <ChildrenTypography> Kgf/cm²</ChildrenTypography>
              </Stack>
            </>
          }
          bottom={
            <>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.ANTI_STRESS.ANTI_STRESS_STR`)}${i18T(
                `MONTHLY_REPORT.BASE.MIN`,
              )}`}</ChildrenTypography>
              <Stack>
                <ChildrenTypography> {summary.min?.toFixed(1)}</ChildrenTypography>
                <ChildrenTypography> Kgf/cm²</ChildrenTypography>
              </Stack>
            </>
          }
        />
      </TestCardContainer>
      {filteredReport &&
        filteredReport?.map((item: MonthlyReportConcreteAntiStress) => (
          <TestCardContainer key={item.id}>
            <RowContent
              labels={['規格', '配比']}
              values={[item.specificationName ?? '350', item.proportionName ?? 'RS-350']}
            />
            <RowContent
              labels={[
                i18T(`MONTHLY_REPORT.ANTI_STRESS.TEST_CAR_NUMBER`),
                i18T(`MONTHLY_REPORT.ANTI_STRESS.SPECIMEN_PRODUCTION`),
              ]}
              values={[item.carNo ? item.carNo : '無資訊', item.testSample]}
            />
            {item.exptConcreteCompStr?.map((_item) => (
              <Stack key={_item.exptId} my={1}>
                <ReportBlock
                  tester={_item.tester}
                  exptId={_item.exptId}
                  exptItemCode={_item.exptItemCode}
                  verifyCustomResults={true}
                  verifyPassedResults={true}
                >
                  <Stack>
                    <Stack flexDirection="row">
                      <ChildrenTypography>{`${_item.specimenAge}日`}</ChildrenTypography>
                      <ChildrenTypography>{moment(_item.testDate).format('yyyy-MM-DD HH:mm')}</ChildrenTypography>
                    </Stack>

                    {_item.strengths?.map((_strengthsItem, index) => (
                      <RowContent
                        key={_strengthsItem.id}
                        widths={[60, 40]}
                        labels={[
                          `${i18T(`MONTHLY_REPORT.ANTI_STRESS.SPECIMEN_STR`, { index: index + 1 })}\n(kgf/cm²)`,
                          `${i18T(`MONTHLY_REPORT.ANTI_STRESS.ACHIEVE_DESIGN_STR`)}(%)`,
                        ]}
                        values={[
                          _strengthsItem.value ? _strengthsItem?.value.toFixed(2) : '0',
                          [_strengthsItem?.ratio, _strengthsItem.ratio < 100],
                        ]}
                      />
                    ))}
                  </Stack>
                </ReportBlock>
              </Stack>
            ))}
          </TestCardContainer>
        ))}
    </>
  );
};

export default memo(ConcreteAntiStressReport);
