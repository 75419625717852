import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DeliveryInformationSlump(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="39" viewBox="0 0 45 39" fill="none">
        <path
          d="M36.2365 26.09C36.2365 25.4373 35.7074 24.9082 35.0547 24.9082H10.2365C9.58381 24.9082 9.05469 25.4373 9.05469 26.09C9.05469 26.7427 9.58381 27.2718 10.2365 27.2718H35.0547C35.7074 27.2718 36.2365 26.7427 36.2365 26.09Z"
          fill="#7C878E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0547 24.9082C35.7074 24.9082 36.2365 25.4373 36.2365 26.09C36.2365 26.7427 35.7074 27.2718 35.0547 27.2718H10.2365C9.58381 27.2718 9.05469 26.7427 9.05469 26.09C9.05469 25.4373 9.58381 24.9082 10.2365 24.9082H35.0547Z"
          fill="#7C878E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4133 31.9666V33.1822H42.1406V31.9666L40.4573 31.69V29.637H42.1406V28.4552H40.4573V28.4551L39.0967 28.4551V28.4552H6.18455H4.82394H3.14062V29.637H4.82394V31.6901L3.14062 31.9668V33.1824H7.8679V31.9668L6.18455 31.6901V29.637H39.0967V31.69L37.4133 31.9666Z"
          fill="#7C878E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4133 31.9666V33.1822H42.1406V31.9666L40.4573 31.69V29.637H42.1406V28.4552H40.4573L39.0967 28.4551L3.14062 28.4552V29.637H4.82394V31.6901L3.14062 31.9668V33.1824H7.8679V31.9668L6.18455 31.6901V29.637H39.0967V31.69L37.4133 31.9666Z"
          fill="#7C878E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.9059 16.0757L26.9581 5.99986H18.5679L17.5556 16.1505L15.5659 16.8834C15.2652 16.9945 15.111 17.3282 15.2217 17.629C15.3126 17.8756 15.5532 18.0236 15.8027 18.008C15.8576 18.0045 15.9129 17.9931 15.9672 17.9731L17.4275 17.4352L16.8597 23.1289H28.5695L28.0265 17.3576L29.6972 17.973C29.6971 17.973 29.6972 17.973 29.6972 17.973C29.9982 18.0838 30.3323 17.9299 30.4432 17.629C30.554 17.3281 30.3998 16.9944 30.099 16.8834C30.099 16.8834 30.0991 16.8834 30.099 16.8834L27.9059 16.0757ZM32.6339 22.3026L32.7789 23.1288H28.9565L28.8595 22.3026H32.6339ZM12.6016 23.1288L12.7468 22.3026H16.521L16.4242 23.1288H12.6016ZM26.8558 21.5689H18.583L19.2272 15.1098L19.98 7.55986H25.5379L26.2413 15.0376L26.8558 21.5689Z"
          fill="#7C878E"
        />
      </svg>
    </SvgIcon>
  );
}
