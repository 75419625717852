import AggregateIcon from '@Src/_basic/icons/GradientBackground/Aggregate';
import React, { useRef, useState } from 'react';
import MaterialsReceiptMakeUpOrderModal from '../MaterialsReceiptMakeUpOrderModal';

interface Props {
  selectedFactory: number;
}
export default function AggregateButton(props: Props) {
  const clickedIdRef = useRef<string | null>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { selectedFactory } = props;
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setModalOpen(true);
    const divId = event.currentTarget.id;
    clickedIdRef.current = divId;
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <>
      <MaterialsReceiptMakeUpOrderModal
        clickedIdRef={clickedIdRef}
        modalOpen={modalOpen}
        onClose={handleClose}
        selectedFactory={selectedFactory}
      />
      {selectedFactory === 0 ? (
        <div id="2" role="button" style={{ position: 'relative', cursor: 'not-allowed' }}>
          <AggregateIcon />
        </div>
      ) : (
        <div id="2" role="button" style={{ position: 'relative', cursor: 'pointer' }} onClick={handleClick}>
          <AggregateIcon />
        </div>
      )}
    </>
  );
}
