import { ChildrenTypography, TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import {
  MonthlyReportFineAggChloride,
  ReportViewResponse,
  ResultAndIsAbnormal,
} from '@Src/_basic/object/MonthlyReportType';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { SetStateAction, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportBlock from './components/ReportBlock';
import RowContent from './components/RowContent';
import ToggleBlockButton from './components/ToggleBlockButton';

type FilterType = 'pieces' | 'days' | null;

const FineAggregateChlorideIonsReport = ({
  report,
  headerData,
}: {
  report: Array<MonthlyReportFineAggChloride<ResultAndIsAbnormal<number>>>;
  headerData: ReportViewResponse;
}) => {
  const { t: i18T } = useTranslation();
  const [abnormalSummary, setAbnormalSummery] = useState({
    pieces: 0,
    days: 0,
    dateRecord: {},
  });
  const [selectedFilter, setSelectedFilter] = useState<SetStateAction<FilterType>>(null);

  const [reports, setReports] = useState<Array<MonthlyReportFineAggChloride<ResultAndIsAbnormal<number>>>>([]);
  const [filteredReport, setFilteredReport] = useState<
    Array<MonthlyReportFineAggChloride<ResultAndIsAbnormal<number>>>
  >([]);

  const handleFilterChange = (filter: FilterType) => () => {
    const newFilter = selectedFilter === filter ? null : filter;
    const filteredReport = reports.filter((d: MonthlyReportFineAggChloride<ResultAndIsAbnormal<number>>) => {
      switch (newFilter) {
        case 'pieces':
          return d.chlorideIons.isAbnormal;
        case 'days':
          // eslint-disable-next-line no-case-declarations
          const date = moment(d.testDate).format('yyyy/MM/dd');
          // eslint-disable-next-line no-case-declarations
          const abnormalDate = Object.keys(abnormalSummary.dateRecord);
          return abnormalDate.findIndex((_d) => _d === date && d.chlorideIons.isAbnormal) > -1;
        default:
          return true;
      }
    });
    setSelectedFilter(newFilter);
    setFilteredReport(filteredReport);
  };

  useEffect(() => {
    const dateRecord: any = {};
    let pieces = 0;
    report.forEach((d: MonthlyReportFineAggChloride<ResultAndIsAbnormal<number>>) => {
      if (d.chlorideIons.isAbnormal) {
        const date = moment(d.testDate).format('yyyy/MM/dd');
        if (!dateRecord[date]) dateRecord[date] = 1;
        pieces += 1;
      }
    });

    setReports(report);
    setFilteredReport(report);
    setAbnormalSummery({
      pieces,
      days: Object.keys(dateRecord).length,
      dateRecord,
    });
  }, [report]);

  return (
    <>
      <TestCardContainer sx={{ flexDirection: 'row', gap: 3, justifyContent: 'space-between' }}>
        <ToggleBlockButton
          top={
            <>
              <ChildrenTypography>{`${
                new Date(headerData?.endTime).getDate() - new Date(headerData?.startTime).getDate() + 1
              }
              ${i18T(`MONTHLY_REPORT.BASE.DAY`)}${i18T(
                `MONTHLY_REPORT.CHLORIDE.CHLORIDE_CONTENT`,
              )}`}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.TEST`)}${i18T(
                `MONTHLY_REPORT.BASE.ITEMS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1} flexDirection="row">
              <ChildrenTypography>{reports?.length}</ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.ITEM`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'pieces'}
          onPress={handleFilterChange('pieces')}
          top={
            <>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.CHLORIDE.CHLORIDE_CONTENT`)}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.ITEMS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack flexDirection="row" mt={3}>
              <ChildrenTypography style={abnormalSummary.pieces ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.pieces}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.ITEM`)}</ChildrenTypography>
            </Stack>
          }
        />
        <ToggleBlockButton
          isToggled={selectedFilter === 'days'}
          onPress={handleFilterChange('days')}
          top={
            <>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.CHLORIDE.CHLORIDE_CONTENT`)}</ChildrenTypography>
              <ChildrenTypography>{`${i18T(`MONTHLY_REPORT.BASE.ABNORMAL`)}${i18T(
                `MONTHLY_REPORT.BASE.DAYS`,
              )}`}</ChildrenTypography>
            </>
          }
          bottom={
            <Stack pt={1} flexDirection="row">
              <ChildrenTypography style={abnormalSummary.days ? { color: `#FFA0F0` } : {}}>
                {abnormalSummary.days}
              </ChildrenTypography>
              <ChildrenTypography>{i18T(`MONTHLY_REPORT.BASE.DAY`)}</ChildrenTypography>
            </Stack>
          }
        />
      </TestCardContainer>
      {filteredReport &&
        filteredReport?.map((item: MonthlyReportFineAggChloride<ResultAndIsAbnormal<number>>) => (
          <ReportBlock
            key={item.exptId}
            tester={item.tester}
            exptId={item.exptId}
            exptItemCode={item.exptItemCode}
            verifyPassedResults={item.verifyPassedResults}
            verifyCustomResults={item.verifyCustomResults}
          >
            <RowContent
              widths={[32, 32, 35]}
              labels={[
                i18T(`MONTHLY_REPORT.BASE.TEST_DATE`),
                i18T(`MONTHLY_REPORT.BASE.SAMPLING_DATE`),
                i18T(`MONTHLY_REPORT.BASE.SAMPLING_PLACE`),
              ]}
              values={[
                moment(item.testDate).format('YYYY/MM/DD'),
                moment(item.sampleDate).format('YYYY/MM/DD'),
                item?.samplePosition,
              ]}
            />
            <RowContent
              widths={[32, 32, 35]}
              labels={[
                i18T(`MONTHLY_REPORT.CHLORIDE.SAMPLE_SOURCE`),
                `${i18T(`MONTHLY_REPORT.CHLORIDE.CHLORIDE_CONTENT`)}(%)`,
                i18T(`MONTHLY_REPORT.CHLORIDE.TEST_RESULT`),
              ]}
              values={[
                item?.sampleSource,
                [item?.chlorideIons?.result?.toFixed(4) ?? '0', item?.chlorideIons.isAbnormal],
                ['', item?.chlorideIons.isAbnormal],
              ]}
            />
          </ReportBlock>
        ))}
    </>
  );
};

export default memo(FineAggregateChlorideIonsReport);
