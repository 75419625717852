import YatungButton from '@Src/_basic/components/YatungButton';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Upload } from '@mui/icons-material';
import { Box, Paper } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import UploadFileCard from './components/UploadFileCard';
import ViewReports from './components/ViewReports';

export default function Reports() {
  // TODO using redux & i18n

  const { t: i18T } = useTranslation();
  const { projectInfo } = useProductionHistory();

  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadButtonClick = () => {
    hiddenFileInputRef?.current?.click();
  };

  return (
    <Paper elevation={4} sx={{ position: 'relative', height: '100%', padding: '12px 16px' }}>
      {projectInfo.reports.length > 0 ? (
        <>
          <ViewReports hiddenFileInputRef={hiddenFileInputRef} />
          <Box sx={{ position: 'absolute', top: '16px', right: '16px' }}>
            <YatungButton
              text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.UPLOAD`)}
              color="blue"
              startIcon={<Upload />}
              onClick={handleUploadButtonClick}
            />
          </Box>
        </>
      ) : (
        <UploadFileCard />
      )}
    </Paper>
  );
}
