import { Response } from '@Src/_basic/object/ApiType';
import {
  GetMonitorParams,
  MonitorAreaData,
  MonitorCustomerData,
  MonitorFactoryData,
  MonitorOrderData,
} from '@Src/_basic/object/MonitorType';
import { Api } from '../Api';
import { MonitorProtocol } from './MonitorProtocol';

export class MonitorApi {
  // 取得所有出貨資訊By區域
  public static getMonitorArea(
    params: GetMonitorParams,
    onSuccess?: (data: Array<MonitorAreaData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(MonitorProtocol.GET_MONITOR_AREA, config, onSuccess, onFailed, onFinally);
  }

  public static getMonitorFactory(
    //取得所有出貨的工廠
    id: number,
    params: GetMonitorParams,
    onSuccess?: (data: Array<MonitorFactoryData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(MonitorProtocol.GET_MONITOR_FACTORY + `/${id}` + '/factory', config, onSuccess, onFailed, onFinally);
  }

  public static getMonitorCustomer(
    //取得所有出貨的客戶
    id: number,
    params: GetMonitorParams,
    onSuccess?: (data: Array<MonitorCustomerData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };
    Api.get(MonitorProtocol.GET_MONITOR_CUSTOMER + `/${id}` + '/customer', config, onSuccess, onFailed, onFinally);
  }

  public static searchMonitorCustomer(
    //模糊搜尋出貨的客戶
    factoryId: number,
    params: GetMonitorParams,
    onSuccess?: (data: Array<MonitorCustomerData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };
    Api.get(
      MonitorProtocol.SEARCH_CUSTOMER + `/${factoryId}` + '/customer/fuzzy',
      config,
      onSuccess,
      onFailed,
      onFinally,
    );
  }

  public static getMonitorOrder(
    //取得所有出貨的訂單車次
    factoryId: number,
    customerId: number,
    orderId: string,
    params: GetMonitorParams,
    onSuccess?: (data: Array<MonitorOrderData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = { params };
    Api.get(
      MonitorProtocol.GET_MONITOR_SHIPMENT +
        `/${factoryId}` +
        '/customer' +
        `/${customerId}` +
        '/order' +
        `/${orderId}` +
        '/shipment' +
        '/V2',
      config,
      onSuccess,
      onFailed,
      onFinally,
    );
  }
}
