import React from 'react';
import Left from './left';
import Right from './right';

interface Props {
  children: React.ReactNode;
}

export default function Layout({ children }: Props) {
  return (
    <div style={{ display: 'flex' }}>
      <Left />
      <Right>{children}</Right>
    </div>
  );
}
