import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import CustomStandards from './pages/CustomStandards';
import ExptSchedule from './pages/ExptSchedule';
import InformAndFineRecord from './pages/InformAndFineRecord';
import LegalStandards from './pages/LegalStandards';
import SampleCourse from './pages/SampleCourse';
import TestManage from './pages/TestManage';

const routesConfig: RouteConfig[] = [
  {
    path: '/exptSchedule/*',
    element: <ExptSchedule />,
  },
  {
    path: '/legalStandards/*',
    element: <LegalStandards />,
    permission: 'WD034',
  },
  {
    path: '/customStandards/*',
    element: <CustomStandards />,
    permission: 'WD035',
  },
  {
    path: '/testManage/*',
    element: <TestManage />,
    permission: 'WD038',
  },
  {
    path: '/sampleCourse/*',
    element: <SampleCourse />,
    permission: 'WD063',
  },
  {
    path: '/informAndFineRecord/*',
    element: <InformAndFineRecord />,
    permission: 'WD064',
  },
];

const QualityManagement = () => PermissionRoute(routesConfig);

export default memo(QualityManagement);
