import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';

import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungIconSelect from '@Src/_basic/components/YatungIconSelect';
import ContentMangementIcon from '@Src/_basic/icons/ContentMangement';
import SourceIcon from '@Src/_basic/icons/Source';
import SpecIcon from '@Src/_basic/icons/Spec';
import StaffIcon from '@Src/_basic/icons/Staff';
import TypeIcon from '@Src/_basic/icons/Type';

import { Options } from '@Src/_basic/components/YatungSelect';
import { NumberData } from '../index';

import { PostMaterialFactoryCodeRequest } from '@Src/_basic/object/MaterialFactoryCodeType';
import { useTranslation } from 'react-i18next';
import EditForm from '../../../components/EditForm';

interface Props {
  open?: boolean;
  handleClose: () => void;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (data: Omit<PostMaterialFactoryCodeRequest, 'factoryId'>, mode: 'add') => void;
  title: string;
  icon: JSX.Element;
  allNumbersData: NumberData;
  facCode: string;
}

interface CircleIconProps {
  icon: JSX.Element;
}

interface AllNumbersOptions {
  suppliers: Options[];
  sources: Options[];
  specs: Options[];
  attributes: Options[];
}

interface Data {
  typeId: number;
  supplierId: number;
  sourceId: number;
  specificationId: number;
  attributeId: number;
}

const CircleIcon = ({ icon }: CircleIconProps) => {
  return <Box sx={{ p: 0.5, borderRadius: '50%', border: '1px solid', height: '34px' }}>{icon}</Box>;
};

export default function AddModal({ open, handleClose, handleSubmit, title, icon, allNumbersData, facCode }: Props) {
  const { t: i18T } = useTranslation();
  const [data, setData] = useState<Data>({ typeId: 0, supplierId: 0, sourceId: 0, specificationId: 0, attributeId: 0 });
  const [code, setCode] = useState<string>('');
  const [allNumbersOptions, setAllNumbersOptions] = useState<AllNumbersOptions>({
    suppliers: [],
    sources: [],
    specs: [],
    attributes: [],
  });

  const onSubmit = useCallback(() => {
    if (data) handleSubmit(data, 'add');
  }, [handleSubmit, data]);

  const handleTypeSelect = useCallback(() => {
    const typeId = allNumbersData.type?.value;

    const suppliersList = allNumbersData.suppliers?.filter(({ type }) => type.id === typeId);
    const sourcesList = allNumbersData.sources?.filter(({ type }) => type.id === typeId);
    const attributesList = allNumbersData.attributes?.filter((item) => item.typeId === typeId);
    const specsList = allNumbersData.specs?.filter((s) => s.typeId === typeId);

    if (suppliersList && sourcesList && attributesList && specsList) {
      setAllNumbersOptions({
        suppliers: suppliersList.map(({ name, id }) => ({ text: name, value: id })),
        sources: sourcesList.map(({ sourceName, id }) => ({ text: sourceName, value: id })),
        specs: specsList.map(({ name, id }) => ({ text: name, value: id })),
        attributes: attributesList.map(({ name, id }) => ({ text: name, value: id })),
      });

      setData({
        ...data,
        typeId: typeId,
        supplierId: 0,
        sourceId: 0,
        specificationId: 0,
        attributeId: 0,
      });
    }
  }, [allNumbersData, data]);

  useEffect(() => {
    if (open) handleTypeSelect();
    else setData({ typeId: 0, supplierId: 0, sourceId: 0, specificationId: 0, attributeId: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    let codeString = '';
    const supplier = allNumbersData.suppliers.find((t) => t.id === data.supplierId);
    const spec = allNumbersData.specs.find((t) => t.id === data.specificationId);
    const source = allNumbersData.sources.find((t) => t.id === data.sourceId);
    const attribute = allNumbersData.attributes.find((t) => t.id === data.attributeId);

    if (allNumbersData.type && allNumbersData.type.code) codeString += allNumbersData.type.code;
    if (supplier && supplier.code) codeString += supplier.code;
    if (source && source.code) codeString += source.code;
    if (spec && spec.code) codeString += spec.code;
    if (attribute && attribute.code) codeString += attribute.code;

    setCode(codeString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <YatungDialog
      open={open}
      handleClose={handleClose}
      icon={icon}
      title={title}
      body={
        <EditForm
          onSubmit={onSubmit}
          onCancel={handleClose}
          canSubmit={Boolean(
            data.typeId && data.supplierId && data.sourceId && data.specificationId && data.attributeId,
          )}
        >
          <Stack sx={{ py: 2 }} alignItems="center">
            <YatungIconSelect
              disabled={true}
              options={[{ value: allNumbersData.type?.value, text: allNumbersData.type?.text }]}
              value={allNumbersData.type?.value}
              onChange={() => ({})}
              icon={<CircleIcon icon={<TypeIcon />} />}
              label={i18T('APPLICATIONSMANAGEMENT.TABLE.TYPE')}
            />
            <YatungIconSelect
              disabled={!allNumbersOptions.suppliers.length}
              options={allNumbersOptions.suppliers}
              value={data.supplierId}
              onChange={(e) => setData({ ...data, supplierId: parseInt(e.target.value) })}
              icon={<CircleIcon icon={<StaffIcon />} />}
              label={i18T('APPLICATIONSMANAGEMENT.TABLE.SUPPLIER_NAME')}
            />
            <YatungIconSelect
              disabled={!allNumbersOptions.sources.length}
              options={allNumbersOptions.sources}
              value={data.sourceId}
              onChange={(e) => setData({ ...data, sourceId: parseInt(e.target.value) })}
              icon={<CircleIcon icon={<SourceIcon />} />}
              label={i18T('APPLICATIONSMANAGEMENT.TABLE.SOURCE')}
            />
            <YatungIconSelect
              disabled={!allNumbersOptions.specs.length}
              options={allNumbersOptions.specs}
              value={data.specificationId}
              onChange={(e) => setData({ ...data, specificationId: parseInt(e.target.value) })}
              icon={<CircleIcon icon={<SpecIcon />} />}
              label={i18T('APPLICATIONSMANAGEMENT.TABLE.SPECIFICATION')}
            />
            <YatungIconSelect
              disabled={!allNumbersOptions.attributes.length}
              options={allNumbersOptions.attributes}
              value={data.attributeId}
              onChange={(e) => setData({ ...data, attributeId: parseInt(e.target.value) })}
              icon={<CircleIcon icon={<ContentMangementIcon />} />}
              label={i18T('APPLICATIONSMANAGEMENT.TABLE.ATTRIBUTE')}
            />
            <Typography sx={{ fontSize: 16, textAlign: 'right', width: 378 }}>{`${i18T(
              'APPLICATIONSMANAGEMENT.TABLE.MATERIAL_CODE',
            )}:${facCode}${code}`}</Typography>
          </Stack>
        </EditForm>
      }
    />
  );
}
