import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectProps, styled } from '@mui/material';
import React from 'react';
import TriangleDown from '../icons/TriangleDown';

export type TextOptions = {
  value: string | undefined;
  text: string | undefined;
};

export interface Props extends SelectProps {
  options: Array<TextOptions>;
  helperText?: string;
  placeholder?: string;
  width?: string;
  isError?: boolean;
}

const SelectInput = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: 8,
  background: 'white',
  '& fieldset': {
    borderColor: '#6C708C',
  },
  '& .MuiInputBase-input': {
    minHeight: `0 !important`,
    fontSize: 20,
    padding: '8px 26px 8px 12px',
  },
}));

const TriangleDownIcon = styled(TriangleDown)(() => ({
  color: '#6C708C',
  fill: '#6C708C',
}));

export default function YatungTextSelect({ options, helperText, width, placeholder = '請選擇 - - -', isError, ...props }: Props) {
  return (
    <FormControl sx={{ minWidth: width ? 0 : 180 }}>
      {helperText && <InputLabel>{helperText}</InputLabel>}
      <Select
        defaultValue={0}
        input={<SelectInput label={helperText} />}
        IconComponent={TriangleDownIcon}
        {...props}
        sx={{
          ...props.sx,
          ...(width ? { width } : {}),
          border: isError ? "1px red solid" : {}
        }}
      >
        <MenuItem disabled value={0}>
          {placeholder}
        </MenuItem>
        {options?.map((option: TextOptions, index) => (
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
