import React from 'react';
import TransferOrderGroupManagementCard from '../components/TransferOrderGroupManagementCard';

export default function TransferOrderGroupManagementCardScreen() {
  return (
    <>
      <TransferOrderGroupManagementCard />
    </>
  );
}
