import React, { useEffect, useState } from 'react';
import { Stack, Modal, Box, Typography } from '@mui/material';
import moment from 'moment';

import YatungPageTitle from '@Src/_basic/components/YatungPageTitle';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungLoading from '@Src/_basic/components/YatungLoading';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import YatungInput from '@Src/_basic/components/YatungInput';

import EditIcon from '@Src/_basic/icons/Edit';
import StorageYardCreateTimeIcon from '@Src/_basic/icons/StorageYardCreateTime';
import StorageYardSupplierIcon from '@Src/_basic/icons/StorageYardSupplier';
import CarPlateIcon from '@Src/_basic/icons/CarPlate';
import ContentMangementIcon from '@Src/_basic/icons/ContentMangement';
import SourceIcon from '@Src/_basic/icons/Source';
import StorageYardSpecificationValueIcon from '@Src/_basic/icons/StorageYardSpecificationValue';

import { YardReceiptsApi } from '@Src/_basic/protocol/yardReceipts/YardReceiptsApi';
import { ChangeValueHelper } from '@Src/_basic/helpers/ChangeValueHelper';
import YatungAlert from '@Src/_basic/components/YatungAlert';
import { useTranslation } from 'react-i18next';
import {
  FormDataResponse,
  MoreConditionalDataResponse,
  OptionData,
  OptionSourceData,
  UpdateFormDataRequest,
  YardReciptData,
} from '@Src/_basic/object/YardReceiptsType';
import { MaterialReceiptsApi } from '@Src/_basic/protocol/materialReceipt/MaterialReceiptsApi';
import { SearchSSSParams, SSSData } from '@Src/_basic/object/MaterialReceiptsType';
import { SpecificationData } from '@Src/_basic/object/SpecificationType';

const StorageYardReciptInputItem = ({ Icon, label, children }: any) => {
  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="flex-start"
      direction="row"
      sx={{ p: 1, mt: 1, mb: 1, width: '100%', fontSize: '20px' }}
    >
      {Icon && <Icon sx={{ mr: 1 }} />}
      <Typography
        sx={{ minWidth: '60px', fontSize: '18px', flex: 'none', padding: '0 8px 0 0' }}
      >{`${label} :`}</Typography>
      <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none', padding: '0 8px 0 0' }}>
        {children}
      </Typography>
    </Stack>
  );
};

function StorageYardReciptUpdateContent({ id, onClose, receiptCode, suppliers }: any) {
  const { t: i18T } = useTranslation();
  const [specErrorOpen, setSpecErrorOpen] = useState<boolean>(false);
  const [totalFieldErrorOpen, setTotalFieldErrorOpen] = useState<boolean>(false);
  const [field2ErrorOpen, setField2ErrorOpen] = useState<boolean>(false);
  const [field3ErrorOpen, setField3ErrorOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<YardReciptData>({
    createTime: 0,
    factoryId: 0,
    id: 0,
    lastUpdateTime: 0,
    plateNumber: '',
    receiptCode: '',
    removed: false,
    source1Id: 0,
    source1Name: '',
    source2Id: 0,
    source2Name: '',
    source3Id: 0,
    source3Name: '',
    specification1Id: 0,
    specification1Name: '',
    specification1Value: 0,
    specification2Id: 0,
    specification2Name: '',
    specification2Value: 0,
    specification3Id: 0,
    specification3Name: '',
    specification3Value: 0,
    staffId: 0,
    staffName: '',
    stationId: 0,
    storageId: 0,
    storageName: '',
    supplier1Id: 0,
    supplier1Name: '',
    supplier2Id: 0,
    supplier2Name: '',
    supplier3Id: 0,
    supplier3Name: '',
    typeId: 0,
    typeName: '',
  });
  const [formData, setFormData] = useState<FormDataResponse>({
    plateNumber: '',
    specification1Id: 0,
    source1Id: 0,
    supplier1Id: 0,
    specification2Id: 0,
    source2Id: 0,
    supplier2Id: 0,
    specification3Id: 0,
    source3Id: 0,
    supplier3Id: 0,
    specification1Value: 0,
    specification2Value: 0,
    specification3Value: 0,
    staffName: '',
  });
  const [specifications1, setSpecifications1] = useState<Array<Options>>([]);
  const [specifications2, setSpecifications2] = useState<Array<Options>>([]);
  const [specifications3, setSpecifications3] = useState<Array<Options>>([]);

  const [sources, setSources] = useState<Array<Options>>([]);
  const [storageYardReciptsDataById, setStorageYardReciptsDataById] = useState<YardReciptData>({
    createTime: 0,
    factoryId: 0,
    id: 0,
    lastUpdateTime: 0,
    plateNumber: '',
    receiptCode: '',
    removed: false,
    source1Id: 0,
    source1Name: '',
    source2Id: 0,
    source2Name: '',
    source3Id: 0,
    source3Name: '',
    specification1Id: 0,
    specification1Name: '',
    specification1Value: 0,
    specification2Id: 0,
    specification2Name: '',
    specification2Value: 0,
    specification3Id: 0,
    specification3Name: '',
    specification3Value: 0,
    staffId: 0,
    staffName: '',
    stationId: 0,
    storageId: 0,
    storageName: '',
    supplier1Id: 0,
    supplier1Name: '',
    supplier2Id: 0,
    supplier2Name: '',
    supplier3Id: 0,
    supplier3Name: '',
    typeId: 0,
    typeName: '',
  });

  const getReceipt = () => {
    setLoading(true);
    YardReceiptsApi.getYardReceiptsByIdAndReceiptCode(id, receiptCode, onGetReceiptSuccess, undefined, () =>
      setLoading(false),
    );
  };

  const onGetReceiptSuccess = (data: YardReciptData) => {
    setStorageYardReciptsDataById(data);
    const factoryAndTypeId: SearchSSSParams = {
      factoryId: data.factoryId,
      typeId: data.typeId,
    };
    const SSSSpecifications1Params: SearchSSSParams = {
      factoryId: data.factoryId,
      typeId: data.typeId,
      sourceId: data.source1Id ? data.source1Id : 0,
    };
    const SSSSpecifications2Params: SearchSSSParams = {
      factoryId: data.factoryId,
      typeId: data.typeId,
      sourceId: data.source2Id ? data.source2Id : 0,
    };
    const SSSSpecifications3Params: SearchSSSParams = {
      factoryId: data.factoryId,
      typeId: data.typeId,
      sourceId: data.source3Id ? data.source3Id : 0,
    };
    MaterialReceiptsApi.getSSSArray(factoryAndTypeId, SSSSourcesToOptions);
    MaterialReceiptsApi.getSSSArray(SSSSpecifications1Params, (sssData: SSSData) => {
      SSSSpecificationsToOptions(sssData, setSpecifications1);
    });
    MaterialReceiptsApi.getSSSArray(SSSSpecifications2Params, (sssData: SSSData) => {
      SSSSpecificationsToOptions(sssData, setSpecifications2);
    });
    MaterialReceiptsApi.getSSSArray(SSSSpecifications3Params, (sssData: SSSData) => {
      SSSSpecificationsToOptions(sssData, setSpecifications3);
    });
    setDetail(data);
    setFormData({
      plateNumber: data.plateNumber,
      specification1Id: data.specification1Id !== null ? data.specification1Id : 0,
      specification2Id: data.specification2Id !== null ? data.specification2Id : 0,
      specification3Id: data.specification3Id !== null ? data.specification3Id : 0,
      source1Id: data.source1Id !== null ? data.source1Id : 0,
      source2Id: data.source2Id !== null ? data.source2Id : 0,
      source3Id: data.source3Id !== null ? data.source3Id : 0,
      supplier1Id: data.supplier1Id !== null ? data.supplier1Id : 0,
      supplier2Id: data.supplier2Id !== null ? data.supplier2Id : 0,
      supplier3Id: data.supplier3Id !== null ? data.supplier3Id : 0,
      specification1Value: data.specification1Value,
      specification2Value: data.specification2Value,
      specification3Value: data.specification3Value,
      staffName: data.staffName,
    });
  };

  const SSSSourcesToOptions = (data: SSSData) => {
    const sourcesOptions = data.sources.map((source: OptionSourceData) => ({
      value: source.id,
      text: source.sourceName,
    }));
    setSources(sourcesOptions);
  };

  const SSSSpecificationsToOptions = (data: SSSData, setOptions: (options: Array<Options>) => void) => {
    const specificationsOptions = data.specifications.map((spec: SpecificationData) => ({
      value: spec.id,
      text: spec.name,
    }));
    setOptions(specificationsOptions || []);
  };

  const [totalSpecificationValue, setTotalSpecificationValue] = useState<number>(0);
  const specificationValueCal = () => {
    const cal = formData.specification1Value + formData.specification2Value + formData.specification3Value;
    setTotalSpecificationValue(cal);
  };
  const handleFormDataChange = (field: string) => (value: unknown) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const handleSource1SelectChange = async (source1Id: number) => {
    const searchSSSParams: SearchSSSParams = {
      factoryId: storageYardReciptsDataById.factoryId,
      typeId: storageYardReciptsDataById.typeId,
      sourceId: source1Id,
    };
    MaterialReceiptsApi.getSSSArray(searchSSSParams, (sssData: SSSData) => {
      SSSSpecificationsToOptions(sssData, setSpecifications1);
    });

    setFormData({
      ...formData,
      source1Id,
      specification1Id: 0,
    });
  };
  const handleSource2SelectChange = async (source2Id: number) => {
    const searchSSSParams: SearchSSSParams = {
      factoryId: storageYardReciptsDataById.factoryId,
      typeId: storageYardReciptsDataById.typeId,
      sourceId: source2Id,
    };
    MaterialReceiptsApi.getSSSArray(searchSSSParams, (sssData: SSSData) => {
      SSSSpecificationsToOptions(sssData, setSpecifications2);
    });

    setFormData({
      ...formData,
      source2Id,
      specification2Id: 0,
    });
  };
  const handleSource3SelectChange = async (source3Id: number) => {
    const searchSSSParams: SearchSSSParams = {
      factoryId: storageYardReciptsDataById.factoryId,
      typeId: storageYardReciptsDataById.typeId,
      sourceId: source3Id,
    };
    MaterialReceiptsApi.getSSSArray(searchSSSParams, (sssData: SSSData) => {
      SSSSpecificationsToOptions(sssData, setSpecifications3);
    });
    setFormData({
      ...formData,
      source3Id,
      specification3Id: 0,
    });
  };

  const handleConfirmButtonClick = () => {
    setLoading(true);
    ChangeValueHelper(formData);
    const updateFormData: UpdateFormDataRequest = { ...formData, id, receiptCode };
    if (
      (formData.specification1Value === null ||
        (formData.specification1Value > 0 && formData.specification1Value < 8)) &&
      (formData.specification2Value === null ||
        (formData.specification2Value > 0 && formData.specification2Value < 8)) &&
      (formData.specification3Value === null || (formData.specification3Value > 0 && formData.specification3Value < 8))
    ) {
      if (
        formData.source2Id === null &&
        formData.specification2Id === null &&
        formData.supplier2Id === null &&
        formData.specification2Value === null &&
        formData.source3Id === null &&
        formData.specification3Id === null &&
        formData.supplier3Id === null &&
        formData.specification3Value === null
      ) {
        YardReceiptsApi.updateYardReceipt(updateFormData, () => {
          setLoading(false);
          onClose(true);
        });
      } else if (
        formData.source2Id > 0 &&
        formData.specification2Id > 0 &&
        formData.supplier2Id > 0 &&
        formData.specification2Value >= 0 &&
        formData.source3Id > 0 &&
        formData.specification3Id > 0 &&
        formData.supplier3Id > 0 &&
        formData.specification3Value >= 0
      ) {
        YardReceiptsApi.updateYardReceipt(updateFormData, () => {
          setLoading(false);
          onClose(true);
        });
      } else if (
        formData.source2Id > 0 &&
        formData.specification2Id > 0 &&
        formData.supplier2Id > 0 &&
        formData.specification2Value > 0
      ) {
        if (
          formData.source3Id > 0 ||
          formData.specification3Id > 0 ||
          formData.supplier3Id > 0 ||
          formData.specification3Value > 0
        ) {
          setLoading(false);
          setField3ErrorOpen(true);
        } else {
          YardReceiptsApi.updateYardReceipt(updateFormData, () => {
            setLoading(false);
            onClose(true);
          });
        }
        setTimeout(() => {
          setField3ErrorOpen(false);
        }, 1500);
      } else if (
        formData.source3Id > 0 &&
        formData.specification3Id > 0 &&
        formData.supplier3Id > 0 &&
        formData.specification3Value > 0
      ) {
        if (
          formData.source2Id > 0 ||
          formData.specification2Id > 0 ||
          formData.supplier2Id > 0 ||
          formData.specification2Value > 0
        ) {
          setLoading(false);
          setField2ErrorOpen(true);
        } else {
          YardReceiptsApi.updateYardReceipt(updateFormData, () => {
            setLoading(false);
            onClose(true);
          });
        }
        setTimeout(() => {
          setField2ErrorOpen(false);
        }, 1500);
      } else {
        setLoading(false);
        setTotalFieldErrorOpen(true);
      }
      setTimeout(() => {
        setTotalFieldErrorOpen(false);
      }, 1500);
    } else {
      setLoading(false);
      setSpecErrorOpen(true);
    }
    setTimeout(() => {
      setSpecErrorOpen(false);
    }, 1500);
  };

  useEffect(() => {
    getReceipt();
  }, []);
  useEffect(() => {
    specificationValueCal();
  }, [loading, formData.specification1Value, formData.specification2Value, formData.specification3Value]);
  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <Box
        sx={{
          minWidth: 680,
          bgcolor: '#BB5E00',
          color: 'white',
          boxShadow: 16,
          borderRadius: '20px',
          p: 2,
          opacity: loading ? 0.8 : 1,
        }}
      >
        <Stack spacing={1} direction="row">
          <div>
            <YatungPageTitle
              title={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.STORAGE_YARD_FEEDING')}
              sx={{ flex: '1 0 0', borderBottom: '1px solid #FFFFFF', pb: 1 }}
              textColor="white"
              iconColor="white"
            />
            <Stack spacing={1} direction="row" sx={{ flex: '1 0 0' }}>
              <Box sx={{ width: '50%' }}>
                <StorageYardReciptInputItem
                  Icon={StorageYardCreateTimeIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.TABLE.CREATETIME')}
                >
                  {moment(detail.createTime).format('YYYY/MM/DD_hh:mm')}
                </StorageYardReciptInputItem>
                <StorageYardReciptInputItem Icon={CarPlateIcon} label={i18T('APPLICATIONSMANAGEMENT.TABLE.CAR_NO')}>
                  <YatungInput
                    onChange={(e: any) => handleFormDataChange('plateNumber')(e.target.value)}
                    value={formData['plateNumber']}
                    sx={{ width: '164px' }}
                  />
                </StorageYardReciptInputItem>
                <StorageYardReciptInputItem
                  Icon={StorageYardSpecificationValueIcon}
                  label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.TOTAL_DOUSHU')}
                >
                  {totalSpecificationValue}
                </StorageYardReciptInputItem>
                <Stack spacing={2} direction="row">
                  <StorageYardReciptInputItem
                    Icon={ContentMangementIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SPECIFICATION1')}
                    value={detail.specification1Name}
                  >
                    <YatungSelect
                      disabled={specifications1.length === 0 || loading}
                      options={specifications1}
                      value={formData['specification1Id']}
                      onChange={(e: any) => handleFormDataChange('specification1Id')(e.target.value)}
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                  <StorageYardReciptInputItem
                    Icon={SourceIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SOURCE1')}
                  >
                    <YatungSelect
                      disabled={sources.length === 0 || loading}
                      options={sources}
                      value={formData['source1Id']}
                      onChange={(e: any) => handleSource1SelectChange(e.target.value)}
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                  <StorageYardReciptInputItem
                    Icon={StorageYardSupplierIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SUPPLIER1')}
                    value={detail.supplier1Name}
                  >
                    <YatungSelect
                      disabled={loading}
                      options={suppliers}
                      value={formData['supplier1Id']}
                      onChange={(e: any) => handleFormDataChange('supplier1Id')(e.target.value)}
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                  <StorageYardReciptInputItem
                    Icon={StorageYardSpecificationValueIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.DOUSHU1')}
                  >
                    <YatungInput
                      onChange={(e: any) => handleFormDataChange('specification1Value')(Number(e.target.value))}
                      value={formData['specification1Value']}
                      type="number"
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                </Stack>
                <Stack spacing={2} direction="row">
                  <StorageYardReciptInputItem
                    Icon={ContentMangementIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SPECIFICATION2')}
                  >
                    <YatungSelect
                      disabled={specifications2.length === 0 || loading}
                      options={specifications2}
                      value={formData['specification2Id']}
                      onChange={(e: any) => handleFormDataChange('specification2Id')(e.target.value)}
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                  <StorageYardReciptInputItem
                    Icon={SourceIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SOURCE2')}
                  >
                    <YatungSelect
                      disabled={sources.length === 0 || loading}
                      options={sources}
                      value={formData['source2Id']}
                      onChange={(e: any) => handleSource2SelectChange(e.target.value)}
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                  <StorageYardReciptInputItem
                    Icon={StorageYardSupplierIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SUPPLIER2')}
                    value={detail.supplier2Name}
                  >
                    <YatungSelect
                      disabled={loading}
                      options={suppliers}
                      value={formData['supplier2Id']}
                      onChange={(e: any) => handleFormDataChange('supplier2Id')(e.target.value)}
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                  <StorageYardReciptInputItem
                    Icon={StorageYardSpecificationValueIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.DOUSHU2')}
                  >
                    <YatungInput
                      onChange={(e: any) => handleFormDataChange('specification2Value')(Number(e.target.value))}
                      value={formData['specification2Value']}
                      type="number"
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                </Stack>
                <Stack spacing={2} direction="row">
                  <StorageYardReciptInputItem
                    Icon={ContentMangementIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SPECIFICATION3')}
                    value={detail.specification3Name}
                  >
                    <YatungSelect
                      disabled={specifications3.length === 0 || loading}
                      options={specifications3}
                      value={formData['specification3Id']}
                      onChange={(e: any) => handleFormDataChange('specification3Id')(e.target.value)}
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                  <StorageYardReciptInputItem
                    Icon={SourceIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SOURCE3')}
                  >
                    <YatungSelect
                      disabled={sources.length === 0 || loading}
                      options={sources}
                      value={formData['source3Id']}
                      onChange={(e: any) => handleSource3SelectChange(e.target.value)}
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                  <StorageYardReciptInputItem
                    Icon={StorageYardSupplierIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SUPPLIER3')}
                    value={detail.supplier3Name}
                  >
                    <YatungSelect
                      disabled={loading}
                      options={suppliers}
                      value={formData['supplier3Id']}
                      onChange={(e: any) => handleFormDataChange('supplier3Id')(e.target.value)}
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                  <StorageYardReciptInputItem
                    Icon={StorageYardSpecificationValueIcon}
                    label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.DOUSHU3')}
                  >
                    <YatungInput
                      onChange={(e: any) => handleFormDataChange('specification3Value')(Number(e.target.value))}
                      value={formData['specification3Value']}
                      type="number"
                      sx={{ width: '164px' }}
                    />
                  </StorageYardReciptInputItem>
                </Stack>
              </Box>
            </Stack>
            <Stack spacing={2} sx={{ pt: 8 }} direction={'row'}>
              <StorageYardReciptInputItem
                Icon={StorageYardSupplierIcon}
                label={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.STAFFNAME')}
              >
                {detail.staffName}
              </StorageYardReciptInputItem>
              <YatungButton
                text={i18T('GLOBAL.CLOSE')}
                startIcon={<EditIcon />}
                onClick={() => {
                  onClose();
                }}
                sx={{
                  color: '#ffffff',
                  bgcolor: '#CB333B',
                  alignSelf: 'flex-end',
                  fontSize: '24px',
                  width: 120,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#CB333B',
                  },
                }}
              />
              <YatungButton
                text={i18T('GLOBAL.FINISH')}
                startIcon={<EditIcon />}
                onClick={handleConfirmButtonClick}
                sx={{
                  border: '1px solid #fff',
                  color: '#ffffff',
                  bgcolor: '#002F8C',
                  alignSelf: 'flex-end',
                  fontSize: '24px',
                  width: 120,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#002F8C',
                  },
                }}
              />
            </Stack>
          </div>
        </Stack>
      </Box>
      {loading && <YatungLoading overlay={true} />}
      <YatungAlert
        open={specErrorOpen}
        setOpen={setSpecErrorOpen}
        title={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.SPECIFICATIONVALUE_ALERT')}
        type='error'
      />
      <YatungAlert
        open={totalFieldErrorOpen}
        setOpen={setTotalFieldErrorOpen}
        title={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.FIELDTOTAL_ALERT')}
        type='error'
      />
      <YatungAlert
        open={field2ErrorOpen}
        setOpen={setField2ErrorOpen}
        title={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.FIELD2_ALERT')}
        type='error'
      />
      <YatungAlert
        open={field3ErrorOpen}
        setOpen={setField3ErrorOpen}
        title={i18T('APPLICATIONSMANAGEMENT.STORAGE_YARD_MATERIALS_RECEIPTS.FIELD3_ALERT')}
        type='error'
      />
    </Stack>
  );
}

export default function StorageYardReciptUpdateModal({ open = false, ...props }: any) {
  return (
    <Modal open={open}>
      <>
        <StorageYardReciptUpdateContent {...props} />
      </>
    </Modal>
  );
}
