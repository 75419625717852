import React from 'react';
import { SiteManagementData } from '@Src/_basic/object/SiteType';
import { Stack } from '@mui/material';
import YatungImg from '@Src/_basic/components/YatungImg';
import YatungButton from '@Src/_basic/components/YatungButton';

export type ExtraData = {
  onGoToUpdatePage: (row: SiteManagementData) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: SiteManagementData;
  formatExtraData: ExtraData;
}
const editImage = '/media/images/edit.png';

export default function ActionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;
  return (
    <Stack direction="row" spacing={1}>
      <YatungButton
        text={formatExtraData.getButtonText('編輯')}
        variant={'contained'}
        type={'button'}
        size={'large'}
        startIcon={<YatungImg img={editImage} />}
        onClick={() => {
          formatExtraData.onGoToUpdatePage(row);
        }}
        sx={{
          color: '#ffffff',
          bgcolor: '#F0CA00',
          '&.MuiButtonBase-root:hover': {
            bgcolor: '#F0CA00',
          },
        }}
      />
    </Stack>
  );
}
