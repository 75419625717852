import YatungButton from '@Src/_basic/components/YatungButton';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import NameIcon from '@Src/_basic/icons/Name';
import PersonIcon from '@Src/_basic/icons/PersonIcon';
import { UpdqateLangName } from '@Src/_basic/object/MultilingualManagementType';
import { Stack, Typography } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  values: UpdqateLangName | any;
  setValues: (name: string, number: any) => void;
  onUpdateGoBackButtonClick: () => void;
  langByIdData: any;
}
export default function AddLangField(props: Props) {
  const {
    values,
    langByIdData,

    onUpdateGoBackButtonClick,
  } = props;

  const { t: i18T } = useTranslation();

  return (
    <>
      <Stack flexDirection={'column'} sx={{ mt: 1 }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <NameIcon />
            <Typography sx={{ pl: 1, fontSize: 20 }}>
              {i18T('USERSMANAGEMENU.MULTILINGUAL_MANAGEMENT.LANGUAGE_NAME')}：
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ pt: 4 }} direction="row" justifyContent="space-between">
          <Field
            id="name"
            name="name"
            type="text"
            component={YatungFormInput}
            value={langByIdData?.name ? langByIdData?.name : values.name}
            sx={{ width: '100%' }}
            position={'start'}
            icon={<PersonIcon sx={{ width: 30, height: 30 }} />}
            required
            onKeyPress={(e: any) => {
              if (new RegExp(/[@=+!#$%^&*-]/).test(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </Stack>

        <Stack
          spacing={3}
          sx={{ bgcolor: 'white', minWidth: 500, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}
          display="flex"
          flexDirection="column"
        >
          <Stack alignItems="center" justifyContent="center" flexDirection="row">
            <YatungButton
              text={i18T('ENTITYMANAGEMENT.CANCEL')}
              onClick={onUpdateGoBackButtonClick}
              sx={{
                color: '#ffffff',
                bgcolor: '#CB333B',
                fontSize: '24px',
                width: 120,
                mr: 1,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#CB333B',
                },
              }}
            />
            <YatungButton
              text={i18T('ENTITYMANAGEMENT.COMPLETE')}
              type="submit"
              sx={{
                border: '1px solid #fff',
                color: '#ffffff',
                bgcolor: '#002F8C',
                fontSize: '24px',
                width: 120,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#002F8C',
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
