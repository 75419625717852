import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { useAuth } from '@Src/redux/auth/authActions';
import { Grid, SelectChangeEvent, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectTypeMenu from './SelectTypeMenu';

const background = '/media/images/applicationsMange/legalStandardBG.png';

interface AreaOptions extends Options {
  factories: Options[];
}
export default function MaterialsReceiptMaleUpOrder() {
  const { t: i18T } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [selectedArea, setSelectedArea] = useState<number>(0);
  const [factories, setFactories] = useState<Options[]>([]);
  const [selectedFactory, setSelectedFactory] = useState<number>(0);

  const { userAreaOptionsData } = useAuth();

  const handleAreaSelectChange = useCallback(
    (area: number) => {
      const selected = userAreaOptionsData.find((i: AreaOptions) => i.value === area);
      if (selected) {
        setFactories(selected.factories);
        setSelectedArea(area);
      }
    },
    [userAreaOptionsData],
  );
  const handleReset = () => {
    setLoading(false);
    setSelectedFactory(0);
  };
  useEffect(() => {
    handleReset();
  }, [selectedArea]);

  const handleFactorySelectChange = useCallback((e: SelectChangeEvent<unknown>) => {
    setSelectedFactory(e.target.value as number);
  }, []);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.MATERIALS_RECEIPTS.MAKE_UP_ORDER_BY_MATERIALS')}
      backgroundImage={background}
      body={
        <Stack flexDirection={'column'} justifyContent={'center'}>
          <Grid container item xs={12}>
            <Grid container item xs={8} justifyContent={'center'} sx={{ ml: 1 }}>
              <YatungSelect
                disabled={loading}
                options={userAreaOptionsData}
                value={selectedArea}
                onChange={(e) => handleAreaSelectChange(e.target.value as number)}
                width={'204px'}
              />
              <YatungSelect
                disabled={selectedArea === 0 || loading}
                options={factories}
                value={selectedFactory}
                onChange={handleFactorySelectChange}
                sx={{ ml: 1 }}
                width={'204px'}
              />
            </Grid>
          </Grid>
          <Stack flexDirection={'row'} justifyContent={'center'} sx={{ mt: 1 }}>
            <SelectTypeMenu selectedFactory={selectedFactory} />
          </Stack>
        </Stack>
      }
    />
  );
}
