import {
  ChildrenTypography,
  TestCardContainer,
  TestVerifyResultStyles,
  TitleTypography,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import YatungRadioButton from '@Src/_basic/components/YatungRadioButton';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { CalcCoarseAbrasive } from '@Src/_basic/helpers/CalcTestForm';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  CoarseAbrasive,
  ImportCoarseAbrasive,
  PutCoarseAbrasiveRequest,
} from '@Src/_basic/object/test/sandRock/CoarseAbrasiveType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: CoarseAbrasive;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  weightInGram1: basicNumberSchema,
  weightInGram2: basicNumberSchema,
  weightInGram3: basicNumberSchema,
  weightInGram4: basicNumberSchema,
  weightInGram5: basicNumberSchema,
  weightInGram6: basicNumberSchema,
  weightInGram7: basicNumberSchema,
  abrasiveChargeCode: Yup.string().required(),
  rpm: basicNumberSchema,
  abrasiveChargeTotalWeight: basicNumberSchema,
  afterTestRetainedWeight: basicNumberSchema,
});

// 洛杉磯試驗
function ExportCoarseAbrasiveTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const [edit, setEdit] = useState<boolean>(false);
  const [chargeCode, setChargeCode] = useState<string>(testDetail?.abrasiveChargeCode);

  const initialValues = useMemo(
    () => ({
      weightInGram1: testDetail?.weightInGram1,
      weightInGram2: testDetail?.weightInGram2,
      weightInGram3: testDetail?.weightInGram3,
      weightInGram4: testDetail?.weightInGram4,
      weightInGram5: testDetail?.weightInGram5,
      weightInGram6: testDetail?.weightInGram6,
      weightInGram7: testDetail?.weightInGram7,
      abrasiveChargeCode: testDetail?.abrasiveChargeCode,
      rpm: testDetail?.rpm,
      abrasiveChargeTotalWeight: testDetail?.abrasiveChargeTotalWeight,
      afterTestRetainedWeight: testDetail?.afterTestRetainedWeight,
    }),
    [testDetail],
  );

  const calcTestForm = useMemo(() => new CalcCoarseAbrasive(testDetail), [testDetail]);

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportCoarseAbrasive = {
        weightInGram1: checkIsNaN(+values.weightInGram1),
        weightInGram2: checkIsNaN(+values.weightInGram2),
        weightInGram3: checkIsNaN(+values.weightInGram3),
        weightInGram4: checkIsNaN(+values.weightInGram4),
        weightInGram5: checkIsNaN(+values.weightInGram5),
        weightInGram6: checkIsNaN(+values.weightInGram6),
        weightInGram7: checkIsNaN(+values.weightInGram7),
        abrasiveChargeCode: values.abrasiveChargeCode,
        rpm: checkIsNaN(+values.rpm),
        abrasiveChargeTotalWeight: checkIsNaN(+values.abrasiveChargeTotalWeight),
        afterTestRetainedWeight: checkIsNaN(+values.afterTestRetainedWeight),
      };

      TestApi.putTest<PutCoarseAbrasiveRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const firstInfo = useMemo(
    () => [
      {
        title: `${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(`TEST.COARSE_ABRASIVE.37500um`)}             ${i18T(
          `TEST.COARSE_ABRASIVE.STAY`,
        )}${i18T(`TEST.COARSE_ABRASIVE.25000um`)}`,
        values: [formatNumber(testDetail?.weightInGram1?.toFixed(4))],
      },
      {
        title: `${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(`TEST.COARSE_ABRASIVE.25000um`)}             ${i18T(
          `TEST.COARSE_ABRASIVE.STAY`,
        )}${i18T(`TEST.COARSE_ABRASIVE.19000um`)}`,
        values: [formatNumber(testDetail?.weightInGram2?.toFixed(4))],
      },
      {
        title: `${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(`TEST.COARSE_ABRASIVE.19000um`)}             ${i18T(
          `TEST.COARSE_ABRASIVE.STAY`,
        )}${i18T(`TEST.COARSE_ABRASIVE.12500um`)}`,
        values: [formatNumber(testDetail?.weightInGram3?.toFixed(4))],
      },
      {
        title: `${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(`TEST.COARSE_ABRASIVE.12500um`)}             ${i18T(
          `TEST.COARSE_ABRASIVE.STAY`,
        )}${i18T(`TEST.COARSE_ABRASIVE.9500um`)}`,
        values: [formatNumber(testDetail?.weightInGram4?.toFixed(4))],
      },
      {
        title: `${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(`TEST.COARSE_ABRASIVE.9500um`)}             ${i18T(
          `TEST.COARSE_ABRASIVE.STAY`,
        )}${i18T(`TEST.COARSE_ABRASIVE.6300um`)}`,
        values: [formatNumber(testDetail?.weightInGram5?.toFixed(4))],
      },
      {
        title: `${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(`TEST.COARSE_ABRASIVE.6300um`)}             ${i18T(
          `TEST.COARSE_ABRASIVE.STAY`,
        )}${i18T(`TEST.COARSE_ABRASIVE.4750um`)}`,
        values: [formatNumber(testDetail?.weightInGram6?.toFixed(4))],
      },
      {
        title: `${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(`TEST.COARSE_ABRASIVE.4750um`)}             ${i18T(
          `TEST.COARSE_ABRASIVE.STAY`,
        )}${i18T(`TEST.COARSE_ABRASIVE.2360um`)}`,
        values: [formatNumber(testDetail?.weightInGram7?.toFixed(4))],
      },
      {
        title: `${i18T(`TEST.COARSE_ABRASIVE.TOTAL`)}`,
        values: [formatNumber(calcTestForm?.totalWeightInGram?.toFixed(4))],
      },
    ],
    [i18T, testDetail, calcTestForm],
  );

  const secondInfo = useMemo(
    () => [
      {
        title: testDetail?.abrasiveChargeCode?.toString(),
        values: [testDetail?.rpm?.toFixed(0)],
      },
    ],
    [testDetail?.abrasiveChargeCode, testDetail?.rpm],
  );

  const thirdInfo = useMemo(
    () => [
      {
        title: testDetail?.abrasiveChargeNum?.toString() ?? '0',
        values: [testDetail?.abrasiveChargeTotalWeight],
      },
    ],
    [testDetail?.abrasiveChargeNum, testDetail?.abrasiveChargeTotalWeight],
  );

  const fourthInfo = useMemo(
    () => [
      {
        title: `${formatNumber(testDetail?.beforeAbrasion?.toFixed(4))}`,
        values: [formatNumber(testDetail?.afterAbrasion?.toFixed(4))],
      },
    ],
    [testDetail?.beforeAbrasion, testDetail?.afterAbrasion],
  );

  const fifthInfo = useMemo(
    () => [
      {
        title: `${formatNumber(testDetail?.abrasionRatio?.toFixed(4))}`,
        titleStyle:
          testDetail?.verifyPassedResults?.abrasionRatio && testDetail?.verifyCustomResults?.abrasionRatio.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
    ],
    [
      testDetail?.abrasionRatio,
      testDetail?.verifyPassedResults?.abrasionRatio,
      testDetail?.verifyCustomResults?.abrasionRatio,
    ],
  );

  const sixthInfo = useMemo(
    () => [
      isLegalPassed && isCustomPassed && !isCustomRejected
        ? {
            titles: verifyResultText,
            titlesStyle: [TestVerifyResultStyles.normal],
          }
        : {
            titles: verifyResultText,
            titlesStyle: [
              isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
              isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
            ],
          },
    ],
    [isLegalPassed, isCustomPassed, verifyResultText, isCustomRejected],
  );

  const firstHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.COARSE_ABRASIVE.SIEVE_NUMBER_SIZE`)}`,
      values: [`${i18T(`TEST.COARSE_ABRASIVE.WEIGHT_IN_GRADING`)}(g)`],
    }),
    [i18T],
  );

  const secondHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.COARSE_ABRASIVE.ABRASIVE_CHARGE_CODE`)}`,
      values: [`${i18T(`TEST.COARSE_ABRASIVE.RPM`)}(轉)`],
    }),
    [i18T],
  );

  const thirdHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.COARSE_ABRASIVE.ABRASIVE_AMOUNT`)}(顆)`,
      values: [`${i18T(`TEST.COARSE_ABRASIVE.ABRASIVE_CHARGE_TOTAL_WEIGHT`)}(g)`],
    }),
    [i18T],
  );

  const fourthHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.COARSE_ABRASIVE.BEFORE_TEST_TOTAL_WEIGHT`)}(g)`,
      values: [`${i18T(`TEST.COARSE_ABRASIVE.AFTER_TEST_TOTAL_WEIGHT`)}(g)`],
    }),
    [i18T],
  );

  const fifthHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.COARSE_ABRASIVE.WEAR_OUT_RATE`)}(%)`,
    }),
    [i18T],
  );

  const sixthHeader = useMemo(
    () => ({
      title: i18T(`TEST.BASE.DETECTION`),
    }),
    [i18T],
  );

  const firstView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack gap={2}>
            <Stack flexDirection="row">
              <TitleTypography>{`${i18T(`TEST.COARSE_ABRASIVE.SIEVE_NUMBER_SIZE`)}`}</TitleTypography>
              <TitleTypography>{`${i18T(`TEST.COARSE_ABRASIVE.WEIGHT_IN_GRADING`)}(g)`}</TitleTypography>
            </Stack>
            <Stack flexDirection="row" pr={10} width={'73%'} sx={{ borderBottom: '2px solid #ccc' }} pb={2}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(
                `TEST.COARSE_ABRASIVE.37500um`,
              )}             ${i18T(`TEST.COARSE_ABRASIVE.STAY`)}${i18T(
                `TEST.COARSE_ABRASIVE.25000um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.weightInGram1}
                isError={!!(formik.touched.weightInGram1 && formik.errors.weightInGram1)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('weightInGram1', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pr={10} width={'73%'} sx={{ borderBottom: '2px solid #ccc' }} pb={2}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(
                `TEST.COARSE_ABRASIVE.25000um`,
              )}             ${i18T(`TEST.COARSE_ABRASIVE.STAY`)}${i18T(
                `TEST.COARSE_ABRASIVE.19000um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.weightInGram2}
                isError={!!(formik.touched.weightInGram2 && formik.errors.weightInGram2)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('weightInGram2', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pr={10} width={'73%'} sx={{ borderBottom: '2px solid #ccc' }} pb={2}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(
                `TEST.COARSE_ABRASIVE.19000um`,
              )}             ${i18T(`TEST.COARSE_ABRASIVE.STAY`)}${i18T(
                `TEST.COARSE_ABRASIVE.12500um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.weightInGram3}
                isError={!!(formik.touched.weightInGram3 && formik.errors.weightInGram3)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('weightInGram3', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pr={10} width={'73%'} sx={{ borderBottom: '2px solid #ccc' }} pb={2}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(
                `TEST.COARSE_ABRASIVE.12500um`,
              )}             ${i18T(`TEST.COARSE_ABRASIVE.STAY`)}${i18T(
                `TEST.COARSE_ABRASIVE.9500um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.weightInGram4}
                isError={!!(formik.touched.weightInGram4 && formik.errors.weightInGram4)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('weightInGram4', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pr={10} width={'73%'} sx={{ borderBottom: '2px solid #ccc' }} pb={2}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(
                `TEST.COARSE_ABRASIVE.9500um`,
              )}             ${i18T(`TEST.COARSE_ABRASIVE.STAY`)}${i18T(
                `TEST.COARSE_ABRASIVE.6300um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.weightInGram5}
                isError={!!(formik.touched.weightInGram5 && formik.errors.weightInGram5)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('weightInGram5', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pr={10} width={'73%'} sx={{ borderBottom: '2px solid #ccc' }} pb={2}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(
                `TEST.COARSE_ABRASIVE.6300um`,
              )}             ${i18T(`TEST.COARSE_ABRASIVE.STAY`)}${i18T(
                `TEST.COARSE_ABRASIVE.4750um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.weightInGram6}
                isError={!!(formik.touched.weightInGram6 && formik.errors.weightInGram6)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('weightInGram6', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pr={10} width={'73%'} sx={{ borderBottom: '2px solid #ccc' }} pb={2}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_ABRASIVE.THROUGH`)}${i18T(
                `TEST.COARSE_ABRASIVE.4750um`,
              )}             ${i18T(`TEST.COARSE_ABRASIVE.STAY`)}${i18T(
                `TEST.COARSE_ABRASIVE.2360um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.weightInGram7}
                isError={!!(formik.touched.weightInGram7 && formik.errors.weightInGram7)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('weightInGram7', e.target.value)}
              />
            </Stack>
          </Stack>
        );
      case false:
        return <TestTable headerData={firstHeader} rows={firstInfo} />;
      default:
        break;
    }
  }, [edit, firstHeader, firstInfo, formik, i18T]);

  const secondView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack
            gap={2}
            flexDirection="row"
            sx={{ borderBottom: '2px solid #ccc' }}
            pb={2}
            width={'73%'}
            justifyContent="space-between"
          >
            <ColFieldInfo
              titleStyle={TestVerifyResultStyles.resultTitle}
              title={i18T(`TEST.COARSE_ABRASIVE.ABRASIVE_CHARGE_CODE`)}
            >
              <YatungRadioButton
                groupName="isColorChanged"
                value={chargeCode}
                onChange={(e) => {
                  setChargeCode(e.target.value);
                }}
                controlData={[
                  { label: 'A', value: 'A' },
                  { label: 'B', value: 'B' },
                  { label: 'C', value: 'C' },
                  { label: 'D', value: 'D' },
                ]}
                labelTextStyle={{
                  fontSize: '20px',
                  fontWeight: '600',
                  fontFamily: 'Microsoft JhengHei',
                  color: '#002F8C',
                }}
              />
            </ColFieldInfo>
            <ColFieldInfo
              titleStyle={TestVerifyResultStyles.resultTitle}
              title={`${i18T(`TEST.COARSE_ABRASIVE.RPM`)}(轉)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.rpm}
                isError={!!(formik.touched.rpm && formik.errors.rpm)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('rpm', e.target.value)}
              />
            </ColFieldInfo>
          </Stack>
        );
      case false:
        return <TestTable headerData={secondHeader} rows={secondInfo} />;
      default:
        break;
    }
  }, [edit, formik, i18T, secondHeader, secondInfo, chargeCode]);

  const thirdView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack
            gap={2}
            flexDirection="row"
            sx={{ borderBottom: '2px solid #ccc' }}
            pb={2}
            width={'73%'}
            justifyContent="space-between"
          >
            <ColFieldInfo
              titleStyle={TestVerifyResultStyles.resultTitle}
              title={`${i18T(`TEST.COARSE_ABRASIVE.ABRASIVE_CHARGE_TOTAL_WEIGHT`)}(g)`}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.abrasiveChargeTotalWeight}
                isError={!!(formik.touched.abrasiveChargeTotalWeight && formik.errors.abrasiveChargeTotalWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('abrasiveChargeTotalWeight', e.target.value)
                }
              />
            </ColFieldInfo>
            <ColFieldInfo
              titleStyle={TestVerifyResultStyles.resultTitle}
              title={i18T(`TEST.COARSE_ABRASIVE.AFTER_TEST_RETAINED_WEIGHT`)}
            >
              <LabelInput
                valueWidth="150px"
                value={formik.values.afterTestRetainedWeight}
                isError={!!(formik.touched.afterTestRetainedWeight && formik.errors.afterTestRetainedWeight)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  formik.setFieldValue('afterTestRetainedWeight', e.target.value)
                }
              />
            </ColFieldInfo>
          </Stack>
        );
      case false:
        return <TestTable headerData={thirdHeader} rows={thirdInfo} />;
      default:
        break;
    }
  }, [edit, thirdHeader, thirdInfo, formik, i18T]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {firstView}

        {secondView}

        {thirdView}
        {!edit && <TestTable headerData={fourthHeader} rows={fourthInfo} />}

        {!edit && <TestTable headerData={fifthHeader} rows={fifthInfo} />}
      </TestCardContainer>

      <TestCardContainer>
        <TestTable headerData={sixthHeader} rows={sixthInfo} />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportCoarseAbrasiveTable);
