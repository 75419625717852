import { Card, CardContent, CardHeader, Grid, SxProps } from '@mui/material';
import React from 'react';

interface Props {
  title?: string;
  body?: JSX.Element;
  icon?: JSX.Element;
  sx?: SxProps;
}
export default function YatungModal(props: Props) {
  const { title, body, icon } = props;
  return (
    <Card sx={{ mt: '25vh', ...props.sx }}>
      <CardHeader
        title={
          <Grid container justifyContent={'center'} display={'flex'} item xs={12}>
            {icon}
            {title}
          </Grid>
        }
        titleTypographyProps={{
          variant: 'h4',
          fontFamily: 'Inter',
          fontWeight: 600,
        }}
        sx={{ color: '#ffffff', background: '#BB5E00', textAlign: 'center' }}
      />
      <CardContent>{body}</CardContent>
    </Card>
  );
}
