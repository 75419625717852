import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Person(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.1167 4.35484C13.1167 6.49257 11.4178 8.20968 9.34167 8.20968C7.2655 8.20968 5.56665 6.49257 5.56665 4.35484C5.56665 2.21711 7.26549 0.5 9.34167 0.5C11.4178 0.5 13.1167 2.21711 13.1167 4.35484ZM18.5 19.3548C18.5 19.4033 18.4996 19.4517 18.4989 19.5H0.501103C0.500368 19.4517 0.5 19.4033 0.5 19.3548C0.5 14.2775 4.53814 10.1774 9.5 10.1774C14.4619 10.1774 18.5 14.2775 18.5 19.3548Z"
      />
    </SvgIcon>
  );
}
