import { AppPermissionRequest } from '@Src/_basic/object/AppPermissionType';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { permissionSlice } from './permissionSlice';

export function usePermission() {
  const { permissionData, originPermissionData, activePermissionData } = useSelector(
    (state: RootState) => state.permission,
  );
  const dispatch = useDispatch();
  const setPermissionData = (_permissionData: AppPermissionRequest | any) =>
    dispatch(permissionSlice.actions.setPermissionData(_permissionData));
  const setOriginPermissionData = (_originPermissionData: AppPermissionRequest | any) =>
    dispatch(permissionSlice.actions.setOriginPermissionData(_originPermissionData));
  const setActivePermissionData = (_activePermissionData: AppPermissionRequest | any) =>
    dispatch(permissionSlice.actions.setActivePermissionData(_activePermissionData));

  return {
    permissionData,
    setPermissionData,
    originPermissionData,
    setOriginPermissionData,
    activePermissionData,
    setActivePermissionData,
  };
}
