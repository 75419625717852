import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import CodeIcon from '@Src/_basic/icons/Code';
import NameIcon from '@Src/_basic/icons/Name';
import TypeIcon from '@Src/_basic/icons/Type';

import YatungCheckBox from '@Src/_basic/components/YatungCheckBox';
import { PostMaterialNumberRequest } from '@Src/_basic/object/MaterialNumberManageType';
import { TypeData } from '@Src/_basic/object/TypeType';
import EditForm from '../../../components/EditForm';
import { ItemType } from '../object/MaterialNumberType';

interface Props {
  open?: boolean;
  handleClose: () => void;
  handleSubmit: (data: PostMaterialNumberRequest) => void;
  allItems: ItemType[];
  allTypes: TypeData[];
  title: string;
  icon: JSX.Element;
  storage?: boolean;
}

const initData = {
  code: '',
  name: '',
  typeId: 0,
};

export default function AddModal({ open, handleClose, handleSubmit, allTypes, allItems, title, icon, storage }: Props) {
  const { t: i18T } = useTranslation();
  const [data, setData] = useState<PostMaterialNumberRequest>(initData);
  const [typeOptions, setTypeOptions] = useState<Options[]>([]);
  const [isCodeDuplicated, setIsCodeDuplicated] = useState<boolean>(false);
  const [toStorage, setToStorage] = useState<Array<number>>([]);

  const onSubmit = () => {
    if (data) {
      if (storage) {
        handleSubmit({ ...data, isYard: toStorage[0] === 0 ? true : false });
      } else {
        handleSubmit(data);
      }
    }
  };

  useEffect(() => {
    const filter = allItems.filter((type) => type.code === data.code && data.typeId === type.typeId);
    setIsCodeDuplicated(Boolean(filter.length));
  }, [data.code, data.typeId]);

  useEffect(() => {
    if (!open) {
      setData(initData);
    }
  }, [open]);

  useEffect(() => {
    if (allTypes && allTypes.length) {
      setTypeOptions(allTypes.map(({ id, name }: any) => ({ value: id, text: name })));
    }
  }, [allTypes]);

  return (
    <YatungDialog
      open={open}
      handleClose={handleClose}
      icon={icon}
      title={title}
      body={
        <EditForm
          onSubmit={onSubmit}
          onCancel={handleClose}
          canSubmit={Boolean(data.code && data.name && data.typeId && !isCodeDuplicated)}
        >
          <Box
            sx={{ py: 2, display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexDirection: 'column' }}
          >
            <Stack sx={{ borderBottom: '1px solid #6C708C', py: 1 }} direction="row" justifyContent="space-between">
              <Stack direction="row" sx={{ width: 120 }} alignItems="center">
                <CodeIcon />
                <Typography sx={{ pl: 1, fontWeight: 900, fontSize: 20 }}>
                  {i18T('APPLICATIONSMANAGEMENT.TABLE.CODE')}
                </Typography>
                <Typography sx={{ pl: 1, fontWeight: 900, fontSize: 12, color: '#FF000D' }}>{`*${i18T(
                  'APPLICATIONSMANAGEMENT.TABLE.CODE_NUM',
                  { num: 2 },
                )}`}</Typography>
              </Stack>
              <Stack direction="row" sx={{ width: 240 }} alignItems="center">
                <NameIcon />
                <Typography sx={{ pl: 1, fontWeight: 900, fontSize: 20 }}>
                  {i18T('APPLICATIONSMANAGEMENT.TABLE.NAME')}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ width: 120 }} alignItems="center">
                <TypeIcon />
                <Typography sx={{ pl: 1, fontWeight: 900, fontSize: 20 }}>
                  {i18T('APPLICATIONSMANAGEMENT.TABLE.TYPE')}
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ pt: 4 }} direction="row" justifyContent="space-between" alignItems="center">
              <YatungInput
                inputProps={{
                  maxLength: 2,
                }}
                onChange={(e: any) => setData({ ...data, code: e.target.value.replace(/[^a-zA-Z0-9]/g, '') })}
                value={data['code']}
                error={isCodeDuplicated}
                helperText={isCodeDuplicated && i18T('APPLICATIONSMANAGEMENT.TABLE.CODE_REPEAT')}
                sx={{ m: 1, width: 120 }}
              />
              <YatungInput onChange={(e: any) => setData({ ...data, name: e.target.value })} value={data['name']} />
              <YatungSelect
                width="120px"
                disabled={typeOptions.length === 0}
                options={typeOptions}
                value={data.typeId}
                onChange={(e) => setData({ ...data, typeId: e.target.value as number })}
              />
            </Stack>
            {storage && (
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography sx={{ pl: 1, fontWeight: 900, fontSize: 20 }}>{`${i18T(
                  'APPLICATIONSMANAGEMENT.MATERIAL_NUMBER_MANAGE.STORAGE',
                )} :`}</Typography>
                <YatungCheckBox
                  options={[
                    {
                      value: 0,
                      text: i18T('GLOBAL.YES'),
                    },
                    {
                      value: 1,
                      text: i18T('GLOBAL.NOT'),
                    },
                  ]}
                  onChange={(e) => setToStorage([parseInt(e.target.value)])}
                  labelTextStyle={{ fontSize: 20 }}
                  initialCheckedValue={toStorage}
                />
              </Stack>
            )}
          </Box>
        </EditForm>
      }
    />
  );
}
