import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Export(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M21.4,7.6c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.2
	c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0l-2.4-2c-0.4-0.4-1-0.3-1.4,0.1C17,4.9,17,5.5,17.5,5.9l0.3,0.3h-5.2
	c-2.9,0-5.3,2.3-5.4,5.2c0,0.6,0.4,1,1,1c0,0,0,0,0,0c0.5,0,1-0.4,1-1c0.1-1.8,1.6-3.3,3.4-3.3h5.4l-0.7,0.7c-0.4,0.4-0.4,1,0,1.4
	c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2.4-2.4C21.3,7.8,21.4,7.7,21.4,7.6z"
      />
      <path
        d="M12.5,3H6.1c-2,0-3.6,1.6-3.6,3.6v10.7c0,2,1.6,3.6,3.6,3.6h10.7c2,0,3.6-1.6,3.6-3.6v-5.7h-1.4v5.7
	c0,1.2-1,2.1-2.1,2.1H6.1c-1.2,0-2.1-1-2.1-2.1V6.6c0-1.2,1-2.1,2.1-2.1h6.4V3z"
      />
    </SvgIcon>
  );
}
