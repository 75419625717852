import { Box, Card, CardContent, CardHeader, Dialog, DialogContent, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungWindow from '@Src/_basic/components/YatungWindow';
import DefineName from '@Src/_basic/icons/DefineName';
import LeftArrow from '@Src/_basic/icons/LeftArrow';
import RightArrow from '@Src/_basic/icons/RightArrow';
import Specification from '@Src/_basic/icons/Specification';
import { WarehouseData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import {
  CreateMixerData,
  CreateStorage,
  MixerData,
  MixerDetailData,
  MixerStorageData,
} from '@Src/_basic/object/MixerTypes';
import { FeedTroughMaintenanceApi } from '@Src/_basic/protocol/feedTroughMaintenanceManagement/FeedTroughMaintenanceApi';
import { useParams } from 'react-router';
interface Props {
  modalOpen: boolean;
  onCloseModal: () => void;
  value: MixerDetailData;
  updateMixerData: MixerData;
  createMixer: CreateMixerData[];
  setUpdateMixerData: (e: MixerData) => void;
  setCreateMixer: (e: CreateMixerData[]) => void;
}
export default function CreateMixerManagement(props: Props) {
  const { t: i18T } = useTranslation();
  const { modalOpen, onCloseModal, value, updateMixerData, createMixer, setUpdateMixerData, setCreateMixer } = props;

  const params = useParams();
  const factoryId = Number(params.id);
  const getFeedTroughMaintenanceTotalData = () => {
    FeedTroughMaintenanceApi.getStorageBySearch(
      { factoryId: factoryId, isYard: false },
      (datas: WarehouseData[]) => {
        mappingData(datas);
      },
      undefined,
      undefined,
    );
  };
  const mappingData = (datas: WarehouseData[]) => {
    const mappedData = datas.map((data: WarehouseData) => ({
      id: data.id,
      name: data.name,
      typeName: data.typeName,
      checked: false,
    }));
    setTotalData(mappedData);
    if (value.storages.length > 0) {
      const dataIds = value.storages.map((data: MixerStorageData) => {
        return data.id;
      });
      const filteredDatas = mappedData.filter((item: any) => !dataIds.includes(item.id));
      setOriginalData(filteredDatas);
    } else {
      setOriginalData(mappedData);
    }
  };
  const getStorageAddChecked = () => {
    if (value.storages) {
      const chekcedArray = value.storages.map((item: MixerStorageData) => {
        return { ...item, checked: true };
      });
      setChangeData(chekcedArray);
    }
  };
  useEffect(() => {
    getFeedTroughMaintenanceTotalData();
    getStorageAddChecked();
  }, [value.id]);
  const [totalData, setTotalData] = useState<Array<CreateStorage>>([]);

  const [originalData, setOriginalData] = useState<Array<CreateStorage>>([]);
  const [changeData, setChangeData] = useState<Array<CreateStorage>>([]);
  const titleStyle = {
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 900,
    color: '#FFFFFF',
    my: 1,
    ml: 1,
  };
  const bodyStyle = {
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#697EA3',
    my: 1,
  };
  const checkedStyle = {
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#ffffff',
    my: 1,
  };
  const buttonTextStyle = {
    fontSize: 32,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#BB5E00',
  };
  const handleModalClose = () => {
    onCloseModal();
    const changeArrayTemp = value.storages.map((item: MixerStorageData) => {
      return { ...item, checked: true };
    });
    setChangeData(changeArrayTemp);
    const dataIds = value.storages.map((data: MixerStorageData) => {
      return data.id;
    });
    const filteredDatas = totalData.filter((item: CreateStorage) => !dataIds.includes(item.id));
    setOriginalData(filteredDatas);
  };
  const handleClick = (id: number) => {
    const updatedArray = originalData.map((data: CreateStorage) => {
      if (data.id === id) {
        return { ...data, checked: !data.checked };
      }
      return data;
    });
    setOriginalData(updatedArray);
  };
  const handleCancelClick = (id: number) => {
    const updatedArray = changeData.map((data: CreateStorage) => {
      if (data.id === id) {
        return { ...data, checked: !data.checked };
      }
      return data;
    });
    setChangeData(updatedArray);
  };
  const handleAddData = () => {
    const filterCheckedData = originalData.filter((data: CreateStorage) => {
      return data.checked === true;
    });
    setChangeData((prevData: any) => [...prevData, ...filterCheckedData]);
    const filterNotCheckedData = originalData.filter((data: CreateStorage) => {
      return data.checked !== true;
    });
    setOriginalData(filterNotCheckedData);
  };
  const handleCancelData = () => {
    const filterCheckedData = changeData.filter((data: CreateStorage) => {
      return data.checked === false;
    });

    setOriginalData((prevData: any) => [...prevData, ...filterCheckedData]);
    const filterNotCheckedData = changeData.filter((data: CreateStorage) => {
      return data.checked !== false;
    });

    setChangeData(filterNotCheckedData);
  };
  const handleSubmit = () => {
    const updatedMixerArray = updateMixerData.mixers.map((data: any) => {
      if (data.id === value.id) {
        return { ...data, storages: changeData };
      }
      return data;
    });
    const updatedMixerArrayTemp = { ...updateMixerData, mixers: updatedMixerArray };

    setUpdateMixerData(updatedMixerArrayTemp);

    const updatedCreateMixerArray = createMixer.map((data: CreateMixerData) => {
      if (data.id === value.id) {
        return { ...data, storages: changeData };
      }
      return data;
    });

    setCreateMixer(updatedCreateMixerArray);

    const resetOriginChecked = originalData.map((data: CreateStorage) => {
      if (data.checked === true) {
        return { ...data, checked: false };
      }
      return data;
    });
    const resetChangeChecked = changeData.map((data: CreateStorage) => {
      if (data.checked === false) {
        return { ...data, checked: true };
      }
      return data;
    });
    setOriginalData(resetOriginChecked);
    setChangeData(resetChangeChecked);
    onCloseModal();
  };
  return (
    <Dialog
      open={modalOpen}
      onClose={handleModalClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, background: '#E9E9E9' } }}
    >
      <DialogContent>
        <Grid container item xs={12}>
          <Grid container item xs={5}>
            <Card sx={{ width: '640px', height: '700px' }}>
              <CardHeader
                sx={{ color: '#ffffff', background: '#BB5E00' }}
                title={
                  <>
                    <Grid container item xs={12} justifyContent={'center'} sx={{ mt: 1 }}>
                      <Grid container item xs={6} alignItems={'center'}>
                        <DefineName />
                        <Typography sx={titleStyle}>{i18T('USERSMANAGEMENU.MIXER_INFORMATION.DEFINE')}</Typography>
                      </Grid>
                      <Grid container item xs={6} alignItems={'center'}>
                        <Specification />
                        <Typography sx={titleStyle}>
                          {i18T('USERSMANAGEMENU.MIXER_INFORMATION.SPECIFICATION')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                }
              ></CardHeader>
              <CardContent sx={{ height: '100%' }}>
                <Box sx={{ mt: '16px', height: `calc(100% - 16px - 64px)`, width: '100%' }}>
                  <YatungWindow
                    className="scroll-bar"
                    data={originalData}
                    renderItem={(data: CreateStorage) => {
                      return (
                        <Grid container sx={{ cursor: 'pointer' }}>
                          {data.checked ? (
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent={'center'}
                              sx={{ mt: 1, background: '#BB5E00' }}
                              onClick={() => handleClick(data.id)}
                            >
                              <Grid container item xs={6} alignItems={'center'} sx={checkedStyle}>
                                {data.name}
                              </Grid>
                              <Grid container item xs={6} alignItems={'center'} sx={checkedStyle}>
                                {data.typeName}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent={'center'}
                              sx={{ mt: 1 }}
                              onClick={() => handleClick(data.id)}
                            >
                              <Grid container item xs={6} alignItems={'center'} sx={bodyStyle}>
                                {data.name}
                              </Grid>
                              <Grid container item xs={6} alignItems={'center'} sx={bodyStyle}>
                                {data.typeName}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      );
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid container item xs={2} justifyContent={'center'}>
            <Stack flexDirection={'column'} justifyContent={'center'}>
              <Stack flexDirection={'column'} alignItems={'center'} sx={{ my: 2 }}>
                <RightArrow sx={{ width: '85px', height: '85px', cursor: 'pointer' }} onClick={handleAddData} />
                <Typography sx={buttonTextStyle}>{i18T('GLOBAL.JOIN')}</Typography>
              </Stack>
              <Stack flexDirection={'column'} alignItems={'center'}>
                <LeftArrow sx={{ width: '85px', height: '85px', cursor: 'pointer' }} onClick={handleCancelData} />
                <Typography sx={buttonTextStyle}>{i18T('GLOBAL.CANCEL')}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid container item xs={5}>
            <Card sx={{ width: '920px', height: '700px' }}>
              <CardHeader
                sx={{ color: '#ffffff', background: '#BB5E00' }}
                title={
                  <>
                    <Grid container item xs={12} justifyContent={'center'} sx={{ mt: 1 }}>
                      <Grid container item xs={6} alignItems={'center'}>
                        <DefineName />
                        <Typography sx={titleStyle}>{i18T('USERSMANAGEMENU.MIXER_INFORMATION.DEFINE')}</Typography>
                      </Grid>
                      <Grid container item xs={6} alignItems={'center'}>
                        <Specification />
                        <Typography sx={titleStyle}>
                          {i18T('USERSMANAGEMENU.MIXER_INFORMATION.SPECIFICATION')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                }
              ></CardHeader>
              <CardContent sx={{ height: '100%' }}>
                <Box sx={{ mt: '16px', height: `calc(100% - 16px - 64px)`, width: '100%' }}>
                  <YatungWindow
                    className="scroll-bar"
                    data={changeData}
                    renderItem={(data: CreateStorage) => {
                      return (
                        <Grid container sx={{ cursor: 'pointer' }}>
                          {!data.checked ? (
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent={'center'}
                              sx={{ mt: 1, background: '#BB5E00' }}
                              onClick={() => handleCancelClick(data.id)}
                            >
                              <Grid container item xs={6} alignItems={'center'} sx={checkedStyle}>
                                {data.name}
                              </Grid>
                              <Grid container item xs={6} alignItems={'center'} sx={checkedStyle}>
                                {data.typeName}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent={'center'}
                              sx={{ mt: 1 }}
                              onClick={() => handleCancelClick(data.id)}
                            >
                              <Grid container item xs={6} alignItems={'center'} sx={bodyStyle}>
                                {data.name}
                              </Grid>
                              <Grid container item xs={6} alignItems={'center'} sx={bodyStyle}>
                                {data.typeName}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      );
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Stack justifyContent="flex-end" flexDirection="row" sx={{ pr: 4, mt: 5 }}>
          <YatungButton
            text={i18T('GLOBAL.CANCEL')}
            color="error"
            variant="contained"
            size="large"
            onClick={handleModalClose}
            sx={{
              color: '#ffffff',
              fontSize: '36px',
              p: 2,
              mr: 5,
            }}
          />
          <YatungButton
            disabled={changeData.length === 0}
            text={i18T('GLOBAL.FINISH')}
            color="blue"
            variant="contained"
            size="large"
            onClick={handleSubmit}
            sx={{
              color: '#ffffff',
              fontSize: '36px',
              p: 2,
            }}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
