import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDialog from '@Src/_basic/components/YatungDialog';

import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import EditV2 from '@Src/_basic/icons/EditV2';
import PowerSwitch from '@Src/_basic/icons/PowerSwitch';
import { TagData } from '@Src/_basic/object/TagType';
import { ResetForm, SubmitData } from '..';

interface Props {
  open?: boolean;
  handleClose: () => void;
  handleSubmit: (data: SubmitData) => void;
  selectedData: TagData;
}

const formSchema = Yup.object().shape({
  tagName: Yup.string().required('必填'),
});

const labelStyle = {
  fontSize: '24px',
  mb: 1,
};

const EditModal = ({ open, handleClose, handleSubmit, selectedData }: Props) => {
  const [lastTime, setLastTime] = useState<number>(new Date().getTime());
  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    if (!open) return;

    setLastTime(selectedData.lastUpdateTime);
    setStatus(selectedData.removed);
  }, [open, selectedData]);

  const initData = {
    tagName: selectedData.tagName,
  };

  const onSubmit = (value: SubmitData, { resetForm }: ResetForm) => {
    const _data = {
      ...value,
      status,
    };
    handleSubmit(_data);
    setStatus(true);
    resetForm();
  };

  return (
    <>
      {selectedData && (
        <Formik initialValues={initData} validationSchema={formSchema} onSubmit={onSubmit} enableReinitialize>
          {(formik) => {
            return (
              <Form>
                <YatungDialog
                  open={open}
                  handleClose={handleClose}
                  title={'編輯標籤'}
                  headerComponent={
                    <Stack flexDirection="row" justifyContent="space-between">
                      <Stack flexDirection="row">
                        <EditV2 fontSize="large" />
                        <Typography sx={{ fontSize: '24px', pl: 1 }}>編輯標籤</Typography>
                      </Stack>
                      <Stack flexDirection="row" justifyContent="space-between">
                        <YatungButton
                          sx={{
                            mr: 1,
                            backgroundColor: '#CB333B',
                            '&:hover': { backgroundColor: '#CB333B' },
                            borderRadius: '20px',
                            px: '16px',
                          }}
                          onClick={handleClose}
                        >
                          取消
                        </YatungButton>
                        <YatungButton
                          sx={{
                            ml: 1,
                            backgroundColor: '#00D03A',
                            '&:hover': { backgroundColor: '#00D03A' },
                            borderRadius: '20px',
                            px: '16px',
                          }}
                          onClick={formik.handleSubmit}
                        >
                          完成
                        </YatungButton>
                      </Stack>
                    </Stack>
                  }
                  body={
                    <>
                      <Stack flexDirection="row" justifyContent="flex-start" sx={{ py: 2 }}>
                        <Box sx={{ mr: 3 }}>
                          <Typography sx={labelStyle}>標籤名稱*</Typography>
                          <Field
                            id="tagName"
                            name="tagName"
                            type="text"
                            label=""
                            component={YatungFormInput}
                            sx={{ minWidth: '70%' }}
                            required
                          />
                        </Box>
                        <Box>
                          <Stack flexDirection="row">
                            <Typography sx={{ ...labelStyle, pr: 2, width: '90px' }}>狀 態</Typography>
                            <Typography
                              sx={{ ...labelStyle, pl: 2, width: '90px', color: status ? '#CB333B' : '#00D03A' }}
                            >
                              {status ? '停 止' : '啟  用'}
                            </Typography>
                          </Stack>
                          <Stack flexDirection="row">
                            <YatungButton
                              startIcon={<PowerSwitch />}
                              sx={{ mr: 1, backgroundColor: '#00D03A', '&:hover': { backgroundColor: '#00D03A' } }}
                              onClick={() => setStatus(false)}
                            >
                              啟用
                            </YatungButton>
                            <YatungButton
                              startIcon={<PowerSwitch />}
                              sx={{ ml: 1, backgroundColor: '#CB333B', '&:hover': { backgroundColor: '#CB333B' } }}
                              onClick={() => setStatus(true)}
                            >
                              停止
                            </YatungButton>
                          </Stack>
                        </Box>
                      </Stack>
                      <Stack flexDirection="row" justifyContent="flex-end" sx={{ py: 2 }}>
                        <Box>
                          <Typography sx={{ ...labelStyle, letterSpacing: 16 }}>修改時間</Typography>
                          <Typography sx={{ fontSize: '16px', letterSpacing: 8 }}>
                            {moment(lastTime).format('YYYY-MM-DD')} <br />
                            {moment(lastTime).format('HH:mm:ss')}
                          </Typography>
                        </Box>
                      </Stack>
                    </>
                  }
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default memo(EditModal);
