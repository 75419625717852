import React from 'react';
import { Typography } from '@mui/material';
import { SampleData } from '@Src/_basic/object/SampleLocationType';

interface Props {
  row: SampleData;
}

export default function NameColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.name}</Typography>;
}
