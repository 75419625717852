import {
  ChildrenTypography,
  TableHeaderTitle,
  TestCardContainer
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import YatungButton from '@Src/_basic/components/YatungButton';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { LegalStandardV2SpecificationDetailsResponse } from '@Src/_basic/object/LegalStandardV2Type';
import { AllTest, ExperimentResultType } from '@Src/_basic/object/TestType';
import { FineSieve, ImportFineSieve, PutFineSieveRequest } from '@Src/_basic/object/test/sandRock/FineSieveType';
import { LegalStandardV2Api } from '@Src/_basic/protocol/legalStandardV2/LegalStandardV2Api';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  wetWeight: basicNumberSchema,
  beforeRinseDryWeight: Yup.string()
    .min(1)
    .matches(/^-?(?!0+$)\d+\.?\d*$/)
    .required(),
  afterRinseDryWeight: basicNumberSchema,
  sieveWeight9500: basicNumberSchema,
  sieveWeight4750: basicNumberSchema,
  sieveWeight2360: basicNumberSchema,
  sieveWeight1180: basicNumberSchema,
  sieveWeight600: basicNumberSchema,
  sieveWeight300: basicNumberSchema,
  sieveWeight150: basicNumberSchema,
  sieveWeight75: basicNumberSchema,
  underPan: basicNumberSchema,
});
interface Props {
  testDetail: FineSieve;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (allTest: AllTest) => void;
  canEdit?: boolean;
  closeExecuteTestHandler?: () => void;
}

function ExportFineSieveTable({ testDetail, afterUpdateTest, canEdit, closeExecuteTestHandler }: Props) {
  const { t: i18T } = useTranslation();
  // const calcTestForm = useMemo(() => new CalcFineSieve(testDetail), [testDetail]);
  const [legalStandard, setLegalStandard] = useState<Map<string, LegalStandardV2SpecificationDetailsResponse>>();
  const [edit, setEdit] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      wetWeight: testDetail?.wetWeight,
      beforeRinseDryWeight: testDetail?.beforeRinseDryWeight,
      afterRinseDryWeight: testDetail?.afterRinseDryWeight,
      sieveWeight9500: testDetail?.poreDiameter1,
      sieveWeight4750: testDetail?.poreDiameter2,
      sieveWeight2360: testDetail?.poreDiameter3,
      sieveWeight1180: testDetail?.poreDiameter4,
      sieveWeight600: testDetail?.poreDiameter5,
      sieveWeight300: testDetail?.poreDiameter6,
      sieveWeight150: testDetail?.poreDiameter7,
      sieveWeight75: testDetail?.poreDiameter8,
      underPan: testDetail?.underPan,
    }),
    [testDetail],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportFineSieve = {
        wetWeight: checkIsNaN(+values.wetWeight),
        beforeRinseDryWeight: checkIsNaN(+values.beforeRinseDryWeight),
        afterRinseDryWeight: checkIsNaN(+values.afterRinseDryWeight),
        poreDiameter1: checkIsNaN(+values.sieveWeight9500),
        poreDiameter2: checkIsNaN(+values.sieveWeight4750),
        poreDiameter3: checkIsNaN(+values.sieveWeight2360),
        poreDiameter4: checkIsNaN(+values.sieveWeight1180),
        poreDiameter5: checkIsNaN(+values.sieveWeight600),
        poreDiameter6: checkIsNaN(+values.sieveWeight300),
        poreDiameter7: checkIsNaN(+values.sieveWeight150),
        poreDiameter8: checkIsNaN(+values.sieveWeight75),
        underPan: checkIsNaN(+values.underPan),
      };

      TestApi.putTest<PutFineSieveRequest>(
        {
          exptItemCode: testDetail.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
            completed: true,
            deadline: Date.now(),
            exptUnitId: 1,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          closeExecuteTestHandler && closeExecuteTestHandler();
        },
        (err) => console.log(err),
      );
    },
  });

  const firstTable = useMemo(() => {
    return (
      <Stack my={4} gap={2} ml={30}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pb={2}>
          <TableHeaderTitle>{i18T(`TEST.SIEVE_ANALYSIS.SIEVE_NUMBER`)}</TableHeaderTitle>
          <TableHeaderTitle sx={{ pr: 18 }}>{`${i18T(
            `TEST.SIEVE_ANALYSIS.ACCUMULATION_SIEVE_WEIGHT`,
          )}(g)`}</TableHeaderTitle>
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.WET_WEIGHT`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.wetWeight}
            isError={!!(formik.touched.wetWeight && formik.errors.wetWeight)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('wetWeight', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.BEFORE_RINSE_DRY_WEIGHT`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.beforeRinseDryWeight}
            isError={!!(formik.touched.beforeRinseDryWeight && formik.errors.beforeRinseDryWeight)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formik.setFieldValue('beforeRinseDryWeight', e.target.value)
            }
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.AFTER_RINSE_DRY_WEIGHT`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.afterRinseDryWeight}
            isError={!!(formik.touched.afterRinseDryWeight && formik.errors.afterRinseDryWeight)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              formik.setFieldValue('afterRinseDryWeight', e.target.value)
            }
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.9500um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.sieveWeight9500}
            isError={!!(formik.touched.sieveWeight9500 && formik.errors.sieveWeight9500)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight9500', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.4750um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.sieveWeight4750}
            isError={!!(formik.touched.sieveWeight4750 && formik.errors.sieveWeight4750)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight4750', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.2360um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.sieveWeight2360}
            isError={!!(formik.touched.sieveWeight2360 && formik.errors.sieveWeight2360)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight2360', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.1180um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.sieveWeight1180}
            isError={!!(formik.touched.sieveWeight1180 && formik.errors.sieveWeight1180)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight1180', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.600um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.sieveWeight600}
            isError={!!(formik.touched.sieveWeight600 && formik.errors.sieveWeight600)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight600', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.300um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.sieveWeight300}
            isError={!!(formik.touched.sieveWeight300 && formik.errors.sieveWeight300)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight300', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.150um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.sieveWeight150}
            isError={!!(formik.touched.sieveWeight150 && formik.errors.sieveWeight150)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight150', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.75um`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.sieveWeight75}
            isError={!!(formik.touched.sieveWeight75 && formik.errors.sieveWeight75)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('sieveWeight75', e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
          <ChildrenTypography>{i18T(`TEST.SIEVE_ANALYSIS.UNDER_PAN`)}</ChildrenTypography>
          <LabelInput
            value={formik.values.underPan}
            isError={!!(formik.touched.underPan && formik.errors.underPan)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('underPan', e.target.value)}
          />
        </Stack>
      </Stack>
    );
  }, [edit, formik, i18T]);

  useEffect(() => {
    LegalStandardV2Api.getLegalStandardV2SpecificationDetailsBySearch(
      {
        experimentResultTypeId: ExperimentResultType.FINE_AGG_SIEVE_ANALYSIS,
        effectiveUnixTime: Date.now(),
        type: 1,
      },
      (_data) => {
        const legalStandardMap = new Map();
        _data.forEach((item: LegalStandardV2SpecificationDetailsResponse) => {
          legalStandardMap.set(item.experimentDetectionItem.code, item);
        });
        setLegalStandard(legalStandardMap);
      },
    );
  }, []);

  return (
    <Stack gap={2} mt={2}>
      <TestCardContainer>
        {firstTable}
        <Stack flexDirection="row" justifyContent="flex-end" gap={5}>
          <YatungButton
            color="green"
            sx={{ height: '35%', mt: 1 }}
            width={80}
            text={`${i18T('GLOBAL.SUBMIT')}`}
            onClick={formik.handleSubmit}
          />
        </Stack>
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportFineSieveTable);
