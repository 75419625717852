import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { SampleData } from '@Src/_basic/object/SampleLocationType';
import { useApi } from '@Src/redux/api/apiAction';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import TypeColumnFormatter from './columnFormatter/TypeColumnFormatter';

interface Props {
  sampleData: SampleData[];
  handleUpdateAndDelete: (id: number, modal: 'UPDATE' | 'DELETE') => void;
}

function SampleManagementTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const { sampleData, handleUpdateAndDelete } = props;

  const getButtonText = useCallback(
    (status: string) => {
      switch (status) {
        case '編輯':
          return i18T('GLOBAL.UPDATE');
        case '刪除':
          return i18T('SAMPLEMANAGEMENT.DELETE');
        case '使用中':
          return i18T('GLOBAL.USING');
        case '啟用':
          return i18T('GLOBAL.OPEN');
        default:
          '';
      }
    },
    [i18T],
  );

  const columns: Array<Column> = useMemo(
    () => [
      {
        text: i18T('SAMPLEMANAGEMENT.NO'),
        dataField: 'number',
        width: '20%',
      },
      {
        text: i18T('SAMPLEMANAGEMENT.SAMPLE'),
        dataField: 'name',
        width: '20%',
      },
      {
        text: i18T('SAMPLEMANAGEMENT.SITE_TYPE'),
        dataField: 'types',
        formatter: TypeColumnFormatter,
        width: '45%',
      },
      {
        text: i18T('USERSMANAGEMENU.TABLE.ACTION'),
        dataField: '',
        formatter: ActionColumnFormatter,
        formatExtraData: {
          onEdit: (id: number) => handleUpdateAndDelete(id, 'UPDATE'),
          onStop: (id: number) => handleUpdateAndDelete(id, 'DELETE'),
          getButtonText: getButtonText,
        },
        width: '15%',
      },
    ],
    [getButtonText, i18T, handleUpdateAndDelete],
  );

  return <YatungTable columns={columns} data={sampleData} loading={actionLoading} />;
}

export default memo(SampleManagementTable);
