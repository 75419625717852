import MenuIcon from '@Src/_basic/icons/Menu';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import YatungButton from '../YatungButton';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Props {
  title: string | undefined;
  sx?: SxProps;
  textColor?: string;
  iconColor?: string;
  actions?: JSX.Element;
  isOrder?: boolean;
  handleClick?: () => void;
}

const YatungTestPageTitle = forwardRef<HTMLDivElement, Props>(
  ({ title, sx, textColor = '#6C708C', iconColor = '#BB5E00', actions, isOrder, handleClick }: Props, ref) => {
    const { t: i18T } = useTranslation();

    const [isOver100vh, setIsOver100vh] = useState(false);

    const scrollToTop = useCallback(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, []);

    useEffect(() => {
      const handleScroll = () => {
        const isOver100vh = window.scrollY > window.innerHeight * 0.8;
        setIsOver100vh(isOver100vh);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (
      <Stack ref={ref} direction="row" spacing={2} alignItems="center">
        <Stack
          flexDirection="row"
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ flex: '1 0 0', fontSize: 36, color: iconColor, ...sx }}
        >
          <Typography sx={{ color: textColor, fontSize: 36, ml: 3 }}>{title}</Typography>
          {isOrder && (
            <YatungButton
              onClick={handleClick}
              color="yellow"
              text={i18T('GLOBAL.MAKE_UP_ORDER')}
              startIcon={<MenuIcon />}
              sx={{ ml: 1 }}
            />
          )}
        </Stack>
        {actions}
        <Box sx={{ flexGrow: 1 }}>
          <Stack
            onClick={scrollToTop}
            sx={{
              position: 'fixed',
              right: isOver100vh ? 30 : -40,
              bottom: isOver100vh ? 30 : -40,
              p: 0.5,
              bgcolor: '#BB5E00',
              borderRadius: '50%',
              fontWeight: 'bold',
              color: '#fff',
              cursor: 'pointer',
              display: 'grid',
              placeItems: 'center',
              transform: 'scale(1.4)',
              transition: '0.7s',
              opacity: isOver100vh ? 1 : 0,
              ':hover': {
                transition: '0.7s',
                transform: 'scale(1.6)',
                bgcolor: 'rgba(90, 96, 174, 0.6)',
              },
            }}
          >
            <ExpandLessIcon />
          </Stack>
        </Box>
      </Stack>
    );
  },
);

YatungTestPageTitle.displayName = 'YatungTestPageTitle';

export default YatungTestPageTitle;
