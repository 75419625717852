import React from 'react';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import { FormHelperText, Stack, SxProps } from '@mui/material';

export interface Options {
  value: number;
  text: string | undefined;
}

export interface FactoriesOptions extends Options {
  factories: Array<Options>;
}

interface Props {
  field: any;
  form: { touched: boolean | any; errors: boolean | any };
  options: Array<Options>;
  placeholder?: string;
  sx?: SxProps;
}

export default function FormSelect({ field, form: { touched, errors }, options, ...props }: Props) {
  return (
    <Stack direction={'column'}>
      <YatungSelect
        options={options}
        error={touched[field.name] && !!errors[field.name]}
        {...field}
        {...props}
        sx={{
          ...props.sx,
        }}
      />
      <FormHelperText error={true} sx={{ ml: 2 }}>
        {touched[field.name] && errors[field.name]}
      </FormHelperText>
    </Stack>
  );
}
