import React, { useCallback, useEffect, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { Box, Stack } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export default function Content({ children }: Props) {
  const [isOver100vh, setIsOver100vh] = useState(false);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isOver100vh = window.scrollY > window.innerHeight * 0.8;
      setIsOver100vh(isOver100vh);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {children}

      <Stack
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          right: isOver100vh ? 30 : -40,
          bottom: isOver100vh ? 30 : -40,
          p: 0.5,
          bgcolor: '#BB5E00',
          borderRadius: '50%',
          fontWeight: 'bold',
          color: '#fff',
          cursor: 'pointer',
          display: 'grid',
          placeItems: 'center',
          transform: 'scale(1.4)',
          transition: '0.7s',
          opacity: isOver100vh ? 1 : 0,
          ':hover': {
            transition: '0.7s',
            transform: 'scale(1.6)',
            bgcolor: 'rgba(90, 96, 174, 0.6)',
          },
        }}
      >
        <ExpandLessIcon />
      </Stack>
    </Box>
  );
}
