import { MultilingualFileData, ReplaceLangFileData } from '@Src/_basic/object/MultilingualManagementType';
import { useLanguage } from '@Src/redux/language/languageAction';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import UploadLangField from './components/UploadLangField';
import { LanguageApi } from '@Src/_basic/protocol/languageManagement/LanguageApi';

interface Props {
  initialValues: ReplaceLangFileData;
  handleUploadGoBackButtonClick: () => void;
  langName: string;
  platformName: string;
  idValue: number | undefined;
}
const vahicleSchema = Yup.object().shape({});

export default function UploadForm(props: Props) {
  const { initialValues, handleUploadGoBackButtonClick, langName, platformName,idValue } = props;
  const [files, setFiles] = useState<FileList>();
  const [defaultName, setDefaultName] = useState<string>('');
  const { replaceUploadLanguageReports } = useLanguage();
  const handleCreateLangName = (request: ReplaceLangFileData) => {
    if (files) {
      replaceUploadLanguageReports(
        {
          langFileInfoId: idValue,
        },
        files,
        () => {
          handleUploadGoBackButtonClick();
        }
      );
    }
  };

  const handleDownloadDefaultFile = () => {
    LanguageApi.getLangPlatformDefaultFileData(
      platformName,
      (data) => {
        onGetLangNameSuccess(data);
      },
      () => {},
    );
  };

  const onGetLangNameSuccess = useCallback((data: MultilingualFileData) => {
    setDefaultName(data.download);
  }, []);

  const handleChange = (event: any) => {
    const files = event.target.files;
    if (files) {
      setFiles(files);
    }
  };

  useEffect(() => {
    handleChange;
  }, [files]);

  useEffect(() => {
    handleDownloadDefaultFile();
  }, []);


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={vahicleSchema}
      onSubmit={(request: ReplaceLangFileData) => handleCreateLangName(request)}
      enableReinitialize
    >
      {({ setFieldValue, values }) => {
        return (
          <Form>
            <UploadLangField
              values={values}
              setValues={setFieldValue}
              handleUploadGoBackButtonClick={handleUploadGoBackButtonClick}
              files={files}
              handleChange={handleChange}
              langName={langName}
              defaultName={defaultName}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
