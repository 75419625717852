import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Source(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.8,22.5c-1.3,0-2.5-0.2-3.8-0.6c-0.9-0.3-1.8-0.6-2.7-1.1L4.6,20C5,20,5.3,20,5.6,20
		c-2.4-1.9-3.7-4.7-3.7-7.8c0-2.6,1-5.1,2.8-7c0.8-1,1.8-1.8,3-2.5c1.3-0.7,2.8-1.1,4.4-1.3c1.6-0.1,3.2,0,4.8,0.5l0,0
		c0.9,0.3,1.8,0.6,2.7,1.1L19.3,4C18.8,4,18.3,4,17.7,4c2.7,1.9,4.3,4.9,4.3,8.3c0,4.8-3.4,9-8.2,9.9c-0.7,0.2-1.3,0.3-2,0.3
		C11.5,22.5,11.2,22.5,10.8,22.5z M7.4,21c1.5,0.4,2.9,0.6,4.3,0.4c0.6-0.1,1.3-0.2,1.9-0.3c4.3-0.8,7.4-4.6,7.4-8.9
		c0-3.1-1.5-5.9-4.1-7.6l-2.4-1.6L16.7,3c0,0,0,0-0.1,0l0,0c-1.5-0.4-3-0.6-4.5-0.5c-1.5,0.1-2.8,0.5-4,1.2C7.1,4.3,6.2,5,5.5,5.9
		c-1.7,1.8-2.6,4-2.6,6.3c0,2.8,1.3,5.4,3.5,7.1L8.5,21L7.4,21z M12,17.6c-3,0-5.4-2.4-5.4-5.4c0-3,2.4-5.4,5.4-5.4
		c3,0,5.4,2.4,5.4,5.4C17.4,15.2,15,17.6,12,17.6z M12,7.8c-2.4,0-4.4,2-4.4,4.4c0,2.4,2,4.4,4.4,4.4c2.4,0,4.4-2,4.4-4.4
		C16.4,9.8,14.4,7.8,12,7.8z"
      />
    </SvgIcon>
  );
}
