import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function CreateTag(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M0.5,12.2l3.9,4.4c0.3,0.2,0.7,0.2,1.1,0.2l0.1,0h8.9C14.1,16,13.8,15,13.8,14c0-3,2.4-5.4,5.4-5.4c1.5,0,2.8,0.6,3.7,1.5V7.2c0-1.1-0.9-2-2-2H5.6l-0.1,0c-0.4,0-0.7,0-1.1,0.2L0.5,9.8C-0.2,10.6-0.2,11.3,0.5,12.2z M3.2,12c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1C2.2,11.5,2.7,12,3.2,12z"/>
      <path d="M24,14c0,2.6-2.1,4.8-4.8,4.8c-2.6,0-4.8-2.1-4.8-4.8c0-2.6,2.1-4.8,4.8-4.8C21.9,9.3,24,11.4,24,14zM19.5,10.3c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4v3.3h-3.3c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h3.3v3.3c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v-3.3h3.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-3.3V10.3z"/>
    </SvgIcon>
  );
}
