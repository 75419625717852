import React from 'react';
import { Typography } from '@mui/material';
import { NewYardReciptData } from '@Src/_basic/object/YardReceiptsType';

interface Props {
  row: NewYardReciptData;
}
export default function IdColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.ids}</Typography>;
}
