import { Response } from '@Src/_basic/object/ApiType';
import {
  GetPaginationBySearch,
  GetPaginationBySearchParams,
  KeyManagementData,
  PostParams,
  PutParams,
} from '@Src/_basic/object/KeyManagementType';
import { Api } from '../Api';
import { KeyManagementProtocol } from './KeyManagementProtocol';

export class KeyManagementApi {
  //新增金鑰並送出驗證碼郵件
  public static postApplySend(
    params: PostParams,
    onSuccess?: (data: KeyManagementData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.post(KeyManagementProtocol.POST_APPLY_SEND, undefined, config, onSuccess, onFailed, onFinally);
  }
  //分頁化取得金鑰
  public static getApplySearch(
    params: GetPaginationBySearchParams,
    onSuccess?: (data: GetPaginationBySearch) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(KeyManagementProtocol.GET_APPLY_SEARCH, config, onSuccess, onFailed, onFinally);
  }
  //註銷金鑰
  public static getApplyCancel(
    id: number,
    onSuccess?: (data: KeyManagementData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };

    Api.get(KeyManagementProtocol.GET_APPLY_CANCEL, config, onSuccess, onFailed, onFinally);
  }
  //修改備註
  public static putApplyDescription(
    params: PutParams,
    onSuccess?: (data: KeyManagementData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.put(KeyManagementProtocol.PUT_APPLY_DESCRIPTION, undefined, config, onSuccess, onFailed, onFinally);
  }
}
