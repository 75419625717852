import { Response } from '@Src/_basic/object/ApiType';
import {
  GetProportionParams,
  ProportionData,
  PutProportionRequest,
} from '@Src/_basic/object/MixerAndProportionInformationBindingType';
import { Api } from '../Api';
import { MixerProportionProtocol } from './MixerProportionProtocol';

export class MixerProportionApi {
  //取得拌合機對應配比
  public static getMixerProportionSetting(
    params: GetProportionParams,
    onSuccess?: (data: Array<ProportionData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(MixerProportionProtocol.GET_MIXER_PROP_SETTING, config, onSuccess, onFailed, onFinally);
  }
  //更新拌合機對應配比
  public static putMixerProportionSetting(
    request: PutProportionRequest,
    onSuccess?: (data: Array<ProportionData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(MixerProportionProtocol.PUT_MIXER_PROP_SETTING, request, undefined, onSuccess, onFailed, onFinally);
  }
}
