import { Box, Grid } from '@mui/material';
import React from 'react';
import BasicInfo from './components/BasicInfo';
import Proportion from './components/Proportion';
import Reports from './components/Reports';
import Signature from './components/Signature';

export default function Information() {
  return (
    <Box sx={{ padding: '16px 24px' }}>
      <Grid container spacing={3}>
        <Grid item container spacing={2} direction="column" xs={7}>
          <Grid item xs>
            <BasicInfo />
          </Grid>
          <Grid item xs>
            <Signature />
          </Grid>
        </Grid>
        <Grid item container spacing={2} direction="column" xs>
          <Grid item xs={6}>
            <Proportion />
          </Grid>
          <Grid item xs={6}>
            <Reports />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
