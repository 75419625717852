import { TestCardContainer, TestVerifyResultStyles } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { AllTest } from '@Src/_basic/object/TestType';
import { ImportUTest, PutUTestRequest, UTest } from '@Src/_basic/object/test/concrete/UTestType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  testDetail: UTest;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  fillingHeight: basicNumberSchema,
});

function ExportUTestTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const initialValues = useMemo(
    () => ({
      fillingHeight: testDetail?.fillingHeight,
    }),
    [testDetail],
  );

  const [edit, setEdit] = useState<boolean>(false);

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const firstInfo = useMemo(
    () => [
      {
        title: formatNumber(testDetail?.fillingHeight?.toFixed(4) ?? ''),
        titleStyle:
          testDetail?.verifyPassedResults?.fillingHeight && testDetail?.verifyCustomResults?.fillingHeight.passed
            ? TestVerifyResultStyles.normal
            : TestVerifyResultStyles.abnormal,
      },
    ],
    [testDetail],
  );

  const firstHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.U_TEST.FILLING_HEIGHT`)}(cm)`,
    }),
    [i18T],
  );

  const secondHeader = useMemo(
    () => ({
      title: i18T(`TEST.BASE.DETECTION`),
    }),
    [i18T],
  );

  const secondInfo = useMemo(
    () => [
      isLegalPassed && isCustomPassed && !isCustomRejected
        ? {
            titles: verifyResultText,
            titlesStyle: [TestVerifyResultStyles.normal],
          }
        : {
            titles: verifyResultText,
            titlesStyle: [
              isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
              isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
            ],
          },
    ],
    [isLegalPassed, isCustomPassed, verifyResultText, isCustomRejected],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportUTest = {
        fillingHeight: checkIsNaN(+values.fillingHeight),
      };

      TestApi.putTest<PutUTestRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const fillingHeightView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <ColFieldInfo
            titleStyle={{ ...TestVerifyResultStyles.resultTitle, borderBottom: '1px solid #ccc', paddingBottom: 20 }}
            title={`${i18T(`TEST.U_TEST.FILLING_HEIGHT`)}(cm)`}
          >
            <LabelInput
              valueWidth="150px"
              value={formik.values.fillingHeight}
              isError={!!(formik.touched.fillingHeight && formik.errors.fillingHeight)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('fillingHeight', e.target.value)}
            />
          </ColFieldInfo>
        );
      case false:
        return <TestTable headerData={firstHeader} rows={firstInfo} />;
      default:
        break;
    }
  }, [edit, formik, firstHeader, firstInfo, i18T]);

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  return (
    <Stack gap={3}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        {fillingHeightView}
      </TestCardContainer>
      <TestCardContainer>
        <TestTable headerData={secondHeader} rows={secondInfo} />
      </TestCardContainer>
    </Stack>
  );
}

export default memo(ExportUTestTable);
