import React from 'react';

export default function Expt() {
  return (
    <svg width={22} height={25} viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9059 3.68891C12.9059 2.49641 11.9582 1.53279 10.8056 1.53279C9.65299 1.53279 8.70528 2.49641 8.70528 3.68891C8.70528 4.88127 9.65299 5.84503 10.8056 5.84503C11.9582 5.84503 12.9059 4.88127 12.9059 3.68891ZM12.0062 3.68891C12.0062 3.01285 11.4659 2.45923 10.8056 2.45923C10.1453 2.45923 9.60494 3.01285 9.60494 3.68891C9.60494 4.36497 10.1453 4.91845 10.8056 4.91845C11.4659 4.91845 12.0062 4.36497 12.0062 3.68891Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40436 22.1332H2.40124V4.91845H4.80247V6.14814C4.80247 7.50082 5.88307 8.60737 7.20371 8.60737H14.4074C15.7281 8.60737 16.8087 7.50082 16.8087 6.14814V4.91845H19.21V11.0666H21.6112V4.91845C21.6112 3.56577 20.5306 2.45923 19.21 2.45923H14.1913C13.6871 1.03275 12.3664 0 10.8056 0C9.24479 0 7.92408 1.03275 7.41985 2.45923H2.40124C1.0806 2.45923 0 3.56577 0 4.91845V22.1332C0 23.4859 1.0806 24.5924 2.40124 24.5924H8.40436V22.1332ZM1.50157 3.99202V23.0598H7.50469V23.666H2.40124C1.57275 23.666 0.899665 22.9694 0.899665 22.1332V4.91845C0.899665 4.08236 1.57275 3.3858 2.40124 3.3858H8.04976L8.26523 2.77619C8.64693 1.69636 9.64256 0.926575 10.8056 0.926575C11.9686 0.926575 12.9643 1.69636 13.346 2.77619L13.5614 3.3858H19.21C20.0384 3.3858 20.7115 4.08236 20.7115 4.91845V10.14H20.1096V3.99202H15.909V6.14814C15.909 6.98423 15.236 7.68079 14.4074 7.68079H7.20371C6.37522 7.68079 5.70214 6.98423 5.70214 6.14814V3.99202H1.50157Z"
        fill="#6C708C"
      />
      <path d="M17.1402 11.0339C17.221 11.0966 17.2811 11.1848 17.3118 11.286Z" fill="#6C708C" />
      <path d="M17.3118 11.286L17.8316 13.0027Z" fill="#6C708C" />
      <path d="M17.8316 13.0027C18.05 13.1163 18.2589 13.2438 18.4583 13.3874Z" fill="#6C708C" />
      <path d="M18.4583 13.3874L20.1181 13.0077Z" fill="#6C708C" />
      <path d="M20.1181 13.0077C20.216 12.9855 20.318 12.9969 20.4094 13.0402Z" fill="#6C708C" />
      <path d="M20.4094 13.0402C20.5007 13.0836 20.5769 13.1565 20.6267 13.2487Z" fill="#6C708C" />
      <path d="M20.6267 13.2487L21.9368 15.6585Z" fill="#6C708C" />
      <path d="M21.9368 15.6585C21.9868 15.7506 22.008 15.8574 21.9973 15.9631Z" fill="#6C708C" />
      <path d="M21.9973 15.9631C21.9866 16.0689 21.9445 16.1684 21.8772 16.2472Z" fill="#6C708C" />
      <path d="M21.8772 16.2472L20.7382 17.583Z" fill="#6C708C" />
      <path d="M20.7382 17.583C20.7548 17.8392 20.7548 18.0963 20.7382 18.3525Z" fill="#6C708C" />
      <path d="M20.7382 18.3525L21.8772 19.6904Z" fill="#6C708C" />
      <path d="M21.8772 19.6904C21.9445 19.7692 21.9866 19.8686 21.9973 19.9744Z" fill="#6C708C" />
      <path d="M21.9973 19.9744C22.008 20.0803 21.9868 20.1869 21.9368 20.279Z" fill="#6C708C" />
      <path d="M21.9368 20.279L20.6267 22.6898Z" fill="#6C708C" />
      <path d="M20.6267 22.6898C20.5767 22.7818 20.5006 22.8546 20.4092 22.8977Z" fill="#6C708C" />
      <path d="M20.4092 22.8977C20.3178 22.9409 20.2159 22.9522 20.1181 22.9298Z" fill="#6C708C" />
      <path d="M20.1181 22.9298L18.4583 22.5501Z" fill="#6C708C" />
      <path d="M18.4583 22.5501C18.2598 22.6928 18.05 22.8214 17.8326 22.9348Z" fill="#6C708C" />
      <path d="M17.8326 22.9348L17.3118 24.6515Z" fill="#6C708C" />
      <path d="M17.3118 24.6515C17.2811 24.7527 17.221 24.841 17.1402 24.9036Z" fill="#6C708C" />
      <path d="M17.1402 24.9036C17.0593 24.9662 16.9619 25 16.8618 25Z" fill="#6C708C" />
      <path d="M16.8618 25H14.2416Z" fill="#6C708C" />
      <path d="M14.2416 25C14.1416 25 14.0441 24.9662 13.9632 24.9036Z" fill="#6C708C" />
      <path d="M13.9632 24.9036C13.9293 24.8773 13.8991 24.8466 13.8732 24.8123Z" fill="#6C708C" />
      <path d="M13.8732 24.8123C13.8372 24.7648 13.8095 24.7103 13.7917 24.6515Z" fill="#6C708C" />
      <path d="M13.7917 24.6515L13.2728 22.9359Z" fill="#6C708C" />
      <path d="M13.2728 22.9359C13.1873 22.8914 13.1029 22.8444 13.0199 22.7951Z" fill="#6C708C" />
      <path d="M13.0199 22.7951C12.8914 22.7189 12.7661 22.6368 12.6442 22.5491Z" fill="#6C708C" />
      <path d="M12.6442 22.5491L10.9853 22.9298Z" fill="#6C708C" />
      <path d="M10.9853 22.9298C10.8874 22.952 10.7855 22.9407 10.6941 22.8973Z" fill="#6C708C" />
      <path d="M10.6941 22.8973C10.6518 22.8772 10.6128 22.8508 10.5781 22.8191Z" fill="#6C708C" />
      <path d="M10.5781 22.8191C10.5379 22.7824 10.5035 22.7383 10.4767 22.6888Z" fill="#6C708C" />
      <path d="M10.4767 22.6888L9.16668 20.279Z" fill="#6C708C" />
      <path d="M9.16668 20.279C9.11664 20.1869 9.0955 20.0803 9.10614 19.9744Z" fill="#6C708C" />
      <path d="M9.10614 19.9744C9.11684 19.8686 9.15892 19.7692 9.22626 19.6904Z" fill="#6C708C" />
      <path d="M9.22626 19.6904L10.3653 18.3525Z" fill="#6C708C" />
      <path d="M10.3653 18.3525C10.3487 18.0969 10.3487 17.8406 10.3653 17.5851Z" fill="#6C708C" />
      <path d="M10.3653 17.5851L9.22626 16.2472Z" fill="#6C708C" />
      <path d="M9.22626 16.2472C9.15892 16.1684 9.11684 16.0689 9.10614 15.9631Z" fill="#6C708C" />
      <path d="M9.10614 15.9631C9.0955 15.8574 9.11664 15.7506 9.16668 15.6585Z" fill="#6C708C" />
      <path d="M9.16668 15.6585L10.4767 13.2477Z" fill="#6C708C" />
      <path d="M10.4767 13.2477C10.5267 13.1557 10.6029 13.0829 10.6943 13.0398Z" fill="#6C708C" />
      <path d="M10.6943 13.0398C10.73 13.0229 10.7672 13.0109 10.8053 13.0039Z" fill="#6C708C" />
      <path d="M10.8053 13.0039C10.8646 12.993 10.9257 12.9941 10.9853 13.0077Z" fill="#6C708C" />
      <path d="M10.9853 13.0077L12.6442 13.3884Z" fill="#6C708C" />
      <path d="M12.6442 13.3884C12.8445 13.2448 13.0544 13.1153 13.2728 13.0017Z" fill="#6C708C" />
      <path d="M13.2728 13.0017L13.7927 11.286Z" fill="#6C708C" />
      <path d="M13.7927 11.286C13.8232 11.1852 13.8829 11.0971 13.9635 11.0345Z" fill="#6C708C" />
      <path d="M13.9635 11.0345C14.0439 10.9719 14.141 10.9379 14.2407 10.9375Z" fill="#6C708C" />
      <path d="M14.2407 10.9375H16.8618Z" fill="#6C708C" />
      <path
        d="M14.2149 16.5483C14.5695 16.1715 15.0504 15.9599 15.5517 15.9599C16.0531 15.9599 16.534 16.1715 16.8885 16.5483C17.243 16.9251 17.4422 17.436 17.4422 17.9688C17.4422 18.5015 17.243 19.0126 16.8885 19.3894C16.534 19.766 16.0531 19.9778 15.5517 19.9778C15.0504 19.9778 14.5695 19.766 14.2149 19.3894C13.8604 19.0126 13.6612 18.5015 13.6612 17.9688C13.6612 17.436 13.8604 16.9251 14.2149 16.5483Z"
        fill="#6C708C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5876 11.942H16.5158L17.0565 13.7178L17.4176 13.9047C17.5954 13.997 17.7669 14.1023 17.9308 14.2201L18.2646 14.4592L19.982 14.0674L20.9471 15.8434L19.7684 17.2265L19.7968 17.6524C19.8104 17.8627 19.8104 18.0739 19.7968 18.2842L19.7684 18.7101L20.9461 20.0943L19.982 21.8701L18.2646 21.4773L17.9308 21.7174C17.8059 21.8072 17.6765 21.8897 17.5433 21.9648C17.5018 21.9883 17.4599 22.0109 17.4176 22.0328L17.0565 22.2197L16.5178 23.9955H14.5876L14.0497 22.2207L13.6877 22.0328C13.602 21.9883 13.5176 21.9408 13.4349 21.8903C13.3459 21.836 13.2587 21.7783 13.1735 21.7174L12.8389 21.4773L11.1224 21.8701L10.1573 20.0943L11.336 18.7101L11.3076 18.2842C11.294 18.0742 11.294 17.8634 11.3076 17.6534L11.336 17.2275L10.1591 15.8434L11.1233 14.0674L12.8399 14.4612L13.1744 14.2201C13.3389 14.1026 13.5107 13.9973 13.6887 13.9047L14.0507 13.7168L14.5876 11.942ZM17.5569 15.838C17.0251 15.2728 16.3038 14.9554 15.5517 14.9554C14.7996 14.9554 14.0784 15.2728 13.5466 15.838C13.0148 16.4031 12.7161 17.1696 12.7161 17.9688C12.7161 18.768 13.0148 19.5344 13.5466 20.0995C14.0784 20.6647 14.7996 20.9821 15.5517 20.9821C16.3038 20.9821 17.0251 20.6647 17.5569 20.0995C18.0887 19.5344 18.3874 18.768 18.3874 17.9688C18.3874 17.1696 18.0887 16.4031 17.5569 15.838Z"
        fill="#6C708C"
      />
    </svg>
  );
}
