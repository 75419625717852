import YatungWindow from '@Src/_basic/components/YatungWindow';
import { numberComma } from '@Src/_basic/helpers/NumberHelpers';
import DeleteCircle from '@Src/_basic/icons/DeleteCircle';
import Edit from '@Src/_basic/icons/Edit';
import PieChartIcon from '@Src/_basic/icons/PieChart';
import { CreateProportionFormType } from '@Src/_basic/object/ProductionHistoryType';
import { Box, Grid, IconButton, Typography, styled } from '@mui/material';
import React from 'react';
import { useElementSize } from 'usehooks-ts';
import TextLine from '../../../../../../TextLine';

const Title = styled(Typography)(({ theme }) => ({
  color: '#002F8C',
  fontSize: '20px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
  lineHeight: '24px',
  letterSpacing: '0.255em',
}));

interface Props {
  data: Array<CreateProportionFormType>;
  createBtnRef: React.RefObject<HTMLButtonElement>;
  submitBtnRef: React.RefObject<HTMLButtonElement>;
  cancelBtnRef: React.RefObject<HTMLButtonElement>;
  onCreateButtonClick: () => void;
  onSubmitButtonClick: () => void;
  onCancelButtonClick: () => void;
  onEditButtonClick: (index: number) => void;
  onDeleteButtonClick: (index: number) => void;
}

export default function ShowItemsCard({
  data,
  createBtnRef,
  submitBtnRef,
  cancelBtnRef,
  onCreateButtonClick,
  onSubmitButtonClick,
  onCancelButtonClick,
  onEditButtonClick,
  onDeleteButtonClick,
}: Props) {
  const [titleRef, titleSize] = useElementSize();

  return (
    <>
      <Box sx={{ height: '100%' }}>
        <Title ref={titleRef}>使用配比</Title>
        <Box sx={{ pl: '50px', mt: '16px', height: '100%' }}>
          <Box sx={{ height: `calc(100% - ${titleSize.height}px - 16px)` }}>
            <YatungWindow
              className="scroll-bar"
              data={data}
              renderItem={(item: CreateProportionFormType, index: number) => {
                return (
                  <Grid container direction="row" alignItems="center" sx={{ py: '4px' }}>
                    <Grid item xs={5}>
                      <TextLine icon={<PieChartIcon stroke="#002F8C" />} content={item.displayName} />
                    </Grid>
                    <Grid item xs>
                      <TextLine title="設計強度：" content={`${numberComma(item.designedStrength)} PSI`} />
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          onEditButtonClick(index);
                        }}
                      >
                        <Edit stroke="#002F8C" />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => onDeleteButtonClick(index)}>
                        <DeleteCircle />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </Box>
        </Box>
      </Box>
      <button ref={createBtnRef} hidden onClick={onCreateButtonClick} />
      <button ref={submitBtnRef} hidden onClick={onSubmitButtonClick} />
      <button ref={cancelBtnRef} hidden onClick={onCancelButtonClick} />
    </>
  );
}
