import { AllTest } from '@Src/_basic/object/TestType';
import { CoarseSieve, CoarseSieveLegalTypeEnum } from '@Src/_basic/object/test/sandRock/CoarseSieveType';
import ExportCoarseSieveTable from '@Src/app/module/test/sandRock/coarseSieve/ExportCoarseSieveTable';
import React, { memo, useState } from 'react';
import { TestCardContainer } from '../../MuiStyleComponent/TestComponent';
import TabCoarseSieveLineChart from '../TabCoarseSieveLineChart';

interface Props {
  testDetail: CoarseSieve;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const CoarseSieveAnalysisCommonTable = ({ testDetail, afterUpdateTest, canEdit }: Props) => {
  const [selectType, setSelectType] = useState<CoarseSieveLegalTypeEnum>(CoarseSieveLegalTypeEnum.TYPE1);

  return (
    <>
      <TestCardContainer sx={{ height: 600 }}>
        <TabCoarseSieveLineChart testFormS1={testDetail} onSelectTypeChange={setSelectType} />
      </TestCardContainer>
      <ExportCoarseSieveTable
        testDetail={testDetail}
        type={selectType}
        afterUpdateTest={afterUpdateTest}
        canEdit={canEdit}
      />
    </>
  );
};

export default memo(CoarseSieveAnalysisCommonTable);
