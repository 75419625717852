import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Factory(props: SvgIconProps) {
  return (
    <svg {...props} width="34" height="32" viewBox="0 0 34 32" fill="none">
      <mask id="path-1-inside-1_1_74521" fill="white">
        <path d="M30.8273 0C31.0065 0 31.1518 0.145204 31.1518 0.324324V1.72973C31.1518 2.08797 30.8612 2.37838 30.5028 2.37838H28.5558V3.56757C28.5558 3.74669 28.4105 3.89189 28.2313 3.89189C28.052 3.89189 27.9068 3.74669 27.9068 3.56757V2.37838C27.9068 2.02014 28.1974 1.72973 28.5558 1.72973H30.5028V0.324324C30.5028 0.145204 30.648 0 30.8273 0Z" />
        <path d="M32.9906 1.94595C33.1698 1.94595 33.3151 2.09115 33.3151 2.27027V3.67568C33.3151 4.03392 33.0245 4.32432 32.6661 4.32432H30.7191V5.51351C30.7191 5.69263 30.5738 5.83784 30.3946 5.83784C30.2153 5.83784 30.0701 5.69263 30.0701 5.51351V4.32432C30.0701 3.96608 30.3607 3.67568 30.7191 3.67568H32.6661V2.27027C32.6661 2.09115 32.8113 1.94595 32.9906 1.94595Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.16332 4.54054H3.02864V6.27027H3.24497V6.7027H3.02864V8.21622H3.24497V8.64865H3.02864V9.51351H3.67764V7.13514C3.67764 6.89631 3.87135 6.7027 4.1103 6.7027H5.84095V6.05405C5.84095 5.81523 6.03466 5.62162 6.27362 5.62162H9.08593C9.32488 5.62162 9.51859 5.81523 9.51859 6.05405V7.78378H17.7392V6.7027H17.5229V6.27027H17.7392V4.54054H18.6045V6.27027H18.8209V6.7027H18.6045V8.21622H18.8209V8.64865H18.6045V9.51351H19.4698V6.05405C19.4698 5.81523 19.6636 5.62162 19.9025 5.62162H22.7148C22.9538 5.62162 23.1475 5.81523 23.1475 6.05405V9.51351H26.1761V22.7027H15.5759V21.1892H11.0329V24.4324H20.7678V30.7027H8.72214V27.027H2.59598V30.7027H0.432663V9.51351H2.16332V8.64865H1.94698V8.21622H2.16332V6.7027H1.94698V6.27027H2.16332V4.54054ZM11.0329 20.5405V9.51351H15.5759V20.5405H11.0329Z"
        />
        <path d="M30.9354 9.08108L31.5844 6.7027H26.8251L27.4741 9.08108H30.9354Z" />
        <path d="M30.9354 9.51351L31.5844 24H26.8251L27.4741 9.51351H30.9354Z" />
        <path d="M26.1761 23.1351H15.5759V24H26.1761V23.1351Z" />
        <path d="M0 31.5676C0 31.3287 0.19371 31.1351 0.432663 31.1351H32.0171C32.256 31.1351 32.4497 31.3287 32.4497 31.5676C32.4497 31.8064 32.256 32 32.0171 32H0.432663C0.19371 32 0 31.8064 0 31.5676Z" />
        <path d="M21.2005 30.7027H31.8007V24.4324H21.2005V30.7027Z" />
      </mask>
      <path
        d="M30.8273 0C31.0065 0 31.1518 0.145204 31.1518 0.324324V1.72973C31.1518 2.08797 30.8612 2.37838 30.5028 2.37838H28.5558V3.56757C28.5558 3.74669 28.4105 3.89189 28.2313 3.89189C28.052 3.89189 27.9068 3.74669 27.9068 3.56757V2.37838C27.9068 2.02014 28.1974 1.72973 28.5558 1.72973H30.5028V0.324324C30.5028 0.145204 30.648 0 30.8273 0Z"
        stroke="white"
        mask="url(#path-1-inside-1_1_74521)"
      />
      <path
        d="M32.9906 1.94595C33.1698 1.94595 33.3151 2.09115 33.3151 2.27027V3.67568C33.3151 4.03392 33.0245 4.32432 32.6661 4.32432H30.7191V5.51351C30.7191 5.69263 30.5738 5.83784 30.3946 5.83784C30.2153 5.83784 30.0701 5.69263 30.0701 5.51351V4.32432C30.0701 3.96608 30.3607 3.67568 30.7191 3.67568H32.6661V2.27027C32.6661 2.09115 32.8113 1.94595 32.9906 1.94595Z"
        stroke="white"
        mask="url(#path-1-inside-1_1_74521)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16332 4.54054H3.02864V6.27027H3.24497V6.7027H3.02864V8.21622H3.24497V8.64865H3.02864V9.51351H3.67764V7.13514C3.67764 6.89631 3.87135 6.7027 4.1103 6.7027H5.84095V6.05405C5.84095 5.81523 6.03466 5.62162 6.27362 5.62162H9.08593C9.32488 5.62162 9.51859 5.81523 9.51859 6.05405V7.78378H17.7392V6.7027H17.5229V6.27027H17.7392V4.54054H18.6045V6.27027H18.8209V6.7027H18.6045V8.21622H18.8209V8.64865H18.6045V9.51351H19.4698V6.05405C19.4698 5.81523 19.6636 5.62162 19.9025 5.62162H22.7148C22.9538 5.62162 23.1475 5.81523 23.1475 6.05405V9.51351H26.1761V22.7027H15.5759V21.1892H11.0329V24.4324H20.7678V30.7027H8.72214V27.027H2.59598V30.7027H0.432663V9.51351H2.16332V8.64865H1.94698V8.21622H2.16332V6.7027H1.94698V6.27027H2.16332V4.54054ZM11.0329 20.5405V9.51351H15.5759V20.5405H11.0329Z"
        stroke="white"
        mask="url(#path-1-inside-1_1_74521)"
      />
      <path
        d="M30.9354 9.08108L31.5844 6.7027H26.8251L27.4741 9.08108H30.9354Z"
        stroke="white"
        mask="url(#path-1-inside-1_1_74521)"
      />
      <path
        d="M30.9354 9.51351L31.5844 24H26.8251L27.4741 9.51351H30.9354Z"
        stroke="white"
        mask="url(#path-1-inside-1_1_74521)"
      />
      <path d="M26.1761 23.1351H15.5759V24H26.1761V23.1351Z" stroke="white" mask="url(#path-1-inside-1_1_74521)" />
      <path
        d="M0 31.5676C0 31.3287 0.19371 31.1351 0.432663 31.1351H32.0171C32.256 31.1351 32.4497 31.3287 32.4497 31.5676C32.4497 31.8064 32.256 32 32.0171 32H0.432663C0.19371 32 0 31.8064 0 31.5676Z"
        stroke="white"
        mask="url(#path-1-inside-1_1_74521)"
      />
      <path d="M21.2005 30.7027H31.8007V24.4324H21.2005V30.7027Z" stroke="white" mask="url(#path-1-inside-1_1_74521)" />
    </svg>
  );
}
