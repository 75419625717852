import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LeftArrow(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="85" height="85" viewBox="0 0 85 85" fill="none">
      <rect width="85" height="85" rx="9" fill="#BB5E00" />
      <path
        d="M43.3914 21.6021L38.7894 17L12.7891 43.0003L38.7894 69.0006L43.3914 64.3985L21.9932 43.0003L43.3914 21.6021Z"
        fill="white"
      />
      <path
        d="M71.9896 21.6021L67.3875 17L41.3872 43.0003L67.3875 69.0006L71.9896 64.3985L50.5913 43.0003L71.9896 21.6021Z"
        fill="white"
      />
    </SvgIcon>
  );
}
