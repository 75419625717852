import { Api } from '../Api';
import { Response } from '@Src/_basic/object/ApiType';
import { ClearExecutionProtocol } from './ClearExecutionProtocol';
import {
  GetPaginationClearExecution,
  PaginationClearExecutionParams,
  PostClearExecutionParams,
} from '@Src/_basic/object/ClearExecutionType';
import { WarehouseData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';

export class ClearExecutionApi {
  public static postClearExecution(
    request: PostClearExecutionParams,
    onSuccess?: (data: WarehouseData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ClearExecutionProtocol.POST_CLEARANCE, request, undefined, onSuccess, onFailed, onFinally);
  }
  public static getPaginationClearExecution(
    params: PaginationClearExecutionParams,
    onSuccess?: (data: GetPaginationClearExecution) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ClearExecutionProtocol.GET_PAGINATION_CLEARANCE, config, onSuccess, onFailed, onFinally);
  }
}
