import { ProductionHistoryProvider } from '@Src/_basic/contexts/ProductionHistory';
import { useProductionHistory } from '@Src/redux/productionHistory/productionHistoryActions';
import { Paper } from '@mui/material';
import React, { useState } from 'react';
import ActionBar from './components/ActionBar';
import CreateProportionForm from './components/CreateProportionForm';
import UpdateProportionFrom from './components/UpdateProportionFrom';
import ViewProportion from './components/ViewProportion';

export default function Proportion() {
  const { projectInfo } = useProductionHistory();

  const [editMode, setEditMode] = useState(false);
  const [hiddenActionBar, setHiddenActionBar] = useState(false);
  const createBtnRef = React.useRef<HTMLButtonElement>(null);
  const submitBtnRef = React.useRef<HTMLButtonElement>(null);
  const cancelBtnRef = React.useRef<HTMLButtonElement>(null);

  const handleCreateButtonClick = () => {
    createBtnRef?.current?.click?.();
  };

  const handleSubmitButtonClick = () => {
    setEditMode(false);
    submitBtnRef?.current?.click?.();
  };

  const handleCancelcelButtonClick = () => {
    setEditMode(false);
    cancelBtnRef?.current?.click?.();
  };

  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  return (
    <ProductionHistoryProvider>
      <Paper elevation={4} sx={{ position: 'relative', height: '100%', padding: '12px 16px' }}>
        {!projectInfo.proportion.length ? (
          <>
            <CreateProportionForm
              createBtnRef={createBtnRef}
              submitBtnRef={submitBtnRef}
              cancelBtnRef={cancelBtnRef}
              onEditModeChange={setEditMode}
            />
            {editMode && (
              <ActionBar
                editMode={editMode}
                onCreateButtonClick={handleCreateButtonClick}
                onConfirmButtonClick={handleSubmitButtonClick}
                onCancelButtonClick={handleCancelcelButtonClick}
              />
            )}
          </>
        ) : (
          <>
            {editMode ? (
              <UpdateProportionFrom
                createBtnRef={createBtnRef}
                submitBtnRef={submitBtnRef}
                cancelBtnRef={cancelBtnRef}
                onEditModeChange={setEditMode}
                onHiddenActionBarChange={setHiddenActionBar}
              />
            ) : (
              <ViewProportion />
            )}
            {!hiddenActionBar && (
              <ActionBar
                editMode={editMode}
                onEditButtonClick={handleEditButtonClick}
                onCreateButtonClick={handleCreateButtonClick}
                onConfirmButtonClick={handleSubmitButtonClick}
                onCancelButtonClick={handleCancelcelButtonClick}
              />
            )}
          </>
        )}
      </Paper>
    </ProductionHistoryProvider>
  );
}
