import React from 'react';
import YatungButton from '@Src/_basic/components/YatungButton';
import Cancel from '@Src/_basic/icons/CancelV2';
import Confirm from '@Src/_basic/icons/Confirm';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@Src/_basic/icons/Edit';

interface Props {
  editMode: boolean;
  onEditButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  onConfirmButtonClick?: () => void;
}

export default function ActionBar({ editMode, onEditButtonClick, onConfirmButtonClick, onCancelButtonClick }: Props) {
  const { t: i18T } = useTranslation();

  const handleEditButtonClick = () => {
    onEditButtonClick?.();
  };

  const handleConfirmButtonClick = () => {
    onConfirmButtonClick?.();
  };

  const handleCancelcelButtonClick = () => {
    onCancelButtonClick?.();
  };

  return (
    <Box
      sx={
        editMode
          ? { position: 'absolute', bottom: '16px', right: '16px' }
          : { position: 'absolute', top: '16px', right: '16px' }
      }
    >
      <Grid container direction="row" justifyContent="flex-end">
        {editMode ? (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <YatungButton
              text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.CANCEL`)}
              color="red"
              startIcon={<Cancel />}
              onClick={() => {
                handleCancelcelButtonClick();
              }}
            />
            <YatungButton
              text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.SUBMIT`)}
              color="green"
              startIcon={<Confirm />}
              onClick={() => {
                handleConfirmButtonClick();
              }}
            />
          </Box>
        ) : (
          <YatungButton
            text={i18T(`REPORT.PRODUCTION_HISTORY.INFORMATION.ACTIONS.EDIT`)}
            color="blue"
            startIcon={<EditIcon />}
            onClick={handleEditButtonClick}
          />
        )}
      </Grid>
    </Box>
  );
}
