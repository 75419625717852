import { ChildrenTypography, TitleTypography } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import { Grid, styled } from '@mui/material';
import React, { memo } from 'react';

interface Props {
  labels: (string | number | any[])[];
  values: (string | number | any[] | undefined)[];
  widths?: number[];
  labelStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

const HeaderContainer = styled(Grid)`
  margin-top: ${({ theme }: any) => theme.spacing(3)};
  padding-bottom: ${({ theme }: any) => theme.spacing(2)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled(Grid)`
  border-bottom-width: 1px;
  border-bottom-color: #9a99a1;
  display: flex;
  flex-direction: row;
  padding-bottom: ${({ theme }: any) => theme.spacing(1)};
  justify-content: space-between;
  border-bottom: 1px solid #aaa;
`;

const RowContent: React.FC<Props> = ({
  labels,
  values,
  widths = [],
  labelStyle,
  textStyle,
  headerStyle,
  contentStyle,
}) => {
  return (
    <Grid container direction="column" spacing={1}>
      <HeaderContainer item style={{ ...headerStyle }}>
        {labels.map((label, i) => (
          <Grid
            key={`label-${i}`}
            item
            style={{ width: widths.length > 0 ? `${widths[i]}%` : `${Math.floor(100 / labels.length)}%` }}
          >
            {Array.isArray(label) ? (
              label.map((l, j) => (
                <TitleTypography key={`label-text-${j}`} style={{ padding: '0 3px' }}>
                  {l}
                </TitleTypography>
              ))
            ) : (
              <TitleTypography style={labelStyle}>{label}</TitleTypography>
            )}
          </Grid>
        ))}
      </HeaderContainer>
      <ContentContainer item style={{ ...contentStyle }}>
        {values?.map((value, i) => (
          <Grid
            key={`value-${i}`}
            item
            container
            direction="row"
            justifyContent="space-between"
            style={{
              width: widths.length > 0 ? `${widths[i]}%` : `${Math.floor(100 / values.length)}%`,
              paddingBottom: 10,
            }}
          >
            {Array.isArray(value) ? (
              <>
                {value[0] ? (
                  <ChildrenTypography style={{ ...textStyle, color: value[1] ? '#FF0002' : '#70C030' }}>
                    {value[0]}
                  </ChildrenTypography>
                ) : (
                  <ChildrenTypography style={{ ...textStyle, color: value[1] ? '#FF0002' : '#70C030' }}>
                    {value[1] ? '異常' : '正常'}
                  </ChildrenTypography>
                )}
              </>
            ) : (
              <ChildrenTypography style={textStyle}>{value ?? ''}</ChildrenTypography>
            )}
          </Grid>
        ))}
      </ContentContainer>
    </Grid>
  );
};

export default memo(RowContent);
