import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PersonIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
          d="M15.2667 4.79032C15.2667 7.14447 13.2899 9.08065 10.8167 9.08065C8.34345 9.08065 6.36665 7.14447 6.36665 4.79032C6.36665 2.43618 8.34344 0.5 10.8167 0.5C13.2899 0.5 15.2667 2.43618 15.2667 4.79032ZM21.5 21.2903C21.5 21.3604 21.4993 21.4303 21.4978 21.5H0.502199C0.500735 21.4303 0.5 21.3604 0.5 21.2903C0.5 15.7026 5.18545 11.1452 11 11.1452C16.8146 11.1452 21.5 15.7026 21.5 21.2903Z"
          stroke="#6C708C"
        />
      </svg>
    </SvgIcon>
  );
}
