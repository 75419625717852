import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function SampleLocationGray(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="25" height="34" viewBox="0 0 18 21" fill="none">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 24" fill="none">
        <path
          d="M11.9863 5.71893L11.9857 5.71834C11.5143 5.26662 10.937 5.03749 10.2811 5.03749C9.62513 5.03749 9.04792 5.26664 8.57709 5.71864C8.10518 6.17167 7.85798 6.73539 7.85798 7.38364C7.85798 8.03173 8.10507 8.59516 8.57699 9.04762C9.04773 9.5002 9.62496 9.72979 10.2811 9.72979C10.9372 9.72979 11.5147 9.50016 11.986 9.04772C12.4574 8.59511 12.7041 8.03163 12.7041 7.38364C12.7041 6.73568 12.4575 6.17205 11.9863 5.71893ZM4.90904 12.2991L4.90892 12.2989C3.66906 10.5404 3.08875 8.96737 3.08875 7.56825C3.08875 5.38503 3.81402 3.68985 5.24035 2.42726L5.24043 2.42719C6.69663 1.13762 8.36991 0.499023 10.2811 0.499023C12.1922 0.499023 13.8655 1.13762 15.3217 2.42719L15.3218 2.42726C16.7481 3.68985 17.4734 5.38503 17.4734 7.56825C17.4734 8.96744 16.8933 10.5405 15.6541 12.299C14.4671 13.9831 12.6818 15.8214 10.2811 17.813C7.88036 15.8214 6.09543 13.9831 4.90904 12.2991Z"
          stroke="#6C708C"
        />
        <path
          d="M4.89615 15.5646C2.12052 16.2889 0.280762 17.5567 0.280762 18.9997C0.280762 21.2506 4.75791 23.0754 10.2808 23.0754C15.8036 23.0754 20.2808 21.2506 20.2808 18.9997C20.2808 17.4272 18.096 16.0628 14.8961 15.3831V16.0827C15.6887 16.26 16.4066 16.4806 17.0295 16.7344C18.7823 17.4488 19.5115 18.2988 19.5115 18.9997C19.5115 19.7005 18.7823 20.5505 17.0295 21.2649C15.342 21.9526 12.9568 22.3961 10.2808 22.3961C7.60475 22.3961 5.2195 21.9526 3.53205 21.2649C1.77925 20.5505 1.04999 19.7005 1.04999 18.9997C1.04999 18.2988 1.77925 17.4488 3.53205 16.7344C3.94664 16.5655 4.40335 16.4112 4.89615 16.2747V15.5646Z"
          fill="#6C708C"
        />
        <path
          d="M4.89615 15.5646C2.12052 16.2889 0.280762 17.5567 0.280762 18.9997C0.280762 21.2506 4.75791 23.0754 10.2808 23.0754C15.8036 23.0754 20.2808 21.2506 20.2808 18.9997C20.2808 17.4272 18.096 16.0628 14.8961 15.3831V16.0827C15.6887 16.26 16.4066 16.4806 17.0295 16.7344C18.7823 17.4488 19.5115 18.2988 19.5115 18.9997C19.5115 19.7005 18.7823 20.5505 17.0295 21.2649C15.342 21.9526 12.9568 22.3961 10.2808 22.3961C7.60475 22.3961 5.2195 21.9526 3.53205 21.2649C1.77925 20.5505 1.04999 19.7005 1.04999 18.9997C1.04999 18.2988 1.77925 17.4488 3.53205 16.7344C3.94664 16.5655 4.40335 16.4112 4.89615 16.2747V15.5646Z"
          stroke="#6C708C"
        />
      </svg>
    </SvgIcon>
  );
}
