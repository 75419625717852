import React, { memo } from 'react';

import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import MonthlyReport from './page/MonthlyReport';
import Record from './page/Record';
import SieveReport from './page/SieveReport';

const routesConfig: RouteConfig[] = [
  {
    path: '/record/*',
    element: <Record />,
    permission: 'WD054',
  },
  {
    path: '/sieve-report/*',
    element: <SieveReport />,
    permission: 'WD055',
  },
  {
    path: '/monthly-report/*',
    element: <MonthlyReport />,
    permission: 'WD056',
  },
];

const View = () => PermissionRoute(routesConfig);

export default memo(View);
