import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { OrganiztionsData } from '@Src/_basic/object/AccountRightType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import IndexColumnFormatter from './columnFormatter/IndexColumnFormatter';
import OrganiztionNameColumnFormatter from './columnFormatter/OrganiztionNameColumnFormatter';

interface Props {
  organizationData: Array<OrganiztionsData>;
  setTargetId: (e: number) => void;
  setTargetName: (e: string) => void;
  setShowUpdateCard: (e: boolean) => void;
  setShowDeleteCard: (e: boolean) => void;
}

export default function OrganizeManagementTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { organizationData, setTargetId, setTargetName, setShowUpdateCard, setShowDeleteCard } = props;
  const handleGoToUpdatePage = (id: number, organiztionName: string) => {
    setTargetId(id);
    setTargetName(organiztionName);
    setShowUpdateCard(true);
  };
  const handleGoToDeletePage = (id: number, organiztionName: string) => {
    setTargetId(id);
    setTargetName(organiztionName);
    setShowDeleteCard(true);
  };
  const getButtonText = (status: string) => {
    switch (status) {
      case '編輯':
        return i18T('GLOBAL.UPDATE');
      case '刪除':
        return i18T('GLOBAL.DELETE');
      default:
        return '';
    }
  };
  const columns: Array<Column> = [
    {
      text: i18T('GLOBAL.NO'),
      dataField: 'index',
      formatter: IndexColumnFormatter,
    },
    {
      text: i18T('ORGANIZEMANAGEMENT.ORGANIZE_NAME'),
      dataField: 'organiztionName',
      formatter: OrganiztionNameColumnFormatter,
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        onGoToUpdatePage: handleGoToUpdatePage,
        onGoToDeletePage: handleGoToDeletePage,
        getButtonText: getButtonText,
      },
    },
  ];
  return <YatungTable columns={columns} data={organizationData} />;
}
