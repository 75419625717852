import React from 'react';
import { Typography } from '@mui/material';

interface Props {
  cellContent: boolean;
}
export default function StateColumnFormatter(props: Props) {
  const { cellContent } = props;
  return (
    <Typography
      variant="inherit"
      sx={{
        color: cellContent ? '#CB333B' : '#3E9B2F',
        fontWeight: 400,
        fontSize: 16,
      }}
    >
      {cellContent ? '關閉中' : '運轉中'}
    </Typography>
  );
}
