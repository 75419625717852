import YatungCheckBox from '@Src/_basic/components/YatungCheckBox';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import { Button, SelectChangeEvent, Stack, Typography, styled } from '@mui/material';
import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UploadFile from './UploadFile';
import UploadPhoto from './UploadPhoto';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungLoading from '@Src/_basic/components/YatungLoading';
import { FineAndRejectFileInfo } from '@Src/_basic/object/FileType';
import { FindAndRejectRecordData, FindAndRejectRecordRequest } from '@Src/_basic/object/FineRecordType';
import { TestItemEnum } from '@Src/_basic/object/TestType';
import { FileApi } from '@Src/_basic/protocol/file/FileApi';
import { FineRecordApi } from '@Src/_basic/protocol/fineRecord/FineRecordApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useParams } from 'react-router';

interface Props {
  fineAndRejectData?: FindAndRejectRecordData;
  fineFileAndPhoto?: Array<FineAndRejectFileInfo>;
  getFindAndRejectRecordFn: () => void;
}

const Text = styled(Typography)(() => ({
  padding: '0 10px',
  fontSize: 22,
  fontWeight: 600,
}));

const currencyOptions = [
  {
    value: 'NTD',
    text: '台幣',
  },
  {
    value: 'USD',
    text: '美金',
  },
  {
    value: 'JPY',
    text: '日圓',
  },
  {
    value: 'VND',
    text: '越南盾',
  },
];

const FineTable = ({ fineAndRejectData, fineFileAndPhoto, getFindAndRejectRecordFn }: Props) => {
  const { t: i18T } = useTranslation();

  const { exptItemCode, exptId } = useParams();
  const { actionLoading } = useApi();
  const { userData } = useAuth();

  const [returnMaterialCheckBox, setReturnMaterialCheckBox] = useState<boolean>(false);
  const [fineCheckBox, setFineCheckBox] = useState<boolean>(false);

  const [selectStartTime, setSelectStartTime] = useState<Date>(new Date());
  const [selectEndTime, setSelectEndTime] = useState<Date>(new Date());

  const [weight, setWeight] = useState<string>('');
  const [find, setFind] = useState<string>('');
  const [currency, setCurrency] = useState<string | number>(0);

  const [finePhotoList, setFinePhotoList] = useState<Array<FineAndRejectFileInfo>>([]);
  const [fineFileList, setFineFileList] = useState<Array<FineAndRejectFileInfo>>([]);

  const [remark, setRemark] = useState<string>('');

  const postFileAndPhoto = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (!files) return;

      FileApi.postFineAndRejectFile(
        {
          exptItemCode: exptItemCode as TestItemEnum,
          exptId: Number(exptId),
          uploaderId: userData?.account as string,
        },
        files,
        getFindAndRejectRecordFn,
      );
    },
    [exptId, exptItemCode, userData?.account, getFindAndRejectRecordFn],
  );

  const editFineAndRejectRecord = useCallback((data: FindAndRejectRecordRequest) => {
    Object.entries(data).forEach(([key, value]) => {
      switch (key) {
        case 'rejectStartDate':
          if (!value) return;
          setReturnMaterialCheckBox(true);
          setSelectStartTime(new Date(value));
          break;
        case 'rejectEndDate':
          if (!value) return;
          setSelectEndTime(new Date(value));
          break;
        case 'tonAmount':
          if (!value) return;
          setWeight(value.toString());
          break;
        case 'fineAmount':
          if (!value) return;
          setFineCheckBox(true);
          setFind(value.toString());
          break;
        case 'currency':
          if (!value) return;
          setCurrency(value);
          break;
        case 'remark':
          if (!value) return;
          setRemark(value);
          break;
        default:
          break;
      }
    });
  }, []);

  const completeRecord = useCallback(() => {
    if (!exptItemCode || !exptId) return;
    if (returnMaterialCheckBox && (!selectStartTime || !selectEndTime || !weight)) return;
    if (fineCheckBox && (!find || !currency)) return;

    const requst = {
      exptItemCode: exptItemCode as TestItemEnum,
      exptId: Number(exptId),
      ...(returnMaterialCheckBox && {
        rejectStartDate: new Date(selectStartTime).getTime(),
        rejectEndDate: new Date(selectEndTime).getTime(),
        tonAmount: Number(weight),
      }),
      ...(fineCheckBox && {
        fineAmount: Number(find),
        currency: currency as 'NTD' | 'USD' | 'JPY' | 'VND',
      }),
      remark,
    };

    FineRecordApi.postFindAndRejectRecord(requst, getFindAndRejectRecordFn);
  }, [
    returnMaterialCheckBox,
    fineCheckBox,
    selectStartTime,
    selectEndTime,
    weight,
    find,
    currency,
    remark,
    exptItemCode,
    exptId,
    getFindAndRejectRecordFn,
  ]);

  const deleteFileAndPhoto = useCallback(
    (id: number) => {
      FileApi.deleteFile(id, getFindAndRejectRecordFn);
    },
    [getFindAndRejectRecordFn],
  );

  useEffect(() => {
    if (!fineAndRejectData) return;
    editFineAndRejectRecord(fineAndRejectData);
  }, [fineAndRejectData]);

  useEffect(() => {
    if (!fineFileAndPhoto || fineFileAndPhoto?.length <= 0) return;
    fineFileAndPhoto.forEach((item) => {
      if (item.name.includes('.jpg') || item.name.includes('.png') || item.name.includes('.jpeg')) {
        setFinePhotoList((prev) => {
          const _index = prev.findIndex((photo) => photo.id === item.id);
          if (_index > -1) return [...prev];
          else return [...prev, item];
        });
      } else {
        setFineFileList((prev) => {
          const _index = prev.findIndex((file) => file.id === item.id);
          if (_index > -1) return [...prev];
          else return [...prev, item];
        });
      }
    });
  }, [fineFileAndPhoto]);

  return (
    <Stack sx={{ px: 20, gap: 3, py: 5, opacity: actionLoading ? 0.5 : 1 }}>
      {actionLoading && <YatungLoading overlay />}
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Stack flexDirection="row" alignItems="center">
          <YatungCheckBox
            options={[{ value: 1, text: '退料:' }]}
            labelTextStyle={{ fontSize: 22, fontWeight: 600 }}
            onChange={() => setReturnMaterialCheckBox((prev) => !prev)}
            initialCheckedValue={returnMaterialCheckBox ? [1] : []}
          />
          <YatungDateSelect
            value={new Date(selectStartTime)}
            onChange={(e: Date | null) => {
              setSelectStartTime(e as Date);
            }}
            disabled={!returnMaterialCheckBox}
          />
          <Text>至</Text>
          <YatungDateSelect
            value={new Date(selectEndTime)}
            onChange={(e: Date | null) => {
              setSelectEndTime(e as Date);
            }}
            disabled={!returnMaterialCheckBox}
          />
          <Text>至</Text>
          <YatungInput
            value={weight}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setWeight(e.target.value)}
            disabled={!returnMaterialCheckBox}
          />
          <Text>噸</Text>
        </Stack>
        <YatungButton color={'red'} onClick={completeRecord} disabled={actionLoading}>
          {'完成紀錄'}
        </YatungButton>
      </Stack>
      <Stack flexDirection="row" alignItems="center">
        <YatungCheckBox
          options={[{ value: 1, text: '罰款:' }]}
          labelTextStyle={{ fontSize: 22, fontWeight: 600 }}
          onChange={() => setFineCheckBox((prev) => !prev)}
          initialCheckedValue={fineCheckBox ? [1] : []}
        />
        <YatungInput
          value={find}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFind(e.target.value)}
          disabled={!fineCheckBox}
        />
        <YatungSelect
          options={currencyOptions}
          onChange={(e: SelectChangeEvent<unknown>) => {
            setCurrency(e.target.value as string);
          }}
          value={currency}
          style={{ marginLeft: 10 }}
          disabled={!fineCheckBox}
        />
      </Stack>

      <Stack flexDirection="column" gap={2}>
        <Text>{`${i18T(`TEST.REMARK.TITLE`)}:`}</Text>
        <Stack>
          <textarea
            style={{
              width: '100%',
              height: '100%',
              resize: 'none',
              minHeight: 300,
              fontWeight: 600,
              fontSize: 18,
              padding: 10,
              border: '1px solid gray',
            }}
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </Stack>
        <Stack flexDirection="row" gap={3} my={4}>
          <Stack flex={1}>
            <UploadPhoto handleChange={postFileAndPhoto} />
            <Stack mt={2} justifyContent="center">
              <Text>新增的圖檔:</Text>
              <Stack sx={{ border: '2px solid gray', p: 4, minHeight: 500 }} mt={2} borderRadius={15}>
                {finePhotoList?.map((photo: FineAndRejectFileInfo, index: number) => (
                  <Stack
                    key={index}
                    sx={{ border: '1px solid #ccc', p: 4, position: 'relative' }}
                    justifyContent="flex-end"
                    alignItems="center"
                    mt={2}
                    py={5}
                    borderRadius={5}
                  >
                    {!actionLoading && (
                      <Button
                        sx={{ position: 'absolute', right: 5, top: 20, borderRadius: 10 }}
                        onClick={() => deleteFileAndPhoto(photo.id)}
                      >
                        <HighlightOffIcon color="action" />
                      </Button>
                    )}
                    <img src={photo.path} alt="" style={{ width: 400, height: 400, objectFit: 'contain' }} />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
          <Stack flex={1}>
            <UploadFile handleChange={postFileAndPhoto} />
            <Stack mt={2}>
              <Text>新增的檔案:</Text>
              <Stack sx={{ border: '2px solid gray', p: 4, minHeight: 500 }} mt={2} borderRadius={15}>
                {fineFileList?.map((file: FineAndRejectFileInfo, index: number) => (
                  <Stack
                    key={index}
                    sx={{ border: '1px solid #ccc', p: 4, position: 'relative' }}
                    justifyContent="flex-end"
                    alignItems="center"
                    mt={2}
                    py={5}
                    borderRadius={5}
                  >
                    {!actionLoading && (
                      <Button
                        sx={{
                          position: 'absolute',
                          right: 5,
                          top: 20,
                          borderRadius: 10,
                          opacity: actionLoading ? 0.5 : 1,
                        }}
                        onClick={() => deleteFileAndPhoto(file.id)}
                      >
                        <HighlightOffIcon color="action" />
                      </Button>
                    )}
                    <Button href={file.download as string} style={{ width: 400, height: 400, objectFit: 'contain' }}>
                      {file.name}
                    </Button>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(FineTable);
