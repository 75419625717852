import { KeyManagementData } from '@Src/_basic/object/KeyManagementType';
import { AddCircle } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';

export type ExtraData = {
  setIsNoteAlert: (e: boolean) => void;
  isNoteAlert: boolean;
  setNoteAlertText: (e: string) => void;
  noteAlertText: string;
};

interface Props {
  row: KeyManagementData;
  formatExtraData: ExtraData;
}
export default function DescriptionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;

  const handleClickAdd = () => {
    formatExtraData.setIsNoteAlert(true);
    formatExtraData.setNoteAlertText(row.description);
  };
  const getConditionalStyle = () => {
    const description = row.description;
    return (
      <>
        {description.length <= 10 ? (
          <Stack direction="row" spacing={1}>
            <Typography variant="inherit">{description}</Typography>
          </Stack>
        ) : (
          <Stack direction="row" spacing={1}>
            <Typography variant="inherit">{description.substring(0, 10)}</Typography>
            <Typography variant="inherit">...</Typography>
            <AddCircle onClick={handleClickAdd} sx={{ ':hover': { cursor: 'pointer' }, fontSize: 20 }} />
          </Stack>
        )}
      </>
    );
  };
  return <>{getConditionalStyle()}</>;
}
