// import { promisify } from '@Src/_basic/helpers/PromiseHelpers';
import { Response } from '@Src/_basic/object/ApiType';
import {
  BasicInfoData,
  ConcreteHeaderData,
  CreateFileParams,
  CreateProportionRequest,
  CreateSignatureRequest,
  FileData,
  PageableProjectsData,
  ProjectData,
  ProjectInfoData,
  ProportionData,
  ReplaceProportionRequest,
  SearchConcreteHeaderParams,
  SearchPageableProjectsParams,
  UpdateBasicInfoRequest,
} from '@Src/_basic/object/ProductionHistoryType';
import { SignatureData, UpdateSignatureRequest, UpdateTemplateRequest } from '@Src/_basic/object/SignatureType';
import { ProductionHistoryApi } from '@Src/_basic/protocol/productionHistory/ProductionHistoryApi';
import { SignatureApi } from '@Src/_basic/protocol/signature/SignatureApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { productionHistorySlice } from './productionHistorySlice';

export function useProductionHistory() {
  const { project, pageableProjects, projectInfo, concreteHeader } = useSelector(
    (state: RootState) => state.productionHistory,
  );
  const dispatch = useDispatch();

  const getProjectByOrderId = (orderId: string) => {
    ProductionHistoryApi.getProjectByOrderId(orderId, setProject);
  };

  const getPageableProjectsBySearch = (params: SearchPageableProjectsParams) => {
    ProductionHistoryApi.getPageableProjectsBySearch(params, setPageableProjects);
  };

  const getCancelablePageableProjectsBySearch = (params: SearchPageableProjectsParams) => {
    ProductionHistoryApi.getCancelablePageableProjectsBySearch(params, setPageableProjects);
  };

  const getProjectInfoById = (id: number) => {
    ProductionHistoryApi.getProjectInfoById(id, setProjectInfo);
  };

  const getProjectInfoByOrderId = (orderId: string) => {
    // 如要使用promise.all，請參考下方註解
    // Promise.all([promisify(ProductionHistoryApi.getProjectInfoByOrderId)(orderId)])
    //   .then((result: Array<any>) => {
    //     setProjectInfo(result[0]);
    //     console.log('所有請求完成', result);
    //   })
    //   .catch(() => {
    //     console.log('請求失敗');
    //   });
    ProductionHistoryApi.getProjectInfoByOrderId(orderId, setProjectInfo);
  };

  const updateTemplate = (params: UpdateTemplateRequest) => {
    SignatureApi.updateTemplate(params);
  };

  const createProjectInfoProfileByOrderId = (
    orderId: string,
    onSuccess?: (data: ProjectInfoData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) => {
    ProductionHistoryApi.createProjectInfoProfileByOrderId(
      orderId,
      (data: ProjectInfoData) => {
        setProjectInfo(data);
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const updateBasicInfo = (request: UpdateBasicInfoRequest) => {
    ProductionHistoryApi.updateBasicInfo(request, (data: BasicInfoData) => {
      setProjectInfo({ ...projectInfo, basicInfo: data });
    });
  };

  const createSignature = (request: CreateSignatureRequest, isTemplate: boolean) => {
    SignatureApi.createSignature(request, (data: SignatureData) => {
      setProjectInfo({ ...projectInfo, signature: data });
      if (isTemplate) setTimeout(() => updateTemplate({ signatureId: data.id }), 1000);
    });
  };

  const updateSignature = (request: UpdateSignatureRequest, cb: () => void, isTemplate: boolean) => {
    SignatureApi.updateSignature(request, (data: SignatureData) => {
      setProjectInfo({ ...projectInfo, signature: data });
      cb();
      if (isTemplate) updateTemplate({ signatureId: data.id });
    });
  };

  const createProportions = (
    requests: Array<CreateProportionRequest>,
    onSuccess?: (data: Array<ProportionData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    ProductionHistoryApi.createProportions(
      requests,
      (data: Array<ProportionData>) => {
        setProjectInfo({ ...projectInfo, proportion: data });
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const replaceProportions = (
    request: ReplaceProportionRequest,
    onSuccess?: (data: Array<ProportionData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    ProductionHistoryApi.replaceProportions(
      request,
      (data: Array<ProportionData>) => {
        setProjectInfo({ ...projectInfo, proportion: data });
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const uploadReports = (
    params: CreateFileParams,
    files: FileList,
    onSuccess?: (data: Array<FileData>) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    ProductionHistoryApi.uploadReports(
      params,
      files,
      (data: Array<FileData>) => {
        setProjectInfo({ ...projectInfo, reports: [...projectInfo.reports, ...data] });
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const deleteReport = (
    id: number,
    onSuccess?: (data: FileData) => void,
    onFailed?: () => void,
    onFinally?: () => void,
  ) => {
    ProductionHistoryApi.deleteReport(
      id,
      (data: FileData) => {
        setProjectInfo({
          ...projectInfo,
          reports: projectInfo.reports.filter((report) => report.id !== id),
        });
        onSuccess?.(data);
      },
      onFailed,
      onFinally,
    );
  };

  const getConcreteHeaderBySearch = (params: SearchConcreteHeaderParams) => {
    ProductionHistoryApi.getConcreteHeaderBySearch(params, setConcreteHeader);
  };

  const setProject = (data: ProjectData) => dispatch(productionHistorySlice.actions.setProject(data));

  const setPageableProjects = (data: PageableProjectsData) => {
    dispatch(productionHistorySlice.actions.setPageableProjects(data));
  };

  const setProjectInfo = (data: ProjectInfoData) => {
    dispatch(productionHistorySlice.actions.setProjectInfo(data));
  };

  const setConcreteHeader = (data: ConcreteHeaderData) => {
    dispatch(productionHistorySlice.actions.setConcreteHeader(data));
  };

  const clearConcreteHeader = () => {
    dispatch(productionHistorySlice.actions.clearConcreteHeader());
  };

  const cleanPageableProjects = () => {
    dispatch(productionHistorySlice.actions.setPageableProjects(undefined));
  };

  return {
    project,
    pageableProjects,
    projectInfo,
    concreteHeader,
    getProjectByOrderId,
    getPageableProjectsBySearch,
    getCancelablePageableProjectsBySearch,
    getProjectInfoById,
    getProjectInfoByOrderId,
    createProjectInfoProfileByOrderId,
    updateBasicInfo,
    createSignature,
    updateSignature,
    createProportions,
    replaceProportions,
    uploadReports,
    deleteReport,
    getConcreteHeaderBySearch,
    clearConcreteHeader,
    cleanPageableProjects,
    updateTemplate,
  };
}
