import { AfterGetUserGroupsData, UserData } from '@Basic/object/AuthType';
import { RootState } from '@Redux/store';
import { AreaOptions } from '@Src/_basic/object/AreaType';
import { useDispatch, useSelector } from 'react-redux';
import { authSlice } from './authSlice';

export function useAuth() {
  const { token, userData, userGroupsData, userAreaOptionsData, userApiPermission, equipmentFactory } = useSelector(
    (state: RootState) => state.auth,
  );

  const dispatch = useDispatch();

  const setToken = (_token: string | undefined) => dispatch(authSlice.actions.setToken(_token));

  const setUserData = (_userData: UserData | undefined) => dispatch(authSlice.actions.setUserData(_userData));

  const setUserGroupsData = (_userGroupsdata: AfterGetUserGroupsData) =>
    dispatch(authSlice.actions.setUserGroupsData(_userGroupsdata));

  const setUserAreaOptionsData = (_userAreasOptionsData: AreaOptions[]) =>
    dispatch(authSlice.actions.setUserAreaOptionsData(_userAreasOptionsData));

  const setUserApiPermission = (userApiPermission: Array<string>) =>
    dispatch(authSlice.actions.setUserApiPermission(userApiPermission));

  const setEquipmentFactory = (equipmentFactory: Array<any>) =>
    dispatch(authSlice.actions.setEquipmentFactory(equipmentFactory));

  return {
    token,
    setToken,
    userData,
    setUserData,
    userGroupsData,
    setUserGroupsData,
    userAreaOptionsData,
    setUserAreaOptionsData,
    userApiPermission,
    setUserApiPermission,
    equipmentFactory,
    setEquipmentFactory,
  };
}
