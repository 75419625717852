import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import MixerInformationCreateScreen from './screens/MixerInformationCreateScreen';
import MixerInformationScreen from './screens/MixerInformationScreen';
import MixerInformationUpdateScreen from './screens/MixerInformationUpdateScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <MixerInformationScreen />,
  },
  {
    path: '/MixerInformationCreate/*',
    element: <MixerInformationCreateScreen />,
  },
  {
    path: '/MixerInformationUpdate/:id',
    element: <MixerInformationUpdateScreen />,
  },
];

const MixerInformationRouter = () => PermissionRoute(routesConfig);

export default memo(MixerInformationRouter);
