import { Stack } from '@mui/material';
import React from 'react';

export default function Header() {
  return (
    <Stack alignItems="center" sx={{ py: 2, position: 'sticky', top: 0, bgcolor: 'inherit', zIndex: 1 }}>

    </Stack>
  );
}
