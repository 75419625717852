import React from 'react';
import { Typography } from '@mui/material';
import { YardReciptData } from '@Src/_basic/object/YardReceiptsType';

interface Props {
  row: YardReciptData;
}
export default function StorageNameColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.storageName}</Typography>;
}
