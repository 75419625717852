import AdapterDateFns from '@date-io/date-fns';
import { Grid, Stack, TextField, Typography, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import zhLocale from 'date-fns/locale/zh-TW'; // 引入中文語言包
import React from 'react';
interface Props {
  title: string;
  icon?: React.ReactNode;
  value: Date | null;
  onChange: (value: Date | null) => void;
  disableFuture?: boolean;
  disablePast?: boolean;
  maxDate?: any;
  minDate?: any;
  disabled?: boolean;
  label?: string;
  fullWidth?: boolean;
  error?: string;
}

const Label = styled(Typography)(({ theme }) => ({
  color: '#6C708C',
  fontSize: '16px',
  fontWeight: '900',
  fontFamily: 'Microsoft JhengHei',
}));

export default function ExptScheduleDateTimePicker({
  title,
  icon,
  value,
  onChange,
  fullWidth,
  error,
  ...props
}: Props) {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <Stack direction="row" alignItems="center" gap="8px">
          {icon && icon}
          <Label>{title}</Label>
        </Stack>
      </Grid>
      <Grid item xs>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={zhLocale}>
          <DatePicker
            inputFormat="yyyy/MM/dd"
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                sx={{
                  width: '100%',
                }}
              />
            )}
            {...props}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
