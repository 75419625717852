import axios, { AxiosError, AxiosResponse } from 'axios';
import { Response } from '@Src/_basic/object/ApiType';
import { NameProtocol } from './NameProtocol';
import { Api } from '../Api';
import {
  MaterialReceiptQRCodeDataResponse,
  MaterialReceiptsData,
  SearchSSSParams,
  SSSData,
  UpdateMaterialReceiptRequest,
} from '@Src/_basic/object/MaterialReceiptsType';
import { NameData, SearchNamesParams } from '@Src/_basic/object/Name';

export class NameApi {
  public static getAllNames(
    onSuccess?: (data: Array<NameData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(NameProtocol.ALL_NAMES, undefined, onSuccess, onFailed, onFinally);
  }
  public static getNamesBySearch(
    params: SearchNamesParams,
    onSuccess?: (data: Array<NameData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(NameProtocol.ALL_NAMES, config, onSuccess, onFailed, onFinally);
  }
}
