import React, { createContext, useState } from 'react';

interface Props {
  children: JSX.Element;
}

interface ProductionHistoryContext {
  selectedProportions: string[] | undefined;
  setSelectedProportions: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

export const ProductionHistoryContext = createContext<ProductionHistoryContext | null>(null);

export const ProductionHistoryProvider = ({ children }: Props) => {
  const [selectedProportions, setSelectedProportions] = useState<string[] | undefined>();

  return (
    <ProductionHistoryContext.Provider value={{ selectedProportions, setSelectedProportions }}>
      {children}
    </ProductionHistoryContext.Provider>
  );
};
