export default [
  {
    id: 1,
    organizeName: '董事長',
  },
  {
    id: 2,
    organizeName: '品質管理',
  },
];
