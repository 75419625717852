import { DropdownItem } from '@Src/_basic/components/YatungSelect';
import YatungWindow from '@Src/_basic/components/YatungWindow';
import { GroupBasicInfo } from '@Src/_basic/object/AuthType';
import { CreateScheduleItemRequest } from '@Src/_basic/object/ExptScheduleType';
import { TestItemData } from '@Src/_basic/object/TestItemType';
import { TestItemApi } from '@Src/_basic/protocol/testItem/TestItemApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { Box, Grid, Typography, styled } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Member from '../../../icons/Member';
import Triangle from '../../../icons/Triangle';
import ExptScheduleCard from '../../ExptScheduleCard';
import ExptScheduleCheckBoxWithSelect, { Option } from '../../ExptScheduleCheckBoxWithSelect';

interface Props {
  isRequirement: string;
  exptTypeId: number;
  specificationId: number | undefined;
  exptItems: Array<CreateScheduleItemRequest> | undefined;
  errors: any;
  setFieldValue: (field: string, value: any) => void;
}

const Title = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: '24px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
  letterSpacing: '0.28em',
}));

export default memo(function SelectExptCard(props: Props) {
  // TODO using i18n

  const { userGroupsData } = useAuth();
  const [exptItems, setExptItems] = useState<Array<TestItemData>>([]);

  const checkedValues = useMemo(() => {
    return props.exptItems?.map((item: any) => item.exptItemId) || [];
  }, [props.exptItems]);

  const operatorGroup: Array<Option> = useMemo(() => {
    return (
      userGroupsData?.supervisorAssignGroups.map((item: GroupBasicInfo<DropdownItem<string>>) => ({
        text: item.groupName,
        value: item.id,
      })) || []
    );
  }, [userGroupsData]);

  const getOperatorListOptions = useCallback(
    (groupId: number) => {
      const group = userGroupsData?.supervisorAssignGroups.find(
        (item: GroupBasicInfo<DropdownItem<string>>) => item.id == groupId,
      );

      return (
        group?.users.map((item: DropdownItem<string>) => ({
          text: item.text ?? '',
          value: item.value,
        })) || []
      );
    },
    [userGroupsData],
  );

  const getSelectedGroup = useCallback(
    (exptItemId: number) => {
      return props.exptItems?.find((item: any) => item.exptItemId === exptItemId)?.groupId;
    },
    [props.exptItems],
  );

  const getSelectedOperator = useCallback(
    (exptItemId: number) => {
      return props.exptItems?.find((item: any) => item.exptItemId === exptItemId)?.operator;
    },
    [props.exptItems],
  );

  const getSelectError = useCallback(
    (exptItemId: number) => {
      const operator = getSelectedOperator(exptItemId);

      if (operator === undefined) {
        return props.errors.exptItems ? true : false;
      }
    },
    [props.errors.exptItems, getSelectedOperator],
  );

  const handleCheckBoxChange = useCallback(
    (value: any) => {
      if (checkedValues.includes(value)) {
        props.setFieldValue(
          'exptItems',
          props.exptItems?.filter((item: any) => item.exptItemId !== value),
        );
      } else {
        if (props.exptItems) {
          props.setFieldValue('exptItems', [
            ...props.exptItems,
            {
              exptItemId: value,
              groupId: undefined,
              operator: undefined,
            },
          ]);
        }
      }
    },
    [checkedValues, props.exptItems],
  );

  const handleGroupChange = useCallback(
    (key: any, value: any) => {
      const exptItems = props.exptItems ? [...props.exptItems] : [];
      const exptItem = exptItems && exptItems.find((item: CreateScheduleItemRequest) => item.exptItemId === key);

      if (exptItem) {
        exptItem.groupId = value;
      }

      props.setFieldValue('exptItems', exptItems);
    },
    [props.exptItems],
  );

  const handleOperatorChange = useCallback(
    (key: any, value: any) => {
      const exptItems = props.exptItems ? [...props.exptItems] : [];
      const exptItem = exptItems && exptItems.find((item: CreateScheduleItemRequest) => item.exptItemId === key);
      if (exptItem) {
        exptItem.operator = value;
      }

      props.setFieldValue('exptItems', exptItems);
    },
    [props.exptItems],
  );

  const getExptItems = useCallback(() => {
    if (props.exptTypeId > 0) {
      if (props.isRequirement === 'true') {
        TestItemApi.getDemandOrderItemsBySearch(
          {
            exptTypeId: props.exptTypeId,
            specificationId: props.specificationId,
          },
          (data: Array<TestItemData>) => setExptItems(data),
        );
      } else {
        TestItemApi.getTestOrderItemsBySearch(
          {
            exptTypeId: props.exptTypeId,
            specificationId: props.specificationId,
          },
          (data: Array<TestItemData>) => setExptItems(data),
        );
      }
    }
  }, [props.isRequirement, props.exptTypeId, props.specificationId]);

  const clearExptItem = useCallback(() => {
    const exptItemIds = exptItems.map((item) => item.id);
    const newExptItems = props.exptItems?.filter((item: any) => exptItemIds.includes(item.exptItemId));

    props.setFieldValue('exptItems', newExptItems);
  }, [props.exptItems, exptItems]);

  const header = useMemo(() => {
    return (
      <Grid container direction="row" alignItems="center" sx={{ height: '100%', px: '24px' }}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }} gap="12px">
            <Triangle />
            <Title>試驗名稱</Title>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }} gap="12px">
            <Member />
            <Title>指派人員</Title>
          </Box>
        </Grid>
      </Grid>
    );
  }, []);

  useEffect(() => {
    getExptItems();
  }, [getExptItems]);

  useEffect(() => {
    if (exptItems && exptItems.length) {
      clearExptItem();
    }
  }, [exptItems]);

  return (
    <ExptScheduleCard
      header={header}
      body={
        <Box sx={{ height: '100%', padding: '24px 24px' }}>
          <YatungWindow
            className="scroll-bar"
            data={exptItems}
            renderItem={(item: TestItemData) => {
              return (
                <ExptScheduleCheckBoxWithSelect
                  text={item.itemName}
                  value={item.id}
                  checkedValues={checkedValues}
                  onChange={handleCheckBoxChange}
                  options={props.isRequirement === 'true' ? operatorGroup : undefined}
                  selectedValue={getSelectedGroup(item.id)}
                  onSelected={(value: any) => {
                    handleGroupChange(item.id, value);
                  }}
                  options2={getOperatorListOptions(getSelectedGroup(item.id) || 0)}
                  selectedValue2={getSelectedOperator(item.id)}
                  onSelected2={(value: any) => {
                    handleOperatorChange(item.id, value);
                  }}
                  error={getSelectError(item.id)}
                />
              );
            }}
          />
        </Box>
      }
    />
  );
});
