import YatungDynamicSelect from '@Src/_basic/components/YatungDynamicSelect';
import { Options } from '@Src/_basic/components/YatungSelect';
import React from 'react';

interface Props {
  proportionOptions: Options[];
  dymanicOptions: Options[];
  onSelected: (value: number) => void;
  selectedValue: number;
}
export default function MixerAndProportionInformationBindSelection(props: Props) {
  const { onSelected, selectedValue, proportionOptions, dymanicOptions } = props;

  const handleSelectOption = (e: any) => {
    onSelected?.(e.target.value);
  };
  return (
    <YatungDynamicSelect
      sx={{ width: '125px' }}
      options={dymanicOptions}
      value={selectedValue}
      onChange={handleSelectOption}
      totalOptions={proportionOptions}
    />
  );
}
