import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isLeftMenuOpen: boolean;
  currentUrl: string;
}

export const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState: {
    isLeftMenuOpen: true,
    currentUrl: '',
  } as InitialState,
  reducers: {
    switchLeftMenuOpen: (state) => {
      state.isLeftMenuOpen = !state.isLeftMenuOpen;
      console.log('switch menu to', state.isLeftMenuOpen);
    },
    setCurrentUrl: (state, action: PayloadAction<string>) => {
      state.currentUrl = action.payload;
    },
  },
});
