import React from 'react';
import '../assets/styles/App.css';

interface Props {
  percent: number;
}
export default function YatungProgressBar(props: Props) {
  const { percent } = props;
  const bar = {
    width: `${percent}%`,
  };
  return (
    <div className="skill-box">
      <div className="skill-bar">
        <span className="skill-per progress" style={bar}>
          <span className="tooltip">{percent}%</span>
        </span>
      </div>
    </div>
  );
}
