import React, { forwardRef } from 'react';
import ChemicalIcon from '@Src/_basic/icons/Chemical';

const ChemicalRibbon = forwardRef<HTMLDivElement, any>((props, ref) => {
  return (
    <div ref={ref} className="ribbon chemical-ribbon">
      <div
        style={{
          position: 'absolute',
          top: '8px',
          left: '13px',
        }}
      >
        <ChemicalIcon />
      </div>
    </div>
  );
});

ChemicalRibbon.displayName = 'ChemicalRibbon';

export default ChemicalRibbon;
