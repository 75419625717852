import { Response } from '@Src/_basic/object/ApiType';
import { Api } from '../../Api';
import { ReportGroupProtocol } from './ReportGroupProtocol';
import {
  ReportGroupsData,
  CreateReportGroupsRequest,
  UpdateReportGroupsRequest,
  ReportGroupsBySearchParams,
  PageableReportGroupsBySearchParams,
  PageableReportGroupsData,
} from '@Src/_basic/object/AccountRightType';

export class ReportGroupApi {
  // 取得回報群組清單
  public static getReportGroupsBySearch(
    params: ReportGroupsBySearchParams,
    onSuccess?: (data: Array<ReportGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ReportGroupProtocol.GET_REPORT_GROUPS, config, onSuccess, onFailed, onFinally);
  }

  // 取得分頁回報群組清單
  public static getPageableReportGroupsBySearch(
    params: PageableReportGroupsBySearchParams,
    onSuccess?: (data: PageableReportGroupsData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(ReportGroupProtocol.GET_PAGEABLE_REPORT_GROUPS, config, onSuccess, onFailed, onFinally);
  }

  // 以ID取得回報群組
  public static getReportGroupById(
    id: number,
    onSuccess?: (data: Array<ReportGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id: id,
      },
    };
    Api.get(ReportGroupProtocol.REPORT_GROUP_URL, config, onSuccess, onFailed, onFinally);
  }

  // 新增回報群組
  public static postCreateReportGroup(
    request: CreateReportGroupsRequest,
    onSuccess?: (data: Array<ReportGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(ReportGroupProtocol.REPORT_GROUP_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改回報群組
  public static putUpdateReportGroup(
    request: UpdateReportGroupsRequest,
    onSuccess?: (data: Array<ReportGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(ReportGroupProtocol.REPORT_GROUP_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 刪除回報群組
  public static deleteReportGroup(
    id: number,
    onSuccess?: (data: Array<ReportGroupsData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id: id,
      },
    };
    Api.delete(ReportGroupProtocol.REPORT_GROUP_URL, config, onSuccess, onFailed, onFinally);
  }
}
