import axios, { AxiosError, AxiosResponse } from 'axios';
import { Response } from '@Src/_basic/object/ApiType';
import { MaterialCodeRequest, MaterialCodeData } from '@Src/_basic/object/MaterialCodeType';
import { MaterialCodeProtocol } from './MaterialCodeProtocol';
import { Api } from '../Api';

export class MaterialCodeApi {
  // 取得所有入料類型
  public static getAllMaterialCode(
    onSuccess?: (data: Array<MaterialCodeData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(MaterialCodeProtocol.ALL_MATERIAL_TYPE, undefined, onSuccess, onFailed, onFinally);
  }

  // 修改入料類型
  public static putMaterialCode(
    request: MaterialCodeRequest,
    onSuccess?: (data: MaterialCodeData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(MaterialCodeProtocol.MATERIAL_TYPE_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 新增入料類型
  public static postMaterialCode(
    request: MaterialCodeRequest,
    onSuccess?: (data: MaterialCodeData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(MaterialCodeProtocol.MATERIAL_TYPE_URL, request, undefined, onSuccess, onFailed, onFinally);
  }
}
