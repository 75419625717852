import React from 'react';

import YatungDynamicSelect from '@Src/_basic/components/YatungDynamicSelect';
import { Options } from '@Src/_basic/components/YatungSelect';

interface Props {
  onSelected: (value: string) => void;
  selectedValue: number;
  proportionOptions: Options[];
  dymanicOptions: Options[];
}
export default function CreateMixerDetailsMixerBindSelect(props: Props) {
  const { onSelected, selectedValue, proportionOptions, dymanicOptions } = props;

  const handleSelectOption = (e: any) => {
    onSelected?.(e.target.value);
  };

  return (
    <YatungDynamicSelect
      sx={{ width: '125px' }}
      options={dymanicOptions}
      value={selectedValue}
      onChange={handleSelectOption}
      totalOptions={proportionOptions}
    />
  );
}
