import { MonthlyReportConcreteAntiStress, MonthlyReportNameEnum } from '@Src/_basic/object/MonthlyReportType';
import { MonthlyReportApi } from '@Src/_basic/protocol/monthlyReport/MonthlyReportApi';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MonthlyReportSelect from '../components/MonthlyReportSelect';

const ConcreteAntiStress = () => {
  const { t: i18T } = useTranslation();
  const [tableData, setTableData] = useState<Array<MonthlyReportConcreteAntiStress>>([]);

  const selectHandler = useCallback((factoryId: number, exptUnitId: number, startTime: number, endTime: number) => {
    if (!factoryId || !exptUnitId || !startTime || !endTime) return;

    MonthlyReportApi.getConcreteAntiStress(
      {
        factoryId,
        exptUnitId,
        startTime,
        endTime,
      },
      (_data) => setTableData(_data),
    );
  }, []);

  return (
    <MonthlyReportSelect
      title={i18T('REPORT.MONTHLY_REPORT.CONCRETE_ANTI_STRESS.TITLE')}
      exptType={MonthlyReportNameEnum.CONCRETE_ANTI_STRESS}
      selectHandler={selectHandler}
      tableData={tableData}
    />
  );
};
export default memo(ConcreteAntiStress);
