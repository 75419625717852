import React from 'react';
import { Typography } from '@mui/material';
import { EquipmentData } from '@Src/_basic/object/EquipmentManagementType';

interface Props {
  row: EquipmentData;
}

export default function FactoryColumnFormatter(props: Props) {
  const { row } = props;

  return <Typography variant="inherit">{row.factoryName}</Typography>;
}
