import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Vehicle(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="21" height="21" viewBox="0 0 21 21" fill="none">
      <rect x="0.5" y="0.5" width="20" height="20" rx="10" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3825 6.27739H4.17498C3.60764 6.27739 3.14773 6.755 3.14773 7.34416V13.6558C3.14773 14.245 3.60764 14.7226 4.17498 14.7226H17.3825C17.9499 14.7226 18.4098 14.245 18.4098 13.6558V7.34416C18.4098 6.755 17.9499 6.27739 17.3825 6.27739ZM4.17498 6.125C3.5266 6.125 3.00098 6.67084 3.00098 7.34416V13.6558C3.00098 14.3292 3.5266 14.875 4.17498 14.875H17.3825C18.0309 14.875 18.5565 14.3292 18.5565 13.6558V7.34416C18.5565 6.67084 18.0309 6.125 17.3825 6.125H4.17498Z"
        fill="#00D03A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8072 6.86222H4.74895C4.18233 6.86222 3.72298 7.34591 3.72298 7.94257V13.0565C3.72298 13.6532 4.18233 14.1369 4.74896 14.1369H16.8072C17.3738 14.1369 17.8331 13.6532 17.8331 13.0565V7.94257C17.8331 7.34591 17.3738 6.86222 16.8072 6.86222ZM4.74895 6.70789C4.10138 6.70789 3.57642 7.26067 3.57642 7.94257V13.0565C3.57642 13.7384 4.10138 14.2912 4.74896 14.2912H16.8072C17.4547 14.2912 17.9797 13.7384 17.9797 13.0565V7.94257C17.9797 7.26067 17.4547 6.70789 16.8072 6.70789H4.74895Z"
        fill="#00D03A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.01972 8.74852C5.33732 8.74852 5.59479 8.48784 5.59479 8.16626C5.59479 7.84468 5.33732 7.584 5.01972 7.584C4.70211 7.584 4.44464 7.84468 4.44464 8.16626C4.44464 8.48784 4.70211 8.74852 5.01972 8.74852ZM5.01972 9.04126C5.497 9.04126 5.88391 8.64951 5.88391 8.16626C5.88391 7.68301 5.497 7.29126 5.01972 7.29126C4.54243 7.29126 4.15552 7.68301 4.15552 8.16626C4.15552 8.64951 4.54243 9.04126 5.01972 9.04126Z"
        fill="#00D03A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8855 9.02633C5.92516 9.03581 5.96643 9.04081 6.00881 9.04081H7.05941C7.36564 9.04081 7.61389 8.77964 7.61389 8.45748C7.61389 8.13531 7.36564 7.87415 7.05941 7.87415H6.00881C5.98064 7.87415 5.95296 7.87636 5.92591 7.88062C6.03304 8.03343 6.09636 8.22233 6.09636 8.42678C6.09636 8.65575 6.01694 8.86521 5.8855 9.02633Z"
        fill="#00D03A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5425 8.74852C16.2249 8.74852 15.9675 8.48784 15.9675 8.16626C15.9675 7.84468 16.2249 7.584 16.5425 7.584C16.8601 7.584 17.1176 7.84468 17.1176 8.16626C17.1176 8.48784 16.8601 8.74852 16.5425 8.74852ZM16.5425 9.04126C16.0653 9.04126 15.6783 8.64951 15.6783 8.16626C15.6783 7.68301 16.0653 7.29126 16.5425 7.29126C17.0198 7.29126 17.4067 7.68301 17.4067 8.16626C17.4067 8.64951 17.0198 9.04126 16.5425 9.04126Z"
        fill="#00D03A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6768 9.02633C15.6371 9.03581 15.5958 9.04081 15.5534 9.04081H14.5028C14.1966 9.04081 13.9484 8.77964 13.9484 8.45748C13.9484 8.13531 14.1966 7.87415 14.5028 7.87415H15.5534C15.5816 7.87415 15.6093 7.87636 15.6363 7.88062C15.5292 8.03343 15.4659 8.22233 15.4659 8.42678C15.4659 8.65575 15.5453 8.86521 15.6768 9.02633Z"
        fill="#00D03A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.01972 13.4148C5.33732 13.4148 5.59479 13.1541 5.59479 12.8325C5.59479 12.5109 5.33732 12.2503 5.01972 12.2503C4.70211 12.2503 4.44464 12.5109 4.44464 12.8325C4.44464 13.1541 4.70211 13.4148 5.01972 13.4148ZM5.01972 13.7075C5.497 13.7075 5.88391 13.3158 5.88391 12.8325C5.88391 12.3493 5.497 11.9575 5.01972 11.9575C4.54243 11.9575 4.15552 12.3493 4.15552 12.8325C4.15552 13.3158 4.54243 13.7075 5.01972 13.7075Z"
        fill="#00D03A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8855 13.1097C5.92516 13.1192 5.96643 13.1242 6.00881 13.1242H7.05941C7.36564 13.1242 7.61389 12.863 7.61389 12.5409C7.61389 12.2187 7.36564 11.9575 7.05941 11.9575H6.00881C5.98064 11.9575 5.95296 11.9597 5.92591 11.964C6.03304 12.1168 6.09636 12.3057 6.09636 12.5102C6.09636 12.7391 6.01694 12.9486 5.8855 13.1097Z"
        fill="#00D03A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5425 13.4148C16.2249 13.4148 15.9675 13.1541 15.9675 12.8325C15.9675 12.5109 16.2249 12.2503 16.5425 12.2503C16.8601 12.2503 17.1176 12.5109 17.1176 12.8325C17.1176 13.1541 16.8601 13.4148 16.5425 13.4148ZM16.5425 13.7075C16.0653 13.7075 15.6783 13.3158 15.6783 12.8325C15.6783 12.3493 16.0653 11.9575 16.5425 11.9575C17.0198 11.9575 17.4067 12.3493 17.4067 12.8325C17.4067 13.3158 17.0198 13.7075 16.5425 13.7075Z"
        fill="#00D03A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6768 13.1097C15.6371 13.1192 15.5958 13.1242 15.5534 13.1242H14.5028C14.1966 13.1242 13.9484 12.863 13.9484 12.5409C13.9484 12.2187 14.1966 11.9575 14.5028 11.9575H15.5534C15.5816 11.9575 15.6093 11.9597 15.6363 11.964C15.5292 12.1168 15.4659 12.3057 15.4659 12.5102C15.4659 12.7391 15.5453 12.9486 15.6768 13.1097Z"
        fill="#00D03A"
      />
    </SvgIcon>
  );
}
