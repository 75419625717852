import YatungPage from '@Src/_basic/components/YatungPage';
import { GroupManagementFormRequire, UsersData } from '@Src/_basic/object/AccountRightType';
import { Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import { AssignGroupApi } from '@Src/_basic/protocol/accountRights/assignGroup/AssignGroupApi';
import { UsersApi } from '@Src/_basic/protocol/accountRights/users/UsersApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useLocation, useNavigate } from 'react-router';
import EditAccountField from '../../../_components/EditAccountField';
import EditAccountLabel from '../../../_components/EditAccountLabel';
import FinishCancelButton from '../../../_components/FinishCancelButton';
import SearchTable from './SearchTable';

export const editFormSchema = Yup.object().shape({
  area: Yup.number().min(0).positive('必填'),
  factoryId: Yup.number().min(0).positive('必填'),
  groupName: Yup.string().required('必填'),
  groupCode: Yup.string().required('必填'),
});

export default function AssignGroupManagementCreateCard() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { factoryValue } = state;
  const { userAreaOptionsData } = useAuth();

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [searchTableOpen, setSearchTableOpen] = useState(false);
  const [memberData, setMemberData] = useState<Array<UsersData>>([]);
  const [factoryId, setFactoryId] = useState<number>(factoryValue);

  const initialForm: GroupManagementFormRequire = {
    area:
      userAreaOptionsData.find((item: any) => item.factories.find((_item: any) => _item.value === factoryValue))
        ?.value ?? 0,
    factoryId: factoryValue,
    groupName: '',
    groupCode: '',
  };

  const handleSubmit = (values: any) => {
    AssignGroupApi.postCreateAssignGroup(
      { ...values, userIds: memberData.filter((item) => item.added === true).map((item) => item.id) },
      () => {
        setIsShowAlert(true);
        setAlertText(i18T('GLOBAL.SUCCESS', { text: i18T('GLOBAL.CREATE') }));
      },
      (error) => {
        setIsShowAlert(true);
        setAlertText(error.message);
      },
    );
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    UsersApi.getUsersOnlyAIV(factoryId, (_data: Array<UsersData>) => {
      setMemberData(_data.map((item) => ({ ...item, added: false, auditor: false })));
    });
  }, [factoryId]);

  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.CREATE_ASSIGN_GROUP')}
      contentBgColor="#FFF"
      body={
        <>
          <Formik
            initialValues={initialForm}
            validationSchema={editFormSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formik: any) => {
              useMemo(() => {
                setFactoryId(formik.values.factoryId);
              }, [formik.values.factoryId]);
              useMemo(() => {
                formik.values.factoryId = 0;
              }, [formik.values.area]);
              return (
                <Form>
                  <Stack display="flex" flexDirection="row">
                    <Stack width="45%" sx={{ p: 5 }}>
                      <Stack direction="row" alignItems="center" sx={{ mt: 5 }} spacing={2.7}>
                        <EditAccountLabel
                          label={`${i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.AREA')}:`}
                          isStar={true}
                        />
                        <EditAccountField target="area" options={userAreaOptionsData} />
                      </Stack>
                      <Stack direction="row" alignItems="center" sx={{ mt: 5 }} spacing={2.7}>
                        <EditAccountLabel
                          label={`${i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.FACTORY_INFO')}:`}
                          isStar={true}
                        />
                        <EditAccountField
                          target="factoryId"
                          options={
                            formik.values.area !== 0
                              ? userAreaOptionsData.find((i: any) => i.value === formik.values.area)?.factories
                              : [{ value: 0, text: '請選擇 - - -' }]
                          }
                        />
                      </Stack>
                      <Stack direction="row" alignItems="center" sx={{ mt: 5 }} spacing={3}>
                        <EditAccountLabel
                          label={`${i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.ASSIGN_NAME')}:`}
                          isStar={true}
                        />
                        <EditAccountField target="groupName" />
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ py: 5, borderBottom: 1, borderBottomStyle: 'dashed' }}
                        spacing={3}
                      >
                        <EditAccountLabel
                          label={`${i18T('USERSMANAGEMENU.ASSIGN_GROUP_MANAGEMENT.ASSIGN_CODE')}:`}
                          isStar={true}
                        />
                        <EditAccountField target="groupCode" />
                      </Stack>
                    </Stack>
                    <SearchTable
                      setSearchTableOpen={setSearchTableOpen}
                      searchTableOpen={searchTableOpen}
                      memberData={memberData}
                      setMemberData={setMemberData}
                    />
                  </Stack>
                  <Stack spacing={2} direction="row" justifyContent="flex-end" sx={{ mr: 15 }}>
                    <FinishCancelButton
                      onFinish={formik.handleSubmit}
                      onCancel={handleGoBack}
                      sx={{ py: 1.5, borderRadius: 0 }}
                    />
                  </Stack>
                </Form>
              );
            }}
          </Formik>
          {alertText === i18T('GLOBAL.SUCCESS', { text: i18T('GLOBAL.CREATE') }) ? (
            <YatungConfirmAlert
              isShowAlert={isShowAlert}
              onClose={() => {
                setIsShowAlert(false);
                setTimeout(() => setAlertText(''), 300);
                navigate(`/UsersManagePage/AssignGroupManagement`);
              }}
              alertText={alertText}
              buttonTitle={i18T('GLOBAL.BACK')}
            />
          ) : (
            <YatungConfirmAlert
              isShowAlert={isShowAlert}
              onClose={() => {
                setIsShowAlert(false);
                setTimeout(() => setAlertText(''), 300);
              }}
              alertText={alertText}
            />
          )}
        </>
      }
    />
  );
}
