import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useCallback, useEffect, useState } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungImg from '@Src/_basic/components/YatungImg';
import YatungTable from '@Src/_basic/components/YatungTable';

import AddCircleFill from '@Src/_basic/icons/AddCircleFill';
import EditIcon from '@Src/_basic/icons/Edit';

import { customSort } from '@Src/_basic/helpers/SortHelper';
import { PostMaterialNumberRequest, PutMaterialNumberRequest } from '@Src/_basic/object/MaterialNumberManageType';
import { SupplierData } from '@Src/_basic/object/SupplierType';
import { TypeData } from '@Src/_basic/object/TypeType';
import { SupplierApi } from '@Src/_basic/protocol/supplier/SupplierApi';
import { useTranslation } from 'react-i18next';
import AddModal from './AddModal';
import EditModal, { SelectedItemType } from './EditModal';

const supplierIcon = '/media/images/icon_supplier.png';

interface Props {
  filterType: number;
  types: TypeData[];
}

const ModalIcon = () => (
  <YatungImg
    img={supplierIcon}
    sx={{
      width: 48,
      height: 48,
      mr: 1,
    }}
  />
);

export default function SuppliersPanel({ filterType, types }: Props) {
  const { t: i18T } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<Array<SelectedItemType>>([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState<Array<SelectedItemType>>([]);

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [selectedSupplier, setSelectedSupplier] = useState<SelectedItemType>();

  const fetchAllSuppliers = () => {
    SupplierApi.getAllSuppliers((allSuppliers: Array<SupplierData>) => {
      const data = allSuppliers
        .map((item: SupplierData) => ({ ...item, typeId: item.type.id }))
        .sort((a, b) => customSort(a.code, b.code));
      setSuppliers(data);
      setLoading(false);
    });
  };

  const filterSuppliers = (_suppliers: Array<SelectedItemType>) => {
    setFilteredSuppliers(
      _suppliers.filter((supplier: SelectedItemType) => filterType === -1 || supplier.typeId === filterType),
    );
  };

  const handleSupplierSelect = (data: SelectedItemType) => {
    if (data) {
      setSelectedSupplier(data);
      setIsEditModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setLoading(false);
  };

  const editSupplierHandler = useCallback((data: PutMaterialNumberRequest) => {
    if (data) {
      setLoading(true);
      SupplierApi.putSupplier(data, () => {
        fetchAllSuppliers();
        closeModal();
      });
    }
  }, []);

  const addSupplierHandler = useCallback((data: PostMaterialNumberRequest) => {
    if (data) {
      setLoading(true);
      SupplierApi.postSupplier(data, () => {
        fetchAllSuppliers();
        closeModal();
      });
    }
  }, []);

  useEffect(() => {
    filterSuppliers(suppliers);
  }, [filterType, suppliers]);

  useEffect(() => {
    setLoading(true);
    fetchAllSuppliers();
  }, []);

  return (
    <Box sx={{ border: '1px solid #6C708C', p: 1, pt: 2 }}>
      <Stack justifyContent={'flex-end'} flexDirection="row">
        <YatungButton
          text={i18T('GLOBAL.CREATE')}
          startIcon={<AddCircleFill />}
          onClick={() => setIsAddModalOpen(true)}
          color="green"
        />
      </Stack>
      <YatungTable
        loading={loading}
        data={filteredSuppliers}
        sx={{
          maxHeight: 'calc(100vh - 340px)',
        }}
        columns={[
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.TYPE'),
            dataField: 'type',
            formatter: ({ cellContent }) => cellContent.name,
            width: '120px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.CODE'),
            dataField: 'code',
            width: '120px',
          },
          {
            text: i18T('APPLICATIONSMANAGEMENT.TABLE.NAME'),
            dataField: 'name',
          },
          {
            text: i18T('GLOBAL.ACTION'),
            dataField: 'action',
            width: '180px',
            formatter: ({ row }) => {
              return (
                <YatungButton
                  text={i18T('GLOBAL.UPDATE')}
                  startIcon={<EditIcon />}
                  onClick={() => {
                    handleSupplierSelect(row);
                  }}
                  color="yellow"
                />
              );
            },
          },
        ]}
      />
      <AddModal
        open={isAddModalOpen}
        handleSubmit={addSupplierHandler}
        handleClose={closeModal}
        allTypes={types}
        allItems={suppliers.map(({ code, name, typeId }: any) => ({ code, name, typeId }))}
        icon={<ModalIcon />}
        title={i18T('APPLICATIONSMANAGEMENT.TABLE.SUPPLIER_NAME')}
        storage={true}
      />
      {selectedSupplier && (
        <EditModal
          open={isEditModalOpen}
          handleSubmit={editSupplierHandler}
          handleClose={closeModal}
          allTypes={types}
          allItems={suppliers.map(({ code, name, typeId }: any) => ({ code, name, typeId }))}
          icon={<ModalIcon />}
          title={i18T('APPLICATIONSMANAGEMENT.TABLE.SUPPLIER_NAME')}
          selectedItem={selectedSupplier}
        />
      )}
    </Box>
  );
}
