import YatungCard from '@Src/_basic/components/YatungPage';
import { AreaData } from '@Src/_basic/object/AreaType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AreaManagementBar from './AreaManagementSearchBar';
import AreaManagementTable from './AreaManagementTable';

const background = '/media/images/background.png';

interface Props {
  loading: boolean;
  areaData: AreaData[];
  onOpenUpdateModal: (id: number) => void;
  onOpenCreateModal: () => void;
}
export default function AreaManagementCard(props: Props) {
  const { t: i18T } = useTranslation();
  const { areaData, onOpenUpdateModal, onOpenCreateModal, loading } = props;

  return (
    <>
      <YatungCard
        title={i18T('AREAMANAGEMENT.PAGE_TITLE')}
        backgroundImage={background}
        body={
          <>
            <AreaManagementBar onOpenCreateModal={onOpenCreateModal} />
            <AreaManagementTable
              areaData={areaData}
              onOpenUpdateModal={(id: number) => onOpenUpdateModal(id)}
              loading={loading}
            />
          </>
        }
      />
    </>
  );
}
