import React from 'react';
import { Grid, Typography } from '@mui/material';

interface Props {
  row: any;
}
export default function DiffColumnFormatter(props: Props) {
  const { row } = props;
  return (
    <>
      <Typography variant="inherit">{row.diff ? row.diff : 0}</Typography>
    </>
  );
}
