import React from 'react';
import { Field } from 'formik';
import YatungImg from '@Src/_basic/components/YatungImg';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import YatungButton from '@Src/_basic/components/YatungButton';
import AreaManagementCreateDulpicateMessage from './AreaManagementCreateDulpicateMessage';

const areaNameImg = '/media/images/areaName.png';
const areaSortImg = '/media/images/sort.png';
interface Props {
  dulpicateWarningMoalOpen: boolean;
  onDulpicateWarningModalClose: () => void;
}
export default function AreaManagementCreateField(props: Props) {
  const { t: i18T } = useTranslation();
  const { dulpicateWarningMoalOpen, onDulpicateWarningModalClose } = props;
  return (
    <>
      <AreaManagementCreateDulpicateMessage
        dulpicateWarningMoalOpen={dulpicateWarningMoalOpen}
        onDulpicateWarningModalClose={onDulpicateWarningModalClose}
      />
      <Grid container justifyContent={'center'} item xs={12} sx={{ my: 2 }}>
        <Grid container justifyContent={'center'} alignItems={'center'} display={'flex'} item xs={10}>
          <Field
            id="name"
            name="name"
            type="text"
            label=""
            component={YatungFormInput}
            sx={{ width: '90%' }}
            position={'startEnd'}
            icon={<YatungImg img={areaNameImg} />}
            text={i18T('AREAMANAGEMENT.AREA_NAME')}
            endTextColor={'#3E9B2F'}
            disabledText={i18T('GLOBAL.RUNNING')}
            required
          />
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} display={'flex'} item xs={2}>
          <YatungButton
            text={i18T('FACTORYMANAGEMENT.ACTION_CLOSE')}
            variant="contained"
            color="red"
            type={'button'}
            size={'large'}
            sx={{
              fontSize: '20px',
              minWidth: '10%',
              '&.Mui-disabled': {
                bgcolor: '#d32f2f',
                color: '#ffffff',
                opacity: 0.6,
              },
            }}
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'} item xs={12} sx={{ my: 2 }}>
        <Grid container justifyContent={'center'} alignItems={'center'} display={'flex'} item xs={10}>
          <Field
            id="sortingOrder"
            name="sortingOrder"
            type="number"
            label=""
            component={YatungFormInput}
            sx={{ width: '90%' }}
            position={'start'}
            icon={<YatungImg img={areaSortImg} />}
            text={i18T('AREAMANAGEMENT.AREA_SORT')}
            required
          />
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} display={'flex'} item xs={2}></Grid>
      </Grid>
    </>
  );
}
