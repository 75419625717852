import { MaterialInspectionDetialByData } from '@Src/_basic/object/MaterialInspectionType';
import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  row: MaterialInspectionDetialByData;
}
export default function OperatorNameColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.operatorName}</Typography>;
}
