/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

const designedStrengths = [135, 150, 210];
const slumps = [18, 19];
const particleSizes = [13, 15];

const tags = [
  '回填',
  '潤管',
  '砂漿',
  '一般',
  '劣質',
  'PC',
  '導溝',
  '鋪面',
  '高流動',
  '緩凝劑',
  '低磅數',
  '結構',
  '高坍度',
  '高流坍',
  'SCC',
  '卜特蘭一型水泥',
  '卜特蘭二型水泥',
  '自充填',
  'I型低鹼水泥',
  'II型低鹼水泥',
  '清水模',
  '早強微收縮',
  '早強',
  '鑽心料',
];

let tagsData = [];

export const getDesignedStrengthItems = () => designedStrengths.map((ds) => ({ text: `${ds}`, value: ds }));
export const getSlumpItems = () => slumps.map((slump) => ({ text: `${slump}`, value: slump }));
export const getParticleSizeItems = () => particleSizes.map((ps) => ({ text: `${ps}`, value: ps }));
export const getTagItems = () => tags.map((tag, i) => ({ text: tag, value: i }));

export interface Formula {
  designedStrength: number; // 設計強度
  designedMixingWater: number; // 拌合水設計用量
  waterBinderRatio: number; // 水膠比
  recycledWaterRatio: number; // 回收水比例
  airContent: number; // 空氣含量 (%)
  coarseAggregateCompactedVolumeRatio: number; // 粗粒料夯實體積比率
  stone1Percentage: number; // 石 1 (%)
  stone2Percentage: number; // 石 2 (%)
  stone3Percentage: number; // 石 3 (%)
  stone4Percentage: number; // 石 4 (%)
  sand1Percentage: number; // 砂 1 (%)
  sand2Percentage: number; // 砂 2 (%)
  sand3Percentage: number; // 砂 3 (%)
  sand4Percentage: number; // 砂 4 (%)
  cementPercentage: number; // 水泥(%)
  slagPercentage: number; // 爐石(%)
  flyAshPercentage: number; // 飛灰(%)
  microSilicaPercentage: number; // 矽灰(%)
  drug1AdditionRate: number;
  drug2AdditionRate: number;
  drug3AdditionRate: number;
  drug4AdditionRate: number;
  drug5AdditionRate: number;
  drug6AdditionRate: number;
  drugAdditionRate: number[];
  versionId: string;
  formulaId: string;
  version: number; //配比版本
  versions: number[];
  name: string;
  area: {
    id: 1;
    name: string;
  };
  factory: {
    factoryId: number;
    factoryName: string;
    factoryCode: string;
    branchCode: string;
  };
  slumpingStep: number;
  particleSize: number;
  tags: number[];
  deliveredSites: number; // 年出貨工地數
  latestUsedAt: number; // 最近出貨日期
  deliveredVolume: number; // 年出貨方數
  rejectedVolume: number; // 年退轉方數
  rejectedRate: number; // 年退轉率
  maxStrenth: number; // 年最高強度
  minStrenth: number; // 年最低強度
  averageStrength: number; // 年平均強度
  maxCost: number; // 年最高成本
  minCost: number; // 年最低成本
  averageCost: number; // 年平均成本
  strengthCost: number; // kg強度成本
  totalStoneSandWeight: number; // 砂石總重
  sandRate: number; // 砂率
}

export interface DrugAddition {
  id: number;
  name: string;
  unitCost: number;
}

export interface Factor {
  strengthFactorA: number;
  strengthFactorB: number;
  coarseAggregateDryWet: number;
  usedDrugAddition: DrugAddition[];
  proportion: {
    cement: number;
    slag: number;
    flyAsh: number;
    microSilica: number;
    drugAddition: number;
    mixer1: {
      stone1: number;
      stone2: number;
      stone3: number;
      stone4: number;
      sand1: number;
      sand2: number;
      sand3: number;
      sand4: number;
    };
    mixer2: {
      stone1: number;
      stone2: number;
      stone3: number;
      stone4: number;
      sand1: number;
      sand2: number;
      sand3: number;
      sand4: number;
    };
  };
  unitCost: {
    cement: number;
    slag: number;
    flyAsh: number;
    microSilica: number;
    coarseAggregate: number;
    fineAggregate: number;
    sand: number;
  };
  transferCost: number;
  designedStrengthMiniLimit: number;
  correctionPsi: {
    1000: number;
    1500: number;
    2000: number;
    3000: number;
    3500: number;
    4000: number;
    4500: number;
    5000: number;
    6000: number;
    7000: number;
    8000: number;
    10000: number;
    12000: number;
    14000: number;
    16000: number;
    18000: number;
  };
  carbonEmissionFacroe: {
    cement: number;
    slag: number;
    flyAsh: number;
    microSilica: number;
    drug: number;
    recycleWater: number;
    water: number;
    process: number;
    transfer: number;
    waste: number;
  };
}

const getRandom = (a = 1, b = 1) => Math.floor(Math.random() * a) / b;
const randomPickArray = (array: [], num: number) => {
  if (num > array.length) return array;

  const list = [...array];
  const randomList: [] = [];

  while (randomList.length < num) {
    const pickupList = list.length > 0 ? list : [];

    const randomIndex = Math.floor(Math.random() * pickupList.length);
    const pickUpitem = pickupList.splice(randomIndex, 1)[0];

    randomList.push(pickUpitem);
  }

  return randomList;
};

const randomAdjustProperties = (target, k: string) => {
  if (typeof target === 'object' && !Array.isArray(target)) {
    const result = {};
    Object.keys(target).forEach((key: string) => {
      result[key] = randomAdjustProperties(target[key], k);
    });
    return result;
  } else if (typeof target === 'number') {
    const pointNotation = target.toString().split('.')[1]?.length || 0;
    const result = target * (1 + k * (1 - 2 * Math.random()));
    return Number(result.toFixed(pointNotation));
  } else {
    return target;
  }
};

export const drugList = [
  { id: 1, name: 'YC-518E', unitCost: 11 },
  { id: 2, name: 'H22K', unitCost: 12 },
  { id: 3, name: 'Sika 1250(北區)', unitCost: 10 },
  { id: 4, name: 'Sika 25(北區)', unitCost: 10 },
  { id: 5, name: 'H51S', unitCost: 10 },
  { id: 6, name: 'R25', unitCost: 10 },
  { id: 7, name: 'JSP-01', unitCost: 10 },
];

export const factors = {
  strengthFactorA: -919.33,
  strengthFactorB: 713.99,
  coarseAggregateDryWet: 1540,
  usedDrugAddition: [],
  proportion: {
    cement: 3.15,
    slag: 2.9,
    flyAsh: 2.25,
    microSilica: 2.25,
    drugAddition: 1.05,
    drug1Addition: 1.05,
    drug2Addition: 1.05,
    drug3Addition: 1.05,
    mixer1: {
      stone1: 30,
      stone2: 30,
      stone3: 30,
      stone4: 30,
      sand1: 20,
      sand2: 20,
      sand3: 20,
      sand4: 20,
    },
    mixer2: {
      stone1: 2.6,
      stone2: 2.6,
      stone3: 2.6,
      stone4: 2.6,
      sand1: 2.63,
      sand2: 2.63,
      sand3: 2.63,
      sand4: 2.63,
    },
  },
  unitCost: {
    cement: 0.1875,
    slag: 0.1125,
    flyAsh: 0.075,
    microSilica: 0.01,
    coarseAggregate: 0.05,
    fineAggregate: 0.05,
    sand: 0.12,
    drug1Addition: 11,
    drug2Addition: 15,
    drug3Addition: 22,
  },
  transferCost: 465,
  designedStrengthMiniLimit: 120,
  correctionPsi: {
    1000: 0.8,
    1500: 0.8,
    2000: 0.8,
    3000: 0.8,
    3500: 0.9,
    4000: 0.9,
    4500: 1.0,
    5000: 1.0,
    6000: 1.0,
    7000: 1.12,
    8000: 1.3,
    10000: 1.5,
    12000: 1.8,
    14000: 1.8,
    16000: 1.8,
    18000: 1.8,
  },
  carbonEmissionFacroe: {
    cement: 0.907,
    slag: 0.0504,
    flyAsh: 0.004,
    microSilica: 0.00747,
    drug: 1.9,
    water: 0.000299,
    process: 2.016,
    transfer: 6.41233,
    waste: 18.559,
  },
};

const areaFactors = [];
let formulas = [];
let formulaRecords = [];

const BASE_FORMULA_1 = {
  designedStrength: 150,
  waterBinderRatio: 0.34,
  cementPercentage: 60,
  slagPercentage: 30,
  flyAshPercentage: 5,
  microSilicaPercentage: 5,
  stone1Percentage: 30,
  stone2Percentage: 20,
  stone3Percentage: 25,
  stone4Percentage: 25,
  sand1Percentage: 40,
  sand2Percentage: 30,
  sand3Percentage: 20,
  sand4Percentage: 10,
  recycledWaterRatio: 36,
};

const BASE_FORMULA_2 = {
  designedMixingWater: 400,
  totalStoneSandWeight: 1600,
  sandRate: 51,
  airContent: 1,
  coarseAggregateCompactedVolumeRatio: 0.55,
  drug1AdditionRate: 1.05,
  drug2AdditionRate: 2.15,
  drug3AdditionRate: 1.35,
  normalPortlandCementRatio: 20,
};

export const sub = (days: number) => new Date().getTime() - days * 86400000;

const generateFormulaRecord = (formula, i) => {
  const deliveredSite = `碩河信義A7`;
  const deliveredVolume = 10 + getRandom(50);

  const averageStrength = 20 + getRandom(200, 10);
  const averageCost = 200 + getRandom(200);
  const strengthCost = 1 + getRandom(100, 10);

  const record = {
    ...formula,
    recordId: formula.versionId + i,
    latestUsedAt: sub(getRandom(400)),
    deliveredSite,
    deliveredVolume,
    maxStrenth: averageStrength + getRandom(200, 10),
    minStrenth: averageStrength - getRandom(200, 10),
    averageStrength,
    maxCost: averageCost + getRandom(200),
    minCost: averageCost - getRandom(200),
    averageCost,
    strengthCost,
  };

  formulaRecords.push(record);
};

const generateFormula = (
  versionId,
  formulaId,
  name,
  version,
  versions,
  area,
  factory,
  designedStrength,
  slumpingStep,
  particleSize,
) => {
  const deliveredVolume = 100 + getRandom(500);
  const rejectedRate = getRandom(20, 100);
  const averageStrength = 200 + getRandom(2000, 10);
  const averageCost = 2000 + getRandom(2000);

  const statistics = {
    deliveredSites: 1 + getRandom(30),
    latestUsedAt: sub(getRandom(400)),
    deliveredVolume,
    rejectedVolume: deliveredVolume * rejectedRate,
    rejectedRate: rejectedRate * 100,
    maxStrenth: averageStrength + getRandom(2000, 10),
    minStrenth: averageStrength - getRandom(2000, 10),
    averageStrength,
    maxCost: averageCost + getRandom(2000),
    minCost: averageCost - getRandom(2000),
    averageCost,
    strengthCost: 1 + getRandom(1000, 100),
  };

  const factoryFactors = getAreaFactor(area.id, factory.factoryId);
  const drugAdditionRate = factoryFactors.usedDrugAddition.map(() => 1 + getRandom(10, 100));

  const formula = {
    ...BASE_FORMULA_1,
    ...randomAdjustProperties(BASE_FORMULA_2, 0.3),
    versionId,
    formulaId,
    version,
    versions,
    name,
    area,
    factory,
    designedStrength,
    slumpingStep,
    particleSize,
    drugAdditionRate,
    tags: randomPickArray(
      tags.map((t, i) => i),
      3,
    ),
  };

  for (let i = 0; i < 2; i++) {
    generateFormulaRecord(formula, i);
  }

  return {
    ...formula,
    ...statistics,
  };
};

export const getAreaFactor = (areaId, factoryId) => {
  return areaFactors.find((f) => f.area.id === areaId && f.factory.factoryId === factoryId);
};

const generateFormulas = (areas) => {
  formulas = [];
  formulaRecords = [];

  areas.forEach(({ id, name, factoryList }, i) => {
    const area = { id, name };
    factoryList.forEach((factory, j) => {
      designedStrengths.forEach((designedStrength) => {
        slumps.forEach((slump) => {
          particleSizes.forEach((particleSize) => {
            const name = `GB${designedStrength}_${slump}_${particleSize}`;
            const versions = [1, 2, 3];
            const formulaId = `${name}_${i}_${j}_${designedStrength}_${slump}_${particleSize}`;

            versions.forEach((version) => {
              const versionId = `${formulaId}-${version}`;
              formulas.push(
                generateFormula(
                  versionId,
                  formulaId,
                  name,
                  version,
                  versions,
                  area,
                  factory,
                  designedStrength,
                  slump,
                  particleSize,
                ),
              );
            });
          });
        });
      });
    });
  });
};

export const generateAreaFactors = (areas) => {
  if (areaFactors.length === 0) {
    areas.forEach(({ id, name, factoryList }) => {
      factoryList.forEach((factory) => {
        areaFactors.push({
          ...randomAdjustProperties(factors, 0.1),
          area: { id, name },
          factory,
          usedDrugAddition: randomPickArray(drugList, 4 + getRandom(2)),
        });
      });
    });
    generateFormulas(areas);
  }
};

export const saveAreaFactors = (area, factory, factors) => {
  const target = areaFactors.find((f) => f.area === area && f.factory === factory);
  Object.keys(factors).forEach((key) => (target[key] = factors[key]));
  return target;
};

export const getFormulaRecords = (area, factory) => {
  return {
    resultCode: 'Success',
    data: formulaRecords.filter((f) => (!area || f.area === area) && (!factory || f.factory === factory)),
  };
};

export const getFormula = (formulaId, version) => {
  return {
    resultCode: 'Success',
    data: formulas.find((f) => f.formulaId === formulaId && f.version === version),
  };
};

export const saveFormula = (formulaId, version, formula) => {
  const target = formulas.find((f) => f.formulaId === formulaId && f.version === version);
  Object.keys(formula).forEach((key) => (target[key] = formula[key]));
  return target;
};

export const getFormulaVersions = (formulaId) => {
  return {
    resultCode: 'Success',
    data: formulas.filter((f) => f.formulaId === formulaId),
  };
};

export const getFormulas = (areaId, factoryId) => {
  return {
    data: formulas.filter((f) => f.area.id === areaId && f.factory.factoryId === factoryId),
  };
};

const countWeightAndVolume = (ratio, proportion, totalWeight) => {
  const weight = (totalWeight * ratio) / 100;
  const volume = weight / proportion / 1000;
  return { weight, volume };
};

export const caculateProportion = (params, factors) => {
  const {
    designedStrength,
    designedMixingWater,
    waterBinderRatio,
    cementPercentage,
    slagPercentage,
    flyAshPercentage,
    microSilicaPercentage,
    airContent, // 空氣含量 (%)
    coarseAggregateCompactedVolumeRatio, // 粗粒料夯實體積比率
    stone1Percentage,
    stone2Percentage,
    stone3Percentage,
    stone4Percentage,
    sand1Percentage,
    sand2Percentage,
    sand3Percentage,
    sand4Percentage,
    drug1AdditionRate,
    drug2AdditionRate,
    drug3AdditionRate,
    drugAdditionRate = [],
  } = params;

  const {
    strengthFactorA,
    strengthFactorB,
    coarseAggregateDryWet,
    proportion,
    unitCost,
    transferCost,
    carbonEmissionFacroe,
    usedDrugAddition = [],
  } = factors;

  // 膠結材總量 = 拌合水設計用量 / 水膠比
  const totalBinder = designedMixingWater / waterBinderRatio;

  const cement = countWeightAndVolume(cementPercentage, proportion.cement, totalBinder);
  const slag = countWeightAndVolume(slagPercentage, proportion.slag, totalBinder);
  const flyAsh = countWeightAndVolume(flyAshPercentage, proportion.flyAsh, totalBinder);
  const microSilica = countWeightAndVolume(microSilicaPercentage, proportion.microSilica, totalBinder);

  const drug1Addition = countWeightAndVolume(drug1AdditionRate, proportion.drug1Addition, designedMixingWater);
  const drug2Addition = countWeightAndVolume(drug2AdditionRate, proportion.drug2Addition, designedMixingWater);
  const drug3Addition = countWeightAndVolume(drug3AdditionRate, proportion.drug3Addition, designedMixingWater);
  const drugAddition = drugAdditionRate.map((rate) =>
    countWeightAndVolume(rate, proportion.drugAddition, designedMixingWater),
  );

  const airVolume = airContent / 100;
  const coarseAggregate = {
    weight: coarseAggregateDryWet * coarseAggregateCompactedVolumeRatio,
    volume: 0,
  };

  const stone1 = countWeightAndVolume(stone1Percentage, proportion.mixer1.stone1, coarseAggregate.weight);

  const stone2 = countWeightAndVolume(stone2Percentage, proportion.mixer1.stone2, coarseAggregate.weight);

  const stone3 = countWeightAndVolume(stone3Percentage, proportion.mixer1.stone3, coarseAggregate.weight);

  const stone4 = countWeightAndVolume(stone4Percentage, proportion.mixer1.stone4, coarseAggregate.weight);

  coarseAggregate.volume = stone1.volume + stone2.volume + stone3.volume + stone4.volume;

  //細粒料體積 = 1 -  水泥(M3) - 爐石(M3) - 飛灰(M3) - 矽灰(M3) - 空氣(M3) - 拌合水設計用量  - 粗粒料體積
  const fineAggregate = {
    weight: 0,
    volume:
      1 -
      cement.volume - //水泥(M3)
      slag.volume - //爐石(M3)
      flyAsh.volume - //飛灰(M3)
      microSilica.volume - //矽灰(M3)
      airVolume - //空氣(M3)
      designedMixingWater / 1000 - //拌合水設計用量
      coarseAggregate.volume, //粗粒料體積
  };

  //細粒料總重 =  細粒料體積 x ( 砂 1 比重 x 砂 1(%) + 砂 2 比重 x 砂 2(%) + 砂 3 比重 x 砂 3(%) + 砂 4 比重 x 砂 4(%)) * 1000
  fineAggregate.weight =
    fineAggregate.volume *
    (proportion.mixer1.sand1 * sand1Percentage +
      proportion.mixer1.sand2 * sand2Percentage +
      proportion.mixer1.sand3 * sand3Percentage +
      proportion.mixer1.sand4 * sand4Percentage);
  //粗粒料總重 = 粗粒料夯實乾重 x 粗粒料夯實體積比率
  coarseAggregate.weight = coarseAggregateDryWet * coarseAggregateCompactedVolumeRatio;

  // 砂率 = 細粒料體積 / (細粒料體積 + 粗粒料體積 )  x 100
  const sandRate = (fineAggregate.volume / (fineAggregate.volume + coarseAggregate.volume)) * 100;

  const sand1 = {
    weight: (fineAggregate.weight * sand1Percentage) / 100,
    volume: (fineAggregate.weight * sand1Percentage) / proportion.mixer1.sand1 / 100000,
  };

  const sand2 = {
    weight: (fineAggregate.weight * sand2Percentage) / 100,
    volume: (fineAggregate.weight * sand2Percentage) / proportion.mixer1.sand2 / 100000,
  };

  const sand3 = {
    weight: (fineAggregate.weight * sand3Percentage) / 100,
    volume: (fineAggregate.weight * sand3Percentage) / proportion.mixer1.sand3 / 100000,
  };

  const sand4 = {
    weight: (fineAggregate.weight * sand4Percentage) / 100,
    volume: (fineAggregate.weight * sand4Percentage) / proportion.mixer1.sand4 / 100000,
  };

  const BW = Number(designedMixingWater);

  const drugAdditionWeight = drugAddition.reduce((total, current) => total + current.weight, 0);

  const proportionUnitWeight =
    BW +
    cement.weight +
    slag.weight +
    flyAsh.weight +
    microSilica.weight +
    coarseAggregate.weight +
    fineAggregate.weight +
    drugAdditionWeight;

  const proportionUnitVolume =
    BW / 1000 +
    cement.volume +
    slag.volume +
    flyAsh.volume +
    microSilica.volume +
    coarseAggregate.volume +
    fineAggregate.volume +
    drugAddition.reduce((total, current) => total + current.volume, 0);

  const durgAdditionCost = usedDrugAddition
    .map((drug, i) => drug.unitCost * (drugAddition[i]?.weight || 0))
    .reduce((total, current) => total + current, 0);

  const proportionUnitCost =
    durgAdditionCost +
    cement.weight * unitCost.cement +
    slag.weight * unitCost.slag +
    flyAsh.weight * unitCost.flyAsh +
    microSilica.weight * unitCost.microSilica +
    coarseAggregate.weight * unitCost.coarseAggregate +
    fineAggregate.weight * unitCost.fineAggregate +
    Number(transferCost);

  const solidWeight = cement.weight + slag.weight + flyAsh.weight + microSilica.weight;
  const liquidWeight = BW + drugAdditionWeight;

  const actualWaterBinderRatio = solidWeight ? liquidWeight / solidWeight : 0;

  // 預估強度 = 水膠比 x 強度係數a + 強度係數b
  const estimatedStrength = waterBinderRatio * strengthFactorA + strengthFactorB;
  // 強度安全係數= 預估強度 / 設計強度
  const strengthSafetyFactor = estimatedStrength / designedStrength;
  // 預期強度 = 實際水膠比 x 強度係數a + 強度係數
  const expectedStrength = actualWaterBinderRatio * strengthFactorA + strengthFactorB;
  // 達成設計強度 = 預期強度 / 設計強度
  const achievedDesignStrength = designedStrength ? (expectedStrength / designedStrength) * 100 : 0;

  // 碳排放量(原料)= 水泥重(kg) x 水泥碳係數 + 爐石重(kg) x 爐石碳係數 + 飛灰重(kg) x 飛灰碳係數 + 砂石重(kg) x 砂石碳係數+ (藥1重(kg)+藥2重(kg)+藥3重(kg)) x 藥劑碳係數 + 水重(kg) x 水碳係數
  const materialCEF =
    cement.weight * carbonEmissionFacroe.cement +
    slag.weight * carbonEmissionFacroe.slag +
    flyAsh.weight * carbonEmissionFacroe.flyAsh +
    microSilica.weight * carbonEmissionFacroe.microSilica +
    drugAdditionWeight * carbonEmissionFacroe.drug +
    designedMixingWater * carbonEmissionFacroe.water;

  // 碳排放量(完整生命週期) = 碳排放量(原料) + 製程碳排量 + 運輸碳排量 + 廢棄碳係數
  const totalCEF =
    materialCEF + carbonEmissionFacroe.process + carbonEmissionFacroe.transfer + carbonEmissionFacroe.waste;

  return {
    strengthSafetyFactor, // 強度安全係數
    totalBinder, // 膠材總重
    sandRate, // 砂率
    coarseAggregate, // 粗粒料
    fineAggregate, // 細粒料
    airVolume, // 空氣體積
    waterBinderRatio,
    stone1,
    stone2,
    stone3,
    stone4,
    sand1,
    sand2,
    sand3,
    sand4,
    cement,
    slag,
    flyAsh,
    microSilica,
    drug1Addition,
    drug2Addition,
    drug3Addition,
    drugAddition,
    estimatedStrength, // 預期強度
    achievedDesignStrength, // 達成設計強度
    proportionUnitWeight, // 配比單位重量
    proportionUnitVolume, // 配比單位體積
    actualWaterBinderRatio, // 實際水膠比
    proportionUnitCost, // 配比單位成本
    expectedStrength,
    solidWeight,
    materialCEF, // 碳排放量(原料)
    totalCEF, // 碳排放量(完整生命週期)
  };
};

export const caculateProportionV2 = (params, factors) => {
  const {
    designedStrength,
    designedMixingWater,
    waterBinderRatio,
    cementWeight, // 水泥重量 (kg)
    slagWeight, // 爐石重量 (kg)
    flyAshWeight, // 飛灰重量 (kg)
    microSilicaWeight, // 矽灰重量 (kg)
    totalWeight, // 砂石總重 (kg)
    sandRate, // 砂率 (%)
    stone1Percentage,
    stone2Percentage,
    stone3Percentage,
    stone4Percentage,
    sand1Percentage,
    sand2Percentage,
    sand3Percentage,
    sand4Percentage,
    drug1AdditionRate,
    drug2AdditionRate,
    drug3AdditionRate,
    drugAdditionRate = [],
  } = params;

  const {
    strengthFactorA,
    strengthFactorB,
    proportion,
    unitCost,
    transferCost,
    carbonEmissionFacroe,
    usedDrugAddition = [],
  } = factors;

  // 膠結材總量 = 拌合水設計用量 / 水膠比
  const totalBinder = designedMixingWater / waterBinderRatio;

  //const cement = countWeightAndVolume(cementPercentage, proportion.cement, totalBinder);
  //const slag = countWeightAndVolume(slagPercentage, proportion.slag, totalBinder);
  //const flyAsh = countWeightAndVolume(flyAshPercentage, proportion.flyAsh, totalBinder);
  //const microSilica = countWeightAndVolume(microSilicaPercentage, proportion.microSilica, totalBinder);

  const cement = {
    weight: cementWeight,
    volume: cementWeight / proportion.cement / 1000,
  };
  const slag = {
    weight: slagWeight,
    volume: slagWeight / proportion.slag / 1000,
  };
  const flyAsh = {
    weight: flyAshWeight,
    volume: flyAshWeight / proportion.flyAsh / 1000,
  };
  const microSilica = {
    weight: microSilicaWeight,
    volume: microSilicaWeight / proportion.microSilica / 1000,
  };

  const cementPercentage = (cementWeight / totalBinder) * 100;
  const slagPercentage = (slagWeight / totalBinder) * 100;
  const flyAshPercentage = (flyAshWeight / totalBinder) * 100;
  const microSilicaPercentage = (microSilicaWeight / totalBinder) * 100;

  const drug1Addition = countWeightAndVolume(drug1AdditionRate, proportion.drug1Addition, designedMixingWater);
  const drug2Addition = countWeightAndVolume(drug2AdditionRate, proportion.drug2Addition, designedMixingWater);
  const drug3Addition = countWeightAndVolume(drug3AdditionRate, proportion.drug3Addition, designedMixingWater);

  const drugAddition = drugAdditionRate.map((rate) =>
    countWeightAndVolume(rate, proportion.drugAddition, designedMixingWater),
  );

  const drugAdditionWeight = drugAddition.reduce((total, current) => total + current.weight, 0);

  const fineAggregate = {
    //細粒料總重 = 砂石總重 ｘ 砂率
    weight: (totalWeight * sandRate) / 100,
    volume: 0,
  };

  const coarseAggregate = {
    //粗粒料總重= 砂石總重 - 細粒料總重
    weight: totalWeight - fineAggregate.weight,
    volume: 0,
  };

  const stone1 = countWeightAndVolume(stone1Percentage, proportion.mixer1.stone1, coarseAggregate.weight);

  const stone2 = countWeightAndVolume(stone2Percentage, proportion.mixer1.stone2, coarseAggregate.weight);

  const stone3 = countWeightAndVolume(stone3Percentage, proportion.mixer1.stone3, coarseAggregate.weight);

  const stone4 = countWeightAndVolume(stone4Percentage, proportion.mixer1.stone4, coarseAggregate.weight);

  coarseAggregate.volume = stone1.volume + stone2.volume + stone3.volume + stone4.volume;

  const sand1 = {
    weight: (fineAggregate.weight * sand1Percentage) / 100,
    volume: (fineAggregate.weight * sand1Percentage) / proportion.mixer1.sand1 / 100000,
  };

  const sand2 = {
    weight: (fineAggregate.weight * sand2Percentage) / 100,
    volume: (fineAggregate.weight * sand2Percentage) / proportion.mixer1.sand2 / 100000,
  };

  const sand3 = {
    weight: (fineAggregate.weight * sand3Percentage) / 100,
    volume: (fineAggregate.weight * sand3Percentage) / proportion.mixer1.sand3 / 100000,
  };

  const sand4 = {
    weight: (fineAggregate.weight * sand4Percentage) / 100,
    volume: (fineAggregate.weight * sand4Percentage) / proportion.mixer1.sand4 / 100000,
  };

  fineAggregate.volume = sand1.volume + sand2.volume + sand3.volume + sand4.volume;

  const BW = Number(designedMixingWater);
  const proportionUnitWeight =
    BW +
    //    drug1Addition.weight +
    //    drug2Addition.weight +
    //    drug3Addition.weight +
    drugAdditionWeight +
    cement.weight +
    slag.weight +
    flyAsh.weight +
    microSilica.weight +
    coarseAggregate.weight +
    fineAggregate.weight;

  const proportionUnitVolume =
    BW / 1000 +
    //    drug1Addition.volume +
    //    drug2Addition.volume +
    //    drug3Addition.volume +
    cement.volume +
    slag.volume +
    flyAsh.volume +
    microSilica.volume +
    coarseAggregate.volume +
    fineAggregate.volume +
    drugAddition.reduce((total, current) => total + current.volume, 0);

  const durgAdditionCost = usedDrugAddition
    .map((drug, i) => drug.unitCost * (drugAddition[i]?.weight || 0))
    .reduce((total, current) => total + current, 0);

  const proportionUnitCost =
    //    drug1Addition.weight * unitCost.drug1Addition +
    //    drug2Addition.weight * unitCost.drug2Addition +
    //    drug3Addition.weight * unitCost.drug3Addition +
    durgAdditionCost +
    cement.weight * unitCost.cement +
    slag.weight * unitCost.slag +
    flyAsh.weight * unitCost.flyAsh +
    microSilica.weight * unitCost.microSilica +
    coarseAggregate.weight * unitCost.coarseAggregate +
    fineAggregate.weight * unitCost.fineAggregate +
    Number(transferCost);

  const solidWeight = cement.weight + slag.weight + flyAsh.weight + microSilica.weight;
  const liquidWeight = BW + drug1Addition.weight + drug2Addition.weight + drug3Addition.weight;

  const actualWaterBinderRatio = solidWeight ? liquidWeight / solidWeight : 0;

  // 預估強度 = 水膠比 x 強度係數a + 強度係數b
  const estimatedStrength = waterBinderRatio * strengthFactorA + strengthFactorB;
  // 強度安全係數= 預估強度 / 設計強度
  const strengthSafetyFactor = estimatedStrength / designedStrength;
  // 預期強度 = 實際水膠比 x 強度係數a + 強度係數
  const expectedStrength = actualWaterBinderRatio * strengthFactorA + strengthFactorB;
  // 達成設計強度 = 預期強度 / 設計強度
  const achievedDesignStrength = designedStrength ? (expectedStrength / designedStrength) * 100 : 0;

  // 碳排放量(原料)= 水泥重(kg) x 水泥碳係數 + 爐石重(kg) x 爐石碳係數 + 飛灰重(kg) x 飛灰碳係數 + 砂石重(kg) x 砂石碳係數+ (藥1重(kg)+藥2重(kg)+藥3重(kg)) x 藥劑碳係數 + 水重(kg) x 水碳係數
  const materialCEF =
    cement.weight * carbonEmissionFacroe.cement +
    slag.weight * carbonEmissionFacroe.slag +
    flyAsh.weight * carbonEmissionFacroe.flyAsh +
    microSilica.weight * carbonEmissionFacroe.microSilica +
    (drug1Addition.weight + drug2Addition.weight + drug3Addition.weight) * carbonEmissionFacroe.drug +
    designedMixingWater * carbonEmissionFacroe.water;

  // 碳排放量(完整生命週期) = 碳排放量(原料) + 製程碳排量 + 運輸碳排量 + 廢棄碳係數
  const totalCEF =
    materialCEF + carbonEmissionFacroe.process + carbonEmissionFacroe.transfer + carbonEmissionFacroe.waste;

  return {
    strengthSafetyFactor, // 強度安全係數
    totalBinder, // 膠材總重
    sandRate, // 砂率
    coarseAggregate, // 粗粒料
    fineAggregate, // 細粒料
    waterBinderRatio,
    stone1,
    stone2,
    stone3,
    stone4,
    sand1,
    sand2,
    sand3,
    sand4,
    cementPercentage,
    slagPercentage,
    flyAshPercentage,
    microSilicaPercentage,
    cement,
    slag,
    flyAsh,
    microSilica,
    drug1Addition,
    drug2Addition,
    drug3Addition,
    drugAddition,
    estimatedStrength, // 預期強度
    achievedDesignStrength, // 達成設計強度
    proportionUnitWeight, // 配比單位重量
    proportionUnitVolume, // 配比單位體積
    actualWaterBinderRatio, // 實際水膠比
    proportionUnitCost, // 配比單位成本
    expectedStrength,
    solidWeight,
    materialCEF, // 碳排放量(原料)
    totalCEF, // 碳排放量(完整生命週期)
  };
};

export const getTagsData = () => {
  if (tagsData.length === 0) {
    tagsData = tags.map((t, i) => ({
      number: i,
      name: t,
      status: 0,
      lastUpdated: Date.now() - getRandom(86400000 * 24),
    }));
  }
  return tagsData;
};

const calcWeight = (pct: number, totalWeight: number) => (pct / 100) * totalWeight;
const calcPct = (weight: number, totalWeight: number) => (weight / totalWeight) * 100;
const calcVolume = (weight: number, speciWt: number) => weight / speciWt / 1000;
const calcTotalCost = (weight: number, cost: number) => weight * cost;
const calcPercentConvertRatio = (pct: number) => pct / 100;

export class CalcBaseProportion {
  // ** display **
  cementitiousWt: number;

  tapWaterPct: number;

  chemAdmx1Wt: number;
  chemAdmx2Wt: number;
  chemAdmx3Wt: number;
  chemAdmx4Wt: number;
  chemAdmx5Wt: number;
  chemAdmx6Wt: number;
  chemAdmx1Volume: number;
  chemAdmx2Volume: number;
  chemAdmx3Volume: number;
  chemAdmx4Volume: number;
  chemAdmx5Volume: number;
  chemAdmx6Volume: number;
  // ****

  estimateStr: number;
  chemAdmxWt: number;
  chemAdmxVolume: number;
  isSelectedMixer2: boolean;
  stone1SpecifWt: number;
  stone2SpecifWt: number;
  stone3SpecifWt: number;
  stone4SpecifWt: number;
  sand1SpecifWt: number;
  sand2SpecifWt: number;
  sand3SpecifWt: number;
  sand4SpecifWt: number;

  constructor(baseProportion: ImportBaseProportion, maintenance: ProportionMaintenanceResponse) {
    this.estimateStr = +baseProportion.waterColloidRatio * maintenance.paramAStr + maintenance.paramBStr;

    this.cementitiousWt = +baseProportion.quantityMixingWater / +baseProportion.waterColloidRatio;

    this.tapWaterPct = 100 - +baseProportion.recycledWater;

    this.chemAdmx1Wt = calcWeight(+baseProportion.chemAdmx1UsagePct, this.cementitiousWt);
    this.chemAdmx2Wt = baseProportion.chemAdmx2UsagePct
      ? calcWeight(+baseProportion.chemAdmx2UsagePct, this.cementitiousWt)
      : 0;
    this.chemAdmx3Wt = baseProportion.chemAdmx3UsagePct
      ? calcWeight(+baseProportion.chemAdmx3UsagePct, this.cementitiousWt)
      : 0;
    this.chemAdmx4Wt = baseProportion.chemAdmx4UsagePct
      ? calcWeight(+baseProportion.chemAdmx4UsagePct, this.cementitiousWt)
      : 0;
    this.chemAdmx5Wt = baseProportion.chemAdmx5UsagePct
      ? calcWeight(+baseProportion.chemAdmx5UsagePct, this.cementitiousWt)
      : 0;
    this.chemAdmx6Wt = baseProportion.chemAdmx6UsagePct
      ? calcWeight(+baseProportion.chemAdmx6UsagePct, this.cementitiousWt)
      : 0;

    this.chemAdmx1Volume = calcVolume(this.chemAdmx1Wt, maintenance.chemAdmxSpecifWt);
    this.chemAdmx2Volume = calcVolume(this.chemAdmx2Wt, maintenance.chemAdmxSpecifWt);
    this.chemAdmx3Volume = calcVolume(this.chemAdmx3Wt, maintenance.chemAdmxSpecifWt);
    this.chemAdmx4Volume = calcVolume(this.chemAdmx4Wt, maintenance.chemAdmxSpecifWt);
    this.chemAdmx5Volume = calcVolume(this.chemAdmx5Wt, maintenance.chemAdmxSpecifWt);
    this.chemAdmx6Volume = calcVolume(this.chemAdmx6Wt, maintenance.chemAdmxSpecifWt);

    this.chemAdmxWt =
      this.chemAdmx1Wt + this.chemAdmx2Wt + this.chemAdmx3Wt + this.chemAdmx4Wt + this.chemAdmx5Wt + this.chemAdmx6Wt;
    this.chemAdmxVolume =
      this.chemAdmx1Volume +
      this.chemAdmx2Volume +
      this.chemAdmx3Volume +
      this.chemAdmx4Volume +
      this.chemAdmx5Volume +
      this.chemAdmx6Volume;

    this.isSelectedMixer2 = baseProportion.selectedMixer === 'mixer2' ? true : false;
    this.stone1SpecifWt = this.isSelectedMixer2 ? maintenance.m2Stone1SpecifWt : maintenance.m1Stone1SpecifWt;
    this.stone2SpecifWt = this.isSelectedMixer2 ? maintenance.m2Stone2SpecifWt : maintenance.m1Stone2SpecifWt;
    this.stone3SpecifWt = this.isSelectedMixer2 ? maintenance.m2Stone3SpecifWt : maintenance.m1Stone3SpecifWt;
    this.stone4SpecifWt = this.isSelectedMixer2 ? maintenance.m2Stone4SpecifWt : maintenance.m1Stone4SpecifWt;
    this.sand1SpecifWt = this.isSelectedMixer2 ? maintenance.m2Sand1SpecifWt : maintenance.m1Sand1SpecifWt;
    this.sand2SpecifWt = this.isSelectedMixer2 ? maintenance.m2Sand2SpecifWt : maintenance.m1Sand2SpecifWt;
    this.sand3SpecifWt = this.isSelectedMixer2 ? maintenance.m2Sand3SpecifWt : maintenance.m1Sand3SpecifWt;
    this.sand4SpecifWt = this.isSelectedMixer2 ? maintenance.m2Sand4SpecifWt : maintenance.m1Sand4SpecifWt;
  }
}

export class CalcCreateProportion extends CalcBaseProportion {
  // ** PostProportionRequest **
  cementWt: number;
  slagWt: number;
  flyAshWt: number;
  microsilicaWt: number;
  // ****

  // ** display **
  safeStr: number;

  cementVolume: number;
  slagVolume: number;
  flyAshVolume: number;
  microsilicaVolume: number;

  sandPct: number;
  coarseAggWt: number;
  fineAggWt: number;
  airVolume: number;
  coarseAggVolume: number;
  fineAggVolume: number;
  stone1Wt: number;
  stone2Wt: number;
  stone3Wt: number;
  stone4Wt: number;
  sand1Wt: number;
  sand2Wt: number;
  sand3Wt: number;
  sand4Wt: number;
  stone1Volume: number;
  stone2Volume: number;
  stone3Volume: number;
  stone4Volume: number;
  sand1Volume: number;
  sand2Volume: number;
  sand3Volume: number;
  sand4Volume: number;

  expectedStr: number;
  proportionUnitWt: number;
  truthWaterColloidRatio: number;
  arrivedDesignStrPct: number;
  proportionUnitVolume: number;
  proportionUnitCost: number;
  // ****

  cementitiousPowerVolume: number;
  cementitiousPowerWt: number;

  recycledWaterWt: number;
  tapWaterWt: number;

  carbonEmissionMaterial: number;
  carbonEmissionLifeCycle: number;

  calcPreSandWeight: number;

  constructor(formData: ImportCreateProportion, maintenance: ProportionMaintenanceResponse) {
    super(formData, maintenance);

    this.safeStr = (this.estimateStr / +formData.dsgnStr) * 100;
    this.recycledWaterWt = (+formData.recycledWater * +formData.quantityMixingWater) / 100;
    this.tapWaterWt = (this.tapWaterPct * +formData.quantityMixingWater) / 100;

    this.cementWt = calcWeight(+formData.cementPct, this.cementitiousWt);
    this.slagWt = calcWeight(+formData.slagPct, this.cementitiousWt);
    this.flyAshWt = calcWeight(+formData.flyAshPct, this.cementitiousWt);
    this.microsilicaWt = calcWeight(+formData.microsilicaPct, this.cementitiousWt);

    this.cementVolume = calcVolume(this.cementWt, maintenance.cementSpecifWt);
    this.slagVolume = calcVolume(this.slagWt, maintenance.slagSpecifWt);
    this.flyAshVolume = calcVolume(this.flyAshWt, maintenance.flyAshSpecifWt);
    this.microsilicaVolume = calcVolume(this.microsilicaWt, maintenance.microsilicaSpecifWt);

    this.coarseAggWt = +formData.coarseAggCompVolumeRatio * maintenance.coraseAggCompDryWt;
    this.stone1Wt = calcWeight(+formData.stone1Pct, this.coarseAggWt);
    this.stone2Wt = calcWeight(+formData.stone2Pct, this.coarseAggWt);
    this.stone3Wt = calcWeight(+formData.stone3Pct, this.coarseAggWt);
    this.stone4Wt = calcWeight(+formData.stone4Pct, this.coarseAggWt);

    this.stone1Volume = calcVolume(this.stone1Wt, this.stone1SpecifWt);
    this.stone2Volume = calcVolume(this.stone2Wt, this.stone2SpecifWt);
    this.stone3Volume = calcVolume(this.stone3Wt, this.stone3SpecifWt);
    this.stone4Volume = calcVolume(this.stone4Wt, this.stone4SpecifWt);
    this.coarseAggVolume = this.stone1Volume + this.stone2Volume + this.stone3Volume + this.stone4Volume;

    this.airVolume = (+formData.airContent / 100) * 1;
    this.cementitiousPowerVolume = this.cementVolume + this.slagVolume + this.flyAshVolume + this.microsilicaVolume;
    this.fineAggVolume =
      1 - (this.cementitiousPowerVolume + this.airVolume + +formData.quantityMixingWater / 1000 + this.coarseAggVolume);
    this.sandPct = (this.fineAggVolume * 100) / (this.fineAggVolume + this.coarseAggVolume);

    this.calcPreSandWeight =
      this.fineAggVolume *
      (this.sand1SpecifWt * calcPercentConvertRatio(+formData.sand1Pct) +
        this.sand2SpecifWt * calcPercentConvertRatio(+formData.sand2Pct) +
        this.sand3SpecifWt * calcPercentConvertRatio(+formData.sand3Pct) +
        this.sand4SpecifWt * calcPercentConvertRatio(+formData.sand4Pct)) *
      1000;

    this.sand1Wt = this.calcPreSandWeight * calcPercentConvertRatio(+formData.sand1Pct);
    this.sand2Wt = this.calcPreSandWeight * calcPercentConvertRatio(+formData.sand2Pct);
    this.sand3Wt = this.calcPreSandWeight * calcPercentConvertRatio(+formData.sand3Pct);
    this.sand4Wt = this.calcPreSandWeight * calcPercentConvertRatio(+formData.sand4Pct);

    this.fineAggWt = this.sand1Wt + this.sand2Wt + this.sand3Wt + this.sand4Wt;
    this.sand1Volume = calcVolume(this.sand1Wt, this.sand1SpecifWt);
    this.sand2Volume = calcVolume(this.sand2Wt, this.sand2SpecifWt);
    this.sand3Volume = calcVolume(this.sand3Wt, this.sand3SpecifWt);
    this.sand4Volume = calcVolume(this.sand4Wt, this.sand4SpecifWt);

    this.cementitiousPowerWt = this.cementWt + this.slagWt + this.flyAshWt + this.microsilicaWt;
    this.proportionUnitWt =
      +formData.quantityMixingWater + this.chemAdmxWt + this.cementitiousPowerWt + this.coarseAggWt + this.fineAggWt;
    this.proportionUnitVolume =
      +formData.quantityMixingWater / 1000 +
      this.chemAdmxVolume +
      this.cementitiousPowerVolume +
      this.airVolume +
      this.coarseAggVolume +
      this.fineAggVolume;
    this.truthWaterColloidRatio = (+formData.quantityMixingWater + this.chemAdmxWt) / this.cementitiousPowerWt;

    this.proportionUnitCost =
      calcTotalCost(this.chemAdmx1Wt, maintenance.costChemAdmx1 ?? 0) +
      calcTotalCost(this.chemAdmx2Wt, maintenance.costChemAdmx2 ?? 0) +
      calcTotalCost(this.chemAdmx3Wt, maintenance.costChemAdmx3 ?? 0) +
      calcTotalCost(this.chemAdmx4Wt, maintenance.costChemAdmx4 ?? 0) +
      calcTotalCost(this.chemAdmx5Wt, maintenance.costChemAdmx5 ?? 0) +
      calcTotalCost(this.chemAdmx6Wt, maintenance.costChemAdmx6 ?? 0) +
      calcTotalCost(this.cementWt, maintenance.costCement ?? 0) +
      calcTotalCost(this.slagWt, maintenance.costSlag ?? 0) +
      calcTotalCost(this.flyAshWt, maintenance.costFlyAsh ?? 0) +
      calcTotalCost(this.microsilicaWt, maintenance.costMicrosilica ?? 0) +
      calcTotalCost(this.coarseAggWt, maintenance.costCoarseAgg ?? maintenance.costSandStone) +
      calcTotalCost(this.fineAggWt, maintenance.costFineAgg ?? maintenance.costSandStone) +
      maintenance.costXferAndProc;
    this.expectedStr = this.truthWaterColloidRatio * maintenance.paramAStr + maintenance.paramBStr;
    this.arrivedDesignStrPct = calcPct(this.expectedStr, +formData.dsgnStr);

    this.carbonEmissionMaterial =
      this.cementWt * maintenance.cementCEC +
      this.slagWt * maintenance.slagCEC +
      this.flyAshWt * maintenance.flyAshCEC +
      (this.sand1Wt + this.sand2Wt) * maintenance.sandStoneCEC +
      (this.chemAdmx1Wt +
        this.chemAdmx2Wt +
        this.chemAdmx3Wt +
        this.chemAdmx4Wt +
        this.chemAdmx5Wt +
        this.chemAdmx6Wt) *
        maintenance.chemicalCEC +
      this.recycledWaterWt * maintenance.reclaimedWaterCEC +
      this.tapWaterWt * maintenance.tapWaterCEC;

    this.carbonEmissionLifeCycle =
      this.carbonEmissionMaterial + maintenance.productionCEC + maintenance.transferCEC + maintenance.disposalCEC;
  }
}

export class CalcProportion extends CalcBaseProportion {
  // ** display **
  arrivedDesignStrPct: number;
  sandAndStoneWt: number;
  sandPct: number;

  stone1Wt: number;
  stone2Wt: number;
  stone3Wt: number;
  stone4Wt: number;
  sand1Wt: number;
  sand2Wt: number;
  sand3Wt: number;
  sand4Wt: number;

  cementPct: number;
  slagPct: number;
  flyAshPct: number;
  microsilicaPct: number;
  // ****

  stone1Volume: number;
  stone2Volume: number;
  stone3Volume: number;
  stone4Volume: number;
  coarseAggVolume: number;
  coarseAggWt: number;
  cementVolume: number;
  slagVolume: number;
  flyAshVolume: number;
  microsilicaVolume: number;
  cementitiousPowerVolume: number;
  airVolume: number;
  fineAggVolume: number;
  expectedStr: number;
  truthWaterColloidRatio: number;
  cementitiousPowerWt: number;
  calcPreSandWeight: number;

  constructor(proportion: ProportionResponse, maintenance: ProportionMaintenanceResponse) {
    super(proportion, maintenance);

    this.cementitiousPowerWt = proportion.cementWt + proportion.slagWt + proportion.flyAshWt + proportion.microsilicaWt;
    this.truthWaterColloidRatio = (proportion.quantityMixingWater + this.chemAdmxWt) / this.cementitiousPowerWt;
    this.expectedStr = this.truthWaterColloidRatio * maintenance.paramAStr + maintenance.paramBStr;
    this.arrivedDesignStrPct = calcPct(this.expectedStr, proportion.dsgnStr);

    this.sandAndStoneWt = proportion.sandTtlWt + proportion.stoneTtlWt;

    this.coarseAggWt = calcWeight(proportion.coarseAggCompVolumeRatio * 100, maintenance.coraseAggCompDryWt);
    this.stone1Wt = calcWeight(proportion.stone1Pct, this.coarseAggWt);
    this.stone2Wt = calcWeight(proportion.stone2Pct, this.coarseAggWt);
    this.stone3Wt = calcWeight(proportion.stone3Pct, this.coarseAggWt);
    this.stone4Wt = calcWeight(proportion.stone4Pct, this.coarseAggWt);

    this.stone1Volume = calcVolume(this.stone1Wt, this.stone1SpecifWt);
    this.stone2Volume = calcVolume(this.stone2Wt, this.stone2SpecifWt);
    this.stone3Volume = calcVolume(this.stone3Wt, this.stone3SpecifWt);
    this.stone4Volume = calcVolume(this.stone4Wt, this.stone4SpecifWt);
    this.coarseAggVolume = this.stone1Volume + this.stone2Volume + this.stone3Volume + this.stone4Volume;

    this.cementVolume = calcVolume(proportion.cementWt, maintenance.cementSpecifWt);
    this.slagVolume = calcVolume(proportion.slagWt, maintenance.slagSpecifWt);
    this.flyAshVolume = calcVolume(proportion.flyAshWt, maintenance.flyAshSpecifWt);
    this.microsilicaVolume = calcVolume(proportion.microsilicaWt, maintenance.microsilicaSpecifWt);
    this.cementitiousPowerVolume = this.cementVolume + this.slagVolume + this.flyAshVolume + this.microsilicaVolume;
    this.airVolume = (proportion.airContent / 100) * 1;

    this.fineAggVolume =
      1 -
      (this.cementitiousPowerVolume + this.airVolume + proportion.quantityMixingWater / 1000 + this.coarseAggVolume);

    this.calcPreSandWeight =
      this.fineAggVolume *
      (this.sand1SpecifWt * calcPercentConvertRatio(+proportion.sand1Pct) +
        this.sand2SpecifWt * calcPercentConvertRatio(+proportion.sand2Pct) +
        this.sand3SpecifWt * calcPercentConvertRatio(+proportion.sand3Pct) +
        this.sand4SpecifWt * calcPercentConvertRatio(+proportion.sand4Pct)) *
      1000;
    this.sand1Wt = this.calcPreSandWeight * calcPercentConvertRatio(+proportion.sand1Pct);
    this.sand2Wt = this.calcPreSandWeight * calcPercentConvertRatio(+proportion.sand2Pct);
    this.sand3Wt = this.calcPreSandWeight * calcPercentConvertRatio(+proportion.sand3Pct);
    this.sand4Wt = this.calcPreSandWeight * calcPercentConvertRatio(+proportion.sand4Pct);

    this.sandPct = (proportion.sandTtlWt * 100) / (proportion.sandTtlWt + proportion.stoneTtlWt);

    this.cementPct = calcPct(proportion.cementWt, this.cementitiousWt);
    this.slagPct = calcPct(proportion.slagWt, this.cementitiousWt);
    this.flyAshPct = calcPct(proportion.flyAshWt, this.cementitiousWt);
    this.microsilicaPct = calcPct(proportion.microsilicaWt, this.cementitiousWt);
  }
}

export class CalcUpdateProportion extends CalcBaseProportion {
  // ** display **
  safeStr: number;

  cementPct: number;
  slagPct: number;
  flyAshPct: number;
  microsilicaPct: number;

  coarseAggWt: number;
  stone1Wt: number;
  stone2Wt: number;
  stone3Wt: number;
  stone4Wt: number;

  calcStone1Pct: number;
  calcStone2Pct: number;
  calcStone3Pct: number;
  calcStone4Pct: number;

  fineAggWt: number;
  sand1Wt: number;
  sand2Wt: number;
  sand3Wt: number;
  sand4Wt: number;

  calcSand1Pct: number;
  calcSand2Pct: number;
  calcSand3Pct: number;
  calcSand4Pct: number;

  expectedStr: number;
  proportionUnitWt: number;
  truthWaterColloidRatio: number;
  arrivedDesignStrPct: number;
  proportionUnitVolume: number;
  proportionUnitCost: number;
  // ****

  cementitiousPowerWt: number;
  cementVolume: number;
  slagVolume: number;
  flyAshVolume: number;
  microsilicaVolume: number;
  stone1Volume: number;
  stone2Volume: number;
  stone3Volume: number;
  stone4Volume: number;
  sand1Volume: number;
  sand2Volume: number;
  sand3Volume: number;
  sand4Volume: number;

  recycledWaterWt: number;
  tapWaterWt: number;

  carbonEmissionMaterial: number;
  carbonEmissionLifeCycle: number;

  constructor(
    formData: ImportUpdateProportion,
    proportion: ProportionResponse,
    maintenance: ProportionMaintenanceResponse,
  ) {
    super(formData, maintenance);

    this.safeStr = (this.estimateStr / +proportion.dsgnStr) * 100;
    this.recycledWaterWt = (+formData.recycledWater * +formData.quantityMixingWater) / 100;
    this.tapWaterWt = (this.tapWaterPct * +formData.quantityMixingWater) / 100;

    this.cementPct = calcPct(formData.cementWt, this.cementitiousWt);
    this.slagPct = calcPct(formData.slagWt, this.cementitiousWt);
    this.flyAshPct = calcPct(formData.flyAshWt, this.cementitiousWt);
    this.microsilicaPct = calcPct(formData.microsilicaWt, this.cementitiousWt);

    this.fineAggWt = calcWeight(+formData.sandPct, +formData.sandAndStoneTtlWt);

    this.sand1Wt = calcWeight(+formData.sand1Pct, this.fineAggWt);
    this.sand2Wt = calcWeight(+formData.sand2Pct, this.fineAggWt);
    this.sand3Wt = calcWeight(+formData.sand3Pct, this.fineAggWt);
    this.sand4Wt = calcWeight(+formData.sand4Pct, this.fineAggWt);

    this.calcSand1Pct = calcPct(+formData.sand1Kg, this.fineAggWt);
    this.calcSand2Pct = calcPct(+formData.sand2Kg, this.fineAggWt);
    this.calcSand3Pct = calcPct(+formData.sand3Kg, this.fineAggWt);
    this.calcSand4Pct = calcPct(+formData.sand4Kg, this.fineAggWt);

    this.coarseAggWt = +formData.sandAndStoneTtlWt - this.fineAggWt;
    this.stone1Wt = calcWeight(+formData.stone1Pct, this.coarseAggWt);
    this.stone2Wt = calcWeight(+formData.stone2Pct, this.coarseAggWt);
    this.stone3Wt = calcWeight(+formData.stone3Pct, this.coarseAggWt);
    this.stone4Wt = calcWeight(+formData.stone4Pct, this.coarseAggWt);

    this.calcStone1Pct = calcPct(+formData.stone1Kg, this.coarseAggWt);
    this.calcStone2Pct = calcPct(+formData.stone2Kg, this.coarseAggWt);
    this.calcStone3Pct = calcPct(+formData.stone3Kg, this.coarseAggWt);
    this.calcStone4Pct = calcPct(+formData.stone4Kg, this.coarseAggWt);

    this.cementitiousPowerWt = +formData.cementWt + +formData.slagWt + +formData.flyAshWt + +formData.microsilicaWt;
    this.truthWaterColloidRatio = (proportion.quantityMixingWater + this.chemAdmxWt) / this.cementitiousPowerWt;
    this.expectedStr = this.truthWaterColloidRatio * maintenance.paramAStr + maintenance.paramBStr;
    this.proportionUnitWt =
      +formData.quantityMixingWater + this.chemAdmxWt + this.cementitiousPowerWt + this.coarseAggWt + this.fineAggWt;

    this.arrivedDesignStrPct = calcPct(this.expectedStr, proportion.dsgnStr);

    this.cementVolume = calcVolume(formData.cementWt, maintenance.cementSpecifWt);
    this.slagVolume = calcVolume(formData.slagWt, maintenance.slagSpecifWt);
    this.flyAshVolume = calcVolume(formData.flyAshWt, maintenance.flyAshSpecifWt);
    this.microsilicaVolume = calcVolume(formData.microsilicaWt, maintenance.microsilicaSpecifWt);
    this.stone1Volume = calcVolume(this.stone1Wt, this.stone1SpecifWt);
    this.stone2Volume = calcVolume(this.stone2Wt, this.stone2SpecifWt);
    this.stone3Volume = calcVolume(this.stone3Wt, this.stone3SpecifWt);
    this.stone4Volume = calcVolume(this.stone4Wt, this.stone4SpecifWt);
    this.sand1Volume = calcVolume(this.sand1Wt, this.sand1SpecifWt);
    this.sand2Volume = calcVolume(this.sand2Wt, this.sand2SpecifWt);
    this.sand3Volume = calcVolume(this.sand3Wt, this.sand3SpecifWt);
    this.sand4Volume = calcVolume(this.sand4Wt, this.sand4SpecifWt);
    this.proportionUnitVolume =
      +formData.quantityMixingWater / 1000 +
      this.chemAdmxVolume +
      this.cementVolume +
      this.slagVolume +
      this.flyAshVolume +
      this.microsilicaVolume +
      this.stone1Volume +
      this.stone2Volume +
      this.stone3Volume +
      this.stone4Volume +
      this.sand1Volume +
      this.sand2Volume +
      this.sand3Volume +
      this.sand4Volume;
    this.proportionUnitCost =
      calcTotalCost(this.chemAdmx1Wt, maintenance.costChemAdmx1 ?? 0) +
      calcTotalCost(this.chemAdmx2Wt, maintenance.costChemAdmx2 ?? 0) +
      calcTotalCost(this.chemAdmx3Wt, maintenance.costChemAdmx3 ?? 0) +
      calcTotalCost(this.chemAdmx4Wt, maintenance.costChemAdmx4 ?? 0) +
      calcTotalCost(this.chemAdmx5Wt, maintenance.costChemAdmx5 ?? 0) +
      calcTotalCost(this.chemAdmx6Wt, maintenance.costChemAdmx6 ?? 0) +
      calcTotalCost(+formData.cementWt, maintenance.costCement ?? 0) +
      calcTotalCost(+formData.slagWt, maintenance.costSlag ?? 0) +
      calcTotalCost(+formData.flyAshWt, maintenance.costFlyAsh ?? 0) +
      calcTotalCost(+formData.microsilicaWt, maintenance.costMicrosilica ?? 0) +
      calcTotalCost(this.coarseAggWt, maintenance.costCoarseAgg ?? maintenance.costSandStone) +
      calcTotalCost(this.fineAggWt, maintenance.costFineAgg ?? maintenance.costSandStone) +
      maintenance.costXferAndProc;

    this.carbonEmissionMaterial =
      proportion.cementWt * maintenance.cementCEC +
      proportion.slagWt * maintenance.slagCEC +
      proportion.flyAshWt * maintenance.flyAshCEC +
      +formData.sandAndStoneTtlWt * maintenance.sandStoneCEC +
      (this.chemAdmx1Wt +
        this.chemAdmx2Wt +
        this.chemAdmx3Wt +
        this.chemAdmx4Wt +
        this.chemAdmx5Wt +
        this.chemAdmx6Wt) *
        maintenance.chemicalCEC +
      this.recycledWaterWt * maintenance.reclaimedWaterCEC +
      this.tapWaterWt * maintenance.tapWaterCEC;

    this.carbonEmissionLifeCycle =
      this.carbonEmissionMaterial + maintenance.productionCEC + maintenance.transferCEC + maintenance.disposalCEC;
  }
}

const calcAfterValue = (origin: number, percentage: number) => origin * (1 + percentage / 100);
const calcExtraValue = (
  after: number,
  before: number,
  moisturePercentage: number | null,
  sgWabsPercentage: number | null,
) => after * (((moisturePercentage ?? 0) - (sgWabsPercentage ?? 0)) / 100) - (after - before);

interface SandAndStone {
  sixStoneWt: number;
  threeStoneWt: number;
  sand1Wt: number;
  sand2Wt: number;
}

export class CalcSafeWater extends CalcBaseProportion {
  coarseAggWt: number;

  // ** display **
  afterStone1Wt: number;
  afterStone2Wt: number;
  afterSand1Wt: number;
  afterSand2Wt: number;
  afterQuantityMixingWater: number;

  afterChemAdmx1Wt: number;
  afterChemAdmx2Wt: number;
  afterChemAdmx3Wt: number;
  afterChemAdmx4Wt: number;
  afterChemAdmx5Wt: number;
  afterChemAdmx6Wt: number;

  extraStone1Wt: number;
  extraStone2Wt: number;
  extraSand1Wt: number;
  extraSand2Wt: number;

  mixerWaterFix: number;
  truthWaterColloidRatio: number;
  expectedStr: number;
  arrivedDesignStrPct: number;
  // ****

  constructor(
    formData: ImportSafeWater,
    sandAndStoneData: SandAndStone,
    sieveAndSgwabs: SieveAndSgwabsByMixer,
    proportion: ProportionResponse,
    maintenance: ProportionMaintenanceResponse,
  ) {
    super(proportion, maintenance);
    this.coarseAggWt = maintenance.coraseAggCompDryWt * proportion.coarseAggCompVolumeRatio;
    this.afterStone1Wt = calcAfterValue(sandAndStoneData.sixStoneWt, formData.stone1SurfaceMoistureContent);
    this.afterStone2Wt = calcAfterValue(sandAndStoneData.threeStoneWt, formData.stone2SurfaceMoistureContent);
    this.afterSand1Wt = calcAfterValue(sandAndStoneData.sand1Wt, formData.sand1SurfaceMoistureContent);
    this.afterSand2Wt = calcAfterValue(sandAndStoneData.sand2Wt, formData.sand2SurfaceMoistureContent);

    this.afterQuantityMixingWater =
      proportion.quantityMixingWater -
      (this.afterStone1Wt - sandAndStoneData.sixStoneWt) -
      (this.afterStone2Wt - sandAndStoneData.threeStoneWt) -
      (this.afterSand1Wt - sandAndStoneData.sand1Wt) -
      (this.afterSand2Wt - sandAndStoneData.sand2Wt);
    this.afterChemAdmx1Wt = 0;
    this.afterChemAdmx2Wt = 0;
    this.afterChemAdmx3Wt = 0;
    this.afterChemAdmx4Wt = 0;
    this.afterChemAdmx5Wt = 0;
    this.afterChemAdmx6Wt = 0;

    this.extraStone1Wt = calcExtraValue(
      this.afterStone1Wt,
      sandAndStoneData.sixStoneWt,
      sieveAndSgwabs.sieveAnlys.stone1,
      sieveAndSgwabs.sgwabsAnlys.stone1,
    );
    this.extraStone2Wt = calcExtraValue(
      this.afterStone2Wt,
      sandAndStoneData.threeStoneWt,
      sieveAndSgwabs.sieveAnlys.stone2,
      sieveAndSgwabs.sgwabsAnlys.stone2,
    );
    this.extraSand1Wt = calcExtraValue(
      this.afterSand1Wt,
      sandAndStoneData.sand1Wt,
      sieveAndSgwabs.sieveAnlys.sand1,
      sieveAndSgwabs.sgwabsAnlys.sand1,
    );
    this.extraSand2Wt = calcExtraValue(
      this.afterSand2Wt,
      sandAndStoneData.sand2Wt,
      sieveAndSgwabs.sieveAnlys.sand2,
      sieveAndSgwabs.sgwabsAnlys.sand2,
    );

    this.mixerWaterFix =
      proportion.quantityMixingWater + this.extraStone1Wt + this.extraStone2Wt + this.extraSand1Wt + this.extraSand2Wt;
    this.truthWaterColloidRatio =
      (this.mixerWaterFix +
        this.chemAdmx1Wt +
        this.chemAdmx2Wt +
        this.chemAdmx3Wt +
        this.chemAdmx4Wt +
        this.chemAdmx5Wt +
        this.chemAdmx6Wt) /
      this.cementitiousWt;
    this.expectedStr = this.truthWaterColloidRatio * maintenance.paramAStr + maintenance.paramBStr;
    this.arrivedDesignStrPct = this.expectedStr / proportion.dsgnStr;
  }
}
