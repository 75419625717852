import YatungPage from '@Src/_basic/components/YatungPage';
import { CreateDemandOrderRequest } from '@Src/_basic/object/DemandOrderType';
import {
  CreateExptScheduleFormType,
  CreateExptScheduleRequest,
  PeriodDayEnum,
} from '@Src/_basic/object/ExptScheduleType';
import { CreateTestRequest } from '@Src/_basic/object/TestType';
import { DemandOrderApi } from '@Src/_basic/protocol/demandOrder/DemandOrderApi';
import { MaterialReceiptsApi } from '@Src/_basic/protocol/materialReceipt/MaterialReceiptsApi';
import { SampleApi } from '@Src/_basic/protocol/sample/SampleApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useExptSchedule } from '@Src/redux/exptSchedule/exptScheduleActions';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import CreateExptScheduleForm from '../components/CreateExptScheduleForm';

export default function CreateExptScheduleScreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { userData, userAreaOptionsData } = useAuth();
  const { createExptSchedule } = useExptSchedule();

  const goToList = useCallback(() => {
    navigate(`/view/record/status-list`);
  }, [navigate]);

  const initialValues: CreateExptScheduleFormType = useMemo(() => {
    return {
      areaId: userAreaOptionsData.map((option) => option.value)[0] || 0,
      factoryId: userAreaOptionsData.map((option) => option.factories[0].value)[0] || 0,
      creater: userData?.account || '',
      title: '',
      isRequirement: 'true',
      isTest: 'true',
      exptTypeId: 0,
      memo: '',
      sampleSourceId: undefined,
      sourceId: undefined,
      specificationId: undefined,
      storageId: undefined,
      sampleId: undefined,
      exptItems: [],
      period: {
        periodType: undefined,
        periodWeekDays: [
          {
            weekDay: PeriodDayEnum.MONDAY,
            length: 1,
          },
        ],
        periodDates: [
          {
            startMonth: 0,
            startDay: 1,
            endMonth: 11,
            endDay: 31,
          },
        ],
      },
      startTime: new Date().setHours(0, 0, 0, 0),
      endTime: new Date().setHours(23, 59, 59, 999) + 1000 * 60 * 60 * 24 * 1,
    };
  }, [userData?.account]);

  const createDemandOrder = useCallback(
    (request: CreateDemandOrderRequest) => {
      DemandOrderApi.createDemandOrder(request, goToList);
    },
    [goToList],
  );

  const createExpt = useCallback(
    (request: CreateTestRequest) => {
      const stone = [1, 2, 71]
      const sand = [3, 4, 64, 65, 66, 67, 68, 69, 70]
      const specification = () => {
        if (request.specificationId !== undefined && stone.includes(request.specificationId)) {
          return 2;
        } else {
          return 1;
        }
      }

      const currentTime = moment().format('YYYY-MM-DD HH:mm');
      const unixCurrentTime = String(moment(currentTime, 'YYYY-MM-DD HH:mm').unix()) + '000';
      const DATE = currentTime.slice(0, 10).replace('-', '').replace('-', '');
      const SERIALNO = moment().format('HHmmss').padStart(6, '0');
      const typeCode = specification.toString() === '1' ? '4' : '5';
      // const ID = 'B' + getCode + DATE + factoryCode + branchCode + stationType + stationCode + '0' + typeCode + SERIALNO;
      const MATERIAL_RECEIPT_ID = 'B' + 'C' + DATE + 'T' + '0' + 'W' + '1' + '0' + typeCode + SERIALNO;
      // const ID = 'S'           + DATE + factoryCode + branchCode + stationType + stationCode + '0' + typeCode + SERIALNO;
      const SAMPLE_ID = 'S' + DATE + 'T' + '0' + 'W' + '1' + '0' + typeCode + SERIALNO;

      request = {
        ...request,
        supplierId: specification() === 1 ? 103 : 105,
        materialReceiptId: MATERIAL_RECEIPT_ID,
        sampleId: SAMPLE_ID,
      }

      MaterialReceiptsApi.createReceipt(
        {
          id: MATERIAL_RECEIPT_ID,
          grossWeight: '99999', // 總重 99999 可變更
          plateNumber: Math.floor(Math.random() * 10000).toString().padStart(4, '0'), // 亂數4碼
          specificationId: request.specificationId?.toString() ?? '',
          supplierId: request.supplierId?.toString() ?? '',
          storageId: request.storageId?.toString() ?? '',
          billOfLandingCode: '',
          deliveryTicketCode: '',
          typeId: specification().toString(), // 1 沙 2 石
          sourceId: request.sourceId?.toString() ?? '',
          createrId: '1266',
          factoryId: request.factoryId?.toString() ?? '25',
          stationId: '1109',
          exportValue: '',
          createTime: unixCurrentTime,
        },
        (_material_receipt_data) => {
          SampleApi.ReceiptMaterialPostSample(
            {
              id: SAMPLE_ID,
              samplerId: '1266', // 創建的createrId(固定),
              materialReceiptId: MATERIAL_RECEIPT_ID,
              sampleSourceId: request.sampleSourceId?.toString() ?? '',
              specificationId: request.specificationId?.toString() ?? '',
              sourceId: request.sourceId?.toString() ?? '',
              storageId: request.storageId?.toString() ?? '',
              supplierId: request.supplierId?.toString() ?? '',
              factoryId: request.factoryId?.toString() ?? '25',
              createTime: unixCurrentTime,
            },
            (_sample_data) => {
              TestApi.createTest(request, goToList);
            }
          );
        },
      );
    },
    [goToList],
  );

  const formDataToCreateExptScheduleRequest = useCallback((formData: CreateExptScheduleFormType) => {
    return {
      ...formData,
      isRequirement: formData.isRequirement === 'true',
      isTest: formData.isTest === 'true',
    };
  }, []);

  const formDataToCreateDemandOrderRequest = useCallback(
    (formData: CreateExptScheduleFormType) => {
      return {
        createrId: userData?.account || '0',
        memo: formData.memo,
        materialReceiptId: undefined,
        sampleId: formData.sampleId,
        storageId: formData.storageId,
        specificationId: formData.specificationId,
        sourceId: formData.sourceId,
        sampleSourceId: formData.sampleSourceId,
        supplierId: undefined,
        exptTypeId: formData.exptTypeId,
        specimenId: undefined,
        factoryId: formData.factoryId,
        startTime: new Date(formData.startTime).getTime(),
        endTime: new Date(formData.endTime).getTime(),
        requestItems: formData.exptItems?.map((item) => ({
          experimentItemId: item.exptItemId,
          operatorId: item.operator,
        })),
      };
    },
    [userData],
  );

  const formDataToCreateExptRequest = useCallback(
    (formData: CreateExptScheduleFormType) => {
      return {
        createrId: userData?.account || '0',
        memo: formData.memo,
        sampleId: formData.sampleId,
        storageId: formData.storageId,
        specificationId: formData.specificationId,
        exptTypeId: formData.exptTypeId,
        areaId: formData.areaId,
        factoryId: formData.factoryId,
        sourceId: formData.sourceId,
        sampleSourceId: formData.sampleSourceId,
        requestItems: formData.exptItems?.map((item) => ({
          experimentItemId: item.exptItemId,
          operatorId: userData?.account,
        })),
      };
    },
    [userData],
  );

  const handleSubmit = useCallback(
    (values: CreateExptScheduleFormType) => {
      const request: CreateExptScheduleRequest = formDataToCreateExptScheduleRequest(values);
      if (request.isRequirement) {
        if (request.period?.periodType) {
          createExptSchedule(request, goToList);
        } else {
          createDemandOrder(formDataToCreateDemandOrderRequest(values));
        }
      } else {
        createExpt(formDataToCreateExptRequest(values));
      }
    },
    [formDataToCreateExptScheduleRequest, formDataToCreateDemandOrderRequest, formDataToCreateExptRequest],
  );

  const handleSubmitByTest = useCallback(
    (values: CreateExptScheduleFormType) => {
      const request: CreateExptScheduleRequest = formDataToCreateExptScheduleRequest(values);
      if (request.isTest) {
        createExpt(formDataToCreateExptRequest(values));
      }
    },
    [formDataToCreateExptScheduleRequest, formDataToCreateExptRequest],
  );
  return (
    <YatungPage
      title={i18T(`QUALITY_MANAGE.SIDE_MENU.EXPT_MANAGEMENT.EXPT_SCHEDULE.CREATE`)}
      body={
        <CreateExptScheduleForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onSubmitByTest={handleSubmitByTest}
        />
      }
    />
  );
}
