import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Stroke(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 33 25" >
        <path fillRule="evenodd" clipRule="evenodd" d="M29.7376 3.85036C30.483 2.58361 29.5514 1 28.0624 1H1L2.16874 2.7265C2.39266 3.05751 2.77059 3.25654 3.17592 3.25654H24.6951C25.7826 3.25654 26.4628 4.38573 25.9186 5.33655L16.8134 21.249C16.2692 22.1998 14.9087 22.1998 14.3644 21.249L6.40247 7.41332H11.2456C11.7105 7.41332 12.1337 7.15189 12.335 6.74109L13.1702 5.03801H2.16496L13.9232 25.0499C14.6677 26.3167 16.53 26.3167 17.2745 25.0499L29.7376 3.85036ZM14.1819 24.8979C14.8104 25.9674 16.3873 25.9674 17.0158 24.8979L29.479 3.69832C30.1018 2.63989 29.3291 1.3 28.0624 1.3H1.56536L2.41717 2.55833C2.58441 2.80554 2.86858 2.95654 3.17592 2.95654H24.6951C26.005 2.95654 26.8416 4.32787 26.179 5.48555C26.179 5.48556 26.179 5.48553 26.179 5.48555L17.0738 21.398C16.4144 22.55 14.7638 22.5501 14.1043 21.3983C14.1042 21.3982 14.1043 21.3984 14.1043 21.3983L5.8837 7.11332H11.2456C11.5981 7.11332 11.9157 6.91512 12.0656 6.60911L12.6889 5.33801H2.68918L14.1819 24.8979Z" fill="#7C878E"/>
        <path d="M26.179 5.48555C26.8416 4.32787 26.005 2.95654 24.6951 2.95654H3.17592C2.86858 2.95654 2.58441 2.80554 2.41717 2.55833L1.56536 1.3H28.0624C29.3291 1.3 30.1018 2.63989 29.479 3.69832L17.0158 24.8979C16.3873 25.9674 14.8104 25.9674 14.1819 24.8979L2.68918 5.33801H12.6889L12.0656 6.60911C11.9157 6.91512 11.5981 7.11332 11.2456 7.11332H5.8837L14.1043 21.3983M26.179 5.48555C26.179 5.48553 26.179 5.48556 26.179 5.48555ZM26.179 5.48555L17.0738 21.398C16.4144 22.55 14.7638 22.5501 14.1043 21.3983M14.1043 21.3983C14.1043 21.3984 14.1042 21.3982 14.1043 21.3983ZM29.7376 3.85036C30.483 2.58361 29.5514 1 28.0624 1H1L2.16874 2.7265C2.39266 3.05751 2.77059 3.25654 3.17592 3.25654H24.6951C25.7826 3.25654 26.4628 4.38573 25.9186 5.33655L16.8134 21.249C16.2692 22.1998 14.9087 22.1998 14.3644 21.249L6.40247 7.41332H11.2456C11.7105 7.41332 12.1337 7.15189 12.335 6.74109L13.1702 5.03801H2.16496L13.9232 25.0499C14.6677 26.3167 16.53 26.3167 17.2745 25.0499L29.7376 3.85036Z" stroke="#7C878E" strokeWidth="0.5"/>
    </SvgIcon>
  );
}
