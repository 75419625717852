import { PageableResponse } from '@Src/_basic/object/ApiType';
import {
  CarbonEmissionHeader,
  CarbonEmissionList,
  SearchCarbonEmissionParams,
  SearchPageableCarbonEmissionParams,
} from '@Src/_basic/object/CarbonEmissionType';
import { CarbonEmissionApi } from '@Src/_basic/protocol/carbonEmission/CarbonEmissionApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { carbonEmissionSlice } from './carbonEmissionSlice';

export function useCarbonEmission() {
  const { header, pageableCarbonEmission } = useSelector((state: RootState) => state.carbonEmission);
  const dispatch = useDispatch();

  const getCarbonEmissionHeader = (params: SearchCarbonEmissionParams) => {
    CarbonEmissionApi.getCarbonEmissionHeaderBySearch(params, setHeader);
  };

  const getPageableCarbonEmissionBySearch = (params: SearchPageableCarbonEmissionParams) => {
    CarbonEmissionApi.getPageableCarbonEmissionBySearch(params, setPageableCarbonEmission);
  };

  const getCancelablePageableCarbonEmissionBySearch = (params: SearchPageableCarbonEmissionParams) => {
    CarbonEmissionApi.getCancelablePageableProductionHistoriesBySearch(params, setPageableCarbonEmission);
  };

  const setHeader = (data: CarbonEmissionHeader) => dispatch(carbonEmissionSlice.actions.setHeader(data));

  const clearHeader = () => dispatch(carbonEmissionSlice.actions.clearHeader());

  const setPageableCarbonEmission = (data: PageableResponse<CarbonEmissionList>) =>
    dispatch(carbonEmissionSlice.actions.setPageableCarbonEmission(data));

  const clearPageableCarbonEmission = () => dispatch(carbonEmissionSlice.actions.clearPageableCarbonEmission());

  return {
    header,
    getCarbonEmissionHeader,
    clearHeader,
    pageableCarbonEmission,
    getPageableCarbonEmissionBySearch,
    getCancelablePageableCarbonEmissionBySearch,
    clearPageableCarbonEmission,
  };
}
