import React from 'react';

export default function Paper() {
  return (
    <svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.2133 8.21333L17.7733 1.77333C17.28 1.28 16.6 1 15.8933 1H3.66667C2.2 1 1 2.2 1 3.66667V22.3333C1 23.8 2.2 25 3.66667 25H22.3333C23.8 25 25 23.8 25 22.3333V10.1067C25 9.4 24.72 8.72 24.2133 8.21333ZM6.33333 6.33333H15.6667V9H6.33333V6.33333ZM19.6667 19.6667H6.33333V17H19.6667V19.6667ZM19.6667 14.3333H6.33333V11.6667H19.6667V14.3333Z"
        stroke="white"
      />
    </svg>
  );
}
