import {
  ChildrenTypography,
  TableHeaderTitle,
  TestCardContainer,
  TestVerifyResultStyles,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import TestTable from '@Src/_basic/components/YatungTest/TestTable';
import { checkIsNaN } from '@Src/_basic/helpers/CalcHelper';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema, notRequiredNumberSchema } from '@Src/_basic/helpers/TestForm';
import { isCustomStandardPassed, isLegalStandardPassed, isRejected } from '@Src/_basic/helpers/VerifyResultHelper';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  CoarseAggSgWabs,
  ImportCoarseAggSgWabs,
  PutCoarseAggSgWabsRequest,
} from '@Src/_basic/object/test/sandRock/CoarseAggSgWabsType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { CSSProperties, ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface TableByTestProps {
  colorIndex?: number;
  colorIndexes?: Array<number>;
  style?: CSSProperties;
  list?: Array<string | undefined | React.ReactNode>;
}

const TableByTest = ({ colorIndex, colorIndexes, style, list }: TableByTestProps) => {
  const styleColors = ['#5A60AE', '#81B29A', '#E07A5F', '#6DC3F6'];
  const verifyResultColor = ['#FFFFFF', '#70C030', '#FF0002'];

  return (
    <Stack flexDirection="row" justifyContent="space-between" alignItems="center" py={1} sx={style}>
      {list?.map((value, index) => (
        <ChildrenTypography
          key={index}
          sx={{
            ...TestVerifyResultStyles.normal,
            ...(colorIndex !== undefined && { color: styleColors[colorIndex] }),
            ...(colorIndexes !== undefined &&
              colorIndexes[index] !== 0 && { color: verifyResultColor[colorIndexes[index]] }),
          }}
        >
          {typeof value === 'string' ? formatNumber(value) : typeof value === undefined ? '' : value}
        </ChildrenTypography>
      ))}
    </Stack>
  );
};

interface Props {
  testDetail: CoarseAggSgWabs;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  firstUnderwaterWeight: basicNumberSchema,
  firstSaturatedSurfaceDryWeight: basicNumberSchema,
  firstOvenDryWeight: basicNumberSchema,

  secondUnderwaterWeight: notRequiredNumberSchema,
  secondSaturatedSurfaceDryWeight: notRequiredNumberSchema,
  secondOvenDryWeight: notRequiredNumberSchema,

  thirdUnderwaterWeight: notRequiredNumberSchema,
  thirdSaturatedSurfaceDryWeight: notRequiredNumberSchema,
  thirdOvenDryWeight: notRequiredNumberSchema,

  fourthUnderwaterWeight: notRequiredNumberSchema,
  fourthSaturatedSurfaceDryWeight: notRequiredNumberSchema,
  fourthOvenDryWeight: notRequiredNumberSchema,
});

function ExportCoarseAggSgWabsTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();

  const initialValues = {
    firstUnderwaterWeight: testDetail?.firstUnderwaterWeight,
    firstSaturatedSurfaceDryWeight: testDetail?.firstSaturatedSurfaceDryWeight,
    firstOvenDryWeight: testDetail?.firstOvenDryWeight,

    secondUnderwaterWeight: testDetail?.secondUnderwaterWeight,
    secondSaturatedSurfaceDryWeight: testDetail?.secondSaturatedSurfaceDryWeight,
    secondOvenDryWeight: testDetail?.secondOvenDryWeight,

    thirdUnderwaterWeight: testDetail?.thirdUnderwaterWeight,
    thirdSaturatedSurfaceDryWeight: testDetail?.thirdSaturatedSurfaceDryWeight,
    thirdOvenDryWeight: testDetail?.thirdOvenDryWeight,

    fourthUnderwaterWeight: testDetail?.fourthUnderwaterWeight,
    fourthSaturatedSurfaceDryWeight: testDetail?.fourthSaturatedSurfaceDryWeight,
    fourthOvenDryWeight: testDetail?.fourthOvenDryWeight,
  };

  const [edit, setEdit] = useState<boolean>(false);

  const isLegalPassed = useMemo(() => {
    return isLegalStandardPassed(testDetail?.verifyPassedResults);
  }, [testDetail?.verifyPassedResults]);

  const isCustomPassed = useMemo(() => {
    return isCustomStandardPassed(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const isCustomRejected = useMemo(() => {
    return isRejected(testDetail?.verifyCustomResults);
  }, [testDetail?.verifyCustomResults]);

  const verifyResultText: Array<string> = useMemo(() => {
    const tempText: Array<string> = [];
    if (isLegalPassed && isCustomPassed && !isCustomRejected) {
      tempText.push(i18T(`TEST.BASE.NORMAL`));
    } else {
      isLegalPassed ? tempText.push(i18T(`TEST.BASE.CNS_NORMAL`)) : tempText.push(i18T(`TEST.BASE.CNS_ABNORMAL`));

      if (isCustomRejected) {
        tempText.push(i18T(`TEST.BASE.CUSTOM_REJECTED`));
      } else {
        isCustomPassed
          ? tempText.push(i18T(`TEST.BASE.CUSTOM_NORMAL`))
          : tempText.push(i18T(`TEST.BASE.CUSTOM_ABNORMAL`));
      }
    }
    return tempText;
  }, [i18T, isLegalPassed, isCustomPassed, isCustomRejected]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportCoarseAggSgWabs = {
        firstUnderwaterWeight: checkIsNaN(+values.firstUnderwaterWeight),
        firstSaturatedSurfaceDryWeight: checkIsNaN(+values.firstSaturatedSurfaceDryWeight),
        firstOvenDryWeight: checkIsNaN(+values.firstOvenDryWeight),

        ...(values.secondUnderwaterWeight &&
          values.secondSaturatedSurfaceDryWeight &&
          values.secondOvenDryWeight && {
            secondUnderwaterWeight: checkIsNaN(+values.secondUnderwaterWeight),
            secondSaturatedSurfaceDryWeight: checkIsNaN(+values.secondSaturatedSurfaceDryWeight),
            secondOvenDryWeight: checkIsNaN(+values.secondOvenDryWeight),
          }),

        ...(values.thirdUnderwaterWeight &&
          values.thirdSaturatedSurfaceDryWeight &&
          values.thirdOvenDryWeight && {
            thirdUnderwaterWeight: checkIsNaN(+values.thirdUnderwaterWeight),
            thirdSaturatedSurfaceDryWeight: checkIsNaN(+values.thirdSaturatedSurfaceDryWeight),
            thirdOvenDryWeight: checkIsNaN(+values.thirdOvenDryWeight),
          }),

        ...(values.fourthUnderwaterWeight &&
          values.fourthSaturatedSurfaceDryWeight &&
          values.fourthOvenDryWeight && {
            fourthUnderwaterWeight: checkIsNaN(+values.fourthUnderwaterWeight),
            fourthSaturatedSurfaceDryWeight: checkIsNaN(+values.fourthSaturatedSurfaceDryWeight),
            fourthOvenDryWeight: checkIsNaN(+values.fourthOvenDryWeight),
          }),
      };

      TestApi.putTest<PutCoarseAggSgWabsRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const showFirstTable = useMemo(
    () => (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" py={2}>
          <TableHeaderTitle>{`${i18T(`TEST.COARSE_AGG_SG_WABS.UNDER_WATER_WEIGHT`)}(g)`}</TableHeaderTitle>
          <TableHeaderTitle>{`${i18T(`TEST.COARSE_AGG_SG_WABS.SATURATED_SURFACE_DRY_WEIGHT`)}(g)`}</TableHeaderTitle>
        </Stack>

        {edit ? (
          <Stack>
            <TableByTest
              colorIndex={0}
              list={[
                <Stack key={'firstUnderwaterWeight'} flexDirection="row" pr={10}>
                  <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_UNDER_WATER_WEIGHT`, {
                    number: 1,
                  })}(g)`}</ChildrenTypography>
                  <LabelInput
                    valueWidth="150px"
                    value={formik.values.firstUnderwaterWeight}
                    isError={!!(formik.touched.firstUnderwaterWeight && formik.errors.firstUnderwaterWeight)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('firstUnderwaterWeight', e.target.value)
                    }
                  />
                </Stack>,
                <Stack key={'firstSaturatedSurfaceDryWeight'} flexDirection="row" pr={10}>
                  <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_SATURATED_SURFACE_DRY_WEIGHT`, {
                    number: 1,
                  })}(g)`}</ChildrenTypography>
                  <LabelInput
                    valueWidth="150px"
                    value={formik.values.firstSaturatedSurfaceDryWeight}
                    isError={
                      !!(formik.touched.firstSaturatedSurfaceDryWeight && formik.errors.firstSaturatedSurfaceDryWeight)
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('firstSaturatedSurfaceDryWeight', e.target.value)
                    }
                  />
                </Stack>,
              ]}
            />
            {testDetail?.secondUnderwaterWeight !== null && (
              <TableByTest
                colorIndex={1}
                list={[
                  <Stack key={'secondUnderwaterWeight'} flexDirection="row" pr={10}>
                    <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_UNDER_WATER_WEIGHT`, {
                      number: 2,
                    })}(g)`}</ChildrenTypography>
                    <LabelInput
                      valueWidth="150px"
                      value={formik.values.secondUnderwaterWeight}
                      isError={!!(formik.touched.secondUnderwaterWeight && formik.errors.secondUnderwaterWeight)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('secondUnderwaterWeight', e.target.value)
                      }
                    />
                  </Stack>,
                  <Stack key={'secondSaturatedSurfaceDryWeight'} flexDirection="row" pr={10}>
                    <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_SATURATED_SURFACE_DRY_WEIGHT`, {
                      number: 2,
                    })}(g)`}</ChildrenTypography>
                    <LabelInput
                      valueWidth="150px"
                      value={formik.values.secondSaturatedSurfaceDryWeight}
                      isError={
                        !!(
                          formik.touched.secondSaturatedSurfaceDryWeight &&
                          formik.errors.secondSaturatedSurfaceDryWeight
                        )
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('secondSaturatedSurfaceDryWeight', e.target.value)
                      }
                    />
                  </Stack>,
                ]}
              />
            )}
            {testDetail?.thirdUnderwaterWeight !== null && (
              <TableByTest
                colorIndex={2}
                list={[
                  <Stack key={'thirdUnderwaterWeight'} flexDirection="row" pr={10}>
                    <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_UNDER_WATER_WEIGHT`, {
                      number: 3,
                    })}(g)`}</ChildrenTypography>
                    <LabelInput
                      valueWidth="150px"
                      value={formik.values.thirdUnderwaterWeight}
                      isError={!!(formik.touched.thirdUnderwaterWeight && formik.errors.thirdUnderwaterWeight)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('thirdUnderwaterWeight', e.target.value)
                      }
                    />
                  </Stack>,
                  <Stack key={'thirdSaturatedSurfaceDryWeight'} flexDirection="row" pr={10}>
                    <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_SATURATED_SURFACE_DRY_WEIGHT`, {
                      number: 3,
                    })}(g)`}</ChildrenTypography>
                    <LabelInput
                      valueWidth="150px"
                      value={formik.values.thirdSaturatedSurfaceDryWeight}
                      isError={
                        !!(
                          formik.touched.thirdSaturatedSurfaceDryWeight && formik.errors.thirdSaturatedSurfaceDryWeight
                        )
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('thirdSaturatedSurfaceDryWeight', e.target.value)
                      }
                    />
                  </Stack>,
                ]}
              />
            )}
            {testDetail?.fourthUnderwaterWeight !== null && (
              <TableByTest
                colorIndex={3}
                list={[
                  <Stack key={'fourthUnderwaterWeight'} flexDirection="row" pr={10}>
                    <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_UNDER_WATER_WEIGHT`, {
                      number: 4,
                    })}(g)`}</ChildrenTypography>
                    <LabelInput
                      valueWidth="150px"
                      value={formik.values.fourthUnderwaterWeight}
                      isError={!!(formik.touched.fourthUnderwaterWeight && formik.errors.fourthUnderwaterWeight)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('fourthUnderwaterWeight', e.target.value)
                      }
                    />
                  </Stack>,
                  <Stack key={'fourthSaturatedSurfaceDryWeight'} flexDirection="row" pr={10}>
                    <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_SATURATED_SURFACE_DRY_WEIGHT`, {
                      number: 4,
                    })}(g)`}</ChildrenTypography>
                    <LabelInput
                      valueWidth="150px"
                      value={formik.values.fourthSaturatedSurfaceDryWeight}
                      isError={
                        !!(
                          formik.touched.fourthSaturatedSurfaceDryWeight &&
                          formik.errors.fourthSaturatedSurfaceDryWeight
                        )
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('fourthSaturatedSurfaceDryWeight', e.target.value)
                      }
                    />
                  </Stack>,
                ]}
              />
            )}
          </Stack>
        ) : (
          <Stack>
            <TableByTest
              colorIndex={0}
              list={[
                formatNumber(testDetail?.firstUnderwaterWeight?.toFixed(2)),
                formatNumber(testDetail?.firstSaturatedSurfaceDryWeight?.toFixed(2)),
              ]}
            />
            {testDetail?.secondUnderwaterWeight !== null && (
              <TableByTest
                colorIndex={1}
                list={[
                  formatNumber(testDetail?.secondUnderwaterWeight?.toFixed(2) as string),
                  formatNumber(testDetail?.secondSaturatedSurfaceDryWeight?.toFixed(2) as string),
                ]}
              />
            )}
            {testDetail?.secondUnderwaterWeight !== null && (
              <TableByTest
                colorIndex={1}
                list={[
                  formatNumber(testDetail?.secondUnderwaterWeight?.toFixed(2) as string),
                  formatNumber(testDetail?.secondSaturatedSurfaceDryWeight?.toFixed(2) as string),
                ]}
              />
            )}
            {testDetail?.thirdUnderwaterWeight !== null && (
              <TableByTest
                colorIndex={2}
                list={[
                  formatNumber(testDetail?.thirdUnderwaterWeight?.toFixed(2) as string),
                  formatNumber(testDetail?.thirdSaturatedSurfaceDryWeight?.toFixed(2) as string),
                ]}
              />
            )}
            {testDetail?.fourthUnderwaterWeight !== null && (
              <TableByTest
                colorIndex={3}
                list={[
                  formatNumber(testDetail?.fourthUnderwaterWeight?.toFixed(2) as string),
                  formatNumber(testDetail?.fourthSaturatedSurfaceDryWeight?.toFixed(2) as string),
                ]}
              />
            )}
            <TableByTest
              style={{ borderTop: '1px solid gray' }}
              list={[
                formatNumber(testDetail?.averageUnderwaterWeight?.toFixed(2)),
                formatNumber(testDetail?.averageSaturatedSurfaceDryWeight?.toFixed(2)),
              ]}
            />
          </Stack>
        )}
      </>
    ),
    [
      i18T,
      testDetail?.firstSaturatedSurfaceDryWeight,
      testDetail?.firstUnderwaterWeight,
      testDetail?.fourthSaturatedSurfaceDryWeight,
      testDetail?.fourthUnderwaterWeight,
      testDetail?.secondSaturatedSurfaceDryWeight,
      testDetail?.secondUnderwaterWeight,
      testDetail?.thirdSaturatedSurfaceDryWeight,
      testDetail?.thirdUnderwaterWeight,
      testDetail?.averageSaturatedSurfaceDryWeight,
      testDetail?.averageUnderwaterWeight,
      edit,
      formik,
    ],
  );

  const showSecondTable = useMemo(
    () => (
      <>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" py={2}>
          <TableHeaderTitle>{`${i18T(`TEST.COARSE_AGG_SG_WABS.OVEN_DRY_WEIGHT`)}(g)`}</TableHeaderTitle>
          {!edit && <TableHeaderTitle>{`${i18T(`TEST.COARSE_AGG_SG_WABS.SPECIFIC_GRAVITY`)}`}</TableHeaderTitle>}
          {!edit && <TableHeaderTitle>{`${i18T(`TEST.COARSE_AGG_SG_WABS.WATER_ABSORPTION`)}(%)`}</TableHeaderTitle>}
        </Stack>

        {edit ? (
          <Stack>
            <TableByTest
              colorIndex={0}
              list={[
                <Stack key={'firstOvenDryWeight'} pr={10} flexDirection="row" width={'50%'}>
                  <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_OVEN_DRY_WEIGHT`, {
                    number: 1,
                  })}(g)`}</ChildrenTypography>
                  <LabelInput
                    valueWidth="150px"
                    value={formik.values.firstOvenDryWeight}
                    isError={!!(formik.touched.firstOvenDryWeight && formik.errors.firstOvenDryWeight)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('firstOvenDryWeight', e.target.value)
                    }
                  />
                </Stack>,
              ]}
            />
            {testDetail?.secondOvenDryWeight !== null && (
              <TableByTest
                colorIndex={1}
                list={[
                  <Stack key={'secondOvenDryWeight'} flexDirection="row" pr={10}>
                    <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_OVEN_DRY_WEIGHT`, {
                      number: 2,
                    })}(g)`}</ChildrenTypography>
                    <LabelInput
                      valueWidth="150px"
                      value={formik.values.secondOvenDryWeight}
                      isError={!!(formik.touched.secondOvenDryWeight && formik.errors.secondOvenDryWeight)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('secondOvenDryWeight', e.target.value)
                      }
                    />
                  </Stack>,
                ]}
              />
            )}
            {testDetail?.thirdOvenDryWeight !== null && (
              <TableByTest
                colorIndex={2}
                list={[
                  <Stack key={'thirdOvenDryWeight'} flexDirection="row" pr={10}>
                    <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_OVEN_DRY_WEIGHT`, {
                      number: 3,
                    })}(g)`}</ChildrenTypography>
                    <LabelInput
                      valueWidth="150px"
                      value={formik.values.thirdOvenDryWeight}
                      isError={!!(formik.touched.thirdOvenDryWeight && formik.errors.thirdOvenDryWeight)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('thirdOvenDryWeight', e.target.value)
                      }
                    />
                  </Stack>,
                ]}
              />
            )}
            {testDetail?.fourthOvenDryWeight !== null && (
              <TableByTest
                colorIndex={3}
                list={[
                  <Stack key={'fourthOvenDryWeight'} flexDirection="row" pr={10}>
                    <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_SG_WABS.NUMBER_OVEN_DRY_WEIGHT`, {
                      number: 4,
                    })}(g)`}</ChildrenTypography>
                    <LabelInput
                      valueWidth="150px"
                      value={formik.values.fourthOvenDryWeight}
                      isError={!!(formik.touched.fourthOvenDryWeight && formik.errors.fourthOvenDryWeight)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue('fourthOvenDryWeight', e.target.value)
                      }
                    />
                  </Stack>,
                ]}
              />
            )}
          </Stack>
        ) : (
          <Stack>
            <TableByTest
              colorIndex={0}
              list={[
                testDetail?.firstOvenDryWeight?.toFixed(2),
                checkIsNaN(testDetail?.firstSpecificWeight)?.toFixed(2),
                checkIsNaN(testDetail?.firstWaterAbsorption)?.toFixed(2),
              ]}
            />

            {testDetail?.secondOvenDryWeight !== null && (
              <TableByTest
                colorIndex={1}
                list={[
                  testDetail?.secondOvenDryWeight?.toFixed(2),
                  checkIsNaN(testDetail?.secondSpecificWeight)?.toFixed(2),
                  checkIsNaN(testDetail?.secondWaterAbsorption)?.toFixed(2),
                ]}
              />
            )}

            {testDetail?.thirdOvenDryWeight !== null && (
              <TableByTest
                colorIndex={2}
                list={[
                  testDetail?.thirdOvenDryWeight?.toFixed(2),
                  checkIsNaN(testDetail?.thirdSpecificWeight)?.toFixed(2),
                  checkIsNaN(testDetail?.thirdWaterAbsorption)?.toFixed(2),
                ]}
              />
            )}

            {testDetail?.fourthOvenDryWeight !== null && (
              <TableByTest
                colorIndex={3}
                list={[
                  testDetail?.fourthOvenDryWeight?.toFixed(2),
                  checkIsNaN(testDetail?.fourthSpecificWeight)?.toFixed(2),
                  checkIsNaN(testDetail?.fourthWaterAbsorption)?.toFixed(2),
                ]}
              />
            )}

            <TableByTest
              style={{ borderTop: '1px solid gray' }}
              colorIndexes={
                testDetail?.verifyPassedResults
                  ? [
                      0,
                      testDetail?.verifyPassedResults?.averageSpecificWeight ? 1 : 2,
                      testDetail?.verifyPassedResults?.averageWaterAbsorption ? 1 : 2,
                    ]
                  : undefined
              }
              list={[
                testDetail?.averageOvenDryWeight?.toFixed(2),
                checkIsNaN(testDetail?.averageSpecificWeight)?.toFixed(2),
                testDetail?.averageWaterAbsorption?.toFixed(2),
              ]}
            />
          </Stack>
        )}
      </>
    ),
    [i18T, testDetail, edit, formik],
  );

  const showThirdTable = useMemo(
    () => (
      <TestTable
        headerData={{
          title: i18T(`TEST.BASE.DETECTION`),
        }}
        rows={[
          isLegalPassed && isCustomPassed && !isCustomRejected
            ? {
                titles: verifyResultText,
                titlesStyle: [TestVerifyResultStyles.normal],
              }
            : {
                titles: verifyResultText,
                titlesStyle: [
                  isLegalPassed ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                  isCustomPassed && !isCustomRejected ? TestVerifyResultStyles.normal : TestVerifyResultStyles.abnormal,
                ],
              },
        ]}
        isShowCollapse={true}
      />
    ),
    [i18T, isLegalPassed, isCustomPassed, verifyResultText, isCustomRejected],
  );

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />

        {showFirstTable}
        {showSecondTable}
      </TestCardContainer>
      <TestCardContainer>{showThirdTable}</TestCardContainer>
    </Stack>
  );
}

export default memo(ExportCoarseAggSgWabsTable);
