import { TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import ColFieldInfo from '@Src/_basic/components/YatungTest/ColFieldInfo';
import { Container } from '@Src/_basic/components/YatungTest/QrcodeDataRows';
import { ReportViewResponse } from '@Src/_basic/object/MonthlyReportType';
import { Stack } from '@mui/material';
import moment from 'moment';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  headerData: ReportViewResponse;
}

const MonthlyHeader = ({ headerData }: Props) => {
  const { t: i18T } = useTranslation();

  return (
    <TestCardContainer>
      <Container sx={{ flexDirection: 'column', gap: 1 }}>
        <ColFieldInfo
          style={{ width: '100%' }}
          titleStyle={{ fontSize: 26 }}
          childrenStyle={{ fontSize: 24 }}
          title={`${i18T('MONTHLY_REPORT.BASE.REPORT_CYCLE')}`}
        >
          {`${moment(headerData.startTime).format('YYYY/MM/DD')} ~ ${moment(headerData.endTime).format('YYYY/MM/DD')}`}
        </ColFieldInfo>
        <ColFieldInfo
          style={{ flex: 1 }}
          titleStyle={{ fontSize: 26 }}
          childrenStyle={{ fontSize: 24 }}
          title={`${i18T('MONTHLY_REPORT.BASE.VIEW_TITLE')}`}
        >
          {headerData.name}
        </ColFieldInfo>
        <Stack flexDirection={'row'} pt={1}>
          <ColFieldInfo
            style={{ flex: 1 }}
            titleStyle={{ fontSize: 26 }}
            childrenStyle={{ fontSize: 24 }}
            title={`${i18T('MONTHLY_REPORT.BASE.TEST_UNIT')}`}
          >
            {headerData.experimentUnit.unitName}
          </ColFieldInfo>
          <ColFieldInfo
            style={{ flex: 1 }}
            titleStyle={{ fontSize: 26 }}
            childrenStyle={{ fontSize: 24 }}
            title={`${i18T('MONTHLY_REPORT.BASE.TEST_FACTORY')}`}
          >
            {headerData?.factory?.factoryName}
          </ColFieldInfo>
        </Stack>
        <Stack flexDirection={'row'}>
          <ColFieldInfo
            style={{ flex: 1 }}
            titleStyle={{ fontSize: 26 }}
            childrenStyle={{ fontSize: 24 }}
            title={`${i18T('MONTHLY_REPORT.BASE.SPECIFICATION')}`}
          >
            {headerData?.specification?.name ?? i18T('GLOBAL.ALL')}
          </ColFieldInfo>
          <ColFieldInfo
            style={{ flex: 1 }}
            titleStyle={{ fontSize: 26 }}
            childrenStyle={{ fontSize: 24 }}
            title={`${i18T('MONTHLY_REPORT.BASE.FINE_SOURCE')}`}
          >
            {headerData?.supplier?.name ?? i18T('GLOBAL.ALL')}
          </ColFieldInfo>
        </Stack>
      </Container>
    </TestCardContainer>
  );
};

export default memo(MonthlyHeader);
