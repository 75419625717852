import {
  ChildrenTypography,
  TestCardContainer,
  TestVerifyResultStyles,
  TitleTypography,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import SwitchTestTable from '@Src/_basic/components/YatungTest/testDetail/SwitchTestTable';
import MonthlyReportIcon from '@Src/_basic/icons/MonthlyReport';
import { Response } from '@Src/_basic/object/ApiType';
import { FileInfo, FineAndRejectFileInfo } from '@Src/_basic/object/FileType';
import { AllTest, TestItemEnum } from '@Src/_basic/object/TestType';
import { GroupDataByCementCompStrResponse } from '@Src/_basic/object/test/cementtitiousPowder/CementCompStrType';
import { GroupDataByFlyAshActivityResponse } from '@Src/_basic/object/test/cementtitiousPowder/FlyAshActivityType';
import { GroupDataBySlagActivityResponse } from '@Src/_basic/object/test/cementtitiousPowder/SlagActivityType';
import { FileApi } from '@Src/_basic/protocol/file/FileApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import TextLine from '@Src/app/module/report/pages/ProductionHistory/screens/components/TextLine';
import { useApi } from '@Src/redux/api/apiAction';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import { caculateFutureDate } from '@Src/_basic/helpers/TimeCalculate';
import { ExperimentItemData } from '@Src/_basic/object/ExperimentItemType';
import { FindAndRejectRecordData, InformAndFineRecordList } from '@Src/_basic/object/FineRecordType';
import { QrcodeData } from '@Src/_basic/object/QrcodeType';
import { DemandOrderApi } from '@Src/_basic/protocol/demandOrder/DemandOrderApi';
import { ExperimentItemApi } from '@Src/_basic/protocol/experimentItem/ExperimentItemApi';
import { FineRecordApi } from '@Src/_basic/protocol/fineRecord/FineRecordApi';
import { QrcodeApi } from '@Src/_basic/protocol/qrcode/QrcodeApi';
import { useAuth } from '@Src/redux/auth/authActions';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Stack } from '@mui/material';
import FineTable from '../components/FineTable';

const TestAndRecheckDetailScreen = () => {
  const { state } = useLocation();

  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { exptItemCode, exptId } = useParams();
  const { userData } = useAuth();
  const navigate = useNavigate();

  const [testDetail, setTestDetail] = useState<AllTest>();
  const [qrData, setQrData] = useState<QrcodeData>();
  const [allExperimentItem, setAllExperimentItem] = useState<Array<ExperimentItemData>>([]);
  const [fileInfoList, setFileInfoList] = useState<Array<FileInfo>>([]);

  const [alertType, setAlertType] = useState<'pass' | 'test' | 'error'>('pass');
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const [fineAndRejectData, setFineAndRejectData] = useState<FindAndRejectRecordData>();
  const [fineFileAndPhoto, setFineFileAndPhoto] = useState<Array<FineAndRejectFileInfo>>([]);

  const [lastTestId, setLastTestId] = useState<number>(0);
  const [disablePass, setDisablePass] = useState<boolean>(false);
  const [disableCreateDemandOrder, setDisableCreateDemandOrder] = useState<boolean>(false);
  const [selectTestDetailId, setSelectTestDetailId] = useState<number>(Number(exptId));

  const [testDataByCementCompStr, setTestDataByCementCompStr] = useState<
    GroupDataByFlyAshActivityResponse | GroupDataBySlagActivityResponse | Array<GroupDataByCementCompStrResponse>
  >();

  const [fineDetail, setFineDetail] = useState<InformAndFineRecordList | undefined>(state);

  const [memeoOpen, setMemeoOpen] = useState<boolean>(true);
  const [fineOpen, setFineOpen] = useState<boolean>(true);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const goPass = useCallback(() => {
    setAlertType('pass');
    setIsShowAlert(true);
    setAlertText('您要將此試驗進行通過判定嗎?');
  }, []);

  const confirmGoPass = useCallback(() => {
    FineRecordApi.getUpdateRecheckPass({ exptId: lastTestId, exptItemCode: exptItemCode as TestItemEnum }, () => {
      setFineDetail((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          passStatus: 2,
        };
      });
    });
  }, [lastTestId, exptItemCode]);

  const goCreateDemandOrder = useCallback(() => {
    setAlertType('test');
    setIsShowAlert(true);
    setAlertText('您要將此試驗進行複驗嗎?');
  }, []);

  const errorMessageHandler = useCallback((err: Response<null>) => {
    setIsShowAlert(true);
    setAlertType('error');
    if (typeof err.message === 'string') setAlertText(err.message);
  }, []);

  const confirmCreateDemandOrder = useCallback(() => {
    if (!testDetail || !userData?.account) return;
    const _experimentItemId = allExperimentItem.find((item) => item.itemCode === exptItemCode)?.id;
    if (!_experimentItemId) return;

    DemandOrderApi.createDemandOrder(
      {
        ...qrData,
        createrId: userData.account,
        memo: testDetail.memo,
        specimenId: testDetail.specimenId,
        startTime: new Date().getTime(),
        endTime: caculateFutureDate(new Date().getTime(), 2),
        factoryId: testDetail.factoryId,
        requestItems: [
          {
            experimentItemId: _experimentItemId,
            experimentItemCode: exptItemCode,
            operatorId: testDetail.operator,
          },
        ],
        recheckParentExptId: Number(exptId),
      },
      goBack,
      errorMessageHandler,
    );
  }, [testDetail, userData, qrData, exptItemCode, exptId, goBack, allExperimentItem, errorMessageHandler]);

  const getFindAndRejectRecordFn = useCallback(() => {
    FineRecordApi.getFindAndRejectRecord(
      { exptId: Number(exptId), exptItemCode: exptItemCode as TestItemEnum },
      setFineAndRejectData,
      (err) => console.log(err),
    );

    FileApi.getFineAndRejectFile(
      { exptId: Number(exptId), exptItemCode: exptItemCode as TestItemEnum },
      setFineFileAndPhoto,
      errorMessageHandler,
    );
  }, [exptId, exptItemCode, errorMessageHandler]);

  const getTestDetail = useCallback(
    (_exptId: number, exptItemCode: TestItemEnum) => {
      setSelectTestDetailId(_exptId);

      if (fineDetail?.recheckExptIds && fineDetail?.recheckExptIds?.length > 0) {
        const _lastTestId = Math.max(...fineDetail.recheckExptIds);
        setLastTestId(_lastTestId);
        if (_exptId !== _lastTestId) setDisablePass(true);
        else setDisablePass(false);
      } else setDisablePass(false);

      if (exptId && _exptId !== Number(exptId)) setDisableCreateDemandOrder(true);
      else setDisableCreateDemandOrder(false);

      const _exptIdReq = { exptId: _exptId };

      TestApi.getTestDetail(
        { ..._exptIdReq, exptItemCode: exptItemCode as TestItemEnum },
        (_data) => {
          if (!_data.completeTime) {
            errorMessageHandler({ code: '', data: null, message: '此試驗尚未完成' });
            setTestDetail(undefined);
          } else setTestDetail(_data);
        },
        (err) => {
          setTestDetail(undefined);
          errorMessageHandler(err);
        },
      );

      FileApi.getFile(
        { ..._exptIdReq, exptItemCode: exptItemCode as TestItemEnum },
        setFileInfoList,
        errorMessageHandler,
      );

      switch (exptItemCode) {
        case TestItemEnum.FLY_ASH_ACTIVITY_TEST:
          TestApi.getGroupDataByFlyAshActivity(_exptIdReq, setTestDataByCementCompStr);
          break;
        case TestItemEnum.SLAG_ACTIVITY_TEST:
          TestApi.getGroupDataBySlagActivity(_exptIdReq, setTestDataByCementCompStr);
          break;
        case TestItemEnum.CEMENT_COMP_STR_TEST:
          TestApi.getGroupDataByCementCompStr(_exptIdReq, setTestDataByCementCompStr);
          break;
        default:
      }
    },
    [exptId, errorMessageHandler, fineDetail],
  );

  useEffect(() => {
    if (!state) return;
    setFineDetail(state);
  }, [state]);

  useEffect(() => {
    if (!exptId || !exptItemCode) return;
    getTestDetail(Number(exptId), exptItemCode as TestItemEnum);
    getFindAndRejectRecordFn();
  }, []);

  useEffect(() => {
    if (!testDetail) return;
    QrcodeApi.getQrcodeData(
      { sampleId: testDetail.sampleId },
      (_data) => {
        setQrData(_data);
        ExperimentItemApi.getAllExperimentItems(
          {
            typeId: _data?.typeId,
            specificationId: _data?.specificationId,
          },
          setAllExperimentItem,
          errorMessageHandler,
        );
      },
      errorMessageHandler,
    );
  }, [testDetail, errorMessageHandler]);

  const CollapseCardContainer = ({ children }: { children: JSX.Element | null }): JSX.Element | null => {
    return (
      <Stack gap={3}>
        <TestCardContainer
          style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: memeoOpen ? '50px' : 5 }}
        >
          <TitleTypography>備註與檔案</TitleTypography>
          <IconButton
            sx={{
              height: 40,
              width: 40,
            }}
            onClick={() => setMemeoOpen((prev) => !prev)}
          >
            {memeoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TestCardContainer>

        {memeoOpen && children}
      </Stack>
    );
  };

  const CollapseFineContainer = ({ children }: { children: JSX.Element | null }): JSX.Element | null => {
    return (
      <Stack position={'relative'} sx={{ borderTop: '2px dashed black' }} pt={3} mt={8}>
        <TestCardContainer
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: fineOpen ? '20px' : 5,
            marginTop: 20,
          }}
        >
          <TitleTypography>罰款與退料紀錄</TitleTypography>
          <IconButton
            sx={{
              height: 40,
              width: 40,
            }}
            onClick={() => setFineOpen((prev) => !prev)}
          >
            {fineOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TestCardContainer>
        {fineOpen && children}
      </Stack>
    );
  };

  const showFiles: JSX.Element = useMemo(
    () => (
      <>
        <TestCardContainer>
          <TitleTypography color={TestVerifyResultStyles.textBlue} my={5}>{`${i18T(
            `TEST.FILE.IMAGE`,
          )}:`}</TitleTypography>
          <Stack flexDirection="row" flexWrap="wrap" justifyContent="center" width="100%" gap={5}>
            {fileInfoList.map((file, index) => {
              return file.type === 'IMAGE' ? (
                <Stack key={index}>
                  {file.type === 'IMAGE' && (
                    <Stack gap={2} mr={3}>
                      <a href={file.path} target={file.path}>
                        <img src={file.path} style={{ width: 450, height: 450 }} />
                      </a>
                      <ChildrenTypography alignSelf="flex-end">
                        {`(${testDetail?.operatorName}/${moment(file.createTime).format('yyyy.MM.DD')})`}
                      </ChildrenTypography>
                    </Stack>
                  )}
                </Stack>
              ) : (
                <ChildrenTypography key={index} sx={{ textAlign: 'center' }}>
                  {i18T(`GLOBAL.NO_INFO`)}
                </ChildrenTypography>
              );
            })}
          </Stack>
        </TestCardContainer>
        <TestCardContainer>
          <TitleTypography color={TestVerifyResultStyles.textBlue} my={5}>{`${i18T(
            `TEST.FILE.DOCUMENT`,
          )}:`}</TitleTypography>
          <Stack flexDirection="row" flexWrap="wrap" justifyContent="center" width="100%" gap={5} mb={5}>
            {fileInfoList.map((file, index) => {
              return file.type === 'DOCUMENT' ? (
                <Stack key={index}>
                  {file.type === 'DOCUMENT' && file.download && (
                    <a href={file.download} style={{ textDecoration: 'none', userSelect: 'none' }}>
                      <TextLine icon={<MonthlyReportIcon stroke="#002F8C" />} content={file.name} />
                    </a>
                  )}
                </Stack>
              ) : (
                <ChildrenTypography key={index} sx={{ textAlign: 'center' }}>
                  {i18T(`GLOBAL.NO_INFO`)}
                </ChildrenTypography>
              );
            })}
          </Stack>
        </TestCardContainer>
      </>
    ),
    [fileInfoList, testDetail?.operatorName, i18T],
  );

  return (
    <Stack gap={2} pt={10} pb={4}>
      <Stack style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10, marginRight: 170, maxHeight: 40 }}>
        <YatungButton color={'yellow'} onClick={goBack} disabled={actionLoading}>
          {'回上頁'}
        </YatungButton>
        <YatungButton
          color={'green'}
          onClick={goPass}
          disabled={!!fineDetail?.passStatus || actionLoading || disablePass}
        >
          {'通過'}
        </YatungButton>
        <YatungButton
          color={'secondary'}
          onClick={goCreateDemandOrder}
          disabled={!!fineDetail?.passStatus || actionLoading || disableCreateDemandOrder}
        >
          {'複驗'}
        </YatungButton>
        {fineDetail?.recheckExptIds && exptId && fineDetail?.recheckExptIds?.length > 0 && (
          <YatungSelect
            options={fineDetail?.recheckExptIds
              .map((item: number) => ({ text: item.toString(), value: item }))
              .concat([{ text: `${exptId}(原試驗)`, value: Number(exptId) }])}
            onChange={(e) => getTestDetail(e.target.value as number, exptItemCode as TestItemEnum)}
            value={selectTestDetailId}
          />
        )}
      </Stack>

      {testDetail && <SwitchTestTable testDetail={testDetail} testDataByCementCompStr={testDataByCementCompStr} />}

      <CollapseCardContainer>
        <>
          {!!testDetail?.completeTime && (
            <TestCardContainer>
              <Stack flexDirection="column" gap={2}>
                <TitleTypography color={TestVerifyResultStyles.textBlue}>{`${i18T(
                  `TEST.REMARK.TITLE`,
                )}:`}</TitleTypography>
                <Stack sx={{ border: '1px solid gray', minHeight: 400, letterSpacing: 1.5, mb: 3 }}>
                  <ChildrenTypography sx={{ flexWrap: 'wrap', p: 2, wordWrap: 'break-word' }}>
                    {testDetail?.memo}
                  </ChildrenTypography>
                </Stack>
              </Stack>
            </TestCardContainer>
          )}

          {fileInfoList.length > 0 && showFiles}
        </>
      </CollapseCardContainer>

      <CollapseFineContainer>
        <FineTable
          fineAndRejectData={fineAndRejectData}
          fineFileAndPhoto={fineFileAndPhoto}
          getFindAndRejectRecordFn={getFindAndRejectRecordFn}
        />
      </CollapseFineContainer>

      <YatungConfirmAlert
        isShowAlert={isShowAlert}
        alertText={alertText}
        onClose={() => setIsShowAlert(false)}
        onOk={alertType === 'error' ? undefined : alertType === 'pass' ? confirmGoPass : confirmCreateDemandOrder}
      />
    </Stack>
  );
};

export default memo(TestAndRecheckDetailScreen);
