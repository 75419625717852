import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import YatungPaperLabel from '@Src/_basic/components/YatungPaperLabel';
import { WarehouseData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { useTranslation } from 'react-i18next';

interface Props {
  data: WarehouseData;
}
export default function StorageYardBody(props: Props) {
  const { t: i18T } = useTranslation();
  const { data } = props;
  const textStyle = {
    fontSize: 24,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#7C878E',
    my: 1,
    ml: 3,
  };
  const symbolSmall = {
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#7C878E',
    ml: 3,
  };
  return (
    <>
      <Grid container justifyContent={'flex-start'} item xs={12}>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.TYPE_SETTING')} sx={textStyle} />
        </Grid>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.FEEDTROUGH_NAME')} sx={textStyle} />
        </Grid>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.DEFINITION_NAME')} sx={textStyle} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} item xs={12}>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={data.typeName} sx={textStyle} />
        </Grid>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={data.code} sx={textStyle} />
        </Grid>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={data.name} sx={textStyle} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} item xs={12} sx={{ mt: 5 }}>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.ADD_SPEFICATIONS')} sx={textStyle} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} item xs={12}>
        <Grid container justifyContent={'flex-start'} item xs={4}>
          <YatungPaperLabel label={data.specificationName} sx={textStyle} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} item xs={12} sx={{ mt: 5 }}>
        <Grid container justifyContent={'flex-start'} item xs={6}>
          <YatungPaperLabel label={i18T('FEEDTROUGHMAINTENANCEMANAGEMENT.STORAGE_YARD_WEIGHT')} sx={textStyle} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} item xs={12}>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container justifyContent={'center'} item xs={8}>
            <YatungPaperLabel label={data.capacity} sx={textStyle} />
          </Grid>
          <Grid container justifyContent={'center'} item xs={4}>
            <YatungPaperLabel sx={symbolSmall} label={'KG'} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
