/* eslint-disable no-unused-vars */
import { PageableResponse, Response } from '@Src/_basic/object/ApiType';
import {
  CarbonEmissionHeader,
  CarbonEmissionList,
  SearchCarbonEmissionParams,
  SearchPageableCarbonEmissionParams,
} from '@Src/_basic/object/CarbonEmissionType';
import { Api } from '../Api';
import { CarbonEmissionProtocol } from './CarbonEmissionProtocol';

export class CarbonEmissionApi {
  public static getCarbonEmissionHeaderBySearch(
    params: SearchCarbonEmissionParams,
    onSuccess?: (data: CarbonEmissionHeader) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(CarbonEmissionProtocol.CARBON_EMISSION_HEADER, config, onSuccess, onFailed, onFinally);
  }

  public static getPageableCarbonEmissionBySearch(
    params: SearchPageableCarbonEmissionParams,
    onSuccess?: (data: PageableResponse<CarbonEmissionList>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(CarbonEmissionProtocol.PAGEABLE_CARBON_EMISSION_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }

  public static getCancelablePageableProductionHistoriesBySearch(
    params: SearchPageableCarbonEmissionParams,
    onSuccess?: (data: PageableResponse<CarbonEmissionList>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      headers: {
        Cancelable: true,
      },
      params,
    };

    Api.get(CarbonEmissionProtocol.PAGEABLE_CARBON_EMISSION_BY_SEARCH, config, onSuccess, onFailed, onFinally);
  }
}
