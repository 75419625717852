import { FactoryApi } from '@Src/_basic/protocol/factoryManagement/FactoryApi';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import FactoryManagementUpdateForm from './FactoryManagementUpdateForm';
import { useTranslation } from 'react-i18next';
import YatungPage from '@Src/_basic/components/YatungPage';
import { FactoryData, PutFactoryDataRequest } from '@Src/_basic/object/FactoryType';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';
import { Options } from '@Src/_basic/components/YatungSelect';
import { AreaData } from '@Src/_basic/object/AreaType';

const background = '/media/images/background.png';

export default function FactoryManagementUpdateCard() {
  const { t: i18T } = useTranslation();
  const params = useParams();
  const id = Number(params.id);
  const [open, setOpen] = useState<boolean>(false);
  const [factoryUpdateData, setFactoryUpdateData] = useState<FactoryData>({
    areaId: 0,
    areaName: '',
    branchCode: '',
    factoryAddress: '',
    factoryCode: '',
    factoryName: '',
    factoryPhone: '',
    id: 0,
    removed: false,
  });
  const { state: areaData } = useLocation();
  const getAreaFilterData = (data: any) => {
    const filterAreaValue = areaData.find((_data: any) => _data.text.includes(data.areaName));
    if (filterAreaValue) {
      setFactoryUpdateData({ ...data, areaId: filterAreaValue.value });
    } else {
      setFactoryUpdateData(data)
    }
  }
  const getFactoryDataById = () => {
    FactoryApi.GetFactoryById(
      id,
      (data: FactoryData) => {
        getAreaFilterData(data)

      },
      undefined,
      undefined,
    );
  };
  useEffect(() => {
    getFactoryDataById();
  }, []);
  const initialFactory: PutFactoryDataRequest = {
    id: factoryUpdateData.id,
    areaName: factoryUpdateData.areaName || '',
    areaId: factoryUpdateData.areaId || 0,
    factoryName: factoryUpdateData.factoryName || '',
    factoryAddress: factoryUpdateData.factoryAddress || '',
    factoryPhone: factoryUpdateData.factoryPhone || '',
    factoryCode: factoryUpdateData.factoryCode || '',
    branchCode: factoryUpdateData.branchCode || '',
    removed: factoryUpdateData.removed || false,
  };
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCancelButtonOpenModal = () => {
    setOpen(false);
  };
  return (
    <YatungPage
      title={i18T('FACTORYMANAGEMENT.PAGE_UPDATE_TITLE')}
      backgroundImage={background}
      body={
        <>
          <FactoryManagementUpdateForm
            initialValues={initialFactory}
            open={open}
            onCancelButtonOpenModal={handleCancelButtonOpenModal}
            onOpenModal={handleOpenModal}
          />
        </>
      }
    />
  );
}
