import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import { Options } from '@Src/_basic/components/YatungFormSelect';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungMenuCheckBox from '@Src/_basic/components/YatungMenuCheckBox';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import EyeCloseIcon from '@Src/_basic/icons/EyeClose';
import { StationType, StationsDataById } from '@Src/_basic/object/SiteType';
import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  index: number;
  factoryBranchCode: string;
  data: StationsDataById;
  setUpdateGuardhouseTypeId: (e: StationsDataById[]) => void;
  updateGuardhouseTypeId: StationsDataById[];
  stationsOption: Options[];
  types: Options[];
  typeTransaction: Options[];
}
export default function UpdateGuardhouseForm(props: Props) {
  const { data, setUpdateGuardhouseTypeId, updateGuardhouseTypeId, factoryBranchCode, types, typeTransaction, index } =
    props;
  const { t: i18T } = useTranslation();
  const [typeCheck, setTypeCheck] = useState<Array<number>>(data.types);
  const [setType, setSetType] = useState<Array<StationType>>();

  const getCheckbox = (array: Array<StationType>) => {
    setSetType(array);
  };

  const getSetTypeId = () => {
    const updatedArray = updateGuardhouseTypeId.map((datas: StationsDataById, i: number) => {
      if (datas.id === data.id) {
        return {
          ...datas,
          stationTypeSettings: setType,
        };
      }
      return datas;
    });
    setUpdateGuardhouseTypeId(updatedArray);
  };
  useEffect(() => {
    getSetTypeId();
  }, [setType]);

  const handleTypeChecked = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const valueTurnToNumber = Number(value);
    if (!typeCheck.includes(valueTurnToNumber)) {
      setTypeCheck([...typeCheck, valueTurnToNumber]);
    } else {
      const checkIndex = typeCheck.indexOf(valueTurnToNumber);
      if (checkIndex > -1) {
        typeCheck.splice(checkIndex, 1);
      }
      setTypeCheck([...typeCheck]);
    }
  };
  const getCheckedTypeId = () => {
    const updatedArray = updateGuardhouseTypeId.map((datas: StationsDataById, i: number) => {
      if (datas.id === data.id) {
        return { ...datas, types: typeCheck };
      }
      return datas;
    });
    setUpdateGuardhouseTypeId(updatedArray);
  };
  useEffect(() => {
    getCheckedTypeId();
  }, [typeCheck]);

  const handleInput = (e: SelectChangeEvent) => {
    const updatedArray = updateGuardhouseTypeId.map((datas: StationsDataById, i: number) => {
      if (datas.id === data.id) {
        return { ...datas, stationName: e.target.value };
      }
      return datas;
    });
    setUpdateGuardhouseTypeId(updatedArray);
  };
  const handlePwInput = (e: SelectChangeEvent) => {
    const updatedArray = updateGuardhouseTypeId.map((datas: StationsDataById, i: number) => {
      if (datas.id === data.id) {
        return { ...datas, password: e.target.value };
      }
      return datas;
    });
    setUpdateGuardhouseTypeId(updatedArray);
  };

  const handleTransactionSelectChange = (e: SelectChangeEvent<unknown>) => {
    const updatedArray: any = updateGuardhouseTypeId.map((datas: StationsDataById, i: number) => {
      if (datas.id === data.id) {
        return { ...datas, transactionId: e.target.value };
      }
      return datas;
    });
    setUpdateGuardhouseTypeId(updatedArray);
  };
  const handlePasswordEdit = () => {
    if (data.passwordEdit) {
      const updatedArray = updateGuardhouseTypeId.map((datas: StationsDataById, i: number) => {
        if (datas.id === data.id) {
          return { ...datas, passwordEdit: false };
        }
        return datas;
      });

      setUpdateGuardhouseTypeId(updatedArray);
    } else {
      const updatedArray = updateGuardhouseTypeId.map((datas: StationsDataById, i: number) => {
        if (datas.id === data.id) {
          return { ...datas, passwordEdit: true };
        }
        return datas;
      });

      setUpdateGuardhouseTypeId(updatedArray);
    }
  };

  const handleInputType = () => {
    if (data.inputType === 'password') {
      const updatedArray = updateGuardhouseTypeId.map((datas: StationsDataById, i: number) => {
        if (datas.id === data.id) {
          return { ...datas, inputType: 'text' };
        }
        return datas;
      });
      setUpdateGuardhouseTypeId(updatedArray);
    } else {
      const updatedArray = updateGuardhouseTypeId.map((datas: StationsDataById, i: number) => {
        if (datas.id === data.id) {
          return { ...datas, inputType: 'password' };
        }
        return datas;
      });
      setUpdateGuardhouseTypeId(updatedArray);
    }
  };
  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.SITES')} />
        </Grid>
        <Grid container item xs={11}>
          <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>
            {i18T('SITEMANAGEMENT.GUARDHOUSE')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.STATION_NAME')} />
        </Grid>
        <Grid container item xs={11}>
          <YatungInput sx={{ width: '930px' }} defaultValue={data.stationName} onBlur={handleInput} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container item xs={3}>
            <YatungFormLabel label={i18T('SITEMANAGEMENT.SITE_TYPE')} />
          </Grid>
          <Grid container item xs={9} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{data.stationType}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={'flex-start'} alignItems={'center'} item xs={4}>
          <Grid container item xs={3}>
            <YatungFormLabel label={i18T('SITEMANAGEMENT.SITE_NUMBER')} />
          </Grid>
          <Grid container item xs={9} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}>{data.stationCode}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.ACCOUNT')} />
        </Grid>
        <Grid container item xs={11}>
          <Typography
            sx={{ fontSize: '24px', fontWeight: 400, color: '#ABAAAA' }}
          >{`${factoryBranchCode}_${data.stationType}_${data.stationCode}`}</Typography>
        </Grid>
      </Grid>
      {/* <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.PASSWORD')} />
        </Grid>
        <Grid container item xs={11}>
          <YatungInput sx={{ width: '930px' }} defaultValue={''} onBlur={handlePwInput} type="password" />
        </Grid>
      </Grid> */}
      {data.edit ? (
        <Grid container alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
          <Grid container item xs={1}>
            <YatungFormLabel label={i18T('SITEMANAGEMENT.PASSWORD')} />
          </Grid>
          {data.passwordEdit ? (
            <Grid container item xs={11}>
              <YatungInput
                isIconButton
                type={data.inputType ? data.inputType : 'password'}
                handleInputType={handleInputType}
                position="end"
                icon={<EyeCloseIcon />}
                defaultValue={data.password}
                onBlur={handlePwInput}
              />
              <YatungButton width={110} sx={{ ml: 1 }} color="blue" text="取消編輯" onClick={handlePasswordEdit} />
            </Grid>
          ) : (
            <YatungButton color="blue" text="編輯密碼" onClick={handlePasswordEdit} />
          )}
        </Grid>
      ) : (
        <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.PASSWORD')} />
          <YatungInput
            isIconButton
            type={data.inputType ? data.inputType : 'password'}
            handleInputType={handleInputType}
            position="end"
            icon={<EyeCloseIcon />}
            defaultValue={data.password}
            onBlur={handlePwInput}
          />
        </Grid>
      )}
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ mt: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.TRANSACTION_TYPE')} />
        </Grid>
        <Grid container item xs={11}>
          <YatungSelect
            options={typeTransaction}
            value={data?.transactionId ?? data.transactionOption?.id}
            onChange={handleTransactionSelectChange}
            sx={{ width: '930px' }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'} item xs={12} sx={{ my: 2 }}>
        <Grid container item xs={1}>
          <YatungFormLabel label={i18T('SITEMANAGEMENT.FUNCTION')} />
        </Grid>
        <Grid container item xs={11}>
          <YatungMenuCheckBox
            initialCheckedValue={typeCheck}
            options={types}
            updateWbStationTypeId={updateGuardhouseTypeId}
            onChange={handleTypeChecked}
            onCheck={getCheckbox}
            sx={{ margin: '0px' }}
          />
        </Grid>
      </Grid>
      <YatungDivider />
    </>
  );
}
