import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import { AreaData } from '@Src/_basic/object/AreaType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AreaColumnFormatter from '../AreaManagementTable/columnFormatter/AreaColumnFormatter';
import StateColumnFormatter from '../AreaManagementTable/columnFormatter/StateColumnFormatter';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import CreateTimeColumnFormatter from './columnFormatter/CreateTimeColumnFormatter';
import IdColumnFormatter from './columnFormatter/IdColumnFormatter';

interface Props {
  loading: boolean;
  areaData: AreaData[];
  onOpenUpdateModal: (id: number) => void;
}
export default function AreaManagementTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { areaData, onOpenUpdateModal, loading } = props;
  console.log('areaData', areaData);
  const getButtonText = (status: string) => {
    switch (status) {
      case '編輯':
        return i18T('AREAMANAGEMENT.MODIFY');
      case '刪除':
        return i18T('AREAMANAGEMENT.DELETE');
      default:
        return '';
    }
  };

  const columns: Array<Column> = [
    {
      text: i18T('AREAMANAGEMENT.SORT'),
      dataField: 'id',
      formatter: IdColumnFormatter,
    },
    {
      text: i18T('AREAMANAGEMENT.GLOBAL_AREA'),
      dataField: 'name',
      formatter: AreaColumnFormatter,
    },
    {
      text: i18T('AREAMANAGEMENT.ADD_DATE'),
      dataField: 'createTime',
      formatter: CreateTimeColumnFormatter,
    },
    {
      text: i18T('FACTORYMANAGEMENT.STATE'),
      dataField: 'removed',
      formatter: StateColumnFormatter,
    },
    {
      text: i18T('AREAMANAGEMENT.ACTION'),
      dataField: 'action',
      formatter: ActionColumnFormatter,
      formatExtraData: {
        onOpenUpdateModal: onOpenUpdateModal,
        getButtonText: getButtonText,
      },
    },
  ];
  return <YatungTable columns={columns} data={areaData} loading={loading} />;
}
