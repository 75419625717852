import { KeyManagementData } from '@Src/_basic/object/KeyManagementType';
import { Typography } from '@mui/material';
import React from 'react';

export type ExtraData = {
  getButtonText: (status: string) => string;
};
interface Props {
  formatExtraData: ExtraData;
  row: KeyManagementData;
}
export default function StateColumnFormatter(props: Props) {
  const { formatExtraData, row } = props;

  const getDisplayStylte = () => {
    if (row.isExpired || row.isCancle) {
      return '#CB333B';
    } else if (row.isStatus) {
      return '#3E9B2F';
    }
  };
  const getDisplayText = () => {
    if (row.isExpired) {
      return formatExtraData.getButtonText('逾期');
    } else if (row.isCancle) {
      return formatExtraData.getButtonText('註銷');
    } else if (row.isStatus) {
      return formatExtraData.getButtonText('啟用');
    }
  };
  return (
    <Typography
      variant="inherit"
      sx={{
        color: getDisplayStylte(),
        fontWeight: 400,
        fontSize: 16,
      }}
    >
      {getDisplayText()}
    </Typography>
  );
}
