import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';
import DetailIcon from '@Src/_basic/icons/Detail';
import { SearchReportData } from '@Src/_basic/object/ReportType';
import { AllTest, TestItemEnum } from '@Src/_basic/object/TestType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { useApi } from '@Src/redux/api/apiAction';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  data: Array<SearchReportData>;
  exptCode: TestItemEnum;
}

const SieveReportTable = ({ data, exptCode }: Props) => {
  const { actionLoading } = useApi();
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();

  const [dataDetail, setDataDetail] = useState<Map<number, AllTest>>(new Map());
  const _dataDetailMap = new Map<number, AllTest>();

  useEffect(() => {
    const ids = data.reduce((prev, curr) => [...prev, curr.exptId1 ?? 0, curr.exptId2 ?? 0], [] as Array<number>);
    ids.forEach((item) =>
      TestApi.getTestDetail({ exptId: item, exptItemCode: exptCode }, (_data) =>
        _dataDetailMap.set(item, _data as AllTest),
      ),
    );
    setDataDetail(_dataDetailMap);
  }, [data, exptCode]);

  return (
    <YatungTable
      loading={actionLoading}
      data={data}
      isDashedBottom={true}
      columns={[
        {
          text: i18T('VIEW.TABLE.DATE'),
          dataField: 'createTime',
          width: '90px',
          formatter: ({ row }) => {
            return <>{moment(row.createTime).format('YYYY/MM/DD HH:mm')}</>;
          },
        },
        {
          text: '',
          dataField: '',
          width: '20px',
          formatter: () => {
            return (
              <Stack flexDirection="column" gap={4}>
                <Typography>S1</Typography>
                <Typography>S2</Typography>
              </Stack>
            );
          },
        },
        {
          text: i18T('VIEW.TABLE.SPECIFICATION'),
          dataField: 'specificationName',
          width: '90px',
          formatter: ({ row }) => {
            return (
              <Stack flexDirection="column" gap={4}>
                <Typography>{dataDetail?.get(row.exptId1)?.specificationName ?? i18T('GLOBAL.NO_INFO')}</Typography>
                <Typography>{dataDetail?.get(row.exptId2)?.specificationName ?? i18T('GLOBAL.NO_INFO')}</Typography>
              </Stack>
            );
          },
        },
        {
          text: i18T('VIEW.TABLE.SOURCE'),
          dataField: 'sourceName',
          width: '90px',
          formatter: ({ row }) => {
            return (
              <Stack flexDirection="column" gap={4}>
                <Typography>{dataDetail?.get(row.exptId1)?.sourceName ?? i18T('GLOBAL.NO_INFO')}</Typography>
                <Typography>{dataDetail?.get(row.exptId2)?.sourceName ?? i18T('GLOBAL.NO_INFO')}</Typography>
              </Stack>
            );
          },
        },
        {
          text: i18T('VIEW.TABLE.SUPPLIER'),
          dataField: 'supplierName',
          width: '90px',
          formatter: ({ row }) => {
            return (
              <Stack flexDirection="column" gap={4}>
                <Typography>{dataDetail?.get(row.exptId1)?.supplierName ?? i18T('GLOBAL.NO_INFO')}</Typography>
                <Typography>{dataDetail?.get(row.exptId2)?.supplierName ?? i18T('GLOBAL.NO_INFO')}</Typography>
              </Stack>
            );
          },
        },
        {
          text: i18T('VIEW.TABLE.STORAGE'),
          dataField: 'materialStockpile',
          width: '150px',
          formatter: ({ row }) => {
            return (
              <Stack flexDirection="column" gap={4}>
                <Typography>{dataDetail?.get(row.exptId1)?.storageName ?? i18T('GLOBAL.NO_INFO')}</Typography>
                <Typography>{dataDetail?.get(row.exptId2)?.storageName ?? i18T('GLOBAL.NO_INFO')}</Typography>
              </Stack>
            );
          },
        },
        {
          text: i18T('VIEW.TABLE.TESTER_NAME'),
          dataField: 'samplingPersonnel',
          width: '90px',
          formatter: ({ row }) => {
            return (
              <Stack flexDirection="column" gap={4}>
                <Typography>{dataDetail?.get(row.exptId1)?.operatorName ?? i18T('GLOBAL.NO_INFO')}</Typography>
                <Typography>{dataDetail?.get(row.exptId2)?.operatorName ?? i18T('GLOBAL.NO_INFO')}</Typography>
              </Stack>
            );
          },
        },
        {
          text: i18T('VIEW.TABLE.TEST_UNIT'),
          dataField: 'unitName',
          width: '90px',
          formatter: ({ row }) => {
            return (
              <Stack flexDirection="column" gap={4}>
                <Typography>{dataDetail?.get(row.exptId1)?.exptUnitName ?? i18T('GLOBAL.NO_INFO')}</Typography>
                <Typography>{dataDetail?.get(row.exptId2)?.exptUnitName ?? i18T('GLOBAL.NO_INFO')}</Typography>
              </Stack>
            );
          },
        },
        {
          text: i18T('GLOBAL.ACTION'),
          dataField: 'action',
          width: '150px',
          formatter: ({ row }) => {
            return (
              <YatungButton
                text={i18T('GLOBAL.DETAIL')}
                startIcon={<DetailIcon />}
                width={90}
                onClick={() =>
                  navigate(`/report/sieve-report/${row.exptId1}/${row.exptId2}/${exptCode}`, {
                    state: { _ratio: row.ratio / 100 },
                  })
                }
                color="yellow"
              />
            );
          },
        },
      ]}
    />
  );
};

export default memo(SieveReportTable);
