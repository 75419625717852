import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import { Box, Card, CardContent, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Options } from '@Src/_basic/components/YatungSelect';
import RestDaySettingIcon from '@Src/_basic/icons/RestDaySettingIcon';
import { colorCodeOption, getEvents, getEventsResponse } from '@Src/_basic/object/CalendarType';
import { CalendarApi } from '@Src/_basic/protocol/calendar/CalendarApi';
import { DayCellContentArg, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import momentPlugin from '@fullcalendar/moment';
import FullCalendar from '@fullcalendar/react';
import moment from 'moment';
import '../../../../../../_basic/assets/styles/Calendar.css';
import RestDaySetting from '../components/RestDaySetting';
import RestDayUpdateSetting from '../components/RestDayUpdateSetting';

export default function CalendarMangementScreens() {
  const { t: i18T } = useTranslation();
  const today = new Date().toDateString();

  const [settingModalOpen, setSettingModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [events, setEvents] = useState<getEvents[]>([]); // 休息日設定
  const [festivalEvents, setFestivalEvents] = useState<getEvents[]>([]); // 休息日設定
  const [findEventData, setFindEventData] = useState<getEventsResponse>({
    id: 0,
    title: '',
    colorCode: [],
    startTime: 0,
    endTime: 0,
    factories: [],
    duration: '',
  });
  const [calendarColorCode, setCalendarColorCode] = useState<Array<Options>>([]);

  const smallCalendarRef = useRef<any>(null);
  const bigCalendarRef = useRef<any>(null);

  const handlePrevChange = () => {
    const smallCalendarApi = smallCalendarRef?.current?.getApi();
    const bigCalendarApi = bigCalendarRef.current.getApi();

    const currentDate = smallCalendarApi.getDate();
    const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

    smallCalendarApi.gotoDate(firstDayOfPreviousMonth);
    bigCalendarApi.gotoDate(firstDayOfPreviousMonth);
  };
  const handleNextChange = () => {
    const smallCalendarApi = smallCalendarRef?.current?.getApi();
    const bigCalendarApi = bigCalendarRef.current.getApi();

    const currentDate = smallCalendarApi.getDate();
    const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);

    smallCalendarApi.gotoDate(firstDayOfPreviousMonth);
    bigCalendarApi.gotoDate(firstDayOfPreviousMonth);
  };

  const getDayCellContent = ({ date }: DayCellContentArg) => {
    if (date.getDay() === 0) {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '5px' }}>{date.getDate()}</div>
          <div style={{ color: '#CB333B' }}>休</div>
        </div>
      );
    }
    return <div>{date.getDate()}</div>;
  };

  const getSmallCalDayCellContent = ({ date }: DayCellContentArg) => {
    const dateStr = date.toDateString();
    if (dateStr === today) {
      return <div className="circle">{date.getDate()}</div>;
    }
    return <div>{date.getDate()}</div>;
  };

  const handleHeaderContent = ({ date }: DayCellContentArg) => {
    // Custom day header content for full control over individual day headers
    const days = ['日', '一', '二', '三', '四', '五', '六'];
    return days[date.getDay()];
  };

  const handleEventClick = (info: EventClickArg) => {
    if (info.event.extendedProps.isFestival) {
      return;
    } else {
      CalendarApi.getCalendarById(
        info.event.id,
        (data: getEventsResponse) => {
          handleCloseMoreView();
          setFindEventData(data);
          setDetailModalOpen(true);
        },
        undefined,
        undefined,
      );
    }
  };
  const handleGotoSettingModal = () => {
    setSettingModalOpen(true);
  };
  const handleCloseModal = () => {
    setSettingModalOpen(false);
  };
  const handleDetailCloseModal = () => {
    setDetailModalOpen(false);
  };

  useEffect(() => {
    CalendarApi.getAllCalendar((data: getEventsResponse[]) => {
      getAdjustCalendar(data);
    });
    CalendarApi.getCalendarFestival((data: getEventsResponse[]) => {
      getAdjustFestival(data);
    });
    const getAdjustFestival = (data: getEventsResponse[]) => {
      const adjustFestivalEvents = data.map((item: getEventsResponse) => {
        return {
          title: item.holidayCategory,
          start: moment(item.date).format('YYYY-MM-DD'),
          end: moment(item.date).format('YYYY-MM-DD'),
          backgroundColor: '#0abab5',
          borderColor: '#0abab5',
          isFestival: true,
        };
      });
      setFestivalEvents(adjustFestivalEvents);
    };
    const getAdjustCalendar = (data: getEventsResponse[]) => {
      const adjustEvents = data.map((item: getEventsResponse) => {
        return {
          id: item.id,
          title: item.title,
          start: moment(item.startTime).format('YYYY-MM-DD'),
          end: moment(item.endTime).format('YYYY-MM-DD'),
          backgroundColor: item.colorCode[0].colorCode,
          borderColor: item.colorCode[0].colorCode,
          factories: item.factories,
          isFestival: false,
        };
      });
      setEvents(adjustEvents);
    };
    CalendarApi.getCalendarColorCode((data: Array<colorCodeOption>) => {
      const adjustColorCode = data.map((item: colorCodeOption) => {
        return {
          value: item.id,
          text: item.colorCode,
        };
      });
      setCalendarColorCode(adjustColorCode);
    });
  }, []);

  const handleCloseMoreView = () => {
    const moreButton = document.querySelector('.fc .fc-popover');
    if (moreButton) {
      moreButton.setAttribute('style', 'display: none;');
    }
  };

  return (
    <>
      <RestDaySetting
        settingModalOpen={settingModalOpen}
        onCloseModal={handleCloseModal}
        setEvents={setEvents}
        calendarColorCode={calendarColorCode}
      />
      <RestDayUpdateSetting
        detailModalOpen={detailModalOpen}
        onCloseModal={handleDetailCloseModal}
        findEventData={findEventData}
        setEvents={setEvents}
        calendarColorCode={calendarColorCode}
      />
      <YatungPage
        title={i18T('USERSMANAGEMENU.CALENDARMANAGE.TITLE')}
        body={
          <>
            <Stack flexDirection={'row'} justifyContent={'flex-end'}>
              <YatungButton
                color="green"
                text={i18T('USERSMANAGEMENU.CALENDARMANAGE.REST_DAY_SETTING')}
                onClick={handleGotoSettingModal}
                startIcon={<RestDaySettingIcon sx={{ width: 20, height: 20, mr: 1, alignSelf: 'center' }} />}
              />
            </Stack>
            <Card
              sx={{
                width: '1600px',
                height: '750px',
                boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
                background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
                mt: 1,
                overflow: 'auto',
              }}
            >
              <CardContent>
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                  <Box>
                    <Card
                      sx={{
                        width: '550px',
                        mt: 1,
                        boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);',
                        background: ' linear-gradient(180.38deg, #FFFFFF 0.33%, #F8F9FF 99.67%);',
                      }}
                    >
                      <CardContent>
                        <FullCalendar
                          plugins={[dayGridPlugin, interactionPlugin, momentPlugin]}
                          initialView="dayGridMonth"
                          height={'500px'}
                          aspectRatio={2}
                          ref={bigCalendarRef}
                          customButtons={{
                            prev: {
                              click: handlePrevChange,
                            },
                            next: {
                              click: handleNextChange,
                            },
                          }}
                          headerToolbar={{
                            start: 'title',
                            end: 'prev,next',
                            center: '',
                          }}
                          titleFormat={i18T('GLOBAL.YEAR_MONTH', {
                            year: 'YYYY',
                            month: 'MM',
                          })}
                          dayHeaderContent={handleHeaderContent}
                          dayCellContent={getSmallCalDayCellContent}
                        />
                      </CardContent>
                    </Card>
                  </Box>
                  <Box style={{ width: '980px' }}>
                    <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin]}
                      initialView="dayGridMonth"
                      events={events.concat(festivalEvents)}
                      height={'700px'}
                      ref={smallCalendarRef}
                      aspectRatio={2}
                      customButtons={{
                        prev: {
                          click: handlePrevChange,
                        },
                        next: {
                          click: handleNextChange,
                        },
                      }}
                      headerToolbar={{
                        left: '',
                        right: '',
                        center: '',
                      }}
                      dayHeaderContent={handleHeaderContent}
                      dayCellContent={getDayCellContent}
                      eventClick={handleEventClick}
                      dayMaxEventRows={2}
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </>
        }
        contentBgColor="#fff"
      />
    </>
  );
}
