import axios, { AxiosError, AxiosResponse } from 'axios';
import { Response } from '@Src/_basic/object/ApiType';
import {
  SearchMaterialPriceParams,
  MaterialPriceData,
  PostMaterialPriceRequest,
  PutMaterialPriceRequest,
  CanAddMaterialPrice,
} from '@Src/_basic/object/MaterialPriceType';
import { MaterialPriceProtocol } from './MaterialPriceProtocol';
import { Api } from '../Api';

export class MaterialPriceApi {
  // 取得所有原料單價
  public static getAllMaterialPrice(
    params: SearchMaterialPriceParams,
    onSuccess?: (data: Array<MaterialPriceData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(MaterialPriceProtocol.ALL_PRICE, config, onSuccess, onFailed, onFinally);
  }

  // 修改原料單價
  public static putMaterialPrice(
    request: PutMaterialPriceRequest,
    onSuccess?: (data: MaterialPriceData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(MaterialPriceProtocol.PRICE_URL, request, undefined, onSuccess, onFailed, onFinally);
  }

  // 取得可修改單價的原料
  public static getCanAddMaterialPrice(
    params: SearchMaterialPriceParams,
    onSuccess?: (data: Array<CanAddMaterialPrice>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(MaterialPriceProtocol.ADD_PRICE, config, onSuccess, onFailed, onFinally);
  }

  // 新增物料表紀錄
  public static postMaterialPrice(
    request: PostMaterialPriceRequest,
    onSuccess?: (data: MaterialPriceData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(MaterialPriceProtocol.PRICE_URL, request, undefined, onSuccess, onFailed, onFinally);
  }
}
