import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungCard from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import { AuthSysBaseResponse } from '@Src/_basic/object/ApiType';
import { AppPermissionPaginationRequest, AppPermissionRequest } from '@Src/_basic/object/AppPermissionType';
import { FactoryData } from '@Src/_basic/object/FactoryType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { PermissionRequires } from '@Src/_basic/protocol/appPermission/PermissionApi';
import { FactoryApi } from '@Src/_basic/protocol/factoryManagement/FactoryApi';
import { usePermission } from '@Src/redux/permission/permissionActions';
import { Grid, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBar from './SearchBar';
import VerificationCodeTable from './VerificationCodeTable';

const background = '/media/images/background.png';

export default function VerificationCodeCard() {
  const { t: i18T } = useTranslation();
  const [searched, setSearched] = useState<string>('');
  const {
    setPermissionData,
    permissionData,
    setOriginPermissionData,
    originPermissionData,
    setActivePermissionData,
    activePermissionData,
  } = usePermission();
  const [factoryOptions, setFactoryOptions] = useState<Array<Options>>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({ page: 1, pageSize: 10, total: 0, totalCount: 0 });

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const getEmails = () => {
    const emails = permissionData.map((row: AppPermissionRequest) => {
      return row.emailAddress;
    });
    return emails;
  };
  const getMappedData = () => {
    const emails = getEmails();
    PermissionRequires.requestUsersData({ emails }, handleGetUserDataSuccess, handleGetUserDataFaild);
  };

  const handleGetUserDataSuccess = (data: Array<AppPermissionRequest>) => {
    console.log('data', data);
    // get map
    const userDataMap: any = {};
    // if (userDatas && userDatas.length) {
    data.forEach((element: any) => {
      userDataMap[element.email] = {
        account: element.account,
        name: element.name,
      };
    });

    // mapping
    const mappedDataTemp: Array<any> = [];
    permissionData.forEach((element: any) => {
      mappedDataTemp.push({
        ...element,
        ...userDataMap[element.emailAddress],
      });
    });
    setActivePermissionData(mappedDataTemp);
    setOriginPermissionData(mappedDataTemp);
    // }
  };

  const handleGetUserDataFaild = (res: AuthSysBaseResponse<null>) => {
    console.log(res.message);
  };

  useEffect(() => {
    FactoryApi.getAllFactories((_data: Array<FactoryData>) => {
      setFactoryOptions(
        _data.reduce(
          (prev, { id, factoryName }) => [
            ...prev,
            {
              value: id,
              text: factoryName,
            },
          ],
          [] as Array<Options>,
        ),
      );
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    PermissionRequires.requestPagination(
      pageInfo.page,
      (datas: AppPermissionPaginationRequest) => {
        console.log('datas', datas);
        setPermissionData(datas.applications);
        setPageInfo({
          ...pageInfo,
          page: pageInfo.page,
          total: datas.maxPage,
        });
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  }, [pageInfo.page]);
  useEffect(() => {
    getMappedData();
  }, [permissionData]);
  const searchValue = (event: any) => {
    setSearched(event.target.value);
  };
  const SearchChange = () => {
    if (searched) {
      const requestSearch = originPermissionData.filter((row: AppPermissionRequest) =>
        Object.values(row).join().includes(searched),
      );
      setActivePermissionData(requestSearch);
    } else {
      setActivePermissionData(originPermissionData);
    }
  };

  const RefreshChange = () => {
    PermissionRequires.requestInformation((datas: Array<AppPermissionRequest>) => {
      setPermissionData(datas.reverse());
    });
  };
  const handleSuccessButtonClick = (id: number) => {
    setLoading(true);

    PermissionRequires.requestSuccess(
      id,
      (datas: AppPermissionRequest) => {
        setIsShowAlert(true);
        setAlertText(i18T('VERIFICATIONCODE.SUCCESS_MODAL'));
        PermissionRequires.requestPagination(pageInfo.page, (datas: any) => {
          console.log('datas', datas);
          setPermissionData(datas.applications);
          setPageInfo({
            ...pageInfo,
            page: pageInfo.page,
            total: datas.maxPage,
          });
        });
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  };

  const handleDeleteButtonClick = (id: number) => {
    setLoading(true);
    PermissionRequires.requestDelete(
      id,
      (datas: AppPermissionRequest) => {
        setIsShowAlert(true);
        setAlertText(i18T('VERIFICATIONCODE.ERROR_MODAL'));
        PermissionRequires.requestPagination(pageInfo.page, (datas: any) => {
          setPermissionData(datas.applications);
          setPageInfo({
            ...pageInfo,
            page: pageInfo.page,
            total: datas.maxPage,
          });
        });
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };
  return (
    <>
      <YatungConfirmAlert
        isShowAlert={isShowAlert}
        alertText={alertText}
        onClose={() => setIsShowAlert(false)}
        headerText={i18T('GLOBAL.MESSAGE')}
      />
      <YatungCard
        title={i18T('VERIFICATIONCODE.PAGE_TITLE')}
        backgroundImage={background}
        body={
          <>
            <SearchBar
              searchValue={searchValue}
              searched={searched}
              SearchChange={SearchChange}
              RefreshChange={RefreshChange}
              factoryOptions={factoryOptions}
            />
            <VerificationCodeTable
              handleSuccessButtonClick={handleSuccessButtonClick}
              handleDeleteButtonClick={handleDeleteButtonClick}
              page={pageInfo.page}
              loading={loading}
            />
            <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2 }}>
              <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
            </Grid>
          </>
        }
      />
    </>
  );
}
