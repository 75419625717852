import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { SpecimenApi } from '@Src/_basic/protocol/specimen/SpecimenApi';
import { Grid, Pagination, SelectChangeEvent, Stack } from '@mui/material';

import SearchIcon from '@Src/_basic/icons/Search';

import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import { BasicSpecimensResponse, SearchChemGroupData, SearchSpecimenData } from '@Src/_basic/object/SpecimenType';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import TestManageDetailModal from '../components/TestManageDetailModal';
import TestManageTable from '../components/TestManageTable';

const background = '/media/images/applicationsMange/materialReceiptsBG.png';

interface AreaOptions extends Options {
  factories: Array<Options>;
}

const initPageInfo = {
  page: 1,
  pageSize: 20,
  total: 0,
  totalCount: 0,
};

export default function TestManageScreen() {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const [dateTime, setDateTime] = useState<Date>(new Date());

  const { userAreaOptionsData } = useAuth();
  const [factories, setFactories] = useState<Array<Options>>([]);
  const [specimenTypes, setSpecimenTypes] = useState<Array<Options>>([]);

  const [selectedArea, setSelectedArea] = useState<number>(0);
  const [selectedFactory, setSelectedFactory] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<number>(0);

  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [selectedTestDetail, setSelectedTestDetail] = useState<SearchSpecimenData>();

  const [allSpecimensData, setAllSpecimensData] = useState<Array<SearchSpecimenData>>([]);

  const [renderChemData, setRenderChemData] = useState<Array<SearchSpecimenData>>([]);
  const [chemGroupDetail, setChemGroupDetail] = useState<Array<Array<SearchChemGroupData>>>([]);

  const [searchInput, setSearchInput] = useState<string>('');
  const [searchData, setSearchData] = useState<Array<SearchSpecimenData>>([]);

  const [pageInfo, setPageInfo] = useState<PageInfo>(initPageInfo);

  const handleDetailButtonClick = useCallback((row: any) => {
    setSelectedTestDetail(row);
    setDetailModalOpen(true);
  }, []);

  const handleSearchButton = useCallback(() => {
    if (selectedArea !== 0 && selectedType !== 0 && selectedFactory !== 0) {
      if (!searchInput) {
        setChemGroupDetail([]);
        searchHandler(selectedType, selectedFactory, dateTime, 1);
      } else {
        setPageInfo((prev) => ({ ...prev, page: 1, total: 1 }));
        SpecimenApi.getSpecimenWithoutPageableBySearch(
          {
            createTime: new Date(dateTime).getTime(),
          },
          (_data) => {
            const chemList: Array<BasicSpecimensResponse> = [];
            switch (selectedType) {
              case 1:
                setSearchData(_data.filter((item) => item.specimenType.id === 1));
                break;
              case 7:
                setSearchData(
                  _data
                    .filter((item) => item.specimenType.id === 7)
                    .map((item) => ({ ...item, controlGroupId: item.id })),
                );
                break;
              case 8:
                setSearchData(
                  _data
                    .filter((item) => item.specimenType.id === 8)
                    .map((item) => ({ ...item, controlGroupId: item.id })),
                );
                break;
              case 5:
                setSearchData(
                  _data
                    .filter((item) => item.specimenType.id === 5)
                    .map((item, index) => ({ ...item, index: index + 1 })),
                );
                break;
              case 9:
                chemGroupDetail.forEach((item) => {
                  Object.values(item).map((_item) => {
                    const type = _item.chemType;
                    Object.values(_item).forEach((obj) => {
                      if (Array.isArray(obj)) {
                        obj.forEach((_obj) =>
                          chemList.push({
                            ..._obj,
                            chemType: type,
                          }),
                        );
                      } else {
                        if (typeof obj !== 'string') {
                          chemList.push({
                            ...obj,
                            chemType: type,
                            factoryName: obj.factoryName ?? obj.specimenType.id === 10 ? '空白組' : '原樣組',
                          });
                        }
                      }
                    });
                  });
                });
                setSearchData(chemList);
                break;
            }
          },
        );
      }
    }
  }, [selectedArea, selectedType, selectedFactory, searchInput, dateTime, chemGroupDetail]);

  const handleReset = useCallback(() => {
    setSelectedType(0);
    setSelectedFactory(0);
    setAllSpecimensData([]);
    setSearchData([]);
    setSearchInput('');
    setPageInfo((prev) => ({ ...prev, page: 1, total: 0 }));
  }, []);

  const searchHandler = useCallback((selectedType: number, selectedFactory: number, dateTime: Date, page: number) => {
    SpecimenApi.getSpecimenBySearch(
      {
        page: page,
        factoryId: selectedFactory,
        createTime: new Date(dateTime).getTime(),
        timeZone: 'Asia/Taipei',
        specimenTypeId: selectedType,
        ...(selectedType === 5 && { isShipmentRequired: false }),
      },
      (_data) => {
        if (selectedType !== 9) {
          setPageInfo((prev) => ({ ...prev, page: page, total: _data.maxPage }));
          setAllSpecimensData(
            _data.specimens.map((item, index) => ({
              ...item,
              index: index + 1 + (page - 1) * 10,
              controlGroupId: item.controlGroupId ?? item.id,
            })),
          );
        } else {
          if (_data.specimens.length > 0) {
            const typeGroupId = new Set();

            _data.specimens.forEach((item) =>
              typeGroupId.has(item.controlGroupId) ? null : typeGroupId.add(item.controlGroupId),
            );

            Array.from(typeGroupId).forEach((item) => {
              SpecimenApi.getChemGroupBySearch(
                {
                  specimenId: item as string,
                },
                (_chemData) => {
                  setChemGroupDetail((prev) => [...prev, _chemData?.filter((_item) => _item.chemType !== null)]);
                },
              );
            });
            setPageInfo((prev) => ({ ...prev, page: 1, total: typeGroupId.size }));
          }
        }
      },
    );
  }, []);

  const handlePageChange = useCallback(
    (e: React.ChangeEvent<unknown>, page: number) => {
      setPageInfo({
        ...pageInfo,
        page,
      });

      if (selectedType !== 9) searchHandler(selectedType, selectedFactory, dateTime, page);
    },
    [pageInfo, selectedFactory, dateTime, selectedType],
  );

  const handleSelectArea = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      handleReset();
      setSelectedArea(e.target.value as number);
      const selected = userAreaOptionsData.find((i) => i.value === e.target.value);
      if (selected) setFactories(selected.factories);
    },
    [userAreaOptionsData],
  );

  const handleSelectFactory = useCallback((e: SelectChangeEvent<unknown>) => {
    handleReset();
    setSelectedFactory(e.target.value as number);
  }, []);

  const getSpecimenType = useCallback(() => {
    SpecimenApi.getSpecimenType((_data) => {
      setSpecimenTypes(
        _data.slice(0, 5).reduce(
          (prev, { id, typeName }) => [
            ...prev,
            {
              value: id === 4 ? 9 : id === 2 ? 7 : id === 3 ? 8 : id,
              text: id === 4 ? '藥劑六項試驗' : typeName,
            },
          ],
          [] as Array<Options>,
        ),
      );
    });
  }, []);

  useEffect(() => {
    handleReset();
    getSpecimenType();
  }, []);

  useEffect(() => {
    if (selectedArea !== 0 && selectedType !== 0 && selectedFactory !== 0) {
      setAllSpecimensData([]);
      setChemGroupDetail([]);
      setSearchData([]);
      setSearchInput('');
      setPageInfo(initPageInfo);
      searchHandler(selectedType, selectedFactory, dateTime, 1);
    }
  }, [selectedType, selectedFactory, dateTime]);

  useEffect(() => {
    setRenderChemData([]);
    if (selectedType !== 9) return;
    if (chemGroupDetail.length > 0 && chemGroupDetail.length === pageInfo.total) {
      chemGroupDetail[pageInfo.page - 1]?.forEach((item) => {
        Object.values(item)?.forEach((_item) => {
          if (Array.isArray(_item)) _item.forEach((_data) => setRenderChemData((prev) => [...prev, _data]));
          else {
            if (typeof _item !== 'string') {
              setRenderChemData((prev) => [
                ...prev,
                {
                  ..._item,
                  factoryName: _item.factoryName ?? _item.specimenType.id === 10 ? '空白組' : '原樣組',
                },
              ]);
            }
          }
        });
      });
    }
  }, [chemGroupDetail, pageInfo]);

  useEffect(() => {
    if (selectedType === 9 && renderChemData.length > 0) {
      setAllSpecimensData(renderChemData);
    }
  }, [renderChemData]);

  useEffect(() => {
    if (searchData) {
      const upperSearchInput = searchInput.toUpperCase();
      switch (selectedType) {
        case 1:
        case 7:
        case 8:
          setAllSpecimensData(
            searchData.filter((item) => {
              if (
                (item.specificationName && item.specificationName.toUpperCase().includes(upperSearchInput)) ||
                (item.sourceName && item.sourceName.toUpperCase().includes(upperSearchInput)) ||
                (item.supplierName && item.supplierName.toUpperCase().includes(upperSearchInput)) ||
                (item.storageName && item.storageName.toUpperCase().includes(upperSearchInput))
              )
                return item;
            }),
          );
          break;
        case 5:
          setAllSpecimensData(
            searchData.filter((item) => {
              if (
                (item.civilEngrName && item.civilEngrName.toUpperCase().includes(upperSearchInput)) ||
                (item.plateNumber && (item.plateNumber as string).toUpperCase().includes(upperSearchInput)) ||
                (item.expectedCompStr && item.expectedCompStr.toString().includes(upperSearchInput)) ||
                (item.propName && item.propName.toUpperCase().includes(upperSearchInput))
              )
                return item;
            }),
          );
          break;
        case 9:
          setAllSpecimensData(
            searchData.filter((item) => {
              if (
                (item.chemType && item.chemType.toUpperCase().includes(upperSearchInput)) ||
                (item.factoryName && item.factoryName.toUpperCase().includes(upperSearchInput)) ||
                (item.areaName && item.areaName.toUpperCase().includes(upperSearchInput)) ||
                (item.specificationName && item.specificationName.toUpperCase().includes(upperSearchInput)) ||
                (item.sourceName && item.sourceName.toUpperCase().includes(upperSearchInput)) ||
                (item.supplierName && item.supplierName.toUpperCase().includes(upperSearchInput))
              )
                return item;
            }),
          );
          break;
      }
    }
  }, [searchData]);

  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TITLE')}
      backgroundImage={background}
      body={
        <Stack>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} sx={{ py: 1 }}>
              <YatungDateSelect
                label=""
                disabled={actionLoading}
                value={dateTime}
                disableFuture={true}
                onChange={(val: Date | null) => {
                  if (val) setDateTime(val);
                }}
              />
              <YatungSelect
                disabled={actionLoading}
                options={userAreaOptionsData}
                value={selectedArea}
                onChange={handleSelectArea}
              />
              <YatungSelect
                disabled={selectedArea === 0 || actionLoading}
                options={factories}
                value={selectedFactory}
                onChange={handleSelectFactory}
              />
              <YatungSelect
                disabled={selectedFactory === 0 || actionLoading}
                options={specimenTypes}
                value={selectedType}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setPageInfo({ ...pageInfo, page: 1 });
                  setSelectedType(e.target.value as number);
                }}
              />
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <YatungInput value={searchInput} onChange={(e: any) => setSearchInput(e.target.value)} />
              <YatungButton
                text={i18T('FACTORYMANAGEMENT.SEARCh_BUTTON')}
                startIcon={<SearchIcon />}
                color="blue"
                onClick={handleSearchButton}
              />
            </Stack>
          </Stack>
          <TestManageTable
            rows={allSpecimensData}
            pageInfo={pageInfo}
            selectedType={selectedType}
            onViewDetail={handleDetailButtonClick}
            searchData={searchData}
          />
          <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2 }}>
            <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
          </Grid>
          {detailModalOpen && (
            <TestManageDetailModal
              selectedTestDetail={selectedTestDetail}
              selectedFactory={selectedFactory}
              onClose={() => setDetailModalOpen(false)}
              open={detailModalOpen}
              updateFn={() => searchHandler(selectedType, selectedFactory, dateTime, 1)}
            />
          )}
        </Stack>
      }
    />
  );
}
