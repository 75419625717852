import React from 'react';
import SiteManagementUpdateCard from '../components/SiteManagementUpdateCard';

export default function SiteManagementUpdateScreen() {
  return (
    <>
      <SiteManagementUpdateCard />
    </>
  );
}
