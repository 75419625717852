import React from 'react';
import AssignGroupManagementUpdateCard from '../components/AssignGroupManagementUpdateCard';

export default function AssignGroupManagementUpdateCardScreen() {
  return (
    <>
      <AssignGroupManagementUpdateCard />
    </>
  );
}
