import React from 'react';
import { EquipmentData } from '@Src/_basic/object/EquipmentManagementType';
import { Typography } from '@mui/material';

interface Props {
  row: EquipmentData;
}
export default function PropertyCodeColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.propertyCode}</Typography>;
}
