import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import OrganizeManagementScreen from './screens/OrganizeManagementScreen';

const configRoutes: RouteConfig[] = [
  {
    path: '/',
    element: <OrganizeManagementScreen />,
  },
];

const OrganizeManagementRouter = () => PermissionRoute(configRoutes);

export default memo(OrganizeManagementRouter);
