import YatungImg from '@Src/_basic/components/YatungImg';
import { MonitorOrderData } from '@Src/_basic/object/MonitorType';
import { Grid } from '@mui/material';
import React from 'react';

interface Props {
  data: MonitorOrderData | undefined;
  imageUrl: string[];
}

export default function ViewImageField(props: Props) {
  const { data, imageUrl } = props;
  console.log('拿到的URL', data);
  // const ModalIcon = () => (

  //   // <YatungImg
  //   //   img={`${data?.imageUrlFlow}`}
  //   //   sx={{
  //   //     width: 480,
  //   //     height: 480,
  //   //     ml: 7,
  //   //     mt: 7,
  //   //   }}
  //   // />
  // );

  return (
    <>
      <Grid container spacing={2}>
        {imageUrl.map((url, index) => (
          <div key={index}>
            <YatungImg
              img={`${url}`}
              sx={{
                width: 480,
                height: 480,
                ml: 7,
                mt: 7,
              }}
            />
          </div>
        ))}
      </Grid>
    </>
  );
}
