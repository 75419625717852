import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function EditV2(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5848 3.58579C18.3658 2.80474 19.6322 2.80474 20.4132 3.58579L21.0848 4.25736C21.8658 5.03841 21.8658 6.30474 21.0848 7.08579L13.0858 14.6719C12.7107 15.0469 12.202 15.2577 11.6716 15.2577H10C9 15.1719 9 15.1719 9 14.2577V12.5861C9 12.0556 9.21071 11.5469 9.58579 11.1719L17.5848 3.58579ZM12.3787 13.9648C12.1911 14.1523 11.9368 14.2577 11.6716 14.2577H10V12.5861C10 12.3209 10.1054 12.0665 10.2929 11.879L18.2919 4.29289C18.6824 3.90237 19.3156 3.90237 19.7061 4.29289L20.3777 4.96447C20.7682 5.35499 20.7682 5.98816 20.3777 6.37868L12.3787 13.9648Z"/>
      <path d="M12 3.5C12 3.77615 11.7761 4 11.5 4H4C3.44772 4 3 4.44772 3 5V20.0001C3 20.5523 3.44772 21.0001 4 21.0001H19C19.5523 21.0001 20 20.5523 20 20.0001V12.5001C20 12.2239 20.2239 12.0001 20.5 12.0001C20.7761 12.0001 21 12.2239 21 12.5001V20.0001C21 21.1046 20.1046 22.0001 19 22.0001H4C2.89543 22.0001 2 21.1046 2 20.0001V5C2 3.89543 2.89543 3 4 3H11.5C11.7761 3 12 3.22386 12 3.5Z"/>
    </SvgIcon>
  );
}
