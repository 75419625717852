import { Stack } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import NewStaffIcon from '@Src/_basic/icons/NewStaff';
import SearchIcon from '@Src/_basic/icons/Search';

interface Props {
  searchInput: string;
  searchInputHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  getAllApiGroup: () => void;
  openCreateCard: () => void;
}

export default function ApiGroupManagementSearchBar({
  searchInput,
  searchInputHandler,
  getAllApiGroup,
  openCreateCard,
}: Props) {
  const { t: i18T } = useTranslation();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" spacing={2} sx={{ py: 1.5 }} alignItems="center">
        <YatungInput value={searchInput} onChange={searchInputHandler} />
        <YatungButton
          text={i18T('GLOBAL.SEARCh_BUTTON')}
          startIcon={<SearchIcon />}
          color="blue"
          onClick={getAllApiGroup}
        />
      </Stack>
      <Stack direction="row" alignItems="center">
        <YatungButton
          text={i18T('USERSMANAGEMENU.API_GROUP_MANAGEMENT.CREATE_GROUP')}
          startIcon={<NewStaffIcon />}
          onClick={openCreateCard}
          color="green"
          sx={{ fontSize: '16px' }}
        />
      </Stack>
    </Stack>
  );
}
