import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function User(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="31" height="32" viewBox="0 0 31 32" fill="none">
      <path
        d="M15.1564 13.9355C18.987 13.9355 22.0924 10.8159 22.0924 6.96774C22.0924 3.11957 18.987 0 15.1564 0C11.3258 0 8.22039 3.11957 8.22039 6.96774C8.22039 10.8159 11.3258 13.9355 15.1564 13.9355Z"
        fill="white"
      />
      <path
        d="M30.8265 30.9677C30.8265 31.3146 30.8152 31.6588 30.7927 32H0.033796C0.01136 31.6588 0 31.3146 0 30.9677C0 22.4162 6.90078 15.4839 15.4133 15.4839C23.9258 15.4839 30.8265 22.4162 30.8265 30.9677Z"
        fill="white"
      />
    </SvgIcon>
  );
}
