import React from 'react';

export default function Source() {
  return (
    <svg width={24} height={25} viewBox="0 0 24 25" fill="none">
      <path
        d="M17.7834 1.13824C18.8387 1.44335 19.8711 1.85366 20.8619 2.36032C20.0882 2.28186 19.3121 2.25931 18.5458 2.29401L16.9956 2.36422L18.2952 3.21224C21.4312 5.25861 23.5 8.7872 23.5 12.7958C23.5 18.376 19.4895 23.027 14.1784 24.0405L14.1612 24.0438L14.1442 24.0483C13.3857 24.2491 12.5963 24.3844 11.7845 24.4513C9.95729 24.6019 8.05359 24.4028 6.18266 23.8618L6.18264 23.8618C5.12732 23.5566 4.09493 23.1463 3.10412 22.6397C3.60949 22.6909 4.11582 22.7183 4.61975 22.7215L6.0929 22.7307L4.92974 21.8267C2.23227 19.7301 0.5 16.4643 0.5 12.7958C0.5 9.71916 1.71819 6.92647 3.70211 4.86847L3.7185 4.85147L3.73321 4.833C4.64772 3.68501 5.79874 2.7275 7.13761 2.00626L7.13761 2.00626C8.64135 1.19619 10.3545 0.699249 12.1815 0.548704C14.0087 0.398146 15.9124 0.597216 17.7834 1.13823L17.7834 1.13824ZM12 18.6732C15.2565 18.6732 17.9 16.0438 17.9 12.7958C17.9 9.54784 15.2565 6.91843 12 6.91843C8.74351 6.91843 6.1 9.54784 6.1 12.7958C6.1 16.0438 8.74351 18.6732 12 18.6732Z"
        stroke="#6C708C"
      />
    </svg>
  );
}
