import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungPage from '@Src/_basic/components/YatungPage';
import { MaterialInspectionArea } from '@Src/_basic/object/MaterialInspectionType';
import { ExptMaterialInspectionApi } from '@Src/_basic/protocol/exptMaterialInspection/ExptMaterialInspectionApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useMaterialInspection } from '@Src/redux/materialInspection/materialInspectionActions';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MaterialInspectionByAreaCard from '../components/MaterialInspectionByAreaCard';

export default function MaterialInspectionAreaScreen() {
  const { t: i18T } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { userGroupsData } = useAuth();
  const { setDate, date } = useMaterialInspection();
  const [materialInspectionAreaData, setMaterialInspectionAreaData] = useState<Array<MaterialInspectionArea>>([]);
  const handleDateChange = (value: unknown) => {
    setDate(value);
  };

  useEffect(() => {
    setLoading(true);
    ExptMaterialInspectionApi.getExptMaterialInspectionByArea(
      { currTime: date, factoryIds: userGroupsData?.factories.map((item) => item.value) },
      (data: Array<MaterialInspectionArea>) => {
        setMaterialInspectionAreaData(data);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  }, [date]);
  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.MATERIAL_INSPECTION_PASS_MONITORING.TITLE')}
      body={
        <>
          <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ py: 1 }}>
            <YatungDateSelect
              disabled={loading}
              value={date}
              disableFuture={true}
              onChange={(val) => val && handleDateChange(new Date(val).getTime())}
            />
          </Stack>
          <MaterialInspectionByAreaCard materialInspectionAreaData={materialInspectionAreaData} loading={loading} />
        </>
      }
      contentBgColor="#fff"
    />
  );
}
