import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import FactoryDeviationScreen from './screens/FactoryDeviationScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <FactoryDeviationScreen />,
  },
];

const FactoryDeviationRouter = () => PermissionRoute(routesConfig);

export default memo(FactoryDeviationRouter);
