import React from 'react';
import FactoryManagementCard from '../components/FactoryManagementCard';

export default function FactoryManagementCardScreen() {
  return (
    <>
      <FactoryManagementCard />
    </>
  );
}
