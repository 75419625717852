import { Protocol } from '../Protocol';

export class FactoryProtocol {
  public static readonly ALL_FACTORY: string = `${Protocol.YATUNG_API_ROOT}/factory/all`;
  public static readonly GET_FACTORY_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/factory`;
  public static readonly GET_FACTORY_WITHOUT_MIXER: string = `${Protocol.YATUNG_API_ROOT}/factory/without-mixer`;

  public static readonly FACTORY_MANAGEMENT_DATA: string = `api/factorymanagement`;
  public static readonly FACTORY_MANAGEMENT_GET_ID: string = 'api/factorymanagement/getId';
  public static readonly FACTORY_MANAGEMENT_CREATE_DATA: string = `api/factorymanagement/new`;
  public static readonly FACTORY_MANAGEMENT_UPDATE_DATA: string = `api/factorymanagement/update`;
  public static readonly FACTORY_MANAGEMENT_UPDATE_STATE: string = `api/factorymanagement/update/state`;
}
