import { SpecimenApi } from '@Src/_basic/protocol/specimen/SpecimenApi';
import { Box, OutlinedInput, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TestManageDetailItem from './TestManageDetailItem';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungSelect, { AccordionModelMenuItem, DropdownItem } from '@Src/_basic/components/YatungSelect';
import YatungTextInput from '@Src/_basic/components/YatungTextInput';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungRadioButton from '@Src/_basic/components/YatungRadioButton';
import TestDetailDialog from '@Src/_basic/components/YatungTest/testDetail/TestDetailDialog';
import { caculateFutureDate } from '@Src/_basic/helpers/TimeCalculate';
import CalendarIcon from '@Src/_basic/icons/Calendar';
import ClockIcon from '@Src/_basic/icons/Clock';
import ContentMangementIcon from '@Src/_basic/icons/ContentMangement';
import LandMark from '@Src/_basic/icons/LandMark';
import ListTitle from '@Src/_basic/icons/ListTitle';
import Person from '@Src/_basic/icons/Person';
import SourceIcon from '@Src/_basic/icons/Source';
import CreateIcon from '@Src/_basic/icons/StorageYardCreateTime';
import WeightIcon from '@Src/_basic/icons/Weight';
import { GroupBasicInfo } from '@Src/_basic/object/AuthType';
import {
  BasicSpecimensResponse,
  SearchSpecimenData,
  SpecimenExptIdAndCodeResponse,
} from '@Src/_basic/object/SpecimenType';
import { useAuth } from '@Src/redux/auth/authActions';
import moment from 'moment';

export interface NoticeDate {
  day: number;
  date: Date;
}

interface Props {
  selectedTestDetail: SearchSpecimenData;
  onClose: () => void;
  selectedFactory: number;
}

const ChemDetail = ({ selectedTestDetail, onClose, selectedFactory }: Props) => {
  const { t: i18T } = useTranslation();
  const { userGroupsData } = useAuth();

  const [specimenGroupData, setSpecimenGroupData] = useState<Array<BasicSpecimensResponse>>();
  const [exptIdAndCode, setExptIdAndCode] = useState<SpecimenExptIdAndCodeResponse>(); // 試驗Id和Code
  const [open, setOpen] = useState<boolean>(false); // 是否開啟試驗單Dialog

  const [orderType, setOrderType] = useState<string>('demand_order');
  const [
    [
      sevenNotificationDate,
      sevenDate,
      sevenEndTimeDate,
      twentyEightNotificationDate,
      twentyEightDate,
      twentyEightEndTimeDate,
    ],
    setFutureDate,
  ] = useState<Array<number>>([]);
  const [allSpecimenNum, setAllSpecimenNum] = useState<number>(0);
  const [operatorList, setOperatorList] = useState<Array<AccordionModelMenuItem<number, string>>>([]);
  const [operatorGroup, setOperatorGroup] = useState<Array<DropdownItem<string>>>([]);
  const [operatorPerson, setOperatorPerson] = useState<string | number>(0);
  const [remark, setRemark] = useState<string>('');

  const [alertType, setAlertType] = useState<'alert' | 'confirm'>('alert');
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  // 試驗類型onChange Function
  const handleOrderType = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setOrderType(e.target.value), []);

  const selectOperatorGroupHandler = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setOperatorPerson(0);
      const group = operatorList.find((item) => item.value === e.target.value)?.extendValue;
      if (group) setOperatorGroup(group);
    },
    [operatorList],
  );

  const submitHandler = useCallback(() => {
    if (!userGroupsData || (orderType === 'demand_order' && !operatorPerson)) return;
    SpecimenApi.postChemStrTest(
      {
        createrId: userGroupsData.account,
        factoryId: selectedFactory,
        specimenId: selectedTestDetail.controlGroupId,
        ...(orderType === 'demand_order'
          ? {
              startTime: sevenDate,
              endTime: twentyEightEndTimeDate,
              operatorId: operatorPerson as string,
              notificationTimes: [
                {
                  age: 7,
                  exptNotificationTime: sevenNotificationDate,
                },
                {
                  age: 28,
                  exptNotificationTime: twentyEightNotificationDate,
                },
              ],
            }
          : {
              operatorId: userGroupsData.account,
              notificationTimes: [
                {
                  age: 7,
                  exptNotificationTime: new Date().getTime(),
                },
                {
                  age: 28,
                  exptNotificationTime: new Date().getTime(),
                },
              ],
            }),
      },
      () => onClose(),
      (_err) => {
        setAlertType('alert');
        setAlertText(typeof _err.message === 'string' && _err.message.length > 0 ? _err.message : 'Error');
        setIsShowAlert(true);
      },
    );
  }, [
    sevenDate,
    twentyEightEndTimeDate,
    userGroupsData,
    selectedFactory,
    orderType,
    selectedTestDetail,
    operatorPerson,
    onClose,
    sevenNotificationDate,
    twentyEightNotificationDate,
  ]);

  // 確認試體是否已經開過試驗單
  const checkUsedInExpt = useCallback(() => {
    if (orderType === 'demand_order' && !operatorPerson) {
      setAlertType('alert');
      setAlertText('請選擇指派對象');
      setIsShowAlert(true);
      return;
    }
    const specimenId = specimenGroupData?.find((item) => item.specimenType.id === 9)?.controlGroupId;
    if (specimenId) {
      SpecimenApi.getCheckUsedInExpt({ specimenId }, (_data) => {
        if (_data) {
          setAlertType('confirm');
          setIsShowAlert(true);
          setAlertText(`此試體已被開過試驗\n請確認是否要繼續開試驗單`);
        } else submitHandler();
      });
    }
  }, [specimenGroupData, operatorPerson, submitHandler, orderType]);

  // 防止28天通知小於7天
  // const sevenDateNotificaiton = useCallback(
  //   (val: Date | null) => {
  //     const _28time = new Date(noticeDates.find((item) => item.day === 28)?.date ?? new Date()).getTime();
  //     if (val && _28time) {
  //       if (new Date(val).getTime() > _28time) {
  //         setNoticeDates((prev) => prev.map((_item) => ({ ..._item, date: val })));
  //       } else {
  //         setNoticeDates((prev) => prev.map((_item) => (_item.day === 7 ? { ..._item, date: val } : _item)));
  //       }
  //     }
  //   },
  //   [noticeDates],
  // );

  useEffect(() => {
    if (!selectedTestDetail || !selectedFactory) return;

    // 取得試驗單Id和Code
    SpecimenApi.getSpecimenExptIdAndCode(
      {
        specimenId: selectedTestDetail.id,
        factoryId: selectedFactory,
      },
      (_data) => {
        if (_data.exptId) setExptIdAndCode(_data);
      },
    );

    // 取得藥劑試體群組
    SpecimenApi.getChemGroupBySearch(
      {
        specimenId: selectedTestDetail.id,
      },
      (_data) => {
        const newArr: Array<BasicSpecimensResponse> = [];
        Object.values(_data).forEach((item) => {
          if (item.chemType !== null) {
            newArr.push(item.controlGroup, item.standardTarget);
            item.experimentalGroup.forEach((item: BasicSpecimensResponse) => newArr.push(item));
          }
        });
        const filterData = newArr
          .filter((item) => item.chemType === selectedTestDetail.chemType)
          .sort((a, b) => b.specimenType.id - a.specimenType.id);
        setAllSpecimenNum(filterData.reduce((prev, curr) => prev + curr.specimenNum, 0));
        setSpecimenGroupData(filterData);
      },
    );

    setFutureDate([
      caculateFutureDate(selectedTestDetail.createTime, 6, true),
      caculateFutureDate(selectedTestDetail.createTime, 7, true),
      caculateFutureDate(selectedTestDetail.createTime, 21, true),
      caculateFutureDate(selectedTestDetail.createTime, 27, true),
      caculateFutureDate(selectedTestDetail.createTime, 28, true),
      caculateFutureDate(selectedTestDetail.createTime, 42, true),
    ]);
  }, [selectedTestDetail, selectedFactory, orderType]);

  useEffect(() => {
    if (!userGroupsData) return;
    const afterOperatorList = userGroupsData.supervisorAssignGroups.reduce(
      (prev: Array<AccordionModelMenuItem<number, string>>, curr: GroupBasicInfo<DropdownItem<string>>) => [
        ...prev,
        { text: curr.groupName, value: curr.id, extendValue: curr.users },
      ],
      [] as Array<AccordionModelMenuItem<number, string>>,
    );
    setOperatorList(afterOperatorList);
  }, [userGroupsData]);

  return (
    <Stack direction="row" width="100%">
      <Stack sx={{ p: 2, bgcolor: 'white', color: 'gray', width: '50%', maxHeight: 650, overflow: 'auto' }}>
        {specimenGroupData?.map((item, index) => (
          <Box
            sx={{
              borderBottom: 1,
              borderBottomStyle: index === specimenGroupData.length - 1 ? '' : 'dashed',
              marginBottom: 2,
            }}
            key={index}
          >
            <TestManageDetailItem
              Icon={ClockIcon}
              label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TIME')}
              value={moment(selectedTestDetail.createTime).format('YYYY/MM/DD HH:mm')}
            />
            <TestManageDetailItem
              Icon={ListTitle}
              label={i18T('APPLICATIONSMANAGEMENT.TABLE.CHEM_TYPE')}
              value={item.chemType}
            />
            <TestManageDetailItem Icon={CalendarIcon} label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.PLATE_NUMBER')}>
              <>
                {(item.plateNumber as Array<string>)?.map((_plateNumber) =>
                  _plateNumber ? (
                    <Stack key={_plateNumber} width={100}>
                      <Stack flexDirection="row" flexWrap="wrap" alignItems="center">
                        <Typography sx={{ fontSize: 18, borderBottom: '1px solid #eee', flex: 1, textAlign: 'center' }}>
                          {_plateNumber ?? i18T('GLOBAL.NO_INFO')}
                        </Typography>
                      </Stack>
                    </Stack>
                  ) : null,
                )}
              </>
            </TestManageDetailItem>
            <Stack direction="row">
              <TestManageDetailItem
                Icon={LandMark}
                label={i18T('APPLICATIONSMANAGEMENT.TABLE.TEST_AREA_NAME')}
                value={item.specificationName}
              />
              <TestManageDetailItem
                Icon={ContentMangementIcon}
                label={i18T('APPLICATIONSMANAGEMENT.TABLE.SPECIFICATION')}
                value={item.specificationName}
              />
            </Stack>
            <Stack direction="row">
              <TestManageDetailItem
                Icon={SourceIcon}
                label={i18T('APPLICATIONSMANAGEMENT.TABLE.SOURCE')}
                value={item.sourceName}
              />
              <TestManageDetailItem
                Icon={Person}
                label={i18T('APPLICATIONSMANAGEMENT.TABLE.SUPPLIER_NAME')}
                value={item.supplierName}
              />
            </Stack>
            <TestManageDetailItem
              Icon={CreateIcon}
              label={
                item.specimenType.id === 10
                  ? i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.SPACE_GROUP_TEST')
                  : item.specimenType.id === 9
                  ? i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.BASIE_GROUP_TEST')
                  : i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.FACTORY_TEST', { factory: item.factoryName })
              }
            >
              <YatungTextInput
                value={item.specimenNum}
                disabled={true}
                onChange={() => ({})}
                size="small"
                style={{
                  width: '30%',
                }}
              />
            </TestManageDetailItem>
          </Box>
        ))}
        <Stack spacing={2} width="100%" sx={{ mb: 2 }}>
          <Typography>{`${i18T('GLOBAL.REMARK')}:`}</Typography>
          <OutlinedInput rows={7} multiline={true} color="primary" onChange={(e) => setRemark(e.target.value)} />
        </Stack>
      </Stack>
      <Stack
        sx={{ p: 2, bgcolor: 'white', color: 'gray', height: '100%', width: '50%', maxHeight: 650, overflow: 'auto' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: 2, mb: 3, gap: 3 }}>
          {orderType === 'demand_order' ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 1 }}>
              <YatungSelect
                width={'100%'}
                options={operatorList}
                placeholder={`${i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.CHOOSE_OPERATOR_GROUP')}...`}
                onChange={selectOperatorGroupHandler}
              />
              <YatungSelect
                disabled={operatorGroup.length <= 0}
                width={'100%'}
                options={operatorGroup}
                placeholder={`${i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.CHOOSE_OPERATOR_PERSON')}...`}
                value={operatorPerson}
                onChange={(e) => setOperatorPerson(e.target.value as string)}
              />
            </Box>
          ) : null}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <YatungRadioButton
              label={i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.STRENGTH_TYPE')}
              onChange={handleOrderType}
              value={orderType}
              groupName={'order-group'}
              controlData={[
                {
                  label: i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.DEMAND_ORDER'),
                  value: 'demand_order',
                },
                {
                  label: i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_ORDER'),
                  value: 'test_order',
                },
              ]}
            />
          </Box>
          {orderType === 'demand_order' ? (
            <>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack width={'40%'}>
                  <Typography sx={{ fontSize: '20px' }}>
                    {`7天${i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_EXECUTE_DATE')}:`}
                  </Typography>
                </Stack>
                <Stack width={'40%'}>
                  <YatungDateSelect
                    label=""
                    fullWidth={true}
                    disabled={true}
                    value={new Date(sevenNotificationDate)}
                    onChange={() => ({})}
                    icon={WeightIcon}
                  />
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack width={'40%'}>
                  <Typography sx={{ fontSize: '20px' }}>
                    {`28天${i18T('APPLICATIONSMANAGEMENT.TEST_MANAGE.TEST_EXECUTE_DATE')}:`}
                  </Typography>
                </Stack>
                <Stack width={'40%'}>
                  <YatungDateSelect
                    label=""
                    fullWidth={true}
                    disabled={true}
                    value={new Date(twentyEightNotificationDate)}
                    onChange={() => ({})}
                    icon={WeightIcon}
                  />
                </Stack>
              </Stack>
            </>
          ) : null}
          <Box display="flex" flexDirection="column" sx={{ gap: 1.5, p: 2, borderBottom: '1px solid gray' }}>
            <Typography>{`第7天抗壓試驗 :`}</Typography>
            <Stack display="flex" flexDirection="row" justifyContent="space-around" alignItems="center">
              <Typography>{'執行顆數:'}</Typography>
              <Stack display="flex" flexDirection="row" alignItems="center">
                <YatungTextInput
                  value={allSpecimenNum % 2 === 0 ? allSpecimenNum / 2 : allSpecimenNum / 2 + 0.5}
                  disabled={true}
                  onChange={() => ({})}
                  size="small"
                  style={{ marginRight: 2, width: '50%' }}
                  text="顆"
                />
              </Stack>
            </Stack>
            <Stack display="flex" flexDirection="row" alignItems="center" sx={{ mt: 1 }} gap={4}>
              {orderType === 'demand_order' ? (
                <>
                  <YatungDateSelect
                    fullWidth
                    label={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.START')}
                    value={new Date(sevenDate)}
                    onChange={() => ({})}
                    disabled={true}
                  />
                  <YatungDateSelect
                    fullWidth
                    label={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.OVER')}
                    value={new Date(sevenEndTimeDate)}
                    onChange={() => ({})}
                    disabled={true}
                  />
                </>
              ) : null}
            </Stack>
          </Box>
          <Box display="flex" flexDirection="column" sx={{ gap: 1.5, p: 2, borderBottom: '1px solid gray' }}>
            <Typography>{`第28天抗壓試驗 :`}</Typography>
            <Stack display="flex" flexDirection="row" justifyContent="space-around" alignItems="center">
              <Typography>{'執行顆數:'}</Typography>
              <Stack display="flex" flexDirection="row" alignItems="center">
                <YatungTextInput
                  value={allSpecimenNum % 2 === 0 ? allSpecimenNum / 2 : allSpecimenNum / 2 - 0.5}
                  disabled={true}
                  onChange={() => ({})}
                  size="small"
                  style={{ marginRight: 2, width: '50%' }}
                  text="顆"
                />
              </Stack>
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 1 }}
              gap={4}
            >
              {orderType === 'demand_order' ? (
                <>
                  <YatungDateSelect
                    fullWidth
                    label={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.START')}
                    value={new Date(twentyEightDate)}
                    onChange={() => ({})}
                    disabled={true}
                  />
                  <YatungDateSelect
                    fullWidth
                    label={i18T('APPLICATIONSMANAGEMENT.TOOLBAR.OVER')}
                    value={new Date(twentyEightEndTimeDate)}
                    onChange={() => ({})}
                    disabled={true}
                  />
                </>
              ) : null}
            </Stack>
          </Box>
        </Box>
        <Stack display="flex" direction="row" justifyContent="flex-end">
          <Stack direction="row" gap={4}>
            {exptIdAndCode && (
              <YatungButton text={i18T('GLOBAL.DETAIL')} onClick={() => setOpen(true)} color="yellow" width={90} />
            )}
            <YatungButton text={i18T('GLOBAL.CANCEL')} onClick={onClose} color="red" width={90} />
            <YatungButton text={i18T('GLOBAL.FINISH')} onClick={checkUsedInExpt} color="blue" width={90} />
          </Stack>
        </Stack>
      </Stack>
      <YatungConfirmAlert
        isShowAlert={isShowAlert}
        alertText={alertText}
        confirmTitle="確認"
        onOk={alertType === 'alert' ? undefined : submitHandler}
        onClose={() => setIsShowAlert(false)}
      />
      {exptIdAndCode && open && (
        <TestDetailDialog
          exptId={exptIdAndCode?.exptId}
          exptItemCode={exptIdAndCode?.exptItemCode}
          allTable={true}
          open={open}
          closeHandler={() => setOpen(false)}
        />
      )}
    </Stack>
  );
};

export default memo(ChemDetail);
