import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable, { FormatterProps } from '@Src/_basic/components/YatungTable';
import { numberComma } from '@Src/_basic/helpers/NumberHelpers';
import ViewIcon from '@Src/_basic/icons/View';
import { ProjectData } from '@Src/_basic/object/ProductionHistoryType';
import { useApi } from '@Src/redux/api/apiAction';
import { Stack } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  templateId: string;
  rows: Array<ProjectData>;
  handleSelectPrintProject: (orderId: string, engName: string) => void;
  onGenerate: (row: ProjectData) => void;
  onViewDetail: (row: ProjectData) => void;
}

export default function ProductionHistoryTable({
  rows,
  onGenerate,
  onViewDetail,
  templateId,
  handleSelectPrintProject,
}: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const columns = [
    {
      text: i18T('REPORT.PRODUCTION_HISTORY.TABLE.HEADER.ID'),
      dataField: 'orderId',
      width: '10%',
    },
    {
      text: i18T('REPORT.PRODUCTION_HISTORY.TABLE.HEADER.NAME'),
      dataField: 'engName',
      width: '30%',
      formatter: ({ row }: FormatterProps) => {
        return (
          <>
            {row.engName}
            <span style={{ color: 'red', paddingLeft: 10, letterSpacing: 1.5 }}>{`${
              templateId === row.orderId ? '(模板)' : ''
            }`}</span>
          </>
        );
      },
    },
    {
      text: i18T('REPORT.PRODUCTION_HISTORY.TABLE.HEADER.ADDRESS'),
      dataField: 'address',
      width: '15%',
    },
    {
      text: i18T('REPORT.PRODUCTION_HISTORY.TABLE.HEADER.START_DATE'),
      dataField: 'createDate',
      width: '10%',
      formatter: ({ row }: FormatterProps) => {
        return (
          <>
            {row.createDate ? moment(row.createDate).format('YYYY/MM/DD') : row.profile ? '尚未設定' : '尚未生成履歷'}
          </>
        );
      },
    },
    {
      text: i18T('REPORT.PRODUCTION_HISTORY.TABLE.HEADER.END_DATE'),
      dataField: 'expireDate',
      width: '15%',
      formatter: ({ row }: FormatterProps) => {
        return (
          <>
            {row.expireDate ? moment(row.expireDate).format('YYYY/MM/DD') : row.profile ? '尚未設定' : '尚未生成履歷'}
          </>
        );
      },
    },
    {
      text: i18T('REPORT.PRODUCTION_HISTORY.TABLE.HEADER.SHIPMENT_QUANTITY'),
      dataField: 'deliverAmount',
      width: '10%',
      formatter: ({ row }: FormatterProps) => {
        return <>{numberComma(row.sumqty)}</>;
      },
    },
    {
      text: i18T('REPORT.PRODUCTION_HISTORY.TABLE.HEADER.ACTIONS'),
      dataField: 'action',
      width: '25%',
      formatter: ({ row }: FormatterProps) => {
        return (
          <Stack direction="row" spacing={1}>
            {row.profile ? (
              <>
                <YatungButton
                  text={i18T('REPORT.PRODUCTION_HISTORY.TABLE.ACTIONS.VIEW')}
                  startIcon={<ViewIcon />}
                  color="yellow"
                  onClick={() => {
                    onViewDetail(row);
                  }}
                />
                <YatungButton
                  text={'選擇匯出日期'}
                  startIcon={<ViewIcon />}
                  color="red"
                  onClick={() => {
                    handleSelectPrintProject(row.orderId, row.engName);
                  }}
                />
              </>
            ) : (
              <YatungButton
                text={i18T('REPORT.PRODUCTION_HISTORY.TABLE.ACTIONS.GENERATE')}
                startIcon={<ViewIcon />}
                color="blue"
                onClick={() => {
                  onGenerate(row);
                }}
              />
            )}
          </Stack>
        );
      },
    },
  ];

  return <YatungTable loading={actionLoading} data={rows} columns={columns} />;
}
