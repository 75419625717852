import YatungButton from '@Src/_basic/components/YatungButton';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import { Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import CreateMixer from '@Src/_basic/icons/CreateMixer';
import { useAuth } from '@Src/redux/auth/authActions';
interface Props {
  areaId: number;
  // eslint-disable-next-line no-unused-vars
  getAllMixerBySelectArea: (id: number) => void;
}

export default function MixerInformationtSearchBar({ areaId, getAllMixerBySelectArea }: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { userAreaOptionsData } = useAuth();

  const handleGoToCreatePage = useCallback(() => {
    navigate('/UsersManagePage/MixerInformation/MixerInformationCreate');
  }, [navigate]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" py={2} px={1}>
      <YatungSelect
        helperText={i18T('GLOBAL.AREA')}
        options={userAreaOptionsData}
        value={areaId}
        onChange={(e) => getAllMixerBySelectArea(e.target.value as number)}
      />
      <YatungButton
        text={i18T('USERSMANAGEMENU.MIXER_INFORMATION.CREATE_MIXER_INFORMATION')}
        startIcon={<CreateMixer sx={{ width: '25px', height: '25px' }} />}
        onClick={handleGoToCreatePage}
        color={'green'}
        sx={{
          fontSize: '16px',
        }}
      />
    </Stack>
  );
}
