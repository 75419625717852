import React from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

interface Props {
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  selectedIndex: number;
  tabs: string[];
  fontSize?: number;
}

interface StyledTabProps {
  label: string;
}

const YatungTab = styled((props: StyledTabProps) => <Tab {...props} />)(({ theme }) => ({
  marginRight: '1px',
  padding: '8px 24px',
  backgroundColor: '#C1C1C1',
  color: 'white',
  fontWeight: 900,
  '&:hover': {
    opacity: 1,
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#002F8C',
  },
}));

export default function YatungTabs({ tabs = [], fontSize, selectedIndex, onChange }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
      }}
    >
      <Tabs
        value={selectedIndex}
        onChange={onChange}
        sx={{
          '& .MuiTabs-indicator': {
            opacity: 0,
          },
        }}
      >
        {tabs.map((tab, i) => (
          <YatungTab label={tab} key={i} sx={{ borderRadius: i === 0 ? '10px 0 0 0' : 0, fontSize: { fontSize } }} />
        ))}
      </Tabs>
    </Box>
  );
}
