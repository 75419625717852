import YatungPage from '@Src/_basic/components/YatungPage';
import { AccountDataFormRequire } from '@Src/_basic/object/AccountManagementType';
import { UsersApi } from '@Src/_basic/protocol/accountRights/users/UsersApi';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import EditAcountCard from '../EditAcountFrom';

export const initialAccount: AccountDataFormRequire = {
  state: '0',
  account: '',
  password: '',
  userName: '',
  jobTitle: '',
  organizationIds: [],
  email: '',
  phone: '',
  createDt: moment().format('YYYY/MM/DD'),
  updateDt: moment().format('YYYY/MM/DD'),
  sourceKind: 0,
};

export default function AccountManagementUpdateCard() {
  const { t: i18T } = useTranslation();
  const { state: data_id } = useLocation();
  const [initData, setInitData] = useState(initialAccount);

  useEffect(() => {
    UsersApi.getUserById(data_id, (_data) => {
      setInitData({
        ..._data,
        organizationIds: _data.organizations.map((item: any) => item.id),
        state: _data.state ? '1' : '0',
        userName: _data.accountName,
      });
    });
  }, [data_id]);

  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.EDIT_ACCOUNT.ROUTE_TITLE')}
      contentBgColor="#FFF"
      body={<EditAcountCard initialValues={initData} />}
    />
  );
}
