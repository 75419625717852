import React from 'react';
import ReportGroupManagementCard from '../components/ReportGroupManagementCard';

export default function ReportGroupManagementCardScreen() {
  return (
    <>
      <ReportGroupManagementCard />
    </>
  );
}
