import React from 'react';

export default function Clock() {
  return (
    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={14} cy={14} r="13.5" stroke="#6C708C" />
      <path d="M14 8H12V18H12.2719L21.6065 20.699L22.162 18.7776L14 16.4177V8Z" fill="#6C708C" />
    </svg>
  );
}
