import { Protocol } from '../Protocol';

export class SampleProtocol {
  public static readonly GET_ALL_SAMPLE: string = `${Protocol.YATUNG_API_ROOT}/source/sample/all`;
  public static readonly GET_SAMPLE_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/source/sample`;
  public static readonly CREATE_SAMPLE: string = `${Protocol.YATUNG_API_ROOT}/source/sample`;
  public static readonly UPDATE_SAMPLE: string = `${Protocol.YATUNG_API_ROOT}/source/sample`;
  public static readonly DELETE_SAMPLE: string = `${Protocol.YATUNG_API_ROOT}/source/sample`;

  public static readonly RECEIPT_MATERIAL: string = `${Protocol.YATUNG_API_ROOT}/sample`;
}
