import YatungDynamicSelect from '@Src/_basic/components/YatungDynamicSelect';
import YatungInput from '@Src/_basic/components/YatungInput';
import { Box, Checkbox, Stack, Typography, styled } from '@mui/material';
import React, { useMemo } from 'react';

interface Props {
  value: any;
  activateValue: Array<any>;
  onChange?: (value: any) => void;
  onSelected?: (index: number, value: any) => void;
  onInputChange?: (index: number, value: any) => void;
  periodWeekDays: any;
  dynamicWeekOptions: any;
  weekOptions: any;
}

const Label = styled(Typography)(({ theme }) => ({
  color: '#6C708C',
  fontSize: '20px',
  fontWeight: '400',
  fontFamily: 'Microsoft JhengHei',
}));

export default function WeeklySelect(props: Props) {
  const isChecked = useMemo(() => {
    return props.activateValue.includes(props.value);
  }, [props.activateValue, props.value]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '20px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Checkbox
          checked={isChecked}
          onChange={() => {
            props.onChange?.(props.value);
          }}
        />
        <Label>每週</Label>
      </Box>
      <Stack alignItems="flex-start" gap="20px">
        {props.periodWeekDays.map((periodWeekDay: any, index: number) => {
          return (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '14px' }}>
              <Box sx={{ width: '85px' }}>
                <YatungDynamicSelect
                  disabled={!isChecked}
                  options={props.dynamicWeekOptions}
                  value={periodWeekDay.weekDay}
                  sx={{ width: '85px' }}
                  onChange={(e) => {
                    props.onSelected?.(index, Number(e.target.value));
                  }}
                  totalOptions={props.weekOptions}
                />
              </Box>
              <Label>需求單</Label>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <YatungInput
                  disabled={!isChecked}
                  type="number"
                  value={periodWeekDay.length}
                  onChange={(e: any) => {
                    props.onInputChange?.(index, Number(e.target.value));
                  }}
                  sx={{ width: '85px' }}
                />
                <Label sx={{ ml: 1 }}>天後結束</Label>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}
