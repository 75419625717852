import useGetAllFactories from '@Src/_basic/apiHooks/useGetAllFactories';
import useGetAllTypes from '@Src/_basic/apiHooks/useGetAllTypes';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { VehicleData } from '@Src/_basic/object/VehicleType';
import { VehicleAPi } from '@Src/_basic/protocol/Vehicle/VehicleAPi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Pagination, Stack } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import VehicleManagementCreateCard from '../components/VehicleManagementCreateCard';
import VehicleManagementSearchBar from '../components/VehicleManagementSearchBar';
import VehicleManagementTable from '../components/VehicleManagementTable';
import VehicleManagementUpdateCard from '../components/VehicleManagementUpdateCard';

export type FilterValue = {
  factoryId: number;
  plateNumber?: string;
  removed: number;
};

type SearchParams = FilterValue & {
  page: number;
  pageSize: number;
};
//  sort: 'plateNumber,desc',
export default function VehicleManagementScreen() {
  const { t: i18T } = useTranslation();
  // const [vehiclegetByIdData, setVehiclegetByIdData] = useState<VehicleData | any>({
  //   id: 0,
  //   plateNumber: '',
  //   removed: false,
  //   favorite: false,
  //   isShow: true,
  //   type: [],
  //   area: [],
  // });
  // const [factoryOptions, setFactoryOptions] = useState<Options[]>([]);
  // const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  // const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  // const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  // const [pageInfo, setPageInfo] = useState<PageInfo>({
  //   page: 1,
  //   pageSize: 10,
  //   total: 0,
  //   totalCount: 0,
  // });
  // const [types, setTypes] = useState<Options[]>([]);
  // const [areas, setAreas] = useState<AreaOptions[]>([]);
  // const [typeId, setTypeId] = useState<Array<number> | undefined>([]);
  // const [otherAreaData, setOtherAreaData] = useState<VehicleArea[] | any>([]);
  // const [factoryValues, setFactoryValues] = useState<Array<number> | undefined>([]);

  // const [inputValues, setInputValues] = useState<string>('');
  // const [searchFactory, setSearchFactory] = useState<number | null>(0);
  // const [searchState, setSearchState] = useState<boolean>(false);

  // const [searchStateValue, setSearchStateValue] = useState<number | null>(0);
  // const [sortState, setSortState] = useState<boolean>(false);
  // const [modifiedArrayKey, setModifiedArrayKey] = useState<VehicleTempOptions[]>([]);
  // const [factoryMappingOptions, setFactoryMappingOptions] = useState<any>([]);

  // const handleOpenCreateModal = () => {
  //   setCreateModalOpen(true);
  // };
  // const handleCreateGoBackButtonClick = () => {
  //   setCreateModalOpen(false);
  // };

  // const handleUpdateButtonClick = (id: number) => {
  //   setUpdateModalOpen(true);
  //   const selectedById = vehicleData.find((i: VehicleData) => i.id === id);
  //   setVehiclegetByIdData(selectedById);
  // };

  // const handleUpdateGoBackButtonClick = () => {
  //   setUpdateModalOpen(false);
  //   setVehiclegetByIdData({
  //     id: 0,
  //     plateNumber: '',
  //     removed: false,
  //     favorite: false,
  //     isShow: true,
  //     type: [],
  //     area: [],
  //   });
  // };

  // const handleStateButtonClick = (id: number) => {
  //   setDeleteModalOpen(true);
  //   const selected = vehicleData.find((i: VehicleData) => i.id === id);
  //   setVehiclegetByIdData(selected);
  // };

  // const handleDeleteGoBackButtonClick = () => {
  //   setDeleteModalOpen(false);
  // };

  // const onGetFactoryOptionsSuccess = useCallback((data: Array<FactoryData>) => {
  //   setFactoryOptions(data.map(({ id, factoryName }: FactoryData) => ({ value: id, text: factoryName })));
  // }, []);
  // const getAllTypes = () => {
  //   const searchParams: SearchTypeParamsByRemoved = { removed: false };
  //   TypeApi.getTypesBySearchRelatedRemoved(searchParams, typesDataToOptions);
  // };

  // const typesDataToOptions = (data: Array<TypeData>) => {
  //   const allTypesRemoveSome = data.filter(
  //     (value: TypeData) => value.id !== 9 && value.id !== 11 && value.id !== 7 && value.id !== 8,
  //   );
  //   const typesOptions = allTypesRemoveSome.map((type: TypeData) => ({ value: type.id, text: type.name }));
  //   setTypes(typesOptions);
  // };
  // const getAllAreasData = () => {
  //   AreaApi.getAllAreas(areaDataToAreaOptions);
  // };

  // const areaDataToAreaOptions = (data: Array<AreaData>) => {
  // const areaOptions = data.filter((value) => value.value !== 7).map(({ id, name, factoryList }) => ({
  //   value: id,
  //   text: name,
  //   factories: factoryList.map(({ factoryId, factoryName }: FactoryItem) => ({
  //     value: factoryId,
  //     text: factoryName,
  //   })),
  // }));
  // const removeIdIs7 = areaOptions.filter((value: AreaOptions) => value.value !== 7);
  // setAreas(removeIdIs7);
  // };
  // const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
  //   if (!sortState) {
  //     getSearchVehicleData(page);
  //     setPageInfo({
  //       ...pageInfo,
  //       page,
  //     });
  //     setSortState(false);
  //   } else {
  //     const params: SearchPaginationParams = {
  //       plateNumber: inputValues,
  //       factory: searchFactory,
  //       removed: searchState,
  //     };
  //     const { pageSize } = pageInfo;
  //     VehicleAPi.getPaginationVehicle(
  //       { ...params, page, pageSize, sort: 'plateNumber,desc' },
  //       (datas: VehiclePaginationData) => {
  //         setVehicleData(datas.data);
  //         setPageInfo({
  //           ...pageInfo,
  //           page,
  //           total: datas.maxPage,
  //           totalCount: datas.elements,
  //         });
  //       },
  //     );
  //     setSortState(true);
  //   }
  // };
  // const refreshData = () => {
  //   if (!sortState) {
  //     const params: SearchPaginationParams = {
  //       plateNumber: inputValues,
  //       factory: searchFactory,
  //       removed: searchState,
  //     };
  //     const { pageSize, page } = pageInfo;
  //     const paginationParams = { ...params, page, pageSize, sort: 'plateNumber,asc' };
  //     VehicleAPi.getPaginationVehicle(paginationParams, (datas: VehiclePaginationData) => {
  //       setVehicleData(datas.data);
  //       setPageInfo({
  //         ...pageInfo,
  //         page,
  //         total: datas.maxPage,
  //         totalCount: datas.elements,
  //       });
  //     });
  //     setSortState(false);
  //   } else {
  //     const params: SearchPaginationParams = {
  //       plateNumber: inputValues,
  //       factory: searchFactory,
  //       removed: searchState,
  //     };
  //     const { pageSize, page } = pageInfo;
  //     const paginationParams = { ...params, page, pageSize, sort: 'plateNumber,desc' };
  //     VehicleAPi.getPaginationVehicle(paginationParams, (datas: VehiclePaginationData) => {
  //       setVehicleData(datas.data);
  //       setPageInfo({
  //         ...pageInfo,
  //         page,
  //         total: datas.maxPage,
  //         totalCount: datas.elements,
  //       });
  //     });
  //     setSortState(true);
  //   }
  // };
  // const getDataId = () => {
  //   const typeValue = vehiclegetByIdData?.type.map((value: VehicleOptions) => {
  //     return value.id;
  //   });
  //   const areaValue = vehiclegetByIdData?.area.filter((value: VehicleArea) => {
  //     return value.areaId !== 7;
  //   });
  //   setOtherAreaData(areaValue);
  //   setTypeId(typeValue);
  // };
  // const getRelatedFactoryData = () => {
  //   const factoryArray = otherAreaData?.map((value: VehicleArea) => {
  //     return value.factories;
  //   });
  //   const mergedArrayTemp = [].concat(...factoryArray);
  //   const modifiedArrayKeyTemp = mergedArrayTemp.map((data: VehicleOptions) => ({
  //     value: data.id,
  //     text: data.name,
  //     removed: data.removed,
  //     remainDays: data.remainDays,
  //   }));
  //   setModifiedArrayKey(modifiedArrayKeyTemp);

  //   const factoryArrayTemp: Array<any> = [];

  //   factoryArray?.forEach((value: VehicleOptions[]) => {
  //     value?.map((v: VehicleOptions) => {
  //       factoryArrayTemp.push(v.id);
  //     });
  //   });

  //   setFactoryValues(factoryArrayTemp);
  // };
  // const mappingFactoryData = () => {
  //   const mappedArray = factoryOptions.map((data) => {
  //     const matchingItem = modifiedArrayKey.find((datas: VehicleTempOptions) => datas.value === data.value);
  //     if (matchingItem) {
  //       return {
  //         value: data.value,
  //         text: data.text,
  //         removed: matchingItem.removed,
  //         remainDays: matchingItem.remainDays,
  //       };
  //     } else {
  //       return data;
  //     }
  //   });
  //   setFactoryMappingOptions(mappedArray);
  // };
  // const getSearchVehicleData = (page: number) => {
  //   if (!sortState) {
  //     const params: SearchPaginationParams = { plateNumber: inputValues, factory: searchFactory, removed: searchState };
  //     const { pageSize } = pageInfo;
  //     VehicleAPi.getPaginationVehicle(
  //       { ...params, page, pageSize, sort: 'plateNumber,asc' },
  //       (datas: VehiclePaginationData) => {
  //         setVehicleData(datas.data);
  //         setPageInfo({
  //           ...pageInfo,
  //           page,
  //           total: datas.maxPage,
  //           totalCount: datas.elements,
  //         });
  //       },
  //     );
  //     setSortState(false);
  //   } else {
  //     const params: SearchPaginationParams = {
  //       plateNumber: inputValues,
  //       factory: searchFactory,
  //       removed: searchState,
  //     };
  //     const { pageSize } = pageInfo;
  //     const paginationParams = { ...params, page, pageSize, sort: 'plateNumber,desc' };
  //     VehicleAPi.getPaginationVehicle(paginationParams, (datas: VehiclePaginationData) => {
  //       setVehicleData(datas.data);
  //       setPageInfo({
  //         ...pageInfo,
  //         page,
  //         total: datas.maxPage,
  //         totalCount: datas.elements,
  //       });
  //     });
  //     setSortState(true);
  //   }
  // };
  // const handleSortClick = (page: number) => {
  //   if (sortState) {
  //     const params: SearchPaginationParams = {
  //       plateNumber: inputValues,
  //       factory: searchFactory,
  //       removed: searchState,
  //     };
  //     const { pageSize } = pageInfo;
  //     VehicleAPi.getPaginationVehicle(
  //       { ...params, page, pageSize, sort: 'plateNumber,asc' },
  //       (datas: VehiclePaginationData) => {
  //         setVehicleData(datas.data);
  //         setPageInfo({
  //           ...pageInfo,
  //           page,
  //           total: datas.maxPage,
  //           totalCount: datas.elements,
  //         });
  //       },
  //     );
  //     setSortState(false);
  //   } else {
  //     const params: SearchPaginationParams = {
  //       plateNumber: inputValues,
  //       factory: searchFactory,
  //       removed: searchState,
  //     };
  //     const { pageSize } = pageInfo;
  //     VehicleAPi.getPaginationVehicle(
  //       { ...params, page, pageSize, sort: 'plateNumber,desc' },
  //       (datas: VehiclePaginationData) => {
  //         setVehicleData(datas.data);
  //         setPageInfo({
  //           ...pageInfo,
  //           page,
  //           total: datas.maxPage,
  //           totalCount: datas.elements,
  //         });
  //       },
  //     );

  //     setSortState(true);
  //   }
  // };
  // const refreshSearchVehicleData = () => {
  //   getSearchVehicleData(1);
  // };

  // useEffect(() => {
  //   getFactoryOptions();
  //   getAllTypes();
  //   getAllAreasData();
  // }, []);
  // useEffect(() => {
  //   getDataId();
  // }, [vehiclegetByIdData]);
  // useEffect(() => {
  //   getRelatedFactoryData();
  // }, [otherAreaData]);
  // useEffect(() => {
  //   refreshSearchVehicleData();
  // }, [searchFactory, searchStateValue]);
  // useEffect(() => {
  //   mappingFactoryData();
  // }, [modifiedArrayKey]);

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<SearchParams>();
  const { factoryOption } = useGetAllFactories();
  const { typeOption } = useGetAllTypes([7, 8, 9, 11]);

  const effectRef = useRef(false);

  const [vehicleData, setVehicleData] = useState<VehicleData[]>([]);
  const [selectedVehicleData, setSelectedVehicleData] = useState<VehicleData>();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [sortState, setSortState] = useState<boolean | null>(null);
  const [modalopen, setModalOpen] = useState<'CREATE' | 'UPDATE' | 'DELETE' | null>(null);

  const initPageInfo: PageInfo = useMemo(
    () => ({
      page: location.pathname === request?.page && request?.request ? request.request.page : 1,
      pageSize: 10,
      total: 0,
      totalCount: 0,
    }),
    [request, location.pathname],
  );

  const initFilterValue: FilterValue = useMemo(() => {
    const {
      factoryId = 0,
      removed = 1,
      plateNumber = '',
    } = location.pathname === request?.page && request?.request ? request.request : {};

    return {
      factoryId,
      removed,
      plateNumber,
    };
  }, [request, location]);

  const [filterValue, filterDispatch] = useReducer<Reducer<FilterValue, Partial<FilterValue>>>(
    updateReducer,
    initFilterValue,
  );
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const filterChange = useCallback((file: keyof FilterValue, value: FilterValue[keyof FilterValue]) => {
    filterDispatch({ [file]: value });
    pageInfoDispatch({ page: 1, total: 0 });
  }, []);

  const handleUpdateAndDelete = useCallback(
    (id: number, modal: 'UPDATE' | 'DELETE') => {
      setModalOpen(modal);
      const selectedById = vehicleData?.find((i) => i.id === id);
      if (selectedById) setSelectedVehicleData(selectedById);
    },
    [vehicleData],
  );

  const handleCloseModal = useCallback(() => {
    setModalOpen(null);
    setSelectedVehicleData(undefined);
  }, []);

  const getVehicleData = useCallback(
    (page: number, _sortState?: boolean | null) => {
      const { plateNumber, removed, factoryId } = filterValue;
      const _sort = _sortState !== undefined ? _sortState : sortState;

      VehicleAPi.getPaginationVehicle(
        {
          ...(plateNumber && { plateNumber }),
          ...(factoryId && { factory: factoryId }),
          ...(_sort !== null && { sort: _sort ? 'plateNumber,asc' : 'plateNumber,desc' }),
          removed: !removed,
          page,
          pageSize: pageInfo.pageSize,
        },
        (datas) => {
          setVehicleData(
            datas.data.map((item, index) => ({ ...item, ids: index + 1 + (page - 1) * pageInfo.pageSize })),
          );
          pageInfoDispatch({
            page,
            total: datas.maxPage,
            totalCount: datas.elements,
          });
          setSaveRequest({
            page: location.pathname,
            request: { page, pageSize: pageInfo.pageSize, plateNumber, factoryId, removed },
          });
          setIsVisible(true);
        },
      );
    },
    [pageInfo, filterValue, sortState, location.pathname, setSaveRequest],
  );

  const handlePageChange = useCallback(
    (e: React.ChangeEvent<unknown>, page: number) => {
      pageInfoDispatch({ page });
      getVehicleData(page);
    },
    [getVehicleData],
  );

  const deleteVehicleHandler = useCallback(() => {
    if (selectedVehicleData) {
      VehicleAPi.DeleteVehicle(selectedVehicleData.id, () => {
        getVehicleData(1);
        handleCloseModal();
      });
    }
  }, [selectedVehicleData, getVehicleData, handleCloseModal]);

  const onSortClick = useCallback(() => {
    let _sort;
    if (sortState) _sort = false;
    else if (sortState === false) _sort = null;
    else _sort = true;

    setSortState(_sort);
    getVehicleData(1, _sort);
  }, [sortState, getVehicleData]);

  useEffect(() => {
    setIsVisible(false);
    setVehicleData([]);
  }, [filterValue]);

  useLayoutEffect(() => {
    if (!effectRef.current) {
      effectRef.current = true;
      getVehicleData(pageInfo.page);
    }
  }, [effectRef]);

  return (
    <>
      <YatungPage
        title={i18T('USERSMANAGEMENU.VEHICLE_MANAGEMENT.ROUTE_TITLE')}
        body={
          <>
            <VehicleManagementSearchBar
              factoryOption={factoryOption}
              onOpenCreateModal={() => setModalOpen('CREATE')}
              filterValue={filterValue}
              filterChange={filterChange}
              onClickSarchButton={() => getVehicleData(pageInfo.page)}
            />
            {
              <YatungFadeInOut isVisible={isVisible}>
                <>
                  <VehicleManagementTable
                    sortState={sortState}
                    vehicleData={vehicleData}
                    onSortClick={onSortClick}
                    handleUpdateAndDelete={handleUpdateAndDelete}
                  />
                  <Stack flexDirection={'row'} justifyContent={'center'} sx={{ p: 1 }}>
                    <Pagination
                      count={pageInfo.total}
                      page={pageInfo.page}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Stack>
                </>
              </YatungFadeInOut>
            }
          </>
        }
        contentBgColor="#FFF"
      />
      {modalopen === 'CREATE' && (
        <VehicleManagementCreateCard
          modalOpen={modalopen === 'CREATE'}
          typeOption={typeOption}
          factoryOption={factoryOption}
          getVehicleData={() => getVehicleData(1)}
          handleCloseModal={handleCloseModal}
        />
      )}
      {modalopen === 'UPDATE' && selectedVehicleData && (
        <VehicleManagementUpdateCard
          modalOpen={modalopen === 'UPDATE'}
          selectedVehicleData={selectedVehicleData}
          handleCloseModal={handleCloseModal}
          typeOption={typeOption}
          factoryOption={factoryOption}
          getVehicleData={() => getVehicleData(1)}
        />
      )}
      <YatungConfirmAlert isShowAlert={modalopen === 'DELETE'} onClose={handleCloseModal} onOk={deleteVehicleHandler} />
    </>
  );
}
