import useGetAllFactories from '@Src/_basic/apiHooks/useGetAllFactories';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import { Options } from '@Src/_basic/components/YatungSelect';
import { EntityData, SearchEntityParams } from '@Src/_basic/object/EntityType';
import { StationNewData } from '@Src/_basic/object/SiteType';
import { EntityApi } from '@Src/_basic/protocol/entity/EntityApi';
import { StationApi } from '@Src/_basic/protocol/site/StationApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import EntityCreateCard from '../components/EntityCreateCard';
import EntityManagementSearchBar from '../components/EntityManagementSearchBar';
import EntityManagementTable from '../components/EntityManagementTable';
import EntityUpdateCard from '../components/EntityUpdateCard';

export type QueryType = {
  name?: string;
  factoryId: number;
  stationId: number;
};

export type FactoryAndStationName = {
  factory: Options;
  station: Options;
};

export default function EntityScreen() {
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { setSaveRequest, request } = useRequestSaving<QueryType>();
  const { withoutAllFactoryOption } = useGetAllFactories();

  const [entityData, setEntityData] = useState<EntityData[]>([]);
  const [entityByIdData, setEntityByIdData] = useState<EntityData>();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [factoryAndStationOptions, setFactoryAndStationOptions] = useState<FactoryAndStationName>();
  const [stationOptions, setStationOptions] = useState<Options[]>([]);

  const initQueryValue = useMemo(() => {
    const {
      name = '',
      factoryId = 0,
      stationId = 0,
    } = location.pathname === request?.page && request?.request ? request.request : {};

    return {
      name,
      factoryId,
      stationId,
    };
  }, [request, location.pathname]);

  const [query, setQuery] = useState(initQueryValue);

  const [modalOpen, setModalOpen] = useState<'CREATE' | 'UPDATE' | 'DELETE' | null>(null);

  const handleModelOpen = useCallback((modal: 'CREATE' | 'UPDATE' | 'DELETE') => {
    setModalOpen(modal);
  }, []);

  const handleModelClose = useCallback(() => {
    setModalOpen(null);
  }, []);

  const handleUpdateAndDelete = useCallback(
    (id: number, modal: 'UPDATE' | 'DELETE') => {
      const _data = entityData?.find((item) => item.id === id);
      if (_data) {
        setEntityByIdData(_data);
        setModalOpen(modal);
      }
    },
    [entityData],
  );

  const params: SearchEntityParams = useMemo(
    () => ({
      ...query,
      removed: false,
    }),
    [query],
  );

  const updateQuery = useCallback(
    (file: keyof QueryType, value: QueryType[keyof QueryType]) => {
      setQuery((prev) => ({ ...prev, [file]: value }));
      if (file === 'stationId') {
        const _factory = withoutAllFactoryOption.find((item) => item.value === params.factoryId);
        const _station = stationOptions.find((item) => item.value === value);

        if (_factory && _station) setFactoryAndStationOptions({ station: _station, factory: _factory });
      }
    },
    [withoutAllFactoryOption, stationOptions, params.factoryId],
  );

  const getSearchEntityData = useCallback(() => {
    EntityApi.getEntityData(params, (_data) => {
      setEntityData(_data.map((item: EntityData, index: number) => ({ ...item, displayId: index + 1 })));
      setSaveRequest({ page: location.pathname, request: params });
      setIsVisible(true);
    });
  }, [params, location, setSaveRequest]);

  const selectFactoryHandler = useCallback((value: number) => {
    setQuery((prev) => ({ ...prev, factoryId: value, stationId: 0 }));
    StationApi.getAllStationByFactoryId(value, (allStations: Array<StationNewData>) => {
      setStationOptions(
        allStations.map((allStation) => ({
          value: allStation.id,
          text: allStation.stationName,
        })),
      );
    });
  }, []);

  const handleDelete = useCallback(() => {
    if (!entityByIdData) return;

    EntityApi.DeleteEntity(entityByIdData.id, () => {
      getSearchEntityData();
      setModalOpen(null);
    });
  }, [entityByIdData, getSearchEntityData]);

  useEffect(() => {
    setIsVisible(false);
  }, [query]);

  useEffect(() => {
    // 處理儲存使用者篩選條件回來時，重新取得資料
    if (query.factoryId) {
      new Promise<void>((resolve) => {
        StationApi.getAllStationByFactoryId(query.factoryId, (_allStations) => {
          const _fac = withoutAllFactoryOption.find((item) => item.value === query.factoryId);
          const _sta = _allStations.find((item) => item.id === query.stationId);

          setFactoryAndStationOptions({
            factory: { value: _fac?.value || 0, text: _fac?.text || '' },
            station: { value: _sta?.id || 0, text: _sta?.stationName || '' },
          });

          setStationOptions(
            _allStations.map((allStation) => ({
              value: allStation.id,
              text: allStation.stationName,
            })),
          );
          resolve();
        });
      }).then(() => {
        setQuery((prev) => ({ ...prev, stationId: query.stationId }));
        getSearchEntityData();
      });
    }
  }, []);

  return (
    <>
      <YatungPage
        title={i18T('ENTITYMANAGEMENT.PAGE_TITLE')}
        body={
          <>
            <EntityManagementSearchBar
              query={query}
              factoryOptions={withoutAllFactoryOption}
              stationOptions={stationOptions}
              updateQuery={updateQuery}
              getSearchEntityData={getSearchEntityData}
              onOpenCreateModal={() => handleModelOpen('CREATE')}
              selectFactoryHandler={selectFactoryHandler}
            />
            <YatungFadeInOut isVisible={isVisible}>
              <EntityManagementTable entityData={entityData} handleUpdateAndDelete={handleUpdateAndDelete} />
            </YatungFadeInOut>
          </>
        }
        contentBgColor="#FFF"
      />

      {factoryAndStationOptions && modalOpen === 'CREATE' && (
        <EntityCreateCard
          query={query}
          modalOpen={modalOpen === 'CREATE'}
          modalClose={handleModelClose}
          getSearchEntityData={getSearchEntityData}
          factoryAndStationOptions={factoryAndStationOptions}
        />
      )}

      {entityByIdData && modalOpen === 'UPDATE' && (
        <EntityUpdateCard
          entityByIdData={entityByIdData}
          modalOpen={modalOpen === 'UPDATE'}
          modalClose={handleModelClose}
          getSearchEntityData={getSearchEntityData}
        />
      )}

      <YatungConfirmAlert isShowAlert={modalOpen === 'DELETE'} onClose={handleModelClose} onOk={handleDelete} />
    </>
  );
}
