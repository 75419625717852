import YatungFadeMenu from '@Src/_basic/components/YatungFadeMenu';
import { useAuth } from '@Src/redux/auth/authActions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function QualityManagementFadeMenu() {
  const { t: i18T } = useTranslation();
  const { userApiPermission } = useAuth();
  const [newRouteSideMenu, setNewRouteSideMenu] = React.useState<Array<any>>([]);

  const routeSideMenu = [
    {
      title: i18T('QUALITY_MANAGE.SIDE_MENU.STANDARDIZED_PENALTY_MANAGEMENT.LEGAL_STANDARD'),
      toUrl: '/qualityManagement/legalStandards/type',
      code: 'WD034',
    },
    {
      title: i18T('QUALITY_MANAGE.SIDE_MENU.STANDARDIZED_PENALTY_MANAGEMENT.CUSTOM_STANDARD'),
      toUrl: '/qualityManagement/customStandards/type',
      code: 'WD035',
    },
  ];

  useEffect(() => {
    const routeSideMenuTemp: Array<any> = [];
    routeSideMenu.forEach((item) => {
      if (userApiPermission.includes(item.code as string)) {
        routeSideMenuTemp.push(item);
      }
    });
    setNewRouteSideMenu(routeSideMenuTemp);
  }, [userApiPermission]);
  return (
    <>
      {newRouteSideMenu.length > 0 && (
        <YatungFadeMenu
          text={i18T('QUALITY_MANAGE.SIDE_MENU.STANDARDIZED_PENALTY_MANAGEMENT.TITLE')}
          option={newRouteSideMenu}
        />
      )}
    </>
  );
}
