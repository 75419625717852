import { UpdateEquipmentRequest } from '@Src/_basic/object/EquipmentManagementType';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import ViewVideoField from './component/ViewVideoField';
import { MonitorOrderData } from '@Src/_basic/object/MonitorType';

interface Props {
  data: MonitorOrderData | undefined;
}

export default function ViewVideoForm(props: Props) {
  const { data } = props;

  const updateSampleSchema = Yup.object().shape({
    name: Yup.string().required('請輸入儀器名稱'),
  });

  const handleSubmit = (request: UpdateEquipmentRequest) => {};

  const initialEquipmentValue: any = {};

  return (
    <>
      <Formik
        initialValues={initialEquipmentValue}
        validationSchema={updateSampleSchema}
        onSubmit={(request: UpdateEquipmentRequest) => handleSubmit(request)}
        enableReinitialize
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <ViewVideoField data={data} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
