import {
  ChildrenTypography,
  TableHeaderSub,
  TestCardContainer,
  TitleTypography,
} from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import EditButtons from '@Src/_basic/components/YatungTest/EditButtons';
import { CalcCoarseAggOblateness } from '@Src/_basic/helpers/CalcTestForm';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { formatNumber } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema } from '@Src/_basic/helpers/TestForm';
import { AllTest } from '@Src/_basic/object/TestType';
import {
  CoarseAggOblateness,
  ImportCoarseAggOblateness,
  PutCoarseAggOblatenessRequest,
} from '@Src/_basic/object/test/sandRock/CoarseAggOblatenessType';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import LabelInput from '@Src/app/module/proportionManage/components/LabelInput';
import { Stack } from '@mui/material';
import { useFormik } from 'formik';
import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface TableHeaderProps {
  title: string;
  values: Array<string>;
}

function TableHeader({ title, values }: TableHeaderProps) {
  return (
    <TableHeaderSub>
      <TitleTypography>{title}</TitleTypography>
      <TableHeaderSub>
        {values.map((value, index) => (
          <TitleTypography key={index}>{value}</TitleTypography>
        ))}
      </TableHeaderSub>
    </TableHeaderSub>
  );
}

interface TableDataProps {
  titles: Array<string>;
  values: Array<string | number>;
}

function TableData({ titles, values }: TableDataProps) {
  return (
    <Stack flexDirection="row" py={2} sx={{ borderBottom: '1px solid gray' }}>
      <Stack flexDirection="column" flex={1} px={1}>
        {titles?.map((_value, _index) => (
          <ChildrenTypography key={_index}>{_value}</ChildrenTypography>
        ))}
      </Stack>

      <Stack flexDirection="row" flex={1}>
        {values?.map((_value, _index) => (
          <ChildrenTypography key={_index} sx={{ flex: 1, px: 1 }}>
            {_value}
          </ChildrenTypography>
        ))}
      </Stack>
    </Stack>
  );
}

interface Props {
  testDetail: CoarseAggOblateness;
  // eslint-disable-next-line no-unused-vars
  afterUpdateTest: (test: AllTest) => void;
  canEdit?: boolean;
}

const validationSchema = Yup.object().shape({
  gradation1: basicNumberSchema,
  gradation2: basicNumberSchema,
  gradation3: basicNumberSchema,
  gradation4: basicNumberSchema,
  gradation5: basicNumberSchema,
  gradation6: basicNumberSchema,

  weightBeforeTest1: basicNumberSchema,
  weightBeforeTest2: basicNumberSchema,
  weightBeforeTest3: basicNumberSchema,
  weightBeforeTest4: basicNumberSchema,
  weightBeforeTest5: basicNumberSchema,
  weightBeforeTest6: basicNumberSchema,

  weightAfterTest1: basicNumberSchema,
  weightAfterTest2: basicNumberSchema,
  weightAfterTest3: basicNumberSchema,
  weightAfterTest4: basicNumberSchema,
  weightAfterTest5: basicNumberSchema,
  weightAfterTest6: basicNumberSchema,
});

function ExportCoarseAggOblatenessTable({ testDetail, afterUpdateTest, canEdit }: Props) {
  const { t: i18T } = useTranslation();
  const calcTestForm = useMemo(() => new CalcCoarseAggOblateness(testDetail), [testDetail]);

  const [edit, setEdit] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      gradation1: testDetail?.gradation1,
      gradation2: testDetail?.gradation2,
      gradation3: testDetail?.gradation3,
      gradation4: testDetail?.gradation4,
      gradation5: testDetail?.gradation5,
      gradation6: testDetail?.gradation6,

      weightBeforeTest1: testDetail?.weightBeforeTest1,
      weightBeforeTest2: testDetail?.weightBeforeTest2,
      weightBeforeTest3: testDetail?.weightBeforeTest3,
      weightBeforeTest4: testDetail?.weightBeforeTest4,
      weightBeforeTest5: testDetail?.weightBeforeTest5,
      weightBeforeTest6: testDetail?.weightBeforeTest6,

      weightAfterTest1: testDetail?.weightAfterTest1,
      weightAfterTest2: testDetail?.weightAfterTest2,
      weightAfterTest3: testDetail?.weightAfterTest3,
      weightAfterTest4: testDetail?.weightAfterTest4,
      weightAfterTest5: testDetail?.weightAfterTest5,
      weightAfterTest6: testDetail?.weightAfterTest6,
    }),
    [testDetail],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data: ImportCoarseAggOblateness = {
        gradation1: checkIsNaN(+values.gradation1),
        gradation2: checkIsNaN(+values.gradation2),
        gradation3: checkIsNaN(+values.gradation3),
        gradation4: checkIsNaN(+values.gradation4),
        gradation5: checkIsNaN(+values.gradation5),
        gradation6: checkIsNaN(+values.gradation6),

        weightBeforeTest1: checkIsNaN(+values.weightBeforeTest1),
        weightBeforeTest2: checkIsNaN(+values.weightBeforeTest2),
        weightBeforeTest3: checkIsNaN(+values.weightBeforeTest3),
        weightBeforeTest4: checkIsNaN(+values.weightBeforeTest4),
        weightBeforeTest5: checkIsNaN(+values.weightBeforeTest5),
        weightBeforeTest6: checkIsNaN(+values.weightBeforeTest6),

        weightAfterTest1: checkIsNaN(+values.weightAfterTest1),
        weightAfterTest2: checkIsNaN(+values.weightAfterTest2),
        weightAfterTest3: checkIsNaN(+values.weightAfterTest3),
        weightAfterTest4: checkIsNaN(+values.weightAfterTest4),
        weightAfterTest5: checkIsNaN(+values.weightAfterTest5),
        weightAfterTest6: checkIsNaN(+values.weightAfterTest6),
      };

      TestApi.putTest<PutCoarseAggOblatenessRequest>(
        {
          exptItemCode: testDetail?.exptItemCode,
          fields: {
            ...testDetail,
            ...data,
          },
        },
        (_data) => {
          afterUpdateTest(_data);
          setEdit(false);
        },
        (err) => console.log(err),
      );
    },
  });

  const firstInfo = useMemo(
    () => [
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.50000um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.37500um`)}`,
        ],
        values: [formatNumber(calcTestForm?.sampleGrading1?.toFixed(4)), testDetail?.gradation1],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.37500um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.25000um`)}`,
        ],
        values: [formatNumber(calcTestForm?.sampleGrading2?.toFixed(4)), testDetail?.gradation2],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.25000um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.19000um`)}`,
        ],
        values: [formatNumber(calcTestForm?.sampleGrading3?.toFixed(4)), testDetail?.gradation3],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.19000um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.12500um`)}`,
        ],
        values: [formatNumber(calcTestForm?.sampleGrading4?.toFixed(4)), testDetail?.gradation4],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.12500um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.9500um`)}`,
        ],
        values: [formatNumber(calcTestForm?.sampleGrading5?.toFixed(4)), testDetail?.gradation5],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.9500um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.4750um`)}`,
        ],
        values: [formatNumber(calcTestForm?.sampleGrading6?.toFixed(4)), testDetail?.gradation6],
      },
      {
        title: [`${i18T(`TEST.COARSE_AGG_OBLATENESS.TOTAL`)}`],
        values: [formatNumber(calcTestForm?.totalSampleGrading?.toFixed(4)), calcTestForm?.totalParticleCount],
      },
    ],
    [i18T, testDetail, calcTestForm],
  );

  const secondInfo = useMemo(
    () => [
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.50000um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.37500um`)}`,
        ],
        values: [
          formatNumber(testDetail?.weightBeforeTest1?.toFixed(4)),
          formatNumber(testDetail?.weightAfterTest1?.toFixed(4)),
        ],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.37500um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.25000um`)}`,
        ],
        values: [
          formatNumber(testDetail?.weightBeforeTest2?.toFixed(4)),
          formatNumber(testDetail?.weightAfterTest2?.toFixed(4)),
        ],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.25000um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.19000um`)}`,
        ],
        values: [
          formatNumber(testDetail?.weightBeforeTest3?.toFixed(4)),
          formatNumber(testDetail?.weightAfterTest3?.toFixed(4)),
        ],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.19000um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.12500um`)}`,
        ],
        values: [
          formatNumber(testDetail?.weightBeforeTest4?.toFixed(4)),
          formatNumber(testDetail?.weightAfterTest4?.toFixed(4)),
        ],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.12500um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.9500um`)}`,
        ],
        values: [
          formatNumber(testDetail?.weightBeforeTest5?.toFixed(4)),
          formatNumber(testDetail?.weightAfterTest5?.toFixed(4)),
        ],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.9500um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.4750um`)}`,
        ],
        values: [
          formatNumber(testDetail?.weightBeforeTest6?.toFixed(4)),
          formatNumber(testDetail?.weightAfterTest6?.toFixed(4)),
        ],
      },
      {
        title: [`${i18T(`TEST.COARSE_AGG_OBLATENESS.TOTAL`)}`],
        values: [
          formatNumber(calcTestForm?.totalWeightBefore?.toFixed(4)),
          formatNumber(calcTestForm?.totalWeightAfter?.toFixed(4)),
        ],
      },
    ],
    [i18T, testDetail, calcTestForm?.totalWeightBefore, calcTestForm?.totalWeightAfter],
  );

  const thirdInfo = useMemo(
    () => [
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.50000um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.37500um`)}`,
        ],
        values: [
          formatNumber(calcTestForm?.percentage1?.toFixed(4)),
          formatNumber(calcTestForm?.finalPercentage1?.toFixed(4)),
        ],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.37500um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.25000um`)}`,
        ],
        values: [
          formatNumber(calcTestForm?.percentage2?.toFixed(4)),
          formatNumber(calcTestForm?.finalPercentage2?.toFixed(4)),
        ],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.25000um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.19000um`)}`,
        ],
        values: [
          formatNumber(calcTestForm?.percentage3?.toFixed(2)),
          formatNumber(calcTestForm?.finalPercentage3?.toFixed(2)),
        ],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.19000um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.12500um`)}`,
        ],
        values: [
          formatNumber(calcTestForm?.percentage4?.toFixed(2)),
          formatNumber(calcTestForm?.finalPercentage4?.toFixed(2)),
        ],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.12500um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.9500um`)}`,
        ],
        values: [
          formatNumber(calcTestForm?.percentage5?.toFixed(2)),
          formatNumber(calcTestForm?.finalPercentage5?.toFixed(2)),
        ],
      },
      {
        title: [
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.9500um`)}`,
          `${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(`TEST.COARSE_AGG_OBLATENESS.4750um`)}`,
        ],
        values: [
          formatNumber(calcTestForm?.percentage6?.toFixed(2)),
          formatNumber(calcTestForm?.finalPercentage6?.toFixed(2)),
        ],
      },
      {
        title: [`${i18T(`TEST.COARSE_AGG_OBLATENESS.TOTAL`)}`],
        values: [
          formatNumber(calcTestForm?.totalPercentage?.toFixed(2)),
          formatNumber(calcTestForm?.totalFinalPercentage?.toFixed(2)),
        ],
      },
    ],
    [i18T, calcTestForm],
  );

  const firstHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.COARSE_AGG_OBLATENESS.SIEVE_NUMBER_SIZE`)}`,
      values: [
        ...(edit ? [] : [`${i18T(`TEST.COARSE_AGG_OBLATENESS.SAMPLE_GRADING`)}(%)`]),
        `${i18T(`TEST.COARSE_AGG_OBLATENESS.PARTICLE_AMOUNT`)}(個)`,
      ],
    }),
    [i18T, edit],
  );

  const secondHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.COARSE_AGG_OBLATENESS.SIEVE_NUMBER_SIZE`)}`,
      values: [
        `${i18T(`TEST.COARSE_AGG_OBLATENESS.QUALITY_BEFORE_TEXT`)}(g)`,
        `${i18T(`TEST.COARSE_AGG_OBLATENESS.QUALITY_AFTER_TEXT`)}(g)`,
      ],
    }),
    [i18T],
  );

  const thirdHeader = useMemo(
    () => ({
      title: `${i18T(`TEST.COARSE_AGG_OBLATENESS.SIEVE_NUMBER_SIZE`)}`,
      values: [
        `${i18T(`TEST.COARSE_AGG_OBLATENESS.PERCENTAGE`)}(%)`,
        `${i18T(`TEST.COARSE_AGG_OBLATENESS.TOTAL_PERCENTAGE`)}(%)`,
      ],
    }),
    [i18T],
  );

  const firstInfoView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <Stack my={4} gap={2}>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.50000um`,
              )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.37500um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.gradation1}
                isError={!!(formik.touched.gradation1 && formik.errors.gradation1)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('gradation1', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.37500um`,
              )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.25000um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.gradation2}
                isError={!!(formik.touched.gradation2 && formik.errors.gradation2)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('gradation2', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.25000um`,
              )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.19000um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.gradation3}
                isError={!!(formik.touched.gradation3 && formik.errors.gradation3)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('gradation3', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.19000um`,
              )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.12500um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.gradation4}
                isError={!!(formik.touched.gradation4 && formik.errors.gradation4)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('gradation4', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.12500um`,
              )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.9500um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.gradation5}
                isError={!!(formik.touched.gradation5 && formik.errors.gradation5)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('gradation5', e.target.value)}
              />
            </Stack>
            <Stack flexDirection="row" pb={2} width={'65%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
              <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.9500um`,
              )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                `TEST.COARSE_AGG_OBLATENESS.4750um`,
              )}`}</ChildrenTypography>
              <LabelInput
                valueWidth="150px"
                value={formik.values.gradation6}
                isError={!!(formik.touched.gradation6 && formik.errors.gradation6)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('gradation6', e.target.value)}
              />
            </Stack>
          </Stack>
        );
      case false:
        return (
          <>
            {firstInfo.map((value, index) => (
              <TableData key={index} titles={value.title} values={value.values} />
            ))}
          </>
        );
      default:
        break;
    }
  }, [edit, firstInfo, formik, i18T]);

  const secondInfoView = useMemo(() => {
    switch (edit) {
      case true:
        return (
          <>
            <Stack my={4} gap={2}>
              <Stack flexDirection="row" pb={2} width={'95%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
                <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.50000um`,
                )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.37500um`,
                )}`}</ChildrenTypography>
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightBeforeTest1}
                  isError={!!(formik.touched.weightBeforeTest1 && formik.errors.weightBeforeTest1)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightBeforeTest1', e.target.value)
                  }
                />
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightAfterTest1}
                  isError={!!(formik.touched.weightAfterTest1 && formik.errors.weightAfterTest1)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightAfterTest1', e.target.value)
                  }
                />
              </Stack>
              <Stack flexDirection="row" pb={2} width={'95%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
                <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.37500um`,
                )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.25000um`,
                )}`}</ChildrenTypography>
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightBeforeTest2}
                  isError={!!(formik.touched.weightBeforeTest2 && formik.errors.weightBeforeTest2)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightBeforeTest2', e.target.value)
                  }
                />
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightAfterTest2}
                  isError={!!(formik.touched.weightAfterTest2 && formik.errors.weightAfterTest2)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightAfterTest2', e.target.value)
                  }
                />
              </Stack>
              <Stack flexDirection="row" pb={2} width={'95%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
                <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.25000um`,
                )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.19000um`,
                )}`}</ChildrenTypography>
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightBeforeTest3}
                  isError={!!(formik.touched.weightBeforeTest3 && formik.errors.weightBeforeTest3)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightBeforeTest3', e.target.value)
                  }
                />
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightAfterTest3}
                  isError={!!(formik.touched.weightAfterTest3 && formik.errors.weightAfterTest3)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightAfterTest3', e.target.value)
                  }
                />
              </Stack>
              <Stack flexDirection="row" pb={2} width={'95%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
                <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.19000um`,
                )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.12500um`,
                )}`}</ChildrenTypography>
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightBeforeTest4}
                  isError={!!(formik.touched.weightBeforeTest4 && formik.errors.weightBeforeTest4)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightBeforeTest4', e.target.value)
                  }
                />
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightAfterTest4}
                  isError={!!(formik.touched.weightAfterTest4 && formik.errors.weightAfterTest4)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightAfterTest4', e.target.value)
                  }
                />
              </Stack>
              <Stack flexDirection="row" pb={2} width={'95%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
                <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.12500um`,
                )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.9500um`,
                )}`}</ChildrenTypography>
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightBeforeTest5}
                  isError={!!(formik.touched.weightBeforeTest5 && formik.errors.weightBeforeTest5)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightBeforeTest5', e.target.value)
                  }
                />
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightAfterTest5}
                  isError={!!(formik.touched.weightAfterTest5 && formik.errors.weightAfterTest5)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightAfterTest5', e.target.value)
                  }
                />
              </Stack>
              <Stack flexDirection="row" pb={2} width={'95%'} gap={10} sx={{ borderBottom: '2px solid #ccc' }}>
                <ChildrenTypography>{`${i18T(`TEST.COARSE_AGG_OBLATENESS.THROUGH`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.9500um`,
                )}${i18T(`TEST.COARSE_AGG_OBLATENESS.STAY`)}${i18T(
                  `TEST.COARSE_AGG_OBLATENESS.4750um`,
                )}`}</ChildrenTypography>
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightBeforeTest6}
                  isError={!!(formik.touched.weightBeforeTest6 && formik.errors.weightBeforeTest6)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightBeforeTest6', e.target.value)
                  }
                />
                <LabelInput
                  valueWidth="200px"
                  value={formik.values.weightAfterTest6}
                  isError={!!(formik.touched.weightAfterTest6 && formik.errors.weightAfterTest6)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formik.setFieldValue('weightAfterTest6', e.target.value)
                  }
                />
              </Stack>
            </Stack>
          </>
        );
      case false:
        return (
          <>
            {secondInfo.map((value, index) => (
              <TableData key={index} titles={value.title} values={value.values} />
            ))}
          </>
        );
      default:
        break;
    }
  }, [edit, secondInfo, formik, i18T]);

  return (
    <Stack gap={2}>
      <TestCardContainer>
        <EditButtons edit={edit} setEdit={setEdit} onSubmit={formik.handleSubmit} canEdit={canEdit} />
        <TableHeader title={firstHeader.title} values={firstHeader.values} />
        {firstInfoView}
      </TestCardContainer>

      <TestCardContainer>
        <TableHeader title={secondHeader.title} values={secondHeader.values} />
        {secondInfoView}
      </TestCardContainer>

      {!edit && (
        <TestCardContainer>
          <TableHeader title={thirdHeader.title} values={thirdHeader.values} />
          {thirdInfo.map((value, index) => (
            <TableData key={index} titles={value.title} values={value.values} />
          ))}
        </TestCardContainer>
      )}
    </Stack>
  );
}

export default memo(ExportCoarseAggOblatenessTable);
