import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import PersonAdd from '@Src/_basic/icons/PersonAdd';
import SearchIcon from '@Src/_basic/icons/Search';
import { useApi } from '@Src/redux/api/apiAction';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryType } from '../../screens/EntityScreen';

interface Props {
  query: QueryType;
  factoryOptions: Options[];
  stationOptions: Options[];
  updateQuery: (file: keyof QueryType, value: QueryType[keyof QueryType]) => void;
  selectFactoryHandler: (factoryId: number) => void;
  onOpenCreateModal: () => void;
  getSearchEntityData: () => void;
}
export default function EntityManagementSearchBar({
  query,
  factoryOptions,
  stationOptions,
  updateQuery,
  onOpenCreateModal,
  selectFactoryHandler,
  getSearchEntityData,
}: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
          <YatungSelect
            helperText={i18T('GLOBAL.FACTORY')}
            disabled={actionLoading}
            options={factoryOptions}
            value={query.factoryId}
            onChange={(e) => {
              selectFactoryHandler(e.target.value as number);
            }}
          />
          <YatungSelect
            helperText={'站點'}
            disabled={!query.factoryId || actionLoading}
            options={stationOptions}
            value={query.stationId}
            onChange={(e) => {
              updateQuery('stationId', e.target.value as number);
            }}
          />
          <YatungInput value={query.name} onChange={(e: any) => updateQuery('name', e.target.value)} />
          <YatungButton
            disabled={actionLoading || !query.factoryId || !query.stationId}
            text={i18T('GLOBAL.SEARCh_BUTTON')}
            startIcon={<SearchIcon />}
            color="blue"
            onClick={getSearchEntityData}
          />
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <YatungButton
            disabled={actionLoading || !query.factoryId || !query.stationId}
            text={i18T('ENTITYMANAGEMENT.CREATE_ENTITY_BUTTON')}
            startIcon={<PersonAdd />}
            onClick={onOpenCreateModal}
            color={'green'}
            sx={{
              fontSize: '16px',
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}
