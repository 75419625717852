export const checkIsNaN = (_value: number) => (isNaN(_value) ? 0 : _value);

export const calcAverage = (numbers: Array<number>): number => {
  const result = numbers.reduce((prev, current) => prev + current) / numbers.length;
  return isNaN(result) ? 0.0 : result;
};

export const calcRatio = (numerator: number, denominator: number): number => {
  const result = numerator / denominator;
  return isNaN(result) ? 0.0 : result;
};

export const calcPercent = (numerator: number, denominator: number): number => {
  const result = numerator / denominator;
  return isNaN(result) ? 0.0 : isFinite(result) ? result * 100 : 0.0;
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};
