import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Quality from '@Src/_basic/icons/Quality';
import ExptManagementFadeMenu from './components/ExptManagementFadeMenu';
import ProportionManageMenu from './components/PropotionManageMenu';
import QualityManagementFadeMenu from './components/QualityManagementFadeMenu';
import RealTimeMonitoring from './components/RealTimeMonitoring';

export default function QualityManageMenu() {
  const { t: i18T } = useTranslation();

  return (
    <>
      <Stack alignItems="center" justifyContent="flex-start" flexDirection="row" sx={{ px: 1, color: 'white' }}>
        <Stack alignItems="center" justifyContent="center" sx={{ width: '40px' }}>
          <Quality />
        </Stack>
        <Typography sx={{ fontSize: 24, fontWeight: 900 }}>{i18T('QUALITY_MANAGE.SIDE_MENU.TITLE')}</Typography>
      </Stack>
      <ExptManagementFadeMenu />
      <QualityManagementFadeMenu />
      <ProportionManageMenu />
      <RealTimeMonitoring />
    </>
  );
}
