import { Protocol } from '../Protocol';

export class YardReceiptsProtocol {
  public static readonly ALL_AREAS: string = `${Protocol.YATUNG_API_ROOT}/area/all`;
  public static readonly ALL_SOURCES: string = `${Protocol.YATUNG_API_ROOT}/source/all`;
  public static readonly ALL_SUPPLIERS: string = `${Protocol.YATUNG_API_ROOT}/supplier/all`;
  public static readonly ALL_YARD_RECEIPTS: string = `${Protocol.YATUNG_API_ROOT}/receipt/yard/all`;
  public static readonly YARD_RECEIPTS_BY_SEARCH: string = `${Protocol.YATUNG_API_ROOT}/receipt/yard/search`;
  public static readonly YARD_RECEIPT: string = `${Protocol.YATUNG_API_ROOT}/receipt/yard`;
  public static readonly GET_YARD_RECEIPT_TTL: string = `${Protocol.YATUNG_API_ROOT}/receipt/yard/ttl`;
}
