import YatungButton from '@Src/_basic/components/YatungButton';
import Fail from '@Src/_basic/icons/Fail';
import Success from '@Src/_basic/icons/Success';
import { AppPermissionRequest } from '@Src/_basic/object/AppPermissionType';
import { Stack } from '@mui/material';
import React from 'react';

const warningSrc = '/media/images/warning.svg';
const checkSrc = '/media/images/check.svg';

export type ExtraData = {
  onSuccessButtonClick: (id: number) => void;
  onDeleteButtonClick: (id: number) => void;
  getButtonText: (status: string) => string;
};
interface Props {
  row: AppPermissionRequest;
  formatExtraData: ExtraData;
}

export default function ActionColumnFormatter(props: Props) {
  const { row, formatExtraData } = props;
  return (
    <Stack spacing={2} direction="row">
      <YatungButton
        text={formatExtraData.getButtonText('success')}
        variant={'contained'}
        color="green"
        type={'button'}
        size={'large'}
        startIcon={<Success />}
        onClick={() => {
          formatExtraData.onSuccessButtonClick(row.id);
        }}
      />
      <YatungButton
        text={formatExtraData.getButtonText('fail')}
        variant={'contained'}
        color="red"
        type={'button'}
        size={'large'}
        startIcon={<Fail />}
        onClick={() => {
          formatExtraData.onDeleteButtonClick(row.id);
        }}
      />
    </Stack>
  );
}
