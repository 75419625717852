import { IconButton, InputAdornment } from '@mui/material';
import { Options } from '@Src/_basic/components/YatungFormSelect';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import { Field } from 'formik';
import React from 'react';
import EyeCloseIcon from '@Src/_basic/icons/EyeClose';
import FormSelect from './FormSelect';

interface Props {
  target: string;
  options?: Array<Options>;
  inputType?: string;
  handleInputType?: () => void;
}

export default function EditAccountField(props: Props) {
  const { target, options, inputType, handleInputType } = props;
  return (
    <>
      {!options ? (
        <>
          <Field
            id={target}
            name={target}
            type={inputType ? inputType : target}
            label=""
            component={YatungFormInput}
            sx={{ width: '450px', minWidth: '450px' }}
            required
            setInputProps={
              inputType
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleInputType}>
                          <EyeCloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : ''
            }
          />
        </>
      ) : (
        <Field
          id={target}
          name={target}
          type={target}
          label=""
          component={FormSelect}
          sx={{ width: '450px' }}
          options={options}
          required
        />
      )}
    </>
  );
}
