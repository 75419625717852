import { Response } from '@Src/_basic/object/ApiType';
import {
  CreateInitialFeedTroughAndFacotryRequest,
  CreateInitialStorageYardRequest,
  FeedTroughMaintenanceManagementDataRequire,
  PageableWarehouseData,
  PageableWarehouseParams,
  UpdateInitialFeedTroughRequest,
  UpdateInitialStorageYard,
  WarehouseAndStateData,
  WarehouseData,
  WarehouseSearchParams,
} from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import axios from 'axios';
import { Api } from '../Api';
import { FeedTroughMaintenanceProtocol } from './FeedTroughMaintenanceProtocol';

export class FeedTroughMaintenanceApi {
  public static feedTroughMaintenanceManagementData() {
    return axios.get(FeedTroughMaintenanceProtocol.FEEDTHROUGHMAINTENANCE_MANAGEMENT_DATA).then((res) => {
      return res.data;
    });
  }
  public static feedTroughMaintenanceManagementCreateData(require: FeedTroughMaintenanceManagementDataRequire) {
    return axios
      .post(FeedTroughMaintenanceProtocol.FEEDTHROUGHMAINTENANCE_MANAGEMENT_CREATE_DATA, require)
      .then((res) => {
        return res.data;
      });
  }
  public static feedTroughMaintenanceManagementUpdateData(require: FeedTroughMaintenanceManagementDataRequire) {
    return axios
      .put(FeedTroughMaintenanceProtocol.FEEDTHROUGHMAINTENANCE_MANAGEMENT_UPDATE_DATA, require)
      .then((res) => {
        return res.data;
      });
  }
  public static getStoragePaginationBySearch(
    params: PageableWarehouseParams,
    onSuccess?: (data: PageableWarehouseData) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(FeedTroughMaintenanceProtocol.GET_STORAGE_PAGINATION_SEARCH, config, onSuccess, onFailed, onFinally);
  }
  public static getStorageBySearch(
    params: WarehouseSearchParams | any,
    onSuccess?: (data: WarehouseAndStateData[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };
    Api.get(FeedTroughMaintenanceProtocol.GET_STORAGE_SEARCH, config, onSuccess, onFailed, onFinally);
  }
  public static getStorageById(
    id: number,
    onSuccess?: (data: Array<any>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id,
      },
    };
    Api.get(FeedTroughMaintenanceProtocol.GET_STORAGE_BY_ID, config, onSuccess, onFailed, onFinally);
  }
  public static putStorage(
    request: UpdateInitialFeedTroughRequest | UpdateInitialStorageYard,
    onSuccess?: (data: WarehouseAndStateData[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.put(FeedTroughMaintenanceProtocol.GET_STORAGE_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }
  public static postStorage(
    request: CreateInitialFeedTroughAndFacotryRequest | CreateInitialStorageYardRequest,
    onSuccess?: (data: WarehouseData[]) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(FeedTroughMaintenanceProtocol.GET_STORAGE_BY_ID, request, undefined, onSuccess, onFailed, onFinally);
  }
}
