import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';
import TestDetailDialog from '@Src/_basic/components/YatungTest/testDetail/TestDetailDialog';
import DetailIcon from '@Src/_basic/icons/Detail';
import { QrcodeData } from '@Src/_basic/object/QrcodeType';
import { AllTest, Record, TestItemEnum } from '@Src/_basic/object/TestType';
import { QrcodeApi } from '@Src/_basic/protocol/qrcode/QrcodeApi';
import { TestApi } from '@Src/_basic/protocol/test/TestApi';
import { useApi } from '@Src/redux/api/apiAction';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: Array<Record>;
  testType?: number;
}

const SieveReportTable = ({ data, testType }: Props) => {
  const { actionLoading } = useApi();
  const { t: i18T } = useTranslation();

  const [open, setOpen] = useState(false);
  const [recordDetail, setRecordDetail] = useState<Record>();

  const [dataDetail, setDataDetail] = useState<Map<string, AllTest | QrcodeData>>(new Map());

  const _dataDetailMap = new Map<string, AllTest | QrcodeData>();

  useEffect(() => {
    data.forEach((item) => {
      if (testType === 4) {
        TestApi.getTestDetail(
          {
            exptId: item.id,
            exptItemCode: item.exptItemCode,
          },
          (_data) => _dataDetailMap.set(`${item.exptItemCode}_${item.id}`, _data as AllTest),
        );
      } else {
        switch (item.exptItemCode) {
          case TestItemEnum.CHEM_ADMX_COMP_STR:
          case TestItemEnum.CHEM_ADMX_PENTA_TEST:
          case TestItemEnum.MIXING_WATER_CHL_CONTENT:
          case TestItemEnum.RECLAIMED_WATER_CHL_CONTENT:
          case TestItemEnum.RECLAIMED_WATER_SOLID_CONTENT:
          case TestItemEnum.RECLAIMED_WATER_SPECIFIC_WEIGHT:
            break;
          default:
            QrcodeApi.getQrcodeData(
              {
                ...(item?.sampleId && { sampleId: item?.sampleId }),
                ...(item?.materialReceiptId && { materialReceiptId: item?.materialReceiptId }),
                ...(item?.specimenId && { specimenId: item?.specimenId }),
              },
              (_data) => _dataDetailMap.set(`${item.exptItemCode}_${item.id}`, _data as QrcodeData),
            );
        }
      }
    });
    setDataDetail(_dataDetailMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testType, data]);

  const openHandler = useCallback((row: Record) => {
    setRecordDetail(row);
    setOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
    setRecordDetail(undefined);
  }, []);

  return (
    <>
      {testType !== 4 ? (
        <YatungTable
          loading={actionLoading}
          data={data}
          columns={[
            {
              text: i18T('VIEW.TABLE.TEST_COMPLETE_DATE'),
              dataField: 'completeTime',
              width: '90px',
              formatter: ({ row }) => {
                return (
                  <Stack direction="column">
                    <Typography>{`${moment(row.completeTime).format('YYYY/MM/DD HH:mm:ss')}`}</Typography>
                  </Stack>
                );
              },
            },
            testType !== 3
              ? {
                text: i18T('VIEW.TABLE.SAMPLE_DATE'),
                dataField: 'samplingTime',
                width: '90px',
                formatter: ({ row }) => {
                  return (
                    <Stack direction="column">
                      <Typography>{`${moment(row.samplingTime).format('YYYY/MM/DD HH:mm:ss')}`}</Typography>
                    </Stack>
                  );
                },
              }
              : { text: '', dataField: '', width: '0px' },
            {
              text: i18T('VIEW.TABLE.TEST_ITEM'),
              dataField: 'reqItemTitle',
              width: '150px',
            },
            testType !== 3
              ? {
                text: i18T('TEST.QRCODE_DATA.CAR_NO'),
                dataField: 'reqItemTitle',
                width: '80px',
                formatter: ({ row }) => {
                  return (
                    <Stack direction="column">
                      <Typography>
                        {(dataDetail?.get(`${row.exptItemCode}_${row.id}`) as AllTest)?.plateNumber
                          ? (dataDetail?.get(`${row.exptItemCode}_${row.id}`) as AllTest)?.plateNumber
                          : i18T('GLOBAL.NO_INFO')}
                      </Typography>
                    </Stack>
                  );
                },
              }
              : { text: '', dataField: '', width: '0px' },
            {
              text: i18T('VIEW.TABLE.SPECIFICATION'),
              dataField: 'specificationName',
              width: '120px',
            },
            testType !== 3
              ? {
                text: i18T('VIEW.TABLE.SOURCE'),
                dataField: 'sourceName',
                width: '120px',
              }
              : { text: '', dataField: '', width: '0px' },
            testType !== 3
              ? {
                text: i18T('VIEW.TABLE.SUPPLIER'),
                dataField: 'supplierName',
                width: '120px',
              }
              : { text: '', dataField: '', width: '0px' },
            {
              text: i18T('VIEW.TABLE.TEST_UNIT'),
              dataField: 'exptUnitName',
              width: '90px',
            },
            {
              text: i18T('VIEW.TABLE.TESTER_NAME'),
              dataField: 'operatorName',
              width: '120px',
            },
            {
              text: i18T('GLOBAL.ACTION'),
              dataField: 'action',
              width: '90px',
              formatter: ({ row }) => {
                return (
                  <Stack direction="row" spacing={1}>
                    <YatungButton
                      text={i18T('GLOBAL.DETAIL')}
                      startIcon={<DetailIcon />}
                      color="yellow"
                      onClick={() => openHandler(row)}
                    />
                  </Stack>
                );
              },
            },
          ]}
        />
      ) : (
        <YatungTable
          loading={actionLoading}
          data={data}
          columns={[
            {
              text: i18T('VIEW.TABLE.TEST_COMPLETE_DATE'),
              dataField: 'createTime',
              width: '120px',
              formatter: ({ row }) => {
                return (
                  <Stack direction="column">
                    <Typography>{`${moment(row.createTime).format('YYYY/MM/DD')}`}</Typography>
                  </Stack>
                );
              },
            },
            {
              text: i18T('VIEW.TABLE.SAMPLE_DATE'),
              dataField: 'samplingTime',
              width: '90px',
              formatter: ({ row }) => {
                return (
                  <Stack direction="column">
                    <Typography>{`${moment(dataDetail?.get(`${row.exptItemCode}_${row.id}`)?.samplingTime).format(
                      'YYYY/MM/DD',
                    )}`}</Typography>
                  </Stack>
                );
              },
            },
            {
              text: i18T('VIEW.TABLE.TEST_ITEM'),
              dataField: 'reqItemTitle',
              width: '200px',
            },
            {
              text: i18T('VIEW.TABLE.SITE_NAME'),
              dataField: 'civilEngrName',
              width: '150px',
              formatter: ({ row }) => {
                return (
                  <Typography>
                    {(dataDetail?.get(`${row.exptItemCode}_${row.id}`) as AllTest)?.civilEngrName ??
                      i18T('GLOBAL.NO_INFO')}
                  </Typography>
                );
              },
            },
            {
              text: i18T('VIEW.TABLE.SITE_ADDRESS'),
              dataField: 'civilEngrAddr',
              width: '150px',
              formatter: ({ row }) => {
                return (
                  <Typography sx={{ wordWrap: 'break-word', width: '180px' }}>
                    {(dataDetail?.get(`${row.exptItemCode}_${row.id}`) as AllTest)?.civilEngrAddr ??
                      i18T('GLOBAL.NO_INFO')}
                  </Typography>
                );
              },
            },
            {
              text: i18T('VIEW.TABLE.TARGET'),
              dataField: 'placement',
              width: '90px',
              formatter: ({ row }) => {
                return (
                  <Typography>
                    {(dataDetail?.get(`${row.exptItemCode}_${row.id}`) as AllTest)?.placement ?? i18T('GLOBAL.NO_INFO')}
                  </Typography>
                );
              },
            },
            {
              text: i18T('VIEW.TABLE.RATIO'),
              dataField: 'propName',
              width: '90px',
              formatter: ({ row }) => {
                return (
                  <Typography>
                    {(dataDetail?.get(`${row.exptItemCode}_${row.id}`) as AllTest)?.propName ?? i18T('GLOBAL.NO_INFO')}
                  </Typography>
                );
              },
            },
            {
              text: i18T('VIEW.TABLE.CAR_NO'),
              dataField: 'plateNumber',
              width: '90px',
              formatter: ({ row }) => {
                return (
                  <Typography>
                    {dataDetail?.get(`${row.exptItemCode}_${row.id}`)?.plateNumber ?? i18T('GLOBAL.NO_INFO')}
                  </Typography>
                );
              },
            },
            {
              text: i18T('VIEW.TABLE.TEST_UNIT'),
              dataField: 'exptUnitName',
              width: '90px',
            },
            {
              text: i18T('VIEW.TABLE.TESTER_NAME'),
              dataField: 'operatorName',
              width: '120px',
            },
            {
              text: i18T('GLOBAL.ACTION'),
              dataField: 'action',
              width: '90px',
              formatter: ({ row }) => {
                return (
                  <Stack direction="row" spacing={1}>
                    <YatungButton
                      text={i18T('GLOBAL.DETAIL')}
                      startIcon={<DetailIcon />}
                      color="yellow"
                      onClick={() => openHandler(row)}
                    />
                  </Stack>
                );
              },
            },
          ]}
        />
      )}

      {recordDetail && (
        <TestDetailDialog
          exptItemCode={recordDetail.exptItemCode}
          exptId={recordDetail.id}
          open={open}
          closeHandler={closeHandler}
          allTable={true}
          canEdit={true}
        />
      )}
    </>
  );
};

export default memo(SieveReportTable);
