import { Protocol } from '../Protocol';

export class AreaProtocol {
  public static readonly AREA_MANAGEMENT_ADD_DATA: string = `api/areamanagement/new`;
  public static readonly AREA_MANAGEMENT_GET_ID: string = 'api/areamanagement/getId';
  public static readonly AREA_MANAGEMENT_DATA: string = `api/areamanagement`;
  public static readonly AREA_MANAGEMENT_DELETE_DATA: string = `api/areamanagement/delete`;
  public static readonly AREA_MANAGEMENT_UPDATE_DATA: string = `api/areamanagement/update`;

  public static readonly ALL_AREAS: string = `${Protocol.YATUNG_API_ROOT}/area/all`;
  public static readonly get_AREA_BY_ID: string = `${Protocol.YATUNG_API_ROOT}/area`;
}
