import YatungButton from '@Src/_basic/components/YatungButton';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import BackButton from '@Src/_basic/icons/BackButton';
import { GetClearExecution, GetPaginationClearExecution } from '@Src/_basic/object/ClearExecutionType';
import { WarehouseAndStateData } from '@Src/_basic/object/FeedTroughMaintenanceManagement';
import { MixerOptions } from '@Src/_basic/object/MixerTypes';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { TypeData } from '@Src/_basic/object/TypeType';
import { ClearExecutionApi } from '@Src/_basic/protocol/clearExecution/ClearExecutionApi';
import { FeedTroughMaintenanceApi } from '@Src/_basic/protocol/feedTroughMaintenanceManagement/FeedTroughMaintenanceApi';
import { MixerApi } from '@Src/_basic/protocol/mixerInformation/MixerApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { Grid, Pagination, SelectChangeEvent, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ClearExecutionLogTable from './components/ClearExecutionLogTable';

interface AreaOptions extends Options {
  factories: Options[];
}
export default function ClearExecutionLogPage() {
  const { t: i18T } = useTranslation();
  const history = useNavigate();
  const { userAreaOptionsData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedArea, setSelectedArea] = useState<number>(0);
  const [factories, setFactories] = useState<Options[]>([]);
  const [selectedFactory, setSelectedFactory] = useState<number>(0);
  const [selectedMixer, setSelectedMixer] = useState<number>(0);
  const [mixers, setMixers] = useState<Options[]>([]);
  const [typesName, setTypesName] = useState<Options[]>([]);
  const [selectedTypeName, setSelectedTypeName] = useState<number>(0);
  const [logRecord, setLogRecord] = useState<GetClearExecution[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: 1,
    pageSize: 10,
    total: 0,
    totalCount: 0,
  });

  const getMixerOptions = (data: Array<MixerOptions>) => {
    const mixerOptions = data.map((type: MixerOptions) => ({ value: type.id, text: type.mixerName }));
    setMixers(mixerOptions);
  };

  const typesDataToOptions = (data: Array<TypeData>) => {
    const typesNameOptions = data.map((type: TypeData) => ({ value: type.id, text: type.name }));
    setTypesName(typesNameOptions);
  };

  const handleAreaSelectChange = useCallback(
    (area: number) => {
      const selected = userAreaOptionsData.find((i: AreaOptions) => i.value === area);
      if (selected) {
        setFactories(selected.factories);
        setSelectedArea(area);
      }
      setPageInfo({
        ...pageInfo,
        page: 1,
      });
    },
    [userAreaOptionsData],
  );
  const handleReset = () => {
    setLoading(false);
    setSelectedFactory(0);
    setSelectedMixer(0);
    setSelectedTypeName(0);
  };
  useEffect(() => {
    handleReset();
  }, [selectedArea]);
  useEffect(() => {
    MixerApi.getMixerOptionsByFactoryId(
      {
        factoryId: selectedFactory,
      },
      (data: MixerOptions[]) => {
        getMixerOptions(data);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  }, [selectedFactory]);
  useEffect(() => {
    setLoading(true);
    FeedTroughMaintenanceApi.getStorageBySearch(
      {
        factoryId: selectedFactory,
        ...(selectedMixer && { mixerId: selectedMixer }),
        isYard: false,
      },
      (data: WarehouseAndStateData[]) => {
        typesDataToOptions(data);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  }, [selectedFactory, selectedMixer]);
  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPageInfo({
      ...pageInfo,
      page,
    });
  };
  useEffect(() => {
    const getLogRecordData = (page: number) => {
      setLoading(true);
      ClearExecutionApi.getPaginationClearExecution(
        {
          factoryId: selectedFactory,
          ...(selectedMixer && { mixerId: selectedMixer }),
          ...(selectedTypeName && { storageId: selectedTypeName }),
          page,
        },
        (data: GetPaginationClearExecution) => {
          mappingData(data);
          setPageInfo({
            ...pageInfo,
            page,
            total: data.maxPage,
            totalCount: data.totalElements,
          });
        },
        undefined,
        () => {
          setLoading(false);
        },
      );
    };
    const mappingData = (data: GetPaginationClearExecution) => {
      setLogRecord(data.data);
    };
    getLogRecordData(pageInfo.page);
  }, [selectedFactory, selectedTypeName, selectedMixer, pageInfo.page]);
  const handleFactorySelectChange = useCallback((e: SelectChangeEvent<unknown>) => {
    setSelectedFactory(e.target.value as number);

    setSelectedMixer(0);
    setSelectedTypeName(0);
    setPageInfo({
      ...pageInfo,
      page: 1,
    });
  }, []);
  const handleTypeSelectChange = useCallback((e: SelectChangeEvent<unknown>) => {
    setSelectedTypeName(e.target.value as number);
    setPageInfo({
      ...pageInfo,
      page: 1,
    });
  }, []);
  const handleMixerSelectChange = useCallback((e: SelectChangeEvent<unknown>) => {
    setSelectedMixer(e.target.value as number);
    setSelectedTypeName(0);
    setPageInfo({
      ...pageInfo,
      page: 1,
    });
  }, []);
  const handleGoBack = useCallback(() => {
    history(-1);
  }, []);
  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.CLEAR_EXECUTION.TABLE_TITLE')}
      body={
        <>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
              <YatungSelect
                disabled={loading}
                options={userAreaOptionsData}
                value={selectedArea}
                onChange={(e) => handleAreaSelectChange(e.target.value as number)}
              />
              <YatungSelect
                disabled={selectedArea === 0 || loading}
                options={factories}
                value={selectedFactory}
                onChange={handleFactorySelectChange}
              />
              <YatungSelect
                disabled={selectedFactory === 0 || loading}
                options={mixers}
                value={selectedMixer}
                onChange={handleMixerSelectChange}
              />
              <YatungSelect
                disabled={selectedMixer === 0 || loading}
                options={typesName}
                value={selectedTypeName}
                onChange={handleTypeSelectChange}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <YatungButton
                text={i18T('GLOBAL.BACK')}
                startIcon={<BackButton sx={{ width: '25px', height: '25px' }} />}
                // disabled={query.typeId === 0}
                onClick={handleGoBack}
                color="green"
                sx={{ alignSelf: 'center' }}
              />
            </Stack>
          </Stack>

          <ClearExecutionLogTable logRecord={logRecord} loading={loading} />
          <Grid container justifyContent={'center'} item xs={12} sx={{ mt: 2 }}>
            <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
          </Grid>
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
