import YatungButton from '@Src/_basic/components/YatungButton';
import YatungTable from '@Src/_basic/components/YatungTable';
import DetailIcon from '@Src/_basic/icons/Detail';
import { ReportViewResponse } from '@Src/_basic/object/MonthlyReportType';
import { MonthlyReportApi } from '@Src/_basic/protocol/monthlyReport/MonthlyReportApi';
import { useApi } from '@Src/redux/api/apiAction';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MonthlyReportDialog from './MonthlyReportDialog';

interface Props {
  data: Array<ReportViewResponse>;
}

const MonthlyReportTable = ({ data }: Props) => {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const [open, setOpen] = useState(false);
  const [detailData, setDetailData] = useState<any>();
  const [headerData, setHeaderData] = useState<ReportViewResponse>();

  const getMonthlyDetailData = useCallback((row: ReportViewResponse) => {
    const parmas = {
      factoryId: row.factory?.id as number,
      exptUnitId: row.experimentUnit.id,
      startTime: row.startTime,
      endTime: row.endTime,
      ...(row.specification && { specificationId: row.specification?.id as number }),
      ...(row.source && { sourceId: row.source?.id as number }),
      ...(row.supplier && { supplierId: row.supplier?.id as number }),
      ...(row.formId && { formId: row.formId as number }),
    };
    setHeaderData(row);

    switch (row.monthlyReportExperimentItem.id) {
      case 1:
        MonthlyReportApi.getFineAggAnalysis(
          parmas,
          (_data) => setDetailData(_data),
          undefined,
          () => setOpen(true),
        );
        break;
      case 2:
        MonthlyReportApi.getFineAggAMS(
          parmas,
          (_data) => setDetailData(_data),
          undefined,
          () => setOpen(true),
        );
        break;
      case 3:
        MonthlyReportApi.getFineAggAbsorption(
          parmas,
          (_data) => setDetailData(_data),
          undefined,
          () => setOpen(true),
        );
        break;
      case 4:
        MonthlyReportApi.getFineAggChloride(
          parmas,
          (_data) => setDetailData(_data),
          undefined,
          () => setOpen(true),
        );
        break;
      case 5:
        MonthlyReportApi.getCoarseAggAnalysis(
          parmas,
          (_data) => setDetailData(_data),
          undefined,
          () => setOpen(true),
        );
        break;
      case 6:
        MonthlyReportApi.getCoarseAggAbsorption(
          parmas,
          (_data) => setDetailData(_data),
          undefined,
          () => setOpen(true),
        );
        break;
      case 7:
        MonthlyReportApi.getFlyAshLValue(
          parmas,
          (_data) => setDetailData(_data),
          undefined,
          () => setOpen(true),
        );
        break;
      case 8:
        MonthlyReportApi.getFlyAshLossOfIgnition(
          parmas,
          (_data) => setDetailData(_data),
          undefined,
          () => setOpen(true),
        );
        break;
      case 9:
        MonthlyReportApi.getConcreteAntiStress(
          parmas,
          (_data) => setDetailData(_data),
          undefined,
          () => setOpen(true),
        );
        break;
      case 10:
        MonthlyReportApi.getFineAggRValue(
          parmas,
          (_data) => setDetailData(_data),
          undefined,
          () => setOpen(true),
        );
        break;
      default:
        return;
    }
  }, []);

  return (
    <>
      <YatungTable
        loading={actionLoading}
        data={data}
        columns={[
          {
            text: '日期',
            dataField: 'createTime',
            width: '50px',
            formatter: ({ row }) => (
              <Typography>{moment(row?.createTime ?? new Date()).format('YYYY/MM/DD')}</Typography>
            ),
          },
          {
            text: '檔案名稱',
            dataField: 'name',
            width: '240px',
          },
          {
            text: '規格',
            dataField: 'specificationName',
            width: '60px',
            formatter: ({ row }) => <Typography>{row?.specification?.name ?? i18T('GLOBAL.ALL')}</Typography>,
          },
          {
            text: '來源',
            dataField: 'sourceName',
            width: '60px',
            formatter: ({ row }) => <Typography>{row?.source?.name ?? i18T('GLOBAL.ALL')}</Typography>,
          },
          {
            text: '供應商',
            dataField: 'supplierName',
            width: '60px',
            formatter: ({ row }) => <Typography>{row?.supplier?.name ?? i18T('GLOBAL.ALL')}</Typography>,
          },
          {
            text: '動作',
            dataField: 'action',
            width: '60px',
            formatter: ({ row }) => {
              return (
                <Stack direction="row" spacing={1}>
                  <YatungButton
                    text={i18T('GLOBAL.DETAIL')}
                    startIcon={<DetailIcon />}
                    color="yellow"
                    onClick={() => {
                      getMonthlyDetailData(row);
                    }}
                  />
                </Stack>
              );
            },
          },
        ]}
      />
      {open && headerData && (
        <MonthlyReportDialog
          open={open}
          closeHandler={() => setOpen(false)}
          detailData={detailData}
          headerData={headerData}
        />
      )}
    </>
  );
};

export default memo(MonthlyReportTable);
