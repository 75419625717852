import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import YatungPage from '@Src/_basic/components/YatungPage';

import { ReportViewResponse } from '@Src/_basic/object/MonthlyReportType';
import { MonthlyReportApi } from '@Src/_basic/protocol/monthlyReport/MonthlyReportApi';
import ExcelReportModal from './components/ExcelReportDialog';
import MonthlyReportSelection from './components/MonthlyReportSelection';
import MonthlyReportTable from './components/MonthlyReportTable';

function MonthlyReportScreen() {
  const { t: i18T } = useTranslation();
  const [data, setData] = useState<Array<ReportViewResponse>>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const getReportSearch = useCallback(
    (
      time: number,
      factoryId: number,
      experimentUnitId: number,
      monthlyReportExperimentId: number,
      specificationId?: number,
      sourceId?: number,
      supplierId?: number,
      formId?: number,
    ) => {
      MonthlyReportApi.getAllMonthlyReportView(
        {
          time,
          factoryId,
          experimentUnitId,
          monthlyReportExperimentId,
          ...(specificationId && { specificationId }),
          ...(sourceId && { sourceId }),
          ...(supplierId && { supplierId }),
          ...(formId && (monthlyReportExperimentId === 1 || monthlyReportExperimentId === 5) && { formId }),
          timeZoneStr: 'Asia/Taipei',
          removed: false,
        },
        (_data) => setData(_data),
      );
    },
    [],
  );

  return (
    <YatungPage
      isMonthlyReport
      handleMonthlyReportClick={() => {
        setModalOpen(true);
      }}
      title={i18T('VIEW.MONTHLY_REPORT.TITLE')}
      contentBgColor="#FFF"
      body={
        <>
          <MonthlyReportSelection getReportSearch={getReportSearch} />
          <MonthlyReportTable data={data} />
          {modalOpen && <ExcelReportModal open={modalOpen} handleCloseModal={() => setModalOpen(false)} />}
        </>
      }
    />
  );
}

export default memo(MonthlyReportScreen);
