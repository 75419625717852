import { Box, Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import React, { ChangeEvent, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import CancelIcon from '@Src/_basic/icons/Cancel';
import ConfirmIcon from '@Src/_basic/icons/Confirm';
import EditIcon from '@Src/_basic/icons/Edit';

import { CalcCreateProportion } from '@Src/_basic/helpers/Proportion';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import { ProportionContext } from '@Src/_basic/contexts/ProportionContext';
import { checkIsNaN } from '@Src/_basic/helpers/CheckIsNaN';
import { getInteger } from '@Src/_basic/helpers/FormatNumber';
import { basicNumberSchema, basicStringSchema } from '@Src/_basic/helpers/TestForm';
import { PostProportionRequest, ProportionMaintenanceResponse } from '@Src/_basic/object/ProportionType';
import { ProportionApi } from '@Src/_basic/protocol/proportion/ProportionApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LabelInput from './LabelInput';
import LabelInputV2 from './LabelInputV2';
import RowContent from './RowContent';
import ValueCheckWrapper from './ValueCheckWrapper';

const initialValues = {
  selectedMixer: '1',
  propName: '',
  dsgnStr: '1',
  slump: '1',
  grainSize: '1',
  quantityMixingWater: '1',
  recycledWater: '1',
  waterColloidRatio: '1',
  cementPct: '1',
  slagPct: '1',
  flyAshPct: '1',
  microsilicaPct: '1',
  airContent: '1',
  coarseAggCompVolumeRatio: '1',
  stone1Pct: '1',
  stone2Pct: '1',
  stone3Pct: '1',
  stone4Pct: '1',
  sand1Pct: '1',
  sand2Pct: '1',
  sand3Pct: '1',
  sand4Pct: '1',
  chemAdmx1UsagePct: '1',
  chemAdmx2UsagePct: '1',
  chemAdmx3UsagePct: '1',
  chemAdmx4UsagePct: '1',
  chemAdmx5UsagePct: '1',
  chemAdmx6UsagePct: '1',
  normalPortlandCementRatio: '1',
};

const validationSchema = Yup.object().shape({
  propName: basicStringSchema(),
  quantityMixingWater: basicNumberSchema,
  slump: basicNumberSchema,
  grainSize: basicNumberSchema,
  waterColloidRatio: basicNumberSchema,
  stone1Pct: basicNumberSchema,
  stone2Pct: basicNumberSchema,
  stone3Pct: basicNumberSchema,
  stone4Pct: basicNumberSchema,
  sand1Pct: basicNumberSchema,
  sand2Pct: basicNumberSchema,
  sand3Pct: basicNumberSchema,
  sand4Pct: basicNumberSchema,
  chemAdmx1UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  chemAdmx2UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  chemAdmx3UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  chemAdmx4UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  chemAdmx5UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  chemAdmx6UsagePct: Yup.string()
    .min(1)
    .matches(/^-?\d+\.?\d*$/),
  selectedMixer: basicStringSchema(),
  dsgnStr: basicNumberSchema,
  cementPct: basicNumberSchema,
  slagPct: basicNumberSchema,
  flyAshPct: basicNumberSchema,
  microsilicaPct: basicNumberSchema,
  airContent: basicNumberSchema,
  coarseAggCompVolumeRatio: basicNumberSchema,
  recycledWater: basicNumberSchema,
  normalPortlandCementRatio: basicNumberSchema,
});

const rowBoxStyle = { backgroundColor: '#697EA3', borderRadius: '4px', m: 1, p: 1, width: '300px' };
const rowLabelsx = {
  fontWeight: 400,
  fontSize: 16,
  color: 'white',
  textAlign: 'center',
  lineHeight: 1,
  p: 0.5,
  letterSpacing: 4,
};
const rowValuesx = { fontWeight: 700, fontSize: 32, color: 'white', textAlign: 'center', lineHeight: 1, p: 0.5 };

interface Props {
  proportionMaintenance: ProportionMaintenanceResponse;
  selectFactory: number;
  selectArea: number;
}

function CreateProportionForm({ proportionMaintenance, selectFactory, selectArea }: Props) {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { setCreatePropportionSelect, createPropportionForm, setCreatePropportionForm } = useContext(ProportionContext);

  const [alertText, setAlertText] = useState<string>('');
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [postRequest, setPostRequest] = useState<PostProportionRequest>();

  const mixerItems: Array<Options> = useMemo(
    () => [
      { text: `${i18T('PROPORTION.MIXER_NUMBER', { number: 1 })}`, value: 1 },
      { text: `${i18T('PROPORTION.MIXER_NUMBER', { number: 2 })}`, value: 2 },
    ],
    [i18T],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => postProportion(values),
  });

  useEffect(() => {
    if (createPropportionForm) {
      Object.entries(createPropportionForm).forEach(([key, value]) => {
        if (key === 'selectedMixer') {
          switch (value) {
            case '1':
              formik.setFieldValue('selectedMixer', '1');
              break;
            case '2':
              formik.setFieldValue('selectedMixer', '2');
              break;
          }
        } else formik.setFieldValue(`${key}`, `${value}`);
      });
    }
  }, [createPropportionForm, formik]);

  const totalStonePercentage = useMemo(() => {
    const _totalStone =
      Number(formik.values.stone1Pct) +
      Number(formik.values.stone2Pct) +
      Number(formik.values.stone3Pct) +
      Number(formik.values.stone4Pct);
    return Number(_totalStone?.toFixed(1)) >= 100.5 || Number(_totalStone?.toFixed(1)) < 99.5;
  }, [formik.values.stone1Pct, formik.values.stone2Pct, formik.values.stone3Pct, formik.values.stone4Pct]);

  const totalSandPercentage = useMemo(() => {
    const _totalSand =
      Number(formik.values.sand1Pct) +
      Number(formik.values.sand2Pct) +
      Number(formik.values.sand3Pct) +
      Number(formik.values.sand4Pct);
    return Number(_totalSand?.toFixed(1)) >= 100.5 || Number(_totalSand?.toFixed(1)) < 99.5;
  }, [formik.values.sand1Pct, formik.values.sand2Pct, formik.values.sand3Pct, formik.values.sand4Pct]);

  const totalBlinderPercentage = useMemo(() => {
    const _totalBlinder =
      Number(formik.values.cementPct) +
      Number(formik.values.slagPct) +
      Number(formik.values.flyAshPct) +
      Number(formik.values.microsilicaPct);
    return Number(_totalBlinder?.toFixed(1)) >= 100.5 || Number(_totalBlinder?.toFixed(1)) < 99.5;
  }, [formik.values.cementPct, formik.values.slagPct, formik.values.flyAshPct, formik.values.microsilicaPct]);

  const postNewProportion = useCallback(
    (postRequest: PostProportionRequest) => {
      console.log(postRequest);
      if (!postRequest) return;
      ProportionApi.postProportion(postRequest, () =>
        navigate(`/proportionManage/proportionList/version/${postRequest.propName}/${postRequest.factoryId}`),
      );
      setCreatePropportionForm(undefined);
      setIsShowAlert(false);
    },
    [postRequest, navigate, setCreatePropportionForm],
  );

  const mixerSelect = useCallback((e: number) => {
    switch (e) {
      case 1:
        formik.setFieldValue('selectedMixer', '1');
        break;
      case 2:
        formik.setFieldValue('selectedMixer', '2');
        break;
    }
  }, []);

  const formDataConvertToImport = useCallback(
    (values: any) => ({
      dsgnStr: checkIsNaN(+values?.dsgnStr),

      cementPct: checkIsNaN(+values?.cementPct),
      slagPct: checkIsNaN(+values?.slagPct),
      flyAshPct: checkIsNaN(+values?.flyAshPct),
      microsilicaPct: checkIsNaN(+values?.microsilicaPct),

      airContent: checkIsNaN(+values?.airContent),
      coarseAggCompVolumeRatio: checkIsNaN(+values?.coarseAggCompVolumeRatio),

      stone1Pct: checkIsNaN(+values?.stone1Pct),
      stone2Pct: checkIsNaN(+values?.stone2Pct),
      stone3Pct: checkIsNaN(+values?.stone3Pct),
      stone4Pct: checkIsNaN(+values?.stone4Pct),

      sand1Pct: checkIsNaN(+values?.sand1Pct),
      sand2Pct: checkIsNaN(+values?.sand2Pct),
      sand3Pct: checkIsNaN(+values?.sand3Pct),
      sand4Pct: checkIsNaN(+values?.sand4Pct),

      propName: values?.propName,
      slump: checkIsNaN(+values?.slump),
      grainSize: checkIsNaN(+values?.grainSize),

      quantityMixingWater: checkIsNaN(+values?.quantityMixingWater),
      waterColloidRatio: checkIsNaN(+values?.waterColloidRatio),
      chemAdmx1UsagePct: proportionMaintenance.chemAdmx1Name ? checkIsNaN(+values?.chemAdmx1UsagePct) : 0,
      chemAdmx2UsagePct: proportionMaintenance.chemAdmx2Name ? checkIsNaN(+values?.chemAdmx2UsagePct) : 0,
      chemAdmx3UsagePct: proportionMaintenance.chemAdmx3Name ? checkIsNaN(+values?.chemAdmx3UsagePct) : 0,
      chemAdmx4UsagePct: proportionMaintenance.chemAdmx4Name ? checkIsNaN(+values?.chemAdmx4UsagePct) : 0,
      chemAdmx5UsagePct: proportionMaintenance.chemAdmx5Name ? checkIsNaN(+values?.chemAdmx5UsagePct) : 0,
      chemAdmx6UsagePct: proportionMaintenance.chemAdmx6Name ? checkIsNaN(+values?.chemAdmx6UsagePct) : 0,
      normalPortlandCementRatio: checkIsNaN(+values.normalPortlandCementRatio),

      recycledWater: checkIsNaN(+values?.recycledWater),
      selectedMixer: values?.selectedMixer,
    }),
    [proportionMaintenance],
  );

  const {
    safeStr,
    cementitiousWt,
    recycledWaterWt,
    tapWaterWt,

    cementWt,
    slagWt,
    flyAshWt,
    microsilicaWt,
    cementVolume,
    slagVolume,
    flyAshVolume,
    microsilicaVolume,

    sandPct,
    coarseAggWt,
    fineAggWt,
    airVolume,
    coarseAggVolume,
    fineAggVolume,
    stone1Wt,
    stone2Wt,
    stone3Wt,
    stone4Wt,
    sand1Wt,
    sand2Wt,
    sand3Wt,
    sand4Wt,
    stone1Volume,
    stone2Volume,
    stone3Volume,
    stone4Volume,
    sand1Volume,
    sand2Volume,
    sand3Volume,
    sand4Volume,

    chemAdmx1Wt,
    chemAdmx2Wt,
    chemAdmx3Wt,
    chemAdmx4Wt,
    chemAdmx5Wt,
    chemAdmx6Wt,
    chemAdmx1Volume,
    chemAdmx2Volume,
    chemAdmx3Volume,
    chemAdmx4Volume,
    chemAdmx5Volume,
    chemAdmx6Volume,
    expectedStr,
    proportionUnitWt,
    truthWaterColloidRatio,
    arrivedDesignStrPct,
    proportionUnitVolume,
    proportionUnitCost,
    carbonEmissionMaterial,
    carbonEmissionLifeCycle,
  } = new CalcCreateProportion(formDataConvertToImport(formik.values), proportionMaintenance);

  const postProportion = (values: any) => {
    if (!proportionMaintenance) return;

    const require = {
      ...formDataConvertToImport(values),

      factoryId: selectFactory,

      cementWt,
      slagWt,
      flyAshWt,
      microsilicaWt,

      sandTtlWt: fineAggWt,
      stoneTtlWt: coarseAggWt,
      maintPropId: proportionMaintenance.id,
    };

    ProportionApi.getIsExistProportion(require, (_data) => {
      const exitProportion = Object.values(_data);
      setPostRequest(require);
      if (!exitProportion[0]) postNewProportion(require);
      else {
        setIsShowAlert(true);
        if (!exitProportion[1])
          setAlertText(`${i18T('PROPORTION.SYSTEM_CHECK')}: ${i18T('PROPORTION.PROPORTION_NAME_EXIST_NEW_VERSION')}}`);
        else
          setAlertText(
            `${i18T('PROPORTION.SYSTEM_CHECK')}: ${i18T('PROPORTION.PROPORTION_NAME_EXIST_CONFIRM')}: ${i18T(
              'PROPORTION.PROPORTION_NAME_EXIST_PREVIOUS_VERSION',
            )}`,
          );
      }
    });
  };

  const showFirstSection = useCallback(
    () => (
      <>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box sx={{ ...rowBoxStyle, width: '48%' }}>
            <RowContent
              labels={[`${i18T('PROPORTION.RECYCLED_WATER_WEIGHT')}(kg)`]}
              values={[getInteger(recycledWaterWt?.toFixed(2))]}
              showBorder={false}
              labelsx={rowLabelsx}
              valuesx={rowValuesx}
            />
          </Box>
          <Box sx={{ ...rowBoxStyle, width: '48%' }}>
            <RowContent
              labels={[`${i18T('PROPORTION.TAP_WATER_WEIGHT')}(kg)`]}
              values={[getInteger(tapWaterWt?.toFixed(2))]}
              showBorder={false}
              labelsx={rowLabelsx}
              valuesx={rowValuesx}
            />
          </Box>
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box sx={{ ...rowBoxStyle, width: '48%' }}>
            <RowContent
              labels={[`${i18T('PROPORTION.SAFE_STR')}(%)`]}
              values={[safeStr.toFixed(2)]}
              showBorder={false}
              labelsx={rowLabelsx}
              valuesx={rowValuesx}
            />
          </Box>
          <Box sx={{ ...rowBoxStyle, width: '48%' }}>
            <RowContent
              labels={[`${i18T('PROPORTION.CEMENTITIOUS_TOTAL')}(kg)`]}
              values={[getInteger(cementitiousWt?.toFixed(2))]}
              showBorder={false}
              labelsx={rowLabelsx}
              valuesx={rowValuesx}
            />
          </Box>
        </Stack>
      </>
    ),
    [i18T, safeStr, cementitiousWt, recycledWaterWt, tapWaterWt],
  );

  const showSecondSection = useCallback(
    () => (
      <Stack>
        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
          <RowContent
            labels={[
              `${i18T('PROPORTION.SAND_RATIO')}(%)`,
              `${i18T('PROPORTION.COARSE_AGG_WEIGHT')}(kg)`,
              `${i18T('PROPORTION.FINE_AGG_WEIGHT')}(kg)`,
            ]}
            values={[sandPct?.toFixed(2), getInteger(coarseAggWt?.toFixed(2)), getInteger(fineAggWt?.toFixed(2))]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            borderColor={'#fff'}
          />
          <RowContent
            labels={[
              `${i18T('PROPORTION.AIR')}(M³)`,
              `${i18T('PROPORTION.COARSE_AGG_VOLUME')}(M³)`,
              `${i18T('PROPORTION.FINE_AGG_WEIGHT')}(M³)`,
            ]}
            values={[airVolume?.toFixed(3), coarseAggVolume?.toFixed(3), fineAggVolume?.toFixed(3)]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
          <RowContent
            labels={[
              `${i18T('PROPORTION.STONE1')}(kg)`,
              `${i18T('PROPORTION.STONE2')}(kg)`,
              `${i18T('PROPORTION.STONE3')}(kg)`,
              `${i18T('PROPORTION.STONE4')}(kg)`,
            ]}
            values={[
              getInteger(stone1Wt?.toFixed(2)),
              getInteger(stone2Wt?.toFixed(2)),
              getInteger(stone3Wt?.toFixed(2)),
              getInteger(stone4Wt?.toFixed(2)),
            ]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            borderColor={'#fff'}
          />
          <RowContent
            labels={[
              `${i18T('PROPORTION.STONE1')}(M³)`,
              `${i18T('PROPORTION.STONE2')}(M³)`,
              `${i18T('PROPORTION.STONE3')}(M³)`,
              `${i18T('PROPORTION.STONE4')}(M³)`,
            ]}
            values={[
              stone1Volume?.toFixed(3),
              stone2Volume?.toFixed(3),
              stone3Volume?.toFixed(3),
              stone4Volume?.toFixed(3),
            ]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
          <RowContent
            labels={[
              `${i18T('PROPORTION.SAND1')}(kg)`,
              `${i18T('PROPORTION.SAND2')}(kg)`,
              `${i18T('PROPORTION.SAND3')}(kg)`,
              `${i18T('PROPORTION.SAND4')}(kg)`,
            ]}
            values={[
              getInteger(sand1Wt?.toFixed(2)),
              getInteger(sand2Wt?.toFixed(2)),
              getInteger(sand3Wt?.toFixed(2)),
              getInteger(sand4Wt?.toFixed(2)),
            ]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            borderColor={'#fff'}
          />
          <RowContent
            labels={[
              `${i18T('PROPORTION.SAND1')}(M³)`,
              `${i18T('PROPORTION.SAND2')}(M³)`,
              `${i18T('PROPORTION.SAND3')}(M³)`,
              `${i18T('PROPORTION.SAND4')}(M³)`,
            ]}
            values={[
              sand1Volume?.toFixed(3),
              sand2Volume?.toFixed(3),
              sand3Volume?.toFixed(3),
              sand4Volume?.toFixed(3),
            ]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
      </Stack>
    ),
    [
      i18T,
      sandPct,
      airVolume,
      coarseAggWt,
      coarseAggVolume,
      fineAggWt,
      fineAggVolume,
      stone1Wt,
      stone1Volume,
      stone2Wt,
      stone2Volume,
      stone3Wt,
      stone3Volume,
      stone4Wt,
      stone4Volume,
      sand1Wt,
      sand1Volume,
      sand2Wt,
      sand2Volume,
      sand3Wt,
      sand3Volume,
      sand4Wt,
      sand4Volume,
    ],
  );

  const showThirdSection = useCallback(
    () => (
      <Stack>
        <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
          <RowContent
            labels={[
              `${i18T('PROPORTION.CEMENT')}(kg)`,
              `${i18T('PROPORTION.SLAG')}(kg)`,
              `${i18T('PROPORTION.FLY_ASH')}(kg)`,
              `${i18T('PROPORTION.MICROSILICA')}(kg)`,
            ]}
            values={[
              getInteger(cementWt?.toFixed(2)),
              getInteger(slagWt?.toFixed(2)),
              getInteger(flyAshWt?.toFixed(2)),
              getInteger(microsilicaWt?.toFixed(2)),
            ]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            borderColor={'#fff'}
          />
          <RowContent
            labels={[
              `${i18T('PROPORTION.CEMENT')}(M³)`,
              `${i18T('PROPORTION.SLAG')}(M³)`,
              `${i18T('PROPORTION.FLY_ASH')}(M³)`,
              `${i18T('PROPORTION.MICROSILICA')}(M³)`,
            ]}
            values={[
              cementVolume?.toFixed(3),
              slagVolume?.toFixed(3),
              flyAshVolume?.toFixed(3),
              microsilicaVolume?.toFixed(3),
            ]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
      </Stack>
    ),
    [i18T, cementWt, cementVolume, slagWt, slagVolume, flyAshWt, flyAshVolume, microsilicaWt, microsilicaVolume],
  );

  const showFourthSection = useCallback(
    () => (
      <>
        {proportionMaintenance.chemAdmx1Name && (
          <Stack>
            <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
              <RowContent
                labels={[
                  proportionMaintenance.chemAdmx1Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', { number: 1 })}(kg)`
                    : '',
                  proportionMaintenance.chemAdmx2Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', { number: 2 })}(kg)`
                    : '',
                  proportionMaintenance.chemAdmx3Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', { number: 3 })}(kg)`
                    : '',
                ]}
                values={[
                  proportionMaintenance.chemAdmx1Name ? getInteger(chemAdmx1Wt?.toFixed(2)) : '',
                  proportionMaintenance.chemAdmx2Name ? getInteger(chemAdmx2Wt?.toFixed(2)) : '',
                  proportionMaintenance.chemAdmx3Name ? getInteger(chemAdmx3Wt?.toFixed(2)) : '',
                ]}
                justifyContent="flex-start"
                columnWidth={[33, 33, 33]}
                labelsx={rowLabelsx}
                valuesx={rowValuesx}
                borderColor={'#fff'}
              />
              <RowContent
                labels={[
                  proportionMaintenance.chemAdmx1Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_VOLUME', { number: 1 })}(M³)`
                    : '',
                  proportionMaintenance.chemAdmx2Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_VOLUME', { number: 2 })}(M³)`
                    : '',
                  proportionMaintenance.chemAdmx3Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_VOLUME', { number: 3 })}(M³)`
                    : '',
                ]}
                values={[
                  proportionMaintenance.chemAdmx1Name ? chemAdmx1Volume?.toFixed(3) : '',
                  proportionMaintenance.chemAdmx2Name ? chemAdmx2Volume?.toFixed(3) : '',
                  proportionMaintenance.chemAdmx3Name ? chemAdmx3Volume?.toFixed(3) : '',
                ]}
                justifyContent="flex-start"
                columnWidth={[33, 33, 33]}
                showBorder={false}
                labelsx={rowLabelsx}
                valuesx={rowValuesx}
              />
            </Box>
          </Stack>
        )}

        {proportionMaintenance.chemAdmx4Name && (
          <Stack>
            <Box sx={{ ...rowBoxStyle, width: 'auto' }}>
              <RowContent
                labels={[
                  `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', { number: 4 })}(kg)`,
                  proportionMaintenance.chemAdmx5Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', { number: 5 })}(kg)`
                    : '',
                  proportionMaintenance.chemAdmx6Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_WEIGHT', { number: 6 })}(kg)`
                    : '',
                ]}
                values={[
                  getInteger(chemAdmx4Wt?.toFixed(2)),
                  proportionMaintenance.chemAdmx5Name ? getInteger(chemAdmx5Wt?.toFixed(2)) : '',
                  proportionMaintenance.chemAdmx6Name ? getInteger(chemAdmx6Wt?.toFixed(2)) : '',
                ]}
                justifyContent="flex-start"
                columnWidth={[33, 33, 33]}
                labelsx={rowLabelsx}
                valuesx={rowValuesx}
                borderColor={'#fff'}
              />
              <RowContent
                labels={[
                  `${i18T('PROPORTION.CHEM_ADMX_VOLUME', { number: 4 })}(M³)`,
                  proportionMaintenance.chemAdmx5Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_VOLUME', { number: 5 })}(M³)`
                    : '',
                  proportionMaintenance.chemAdmx6Name
                    ? `${i18T('PROPORTION.CHEM_ADMX_VOLUME', { number: 6 })}(M³)`
                    : '',
                ]}
                values={[
                  chemAdmx4Volume?.toFixed(3),
                  proportionMaintenance.chemAdmx5Name ? chemAdmx5Volume?.toFixed(3) : '',
                  proportionMaintenance.chemAdmx6Name ? chemAdmx6Volume?.toFixed(3) : '',
                ]}
                justifyContent="flex-start"
                columnWidth={[33, 33, 33]}
                showBorder={false}
                labelsx={rowLabelsx}
                valuesx={rowValuesx}
              />
            </Box>
          </Stack>
        )}
      </>
    ),
    [
      i18T,
      chemAdmx1Wt,
      chemAdmx1Volume,
      chemAdmx2Wt,
      chemAdmx2Volume,
      chemAdmx3Wt,
      chemAdmx3Volume,
      proportionMaintenance.chemAdmx1Name,
      proportionMaintenance.chemAdmx2Name,
      proportionMaintenance.chemAdmx3Name,
      proportionMaintenance.chemAdmx4Name,
      proportionMaintenance.chemAdmx5Name,
      proportionMaintenance.chemAdmx6Name,
      chemAdmx4Wt,
      chemAdmx4Volume,
      chemAdmx5Wt,
      chemAdmx5Volume,
      chemAdmx6Wt,
      chemAdmx6Volume,
    ],
  );

  const showResultSection = useCallback(
    () => (
      <Stack flexDirection="row" justifyContent="space-between">
        <Box sx={{ ...rowBoxStyle, width: '33%', backgroundColor: '#012E7F' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.EXPECTED_STR')}`]}
            values={[{ text: expectedStr?.toFixed(1), unit: 'Kgf/cm²' }]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
          <RowContent
            labels={[`${i18T('PROPORTION.ARRIVE_DESIGN_STR')}`]}
            values={[{ text: arrivedDesignStrPct?.toFixed(1), unit: '%' }]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
        <Box sx={{ ...rowBoxStyle, width: '33%', backgroundColor: '#012E7F' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.PROPORTION_UNIT_WEIGHT')}`]}
            values={[{ text: proportionUnitWt?.toFixed(0), unit: 'kg' }]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
          <RowContent
            labels={[`${i18T('PROPORTION.PROPORTION_UNIT_VOLUME')}`]}
            values={[{ text: proportionUnitVolume?.toFixed(3), unit: 'M³' }]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
        <Box sx={{ ...rowBoxStyle, width: '33%', backgroundColor: '#012E7F' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.TRUTH_WATER_COLLOID_RATIO')}`]}
            values={[{ text: truthWaterColloidRatio?.toFixed(2), unit: 'W/B' }]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
          <RowContent
            labels={[`${i18T('PROPORTION.PROPORTION_UNIT_COST')}`]}
            values={[{ text: proportionUnitCost?.toFixed(1), unit: '元' }]}
            showBorder={false}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
          />
        </Box>
      </Stack>
    ),
    [
      i18T,
      expectedStr,
      arrivedDesignStrPct,
      proportionUnitWt,
      proportionUnitVolume,
      truthWaterColloidRatio,
      proportionUnitCost,
    ],
  );

  const showCarbonEmissionSection = useCallback(
    () => (
      <Stack flexDirection="row" justifyContent="space-between">
        <Box sx={{ ...rowBoxStyle, width: '49%', backgroundColor: '#00D03A' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.CARBON_EMISSION')}(${i18T('PROPORTION.MATERIAL')})`]}
            values={[{ text: carbonEmissionMaterial.toFixed(2), unit: 'kg' }]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            showBorder={false}
            borderColor={'#fff'}
            labelBorder={true}
          />
        </Box>
        <Box sx={{ ...rowBoxStyle, width: '49%', backgroundColor: '#00D03A' }}>
          <RowContent
            labels={[`${i18T('PROPORTION.CARBON_EMISSION')}(${i18T('PROPORTION.FULL_LIFE_CYCLE')})`]}
            values={[{ text: carbonEmissionLifeCycle.toFixed(2), unit: 'kg' }]}
            labelsx={rowLabelsx}
            valuesx={rowValuesx}
            showBorder={false}
            borderColor={'#fff'}
            labelBorder={true}
          />
        </Box>
      </Stack>
    ),
    [i18T, carbonEmissionMaterial, carbonEmissionLifeCycle],
  );

  const navigateBack = () => {
    navigate('/proportionManage/proportionList');
  };

  const navigateToProportionParameterTable = () => {
    setCreatePropportionSelect({
      areaId: selectArea,
      factoryId: selectFactory,
    });
    setCreatePropportionForm({
      dsgnStr: checkIsNaN(+formik.values?.dsgnStr),

      cementPct: checkIsNaN(+formik.values?.cementPct),
      slagPct: checkIsNaN(+formik.values?.slagPct),
      flyAshPct: checkIsNaN(+formik.values?.flyAshPct),
      microsilicaPct: checkIsNaN(+formik.values?.microsilicaPct),

      airContent: checkIsNaN(+formik.values?.airContent),
      coarseAggCompVolumeRatio: checkIsNaN(+formik.values?.coarseAggCompVolumeRatio),

      stone1Pct: checkIsNaN(+formik.values?.stone1Pct),
      stone2Pct: checkIsNaN(+formik.values?.stone2Pct),
      stone3Pct: checkIsNaN(+formik.values?.stone3Pct),
      stone4Pct: checkIsNaN(+formik.values?.stone4Pct),

      sand1Pct: checkIsNaN(+formik.values?.sand1Pct),
      sand2Pct: checkIsNaN(+formik.values?.sand2Pct),
      sand3Pct: checkIsNaN(+formik.values?.sand3Pct),
      sand4Pct: checkIsNaN(+formik.values?.sand4Pct),

      propName: formik.values?.propName,
      slump: checkIsNaN(+formik.values?.slump),
      grainSize: checkIsNaN(+formik.values?.grainSize),

      quantityMixingWater: checkIsNaN(+formik.values?.quantityMixingWater),
      waterColloidRatio: checkIsNaN(+formik.values?.waterColloidRatio),
      chemAdmx1UsagePct: checkIsNaN(+formik.values?.chemAdmx1UsagePct),
      chemAdmx2UsagePct: checkIsNaN(+formik.values?.chemAdmx2UsagePct),
      chemAdmx3UsagePct: checkIsNaN(+formik.values?.chemAdmx3UsagePct),
      chemAdmx4UsagePct: checkIsNaN(+formik.values?.chemAdmx4UsagePct),
      chemAdmx5UsagePct: checkIsNaN(+formik.values?.chemAdmx5UsagePct),
      chemAdmx6UsagePct: checkIsNaN(+formik.values?.chemAdmx6UsagePct),
      normalPortlandCementRatio: checkIsNaN(+formik.values?.normalPortlandCementRatio),

      recycledWater: checkIsNaN(+formik.values?.recycledWater),
      selectedMixer: formik.values?.selectedMixer,
    });
    navigate(`/proportionManage/proportionList/proportionParameterTable`, {
      state: {
        proportionMaintenanceId: proportionMaintenance.id,
        mode: 'create',
      },
    });
  };

  return (
    <Box
      sx={{
        p: 2,
        border: '1px solid #6C708C',
        borderRadius: '8px',
        minWidth: '1380px',
        position: 'relative',
        pr: '48px',
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          right: '8px',
          top: '96px',
        }}
      >
        <IconButton
          onClick={navigateToProportionParameterTable}
          sx={{
            color: 'white',
            backgroundColor: '#EBD82E',
            '&:hover': { backgroundColor: '#EBD82E' },
          }}
        >
          <EditIcon />
        </IconButton>
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={2} sx={{ pb: 2, borderBottom: '1px solid #697EA3' }}>
        <LabelInput
          label={`${i18T('PROPORTION.PROP_NAME')}:`}
          valueWidth="159px"
          value={formik.values.propName}
          isError={!!(formik.touched.propName && formik.errors.propName)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('propName', e.target.value)}
        />
        <LabelInputV2
          float={0}
          label={`${i18T('PROPORTION.DESIGN_STR')}(kgf/cm²):`}
          valueWidth="150px"
          value={formik.values.dsgnStr}
          isError={!!(formik.touched.dsgnStr && formik.errors.dsgnStr)}
          onChange={(text: string) => formik.setFieldValue('dsgnStr', text)}
        />
        <LabelInput
          label={`${i18T('PROPORTION.SLUMP')}(cm):`}
          valueWidth="150px"
          value={formik.values.slump}
          isError={!!(formik.touched.slump && formik.errors.slump)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('slump', e.target.value)}
        />
        <LabelInput
          label={`${i18T('PROPORTION.GRAIN_SIZE')}(mm):`}
          valueWidth="150px"
          value={formik.values.grainSize}
          isError={!!(formik.touched.grainSize && formik.errors.grainSize)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('grainSize', e.target.value)}
        />
        <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontSize: '20px', fontWeight: 900, color: '#002F8C', mr: 1 }}>拌合機 :</Typography>
          <YatungSelect
            sx={{ width: '100%' }}
            options={mixerItems}
            value={formik.values.selectedMixer === '1' ? 1 : 2}
            isError={!!(formik.touched.selectedMixer && formik.errors.selectedMixer)}
            onChange={(e) => mixerSelect(e.target.value as number)}
          />
        </Stack>
      </Stack>
      <Stack flexDirection="row">
        <Box sx={{ width: '50%', p: 1 }}>
          <Card sx={{ my: 2, width: '100%', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
            <CardContent>
              <Stack flexDirection="row" justifyContent="space-between">
                <LabelInputV2
                  label={`${i18T('PROPORTION.QUANTITY_MIXER_WATER')}(kg)`}
                  value={formik.values.quantityMixingWater}
                  letterSpacing={8}
                  rootWidth="48%"
                  sx={{ width: '100%' }}
                  isError={!!(formik.touched.quantityMixingWater && formik.errors.quantityMixingWater)}
                  onChange={(text: string) => formik.setFieldValue('quantityMixingWater', text)}
                />
                <LabelInputV2
                  label={`${i18T('PROPORTION.WATER_COLLOID_RATIO')}(W/B)`}
                  value={formik.values.waterColloidRatio}
                  letterSpacing={8}
                  rootWidth="48%"
                  sx={{ width: '100%' }}
                  isError={!!(formik.touched.waterColloidRatio && formik.errors.waterColloidRatio)}
                  onChange={(text: string) => formik.setFieldValue('waterColloidRatio', text)}
                />
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between">
                <LabelInputV2
                  float={2}
                  label={`${i18T('PROPORTION.RECYCLED_WATER_USE_RATIO')}(%)`}
                  value={formik.values.recycledWater}
                  letterSpacing={8}
                  rootWidth="48%"
                  sx={{ width: '100%' }}
                  isError={!!(formik.touched.recycledWater && formik.errors.recycledWater)}
                  onChange={(text: string) => formik.setFieldValue('recycledWater', text)}
                />
                <LabelInputV2
                  label={`${i18T('PROPORTION.TAP_WATER_USE_RATIO')}(%)`}
                  value={100 - Number(formik.values.recycledWater)}
                  letterSpacing={8}
                  rootWidth="48%"
                  sx={{ width: '100%' }}
                  editable={false}
                />
              </Stack>
              {showFirstSection()}
            </CardContent>
          </Card>
          <Card sx={{ my: 2, width: '100', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
            <CardContent>
              <Stack flexDirection="row" justifyContent="space-between">
                <LabelInputV2
                  float={2}
                  label={`${i18T('PROPORTION.AIR_CONTENT')}(%)`}
                  value={formik.values.airContent}
                  letterSpacing={12}
                  rootWidth="48%"
                  sx={{ width: '100%' }}
                  isError={!!(formik.touched.airContent && formik.errors.airContent)}
                  onChange={(text: string) => formik.setFieldValue('airContent', text)}
                />
                <LabelInputV2
                  label={`${i18T('PROPORTION.COARSE_AGG_COMP_VOLUME_RATIO')}`}
                  value={formik.values.coarseAggCompVolumeRatio}
                  letterSpacing={8}
                  rootWidth="48%"
                  sx={{ width: '100%' }}
                  isError={!!(formik.touched.coarseAggCompVolumeRatio && formik.errors.coarseAggCompVolumeRatio)}
                  onChange={(text: string) => formik.setFieldValue('coarseAggCompVolumeRatio', text)}
                />
              </Stack>
              <ValueCheckWrapper
                values={[
                  Number(formik.values.stone1Pct),
                  Number(formik.values.stone2Pct),
                  Number(formik.values.stone3Pct),
                  Number(formik.values.stone4Pct),
                ]}
              >
                <Stack flexDirection="row" justifyContent="space-between">
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.STONE1')}(%)`}
                    value={formik.values.stone1Pct}
                    {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.stone1Pct && formik.errors.stone1Pct)}
                    onChange={(text: string) => formik.setFieldValue('stone1Pct', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.STONE2')}(%)`}
                    value={formik.values.stone2Pct}
                    {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.stone2Pct && formik.errors.stone2Pct)}
                    onChange={(text: string) => formik.setFieldValue('stone2Pct', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.STONE3')}(%)`}
                    value={formik.values.stone3Pct}
                    {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.stone3Pct && formik.errors.stone3Pct)}
                    onChange={(text: string) => formik.setFieldValue('stone3Pct', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.STONE4')}(%)`}
                    value={formik.values.stone4Pct}
                    {...(totalStonePercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.stone4Pct && formik.errors.stone4Pct)}
                    onChange={(text: string) => formik.setFieldValue('stone4Pct', text)}
                  />
                </Stack>
              </ValueCheckWrapper>
              <ValueCheckWrapper
                values={[
                  Number(formik.values.sand1Pct),
                  Number(formik.values.sand2Pct),
                  Number(formik.values.sand3Pct),
                  Number(formik.values.sand4Pct),
                ]}
              >
                <Stack flexDirection="row" justifyContent="space-between">
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.SAND1')}(%)`}
                    value={formik.values.sand1Pct}
                    {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.sand1Pct && formik.errors.sand1Pct)}
                    onChange={(text: string) => formik.setFieldValue('sand1Pct', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.SAND2')}(%)`}
                    value={formik.values.sand2Pct}
                    {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.sand2Pct && formik.errors.sand2Pct)}
                    onChange={(text: string) => formik.setFieldValue('sand2Pct', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.SAND3')}(%)`}
                    value={formik.values.sand3Pct}
                    {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.sand3Pct && formik.errors.sand3Pct)}
                    onChange={(text: string) => formik.setFieldValue('sand3Pct', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.SAND4')}(%)`}
                    value={formik.values.sand4Pct}
                    {...(totalSandPercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.sand4Pct && formik.errors.sand4Pct)}
                    onChange={(text: string) => formik.setFieldValue('sand4Pct', text)}
                  />
                </Stack>
              </ValueCheckWrapper>
              {showSecondSection()}
            </CardContent>
          </Card>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end" sx={{ width: '50%', p: 1 }}>
          <Card sx={{ my: 2, width: '100%', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);' }}>
            <CardContent>
              <ValueCheckWrapper
                values={[
                  Number(formik.values.cementPct),
                  Number(formik.values.slagPct),
                  Number(formik.values.flyAshPct),
                  Number(formik.values.microsilicaPct),
                ]}
              >
                <Stack flexDirection="row" justifyContent="space-between">
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.CEMENT')}(%)`}
                    value={formik.values.cementPct}
                    {...(totalBlinderPercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.cementPct && formik.errors.cementPct)}
                    onChange={(text: string) => formik.setFieldValue('cementPct', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.SLAG')}(%)`}
                    value={formik.values.slagPct}
                    {...(totalBlinderPercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.slagPct && formik.errors.slagPct)}
                    onChange={(text: string) => formik.setFieldValue('slagPct', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.FLY_ASH')}(%)`}
                    value={formik.values.flyAshPct}
                    {...(totalBlinderPercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.flyAshPct && formik.errors.flyAshPct)}
                    onChange={(text: string) => formik.setFieldValue('flyAshPct', text)}
                  />
                  <LabelInputV2
                    float={2}
                    label={`${i18T('PROPORTION.MICROSILICA')}(%)`}
                    value={formik.values.microsilicaPct}
                    {...(totalBlinderPercentage ? { labelColor: 'white' } : {})}
                    isError={!!(formik.touched.microsilicaPct && formik.errors.microsilicaPct)}
                    onChange={(text: string) => formik.setFieldValue('microsilicaPct', text)}
                  />
                </Stack>
              </ValueCheckWrapper>
              {showThirdSection()}
            </CardContent>
          </Card>
          <Card sx={{ my: 2, width: '100%', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
            <CardContent>
              <Stack flexDirection="row" justifyContent="space-between">
                <LabelInputV2
                  float={2}
                  label={`${i18T('PROPORTION.NORMAL_PORTLAND_CEMENT_RATIO')}(%)`}
                  value={formik.values.normalPortlandCementRatio}
                  letterSpacing={12}
                  rootWidth="48%"
                  sx={{ width: '100%' }}
                  isError={!!(formik.touched.normalPortlandCementRatio && formik.errors.normalPortlandCementRatio)}
                  onChange={(text: string) => formik.setFieldValue('normalPortlandCementRatio', text)}
                />
                <LabelInputV2
                  label={`${i18T('PROPORTION.SECOND_PORTLAND_CEMENT_RATIO')}(%)`}
                  value={100 - Number(formik.values.normalPortlandCementRatio)}
                  letterSpacing={12}
                  rootWidth="48%"
                  sx={{ width: '100%' }}
                  editable={false}
                />
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between">
                <Box sx={{ ...rowBoxStyle, width: '48%' }}>
                  <RowContent
                    labels={[`${i18T('PROPORTION.NORMAL_PORTLAND_CEMENT_WEIGHT')}(kg)`]}
                    values={[
                      getInteger((cementWt * (Number(formik.values.normalPortlandCementRatio) / 100))?.toFixed(2)),
                    ]}
                    showBorder={false}
                    labelsx={rowLabelsx}
                    valuesx={rowValuesx}
                  />
                </Box>
                <Box sx={{ ...rowBoxStyle, width: '48%' }}>
                  <RowContent
                    labels={[`${i18T('PROPORTION.SECOND_PORTLAND_CEMENT_WEIGHT')}(kg)`]}
                    values={[
                      getInteger((cementWt * (1 - Number(formik.values.normalPortlandCementRatio) / 100))?.toFixed(2)),
                    ]}
                    showBorder={false}
                    labelsx={rowLabelsx}
                    valuesx={rowValuesx}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
          {proportionMaintenance.chemAdmx1Name && (
            <Card sx={{ my: 2, width: '100%', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2);' }}>
              <CardContent>
                <Stack flexDirection="row">
                  <LabelInputV2
                    label={
                      <>
                        {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 1 })}(%)`}
                        <br />
                        {`${proportionMaintenance.chemAdmx1Name}`}
                      </>
                    }
                    letterSpacing={2}
                    value={formik.values.chemAdmx1UsagePct}
                    rootWidth="32%"
                    sx={{ width: '100%' }}
                    isError={!!(formik.touched.chemAdmx1UsagePct && formik.errors.chemAdmx1UsagePct)}
                    onChange={(text: string) => formik.setFieldValue('chemAdmx1UsagePct', text)}
                  />
                  {proportionMaintenance.chemAdmx2Name && (
                    <LabelInputV2
                      label={
                        <>
                          {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 2 })}(%)`}
                          <br />
                          {`${proportionMaintenance.chemAdmx2Name}`}
                        </>
                      }
                      letterSpacing={2}
                      value={formik.values.chemAdmx2UsagePct}
                      rootWidth="32%"
                      sx={{ width: '100%' }}
                      isError={!!(formik.touched.chemAdmx2UsagePct && formik.errors.chemAdmx2UsagePct)}
                      onChange={(text: string) => formik.setFieldValue('chemAdmx2UsagePct', text)}
                    />
                  )}
                  {proportionMaintenance.chemAdmx3Name && (
                    <LabelInputV2
                      label={
                        <>
                          {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 3 })}(%)`}
                          <br />
                          {`${proportionMaintenance.chemAdmx3Name}`}
                        </>
                      }
                      letterSpacing={2}
                      value={formik.values.chemAdmx3UsagePct}
                      rootWidth="32%"
                      sx={{ width: '100%' }}
                      isError={!!(formik.touched.chemAdmx3UsagePct && formik.errors.chemAdmx3UsagePct)}
                      onChange={(text: string) => formik.setFieldValue('chemAdmx3UsagePct', text)}
                    />
                  )}
                </Stack>
                {proportionMaintenance.chemAdmx4Name && (
                  <Stack flexDirection="row">
                    <LabelInputV2
                      label={
                        <>
                          {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 4 })}(%)`}
                          <br />
                          {`${proportionMaintenance.chemAdmx4Name}`}
                        </>
                      }
                      letterSpacing={2}
                      value={formik.values.chemAdmx4UsagePct}
                      rootWidth="32%"
                      sx={{ width: '100%' }}
                      isError={!!(formik.touched.chemAdmx4UsagePct && formik.errors.chemAdmx4UsagePct)}
                      onChange={(text: string) => formik.setFieldValue('chemAdmx4UsagePct', text)}
                    />

                    {proportionMaintenance.chemAdmx5Name && (
                      <LabelInputV2
                        label={
                          <>
                            {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 5 })}(%)`}
                            <br />
                            {`${proportionMaintenance.chemAdmx5Name}`}
                          </>
                        }
                        letterSpacing={2}
                        value={formik.values.chemAdmx5UsagePct}
                        rootWidth="32%"
                        sx={{ width: '100%' }}
                        isError={!!(formik.touched.chemAdmx5UsagePct && formik.errors.chemAdmx5UsagePct)}
                        onChange={(text: string) => formik.setFieldValue('chemAdmx5UsagePct', text)}
                      />
                    )}
                    {proportionMaintenance.chemAdmx6Name && (
                      <LabelInputV2
                        label={
                          <>
                            {`${i18T('PROPORTION.CHEM_ADMX_ADDITION', { number: 6 })}(%)`}
                            <br />
                            {`${proportionMaintenance.chemAdmx6Name}`}
                          </>
                        }
                        letterSpacing={2}
                        value={formik.values.chemAdmx6UsagePct}
                        rootWidth="32%"
                        sx={{ width: '100%' }}
                        isError={!!(formik.touched.chemAdmx6UsagePct && formik.errors.chemAdmx6UsagePct)}
                        onChange={(text: string) => formik.setFieldValue('chemAdmx6UsagePct', text)}
                      />
                    )}
                  </Stack>
                )}
                {showFourthSection()}
              </CardContent>
            </Card>
          )}
          <Card sx={{ my: 2, width: '100%', boxShadow: ' 4px 4px 20px rgba(0, 0, 0, 0.2)' }}>
            <CardContent>
              {showResultSection()}
              {showCarbonEmissionSection()}
            </CardContent>
          </Card>
          <Stack flexDirection="row" alignItems="center">
            <YatungButton sx={{ mr: 1 }} color="red" onClick={navigateBack} startIcon={<CancelIcon />}>
              {i18T('GLOBAL.CANCEL')}
            </YatungButton>
            <YatungButton color="blue" onClick={formik.handleSubmit} startIcon={<ConfirmIcon />}>
              {i18T('GLOBAL.FINISH')}
            </YatungButton>
          </Stack>
        </Box>
      </Stack>
      <YatungConfirmAlert
        isShowAlert={isShowAlert}
        alertText={alertText}
        onOk={() => postNewProportion(postRequest as PostProportionRequest)}
        onClose={() => setIsShowAlert(false)}
      />
    </Box>
  );
}

export default memo(CreateProportionForm);
