import { Box, Stack } from '@mui/material';
import React from 'react';
import Content from './Content';
import Header from './Header';

interface Props {
  children: React.ReactNode;
}

export default function Right({ children }: Props) {
  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.default', p: 0, height: '100vh' }}>
      <Stack sx={{ height: '100%' }}>
        <Header />
        <Content>{children}</Content>
      </Stack>
    </Box>
  );
}
