import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ProportionMaintenanceResponse } from '@Src/_basic/object/ProportionType';
import { ProportionApi } from '@Src/_basic/protocol/proportion/ProportionApi';

import YatungPage from '@Src/_basic/components/YatungPage';
import YatungLoading from '@Src/_basic/components/YatungLoading';
import ProportionParameterForm from '../../components/ProportionParameterForm';


export default function ProportionParameterTable() {
  const { t: i18T } = useTranslation();
  const { state: { proportionMaintenanceId, mode, editProportion, proportion } } = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [defaultProportionMaintenance, setDefaultProportionMaintenance] = useState<ProportionMaintenanceResponse>();

  useEffect(() => {
    console.log(proportionMaintenanceId)
    if (proportionMaintenanceId) {
      setLoading(true)

      ProportionApi.getProportionMaintenance(
        { id: proportionMaintenanceId },
        (_data) => setDefaultProportionMaintenance(_data),
        () => setDefaultProportionMaintenance(undefined),
        () => setLoading(false)
      );
      
    }
  }, [proportionMaintenanceId]);

  return (
    <YatungPage
      title={i18T('PROPORTION_MANAGEMENT.PROPORTION_PARAMETER_TABLE.TITLE')}
      body={
        loading 
          ? <YatungLoading overlay={true} />
          : defaultProportionMaintenance && 
            <ProportionParameterForm defaultProportionMaintenance={defaultProportionMaintenance} mode={mode} editProportion={editProportion} proportion={proportion}/>
      }
      contentBgColor="#FFF"
    />
  );
}
