import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungPage from '@Src/_basic/components/YatungPage';
import BackButton from '@Src/_basic/icons/BackButton';
import { TestInDistrictFactory } from '@Src/_basic/object/TestInDistrictType';
import { ExptTestInDistrictApi } from '@Src/_basic/protocol/exptTestInDistrict/ExptTestInDistrictApi';
import { useAuth } from '@Src/redux/auth/authActions';
import { useTestDistrict } from '@Src/redux/testInDistrict/testInDistrictActions';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import TestInDistrictByFactoryCard from '../components/TestInDistrictByFactoryCard';

export default function TestInDistrictByFactoryScreen() {
  const { t: i18T } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    state: { areaName },
  } = useLocation();

  const { userAreaOptionsData, userGroupsData } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const { setDate, date } = useTestDistrict();

  const [testInDistrictFactoryData, setTestInDistrictFactoryData] = useState<Array<TestInDistrictFactory>>([]);
  const handleDateChange = (value: unknown) => {
    setDate(value);
  };
  const handleGoBack = () => {
    navigate('/realTimeMonitoring/testInDistrict/testsDistrictByArea');
  };
  useEffect(() => {
    setLoading(true);
    ExptTestInDistrictApi.getExptTestInDistrictByFactory(
      { currTime: date, factoryIds: userGroupsData?.factories.map((item) => item.value) },
      (data: Array<TestInDistrictFactory>) => {
        getIncludeFactory(data);
      },
      undefined,
      () => {
        setLoading(false);
      },
    );
  }, [date, id]);
  const getIncludeFactory = (data: Array<TestInDistrictFactory>) => {
    const factoryData = userAreaOptionsData.filter((item: any) => item.text === areaName);
    const findFactoryData = factoryData.map((item: any) => item.factories);
    const factoryIds: Array<number> = [];
    findFactoryData.forEach((item: any) => {
      item.forEach((item: any) => {
        factoryIds.push(item.value);
      });
    });
    const newData = data.filter((item) => factoryIds.includes(item.factoryId));
    setTestInDistrictFactoryData(newData);
    setLoading(false);
  };
  return (
    <YatungPage
      title={i18T('APPLICATIONSMANAGEMENT.TESTS_IN_EACH_DISTRICT.TITLE') + `(${areaName})`}
      body={
        <>
          <Stack direction="row" spacing={2} sx={{ py: 1 }} justifyContent="space-between">
            <YatungDateSelect
              disabled={loading}
              value={date}
              disableFuture={true}
              onChange={(val) => val && handleDateChange(new Date(val).getTime())}
              // maxDate={date.endTime}
            />
            <YatungButton
              disabled={loading}
              text={i18T('GLOBAL.BACK_PAGE')}
              color="green"
              onClick={handleGoBack}
              startIcon={<BackButton sx={{ width: '25px', height: '25px' }} />}
              sx={{ alignSelf: 'center' }}
            />
          </Stack>

          <TestInDistrictByFactoryCard testInDistrictFactoryData={testInDistrictFactoryData} loading={loading} />
        </>
      }
      contentBgColor="#fff"
    />
  );
}
