import MockAdapter from 'axios-mock-adapter/types';
import { fstat } from 'fs';
import { StationProtocol } from '../StationProtocol';
import siteAreaTableMock from './siteAreaTableMock';
import siteFactoryTableMock from './siteFactoryTableMock';
import sitesTableMock from './sitesTableMock';
import siteManagementTableMock from './siteManagementTableMock';

export default function siteMockDetails(mock: MockAdapter) {
  mock.onGet(StationProtocol.SITE_MANAGEMENT_DATA).reply(() => {
    return [
      200,
      {
        code: '00000',
        data: siteManagementTableMock,
      },
    ];
  });

  // mock.onPost(StationProtocol.SITE_MANAGEMENT_CREATE_DATA).reply(({ data }) => {
  //   const { area, factory, settings } = JSON.parse(data);
  //   const factoryFind = siteFactoryTableMock.find((val: any) => val.value === factory);
  //   const factoryFilterToObject = Object.assign({}, factoryFind);
  //   const areaFilter = siteAreaTableMock.find((val: any) => val.value === area);
  //   const areaFilterToObject = Object.assign({}, areaFilter);
  //   const mappedSetting: Array<any> = [];
  //   settings.forEach((setting: any) => {
  //     mappedSetting.push({
  //       ...setting,
  //       sites: sitesTableMock.find((val: any) => val.value === setting.sites),
  //     });
  //   });

  //   console.log(mappedSetting);

  //   if (area && factory && settings) {
  //     const management = {
  //       id: generateUserId(),
  //       area: areaFilterToObject,
  //       factory: factoryFilterToObject,
  //       settings: mappedSetting,
  //     };
  //     console.log(management);
  //     siteManagementTableMock.push(management);
  //     return [200, { ...management }];
  //   }
  //   return [400];
  // });

  mock.onPost(StationProtocol.SITE_MANAGEMENT_SEARCH_DATA).reply(({ data }) => {
    const { area, inputValues } = JSON.parse(data);
    if (inputValues && area === 0) {
      const fillInInputAndAreaIsAll = siteManagementTableMock.filter(
        (val: any) =>
          val.area.text.includes(inputValues) ||
          val.factory.text.includes(inputValues) ||
          val.settings.some((settings: any) => settings.sites.text.includes(inputValues)),
      );
      return [200, [...fillInInputAndAreaIsAll]];
    } else if (inputValues && area !== 0) {
      const fillInInputAndAreaNotIsAll = siteManagementTableMock.filter(
        (val: any) =>
          val.area.value === area &&
          (val.area.text.includes(inputValues) ||
            val.factory.text.includes(inputValues) ||
            val.settings.some((settings: any) => settings.sites.text.includes(inputValues))),
      );
      return [200, [...fillInInputAndAreaNotIsAll]];
    } else if (area === 0) {
      return [200, siteManagementTableMock];
    } else if (area) {
      const onlyFillInAreaData = siteManagementTableMock.filter((val: any) => val.area.value === area);
      return [200, [...onlyFillInAreaData]];
    } else {
      return [200, siteManagementTableMock];
    }
    return [400];
  });
  mock.onPut(StationProtocol.SITE_MANAGEMENT_UPDATE_DATA).reply(({ data }) => {
    const dataToJson = JSON.parse(data);
    console.log(dataToJson);
    const factoryFind = siteFactoryTableMock.find((val: any) => val.value === dataToJson.factory);
    const factoryFilterToObject = Object.assign({}, factoryFind);
    const areaFilter = siteAreaTableMock.find((val: any) => val.value === dataToJson.area);
    const areaFilterToObject = Object.assign({}, areaFilter);
    const mappedSetting: Array<any> = [];
    dataToJson.settings.forEach((setting: any) => {
      mappedSetting.push({
        ...setting,
        sites: sitesTableMock.find((val: any) => val.value === setting.sites),
      });
    });
    const index = siteManagementTableMock.findIndex((el) => el.id === dataToJson.id);
    if (index !== -1) {
      siteManagementTableMock[index] = {
        ...dataToJson,
        area: areaFilterToObject,
        factory: factoryFilterToObject,
        settings: mappedSetting,
      };
      return [
        200,
        {
          ...dataToJson,
          area: areaFilterToObject,
          factory: factoryFilterToObject,
          settings: mappedSetting,
        },
      ];
    }
    console.log('error');
    return [400];
  });
  mock.onGet(StationProtocol.SITE_MANAGEMENT_GET_ID).reply((config) => {
    // const datas = siteManagementTableMock.filter((el) => el.factory === config.params.id);
    // if (datas) {
    //   return [200, { code: '00000', data: datas }];
    // }
    return [400];
  });
  function generateUserId() {
    const ids = siteManagementTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}
