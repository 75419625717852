import { useAuth } from '@Redux/auth/authActions';
import useI18n from '@Src/_basic/i18n/i18n';
import ErrorBoundary from '@Src/app/ErrorBoundary';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import BasicRouter from './BasicRouter';

function App() {
  const { token, setToken } = useAuth();
  const { setSaveRequest } = useRequestSaving();
  const { isInitialized } = useI18n();

  useEffect(() => {
    // AuthApi.login({ userName: 'app_user001', password: '123456789' }, () => {
    //   AuthApi.getJWT((data: JWTData) => {
    //     setToken(data.token);
    //   });
    // });
    setToken("eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJuYW1lIjoi5ZOB566h5Lq65ZOhIiwiaWQiOiI1YmZmNjVmMC1mODcxLTQwMWEtODFiNS1kMTVlYjMyYTg2OTciLCJleHAiOjI1Nzc3NjY2OTYsImFjY291bnQiOiJhcHBfdXNlcjAwMSIsImp0aSI6ImI0YmYzZGY3LTVjNWUtNDgxYi1iYzRlLTUwMWY2ZjgxYzA4MiJ9.7VXONccJkEIWI_1ZSXRTWKVObPH5ijMMuulOSSyE6cHt_MSJ1ruEJ8fr2EjgeZzkqzlqlq9T9-VPhbqYD6SXWw");
  }, []);

  useEffect(() => {
    if (!token) setSaveRequest(undefined);
  }, [token]);

  return (
    <BrowserRouter>
      <ErrorBoundary>{isInitialized ? !token ? <></> : <BasicRouter /> : <></>} </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
