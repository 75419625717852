import YatungButton from '@Src/_basic/components/YatungButton';
import YatungFormInput from '@Src/_basic/components/YatungFormInput';
import YatungFormLabel from '@Src/_basic/components/YatungFormLabel';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import PersonIcon from '@Src/_basic/icons/PersonIcon';
import { EntityData, UpdateEntityRequest } from '@Src/_basic/object/EntityType';
import { Stack } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  values: UpdateEntityRequest;
  setFieldValue: (name: string, value: string) => void;
  handleClose: () => void;
  entityByIdData: EntityData;
}

export default function EntityUpdateField({ values, setFieldValue, handleClose, entityByIdData }: Props) {
  const { t: i18T } = useTranslation();

  return (
    <>
      <Stack flexDirection={'column'} sx={{ mt: 1 }}>
        <YatungFormLabel label={i18T('ENTITYMANAGEMENT.ENTITY_NUMBER')} sx={{ my: 2 }} />
        <Field
          id="id"
          name="id"
          type="text"
          component={YatungFormInput}
          value={values.id}
          sx={{ width: '100%' }}
          position={'start'}
          icon={<PersonIcon sx={{ width: 30, height: 30 }} />}
          required
          disabled //不讓使用者更改ID
        />

        <YatungFormLabel label={i18T('ENTITYMANAGEMENT.ENTITY')} sx={{ my: 2 }} />
        <Field id="name" name="name" type="text" component={YatungFormInput} value={values.name} required />

        <YatungFormLabel label={i18T('ENTITYMANAGEMENT.FACTORY_TYPE')} sx={{ my: 2 }} />
        <YatungSelect
          disabled={true}
          options={[{ value: entityByIdData.station.factory_id, text: entityByIdData.station.factory_name }]}
          value={entityByIdData.station.factory_id}
          width={'100%'}
        />

        <YatungFormLabel label={i18T('ENTITYMANAGEMENT.SITE_TYPE')} sx={{ my: 2 }} />
        <YatungSelect
          id="stationId"
          disabled={true}
          options={[{ value: entityByIdData.station.id, text: entityByIdData.station.station_name }]}
          value={entityByIdData.station.id}
          width={'100%'}
        />

        <Stack
          spacing={3}
          sx={{ bgcolor: 'white', minWidth: 500, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}
          display="flex"
          flexDirection="column"
        >
          <Stack alignItems="center" justifyContent="center" flexDirection="row">
            <YatungButton
              text={i18T('ENTITYMANAGEMENT.CANCEL')}
              onClick={handleClose}
              sx={{
                color: '#ffffff',
                bgcolor: '#CB333B',
                fontSize: '24px',
                width: 120,
                mr: 1,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#CB333B',
                },
              }}
            />
            <YatungButton
              text={i18T('ENTITYMANAGEMENT.COMPLETE')}
              type="submit"
              sx={{
                border: '1px solid #fff',
                color: '#ffffff',
                bgcolor: '#002F8C',
                fontSize: '24px',
                width: 120,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#002F8C',
                },
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
