import YatungButton from '@Src/_basic/components/YatungButton';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import AddCircle from '@Src/_basic/icons/AddCircle';
import DeleteIcon from '@Src/_basic/icons/Delete';
import EditIcon from '@Src/_basic/icons/Edit';
import { ReportGroupsData } from '@Src/_basic/object/AccountRightType';
import { ReportGroupApi } from '@Src/_basic/protocol/accountRights/reportGroup/ReportGroupApi';
import { useApi } from '@Src/redux/api/apiAction';
import { Cancel } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface Props {
  groupData: Array<ReportGroupsData>;
  getReportGroup: () => void;
}

export default function ReportGroupManagementTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { groupData, getReportGroup } = props;
  const navigate = useNavigate();

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const [deleteId, setDeleteId] = useState<number>(0);
  const [newGroupData, setNewGroupData] = useState<Array<ReportGroupsData>>([]);

  const handleGoToUpdatePage = (row: ReportGroupsData) => {
    navigate(`/UsersManagePage/ReportGroupManagement/ReportGroupManagementUpdate/${row.id}`, { state: { row } });
  };

  const deleteReportGroup = (id: number) => {
    ReportGroupApi.deleteReportGroup(
      id,
      () => {
        setAlertText(i18T('GLOBAL.SUCCESS', { text: i18T('GLOBAL.DELETE') }));
        setIsShowAlert(true);
      },
      (error) => {
        setAlertText(error.message);
        setIsShowAlert(true);
      },
    );
  };

  useEffect(() => {
    setNewGroupData(groupData.map((item) => ({ ...item, allList: false })));
  }, [groupData]);

  const columns: Array<Column> = [
    {
      text: i18T('USERSMANAGEMENU.REPORT_GROUP_MANAGEMENT.FACTORY_INFO'),
      dataField: 'factoryName',
    },
    {
      text: i18T('USERSMANAGEMENU.REPORT_GROUP_MANAGEMENT.GROUP_NAME'),
      dataField: 'groupName',
    },
    {
      text: i18T('USERSMANAGEMENU.REPORT_GROUP_MANAGEMENT.CREATE_TIME'),
      dataField: 'createTime',
      formatter: ({ row }) => {
        return <Typography>{moment(row.createTime).format('YYYY/MM/DD')}</Typography>;
      },
    },
    {
      text: i18T('USERSMANAGEMENU.REPORT_GROUP_MANAGEMENT.MEMBER_LIST'),
      dataField: 'memberList',
      width: '700px',
      formatter: ({ row }) => {
        const list = row.memberList?.split(';');
        if (list) {
          return (
            <>
              {list.length <= 5 ? (
                list.splice(0, list.length - 1).map((item: string, index: number) => (
                  <span key={index} style={{ letterSpacing: 2 }}>
                    {item};
                  </span>
                ))
              ) : row.allList ? (
                <>
                  {list.splice(0, list.length - 1).map((item: string, index: number) => (
                    <span key={index} style={{ letterSpacing: 2 }}>
                      {item};
                    </span>
                  ))}
                  <Cancel
                    onClick={() =>
                      setNewGroupData((prev: Array<ReportGroupsData>) =>
                        prev.map((item: ReportGroupsData) => (item.id === row.id ? { ...item, allList: false } : item)),
                      )
                    }
                    sx={{ ':hover': { cursor: 'pointer' }, paddingTop: 1 }}
                  />
                </>
              ) : (
                <Stack display="flex" flexDirection="row" alignItems="center">
                  {list.splice(0, 5).map((item: string, index: number) => (
                    <span key={index} style={{ letterSpacing: 2 }}>
                      {item};
                    </span>
                  ))}
                  <span style={{ fontSize: 26, paddingBottom: 5 }}>...</span>
                  <AddCircle
                    onClick={() =>
                      setNewGroupData((prev: Array<ReportGroupsData>) =>
                        prev.map((item: ReportGroupsData) => (item.id === row.id ? { ...item, allList: true } : item)),
                      )
                    }
                    sx={{ ':hover': { cursor: 'pointer' }, fontSize: 20 }}
                  />
                </Stack>
              )}
            </>
          );
        } else return <></>;
      },
    },
    {
      text: i18T('GLOBAL.ACTION'),
      dataField: 'action',
      width: '250px',
      formatter: ({ row }) => {
        return (
          <Stack display="flex" flexDirection="row" justifyContent="space-between">
            <YatungButton
              text={i18T('GLOBAL.UPDATE')}
              startIcon={<EditIcon />}
              color="yellow"
              onClick={() => {
                handleGoToUpdatePage(row);
              }}
            />
            <YatungButton
              text={i18T('GLOBAL.DELETE')}
              startIcon={<DeleteIcon />}
              color="light_red"
              onClick={() => {
                setDeleteId(row.id);
                setIsShowAlert(true);
              }}
            />
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <YatungTable loading={actionLoading} columns={columns} data={newGroupData} />
      {alertText ? (
        <YatungConfirmAlert
          isShowAlert={isShowAlert}
          alertText={alertText}
          onClose={() => {
            setIsShowAlert(false);
            setTimeout(() => setAlertText(''), 300);
            getReportGroup();
          }}
        />
      ) : (
        <YatungConfirmAlert
          isShowAlert={isShowAlert}
          onClose={() => setIsShowAlert(false)}
          onOk={() => deleteReportGroup(deleteId)}
        />
      )}
    </>
  );
}
