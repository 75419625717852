import { motion } from 'framer-motion';
import React, { memo, useMemo } from 'react';

interface Props {
  isVisible: boolean;
  children: JSX.Element;
  y?: number;
}

const YatungFadeInOut = ({ isVisible, children, y = 30 }: Props) => {
  const animation = useMemo(
    () => ({
      initial: { opacity: 0, y },
      animate: { opacity: isVisible ? 1 : 0, y: isVisible ? 0 : y },
      transition: {
        duration: isVisible ? 0.5 : 0.5,
      },
    }),
    [isVisible, y],
  );

  return <motion.div {...animation}>{children}</motion.div>;
};

export default memo(YatungFadeInOut);
