import { Box } from '@mui/material';
import React from 'react';

export default function HomePage() {
  return (
    <Box
      sx={{
        backgroundSize: 'cover',
        height: '100%',
        backgroundColor: '#F2F2F2',
      }}
    ></Box>
  );
}
