import axios from 'axios';
import { Response } from '@Src/_basic/object/ApiType';
import { ExperimentDetectionItemProtocol } from './ExperimentDetectionItemProtocol';
import { SearchExperimentItemsParams } from '@Src/_basic/object/ExperimentItemType';
import {
  ExperimentDetectionItemData,
  SearchExperimentDetectionItemsParams,
} from '@Src/_basic/object/ExperimentDetectionItemType';
import { Api } from '../Api';

export class ExperimentDetectionItemApi {
  public static getExperimentDetectionItemsBySearch(
    params: SearchExperimentDetectionItemsParams,
    onSuccess?: (data: Array<ExperimentDetectionItemData>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params,
    };

    Api.get(
      ExperimentDetectionItemProtocol.ALL_EXPERIMENT_DETECTION_ITEMS_BY_SEARCH,
      config,
      onSuccess,
      onFailed,
      onFinally,
    );
  }
}
