import YatungTable from '@Src/_basic/components/YatungTable';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { NewYardReciptData, YardReciptData } from '@Src/_basic/object/YardReceiptsType';
import { useApi } from '@Src/redux/api/apiAction';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionColumnFormatter from './columnFormatter/ActionColumnFormatter';
import CreateTimeColumnFormatter from './columnFormatter/CreateTimeColumnFormatter';
import IdColumnFormatter from './columnFormatter/IdColumnFormatter';
import PlateNumberColumnFormatter from './columnFormatter/PlateNumberColumnFormatter';
import SourceNameColumnFormatter from './columnFormatter/SourceNameColumnFormatter';
import SpecificationNameColumnFormatter from './columnFormatter/SpecificationNameColumnFormatter';
import SpecificationValueColumnFormatter from './columnFormatter/SpecificationValueColumnFormatter';
import StorageNameColumnFormatter from './columnFormatter/StorageNameColumnFormatter';
import SupplierNameColumnFormatter from './columnFormatter/SupplierNameColumnFormatter';

interface Props {
  rows: Array<YardReciptData>;
  pageInfo: PageInfo;
  onViewDetail: (row: any) => void;
  onDelete: (row: any) => void;
  onEdit: (row: any) => void;
}
export default function StorageYardMaterialReceiptsTable(props: Props) {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();
  const { rows, onViewDetail, onEdit, onDelete, pageInfo } = props;
  const [newStorageYardRecipts, setNewStorageYardRecipts] = useState<NewYardReciptData[]>([]);

  const getButtonText = (status: string) => {
    switch (status) {
      case '詳細':
        return i18T('GLOBAL.DETAIL');
      case '編輯':
        return i18T('GLOBAL.UPDATE');
      case '刪除':
        return i18T('GLOBAL.DELETE');
      default:
        return '';
    }
  };
  const getNewVehicleData = () => {
    const storageYardReciptsTemp = rows?.map((data: any, index: number) => {
      return { ...data, ids: index + 1 + (pageInfo.page - 1) * pageInfo.pageSize };
    });
    setNewStorageYardRecipts(storageYardReciptsTemp);
  };
  useEffect(() => {
    getNewVehicleData();
  }, [rows]);
  return (
    <YatungTable
      isDashedBottom
      loading={actionLoading}
      data={newStorageYardRecipts}
      verticalAlign="top"
      columns={[
        {
          text: '#',
          dataField: 'ids',
          formatter: IdColumnFormatter,
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.CREATETIME'),
          dataField: 'createTime',
          formatter: CreateTimeColumnFormatter,
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.CAR_NO'),
          dataField: 'plateNumber',
          formatter: PlateNumberColumnFormatter,
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.SUPPLIER_NAME'),
          dataField: 'supplier1Name',
          formatter: SupplierNameColumnFormatter,
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.SOURCE'),
          dataField: 'source1Name',
          formatter: SourceNameColumnFormatter,
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.SPECIFICATION'),
          dataField: 'specification1Name',
          formatter: SpecificationNameColumnFormatter,
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.DOUSHU'),
          dataField: 'specification1Value',
          formatter: SpecificationValueColumnFormatter,
        },
        {
          text: i18T('APPLICATIONSMANAGEMENT.TABLE.STORAGE_NAME_WAREHOUSE'),
          dataField: 'storageName',
          formatter: StorageNameColumnFormatter,
        },
        {
          text: i18T('GLOBAL.ACTION'),
          dataField: 'action',
          formatter: ActionColumnFormatter,
          formatExtraData: {
            onViewDetail: onViewDetail,
            onEdit: onEdit,
            onDelete: onDelete,
            getButtonText: getButtonText,
          },
        },
      ]}
    />
  );
}
