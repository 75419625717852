import YatungButton from '@Src/_basic/components/YatungButton';
import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect from '@Src/_basic/components/YatungSelect';
import { TextOptions } from '@Src/_basic/components/YatungTextSelect';
import AddEquipment from '@Src/_basic/icons/AddEquipment';
import SearchIcon from '@Src/_basic/icons/Search';
import {
  EquipmentData,
  FileData,
  GetAllName,
  GetUploadEquipmentReports,
  SearchPaginationParams,
  SearchPaginationUser,
  SearchPaginationUserRequest,
  UpdateEquipmentRequest,
} from '@Src/_basic/object/EquipmentManagementType';
import { EntityApi } from '@Src/_basic/protocol/entity/EntityApi';
import { EquipmentApi } from '@Src/_basic/protocol/equipment/EquipmentApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useAuth } from '@Src/redux/auth/authActions';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { SelectChangeEvent, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import EquipmentCreateCard from '../components/EquipmentCreateCard';
import EquipmentManagementTable from '../components/EquipmentManagementTable';
import EquipmentUpdateCard from '../components/EquipmentUpdateCard';

type QueryType = {
  factoryId: number;
  name: string;
};

export default function EquipmentScreen() {
  const { userGroupsData } = useAuth();
  const { actionLoading } = useApi();
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<QueryType>();

  const initQuery = useMemo<QueryType>(() => {
    const { factoryId = 0, name = '' } = (location.pathname === request?.page && request?.request) || {};

    return {
      factoryId,
      name,
    };
  }, [request, location.pathname]);

  const [{ factoryId, name }, setQuery] = useState<QueryType>(initQuery);

  const params: SearchPaginationParams = useMemo(
    () => ({
      ...(name && { name }),
      factoryId: factoryId,
      removed: false,
    }),
    [name, factoryId],
  );

  const [equipmentByIdData, setEquipmentByIdData] = useState<EquipmentData>();
  const [equipmentData, setEquipmentData] = useState<EquipmentData[]>();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<'CREATE' | 'UPDATE' | 'DELETE' | null>(null);

  const [createFactory, setCreateFactory] = useState<number>(0);
  const [createPrioedNumber, setCreatePrioedNumber] = useState<number | null>(0);
  const [createPrioedUnit, setCreatePrioedUnit] = useState<string | null>('');
  const [createKeeper, setCreateKeeper] = useState<string | null>('');

  const [updateFactory, setUpdateFactory] = useState<number | undefined>(0);
  const [updatePrioedNumber, setUpdatePrioedNumber] = useState<number>(0);
  const [updatePrioedUnit, setUpdtaePrioedUnit] = useState<string>('');
  const [updateKeeper, setUpdateKeeper] = useState<string | undefined>('');
  const [updateNeedMaintenance, setUpdateNeedMaintenance] = useState<boolean | undefined>(undefined);

  const [keeperData, setKeeperData] = useState<SearchPaginationUser[]>([]);
  const [createKeeperOptions, setCreateKeeperOptions] = useState<TextOptions[]>([]);
  const [updateKeeperData, setUpdateKeeperData] = useState<SearchPaginationUser[]>([]);
  const [updateKeeperOptions, setUpdateKeeperOptions] = useState<TextOptions[]>([]);

  const [inputNameValues, setInputNameValues] = useState<string>('');
  const [inputPeriodValues, setInputPeriodValues] = useState<string>('');

  const [fileName, setFileName] = useState<FileData[]>([]);

  const initialUpdtaeEquipValue: UpdateEquipmentRequest = {
    id: equipmentByIdData && equipmentByIdData.id,
    name: equipmentByIdData && equipmentByIdData.name,
    propertyCode: equipmentByIdData && equipmentByIdData.propertyCode,
    notificationTime: 0,
    periodNumber: updatePrioedNumber,
    periodUnit: updatePrioedUnit,
    keeper: equipmentByIdData && equipmentByIdData.keeper,
    factoryId: updateFactory,
    needMaintenance: updateNeedMaintenance,
  };

  const getSearchKeeperData = () => {
    //獲取帳號人員作為儀器負責人
    const params: GetAllName = {
      //當新增廠改變，以此factoryId去做全人員搜尋
      pageNumber: 0,
      pageSize: 99999,
      sourceKides: 0,
      factoryId: createFactory,
    };
    EntityApi.GetKeeper({ ...params }, (datas: SearchPaginationUserRequest) => {
      setKeeperData(datas.data);
    });
  };

  const getSearchUploadData = (id?: number) => {
    const params: GetUploadEquipmentReports = {
      //當選擇設備改變，搜尋該設備所擁有的所有檔案
      itemId: id ? id : equipmentByIdData && equipmentByIdData.id,
    };
    EquipmentApi.getUploadEquipmentReports({ ...params }, (datas: FileData[]) => {
      setFileName(datas);
    });
  };

  const getUpdateSearchKeeperData = () => {
    const params: GetAllName = {
      //當新增廠改變，以此factoryId去做全人員搜尋，並把datas.data值設給setUpdateKeeperOptions
      pageNumber: 0,
      pageSize: 99999,
      sourceKides: 0,
      factoryId: updateFactory ? updateFactory : equipmentByIdData ? equipmentByIdData.factoryId : 0,
    };
    EntityApi.GetKeeper({ ...params }, (datas: SearchPaginationUserRequest) => {
      setUpdateKeeperData(datas.data);
    });
  };

  const onGetKeeperOptionsSuccess = () => {
    setCreateKeeperOptions(
      keeperData?.map(({ accountName, account }: SearchPaginationUser) => ({ value: account, text: accountName })),
    ); //選擇不同廠時，顯示不同廠之負責人員
  };

  const onGetUpdateOptionsSuccess = () => {
    setUpdateKeeperOptions(
      updateKeeperData?.map(({ accountName, account }: SearchPaginationUser) => ({
        value: account,
        text: accountName,
      })),
    ); //選擇不同廠時，顯示不同廠之負責人員
  };

  const reset = useCallback(() => {
    setIsVisible(false);
    setEquipmentData([]);
  }, [setEquipmentData]);

  const handleModelOpen = useCallback((modal: 'CREATE' | 'UPDATE' | 'DELETE') => {
    //點擊新增、修改、刪除時，開啟對應的modal
    setModalOpen(modal);
  }, []);

  const handleModelClose = useCallback(() => {
    setModalOpen(null);
  }, []);

  const getEquipmentData = useCallback(() => {
    //點擊搜尋時，獲取設備資料
    EquipmentApi.geAllEquipment(params, (_data) => {
      setIsVisible(true);
      setSaveRequest({ page: location.pathname, request: { factoryId, name } });
      setEquipmentData(_data.map((item, index) => ({ ...item, number: index + 1 })));
    });
  }, [name, factoryId, location.pathname, setSaveRequest, params]);

  const handleUpdateAndDelete = useCallback(
    (id: number, modal: 'UPDATE' | 'DELETE') => {
      setModalOpen(modal);
      const selectedById = equipmentData?.find((i: EquipmentData) => i.id === id);
      if (selectedById) setEquipmentByIdData(selectedById);
      getSearchUploadData(id); //透過點擊的id獲取已存在檔案
    },
    [equipmentData],
  );

  const handleDelete = useCallback(() => {
    EquipmentApi.deleteEquipment(equipmentByIdData && equipmentByIdData.id, () => {
      getEquipmentData();
      setModalOpen(null);
    });
  }, [equipmentByIdData, getEquipmentData]);

  const handleChange = (field: keyof QueryType) => (value: QueryType[keyof QueryType]) => {
    setQuery((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    reset();
  }, [factoryId, name]);

  useEffect(() => {
    if (request?.page === location.pathname && request?.request && request.request.factoryId) {
      getEquipmentData();
    }
  }, []);

  useEffect(() => {
    getSearchKeeperData();
  }, [createFactory]);

  useEffect(() => {
    onGetKeeperOptionsSuccess();
  }, [keeperData]);

  useEffect(() => {
    onGetUpdateOptionsSuccess();
  }, [updateKeeperData]);

  useEffect(() => {
    getUpdateSearchKeeperData();
  }, [equipmentByIdData, updateFactory]);

  return (
    <>
      <YatungPage
        title={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.PAGE_TITLE')}
        body={
          <>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} sx={{ py: 1 }} alignItems="center">
                {userGroupsData && (
                  <YatungSelect
                    disabled={actionLoading}
                    options={userGroupsData?.factories} //根據使用者權限顯示廠區
                    value={factoryId}
                    onChange={(e: SelectChangeEvent<unknown>) => handleChange('factoryId')(e.target.value as number)}
                  />
                )}
                <YatungInput value={name} onChange={(e: any) => handleChange('name')(e.target.value)} />
                <YatungButton
                  disabled={!factoryId || actionLoading}
                  text={i18T('GLOBAL.SEARCh_BUTTON')}
                  startIcon={<SearchIcon />}
                  onClick={getEquipmentData}
                  color="blue"
                />
              </Stack>
              <YatungButton
                disabled={!factoryId || actionLoading}
                text={i18T('APPLICATIONSMANAGEMENT.EQUIPMENTMANAGEMENT.CREATE_EQUIPMENT_BUTTON')}
                startIcon={<AddEquipment sx={{ width: 20, height: 30, alignSelf: 'center' }} />}
                onClick={() => handleModelOpen('CREATE')}
                color={'green'}
              />
            </Stack>
            <YatungFadeInOut isVisible={isVisible}>
              <EquipmentManagementTable equipmentData={equipmentData} handleUpdateAndDelete={handleUpdateAndDelete} />
            </YatungFadeInOut>
          </>
        }
        contentBgColor="#FFF"
      />
      <EquipmentCreateCard
        createModalOpen={modalOpen === 'CREATE'}
        onCreateGoBackButtonClick={handleModelClose}
        getEquipmentData={getEquipmentData}
        userGroupsData={userGroupsData}
        entityOptions={createKeeperOptions}
        createFactory={createFactory}
        testCreateKeeper={createKeeper}
        createPrioedNumber={createPrioedNumber}
        createPrioedUnit={createPrioedUnit}
        inputNameValues={inputNameValues}
        inputPeriodValues={inputPeriodValues}
        setInputPeriodValues={setInputPeriodValues}
        setInputNameValues={setInputNameValues}
        setCreateFactory={setCreateFactory}
        setTestCreateKeeper={setCreateKeeper}
        setCreatePrioedNumber={setCreatePrioedNumber}
        setCreatePrioedUnit={setCreatePrioedUnit}
      />
      <EquipmentUpdateCard
        initialUpdtaeEquipValue={initialUpdtaeEquipValue}
        equipmentByIdData={equipmentByIdData}
        userGroupsData={userGroupsData}
        entityOptions={updateKeeperOptions}
        updateFactory={updateFactory}
        setUpdateFactory={setUpdateFactory}
        updatePrioedNumber={updatePrioedNumber}
        setUpdatePrioedNumber={setUpdatePrioedNumber}
        updatePrioedUnit={updatePrioedUnit}
        setUpdtaePrioedUnit={setUpdtaePrioedUnit}
        updateKeeper={updateKeeper}
        setUpdateKeeper={setUpdateKeeper}
        updateNeedMaintenance={updateNeedMaintenance}
        setUpdateNeedMaintenance={setUpdateNeedMaintenance}
        onUpdateGoBackButtonClick={handleModelClose}
        updateModalOpen={modalOpen === 'UPDATE'}
        refreshUploadFileData={getSearchUploadData} //刪除後立刻刷新
        getEquipmentData={getEquipmentData}
        fileName={fileName}
      />
      <YatungConfirmAlert isShowAlert={modalOpen === 'DELETE'} onClose={handleModelClose} onOk={handleDelete} />
    </>
  );
}
