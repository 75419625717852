import YatungAlert from '@Src/_basic/components/YatungAlert';
import YatungButton from '@Src/_basic/components/YatungButton';
import YatungCombinationSelect from '@Src/_basic/components/YatungCombinationSelect';
import YatungPage from '@Src/_basic/components/YatungPage';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { ProportionData } from '@Src/_basic/object/MixerAndProportionInformationBindingType';
import { MixerOption } from '@Src/_basic/object/MixerTypes';
import { MixerApi } from '@Src/_basic/protocol/mixerInformation/MixerApi';
import { MixerProportionApi } from '@Src/_basic/protocol/mixerProportion/MixerProportionApi';
import { useApi } from '@Src/redux/api/apiAction';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { SelectChangeEvent, Stack } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import MixerAndProportionInformationBindForm from '../components/MixerAndProportionInformationBindForm';

type SelectType = {
  areaId: number;
  factoryId: number;
};

type QueryType = {
  factoryId: number;
  mixerId: number;
};

type AsyncStorageSavingType = SelectType & QueryType;

type AlertOption = {
  title: string;
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen: (e: boolean) => void;
  type: 'error' | 'success';
};

export default function MixerAndProportionInformationBindScreen() {
  const { t: i18T } = useTranslation();
  const { actionLoading } = useApi();

  const location = useLocation();
  const { request, setSaveRequest } = useRequestSaving<AsyncStorageSavingType>();

  const [proportionData, setProportionData] = useState<ProportionData[]>([]);
  const [mixerOption, setMixerOption] = useState<Array<Options>>([]);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState<boolean>(false);

  const initFilterValue: AsyncStorageSavingType = useMemo(() => {
    const {
      areaId = 0,
      factoryId = 0,
      mixerId = 0,
    } = request?.page === location.pathname && request?.request ? request.request : {};

    return {
      areaId,
      factoryId,
      mixerId,
    };
  }, [request, location.pathname]);

  const initQuery: QueryType = useMemo(
    () => ({
      factoryId: initFilterValue.factoryId,
      mixerId: initFilterValue.mixerId,
    }),
    [initFilterValue],
  );

  const [filterValue, filterValueDispatch] = useReducer<
    Reducer<AsyncStorageSavingType, Partial<AsyncStorageSavingType>>
  >(updateReducer, initFilterValue);
  const [query, queryDispatch] = useReducer<Reducer<QueryType, Partial<QueryType>>>(updateReducer, initQuery);

  const reset = useCallback(() => {
    handleChange('mixerId')(0);
    setProportionData([]);
  }, []);

  const getMixerProportionSetting = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const mixerId = e.target.value as number;
      handleChange('mixerId')(mixerId);
      MixerProportionApi.getMixerProportionSetting({ mixerId }, (_data) => {
        setProportionData(_data);
        const { areaId, factoryId } = filterValue;
        setSaveRequest({
          page: location.pathname,
          request: {
            areaId,
            factoryId,
            mixerId,
          },
        });
      });
    },
    [filterValue, location.pathname, setSaveRequest],
  );

  const getMixerOptions = useCallback((factoryId: number) => {
    MixerApi.getMixerOptionsV2ByFactoryId(factoryId, (mixerData: MixerOption[]) => {
      const mixerOptions: Options[] = mixerData.map((mixer: MixerOption) => ({
        value: mixer.id,
        text: mixer.mixerName,
      }));
      setMixerOption(mixerOptions);
    });
  }, []);

  const handleSubmit = useCallback(() => {
    const proportionNewData = proportionData.map((value: ProportionData) => {
      return { propOptId: value.propOptId, mixerPropSettingId: value.id };
    });
    MixerProportionApi.putMixerProportionSetting(
      {
        mixerId: query.mixerId,
        settings: proportionNewData,
      },
      () => setAlertOpen(true),
      () => setAlertErrorOpen(true),
    );
  }, [query, proportionData]);

  const handleChange =
    (field: keyof SelectType | keyof QueryType) =>
    (value: SelectType[keyof SelectType] | QueryType[keyof QueryType]) => {
      filterValueDispatch({ [field]: value });
      queryDispatch({ [field]: value });
    };

  useEffect(() => {
    reset();
    if (query.factoryId) getMixerOptions(query.factoryId);
  }, [query.factoryId]);

  // 處理返回頁面時,尚未取得mixerOption的情況,mixerId無法選擇
  useEffect(() => {
    if (!query.mixerId || mixerOption.length) return;

    MixerProportionApi.getMixerProportionSetting({ mixerId: query.mixerId }, (_data) => {
      setProportionData(_data);
      handleChange('mixerId')(query.mixerId);
    });
  }, []);

  const alertOptions: AlertOption = useMemo(() => {
    const open = alertOpen || alertErrorOpen;
    const setOpen = alertOpen ? setAlertOpen : setAlertErrorOpen;
    const title = alertOpen
      ? i18T('GLOBAL.SUCCESS', { text: i18T('GLOBAL.UPDATE') })
      : i18T('GLOBAL.ERROR', { text: i18T('GLOBAL.UPDATE') });
    const type = alertOpen ? 'success' : 'error';

    return { open, setOpen, title, type };
  }, [alertOpen, alertErrorOpen, i18T]);

  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.MIXERANDPROPROTION_INFORMATION_BIND.ROUTE_TITLE')}
      body={
        <>
          <Stack direction="row" justifyContent={'space-between'}>
            <Stack direction="row" spacing={2} alignItems={'center'}>
              <YatungCombinationSelect<SelectType>
                filterValue={filterValue}
                handleChange={handleChange}
                selectOptions={['AreaAndFactory']}
              />
              <YatungSelect
                disabled={!filterValue.factoryId || actionLoading}
                options={mixerOption}
                value={query.mixerId}
                onChange={getMixerProportionSetting}
              />
            </Stack>
            <Stack direction="row" spacing={2} alignItems={'center'}>
              <YatungButton
                disabled={!query.mixerId || !proportionData.length}
                text={i18T('GLOBAL.FINISH')}
                onClick={handleSubmit}
                color="blue"
                variant="contained"
                sx={{
                  py: 1,
                  px: 2,
                  mr: 5,
                }}
              />
            </Stack>
          </Stack>
          <MixerAndProportionInformationBindForm
            proportionData={proportionData}
            setProportionData={setProportionData}
          />
          <YatungAlert {...alertOptions} />
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
