import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Detail(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12.4,22.5C6.6,22.5,1.9,17.8,1.9,12c0-5.8,4.7-10.5,10.5-10.5S22.9,6.2,22.9,12C22.9,17.8,18.2,22.5,12.4,22.5z M12.4,2.5c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5S17.6,2.5,12.4,2.5z" />
      <path d="M13.6,7.4c0,0.7-0.5,1.2-1.2,1.2c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2C13,6.2,13.6,6.7,13.6,7.4z" />
      <path d="M13.6,12c0,0.7-0.5,1.2-1.2,1.2c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2C13,10.8,13.6,11.3,13.6,12z" />
      <path d="M12.4,18.2c0.6,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2S11.8,18.2,12.4,18.2z" />
    </SvgIcon>
  );
}
