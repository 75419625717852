import { Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentAndGroupsTableType } from '@Src/_basic/object/AccountRightType';
import { RoleApi } from '@Src/_basic/protocol/accountRights/fetAuth/role/RoleApi';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungInput from '@Src/_basic/components/YatungInput';
import YatungSelect, { Options } from '@Src/_basic/components/YatungSelect';
import YatungTable, { Column } from '@Src/_basic/components/YatungTable';
import TitleWithButton from '../../../_components/TitleWithButton';

import YatungConfirmAlert from '@Src/_basic/components/YatungConfirmAlert';
import AddCircleFillIcon from '@Src/_basic/icons/AddCircleFill';
import DeleteCircleIcon from '@Src/_basic/icons/DeleteCircle';
import SearchIcon from '@Src/_basic/icons/Search';
import SwitchIcon from '@Src/_basic/icons/Switch';
import TickIcon from '@Src/_basic/icons/Tick';
import { GroupApi } from '@Src/_basic/protocol/accountRights/fetAuth/group/GroupApi';

interface Props {
  open: boolean;
  closeModel: () => void;
  factoryOptions: Array<Options>;
  postGetAccountGroupList: () => void;
}

export default function AccountGroupManagementCreateCard({
  open,
  closeModel,
  factoryOptions,
  postGetAccountGroupList,
}: Props) {
  const { t: i18T } = useTranslation();

  const [code, setCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [searchGroup, setSearchGroup] = useState<string>('');
  const [selectFactory, setSelectFactory] = useState<number>(0);
  const [state, setState] = useState<string>('0');

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');

  const [roleListData, setRoleListData] = useState<Array<ContentAndGroupsTableType>>([]);
  const [searchRoleListByInput, setSearchRoleListByInput] = useState<Array<ContentAndGroupsTableType>>();

  const reset = () => {
    setRoleListData([]);
    setSelectFactory(0);
    setCode('');
    setName('');
  };

  const cancelHandler = () => {
    closeModel();
    reset();
  };

  const finishHandler = () => {
    if (code && name && selectFactory) {
      GroupApi.postCreateRightsGroup(
        {
          code,
          name,
          state,
          ids: roleListData?.filter((item) => item.check).map((item) => item.id),
          attrs: [
            {
              code: 'type',
              attrVal: 'user',
            },
            {
              code: 'factory',
              attrVal: selectFactory.toString(),
            },
          ],
        },
        (_data) => {
          postGetAccountGroupList();
          closeModel();
          reset();
        },
        (error) => {
          setIsShowAlert(true);
          setAlertText(error.message);
        },
      );
    }
  };

  const postGetRoleList = () => {
    if (selectFactory) {
      RoleApi.postGetRightsRoles(
        {
          pageNumber: 0,
          pageSize: 99999,
          state: '1',
          attrs: [
            {
              code: 'factory',
              attrVal: selectFactory.toString(),
            },
          ],
        },
        (_data) => setRoleListData(_data.content.map((item) => ({ ...item, check: false }))),
      );
    }
  };

  useEffect(() => {
    postGetRoleList();
  }, [selectFactory]);

  useEffect(() => {
    setSearchRoleListByInput(roleListData);
    if (searchGroup) {
      setSearchRoleListByInput(
        roleListData.filter(
          (item) =>
            item.code.toUpperCase().includes(searchGroup.toUpperCase()) ||
            item.name.toUpperCase().includes(searchGroup.toUpperCase()),
        ),
      );
    }
  }, [searchGroup, roleListData]);

  const columns: Array<Column> = [
    {
      text: i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.ROLE_CODE'),
      dataField: 'code',
    },
    {
      text: i18T('USERSMANAGEMENU.EDIT_ACCOUNT.NAME_2'),
      dataField: 'name',
    },
    {
      text: undefined,
      dataField: 'id',
      formatter: ({ row }) => {
        return (
          <>
            <IconButton
              onClick={() =>
                setRoleListData((prev) => prev.map((item) => (item.id === row.id ? { ...item, check: false } : item)))
              }
              edge="end"
              sx={{
                height: 10,
                width: 10,
              }}
            >
              <DeleteCircleIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Modal open={open}>
      <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        <TitleWithButton
          Icon={<AddCircleFillIcon sx={{ width: 40, height: 35 }} />}
          title={i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.CREATE_GROUP')}
          onCancel={cancelHandler}
          onFinish={finishHandler}
        />
        <Stack spacing={5} sx={{ bgcolor: 'white', minWidth: 700, p: 2, px: 4, borderRadius: '0 0 20px 20px' }}>
          <Box display="flex" alignItems="center" flexDirection="row">
            <Stack sx={{ width: '60%' }}>
              <Typography sx={{ fontSize: 28 }}>
                {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.CODE')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Stack sx={{ width: '50%' }}>
                <YatungInput value={code} onChange={(e: any) => setCode(e.target.value)} />
              </Stack>
            </Stack>
            <Stack>
              <Typography sx={{ fontSize: 28 }}>
                {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.NAME')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Stack sx={{ width: '100%' }}>
                <YatungInput value={name} onChange={(e: any) => setName(e.target.value)} />
              </Stack>
            </Stack>
          </Box>
          <Box display="flex" alignItems="center" flexDirection="row">
            <Stack spacing={1} sx={{ width: '35%', mr: 3 }}>
              <Typography sx={{ fontSize: 28, letterSpacing: 10 }}>
                {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.STATUS')}:
                <span style={{ color: state === '1' ? '#00D03A' : '#FC0000', marginLeft: '20px' }}>
                  {state === '1' ? i18T('GLOBAL.OPEN') : i18T('GLOBAL.STOP')}
                </span>
              </Typography>
              <Stack spacing={2} alignItems="center" justifyContent="flex-start" direction="row">
                <YatungButton
                  text={i18T('GLOBAL.OPEN')}
                  color={'green'}
                  startIcon={<SwitchIcon />}
                  onClick={() => setState('1')}
                />
                <YatungButton
                  text={i18T('GLOBAL.STOP')}
                  color={'red'}
                  startIcon={<SwitchIcon />}
                  onClick={() => setState('0')}
                />
              </Stack>
            </Stack>
            <Stack spacing={1} sx={{ width: '35%', mr: 3 }}>
              <Stack display="flex" alignItems="center" flexDirection="row">
                <Typography sx={{ fontSize: 28 }}>
                  {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.FACTORY')}
                </Typography>
                <Typography sx={{ fontSize: 28 }}>
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
              </Stack>
              <YatungSelect
                options={factoryOptions}
                value={selectFactory}
                onChange={(e) => setSelectFactory(e.target.value as number)}
              />
            </Stack>
            <Stack>
              <Typography sx={{ fontSize: 28, letterSpacing: 15 }}>
                {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.EDIT_TIME')}
              </Typography>
              <Stack>
                <Typography sx={{ pl: 1, letterSpacing: 10 }}>{moment(new Date()).format('YYYY/MM/DD')}</Typography>
                <Typography sx={{ pl: 1, letterSpacing: 10 }}>{moment(new Date()).format('HH:mm')}</Typography>
              </Stack>
            </Stack>
          </Box>
          <Stack display="flex" flexDirection="column" spacing={2}>
            <Typography sx={{ fontSize: 28 }}>
              {i18T('USERSMANAGEMENU.ACCOUNT_GROUP_MANAGEMENT.CREATE_ROLE')}
            </Typography>
            <YatungTable
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'gray',
                minHeight: 200,
                maxHeight: 200,
                borderRadius: 2,
                maxWidth: 630,
                px: 1,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#BB5E00',
                  borderRadius: 8,
                },
              }}
              isTableMinWidth={false}
              columns={columns}
              data={roleListData.filter((item) => item.check)}
              headColor={false}
            />
            <YatungInput
              value={searchGroup}
              onChange={(e: any) => setSearchGroup(e.target.value)}
              position="start"
              icon={<SearchIcon sx={{ fontSize: 36, ml: 2 }} />}
              style={{ fontSize: 28 }}
            />
            <Stack
              sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'gray',
                minHeight: 200,
                maxHeight: 200,
                maxWidth: 630,
                borderRadius: 2,
                px: 3,
                py: 1,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 8,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#BB5E00',
                  borderRadius: 8,
                },
              }}
            >
              {searchRoleListByInput?.map((item: any) => (
                <Button
                  key={item.id}
                  sx={{ fontSize: 20, color: 'gray', borderBottom: 1, justifyContent: 'space-between' }}
                  disabled={item.check}
                  onClick={() => {
                    setRoleListData((prev) =>
                      prev.map((_data) => (_data.id === item.id ? { ..._data, check: true } : _data)),
                    );
                  }}
                >
                  {`${item.code} ${item.name}`}
                  {item.check ? <TickIcon /> : <></>}
                </Button>
              ))}
              <YatungConfirmAlert
                isShowAlert={isShowAlert}
                onClose={() => {
                  setIsShowAlert(false);
                  setTimeout(() => setAlertText(''), 300);
                }}
                alertText={alertText}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}
