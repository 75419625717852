import { Response } from '@Src/_basic/object/ApiType';
import { TestUnit } from '@Src/_basic/object/UnitType';
import { Api } from '../Api';
import { UnitProtocol } from './UnitProtocol';

export class UnitApi {
  public static getTestUnit(
    onSuccess?: (data: Array<TestUnit>) => void,
    onFailed?: (res: Response<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(UnitProtocol.ALL_Test_Unit, undefined, onSuccess, onFailed, onFinally);
  }
}
