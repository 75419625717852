import React from 'react';
import { OrganiztionsData } from '@Src/_basic/object/AccountRightType';
import { Typography } from '@mui/material';

interface Props {
  row: OrganiztionsData;
}
export default function OrganiztionNameColumnFormatter(props: Props) {
  const { row } = props;
  return <Typography variant="inherit">{row.organiztionName}</Typography>;
}
