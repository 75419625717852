import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import VerificationCodeScreen from './screens/VerificationCodeScreen';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <VerificationCodeScreen />,
  },
];

const VerificationCodeRouter = () => PermissionRoute(routesConfig);

export default memo(VerificationCodeRouter);
