import React from 'react';
import MixerInformationUpdateCard from '../components/MixerInformationUpdateCard';

export default function MixerInformationUpdateScreen() {
  return (
    <>
      <MixerInformationUpdateCard />
    </>
  );
}
