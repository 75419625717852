import { Box } from '@mui/material';
import React, { memo } from 'react';

interface Props {
  header: React.ReactNode;
  body: React.ReactNode;
  invisible?: boolean;
}

export default memo(function ExptScheduleCard(props: Props) {
  return (
    <Box
      sx={{
        minHeight: '100%',
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.2)',
        opacity: props.invisible ? 0 : 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: '#BB5E00',
          borderRadius: '10px 10px 0px 0px',
          height: '55px',
        }}
      >
        {props.header}
      </Box>
      <Box
        sx={{
          height: 'calc(100% - 55px)',
          borderRadius: '0px 0px 10px 10px',
        }}
      >
        {props.body}
      </Box>
    </Box>
  );
});
