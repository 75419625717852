import React from 'react';
import MixerInformationCreateCard from '../components/MixerInformationCreateCard';

export default function MixerInformationCreateScreen() {
  return (
    <>
      <MixerInformationCreateCard />
    </>
  );
}
