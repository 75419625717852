import { TestCardContainer } from '@Src/_basic/components/MuiStyleComponent/TestComponent';
import { Button, Stack } from '@mui/material';
import React, { memo } from 'react';

interface Props {
  onPress?: () => void;
  children: React.ReactNode;
  isToggled?: boolean;
}

function FixedRatioBlock({ onPress, children, isToggled }: Props) {
  return (
    <TestCardContainer
      sx={{
        width: isToggled ? 300 : 250,
        height: isToggled ? 300 : 250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#bbb',
        transition: 'all 0.3s ease',
      }}
    >
      {onPress ? (
        <Button onClick={onPress}>
          <Stack
            bgcolor="#666"
            width={isToggled ? 300 : 250}
            height={isToggled ? 250 : 200}
            borderRadius={10}
            justifyContent="center"
            color="white"
            alignItems="center"
            sx={{
              transition: 'all 0.3s ease',
            }}
          >
            {children}
          </Stack>
        </Button>
      ) : (
        <Stack
          bgcolor="#666"
          width={isToggled ? 300 : 250}
          height={isToggled ? 250 : 200}
          borderRadius={10}
          justifyContent="center"
          color="white"
          alignItems="center"
          sx={{
            transition: 'all 0.3s ease',
            ':hover': {
              cursor: 'not-allowed',
            },
          }}
        >
          {children}
        </Stack>
      )}
    </TestCardContainer>
  );
}

export default memo(FixedRatioBlock);
