import axios from 'axios';

import {
  AppPermissionPaginationRequest,
  AppPermissionRequest,
  EmailRequest,
  EmailsRequest,
} from '@Basic/object/AppPermissionType';
import { AppPermissionProtocol } from '@Basic/protocol/appPermission/AppPermissionProtocol';
import { AuthSysBaseResponse } from '@Src/_basic/object/ApiType';
import { Api } from '../Api';

export class PermissionRequires {
  public static requestEmail(
    request: EmailRequest,
    onSuccess?: (data: AppPermissionRequest) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(AppPermissionProtocol.SEND_APPLY_EMAIL, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static requestInformation(
    onSuccess?: (data: Array<AppPermissionRequest>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.get(AppPermissionProtocol.APPLY_USERINFORMATION, undefined, onSuccess, onFailed, onFinally);
  }

  public static requestDelete(
    id: number,
    onSuccess?: (data: AppPermissionRequest) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        id: id,
      },
    };
    Api.get(AppPermissionProtocol.DELETE_APPLY, config, onSuccess, onFailed, onFinally);
  }

  public static requestUsersData(
    request: EmailsRequest,
    onSuccess?: (data: Array<AppPermissionRequest>) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    Api.post(AppPermissionProtocol.GET_USERS_DATA, request, undefined, onSuccess, onFailed, onFinally);
  }

  public static requestSuccess(
    id: number,
    onSuccess?: (data: AppPermissionRequest) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    console.log('id', id);
    console.log('url', AppPermissionProtocol.SUCCESS_APPLY + '?id=' + id);
    const config = {
      params: {
        id: id,
      },
    };
    Api.get(AppPermissionProtocol.SUCCESS_APPLY, config, onSuccess, onFailed, onFinally);
  }

  public static requestPagination(
    page: number,
    onSuccess?: (data: AppPermissionPaginationRequest) => void,
    onFailed?: (res: AuthSysBaseResponse<null>) => void,
    onFinally?: () => void,
  ) {
    const config = {
      params: {
        page: page,
      },
    };
    Api.get(AppPermissionProtocol.APPLY_USERINFORMATION_PAGE, config, onSuccess, onFailed, onFinally);
  }
}
