import React, { useCallback, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';

import Layout from './module/layout';
import PowerbiManagePage from './module/powerbiManage/PowerbiManagePage';
import UsersManagePage from './module/usersManage/UsersManagePage';
import View from './module/view';

import { DropdownItem } from '@Src/_basic/components/YatungSelect';
import { AreaData, AreaOptions } from '@Src/_basic/object/AreaType';
import { AreaItem, GetUserGroupsData, GetUserGroupsParams } from '@Src/_basic/object/AuthType';
import { FactoryItem } from '@Src/_basic/object/FactoryType';
import { AuthApi } from '@Src/_basic/protocol/auth/AuthApi';
import { useAuth } from '@Src/redux/auth/authActions';
import ProportionManage from './module/proportionManage';
import QualityManagement from './module/qualityManagement';
import RealTimeMonitoring from './module/realTimeMonitoring';
import Report from './module/report';

export interface RouteConfig {
  path: string;
  permission?: string;
  element: JSX.Element;
}

export default function BasicRouter() {
  const { setUserData, setUserGroupsData, setUserAreaOptionsData, setUserApiPermission } = useAuth();

  const getAreasOptions = useCallback(
    (areas: AreaData[]) => {
      const areaOptions: AreaOptions[] = areas.map((allArea: AreaData) => ({
        value: allArea.id,
        text: allArea.name,
        factories: allArea.factoryList.map((factory: FactoryItem) => ({
          value: factory.factoryId,
          text: factory.factoryName,
          facCode: `${factory.factoryCode + factory.branchCode}`,
        })),
      }));
      setUserAreaOptionsData(areaOptions);
    },
    [setUserAreaOptionsData],
  );

  const getUserGroupsDataSuccess = useCallback(
    (getUserGroupsDataResp: GetUserGroupsData) => {
      if (getUserGroupsDataResp) {
        const factoryArray = getUserGroupsDataResp.areas.reduce((acc: Array<DropdownItem<number>>, area: AreaItem) => {
          area.factoryList.forEach((factory: FactoryItem) => {
            if (!acc.some((f) => f.value === factory.factoryId)) {
              acc.push({ text: factory.factoryName, value: factory.factoryId });
            }
          });
          return acc;
        }, [] as Array<DropdownItem<number>>);
        const afterData = {
          ...getUserGroupsDataResp,
          areas: getUserGroupsDataResp.areas,
          factories: factoryArray,
          supervisorReportGroups: getUserGroupsDataResp.supervisorReportGroups.map((item) => ({
            ...item,
            users: item.users.reduce(
              (prev, curr) => [...prev, { text: curr.accountName, value: curr.account }],
              [] as Array<DropdownItem<string>>,
            ),
          })),
          supervisorTransformGroups: getUserGroupsDataResp.supervisorTransformGroups.map((item) => ({
            ...item,
            users: item.users.reduce(
              (prev, curr) => [...prev, { text: curr.accountName, value: curr.account }],
              [] as Array<DropdownItem<string>>,
            ),
          })),
          supervisorAssignGroups: getUserGroupsDataResp.supervisorAssignGroups.map((item) => ({
            ...item,
            users: item.users.reduce(
              (prev, curr) => [...prev, { text: curr.accountName, value: curr.account }],
              [] as Array<DropdownItem<string>>,
            ),
          })),
        };
        getAreasOptions(afterData.areas);
        setUserGroupsData(afterData);
      }
    },
    [getAreasOptions, setUserGroupsData],
  );

  const getUserGroupsData = useCallback(
    (params: GetUserGroupsParams) => {
      AuthApi.getUserGroupsData(params, getUserGroupsDataSuccess, (err) =>
        console.log('ERROR: getUserGroupsData', err),
      );
    },
    [getUserGroupsDataSuccess],
  );

  const getUserApiPermission = useCallback(() => {
    AuthApi.getUserApiPermission((data: Array<string>) => {
      setUserApiPermission(data);
    });
  }, [setUserApiPermission]);

  const getUserDataSuccess = useCallback(
    (data: any) => {
      console.log('getUserDataSuccess', data);
      setUserData(data);
      if (data.id) {
        getUserGroupsData({ id: data.id });
        getUserApiPermission();
      }
    },
    [getUserApiPermission, getUserGroupsData, setUserData],
  );

  const getUserData = useCallback(() => {
    AuthApi.getUserData(getUserDataSuccess);
  }, [getUserDataSuccess]);

  useEffect(() => {
    // getUserData();
    setUserData({
      accountName: '品管人員',
      roles: [],
      groups: [
        {
          createBy: 'fa01f705-8637-4130-a9e8-56f2d486f8d6',
          code: 'SIEVE_UTIL',
          updateBy: 'fa01f705-8637-4130-a9e8-56f2d486f8d6',
          name: '品管人員',
          state: '1',
          id: '0dc0edf0-c27b-4f62-a62b-c38566328d21',
          updateDt: '2024/04/02 15:39:52',
          createDt: '2024/04/02 15:39:52',
          description: '品管人員'
        }
      ],
      updateDt: '2024/04/02 15:40:07',
      createDt: '2024/03/26 16:48:10',
      createBy: 'fa01f705-8637-4130-a9e8-56f2d486f8d6',
      sourceKind: '0',
      phone: '0987654321',
      updateBy: 'fa01f705-8637-4130-a9e8-56f2d486f8d6',
      tel: '039907503#142',
      id: '5bff65f0-f871-401a-81b5-d15eb32a8697',
      state: '1',
      account: 'app_user001',
      email: 'app_user001@gmail.com',
    })
    setUserApiPermission(['CA025', 'WD032', 'WD042', 'WD054', 'WD055', 'WD064'])
    setUserGroupsData({
      id: '5bff65f0-f871-401a-81b5-d15eb32a8697',
      account: 'app_user001',
      accountName: '品管人員',
      jobTitle: '品管人員',
      state: true,
      organizations: [],
      areas: [
        {
          id: 13,
          name: '臺灣',
          removed: false,
          createTime: "1711433924981",
          sortingOrder: 13,
          factoryList: [
            {
              factoryId: 25,
              factoryName: '宥辰八德廠',
              factoryCode: 'T',
              branchCode: '0'
            }
          ]
        }
      ],
      supervisorReportGroups: [],
      supervisorTransformGroups: [],
      supervisorAssignGroups: [],
      factories: [{ text: '宥辰八德廠', value: 25 }]
    })
    setUserAreaOptionsData([
      {
        value: 13,
        text: '臺灣',
        factories: [{ value: 25, text: '宥辰八德廠', facCode: 'T0' }]
      }
    ])
  }, []);

  return (
    <Layout>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/UsersManagePage/*" element={<UsersManagePage />} />
        <Route path="/PowerbiManagePage/*" element={<PowerbiManagePage />} />
        <Route path="/proportionManage/*" element={<ProportionManage />} />
        <Route path="/qualityManagement/*" element={<QualityManagement />} />
        <Route path="/report/*" element={<Report />} />
        <Route path="/view/*" element={<View />} />
        <Route path="/realTimeMonitoring/*" element={<RealTimeMonitoring />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
}
