import YatungFadeInOut from '@Src/_basic/components/YatungFadeInOut';
import YatungPage from '@Src/_basic/components/YatungPage';
import { updateReducer } from '@Src/_basic/helpers/useUpdateReducer';
import { AccountManagementData } from '@Src/_basic/object/AccountManagementType';
import { PageInfo } from '@Src/_basic/object/PageInfoType';
import { UsersApi } from '@Src/_basic/protocol/accountRights/users/UsersApi';
import { useRequestSaving } from '@Src/redux/requestSaving/requestSavingActions';
import { Grid, Pagination } from '@mui/material';
import React, { Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import AccountManagementSearchBar from './AccountManagementSearchBar';
import AccountManagementTable from './AccountManagementTable';

export type FilterValue = {
  inputValues: string;
  factory: number;
  organize: number;
};

type Query = FilterValue & {
  page: number;
};

export default function AccountManagementCard() {
  const { t: i18T } = useTranslation();

  const location = useLocation();
  const { setSaveRequest, request } = useRequestSaving<Query>();

  const initFilterValue = useMemo(() => {
    const {
      inputValues = '',
      factory = 0,
      organize = 0,
    } = location.pathname === request?.page && request?.request ? request.request : {};

    return {
      inputValues,
      factory,
      organize,
    };
  }, [location.pathname, request]);

  const initPageInfo = useMemo(
    () => ({
      page: location.pathname === request?.page && request?.request ? request.request.page : 1,
      pageSize: 20,
      total: 0,
      totalCount: 0,
    }),
    [location.pathname, request],
  );

  const [filterValue, filterDispatch] = useReducer<Reducer<FilterValue, Partial<FilterValue>>>(
    updateReducer,
    initFilterValue,
  );
  const [pageInfo, pageInfoDispatch] = useReducer<Reducer<PageInfo, Partial<PageInfo>>>(updateReducer, initPageInfo);

  const effectRef = useRef(false);
  const [isVisable, setIsVisable] = useState(false);
  const [accountData, setAccountData] = useState<Array<AccountManagementData>>([]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    pageInfoDispatch({ page });
    getAllAccount(page);
  };

  const handleChange = useCallback((file: keyof FilterValue, value: FilterValue[keyof FilterValue]) => {
    filterDispatch({ [file]: value });
    pageInfoDispatch({ page: 1, total: 0 });
    setIsVisable(false);
    setAccountData([]);
  }, []);

  const getAllAccount = useCallback(
    (page: number) => {
      UsersApi.getAllUser(
        {
          pageNumber: page - 1,
          pageSize: pageInfo.pageSize,
          sourceKides: [0, 3],
          ...(filterValue.inputValues && { keywords: [filterValue.inputValues] }),
          ...(filterValue.factory && { factoryId: filterValue.factory }),
          ...(filterValue.organize && { organizationId: filterValue.organize }),
        },
        (_data) => {
          setAccountData(
            _data.data.map((item: AccountManagementData, index: number) => ({
              ...item,
              index: index + 1 + (page - 1) * pageInfo.pageSize,
            })),
          );
          pageInfoDispatch({ total: _data.totalPages });
          setSaveRequest({
            page: location.pathname,
            request: {
              ...filterValue,
              page,
            },
          });
          setIsVisable(true);
        },
      );
    },
    [filterValue, pageInfo, location.pathname, setSaveRequest],
  );

  useEffect(() => {
    if (!effectRef.current) {
      effectRef.current = true;
      getAllAccount(pageInfo.page);
    }
  }, [effectRef]);

  return (
    <YatungPage
      title={i18T('USERSMANAGEMENU.ACCOUNT_MANAGEMENT.ROUTE_TITLE')}
      body={
        <>
          <AccountManagementSearchBar
            filterValue={filterValue}
            handleChange={handleChange}
            getAllAccount={() => getAllAccount(pageInfo.page)}
          />
          {
            <YatungFadeInOut isVisible={isVisable}>
              <>
                <AccountManagementTable accountData={accountData} />
                <Grid container justifyContent={'center'} item xs={11} sx={{ mt: 2 }}>
                  <Pagination count={pageInfo.total} page={pageInfo.page} onChange={handlePageChange} color="primary" />
                </Grid>
              </>
            </YatungFadeInOut>
          }
        </>
      }
      contentBgColor="#FFF"
    />
  );
}
