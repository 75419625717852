import React, { useEffect, useState } from 'react';

import YatungButton from '@Src/_basic/components/YatungButton';
import YatungDateSelect from '@Src/_basic/components/YatungDateSelect';
import YatungDialog from '@Src/_basic/components/YatungDialog';
import YatungDivider from '@Src/_basic/components/YatungDivider';
import { Options } from '@Src/_basic/components/YatungSelect';
import YatungSelectColor from '@Src/_basic/components/YatungSelectColor';
import YatungWindow from '@Src/_basic/components/YatungWindow';
import RestDaySettingIcon from '@Src/_basic/icons/RestDaySettingIcon';
import { AreaCheckedBoxOptions, AreaData, AreaOptions } from '@Src/_basic/object/AreaType';
import { getEvents, getEventsResponse } from '@Src/_basic/object/CalendarType';
import { FactoryCheckedBoxOptions, FactoryItem } from '@Src/_basic/object/FactoryType';
import { AreaApi } from '@Src/_basic/protocol/area/AreaApi';
import { CalendarApi } from '@Src/_basic/protocol/calendar/CalendarApi';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface Props {
  setEvents: (events: getEvents[]) => void;
  settingModalOpen: boolean;
  onCloseModal: () => void;
  calendarColorCode: Array<Options>;
}

export default function RestDaySetting(props: Props) {
  const { t: i18T } = useTranslation();
  const { settingModalOpen, onCloseModal, setEvents, calendarColorCode } = props;
  const RestDaySettingItem = ({ Icon, label, value = '' }: any) => {
    return (
      <Stack
        spacing={1}
        alignItems="center"
        justifyContent="flex-end"
        direction="row"
        sx={{ p: 1, mt: 1, mb: 1, width: '100%', fontSize: '20px' }}
      >
        {Icon && <Icon sx={{ mr: 1 }} />}
        <Typography
          sx={{ minWidth: '60px', fontSize: '18px', flex: 'none', padding: '0 8px 0 0' }}
        >{`${label} :`}</Typography>
        <Typography sx={{ minWidth: '60px', fontSize: '18px', flex: 'none', padding: '0 8px 0 0' }}>{value}</Typography>
      </Stack>
    );
  };

  const [query, setQuery] = useState<any>({
    startTime: new Date().getTime() - 86399000,
    endTime: new Date().getTime(),
  });
  const [areaCheckedData, setAreaCheckedData] = useState<Array<number>>([]);
  const [areas, setAreas] = useState<Array<AreaOptions>>([]);
  const [factories, setFactories] = useState<any>([]);
  const [factoryCheckedData, setFactoryCheckedData] = useState<Array<number>>([]);
  const [colorCodeId, setColorCodeId] = useState<number>(0);
  const [title, setTitle] = useState<string>('');

  const handleChangeClick = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target;

    const valueTurnToNumber = Number(value);
    const factory = areas.find((item: AreaOptions) => {
      return item.value === valueTurnToNumber;
    });
    factory?.factories.forEach((item: FactoryCheckedBoxOptions) => {
      item.area = factory.text;
      item.areaId = factory.value;
    });
    if (!areaCheckedData.includes(valueTurnToNumber)) {
      setAreaCheckedData([...areaCheckedData, valueTurnToNumber]);
      getAppendFactory(factory);
    } else {
      const index = areaCheckedData.indexOf(valueTurnToNumber);
      if (index > -1) {
        areaCheckedData.splice(index, 1);
      }
      setAreaCheckedData([...areaCheckedData]);
      getFactoryCheckedTemp(factory);
      getFilterFactory(factory);
    }
  };
  const getFactoryCheckedTemp = (factory: AreaCheckedBoxOptions | undefined) => {
    const factoryCheckedTemp = factoryCheckedData.map((item: number) => item);
    const factoryValue = factory?.factories.map((item: FactoryCheckedBoxOptions) => item.value);
    const factoryCheckedDataTemp = factoryCheckedTemp.filter((item: number) => !factoryValue?.includes(item));
    setFactoryCheckedData([...factoryCheckedDataTemp]);
  };
  const getFilterFactory = (factory: AreaCheckedBoxOptions | undefined) => {
    const filterFactory = []
      .concat(...factories)
      .filter((item: FactoryCheckedBoxOptions) => item.areaId !== factory?.value);
    setFactories(filterFactory);
  };
  const getAppendFactory = (factory: AreaCheckedBoxOptions | undefined) => {
    setFactories([...factories, factory?.factories]);
  };
  const getChecked = (id: number) => {
    if (areaCheckedData) {
      return areaCheckedData.includes(id);
    } else {
      return false;
    }
  };
  const handleFactoryChangeClick = (e: SelectChangeEvent, factoryId: number) => {
    if (!factoryCheckedData.includes(factoryId)) {
      setFactoryCheckedData([...factoryCheckedData, factoryId]);
    } else {
      const index = factoryCheckedData.indexOf(factoryId);
      if (index > -1) {
        factoryCheckedData.splice(index, 1);
      }

      setFactoryCheckedData([...factoryCheckedData]);
    }
  };
  const getFactoryChecked = (id: number) => {
    return factoryCheckedData.includes(id);
  };

  useEffect(() => {
    AreaApi.getAllAreas((allAreas: Array<AreaData>) => {
      const areaOptions: AreaOptions[] = allAreas.map((allArea: AreaData) => ({
        value: allArea.id,
        text: allArea.name,
        factories: allArea.factoryList.map((factory: FactoryItem) => ({
          value: factory.factoryId,
          text: factory.factoryName,
        })),
      }));
      setAreas(areaOptions);
    });
  }, []);
  const handleCloseModal = () => {
    onCloseModal();
    setQuery({
      startTime: new Date().getTime() - 86399000,
      endTime: new Date().getTime(),
    });
    setFactories([]);
    setAreaCheckedData([]);
    setFactoryCheckedData([]);
    setColorCodeId(0);
    setTitle('');
  };
  const handleQueryChange = (field: string) => async (value: any) => {
    setQuery({
      ...query,
      [field]: value,
    });
  };
  const handleEndDatePickerChange = (endTime: number) => {
    setQuery({
      ...query,
      startTime: query.startTime > endTime ? endTime - 86399000 : query.startTime,
      endTime,
    });
  };
  const handleSubmit = () => {
    const request = {
      startTime: query.startTime,
      endTime: query.endTime,
      factories: factoryCheckedData,
      colorCodeId,
      title,
    };

    CalendarApi.postCalendar(
      request,
      (data: any) => {
        CalendarApi.getAllCalendar((data: getEventsResponse[]) => {
          getAdjustCalendar(data);
        });

        const getAdjustCalendar = (data: getEventsResponse[]) => {
          const adjustEvents = data.map((item: getEventsResponse) => {
            return {
              id: item.id,
              title: item.title,
              start: moment(item.startTime).format('YYYY-MM-DD'),
              end: moment(item.endTime).format('YYYY-MM-DD'),
              backgroundColor: item.colorCode[0].colorCode,
              borderColor: item.colorCode[0].colorCode,
              factories: item.factories,
            };
          });
          setEvents(adjustEvents);
        };
        handleCloseModal();
      },
      undefined,
      undefined,
    );
  };

  const handleChangeColor = (e: SelectChangeEvent<unknown>) => {
    setColorCodeId(e.target.value as number);
  };

  const handleNotesInput = (e: SelectChangeEvent) => {
    setTitle(e.target.value);
  };
  return (
    <YatungDialog
      isTextArea
      isRestDay
      type={'md'}
      open={settingModalOpen}
      handleClose={handleCloseModal}
      icon={<RestDaySettingIcon sx={{ width: 40, height: 40, mr: 1, alignSelf: 'center' }} />}
      notes={title}
      handleNotesInput={handleNotesInput}
      placeHolder={i18T('USERSMANAGEMENU.CALENDARMANAGE.REST_DAY_SETTING')}
      restDayDescription={i18T('USERSMANAGEMENU.CALENDARMANAGE.NOTICE')}
      body={
        <>
          <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
            <RestDaySettingItem
              label={i18T('USERSMANAGEMENU.CALENDARMANAGE.FACTORY_TYPE')}
              value={i18T('USERSMANAGEMENU.CALENDARMANAGE.COUNT', {
                number: factoryCheckedData.length > 0 ? factoryCheckedData.length : 0,
              })}
            />
            <YatungSelectColor options={calendarColorCode} value={colorCodeId} onChange={handleChangeColor} />
          </Stack>
          <Stack flexDirection={'row'} sx={{ my: 2 }} justifyContent={'space-evenly'}>
            <YatungDateSelect
              label={i18T('GLOBAL.START')}
              value={query.startTime}
              onChange={(val) => val && handleQueryChange('startTime')(new Date(val).getTime())}
              maxDate={query.endTime}
            />
            <YatungDateSelect
              label={i18T('GLOBAL.END')}
              value={query.endTime}
              onChange={(val) => val && handleEndDatePickerChange(new Date(val).getTime())}
            />
          </Stack>
          <Stack flexDirection={'row'} sx={{ my: 2 }} justifyContent={'space-between'}>
            <Card sx={{ width: '380px', height: '365px', background: '#FFFFFF', mt: 1 }}>
              <CardHeader
                sx={{ color: '#BB5E00' }}
                title={
                  <>
                    <Grid container item xs={12} sx={{ mt: 1 }}>
                      <Grid container item xs={6} alignItems={'center'} justifyContent={'flex-start'}>
                        <Typography>&nbsp;</Typography>
                      </Grid>
                      <Grid container item xs={6} alignItems={'center'} justifyContent={'flex-start'}>
                        <Typography
                          sx={{ fontSize: '20px', fontFamily: 'Inter', fontWeight: 900, color: '#BB5E00', pr: 1 }}
                        >
                          {i18T('GLOBAL.AREA')}
                        </Typography>
                      </Grid>
                      <YatungDivider borderStyle />
                    </Grid>
                  </>
                }
              ></CardHeader>
              <CardContent sx={{ height: '100%' }}>
                <Box sx={{ mt: '2px', height: `calc(100% - 16px - 64px)`, width: '100%' }}>
                  <YatungWindow
                    className="scroll-bar"
                    data={areas}
                    renderItem={(data: AreaOptions) => {
                      return (
                        <Grid container item xs={12}>
                          <Grid container item xs={6} alignItems={'center'} justifyContent={'flex-start'}>
                            <Checkbox
                              onChange={handleChangeClick}
                              checked={getChecked?.(data.value)}
                              value={data.value}
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={6}
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                            sx={{ fontSize: '20px', fontFamily: 'Inter', fontWeight: 900, color: '#BB5E00', pr: 1 }}
                          >
                            {data.text}
                          </Grid>
                        </Grid>
                      );
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
            <Card sx={{ width: '380px', height: '365px', background: '#FFFFFF', mt: 1 }}>
              <CardHeader
                sx={{ color: '#BB5E00' }}
                title={
                  <>
                    <Grid container item xs={12} sx={{ mt: 1 }}>
                      <Grid container item xs={4} alignItems={'center'} justifyContent={'flex-start'}>
                        <Typography>&nbsp;</Typography>
                      </Grid>
                      <Grid container item xs={4} alignItems={'center'} justifyContent={'flex-start'}>
                        <Typography
                          sx={{ fontSize: '20px', fontFamily: 'Inter', fontWeight: 900, color: '#BB5E00', pr: 1 }}
                        >
                          {i18T('USERSMANAGEMENU.CALENDARMANAGE.AREA')}
                        </Typography>
                      </Grid>
                      <Grid container item xs={4} alignItems={'center'} justifyContent={'flex-start'}>
                        <Typography
                          sx={{ fontSize: '20px', fontFamily: 'Inter', fontWeight: 900, color: '#BB5E00', pr: 1 }}
                        >
                          {i18T('USERSMANAGEMENU.CALENDARMANAGE.FACTORY')}
                        </Typography>
                      </Grid>
                      <YatungDivider borderStyle />
                    </Grid>
                  </>
                }
              ></CardHeader>
              <CardContent sx={{ height: '100%' }}>
                <Box sx={{ mt: '2px', height: `calc(100% - 16px - 64px)`, width: '100%' }}>
                  <YatungWindow
                    className="scroll-bar"
                    data={[].concat(...factories)}
                    renderItem={(data: any) => {
                      return (
                        <Grid container item xs={12}>
                          <Grid container item xs={4} alignItems={'center'} justifyContent={'flex-start'}>
                            <Checkbox
                              onChange={(event: SelectChangeEvent) => handleFactoryChangeClick(event, data.value)}
                              checked={getFactoryChecked?.(data.value)}
                            />
                          </Grid>
                          <Grid
                            container
                            item
                            xs={4}
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                            sx={{ fontSize: '20px', fontFamily: 'Inter', fontWeight: 900, color: '#BB5E00', pr: 1 }}
                          >
                            {data.area}
                          </Grid>
                          <Grid
                            container
                            item
                            xs={4}
                            alignItems={'center'}
                            justifyContent={'flex-start'}
                            sx={{ fontSize: '20px', fontFamily: 'Inter', fontWeight: 900, color: '#BB5E00', pr: 1 }}
                          >
                            {data.text}
                          </Grid>
                        </Grid>
                      );
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'center'} sx={{ mt: 3 }}>
            <YatungButton
              text={i18T('GLOBAL.CANCEL')}
              color="red"
              variant="contained"
              size="medium"
              onClick={handleCloseModal}
              sx={{
                fontSize: '24px',
                minWidth: '15%',
                mr: 1,
              }}
            />
            <YatungButton
              disabled={factoryCheckedData.length === 0 || colorCodeId === 0 || title === ''}
              text={i18T('GLOBAL.FINISH')}
              color="blue"
              variant="contained"
              size="medium"
              onClick={handleSubmit}
              sx={{
                fontSize: '24px',
                minWidth: '15%',
                mr: 1,
              }}
            />
          </Stack>
        </>
      }
    />
  );
}
