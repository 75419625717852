import PermissionRoute from '@Src/_basic/helpers/PermissionRoute';
import { RouteConfig } from '@Src/app/BasicRouter';
import React, { memo } from 'react';
import CreateSieveReport from './screens/CreateSieveReport';
import RevisionSieveReport from './screens/RevisionSieveReport';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    element: <CreateSieveReport />,
  },
  {
    path: '/:form1Id/:form2Id/:exptItemCode',
    element: <RevisionSieveReport />,
  },
];

const SieveReport = () => PermissionRoute(routesConfig);

export default memo(SieveReport);
